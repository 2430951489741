import React from 'react';
import classnames from 'classnames';

import Text from 'bloko/blocks/text';

interface SupernovaCounterProps {
    children?: number | string;
    clear?: boolean;
    dotOnly?: boolean;
    dropdown?: boolean;
    forIcon?: boolean;
    hidden?: boolean;
    light?: boolean;
    primary?: boolean;
    large?: boolean;
}

const SupernovaCounter: React.VFC<SupernovaCounterProps> = ({
    clear,
    children,
    dotOnly,
    dropdown,
    forIcon,
    hidden,
    light,
    primary,
    large,
    ...props
}) => {
    let content = children;

    if (typeof children === 'number') {
        if (children > 1000) {
            content = `${Math.floor(children / 1000)}K`;
        } else if (children > 99) {
            content = '99+';
        }
    }

    if (dropdown) {
        return (
            <span className="supernova-dropdown-counter" {...props}>
                <Text strong>{content}</Text>
            </span>
        );
    }

    return (
        <span
            className={classnames('supernova-navi-counter', {
                'supernova-navi-counter_clear': clear,
                'supernova-navi-counter_dot-only': dotOnly,
                'supernova-navi-counter_for-icon': forIcon,
                'supernova-navi-counter_light': light,
                'supernova-navi-counter_primary': primary,
                'supernova-navi-counter_large': large,
                'g-hidden': hidden,
            })}
            {...props}
        >
            {content}
        </span>
    );
};

export default SupernovaCounter;
