import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Acknowledge from 'HH/EmployerReviews/Acknowledge';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';

const TrlKeys = {
    employerReviews: 'applicant.employerReviews.supernova.menuItem',
};

const handleClick = () => {
    Analytics.sendHHEventButtonClick('employer_review_status_waiting', { type: 'profile' });
    Acknowledge();
};

const employerReviewsApplicantProfileBadgeAction = makeSetStoreField('employerReviewsApplicantProfileBadge');

const EmployersReviews: TranslatedComponent<NavItem> = ({ name, trls, ...props }) => {
    const analyticsSentRef = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!analyticsSentRef.current) {
            Analytics.sendHHEvent('element_shown', {
                elementName: 'employer_review_list_button',
                hhtmSourceLabel: 'profile_menu',
            });
            dispatch(employerReviewsApplicantProfileBadgeAction(false));
            analyticsSentRef.current = true;
        }
    }, [dispatch]);

    return (
        <SupernovaDropdownOption onClick={handleClick} name={name} {...props}>
            {trls[TrlKeys.employerReviews]}
        </SupernovaDropdownOption>
    );
};

export default translation(EmployersReviews);
