import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import NovaFilters from 'src/models/novaFilters';
import { VacancySearchResult } from 'src/models/vacancySearch/vacancySearchResult';

export interface Coordinates {
    lat: number;
    lng: number;
}

export interface BBox {
    topRight: Coordinates;
    bottomLeft: Coordinates;
}

export interface GeoCluster {
    bbox: BBox;
    centerPoint: Coordinates;
    count: number;
    geohash: string;
}

export interface Vacancy {
    address: Coordinates;
    company: {
        id: number;
        name: string;
        url: string;
    };
    compensation: {
        currencyCode: CurrencyType;
        from: number;
        to: number;
    };
    id: number;
    name: string;
    url: string;
    isSimilar?: boolean;
}

interface SearchMapResponse {
    vacanciesFromResponse: Vacancy[];
    geoClusters: GeoCluster[];
    mapHasGeoClusters: boolean;
    query: string;
    clusters?: NovaFilters;
    vacancySearchResult?: VacancySearchResult;
    sendResponse: boolean;
}

const DEFAULT_STATE: SearchMapResponse = {
    vacanciesFromResponse: [],
    geoClusters: [],
    mapHasGeoClusters: false,
    query: '',
    sendResponse: false,
};

export default autoGeneratedReducer<SearchMapResponse>(DEFAULT_STATE);
