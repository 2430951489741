import React from 'react';

import Analytics from '@hh.ru/analytics-js';
import talantixLinkContactsHideButtonClick from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_button_click';
import headerButtonClick, { Type as HeaderType } from '@hh.ru/analytics-js-events/build/xhh/common/header_button_click';
import { employerRegistrationButtonClickExternal } from '@hh.ru/analytics-js-events/build/xhh/employer/registration/employer_registration_button_click';

import { useSelector } from 'src/hooks/useSelector';
import { NavItemAnalytics } from 'src/models/supernovaNaviMenu';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

export interface TrackClicksProps {
    trackClicks?: boolean;
    analytics?: NavItemAnalytics;
}

export const SUPPORT_ANALYTICS_EVENTS: Record<string, string> = {
    copiny: 'support_community',
    employerHelp: 'q&a',
    orderCall: 'call_request',
    personalManager: 'personal_manager',
    supportChat: 'support_chat',
    telegram: 'support_telegram',
    viber: 'support_viber',
    whatsapp: 'support_whatsapp',
};

const HYBRID_ANALYTICS_EVENTS: Record<string, string> = {
    signup: 'create_CV',
    login: 'enter',
};

export const sendSupportAnalytics = (userType: UserType, name: keyof typeof SUPPORT_ANALYTICS_EVENTS): void => {
    Analytics.sendEvent(userType, 'support_chat_in_help_menu_click', SUPPORT_ANALYTICS_EVENTS[name]);
};

const useGetHeaderType = (): HeaderType => {
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    if (userType === UserType.Employer) {
        return 'authorized_employer';
    }
    if (supernovaUserType === SupernovaUserType.Employer) {
        return 'anonymous_employer';
    }

    if (userType === UserType.Applicant) {
        return 'authorized_applicant';
    }

    return 'anonymous_applicant';
};

const useTrackClicks = (name: string, trackClicks?: boolean, analytics?: NavItemAnalytics): (() => void) => {
    const userType = useSelector(({ userType }) => userType);
    const headerType = useGetHeaderType();

    return React.useCallback(() => {
        if (name === 'talantix') {
            talantixLinkContactsHideButtonClick({ hhtmSourceLabel: 'header' });
            return;
        }

        if (trackClicks) {
            if (analytics?.name) {
                headerButtonClick({
                    buttonName: analytics?.name,
                    type: headerType,
                });
                if (analytics?.name === 'employer_registration_header' && name === 'employerSignup') {
                    employerRegistrationButtonClickExternal();
                }
            } else {
                Analytics.sendHHEventButtonClick(`menu_item_${name}`, analytics?.params);
            }
        }

        if (name in HYBRID_ANALYTICS_EVENTS) {
            window.txqReachGoal?.(HYBRID_ANALYTICS_EVENTS[name]);
        }

        if (name in SUPPORT_ANALYTICS_EVENTS) {
            sendSupportAnalytics(userType, name);
        }
    }, [name, trackClicks, analytics, headerType, userType]);
};

export default useTrackClicks;
