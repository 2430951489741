import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Resume } from 'src/models/resume/resume.types';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { Phone } from 'src/models/resume/resumeCommon.types';

const RESUME = 'RESUME';
const RESUME_UPDATED = 'RESUME_UPDATED';
const RESUME_ACCESS_TYPE = 'RESUME_ACCESS_TYPE';
const RESUME_UPDATE_CONTACT_PHONES = 'RESUME_UPDATE_CONTACT_PHONES';
const RESUME_SET_TO_UPDATE_VALUE_ACTION = 'RESUME_SET_TO_UPDATE_VALUE_ACTION';
interface PayloadTypes {
    [RESUME]: Resume;
    [RESUME_UPDATED]: { updated: number };
    [RESUME_ACCESS_TYPE]: { accessType: ResumeAccessType };
    [RESUME_UPDATE_CONTACT_PHONES]: Phone[];
    [RESUME_SET_TO_UPDATE_VALUE_ACTION]: { value: number };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const resumeAction = ActionCreator(RESUME);

export const resumeUpdatedAction = ActionCreator(RESUME_UPDATED);

export const resumeUpdateContactPhonesAction = ActionCreator(RESUME_UPDATE_CONTACT_PHONES);

export const resumeSetToUpdateValueAction = ActionCreator(RESUME_SET_TO_UPDATE_VALUE_ACTION);

export default createReducer<Resume, PayloadTypes>(null, {
    [RESUME]: (_state, action) => ({ ...action.payload }),
    [RESUME_UPDATED]: (state, action) => ({
        ...state,
        updated: action.payload.updated,
    }),
    [RESUME_ACCESS_TYPE]: (state, action) => ({
        ...state,
        accessType: action.payload.accessType,
    }),
    [RESUME_UPDATE_CONTACT_PHONES]: (state, action) => ({
        ...state,
        phone: { ...state.phone, value: action.payload },
        openedContacts: true,
    }),
    [RESUME_SET_TO_UPDATE_VALUE_ACTION]: (state, action) => ({
        ...state,
        toUpdate: {
            // поле toUpdate из питона имеет структуру вида
            // {value: 12804, count: 4, translation: "hours", ending: 1}
            // но в коде используем только value, так что все остальные поля просто стираю
            value: action.payload.value,
        },
    }),
});
