import { FC, ReactNode } from 'react';

import { Card, Cell, CellText, Radio } from '@hh.ru/magritte-ui';

interface EmployerAccountSwitcherOptionProps {
    children: ReactNode;
    onClick?: () => void;
    checked?: boolean;
}

const EmployerAccountSwitcherOption: FC<EmployerAccountSwitcherOptionProps> = ({ checked, children, onClick }) => {
    return (
        <Card showBorder actionCard padding={16} borderRadius={16} data-qa="employer-switcher-option">
            <Cell left={<Radio checked={!!checked} onChange={onClick} />}>
                <CellText data-qa="employer-switcher-option-text">{children}</CellText>
            </Cell>
        </Card>
    );
};

export default EmployerAccountSwitcherOption;
