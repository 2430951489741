import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalError, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Notification, { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { runLoyaltySurvey } from 'src/models/loyaltySurvey';
import { ComplexUserNotificationsTemplateKey, deleteUserNotification } from 'src/models/userNotifications';
import fetcher from 'src/utils/fetcher';

import StepHeader from 'src/components/LoyaltySurveyModal/StepHeader';
import Steps from 'src/components/LoyaltySurveyModal/Steps';
import {
    DEFAULT_ANSWER_CONFIG,
    FINAL_STEP,
    FORM_STEPS_ARRAY,
    FormSteps,
    SKIP_ANSWER_VALUE,
    SurveyResultType,
} from 'src/components/LoyaltySurveyModal/constants';

import styles from './loyalty-survey-modal.less';

const TrlKeys = {
    button: 'notification.employer.loyaltySurvey.button',
    loadError: 'notification.employer.loyaltySurvey.questionsLoadingError',
    saveError: 'notification.employer.loyaltySurvey.answersSaveError',
    thankYou: 'notification.employer.loyaltySurvey.thankYou',
    submit: 'surveys.loyalty.popup.submit',
    back: 'surveys.loyalty.popup.back',
    next: 'surveys.loyalty.popup.next',
    skip: 'surveys.loyalty.popup.skip',
    counterPart: 'surveys.loyalty.popup.counterPart',
    popupHeader: 'surveys.loyalty.popup.header',
    droptip: 'surveys.loyalty.popup.droptip',
};

const SURVEY_URL = '/surveys/loyalty';
const SURVEY_FINISH_URL = '/surveys/loyalty/finish';

declare global {
    interface FetcherGetApi {
        [SURVEY_URL]: {
            queryParams: { sid: number };
            response: SurveyResultType;
        };
    }
    interface FetcherPostApi {
        [SURVEY_URL]: {
            queryParams: void;
            body: { answers: string; sid: number };
            response: SurveyResultType;
        };
        [SURVEY_FINISH_URL]: {
            queryParams: void;
            body: { sid: number };
            response: void;
        };
    }
}

const LoyaltySurveyModal: TranslatedComponent = ({ trls }) => {
    const params = useSelector(({ loyaltySurvey }) => loyaltySurvey);
    const [showError, setShowError] = useState(false);
    const [surveyLoadError, setSurveyLoadError] = useState(false);
    const [surveySaveError, setSurveySaveError] = useState(false);
    const [surveySuccessSave, setSurveySuccessSave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [surveyResult, setSurveyResult] = useState<SurveyResultType>({});
    const formRef = useRef<HTMLFormElement>(null);
    const [step, setStep] = useState(0);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const dispatch = useDispatch();
    const userNotifications = useSelector(({ userNotifications }) => userNotifications);
    const activatorRef = useRef(null);

    const answers = FORM_STEPS_ARRAY.map((variant) => surveyResult[variant] ?? DEFAULT_ANSWER_CONFIG[variant]);

    const finishSurvey = useCallback(
        async (sid: number) => {
            userNotifications.forEach((el) => {
                if (ComplexUserNotificationsTemplateKey.EmployerLoyaltySurvey === el.templateKey) {
                    dispatch(deleteUserNotification(el.id));
                }
            });

            try {
                await fetcher.postFormData(SURVEY_FINISH_URL, {
                    sid,
                });
            } catch (_) {
                setSurveySaveError(true);
                return;
            }

            Analytics.sendEvent('employer', 'finish-loyalty-survey', params.type);
            setSurveySuccessSave(true);
            setShowModal(false);
        },
        [dispatch, params.type, userNotifications]
    );

    const fetchSurvey = useCallback(async () => {
        if (params.id === undefined || !params.runSurvey) {
            return;
        }

        let survey: SurveyResultType | undefined;

        try {
            survey = await fetcher.get(SURVEY_URL, {
                params: {
                    sid: params.id,
                },
            });
        } catch (_) {
            setSurveyLoadError(true);
            return;
        }

        let passedStepsCount = Object.keys(survey).length;
        if (passedStepsCount === 1 && survey[FORM_STEPS_ARRAY[0]] === SKIP_ANSWER_VALUE) {
            passedStepsCount = 2;
        } else if (passedStepsCount === 2 && survey[FORM_STEPS_ARRAY[2]]) {
            passedStepsCount = 3;
        } else if (passedStepsCount === 2 && survey[FORM_STEPS_ARRAY[1]] === '') {
            passedStepsCount = 1;
        }

        setSurveyResult(survey);

        setShowModal(true);
        setStep(Math.min(FINAL_STEP, passedStepsCount));
        Analytics.sendEvent('employer', 'open-loyalty-survey-popup', params.type);
    }, [params.id, params.runSurvey, params.type]);

    const saveAnswers = useCallback(
        async (useFormData: boolean, stepChange: number, shouldFinish = false) => {
            if (!params.id || formRef.current === null) {
                return;
            }

            setDisableSubmit(true);

            let answer: SurveyResultType = {};
            if (useFormData) {
                const data = new FormData(formRef.current);
                const stepVariant = [...data.keys()].find((el) => FORM_STEPS_ARRAY.includes(el)) as FormSteps;
                if (stepVariant) {
                    const value = (data.get(stepVariant) as string) || DEFAULT_ANSWER_CONFIG[stepVariant];
                    answer = { [stepVariant]: value };
                    setSurveyResult({ ...surveyResult, ...answer });
                }
            } else {
                answer = { [FORM_STEPS_ARRAY[step]]: SKIP_ANSWER_VALUE };
                setSurveyResult({ ...surveyResult, ...answer });
            }

            try {
                await fetcher.postFormData(SURVEY_URL, {
                    sid: params.id,
                    answers: JSON.stringify({ ...surveyResult, ...answer }),
                });
            } catch (_) {
                setShowError(true);
                return;
            } finally {
                setDisableSubmit(false);
            }

            if (shouldFinish) {
                await finishSurvey(params.id);
                return;
            }

            const newStep = step + stepChange;
            if (newStep >= 0 && newStep <= FINAL_STEP) {
                setStep(step + stepChange);
            }
        },
        [finishSurvey, params.id, step, surveyResult]
    );

    const closeModal = useCallback(() => {
        void saveAnswers(true, 0);
        dispatch(runLoyaltySurvey(false));
        setShowModal(false);
    }, [dispatch, saveAnswers]);

    const handleNext = () => {
        setShowError(false);
        void saveAnswers(true, 1, step === FINAL_STEP);
    };

    const handlePrevious = () => {
        void saveAnswers(true, step === 2 && answers[0] === SKIP_ANSWER_VALUE ? -2 : -1);
    };

    const handleSkip = () => {
        void saveAnswers(false, step === 0 ? 2 : 1, step === FINAL_STEP);
    };

    useEffect(() => {
        void fetchSurvey();
    }, [fetchSurvey]);

    useEffect(() => {
        if (surveyResult[FORM_STEPS_ARRAY[step]] === SKIP_ANSWER_VALUE || !surveyResult[FORM_STEPS_ARRAY[step]]) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }, [step, setDisableSubmit, surveyResult]);

    if (surveyLoadError) {
        return (
            <Notification kind={NotificationKind.Error} autoClose>
                {trls[TrlKeys.loadError]}
            </Notification>
        );
    }

    if (surveySaveError) {
        return (
            <Notification kind={NotificationKind.Error} autoClose>
                {trls[TrlKeys.saveError]}
            </Notification>
        );
    }

    if (surveySuccessSave) {
        return (
            <Notification kind={NotificationKind.Ok} autoClose>
                {trls[TrlKeys.thankYou]}
            </Notification>
        );
    }

    const submitButton = (
        <div className={styles.loyaltySurveyModalFooterTipHelper} ref={activatorRef}>
            <Button
                disabled={disableSubmit}
                data-qa="loyalty-survey-next-question"
                stretched
                kind={ButtonKind.Primary}
                onClick={handleNext}
            >
                {trls[step !== FINAL_STEP ? TrlKeys.next : TrlKeys.submit]}
            </Button>
        </div>
    );

    return (
        <Modal onClose={closeModal} visible={showModal}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.popupHeader]}</ModalTitle>
            </ModalHeader>
            <div className={styles.loyaltySurveyModalBody} data-qa="loyalty-survey-popup-body">
                <Form
                    method="POST"
                    action={SURVEY_URL}
                    ref={formRef}
                    onChange={() => {
                        setDisableSubmit(false);
                    }}
                >
                    {params.type && (
                        <StepHeader step={step} total={FORM_STEPS_ARRAY.length} type={params.type} answers={answers} />
                    )}
                    <div data-qa="loyalty-survey-step">
                        <Steps step={step} answers={answers} />
                    </div>
                </Form>
            </div>
            <ModalError visible={showError}>{trls[TrlKeys.saveError]}</ModalError>
            <ModalFooter>
                <div className={styles.loyaltySurveyModalFooter}>
                    <div className={styles.loyaltySurveyModalFooterLink}>
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            data-qa="loyalty-survey-skip-question"
                            onClick={handleSkip}
                        >
                            {trls[TrlKeys.skip]}
                        </Link>
                    </div>
                    <div className={styles.loyaltySurveyModalFooterButtonsWrapper}>
                        {step > 0 && (
                            <Button
                                data-qa="loyalty-survey-previous-question"
                                stretched
                                appearance={ButtonAppearance.Outlined}
                                onClick={handlePrevious}
                            >
                                {trls[TrlKeys.back]}
                            </Button>
                        )}
                        {disableSubmit && (
                            <HoverTip render={() => trls[TrlKeys.droptip]} activatorRef={activatorRef}>
                                {submitButton}
                            </HoverTip>
                        )}
                        {!disableSubmit && submitButton}
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(LoyaltySurveyModal);
