import { FC, useRef } from 'react';

import { Card, Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useTrackElementShown from 'src/components/SupernovaMainMenu/useTrackElementShown';
import { NavItem } from 'src/models/supernovaNaviMenu';

const EmployerPanelCardItem: FC<NavItem> = ({
    translations,
    url,
    external,
    name,
    trackClicks,
    analytics,
    trackElementShown,
}) => {
    const elementRef = useRef(null);

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    useTrackElementShown(elementRef, name, trackElementShown);

    return (
        <Card
            data-qa={name}
            Element={Link}
            to={url}
            onClick={handleTrackClick}
            target={external ? '_blank' : undefined}
            ref={elementRef}
            hoverStyle="neutral"
            key={name}
            padding={12}
            borderRadius={12}
            stretched
            actionCard
        >
            <Text typography="label-2-regular" style="primary">
                {translations.name}
            </Text>
        </Card>
    );
};

export default EmployerPanelCardItem;
