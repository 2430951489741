import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { StageType } from 'src/components/VacancyFunnel/types';

import { VacancyFunnelStage, VacancyFunnelSubstate } from 'src/models/vacancyFunnel';

export enum TemplateType {
    Mass = 'mass',
    Point = 'point',
    Default = 'default',
}
export type TemplateSubstate = Pick<VacancyFunnelSubstate, 'state' | 'substateName'>;
export type TemplateStage = Pick<VacancyFunnelStage, 'state'> & { substates: TemplateSubstate[] };
export type FunnelTemplate = { [key in StageType]: TemplateStage[] } & { templateType: TemplateType };
export type FunnelTemplates = Record<TemplateType, FunnelTemplate>;

export default autoGeneratedReducer<FunnelTemplates>(null);
