import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CountryCode } from 'src/models/countryId';
import { Badge } from 'src/models/vacancyView.types';

const EMPLOYER_INFO_SET_DESCRIPTION = 'EMPLOYER_INFO_SET_DESCRIPTION';
const EMPLOYER_INFO_DESCRIPTION_STATUS = 'EMPLOYER_INFO_DESCRIPTION_STATUS';
const EMPLOYER_INFO_SET_SITE = 'EMPLOYER_INFO_SET_SITE';
const EMPLOYER_INFO_SET_INDUSTRIES = 'EMPLOYER_INFO_SET_INDUSTRIES';

export enum Status {
    Dirty = 'DIRTY',
    Fetching = 'FETCHING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
}

export enum EmployerCategory {
    Company = 'company',
    Agency = 'agency',
    PrivateRecruiter = 'private_recruiter',
    ProjectDirector = 'project_director',
    PrivateIndividual = 'private_individual',
    SelfEmployed = 'self_employed',
    Unknown = 'unknown',
}
export interface Industries {
    id: number;
    trl: string;
    items: number[];
}

interface HrBrandData {
    '@url'?: string;
    '@year': string;
}

export interface HrBrand {
    nominees?: HrBrandData;
    winners?: HrBrandData;
}

export interface EmployerBadges {
    badge: Badge[];
}

interface PreviousEmployerTarget {
    activationTime: number;
    id: number;
    smsCount: number;
}

export interface EmployerSite {
    href: string;
    hostname: string;
}

export interface EmployerInfoState {
    accreditedITEmployer: boolean;
    id?: number;
    name?: string;
    category?: EmployerCategory;
    address?: string;
    site?: EmployerSite;
    industries: Industries[] | [];
    hrBrand?: HrBrand;
    description?: string;
    saveDescriptionStatus: Status;
    organizationFormId: number | null;
    isTrusted: boolean;
    canEditDescription: boolean;
    hasUnsavedChanges: boolean;
    employerCountryCode: CountryCode | string;
    previousEmployerTargets: Array<PreviousEmployerTarget>;
    itAccreditationBlocked: boolean;
    accepted?: boolean;
    isZpEmployer?: boolean;
    badges?: EmployerBadges;
}

export const INITIAL_STATE: EmployerInfoState = {
    accreditedITEmployer: false,
    industries: [],
    saveDescriptionStatus: Status.Dirty,
    isTrusted: false,
    canEditDescription: false,
    hasUnsavedChanges: false,
    employerCountryCode: '',
    previousEmployerTargets: [],
    itAccreditationBlocked: false,
    organizationFormId: null,
};

interface PayloadTypes {
    [EMPLOYER_INFO_SET_DESCRIPTION]: string;
    [EMPLOYER_INFO_DESCRIPTION_STATUS]: Status;
    [EMPLOYER_INFO_SET_SITE]: EmployerSite | undefined;
    [EMPLOYER_INFO_SET_INDUSTRIES]: Industries[];
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setDescription = ActionCreator(EMPLOYER_INFO_SET_DESCRIPTION);
export const setDescriptionStatus = ActionCreator(EMPLOYER_INFO_DESCRIPTION_STATUS);
export const setSite = ActionCreator(EMPLOYER_INFO_SET_SITE);
export const setIndustries = ActionCreator(EMPLOYER_INFO_SET_INDUSTRIES);

export default createReducer<EmployerInfoState, PayloadTypes>(INITIAL_STATE, {
    [EMPLOYER_INFO_SET_DESCRIPTION]: (state, { payload }) => ({
        ...state,
        description: payload,
        hasUnsavedChanges: true,
    }),
    [EMPLOYER_INFO_DESCRIPTION_STATUS]: (state, { payload }) => ({
        ...state,
        saveDescriptionStatus: payload,
        hasUnsavedChanges: payload !== Status.Success,
    }),
    [EMPLOYER_INFO_SET_SITE]: (state, { payload }) => ({ ...state, site: payload }),
    [EMPLOYER_INFO_SET_INDUSTRIES]: (state, { payload }) => ({ ...state, industries: payload }),
});
