// tempexp_28607_file

import Analytics from '@hh.ru/analytics-js';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ComplexUserNotification } from 'src/models/userNotifications';
import { WORK_CLOSE_TO_HOME_LINK, WorkCloseToHomeVariant } from 'src/utils/notifications/WorkCloseToHome';

const TrlKeys = {
    [WorkCloseToHomeVariant.First]: {
        title: 'work.close.to.home.notification.variant1.title',
        description: 'work.close.to.home.notification.variant1.description',
        link: 'work.close.to.home.notification.variant1.link',
    },
    [WorkCloseToHomeVariant.Second]: {
        title: 'work.close.to.home.notification.variant2.title',
        description: 'work.close.to.home.notification.variant2.description',
        link: 'work.close.to.home.notification.variant2.link',
    },
    [WorkCloseToHomeVariant.Third]: {
        title: 'work.close.to.home.notification.variant3.title',
        description: 'work.close.to.home.notification.variant3.description',
        link: 'work.close.to.home.notification.variant3.link',
    },
};

const WorkCloseToHome: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const variant = useSelector(({ workCloseToHomeVariant }) => workCloseToHomeVariant);
    const trlKeys = TrlKeys[variant];
    const link = WORK_CLOSE_TO_HOME_LINK;
    const analyticsName = `work_close_to_home_supernova_variant_${variant}`;
    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Info}
            dataQa={DataQaInvariants.Info}
            onRemove={() => {
                Analytics.sendHHEventButtonClick(`${analyticsName}_remove`);
            }}
            {...notification}
        >
            <NotificationSpacer>
                <Text strong>{trls[trlKeys.title]}</Text>
            </NotificationSpacer>
            <NotificationSpacer>
                <p>{trls[trlKeys.description]}</p>
            </NotificationSpacer>
            <NotificationLink
                target="_blank"
                to={link}
                onClick={() => {
                    Analytics.sendHHEventButtonClick(analyticsName);
                }}
            >
                {trls[trlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(WorkCloseToHome);
