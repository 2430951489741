import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface RelocationWarning {
    vacancyId?: number;
    show: boolean;
    regionTrl?: string;
    confirmed: boolean;
}

export default autoGeneratedReducer<RelocationWarning>({ show: false, confirmed: false });
