import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ApplicantNegotiationFiltersStates } from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';

interface ApplicantNegotiationsCounters {
    new: Record<ApplicantNegotiationFiltersStates, number>;
    total?: Record<ApplicantNegotiationFiltersStates, number>;
    shouldShowNewAsBadge: boolean;
    activeFilterTab: ApplicantNegotiationFiltersStates;
}

export default autoGeneratedReducer<ApplicantNegotiationsCounters>({
    shouldShowNewAsBadge: true,
    activeFilterTab: ApplicantNegotiationFiltersStates.All,
    new: {
        all: 0,
        awaiting: 0,
        invitation: 0,
        discard: 0,
        deleted: 0,
        archived: 0,
    },
});
