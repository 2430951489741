import { useState } from 'react';

import { Accordion, Button, Divider, HSpacingContainer, Text, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { VkSolidSize24, TelegramSolidSize24, ViberSolidSize24 } from '@hh.ru/magritte-ui/service';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { ChatBotMessengerTypes } from 'src/models/chatBot';

import styles from './styles.less';

const TrlKeys = {
    [ChatBotMessengerTypes.Vk]: 'autosearch.messengers.vk',
    [ChatBotMessengerTypes.Telegram]: 'autosearch.messengers.telegram',
    [ChatBotMessengerTypes.Viber]: 'autosearch.messengers.viber',
    description: 'autosearch.messengers.description.qr',
    button: 'autosearch.messengers.button',
};

const ICON_MAP = {
    [ChatBotMessengerTypes.Vk]: VkSolidSize24,
    [ChatBotMessengerTypes.Telegram]: TelegramSolidSize24,
    [ChatBotMessengerTypes.Viber]: ViberSolidSize24,
};

interface AutosearchMessengersProps {
    isXS?: boolean;
}

const AutosearchMessengers: TranslatedComponent<AutosearchMessengersProps> = ({ isXS, trls }) => {
    const { messengers, page, genericUserType, source } = useSelectorNonNullable((store) => store.chatBot);

    const [openMessenger, setOpenMessenger] = useState<ChatBotMessengerTypes | null>(ChatBotMessengerTypes.Vk);

    return (
        <VSpacingContainer default={isXS ? 0 : 8}>
            {messengers.map(({ type, href }, index) => {
                const IconComponent = ICON_MAP[type];
                const title = (
                    <HSpacingContainer default={12}>
                        <IconComponent initial="secondary" />
                        <Text typography="label-2-regular">{trls[TrlKeys[type]]}</Text>
                    </HSpacingContainer>
                );

                if (isXS) {
                    return (
                        <div
                            key={type}
                            onClick={() => {
                                window.open(href, '_blank');
                            }}
                        >
                            <VSpacing default={16} />
                            <div className={styles.messengerItem}>
                                {title}
                                <ChevronRightOutlinedSize24 initial="secondary" />
                            </div>
                            <VSpacing default={16} />
                            {index !== messengers.length - 1 && <Divider />}
                        </div>
                    );
                }

                return (
                    <Accordion
                        key={type}
                        title={title}
                        expanded={type === openMessenger}
                        onClick={() => {
                            if (type === openMessenger) {
                                setOpenMessenger(null);
                            } else {
                                setOpenMessenger(type);
                            }
                        }}
                        controlled
                    >
                        <HSpacingContainer default={12}>
                            <Text typography="paragraph-3-regular">{trls[TrlKeys.description]}</Text>
                            <StaticImg
                                className={styles.qrCode}
                                path={`/images/hh/chatbot/${page}/qr-${type}-${genericUserType}-${source}.png`}
                                alt={`qr-code-${type}`}
                            />
                        </HSpacingContainer>
                        <VSpacing default={12} />
                        <Button
                            mode="secondary"
                            style="accent"
                            Element="a"
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="small"
                            stretched
                        >
                            {trls[TrlKeys.button]}
                        </Button>
                    </Accordion>
                );
            })}
        </VSpacingContainer>
    );
};

export default translation(AutosearchMessengers);
