import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { IVacancyCreateFormValues } from 'src/models/vacancyCreate/vacancyCreate.types';

export const UPDATE_VACANCY_INITIAL_BODY = 'UPDATE_VACANCY_INITIAL_BODY';

type VacancyInitialBody = Partial<IVacancyCreateFormValues>;

interface PayloadTypes {
    [UPDATE_VACANCY_INITIAL_BODY]: VacancyInitialBody;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const vacancyUpdateInitialBodyAction = actionCreator(UPDATE_VACANCY_INITIAL_BODY);

export const INITIAL_STATE: VacancyInitialBody = {};

export default createReducer<VacancyInitialBody, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_VACANCY_INITIAL_BODY]: (state, action) => ({ ...state, ...action.payload }),
});
