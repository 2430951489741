import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum KakdelaLinkMode {
    Internal = 'internal',
    External = 'external',
}

export interface KakdelaBanner {
    displayPlace: string;
    passingLink: string;
    linkMode: KakdelaLinkMode;
    bannerText: string;
}

export const kakdelaBannerReducer = autoGeneratedReducer<KakdelaBanner>(null);
