import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerCategoryCode {
    Company = 'COMPANY',
    ProjectDirector = 'PROJECT_DIRECTOR',
    PrivateIndividual = 'PRIVATE_INDIVIDUAL',
    SelfEmployed = 'SELF_EMPLOYED',
    PrivateRecruiter = 'PRIVATE_RECRUITER',
    Agency = 'AGENCY',
}

interface EmployerCategory {
    code: EmployerCategoryCode;
    value: number;
    translationKey?: string;
    translation: string;
}

type EmployerCategories = EmployerCategory[];

export default autoGeneratedReducer<EmployerCategories>([]);
