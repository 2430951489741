import { FC, PropsWithChildren } from 'react';

import { ThemeProvider } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import zpDayTheme from 'src/themes/magritteZPDayTheme';

const MagritteThemeProvider: FC<PropsWithChildren> = ({ children }) => {
    const isZP = useIsZarplataPlatform();

    return isZP ? <ThemeProvider themeProducer={zpDayTheme}>{children}</ThemeProvider> : <>{children}</>;
};

export default MagritteThemeProvider;
