import { FC, createContext, MutableRefObject, PropsWithChildren, useContext, useRef } from 'react';

export const NovaFilterUpdateContext = createContext<MutableRefObject<AbortController | null>>({ current: null });

type AbortAndGetUpdatedSignalFunction = () => AbortSignal;

export const useUpdateAbortController = (): AbortAndGetUpdatedSignalFunction => {
    const abortControllerRef = useContext(NovaFilterUpdateContext);

    return () => {
        abortControllerRef.current?.abort();

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        return abortController.signal;
    };
};

const NovaFilterUpdateContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const cancelNovaFilterUpdateRef = useRef(null);

    return (
        <NovaFilterUpdateContext.Provider value={cancelNovaFilterUpdateRef}>
            {children}
        </NovaFilterUpdateContext.Provider>
    );
};

export default NovaFilterUpdateContextProvider;
