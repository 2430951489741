import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { PartTimeOption } from 'src/models/search/searchCriteria.types';

export type SoftSchedule = 'fullDay' | 'remote' | 'flexible' | 'shift' | 'flyInFlyOut' | 'partTime';
export type SnatchSchedule = 'fullDay' | 'remote' | 'flexible' | 'shift' | 'flyInFlyOut' | 'doesNotMatter';

export interface ShortRole {
    id: string;
    name: string;
}

type AnonymousOnboardingSoft = {
    isOnboardingExp: true;
    isSoft: true;
    isSnatch: false;
    schedules: SoftSchedule[];
    partTime: PartTimeOption[];
    area: string;
    popularShortRoles: ShortRole[];
    dictShortRoles: Record<string, string>;
};

type AnonymousOnboardingSnatch = {
    isOnboardingExp: true;
    isSoft: false;
    isSnatch: true;
    schedules: SnatchSchedule[];
    area: string;
    popularShortRoles: ShortRole[];
    otherShortRoles: ShortRole[];
    dictShortRoles: Record<string, string>;
};

type AnonymousOnboardingOff = { isOnboardingExp: false; isSoft: false; isSnatch: false };

export default autoGeneratedReducer<AnonymousOnboardingSoft | AnonymousOnboardingSnatch | AnonymousOnboardingOff>({
    isOnboardingExp: false,
    isSoft: false,
    isSnatch: false,
});
