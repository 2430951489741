import { useEffect, useRef } from 'react';

import userStatusSelectOfferCompanyElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_element_shown';
import userStatusSelectOfferCompanyScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_screen_shown';
import Radio from 'bloko/blocks/radio';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { ReasonVariants } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/types';

const TrlKeys = {
    [ReasonVariants.WithoutResponse]: 'jobSearchStatus.postChangeModal.notOnHH.reason.withoutResponse',
    [ReasonVariants.ContactedByMyself]: 'jobSearchStatus.postChangeModal.notOnHH.reason.contactedByMyself',
    [ReasonVariants.Other]: 'jobSearchStatus.postChangeModal.notOnHH.reason.other',
    description: 'jobSearchStatus.postChangeModal.notOnHH.description',
};

interface ReasonsContentProps {
    value: ReasonVariants | null;
    onSet: (value: ReasonVariants) => void;
}

const ReasonsContent: TranslatedComponent<ReasonsContentProps> = ({ value, onSet, trls }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current) {
            userStatusSelectOfferCompanyScreenShown();
            userStatusSelectOfferCompanyElementShown(wrapperRef.current, { type: 'nothing', employerIdList: '' });
        }
    }, []);

    const renderRadio = (variant: ReasonVariants) => (
        <Radio checked={value === variant} onChange={() => onSet(variant)}>
            {trls[TrlKeys[variant]]}
        </Radio>
    );

    return (
        <div ref={wrapperRef}>
            <Text>{trls[TrlKeys.description]}</Text>
            <VSpacing base={5} />
            {renderRadio(ReasonVariants.WithoutResponse)}
            <VSpacing base={2} />
            {renderRadio(ReasonVariants.ContactedByMyself)}
            <VSpacing base={2} />
            {renderRadio(ReasonVariants.Other)}
        </div>
    );
};

export default translation(ReasonsContent);
