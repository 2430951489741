import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import DriverLicenseTypes from 'src/models/driverLicense.types';
import { EducationLevel, Gender } from 'src/models/resume/resumeCommon.types';
import { ExclusionOption } from 'src/models/search/advancedSearch';
import { SearchQueries } from 'src/models/search/resume/searchQueries';

export enum AutoInviteCriteria {
    SearchQueries = 'searchQueries',
    AgeInterval = 'ageInterval',
    Areas = 'areas',
    Citizenships = 'citizenships',
    Districts = 'districts',
    DriverLicenseTypes = 'driverLicenseTypes',
    EducationLevels = 'educationLevels',
    Employments = 'employments',
    Experiences = 'experiences',
    Gender = 'gender',
    JobSearchStatuses = 'jobSearchStatuses',
    Labels = 'labels',
    Languages = 'languages',
    MetroStations = 'metroStations',
    ProfessionalRoles = 'professionalRoles',
    Relocation = 'relocation',
    Salary = 'salary',
    Schedules = 'schedules',
    Skills = 'skills',
    Specializations = 'specializations',
    Universities = 'universities',
    WorkTickets = 'workTickets',
    Exclusions = 'exclusions',
}

export enum DaysOfWeek {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
}

export interface PreviewAutoActionResponse {
    searchPreview: {
        differenceInPercent: number;
        suitableResumes: number;
        totalResumes: number;
    };
    spendingPreview: {
        invitesPerMonth: number;
        warnings: string[];
    };
}

interface TopicStateInfo {
    addressId?: string;
    message: string;
    smsText?: string;
    state: string;
}

export enum ScheduleMode {
    Schedule = 'SCHEDULE',
    Once = 'ONCE',
}

export enum DisableReason {
    NoAvailableService = 'NO_AVAILABLE_SERVICE',
    QuotaExceeded = 'QUOTA_EXCEEDED',
    Finished = 'FINISHED',
    DisabledByUser = 'DISABLED_BY_USER',
}

interface ScheduleInfo {
    daysOfWeek: DaysOfWeek[];
    mode: ScheduleMode;
    maxInvitationsPerDay: number;
}

export interface AutoInviteFilters {
    [AutoInviteCriteria.Labels]?: string[];
    [AutoInviteCriteria.SearchQueries]: SearchQueries[];
    [AutoInviteCriteria.ProfessionalRoles]: number[];
    [AutoInviteCriteria.Areas]: number[];
    [AutoInviteCriteria.Relocation]: string;
    [AutoInviteCriteria.MetroStations]: string[];
    [AutoInviteCriteria.Districts]: number[];
    [AutoInviteCriteria.Salary]: { from: number; to: number; currencyCode: CurrencyType };
    [AutoInviteCriteria.Experiences]: string[];
    [AutoInviteCriteria.Skills]: number[];
    [AutoInviteCriteria.EducationLevels]: EducationLevel[];
    [AutoInviteCriteria.Universities]: number[];
    [AutoInviteCriteria.Citizenships]: number[];
    [AutoInviteCriteria.WorkTickets]: number[];
    [AutoInviteCriteria.AgeInterval]: { from: number; to: number };
    [AutoInviteCriteria.Gender]: Gender;
    [AutoInviteCriteria.Employments]: string[];
    [AutoInviteCriteria.Schedules]: string[];
    [AutoInviteCriteria.DriverLicenseTypes]: DriverLicenseTypes[];
    [AutoInviteCriteria.JobSearchStatuses]: string[];
    [AutoInviteCriteria.Languages]: string[];
    [AutoInviteCriteria.Exclusions]: ExclusionOption[];
}

interface AutoInviteType {
    filters: AutoInviteFilters;
    scheduleInfo: ScheduleInfo;
    topicStateInfo: TopicStateInfo;
    vacancyId: number;
    disableReason?: DisableReason;
    disableTime?: number;
}

export interface AutoInviteEnabledType extends AutoInviteType {
    ruleCreationTime?: number;
    ruleId?: number;
    ruleLastModificationTime?: number;
}

export default autoGeneratedReducer<AutoInviteEnabledType>(null);
