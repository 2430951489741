import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName, SupernovaSearchNameAnalyticsMapping } from 'src/models/supernovaSearchName';

import EmployerSearchHiddenFields from 'src/components/SupernovaSearch/EmployerSearchHiddenFields';
import ResumeSearchHiddenFields from 'src/components/SupernovaSearch/ResumeSearchHiddenFields';
import VacancySearchHiddenFields from 'src/components/SupernovaSearch/VacancySearchHiddenFields';

const searchNameToFieldsMap = {
    [SupernovaSearchName.Resumes]: ResumeSearchHiddenFields,
    [SupernovaSearchName.Employers]: EmployerSearchHiddenFields,
    [SupernovaSearchName.Vacancies]: VacancySearchHiddenFields,
};

const HiddenFields: FC<{ searchName: SupernovaSearchName }> = ({ searchName }) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const Component = searchNameToFieldsMap[searchName];

    return (
        <>
            <Component />
            <input type="hidden" name="hhtmFrom" value={hhtmSource} />
            <input type="hidden" name="hhtmFromLabel" value={SupernovaSearchNameAnalyticsMapping[searchName]} />
        </>
    );
};

export default HiddenFields;
