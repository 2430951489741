import { FC, useEffect, PropsWithChildren } from 'react';

import Content from 'src/components/SupernovaMainMenu/UserNotifications/Content';
import useNotifications from 'src/components/SupernovaMainMenu/UserNotifications/useNotifications';

interface UserNotificationsMobileProps {
    visible?: boolean;
    close?: VoidFunction;
}

const UserNotificationsMobile: FC<UserNotificationsMobileProps & PropsWithChildren> = ({ visible, close }) => {
    const { userNotifications, markNotificationsAsViewed } = useNotifications();

    useEffect(() => {
        if (visible) {
            markNotificationsAsViewed();
        }
    }, [markNotificationsAsViewed, visible]);

    return <Content userNotifications={userNotifications} close={close} />;
};

export default UserNotificationsMobile;
