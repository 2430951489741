import Modal, { ModalContent, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AreaSwitcher from 'src/components/AreaSwitcher';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'index.dashboard.modal.areaswitcher.title',
};

const AreaSwitcherModal: TranslatedComponent<{ visible: boolean; onClose: () => void }> = ({
    visible,
    trls,
    onClose,
}) => {
    return (
        <Modal visible={visible} onClose={onClose}>
            <ModalHeader>
                <ModalTitle Element="h2">{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <VSpacing base={6} />
                <AreaSwitcher />
            </ModalContent>
        </Modal>
    );
};

export default translation(AreaSwitcherModal);
