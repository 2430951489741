import { useCallback, useState, useEffect, Dispatch, SetStateAction, FC, useContext } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { BarsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { LocationScaleSmallAppearanceFilled, IconColor } from 'bloko/blocks/icon';
import { Breakpoint } from 'bloko/common/media';
import { Placement } from 'bloko/common/metrics';

import NoIndex from 'src/components/NoIndex';
import useHandleOpenGeoSwitcher from 'src/hooks/useHandleOpenGeoSwitcher';
import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import ChatikActivatorMobile from 'src/components/SupernovaMainMenu/ChatikActivator/ChatikActivatorMobile';
import CreateResumeMobileButton from 'src/components/SupernovaMainMenu/CreateResumeButton';
import MobileMagritte from 'src/components/SupernovaMainMenu/MobileMagritte';
import MobileScreen from 'src/components/SupernovaMainMenu/MobileScreen';
import MobileServices from 'src/components/SupernovaMainMenu/MobileServices';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import UserNotificationsMenuItem from 'src/components/SupernovaMainMenu/UserNotifications/Mobile/MenuItem';
import useNotifications from 'src/components/SupernovaMainMenu/UserNotifications/useNotifications';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const ARROW_BREAKPOINTS = [Breakpoint.XS, Breakpoint.S];
const FULL_WIDTH_BREAKPOINTS = [Breakpoint.XS, Breakpoint.S];
const VISIBLE_ON = [Breakpoint.XS];

const Mobile: FC<NavItem & { setExpanded: Dispatch<SetStateAction<boolean>>; isExpanded: boolean }> = ({
    name,
    analytics,
    subItems,
    trackClicks,
    isExpanded,
    setExpanded,
}) => {
    const isRedesignActive = useContext(isRedesignActiveContext);
    const [currentScreen, setCurrentScreen] = useState('default');
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);
    const { unreadNotificationsCount } = useNotifications();
    const openGeoSwitcher = useHandleOpenGeoSwitcher(() => setCurrentScreen('areaSwitcher'));
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();

    const setDefaultScreen = useCallback(() => {
        setExpanded(false);
        setCurrentScreen('default');
    }, [setExpanded, setCurrentScreen]);

    const handleAreaSwitchClick = useCallback(() => {
        openGeoSwitcher();

        Analytics.sendHHEventButtonClick('vacancy_search_region');
    }, [openGeoSwitcher]);

    useEffect(() => {
        if (isExpanded) {
            Analytics.sendHHEvent('screen_shown', {
                screenName: 'menu',
                hhtmSource: 'menu',
            });
        }
    }, [isExpanded]);

    const render = useCallback(
        (close: VoidFunction) => {
            return (
                <div className="supernova-dropdown-mobile-screens-wrapper">
                    <div
                        className={classnames('supernova-dropdown-mobile-screen', {
                            'supernova-dropdown-mobile-screen_hidden': currentScreen !== 'default',
                        })}
                    >
                        {subItems?.map(
                            ({ name, subItems, trackClicks, trackElementShown, translations, analytics }) => {
                                switch (name) {
                                    case 'areaSwitcher':
                                        return (
                                            <SupernovaDropdownSection key={name}>
                                                <NoIndex>
                                                    <SupernovaDropdownOption
                                                        data-qa="mainmenu_areaSwitcher-xs"
                                                        name={name}
                                                        onClick={handleAreaSwitchClick}
                                                        trackClicks={trackClicks}
                                                        trackElementShown={trackElementShown}
                                                        analytics={analytics}
                                                    >
                                                        {isRedesignGeoExpActive
                                                            ? translations.geoExpName || translations.name
                                                            : translations.name}
                                                        <span>&#160;</span>
                                                        <LocationScaleSmallAppearanceFilled
                                                            initial={IconColor.Gray50}
                                                        />
                                                    </SupernovaDropdownOption>
                                                </NoIndex>
                                            </SupernovaDropdownSection>
                                        );
                                    case 'applicantServices':
                                    case 'employerProjects':
                                        return (
                                            <SupernovaDropdownSection key={name} services>
                                                <MobileServices items={subItems} />
                                            </SupernovaDropdownSection>
                                        );
                                    case 'mainContent':
                                        return (
                                            <SupernovaDropdownSection key={name} mainContent>
                                                {subItems?.map(
                                                    ({
                                                        counter,
                                                        dropdown,
                                                        name,
                                                        trackClicks,
                                                        trackElementShown,
                                                        analytics,
                                                        translations,
                                                        url,
                                                        modalType,
                                                        subItems,
                                                    }) => {
                                                        switch (name) {
                                                            case 'chatikActivator':
                                                                return (
                                                                    <ChatikActivatorMobile
                                                                        key={name}
                                                                        name={name}
                                                                        trackClicks={trackClicks}
                                                                        trackElementShown={trackElementShown}
                                                                        translations={translations}
                                                                        url={url}
                                                                    />
                                                                );
                                                            case 'userNotifications':
                                                                return (
                                                                    <UserNotificationsMenuItem
                                                                        key={name}
                                                                        name={name}
                                                                        trackClicks={trackClicks}
                                                                        trackElementShown={trackElementShown}
                                                                        translations={translations}
                                                                        url={url}
                                                                        onClick={() => void setCurrentScreen(name)}
                                                                        unreadCount={unreadNotificationsCount}
                                                                    />
                                                                );
                                                            case 'createResume':
                                                                return (
                                                                    <CreateResumeMobileButton
                                                                        key={name}
                                                                        name={name}
                                                                        url={url}
                                                                        trackClicks={trackClicks}
                                                                        translations={translations}
                                                                    />
                                                                );
                                                            case 'promo':
                                                            case 'signup':
                                                                return (
                                                                    <div
                                                                        key={name}
                                                                        className="supernova-dropdown-static-option"
                                                                    >
                                                                        <SupernovaButton
                                                                            name={name}
                                                                            primary
                                                                            trackClicks={trackClicks}
                                                                            url={url}
                                                                            rel="nofollow"
                                                                            analytics={analytics}
                                                                        >
                                                                            {translations.name}
                                                                        </SupernovaButton>
                                                                    </div>
                                                                );
                                                            case 'anonSupport':
                                                            case 'help':
                                                            case 'support':
                                                                return (
                                                                    <SupernovaDropdownOption
                                                                        key={name}
                                                                        counter={counter}
                                                                        data-qa={`mainmenu_${name}-xs`}
                                                                        name={name}
                                                                        analytics={analytics}
                                                                        onClick={() => {
                                                                            return undefined;
                                                                        }}
                                                                        trackClicks={trackClicks}
                                                                        trackElementShown={trackElementShown}
                                                                        url={dropdown ? undefined : url}
                                                                        modalType={modalType}
                                                                        subItems={subItems}
                                                                        onClose={close}
                                                                    >
                                                                        {translations.name}
                                                                    </SupernovaDropdownOption>
                                                                );
                                                            default:
                                                                return (
                                                                    <SupernovaDropdownOption
                                                                        key={name}
                                                                        counter={counter}
                                                                        data-qa={`mainmenu_${name}-xs`}
                                                                        name={name}
                                                                        analytics={analytics}
                                                                        onClick={
                                                                            dropdown
                                                                                ? () => void setCurrentScreen(name)
                                                                                : close
                                                                        }
                                                                        trackClicks={trackClicks}
                                                                        trackElementShown={trackElementShown}
                                                                        url={dropdown ? undefined : url}
                                                                    >
                                                                        {translations.name}
                                                                    </SupernovaDropdownOption>
                                                                );
                                                        }
                                                    }
                                                )}
                                            </SupernovaDropdownSection>
                                        );
                                    default:
                                        return null;
                                }
                            }
                        )}
                    </div>
                    {subItems?.map((item) => {
                        return (
                            <MobileScreen
                                key={item.name}
                                currentScreen={currentScreen}
                                setDefaultScreen={setDefaultScreen}
                                close={close}
                                {...item}
                            />
                        );
                    })}
                </div>
            );
        },
        [
            isRedesignGeoExpActive,
            currentScreen,
            handleAreaSwitchClick,
            setDefaultScreen,
            subItems,
            unreadNotificationsCount,
        ]
    );

    /** Для соискателя на XS экранах уведомления прячутся в бургер меню, поэтому на нем нужно показать каунтер */
    const shouldShowCounter = isApplicant && unreadNotificationsCount > 0;

    if (isRedesignActive) {
        return (
            <MobileMagritte
                name={name}
                analytics={analytics}
                subItems={subItems}
                trackClicks={trackClicks}
                shouldShowCounter={shouldShowCounter}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                onClose={setDefaultScreen}
                onAreaSwitchClick={handleAreaSwitchClick}
            />
        );
    }

    return (
        <SupernovaNaviItem visibleOn={VISIBLE_ON}>
            <SupernovaDropdown
                activator={
                    <SupernovaActionLink
                        icon={<BarsOutlinedSize24 />}
                        showBadge={shouldShowCounter}
                        data-page-analytics-event="menu"
                        name={name}
                        analytics={analytics}
                        trackClicks={trackClicks}
                        data-qa={`mainmenu_${name}`}
                    />
                }
                anchorFullHeight
                arrowBreakpoints={ARROW_BREAKPOINTS}
                fullWidthBreakpoints={FULL_WIDTH_BREAKPOINTS}
                onClose={setDefaultScreen}
                onShown={() => setExpanded(true)}
                placement={Placement.BottomEnd}
                render={render}
            />
        </SupernovaNaviItem>
    );
};

export default Mobile;
