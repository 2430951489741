import { useMemo } from 'react';

import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    resumeAccess: 'employer.employerProfile.popup.informers.resumeAccess',
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
    access: {
        one: 'index.employer.billing.access.one',
        some: 'index.employer.billing.access.some',
        many: 'index.employer.billing.access.many',
    },
};

const ResumeAccessInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const resumeAccessCodes = billingInfo?.billingSummary?.serviceCategories?.resumeAccessCodes;

    const accessCount = useMemo(() => {
        if (!resumeAccessCodes) {
            return null;
        }
        const { count, remainingDays, days } = resumeAccessCodes;
        if (remainingDays) {
            return (
                <ConversionNumber
                    hasValue
                    one={trls[TrlKeys.days.one]}
                    some={trls[TrlKeys.days.many]}
                    many={trls[TrlKeys.days.some]}
                    value={remainingDays}
                />
            );
        }
        if (days) {
            return (
                <ConversionNumber
                    hasValue
                    one={trls[TrlKeys.days.one]}
                    some={trls[TrlKeys.days.many]}
                    many={trls[TrlKeys.days.some]}
                    value={days}
                />
            );
        }
        if (count) {
            return (
                <ConversionNumber
                    hasValue
                    one={trls[TrlKeys.access.one]}
                    some={trls[TrlKeys.access.many]}
                    many={trls[TrlKeys.access.some]}
                    value={count}
                />
            );
        }
        return null;
    }, [resumeAccessCodes, trls]);

    if (accessCount) {
        return (
            <Informer data-qa="resume-access-count" title={trls[TrlKeys.resumeAccess]}>
                {accessCount}
            </Informer>
        );
    }

    return null;
};

export default translation(ResumeAccessInformer);
