import { Button } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useHandleSwitchAccountClick from 'src/components/SupernovaMainMenu/hooks/useHandleSwitchAccountClick';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

const TrlKeys = {
    switchAccount: 'employer.employerProfile.popup.switchAccount',
};

const SwitchAccountButton: TranslatedComponent<NavItem> = ({ name, trls, trackClicks, analytics }) => {
    const handleSwitchAccountClick = useHandleSwitchAccountClick();
    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const handleClick = () => {
        handleTrackClick();
        handleSwitchAccountClick();
    };

    return (
        <Button
            data-qa="switch-account-button"
            icon={<ArrowRightOutlinedSize24 />}
            stretched
            onClick={handleClick}
            mode="secondary"
            style="accent"
            size="large"
        >
            {trls[TrlKeys.switchAccount]}
        </Button>
    );
};

export default translation(SwitchAccountButton);
