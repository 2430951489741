import { type FC, memo, useLayoutEffect } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const BannersLayer: FC = () => {
    const bannersBatchUrl = useSelector(({ bannersBatchUrl }) => bannersBatchUrl);

    useLayoutEffect(() => {
        if (bannersBatchUrl) {
            window.globalVars.bannersBatchUrl = bannersBatchUrl;
        }
    }, [bannersBatchUrl]);

    return (
        <template
            className="HHC-Banners-Place-Template"
            dangerouslySetInnerHTML={{
                __html: `<div
                class="HHC-Banners-Place HHC-Banner-%id% banner-place banner-place_%id% "
                data-banner-id="%id%"
                data-empty-class="banner-place_hide"
                data-loaded-class="banner-place_show"
                data-banner-adv-mark-trl="Реклама"
                data-banner-additional-class=""
            ></div>`,
            }}
        />
    );
};

export default memo(BannersLayer);
