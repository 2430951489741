import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { AnonymousOnboardingFilterChips } from 'src/models/anonymousOnboardingFilterChips';
import { AdsSearchParams } from 'src/models/banners/adsSearchParams';
import { Banner } from 'src/models/banners/banners';
import { ChatBot } from 'src/models/chatBot';
import NovaFilters, { NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { SearchCatalog } from 'src/models/searchCatalog';
import {
    setOrderArea,
    setOrderDistrict,
    setOrderIndustry,
    setOrderMetro,
    setOrderProfRole,
    setOrderSubIndustry,
} from 'src/models/searchClustersOrder';
import { replaceVacancySearchResult, VacancySearchResult } from 'src/models/vacancySearch/vacancySearchResult';
import UserLabel from 'src/utils/constants/userLabels';
import fetcher from 'src/utils/fetcher';

const searchCatalogAction = makeSetStoreField('searchCatalog');
const searchClustersAction = makeSetStoreField('searchClusters');
const searchSessionIdAction = makeSetStoreField('searchSessionId');
const bannersAction = makeSetStoreField('banners');
const userLabelsForVacanciesAction = makeSetStoreField('userLabelsForVacancies');
const adsSearchParamsAction = makeSetStoreField('adsSearchParams');
const chatBotAction = makeSetStoreField('chatBot');
const anonymousOnboardingFilterChipsAction = makeSetStoreField('anonymousOnboardingFilterChips');

const VACANCY_SEARCH_URL = '/shards/vacancy/search';

interface SearchVacanciesResponse {
    searchClustersOrder: Record<NovaFilterKey, string[]>;
    banners: Record<string, Banner[]>;
    chatBot: ChatBot;
    redirectCatalogPath?: string;
    searchCatalog: SearchCatalog | null;
    searchClusters: NovaFilters;
    vacancySearchResult: VacancySearchResult;
    userLabelsForVacancies: Record<string, UserLabel[]>;
    adsSearchParams: AdsSearchParams;
    anonymousOnboardingFilterChips: AnonymousOnboardingFilterChips;
}

declare global {
    interface FetcherGetApi {
        [VACANCY_SEARCH_URL]: {
            response: SearchVacanciesResponse;
            queryParams: undefined;
        };
    }
}

interface Params {
    dispatch: Dispatch;
    query: string;
    abortSignal?: AbortSignal;
}

type FetchVacancies = () => Promise<{ totalResults: number; redirect?: string }>;

const getFetchVacancies =
    ({ dispatch, query, abortSignal }: Params, push: Push): FetchVacancies =>
    async () => {
        let response;
        try {
            response = await fetcher.get(`${VACANCY_SEARCH_URL}?${query}` as typeof VACANCY_SEARCH_URL, {
                signal: abortSignal,
                params: undefined,
            });
        } catch (error) {
            return Promise.reject(error as Error);
        }

        // get search data
        const {
            searchCatalog,
            searchClusters,
            vacancySearchResult,
            userLabelsForVacancies,
            banners,
            adsSearchParams,
            redirectCatalogPath,
            searchClustersOrder,
            chatBot,
            anonymousOnboardingFilterChips,
        } = response;

        // custom catalog redirect
        if (redirectCatalogPath) {
            return { totalResults: 0, redirect: redirectCatalogPath };
        }

        // update url
        const { pathname } = location;
        const parsedUrl = urlParser(`${pathname}?${query}`);
        if (parsedUrl.params.searchSessionId) {
            delete parsedUrl.params.searchSessionId;
        }
        push(parsedUrl.href, undefined, { cancelFetchingData: true });

        // send search data
        const actions = [
            setOrderArea(searchClustersOrder?.[NovaFilterKey.Area] || []),
            setOrderDistrict(searchClustersOrder?.[NovaFilterKey.District] || []),
            setOrderMetro(searchClustersOrder?.[NovaFilterKey.Metro] || []),
            setOrderProfRole(searchClustersOrder?.[NovaFilterKey.ProfessionalRole] || []),
            setOrderIndustry(searchClustersOrder?.[NovaFilterKey.Industry] || []),
            setOrderSubIndustry(searchClustersOrder?.[NovaFilterKey.SubIndustry] || []),
            searchCatalogAction(searchCatalog),
            searchClustersAction(searchClusters),
            replaceVacancySearchResult(vacancySearchResult),
            bannersAction(banners),
            userLabelsForVacanciesAction(userLabelsForVacancies),
            adsSearchParamsAction(adsSearchParams),
            chatBotAction(chatBot),
            anonymousOnboardingFilterChipsAction(anonymousOnboardingFilterChips),
        ];
        const searchSessionId = vacancySearchResult.criteria?.[CriteriaKey.SearchSessionId];
        if (searchSessionId) {
            actions.push(searchSessionIdAction(searchSessionId));
        }
        dispatch(actions);

        return { totalResults: vacancySearchResult.totalResults };
    };

export default getFetchVacancies;
