import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface WithZpCrossPost {
    needToSetValue: boolean;
    value?: boolean;
}

const initialState: WithZpCrossPost = {
    needToSetValue: false,
};

export default autoGeneratedReducer<WithZpCrossPost>(initialState);
