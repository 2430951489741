import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import overspendingViewCheckboxClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/overspending_view_checkbox_click';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import ConversionNumber from 'bloko/blocks/conversion';
import { FormSpacer } from 'bloko/blocks/form';
import { H2 } from 'bloko/blocks/header';
import Link from 'bloko/blocks/link';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Notices from 'Modules/Notices';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import fetcher from 'src/utils/fetcher';

interface VacancyAutoUpdateInfo {
    vacancyId: number;
    vacancyName: string;
    autoProlongationsCount: number;
}

declare global {
    interface FetcherGetApi {
        '/shards/employer/overspending': {
            queryParams: void;
            response: { vacancyWithAutoUpdateViews: VacancyAutoUpdateInfo[] };
        };
    }
}

const TrlKeys = {
    dateTo: 'dateTo',
    vacancyMany: 'vacancy.many',
    vacancySome: 'vacancy.some',
    vacancyOne: 'vacancy.one',
    title: 'employer.overspending.vacancy.title',
    more: 'employer.overspending.vacancy.more',
    spent: 'employer.overspending.vacancy.spent',
    disable: 'employer.overspending.vacancy.disable',
    ok: 'employer.overspending.vacancy.ok',
    continue: 'employer.overspending.vacancy.continue',
    one: 'employer.overspending.vacancy.one',
    some: 'employer.overspending.vacancy.some',
    many: 'employer.overspending.vacancy.many',
    hide: 'employer.overspending.vacancy.hide',
    autoprolongation: 'employer.overspending.vacancy.autoprolongation',
    months: [
        'calendar.genitive.month.0',
        'calendar.genitive.month.1',
        'calendar.genitive.month.2',
        'calendar.genitive.month.3',
        'calendar.genitive.month.4',
        'calendar.genitive.month.5',
        'calendar.genitive.month.6',
        'calendar.genitive.month.7',
        'calendar.genitive.month.8',
        'calendar.genitive.month.9',
        'calendar.genitive.month.10',
        'calendar.genitive.month.11',
    ],
};

const get7DaysRangeTrl = (trls: Record<string, string>) => {
    const dateObj = new Date();
    const currentMonth = dateObj.getMonth();
    const currentDate = dateObj.getDate();
    dateObj.setDate(currentDate - 7);
    const previousDate = dateObj.getDate();
    const previousMonthTrl = previousDate > currentDate ? ` ${trls[TrlKeys.months[currentMonth - 1]]}` : '';
    return `${previousDate}${previousMonthTrl} ${trls[TrlKeys.dateTo]}${NON_BREAKING_SPACE}${currentDate} ${
        trls[TrlKeys.months[currentMonth]]
    }`;
};

const markModalViewed = (permanent = false) =>
    Notices.markAsViewed('employerOverspendingInfo', permanent && { permanent });

const EmployerOverspending: TranslatedComponent<SimpleNotificationComponentProps> = ({ trls, onClose }) => {
    const push = usePush();
    const pathname = useSelector(({ router }) => router.location.pathname);
    const [vacancyUpdateInfo, setVacancyUpdateInfo] = useState<VacancyAutoUpdateInfo[]>([]);
    const checkboxStateRef = useRef(false);

    useEffect(() => {
        fetcher.get('/shards/employer/overspending').then(({ vacancyWithAutoUpdateViews }) => {
            if (!vacancyWithAutoUpdateViews.length) {
                onClose();
                return;
            }
            markModalViewed();
            Analytics.sendHHEvent('overspending_modal_shown');
            setVacancyUpdateInfo(vacancyWithAutoUpdateViews);
        }, onClose);
    }, [onClose]);

    const hideModalHandler = useCallback(() => {
        markModalViewed(checkboxStateRef.current);
        Analytics.sendHHEventButtonClick('overspending_close', { hide: checkboxStateRef.current });
        onClose();
    }, [onClose]);

    const continueHandler = useCallback(() => {
        markModalViewed(checkboxStateRef.current);
        Analytics.sendHHEventButtonClick('overspending_continue', { hide: checkboxStateRef.current });
        onClose();
        if (pathname !== '/employer/vacancies') {
            push('/employer/vacancies');
        }
    }, [onClose, pathname, push]);

    const handleOnChangeModalViewed = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        checkboxStateRef.current = checked;
        overspendingViewCheckboxClick({ checked });
    };

    const [overspendingToDisplay, overspendingRest, overspendingRestVacanciesCount] = useMemo(
        () => [
            vacancyUpdateInfo.slice(0, 10),
            vacancyUpdateInfo.slice(10),
            vacancyUpdateInfo.slice(10).reduce((sum, { autoProlongationsCount }) => sum + autoProlongationsCount, 0),
        ],
        [vacancyUpdateInfo]
    );

    return (
        <Modal
            onClose={onClose}
            backgroundClick={false}
            closeButtonShow={false}
            escapePress={false}
            visible={vacancyUpdateInfo.length > 0}
        >
            <ModalHeader>
                <H2>
                    <ConversionNumber
                        value={vacancyUpdateInfo.length}
                        one={trls[TrlKeys.one]}
                        some={trls[TrlKeys.some]}
                        many={trls[TrlKeys.many]}
                        hasValue={false}
                        format={(trl) =>
                            format(trls[TrlKeys.title], {
                                '{0}': get7DaysRangeTrl(trls),
                                '{1}': `${vacancyUpdateInfo.length} ${trl}`,
                            })
                        }
                    />
                </H2>
            </ModalHeader>
            <ModalContent>
                <div className="employer-overspending-row">
                    <Text strong>{trls[TrlKeys.autoprolongation]}</Text>
                    <div className="employer-overspending-count">
                        <Text strong>{trls[TrlKeys.spent]}</Text>
                    </div>
                </div>
                {overspendingToDisplay.map(({ vacancyId, autoProlongationsCount, vacancyName }) => (
                    <div key={vacancyId} className="employer-overspending-row">
                        <div>
                            <Link href={`/vacancy/${vacancyId}`} target="_blank">
                                {vacancyName}
                            </Link>
                        </div>
                        <div>{autoProlongationsCount}</div>
                    </div>
                ))}
                {overspendingRest.length > 0 && (
                    <div className="employer-overspending-row">
                        <div>
                            {trls[TrlKeys.more]}{' '}
                            <ConversionNumber
                                value={overspendingRest.length}
                                one={trls[TrlKeys.vacancyOne]}
                                some={trls[TrlKeys.vacancySome]}
                                many={trls[TrlKeys.vacancyMany]}
                            />
                        </div>
                        <div>{overspendingRestVacanciesCount}</div>
                    </div>
                )}
                <div className="employer-overspending-info">{trls[TrlKeys.disable]}</div>
            </ModalContent>
            <ModalFooter>
                <div className="employer-overspending-footer-content">
                    <div>
                        <Checkbox onChange={handleOnChangeModalViewed}>{trls[TrlKeys.hide]}</Checkbox>
                    </div>
                    <div>
                        <VSpacing base={2} />
                        <Button appearance={ButtonAppearance.Outlined} onClick={hideModalHandler}>
                            {trls[TrlKeys.ok]}
                        </Button>
                        <FormSpacer>
                            <Button kind={ButtonKind.Primary} onClick={continueHandler}>
                                {trls[TrlKeys.continue]}
                            </Button>
                        </FormSpacer>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(EmployerOverspending);
