export enum AreaSwitcherScreens {
    TopLevelDomains = 'TopLevelDomains',
    SubdomainsByLetter = 'SubdomainsByLetter',
}

export interface AreaDomain {
    areaId?: number;
    name?: string;
    href?: string;
    isCurrentHost?: boolean;
    isCurrentDomain?: boolean;
}

export type TopLevelDomain = AreaDomain & {
    moreLength: number;
    subdomains: AreaDomain[];
};

export interface TopLevelDomainsData {
    topLevelDomains: TopLevelDomain[];
    currentDomainFirstLetter: string;
    currentDomainAreaId: number;
}

export interface AlphabetData {
    subdomainsByLetter: AreaDomain[][];
    currentTopLevelDomain: AreaDomain;
    firstLetters: string[];
    canChoseAnotherDomain: boolean;
    shouldShowAlphabet: boolean;
}
