import { FC, RefObject } from 'react';

import { BottomSheet, Drop } from '@hh.ru/magritte-ui';

import { NavItems } from 'src/models/supernovaNaviMenu';

import AccountActions from 'src/components/SupernovaMainMenu/EmployerProfile/AccountActions';
import EmployerPanel from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerPanel';
import EmployerProfileHeader from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerProfileHeader';
import EmployerProfilePopupContent from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerProfilePopupContent';
import PopupActions from 'src/components/SupernovaMainMenu/EmployerProfile/PopupActions';

import styles from './styles.less';

interface EmployerProfilePopupProps {
    activatorRef: RefObject<HTMLDivElement>;
    subItems?: NavItems;
    visible: boolean;
    onClose: () => void;
}

const EmployerProfilePopup: FC<EmployerProfilePopupProps> = ({ activatorRef, visible, onClose, subItems }) => {
    const content = (
        <div className={styles.profileContainer}>
            <PopupActions onClose={onClose} />
            <div className={styles.profileContent}>
                {(subItems || []).map(({ name, subItems }) => {
                    switch (name) {
                        case 'employerInfo':
                            return (
                                <div key={name}>
                                    <EmployerProfileHeader subItems={subItems} />
                                    <EmployerProfilePopupContent />
                                </div>
                            );
                        case 'employerPanel':
                            return <EmployerPanel key={name} subItems={subItems} />;
                        case 'accountActions':
                            return <AccountActions key={name} subItems={subItems} />;
                        default:
                            return null;
                    }
                })}
            </div>
        </div>
    );
    return (
        <>
            <Drop
                width={480}
                space={0}
                onClose={onClose}
                visible={visible}
                placement="bottom-right"
                activatorRef={activatorRef}
                style={{ marginTop: '4px', marginLeft: '-12px' }}
                forcePlacement
                ignoreIntersections
            >
                {content}
            </Drop>
            <BottomSheet onClose={onClose} withContentPaddings={false} visible={visible}>
                {content}
            </BottomSheet>
        </>
    );
};

export default EmployerProfilePopup;
