import { HhtmSourceLabel } from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_content_element_shown';
import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_AUCTION_HHTM_SOURCE_LABEL = 'SET_AUCTION_HHTM_SOURCE_LABEL';

export type AuctionHhtmlSourceLabel = HhtmSourceLabel | undefined | null;
interface PayloadAuctionDataTypes {
    [SET_AUCTION_HHTM_SOURCE_LABEL]: AuctionHhtmlSourceLabel;
}

const actionCreator = ActionCreatorHelper<PayloadAuctionDataTypes>();

export const setAuctionHhtmlSourceLabel = actionCreator(SET_AUCTION_HHTM_SOURCE_LABEL);

export default createReducer<AuctionHhtmlSourceLabel, PayloadAuctionDataTypes>(null, {
    [SET_AUCTION_HHTM_SOURCE_LABEL]: (_, { payload }) => {
        return payload;
    },
});
