import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { BrandSnippetEdit } from 'src/models/brandSnippetEditor/brandSnippet.types';

const SET_EDITABLE_BRAND_SNIPPET = 'SET_EDITABLE_BRAND_SNIPPET';
const SET_EDITABLE_SNIPPET_NAME = 'SET_EDITABLE_SNIPPET_NAME';
const SET_EDITABLE_SNIPPET_FIELD = 'SET_EDITABLE_SNIPPET_FIELD';

type SingleKeyOf<T> = keyof T extends infer K ? (K extends keyof T ? Pick<T, K> : never) : never;

interface PayloadTypes {
    [SET_EDITABLE_BRAND_SNIPPET]: BrandSnippetEdit;
    [SET_EDITABLE_SNIPPET_NAME]?: string;
    [SET_EDITABLE_SNIPPET_FIELD]: SingleKeyOf<BrandSnippetEdit>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setEditableBrandSnippet = actionCreator(SET_EDITABLE_BRAND_SNIPPET);
export const setEditableSnippetName = actionCreator(SET_EDITABLE_SNIPPET_NAME);
export const setEditableSnippetField = actionCreator(SET_EDITABLE_SNIPPET_FIELD);

export interface BrandSnippetEditor {
    employerId: string;
    templates?: {
        id: number;
        name: string;
    }[];
    editableSnippet?: BrandSnippetEdit;
    editableSnippetName?: string;
}

export default createReducer<BrandSnippetEditor, PayloadTypes>(null, {
    [SET_EDITABLE_BRAND_SNIPPET]: (state, { payload }) => ({ ...state, editableSnippet: payload }),
    [SET_EDITABLE_SNIPPET_NAME]: (state, { payload }) => ({ ...state, editableSnippetName: payload }),
    [SET_EDITABLE_SNIPPET_FIELD]: (state, { payload }) => ({
        ...state,
        editableSnippet: { ...(state.editableSnippet || {}), ...payload },
    }),
});
