import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ResumeBaseFields } from 'src/models/resume/resume.types';
import { Language } from 'src/models/resume/resumeCommon.types';

import { ResumeFieldValueList } from 'src/models/resumeForm/fields.types';

type DefaultFields =
    | 'area'
    | 'citizenship'
    | 'educationLevel'
    | 'email'
    | 'employment'
    | 'firstName'
    | 'lastName'
    | 'keySkills'
    | 'middleName'
    | 'professionalRole'
    | 'relocation'
    | 'salary'
    | 'workSchedule'
    | 'workTicket';

type ResumeFormDefaultsForWrap = Pick<ResumeBaseFields, DefaultFields>;
type CustomFieldForWrap = Pick<ResumeBaseFields, 'language' | 'phone'>;
type ResumeFormDefaults = {
    [Property in keyof ResumeFormDefaultsForWrap]: ResumeFieldValueList<ResumeFormDefaultsForWrap[Property]>;
} & {
    [Property in keyof CustomFieldForWrap]: CustomFieldForWrap[Property][];
} & {
    nativeLanguage: Language[];
};

export default autoGeneratedReducer<Partial<ResumeFormDefaults>>({});
