let count = 0;
/**
 * Generates unique string with prefix.
 * @param {String} prefix
 * @returns {String} unique identifier
 */
// eslint-disable-next-line no-plusplus
export const generateId = (prefix = 'uid'): string => `${prefix}${count++}`;

/**
 * Generates unique ID for DOM element for IE returns native uniqID.
 * @param {Object} element DOM element
 * @returns {String} unique identifier
 */
export const getUniqueId = (element: HTMLElement & { uniqueID?: string }): string => {
    if (!element.uniqueID) {
        element.uniqueID = generateId();
    }
    return element.uniqueID;
};

/**
 * Замена <br> на символы новой строки
 * @param  {Element} element Элемент
 * @returns {String}
 */
export const br2nl = ({ childNodes }: Node): string =>
    [...childNodes]
        .map((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.nodeValue?.replace(/\n+/g, '');
            }
            if (node.nodeType === Node.ELEMENT_NODE) {
                return node.nodeName === 'BR' ? '\n' : br2nl(node);
            }
            return '';
        })
        .join('');

export const isObject = (val: unknown): val is Record<string, unknown> =>
    val != null && !Array.isArray(val) && typeof val === 'object';

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};

export const isNumber = (value: unknown): value is number => {
    return typeof value === 'number';
};

// Приводит объекты вида {foo: { bar: { baz: 123 } }} к {foo.bar.baz: 123} для получения ключей валидации
export const treeToKeys = (object: unknown, preKey = ''): Record<string, unknown> => {
    if (!isObject(object)) {
        return {
            [preKey]: object,
        };
    }
    return Object.entries(object).reduce((acc, [key, subObject]) => {
        const newKey = `${preKey}${key}${isObject(subObject) ? '.' : ''}`;
        const objectToMerge = treeToKeys(subObject, newKey);
        return { ...acc, ...objectToMerge };
    }, {});
};
