import { Title, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import MobileAppButton from 'src/components/MobileAppButton';
import translation from 'src/components/translation';
import { StayInTouchApp } from 'src/models/stayInTouch';

import styles from './footer-mobile-banner-redesign.less';

interface AppBannerStoreButtonsProps {
    app: StayInTouchApp;
}

const TrlKeys = {
    title: 'mobile.index.app.title',
    subtitle: 'mobile.index.app.subtitle',
};

const getAppLinkHref = (appHref: string) => {
    const parsedUrl = urlParser(appHref);
    parsedUrl.params = { ...parsedUrl.params, footer: '' };

    return parsedUrl.href;
};

const AppBannerStoreRedesign: TranslatedComponent<AppBannerStoreButtonsProps> = ({ trls, app }) => {
    const { isMobile } = useBreakpoint();
    const { name, href } = app;

    return (
        <div className={styles.footerAppBannerRedesignStore}>
            <Title
                Element="h2"
                size="medium"
                alignment={isMobile ? 'center' : 'left'}
                description={trls[TrlKeys.subtitle]}
            >
                {trls[TrlKeys.title]}
            </Title>
            {isMobile && <VSpacing default={24} />}
            <div className={styles.footerAppBannerRedesignStoreButtons}>
                <MobileAppButton name={name} appLink={getAppLinkHref(href)} />
            </div>
            {isMobile && <VSpacing default={32} />}
        </div>
    );
};

export default translation(AppBannerStoreRedesign);
