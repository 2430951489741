import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

export interface BlacklistEmployerItem {
    id: string;
    name: string;
    vacanciesNumber: string;
}

export interface ApplicantBlacklistEmployers {
    list: BlacklistEmployerItem[];
    found: string;
    paging?: Paging;
}

const REMOVE_EMPLOYER = 'REMOVE_EMPLOYER';
const REMOVE_ALL_EMPLOYERS = 'REMOVE_ALL_EMPLOYER';

interface PayloadTypes {
    [REMOVE_EMPLOYER]: { employerId: string };
    [REMOVE_ALL_EMPLOYERS]: void;
}

const INITIAL_STATE: ApplicantBlacklistEmployers = {
    paging: undefined,
    list: [],
    found: '0',
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const removeEmployer = actionCreator(REMOVE_EMPLOYER);
export const removeAllEmployers = actionCreator(REMOVE_ALL_EMPLOYERS);

export default createReducer<ApplicantBlacklistEmployers, PayloadTypes>(INITIAL_STATE, {
    [REMOVE_EMPLOYER]: (state, action) => {
        const { employerId } = action.payload;
        const newList = state.list.filter((employer) => employer.id !== employerId);

        return {
            ...state,
            found: String(Number(state.found) - 1),
            list: newList,
        };
    },
    [REMOVE_ALL_EMPLOYERS]: () => INITIAL_STATE,
});
