import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import EmployerProfileInformers from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerProfileInformers';
import WaitForActivationCard from 'src/components/SupernovaMainMenu/EmployerProfile/WaitForActivationCard';
import useEmployerActivationCartsCount from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerActivationCartsCount';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';

import styles from './styles.less';

const EmployerProfilePopupContent: FC = () => {
    const { billingInfo } = useEmployerBillingInfo();
    const { isLoading: isActivationCountLoading, activationCount } = useEmployerActivationCartsCount();

    const isLoading = !billingInfo?.employer || isActivationCountLoading;
    return (
        <div className={styles.popupContent}>
            <VSpacingContainer default={16}>
                <WaitForActivationCard isLoading={isLoading} count={activationCount} />
                <EmployerProfileInformers isLoading={isLoading} />
            </VSpacingContainer>
        </div>
    );
};

export default EmployerProfilePopupContent;
