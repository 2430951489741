import { ComponentType } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

const TrlKeys = {
    fail: 'zp.importResume.error.fail',
    again: 'zp.importResume.error.again',
};

const ErrorStep: ComponentType = translation(({ trls }) => {
    return (
        <>
            <Text typography="subtitle-1-semibold">{trls[TrlKeys.fail]}</Text>
            <VSpacing default={8} />
            <Text style="secondary">{trls[TrlKeys.again]}</Text>
        </>
    );
});

export default ErrorStep;
