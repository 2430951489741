import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VacancyTestTask {
    id: number;
    description: string;
    multiple: string;
    open: string;
    candidateSolutions: {
        id: string;
        text: string;
    }[];
}

export interface VacancyTest {
    uidPk: number;
    guid: string;
    name: string;
    description: string;
    required: string;
    startTime: string;
    tasks: VacancyTestTask[];
}

export default autoGeneratedReducer<Record<number, VacancyTest>>({});
