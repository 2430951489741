export enum CurrencyType {
    USD = 'USD',
    AZN = 'AZN',
    KZT = 'KZT',
    UAH = 'UAH',
    BYR = 'BYR',
    RUR = 'RUR',
    EUR = 'EUR',
    KGS = 'KGS',
    UZS = 'UZS',
    GEL = 'GEL',
}
