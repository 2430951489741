import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ProductIdType, RegionIdType, BillingPlatform } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export interface Aspe {
    id: string;
    serviceEntry: ServiceItemCode;
    entriesCount: number;
    balance: number;
    billingPlatforms?: BillingPlatform[];
}

export interface EmployerService {
    productId: ProductIdType;
    employerServiceId: string;
    shortTitle: string;
    aspes: Aspe[];
    creationTime: string;
    expirationTime: string;
    activationTime: string;
    region: RegionIdType;
}

export interface PriceAddContacts {
    activeAccesses: EmployerService[];
    dbAccessUrl: string;
    purchaseUrl: string;
    tabName: string;
}

export default autoGeneratedReducer<PriceAddContacts>({
    tabName: '',
    purchaseUrl: '',
    dbAccessUrl: '',
    activeAccesses: [],
});
