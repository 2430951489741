import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface BrandingPromotion {
    hasBrandedTemplates: boolean;
    hasBrandingChameleonService: boolean;
    hasBrandingConstructorService: boolean;
    possibleToBuyBranding: boolean;
}

export interface BrandingPromotionWithTemplate extends BrandingPromotion {
    isBrandingTemplateApplied?: boolean;
}

export default autoGeneratedReducer<BrandingPromotion>(null);
