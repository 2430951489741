import React, { forwardRef, ComponentPropsWithoutRef, ForwardRefRenderFunction } from 'react';

import { useSelector } from 'src/hooks/useSelector';

interface StaticImgProps extends ComponentPropsWithoutRef<'img'> {
    path: string;
}

const StaticImg: ForwardRefRenderFunction<HTMLImageElement, StaticImgProps> = ({ path, ...imgProps }, ref) => {
    const staticHost = useSelector(({ config }) => config.staticHost);
    return <img src={`${staticHost}${path}`} {...imgProps} ref={ref} />;
};

export default forwardRef(StaticImg);
