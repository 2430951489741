import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import NotificationLayout from 'src/components/SupernovaMainMenu/UserNotifications/Notification/Layout';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    content: 'supernova.notification.autoSearches.1',
    link: 'supernova.notification.autoSearches.2',
};

const EmptyAutoSearchVacancy: TranslatedComponent = ({ trls }) => {
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);

    if (!isApplicant) {
        return null;
    }

    return (
        <NotificationLayout iconKind={SupernovaUserNotificationsIconKind.Empty} dataQa={DataQaInvariants.Empty}>
            <NotificationSpacer>{trls[TrlKeys.content]}</NotificationSpacer>
            <NotificationLink to="/applicant/autosearch">{trls[TrlKeys.link]}</NotificationLink>
        </NotificationLayout>
    );
};

export default translation(EmptyAutoSearchVacancy);
