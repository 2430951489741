import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AccountConnectResponse {
    code?: number;
    enteredEmail?: string;
    errors?: {
        login: string;
        code: number;
        captcha: string;
        generalErrorTrl: string;
    };
    login?: string;
    isNativeLoginAvailable?: boolean;
    isSocialLoginAvailable?: boolean;
}

export default autoGeneratedReducer<AccountConnectResponse>({});
