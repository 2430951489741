import { type FC } from 'react';

import CacheTranslationsContext from 'src/components/translation/CacheTranslationsContext';

import BlokoNotifications from 'src/components/Notifications/Provider/BlokoNotifications';
import MagritteNotifications from 'src/components/Notifications/Provider/MagritteNotifications';
import type { RemoveNotification, StoredNotification } from 'src/components/Notifications/Provider/types';

const isBlokoNotification = (notification: StoredNotification) => {
    return 'Element' in notification.component;
};
const isMagritteNotification = (notification: StoredNotification) => {
    return typeof notification.component === 'function';
};

const Notifications: FC<{ notifications: StoredNotification[]; removeNotificationByIdAction: RemoveNotification }> = ({
    notifications,
    removeNotificationByIdAction,
}) => {
    return (
        <CacheTranslationsContext.Provider value={true}>
            <BlokoNotifications
                notifications={notifications.filter(isBlokoNotification)}
                removeNotificationByIdAction={removeNotificationByIdAction}
            />
            <MagritteNotifications
                notifications={notifications.filter(isMagritteNotification)}
                removeNotificationByIdAction={removeNotificationByIdAction}
            />
        </CacheTranslationsContext.Provider>
    );
};

export default Notifications;
