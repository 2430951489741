import createReducer, { autoGeneratedReducer, ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Sort } from 'src/models/employerVacancies/sort';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';

export interface BaseFilterOption {
    value: string;
    label: string;
}

interface ArchivedVacanciesFilters {
    areaFilter: BaseFilterOption[];
    managerFilter: BaseFilterOption[];
    archivationTypeFilter: BaseFilterOption[];
    approvalStatusFilter?: BaseFilterOption[];
    vacancyPublicationFormatFilter?: BaseFilterOption[];
    vacancyVisibilityFilter?: BaseFilterOption[];
}

export interface ArchivedVacancy
    extends Omit<
        Vacancy,
        'archivationTime' | 'negotiations' | 'daysBeingPublic' | 'creationTime' | 'freeRestoreExpireTime'
    > {
    daysBeingPublic: number | null;
    notProcessedResponsesCount: Vacancy['negotiations']['notProcessedResponsesCount'];
    responsesCount: Vacancy['negotiations']['responsesCount'];
    topicsCount: Vacancy['negotiations']['topicsCount'];
    metallicTrl: string;
    type: 'open' | 'closed' | 'anonymous' | 'advertising';
    readOnly: { daysBeforeReadOnly: number | null; value: boolean };
    creationTime: {
        javaTime: string;
    } | null;
    archivationTime: {
        javaTime: string;
    } | null;
    freeRestoreExpireTime: {
        javaTime: string;
    } | null;
    zpCrosspost: boolean;
    hidden: boolean;
    freeRestoreDays: number;
}

type ArchivedVacanciesSearchResults = ArchivedVacancy[];
export type ArchivationType = 'ALL' | 'ARCHIVED' | 'DELETED_BY_CLIENT' | 'DELETED_BY_MODERATOR';
export type VacancyVisibility = 'ALL' | 'OPEN' | 'CLOSE';

interface ArchivedVacanciesSearchParams {
    query: string | null;
    employerId?: string;
    selectedManagerId: string;
    selectedAreaId: string;
    visible: string;
    selectedArchivationType: ArchivationType;
    selectedVacancyVisibility: VacancyVisibility;
    selectedApprovalStatus: string;
    selectedVacancyClassifyingProperties: string;
    order: Sort;
    page: string;
    itemsOnPage: string;
    default: boolean;
}

export interface ArchivedVacancies {
    canCreateVacancies: boolean;
    count: number;
    hasHidden: boolean;
    vacancies: ArchivedVacanciesSearchResults;
    searchParams: ArchivedVacanciesSearchParams;
    fetching: boolean;
}

const DEFAULT_SEARCH_PARAMS: ArchivedVacanciesSearchParams = {
    query: null,
    selectedManagerId: 'ALL',
    selectedAreaId: 'ALL',
    visible: 'false',
    selectedArchivationType: 'ALL',
    selectedApprovalStatus: 'approvalStatusFilter',
    selectedVacancyClassifyingProperties: 'ALL',
    selectedVacancyVisibility: 'ALL',
    order: Sort.ByArchivationTimeDesc,
    page: '0',
    itemsOnPage: '50',
    default: false,
};

const CHANGE_ARCHIVED_VACANCIES = 'CHANGE_ARCHIVED_VACANCIES';
const START_FETCHING_ARCHIVED_VACANCIES = 'START_FETCHING_ARCHIVED_VACANCIES';
const STOP_FETCHING_ARCHIVED_VACANCIES = 'STOP_FETCHING_ARCHIVED_VACANCIES';

interface PayloadTypes {
    [CHANGE_ARCHIVED_VACANCIES]: ArchivedVacancies;
    [START_FETCHING_ARCHIVED_VACANCIES]: void;
    [STOP_FETCHING_ARCHIVED_VACANCIES]: void;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const changeArchivedVacancies = ActionCreator(CHANGE_ARCHIVED_VACANCIES);
export const startFetchingArchivedVacancies = ActionCreator(START_FETCHING_ARCHIVED_VACANCIES);
export const stopFetchingArchivedVacancies = ActionCreator(STOP_FETCHING_ARCHIVED_VACANCIES);

export default {
    archivedVacancies: createReducer<ArchivedVacancies, PayloadTypes>(
        {
            canCreateVacancies: false,
            count: 0,
            hasHidden: false,
            vacancies: [],
            searchParams: DEFAULT_SEARCH_PARAMS,
            fetching: false,
        },
        {
            [CHANGE_ARCHIVED_VACANCIES]: (state, action) => ({ ...state, ...action.payload }),
            [START_FETCHING_ARCHIVED_VACANCIES]: (state) => ({ ...state, fetching: true }),
            [STOP_FETCHING_ARCHIVED_VACANCIES]: (state) => ({ ...state, fetching: false }),
        }
    ),
    archivedVacanciesFilters: autoGeneratedReducer<ArchivedVacanciesFilters>({
        areaFilter: [],
        managerFilter: [],
        archivationTypeFilter: [],
    }),
    archivedVacanciesSearchParams: autoGeneratedReducer<ArchivedVacanciesSearchParams>(null),
};
