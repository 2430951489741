import { FC, PropsWithChildren } from 'react';

import styles from './counter.less';

const Counter: FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.counter} data-qa="loyalty-survey-survey-popup-counter">
        {children}
    </div>
);
export default Counter;
