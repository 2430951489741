import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'supernova.notification.interview.practice.waiting_for_call',
    link: 'supernova.notification.interview.practice.waiting_for_call.link',
};

const InterviewPracticeWaitingForCall: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification
        dataQa={DataQaInvariants.InterviewPracticeWaitingForCall}
        iconKind={SupernovaUserNotificationsIconKind.Info}
        {...notification}
    >
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.first], {
                '{0}': notification.params.callTimeDate,
                '{1}': notification.params.callTimeBegin,
                '{2}': notification.params.callTimeEnd,
            })}
            <NotificationLink
                to={`/applicant-services/complete_resume_interview_practice/order/${
                    notification.params.orderCode || ''
                }`}
            >
                {trls[TrlKeys.link]}
            </NotificationLink>
        </NotificationSpacer>
    </Notification>
);

export default translation(InterviewPracticeWaitingForCall);
