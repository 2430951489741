import Analytics from '@hh.ru/analytics-js';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import Metrics from 'bloko/common/metrics';
import { TypeElementMetrics } from 'bloko/common/types';

const MINIMUM_NOTIFICATIONS_COUNT_FOR_ANALYTICS = 2;

interface NotificationMetrics {
    count: number;
    viewportHeight: number;
    notificationManagerBottom: number;
    breakpoint: Breakpoint;
}

export default function sendNotificationManagerAnalytics(notificationManagerElement: Element): void {
    let notificationMetrics: NotificationMetrics;
    let attemptsLeft = 3;

    const setNotificationMetrics = () => {
        const shownNotificationsCount = notificationManagerElement.children.length;
        if (
            shownNotificationsCount < MINIMUM_NOTIFICATIONS_COUNT_FOR_ANALYTICS ||
            (notificationMetrics && notificationMetrics.count > shownNotificationsCount)
        ) {
            return;
        }

        notificationMetrics = {
            count: shownNotificationsCount,
            viewportHeight: Metrics.getViewportMetrics().height,
            // Т.к. у Bloko-Notification-Manager нулевая высота - вычисляем bottom у последнего уведомления
            notificationManagerBottom: Metrics.getMetrics(
                notificationManagerElement.lastElementChild as TypeElementMetrics
            ).bottom,
            breakpoint: getBreakpoint(),
        };
    };

    function sendNotificationMetricsAnalytics() {
        setTimeout(() => {
            attemptsLeft -= 1;
            setNotificationMetrics();
            if (attemptsLeft === 0) {
                notificationMetrics && Analytics.sendHHEvent('initial-notifications-shown', notificationMetrics);
                return;
            }
            sendNotificationMetricsAnalytics();
        }, 3000);
    }
    sendNotificationMetricsAnalytics();
}
