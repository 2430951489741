import {
    EmploymentForm,
    FlyInFlyOutDuration,
    WorkFormat,
    WorkingHours,
    WorkScheduleByDays,
} from '@hh.ru/types-hh-microcore';

interface EmploymentFieldsDictionary<T> {
    id: T;
    text: string;
}

export interface VacancyEmploymentFieldsDictionary {
    employmentForm: EmploymentFieldsDictionary<EmploymentForm>[];
    flyInFlyOutDurations: EmploymentFieldsDictionary<FlyInFlyOutDuration>[];
    workFormats: EmploymentFieldsDictionary<WorkFormat>[];
    workingHours: EmploymentFieldsDictionary<WorkingHours>[];
    workScheduleByDays: EmploymentFieldsDictionary<WorkScheduleByDays>[];
}

export const DEFAULT_VACANCY_EMPLOYMENT_FIELDS_DICTIONARY = {
    employmentForm: [],
    flyInFlyOutDurations: [],
    workFormats: [],
    workingHours: [],
    workScheduleByDays: [],
};
