import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'supernova.notification.career.consult.paid',
    second: 'supernova.notification.career.consult.paid.service.link',
    link: 'supernova.notification.career.consult.paid.fill.link',
};

const CareerConsultPaid: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification dataQa={DataQaInvariants.CareerConsultPaid} {...notification}>
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.first], {
                '{0}': <NotificationLink to="/career_consult">{trls[TrlKeys.second]}</NotificationLink>,
            })}
        </NotificationSpacer>
        <NotificationLink
            to={`/applicant-services/complete_resume_consult_only/order/${notification.params.orderCode || ''}`}
        >
            {trls[TrlKeys.link]}
        </NotificationLink>
    </Notification>
);

export default translation(CareerConsultPaid);
