import { UXFeedback } from 'src/utils/uxfeedback';

const getUxFeedbackEventName = (): string => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `close_chat_${window.globalVars.userType}`;
};

export const triggerUxFeedback = (): void => {
    UXFeedback.sendEvent(getUxFeedbackEventName());
};
