import React from 'react';

interface SupernovaDropdownSectionHeaderProps {
    onClick: React.MouseEventHandler;
}

const SupernovaDropdownSectionHeader: React.FC<SupernovaDropdownSectionHeaderProps & React.PropsWithChildren> = ({
    children,
    onClick,
}) => {
    return (
        <div className="supernova-dropdown-section-header">
            <div className="supernova-dropdown-section-header__back" onClick={onClick} />
            {children}
        </div>
    );
};

export default SupernovaDropdownSectionHeader;
