import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum UserType {
    EmployerUser = 'EMPLOYER_USER',
    ApplicantUser = 'APPLICANT_USER',
}

export interface Participant {
    id: number;
    externalId: string;
    type: UserType;
    isCurrentUser: boolean;
    display: { name: string };
}

export enum MessageTypes {
    Simple = 'SIMPLE',
    ParticipantJoined = 'PARTICIPANT_JOINED',
    ParticipantLeft = 'PARTICIPANT_LEFT',
}

export interface MessageItem {
    id: number;
    participant: Participant;
    text: string;
    creationTime: string;
    lastViewedByApplicant?: boolean;
    viewed?: boolean;
    type: MessageTypes;
}

export interface Messages {
    hasMore: boolean;
    items: MessageItem[];
}

export interface GroupedMessages {
    participantType: UserType;
    messages: MessageItem[];
}

export default autoGeneratedReducer<Messages>({
    hasMore: false,
    items: [],
});
