import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'supernova.notification.complete.resume.waiting_for_call',
    serviceLink: 'supernova.notification.complete.resume.service.link',
};

const CompleteResumeWaitingForCall: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification
        dataQa={DataQaInvariants.CompleteResumeWaitingForCall}
        iconKind={SupernovaUserNotificationsIconKind.Info}
        {...notification}
    >
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.first], {
                '{0}': notification.params.callTimeDate,
                '{1}': notification.params.callTimeBegin,
                '{2}': notification.params.callTimeEnd,
                '{3}': <NotificationLink to="/expert_resume/">{trls[TrlKeys.serviceLink]}</NotificationLink>,
            })}
        </NotificationSpacer>
        <NotificationLink to={`/applicant-services/complete_resume/order/${notification.params.orderCode || ''}`}>
            {trls[TrlKeys.serviceLink]}
        </NotificationLink>
    </Notification>
);

export default translation(CompleteResumeWaitingForCall);
