import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface FolderContent {
    canChangeFolderOwnership: boolean;
    isTrash: boolean;
    managersCount: number;
    owner: {
        firstname: string;
        id: number;
        lastname: string;
        middlename: string;
    };
    resumeIds: number[];
    visibleTo: number[];
}

export default autoGeneratedReducer<FolderContent>(null);
