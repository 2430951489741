import { useRef, useEffect } from 'react';

import failedShown, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/failed_web_call_element_shown';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    failTitle: 'webcall.notification.fail.title',
    failDescription: 'webcall.notification.fail.description',
    micDisabledTitle: 'webcall.notification.micDisabled.title',
    micDisabledDescription: 'webcall.notification.micDisabled.description',
};

export interface WebcallFailedToCallProps {
    onClose: () => void;
    hhtmSource: HhtmSource;
    topicId: string;
    resumeId: number;
    webCallSessionId: string | null;
    error: string;
}

const WebcallFailedToCall: TranslatedComponent<WebcallFailedToCallProps> = ({
    trls,
    hhtmSource,
    topicId,
    resumeId,
    webCallSessionId,
    error,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        failedShown(ref.current as HTMLDivElement, {
            hhtmSource,
            resumeId,
            topicId,
            webCallSessionId,
            errorType: error,
        });
    }, [error, hhtmSource, resumeId, topicId, webCallSessionId]);

    return (
        <div ref={ref}>
            <NotificationHeading>{trls[TrlKeys.failTitle]}</NotificationHeading>
            <Text>{trls[TrlKeys.failDescription]}</Text>
        </div>
    );
};

export const webcallFailedToCall = {
    Element: translation(WebcallFailedToCall),
    kind: 'error',
    onClose: (_: unknown, { onClose }: WebcallFailedToCallProps): void => onClose(),
};
