import Analytics from '@hh.ru/analytics-js';

export const mainMenuItemClick = (item: string): void => {
    Analytics.sendHHEventButtonClick(`main_menu_item_${item}`);
};

export const mainMenuItemsShown = (element: HTMLElement, items: string[]): void => {
    Analytics.sendHHEventElementShown(element, {
        name: 'main_menu_items',
        item: items,
    });
};

export const mainMenuSubItemsShown = (items: string[]): void => {
    Analytics.sendHHEvent('element_shown', {
        elementName: 'main_menu_sub_items',
        item: items,
    });
};

export default {
    mainMenuItemClick,
    mainMenuItemsShown,
    mainMenuSubItemsShown,
};
