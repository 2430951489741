import { FC, useRef } from 'react';

import { Card, Cell, CellText, Radio, useBreakpoint, CardProps, Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import SupernovaModals from 'src/components/SupernovaMainMenu/SupernovaModals';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useTrackElementShown from 'src/components/SupernovaMainMenu/useTrackElementShown';
import { NavItem } from 'src/models/supernovaNaviMenu';

const SupernovaMagritteDropdownItem: FC<NavItem & { onClick?: () => void; onClose?: () => void }> = ({
    name,
    url,
    analytics,
    trackClicks,
    translations,
    onClick,
    trackElementShown,
    modalType,
    subItems,
    onClose,
}) => {
    const elementRef = useRef(null);
    const { isMobile } = useBreakpoint();

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const handleClick = () => {
        onClick && onClick();
        handleTrackClick();
    };

    useTrackElementShown(elementRef, name, trackElementShown);

    const content = (
        <Cell Element="div" right={isMobile ? <Radio /> : undefined}>
            {isMobile ? (
                <CellText>{translations.name}</CellText>
            ) : (
                <Text typography="label-3-regular">{translations.name}</Text>
            )}
        </Cell>
    );

    const cardProps: Partial<CardProps> = isMobile
        ? {
              padding: 16,
              borderRadius: 16,
              showBorder: true,
          }
        : {
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
          };

    if (url) {
        return (
            <Card
                {...cardProps}
                ref={elementRef}
                stretched
                onClick={handleClick}
                isSeoLink
                to={url}
                Element={Link}
                actionCard
            >
                {content}
            </Card>
        );
    }

    return (
        <>
            <Card {...cardProps} ref={elementRef} stretched onClick={handleClick} actionCard>
                {content}
            </Card>
            {modalType && (
                <SupernovaModals
                    onClose={onClose}
                    modalType={modalType}
                    activatorRef={elementRef}
                    subItems={subItems}
                />
            )}
        </>
    );
};

export default SupernovaMagritteDropdownItem;
