import { createContext } from 'react';

import type {
    StoredNotification,
    AddNotification,
    AddAction,
    RemoveAction,
    RemoveByComponentAction,
    RemoveNotificationByComponent,
    RemoveNotification,
} from 'src/components/Notifications/Provider/types';

export const NotificationContext = createContext<{
    notifications: StoredNotification[];
    addNotification: AddNotification;
    removeNotification: RemoveNotificationByComponent;
    removeNotificationByIdAction: RemoveNotification;
}>({
    notifications: [],
    addNotification: () => void 0,
    removeNotification: () => void 0,
    removeNotificationByIdAction: () => void 0,
});

let id = 0;

const reducer = (
    state: StoredNotification[],
    action: AddAction | RemoveAction | RemoveByComponentAction
): StoredNotification[] => {
    switch (action.type) {
        case 'ADD':
            if (
                action.payload.uniqueType &&
                state.find((notification) => notification.component === action.payload.component)
            ) {
                return state;
            }

            id += 1;
            return [...state, { ...action.payload, id }];
        case 'REMOVE_BY_ID':
            return state.filter(({ id }) => id !== action.payload.id);
        case 'REMOVE_BY_COMPONENT':
            return state.filter((notification) => notification.component !== action.payload);
        default:
            return state;
    }
};

export default reducer;
