import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_VR_SIGNUP_LOGIN_FINGERPRINTS = 'SET_VR_SIGNUP_LOGIN_FINGERPRINTS';

interface PayloadTypes {
    [SET_VR_SIGNUP_LOGIN_FINGERPRINTS]: Record<string, unknown>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setVrSingUpLoginFingerprints = actionCreator(SET_VR_SIGNUP_LOGIN_FINGERPRINTS);

export interface VrSignupLogin {
    alreadyLoggedIn: boolean;
    vrResponseHash: string;
    forceResumeCreation: boolean;
    resumes?: ResumeIdentifiersData[];
    vacancyTitle: string;
    vacancyId: number | null;
    vrLeadData?: VrLeadData;
    alreadyHasNegotiationAttempt: boolean;
    fingerprints: Record<string, unknown>;
    profRolesFromSurvey?: string[];
}

const initialState = {
    alreadyLoggedIn: false,
    vrResponseHash: '',
    forceResumeCreation: false,
    vacancyTitle: '',
    vacancyId: null,
    alreadyHasNegotiationAttempt: false,
    fingerprints: {},
};

export enum VrLeadCheckFields {
    Phone = 'phone',
    Citizenship = 'citizenship',
    FirstName = 'firstName',
    LastName = 'lastName',
}

export interface VrLeadData {
    [VrLeadCheckFields.FirstName]: string;
    [VrLeadCheckFields.LastName]: string;
    [VrLeadCheckFields.Phone]: string;
    [VrLeadCheckFields.Citizenship]?: number;

    [field: string]: unknown;
}

export interface ResumeIdentifiersData {
    hash: string;
    title: string;
}

export interface ShortResumeData {
    [VrLeadCheckFields.FirstName]: string;
    [VrLeadCheckFields.LastName]: string;
    [VrLeadCheckFields.Phone]?: { raw: string; [field: string]: unknown }[];
    [VrLeadCheckFields.Citizenship]?: number[];
    userId?: string;
}

export enum VrResumeExistenceType {
    New = 'NEW',
    Existed = 'EXISTED',
    Empty = 'EMPTY',
}

export default createReducer<VrSignupLogin, PayloadTypes>(initialState, {
    [SET_VR_SIGNUP_LOGIN_FINGERPRINTS]: (state, action) => {
        return { ...state, fingerprints: action.payload };
    },
});
