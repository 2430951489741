import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';

import ResumesCountriesVisibilitySettingsModal from 'src/components/ResumesCountriesVisibility/ResumesCountriesVisibilitySettingsModal';

const ResumesCountriesVisibilitySettingsModalContainer: FC = () => {
    const shouldBeShown = useSelector(
        ({ showResumeCountriesVisibilitySettingsModal }) => showResumeCountriesVisibilitySettingsModal
    );
    return shouldBeShown ? <ResumesCountriesVisibilitySettingsModal /> : null;
};

export default ResumesCountriesVisibilitySettingsModalContainer;
