import { type FC, type PropsWithChildren, useContext, useReducer, useCallback, ComponentType } from 'react';

import Notifications from 'src/components/Notifications/Provider/Notifications';
import reducer, { NotificationContext } from 'src/components/Notifications/Provider/context';
import {
    BlokoNotificationComponent,
    StoredNotification,
    RemoveNotification,
    RemoveNotificationByComponent,
    AddNotification,
    MagritteNotificationComponent,
} from 'src/components/Notifications/Provider/types';

interface NotificationsHOCProps {
    addNotification: AddNotification;
    removeNotification: RemoveNotification;
}

export const useNotification = (): {
    removeNotification: RemoveNotificationByComponent;
    addNotification: AddNotification;
} => {
    const { removeNotification, addNotification } = useContext(NotificationContext);
    return { removeNotification, addNotification };
};

export const NotificationsHOC = <P extends object>(
    WrappedComponent: ComponentType<P>
): FC<P & NotificationsHOCProps> => {
    const NotificationsWrapper: FC<P & NotificationsHOCProps> = (props) => {
        const { addNotification, removeNotification } = useNotification();
        return (
            <WrappedComponent {...props} addNotification={addNotification} removeNotification={removeNotification} />
        );
    };
    return NotificationsWrapper;
};

const NotificationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [notifications, dispatch] = useReducer(reducer, []);

    const addNotification = useCallback<AddNotification>(
        (component, notification) => {
            dispatch({
                type: 'ADD',
                payload: {
                    ...(notification as Omit<StoredNotification, 'id' | 'component'>),
                    component: component as BlokoNotificationComponent | MagritteNotificationComponent,
                },
            });
        },
        [dispatch]
    );

    const removeNotificationByIdAction = useCallback<RemoveNotification>(
        (id) => {
            dispatch({
                type: 'REMOVE_BY_ID',
                payload: { id },
            });
        },
        [dispatch]
    );

    const removeNotificationByComponent = useCallback<RemoveNotificationByComponent>(
        (payload) => {
            dispatch({
                type: 'REMOVE_BY_COMPONENT',
                payload,
            });
        },
        [dispatch]
    );

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification,
                removeNotification: removeNotificationByComponent,
                removeNotificationByIdAction,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const NotificationsRenderer: FC = () => {
    const { notifications, removeNotificationByIdAction } = useContext(NotificationContext);
    return <Notifications notifications={notifications} removeNotificationByIdAction={removeNotificationByIdAction} />;
};

export default NotificationContextProvider;
