import { useCallback, useMemo, Fragment } from 'react';

import { Button, VSpacing, CheckableCard, Cell, CellText, Checkbox, Select, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    EXTERNAL_OPTIONS,
    WORKPLACE_OPTIONS,
    WORKPLACE_OPTIONS_SET,
    getStateWithDefaultValues,
} from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { SearchQueries, PosOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    settingsSave: 'search.resume.query.settings.button.save',
    positionTitle: 'search.resume.query.position.title',
    workplacesPlaceholder: 'search.resume.query.position.workplaces.placeholder',
};

interface Props {
    selectKey: number;
    currentQuery: SearchQueries;
    setCurrentQuery: (value: SearchQueries) => void;
}

const MobilePosSelect: TranslatedComponent<Props> = ({ trls, selectKey, currentQuery, setCurrentQuery }) => {
    const preparedTrls = useSelector((state) => state.searchQueries?.trls[CriteriaKey.Pos]);
    const isZarplataPlatform = useIsZarplataPlatform();
    const posValue = currentQuery[CriteriaKey.Pos];

    const allowedWorkplaceOptions = useMemo(() => {
        return isZarplataPlatform
            ? WORKPLACE_OPTIONS.filter((item) => item !== PosOption.WorkplaceOrganization)
            : [...WORKPLACE_OPTIONS];
    }, [isZarplataPlatform]);

    const workplaceOptionsForSelect = useMemo(() => {
        return allowedWorkplaceOptions.map((option) => ({
            label: preparedTrls?.[option],
            value: option,
        }));
    }, [allowedWorkplaceOptions, preparedTrls]);

    const selectValue = useMemo(() => {
        let selectValue = posValue.filter((item) => WORKPLACE_OPTIONS_SET.has(item));
        if (!selectValue.length && posValue.includes(PosOption.Workplaces)) {
            selectValue = [...allowedWorkplaceOptions];
        }

        return selectValue;
    }, [posValue, allowedWorkplaceOptions]);

    const changePosQuery = useCallback(
        (options: PosOption[]) => {
            const optionSet = new Set(options);

            if (optionSet.size === 0) {
                optionSet.add(PosOption.FullText);
            } else if (optionSet.size > 1 && optionSet.has(PosOption.FullText)) {
                optionSet.delete(PosOption.FullText);
            }

            const modifiedOptions = [...optionSet];

            setCurrentQuery(
                getStateWithDefaultValues({ ...currentQuery, [CriteriaKey.Pos]: modifiedOptions }, modifiedOptions)
            );
        },
        [setCurrentQuery, currentQuery]
    );

    const handlePosChange = (changedOption: PosOption) => {
        const optionSet = new Set(posValue);

        if (optionSet.has(changedOption)) {
            optionSet.delete(changedOption);
        } else {
            optionSet.add(changedOption);
        }

        const newOptions = [...optionSet];
        changePosQuery(newOptions);
    };

    const handleSelectChange = useCallback(
        (activeOptions: string[]) => {
            const optionSet = new Set(posValue);

            WORKPLACE_OPTIONS.forEach((item) => {
                optionSet.delete(item);
            });
            optionSet.delete(PosOption.Workplaces);

            const isEveryOptionChecked = WORKPLACE_OPTIONS.length === activeOptions.length;
            const newOptions: PosOption[] = [
                ...optionSet,
                ...(isEveryOptionChecked ? [PosOption.Workplaces] : (activeOptions as PosOption[])),
            ];

            changePosQuery(newOptions);
        },
        [posValue, changePosQuery]
    );

    if (!preparedTrls) {
        return null;
    }

    return (
        <>
            <Text style="secondary" typography="label-2-regular">
                {trls[TrlKeys.positionTitle]}
            </Text>
            <VSpacing default={12} />
            <input type="hidden" name={CriteriaKey.Pos} value={currentQuery[CriteriaKey.Pos].join(',')} />
            {EXTERNAL_OPTIONS.map((option) => {
                const isChecked = posValue.includes(option);

                return (
                    <Fragment key={option}>
                        {option === PosOption.Workplaces ? (
                            <Select
                                // Костыль для сброса состояния селекта при ресете фильтров.
                                key={selectKey}
                                type="checkbox"
                                triggerProps={{
                                    size: 'large',
                                    label: trls[TrlKeys.workplacesPlaceholder],
                                    elevateLabel: true,
                                    stretched: true,
                                }}
                                options={workplaceOptionsForSelect}
                                value={selectValue}
                                onChange={handleSelectChange}
                                applyChangesButton={
                                    <Button mode="primary" style="accent">
                                        {trls[TrlKeys.settingsSave]}
                                    </Button>
                                }
                                name={CriteriaKey.Pos}
                                bottomSheetHeight="content"
                                multiple
                            />
                        ) : (
                            <CheckableCard
                                type="checkbox"
                                checked={isChecked}
                                padding={16}
                                borderRadius={16}
                                onChange={() => {
                                    handlePosChange(option);
                                }}
                            >
                                <Cell
                                    right={
                                        <Checkbox checked={isChecked} dataQaCheckbox={`${option}-checkbox`} readOnly />
                                    }
                                >
                                    <CellText data-qa={`${option}-title`}>{preparedTrls[option]}</CellText>
                                </Cell>
                            </CheckableCard>
                        )}
                        <VSpacing default={12} />
                    </Fragment>
                );
            })}
        </>
    );
};

export default translation(MobilePosSelect);
