import { MutableRefObject, useCallback } from 'react';

import { Link, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedSearchButton from 'src/components/SupernovaSearch/AdvancedSearchButton';
import SearchButton from 'src/components/SupernovaSearch/SearchButton';
import SearchNameSelect from 'src/components/SupernovaSearch/SearchNameSelect';
import { SupernovaSearchBaseState } from 'src/components/SupernovaSearch/useSearchBaseState';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    addField: 'resumesSearch.advanced.addField',
};

interface Props {
    searchBaseState: SupernovaSearchBaseState;
    addQueryRef: MutableRefObject<(() => void) | undefined>;
}

const MultisearchControllers: TranslatedComponent<Props> = ({ trls, searchBaseState, addQueryRef }) => {
    const { isMobile } = useBreakpoint();

    const handleAddQuery = useCallback(() => {
        addQueryRef?.current?.();
    }, [addQueryRef]);

    return (
        <div className={styles.wrapper}>
            <Link onClick={handleAddQuery} data-qa="resumes-search-wizard-add-item">
                {trls[TrlKeys.addField]}
            </Link>
            {!isMobile && <AdvancedSearchButton {...searchBaseState} />}
            <div className={styles.spacer}></div>
            <div className={styles.searchSelect}>
                <SearchNameSelect {...searchBaseState} />
            </div>
            <div className={styles.submitButton} data-qa="search-button">
                <SearchButton stretched />
            </div>
            {isMobile && <AdvancedSearchButton {...searchBaseState} shortView />}
        </div>
    );
};

export default translation(MultisearchControllers);
