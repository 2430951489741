import { useEffect, useRef } from 'react';

import notVerifiedStatusElementShown, {
    ViewLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/not_verified_status_element_shown';
import uploadDocumentsTooltipButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/upload_documents_tooltip_button_click';
import { Button } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize16, VerifiedBadgeFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    EmployerVerificationState,
    TooltipType,
    useEmployerVerificationState,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmployerVerificationIcon from 'src/components/EmployerVerification/VerificationIcon/EmployerVerificationIcon';

interface VerificationIconProps {
    tooltipType: TooltipType;
    vacancyId?: string;
    draftId?: string;
    isPreview?: boolean;
    isNotVerifiedEmployerBannerExp: boolean;
}

const TrlKeys = {
    upload: 'employer.notVerifiedBanner.upload',
    removeRestrictions: 'employer.notVerifiedBanner.removeRestrictions',
    close: 'employer.notVerifiedBanner.close',
    content: {
        vacancy: {
            newWithoutDocs: 'employer.notVerifiedIcon.vacancy.newWithoutDocs',
            newWithDocs: 'employer.notVerifiedIcon.vacancy.newWithDocs',
            notVerifiedWithoutDocs: 'employer.notVerifiedIcon.vacancy.notVerifiedWithoutDocs',
            notVerifiedWithDocs: 'employer.notVerifiedIcon.vacancy.notVerifiedWithDocs',
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.vacancy.newWithoutDocs',
            verificationNewWithDocs: 'employer.verification.notVerifiedIcon.vacancy.newWithDocs',
            notVerified: 'employer.verification.notVerifiedIcon.vacancy.notVerified',
        },
        profile: {
            newWithoutDocs: 'employer.notVerifiedIcon.profile.newWithoutDocs',
            newWithDocs: 'employer.notVerifiedIcon.profile.newWithDocs',
            notVerifiedWithoutDocs: 'employer.notVerifiedIcon.profile.notVerifiedWithoutDocs',
            notVerifiedWithDocs: 'employer.notVerifiedIcon.profile.notVerifiedWithDocs',
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.profile.newWithoutDocs',
            verificationNewWithDocs: 'employer.verification.notVerifiedIcon.profile.newWithDocs',
            notVerified: 'employer.verification.notVerifiedIcon.profile.notVerified',
        },
    },
    verifiedEmployerTooltipContent: 'employer.verifiedIcon.tooltip.content',
};

const calcShouldShowTooltip = (
    tooltipType: TooltipType,
    employerVerificationState: EmployerVerificationState | undefined,
    verificationExpEnabled: boolean,
    isPreview: boolean | undefined
) => {
    if (isPreview) {
        return false;
    }

    if (verificationExpEnabled) {
        if (
            tooltipType === TooltipType.Vacancy &&
            [EmployerVerificationState.NotVerifiedWithDocs, EmployerVerificationState.NotVerifiedWithoutDocs].includes(
                employerVerificationState
            )
        ) {
            return false;
        }
    }

    return true;
};

const VerificationIcon: TranslatedComponent<VerificationIconProps> = ({
    trls,
    tooltipType,
    vacancyId,
    draftId,
    isPreview,
    isNotVerifiedEmployerBannerExp,
}) => {
    const verificationExpEnabled = useSelector((state) => state.verificationExpEnabled);
    const { employerVerificationState } = useEmployerVerificationState();
    const notVerifiedEmployerIconRef = useRef<HTMLDivElement>(null);
    const isApproved = employerVerificationState === EmployerVerificationState.Approved;
    const newWithoutDocs = employerVerificationState === EmployerVerificationState.NewWithoutDocs;
    const newWithDocs = employerVerificationState === EmployerVerificationState.NewWithDocs;
    const notVerifiedWithoutDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithoutDocs;
    const notVerifiedWithDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithDocs;

    const analyticsParams = {
        vacancyId,
        draftId,
        tooltipType,
        viewLabel: (isPreview ? 'vacancy_preview' : 'vacancy_view') as ViewLabel,
    };

    useEffect(() => {
        if (isNotVerifiedEmployerBannerExp && !isApproved && notVerifiedEmployerIconRef.current) {
            notVerifiedStatusElementShown(notVerifiedEmployerIconRef.current, analyticsParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldShowTooltip = calcShouldShowTooltip(
        tooltipType,
        employerVerificationState,
        verificationExpEnabled,
        isPreview
    );

    if (isNotVerifiedEmployerBannerExp && !isApproved) {
        const withActionProps = newWithoutDocs || notVerifiedWithoutDocs;
        return (
            <div ref={notVerifiedEmployerIconRef}>
                <EmployerVerificationIcon
                    icon={<ExclamationCircleFilledSize16 initial="warning" />}
                    tipContent={
                        verificationExpEnabled ? (
                            <>
                                {newWithoutDocs && trls[TrlKeys.content[tooltipType].verificationNewWithoutDocs]}
                                {newWithDocs && trls[TrlKeys.content[tooltipType].newWithDocs]}
                                {notVerifiedWithoutDocs && trls[TrlKeys.content[tooltipType].notVerified]}
                                {notVerifiedWithDocs && trls[TrlKeys.content[tooltipType].notVerified]}
                            </>
                        ) : (
                            <>
                                {newWithoutDocs && trls[TrlKeys.content[tooltipType].newWithoutDocs]}
                                {newWithDocs && trls[TrlKeys.content[tooltipType].newWithDocs]}
                                {notVerifiedWithoutDocs && trls[TrlKeys.content[tooltipType].notVerifiedWithoutDocs]}
                                {notVerifiedWithDocs && trls[TrlKeys.content[tooltipType].notVerifiedWithDocs]}
                            </>
                        )
                    }
                    actionButton={
                        <Button
                            Element={Link}
                            to="/employer/documents"
                            target={tooltipType === TooltipType.Profile ? '_blank' : ''}
                            mode="primary"
                            style="accent"
                            onClick={() => uploadDocumentsTooltipButtonClick(analyticsParams)}
                        >
                            {verificationExpEnabled ? trls[TrlKeys.removeRestrictions] : trls[TrlKeys.upload]}
                        </Button>
                    }
                    placement={tooltipType === TooltipType.Profile ? 'left-center' : 'bottom-center'}
                    analyticsParams={analyticsParams}
                    tooltipActionProps={
                        withActionProps && analyticsParams
                            ? {
                                  buttons: (onClose: VoidFunction) => (
                                      <>
                                          <Button
                                              mode="secondary"
                                              style="contrast"
                                              size="small"
                                              Element={Link}
                                              to="/employer/documents"
                                              target="_blank"
                                              onClick={() => uploadDocumentsTooltipButtonClick(analyticsParams)}
                                          >
                                              {verificationExpEnabled
                                                  ? trls[TrlKeys.removeRestrictions]
                                                  : trls[TrlKeys.upload]}
                                          </Button>
                                          {!verificationExpEnabled && (
                                              <Button mode="tertiary" size="small" style="contrast" onClick={onClose}>
                                                  {trls[TrlKeys.close]}
                                              </Button>
                                          )}
                                      </>
                                  ),
                                  showClose: false,
                              }
                            : {
                                  buttons: undefined,
                                  showClose: !verificationExpEnabled,
                              }
                    }
                    shouldShowTooltip={shouldShowTooltip}
                    isHoverTip={verificationExpEnabled}
                />
            </div>
        );
    }

    if (isApproved) {
        return (
            <EmployerVerificationIcon
                icon={<VerifiedBadgeFilledSize16 initial="accent" />}
                tipContent={trls[TrlKeys.verifiedEmployerTooltipContent]}
                placement="bottom-center"
                shouldShowTooltip={shouldShowTooltip}
                isHoverTip={verificationExpEnabled}
            />
        );
    }

    return null;
};

export default translation(VerificationIcon);
