import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatFromHyphenDateString } from 'Modules/formatDate';
import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.BillingCartActivated]: {
        first: 'notification.messages.billing.cart.activated.message.part1',
        second: 'notification.messages.billing.cart.activated.message.part2',
        upgrade: 'notification.messages.billing.cart.activated.UPGRADE.message.part2',
        restore: 'notification.messages.billing.cart.activated.RESTORE.message.part2',
        link: 'notification.messages.billing.cart.activated.link.order',
    },
    [ComplexUserNotificationsTemplateKey.BillingCartActivationReady]: {
        first: 'notification.messages.billing.cart.activation.ready.message.part1',
        second: 'notification.messages.billing.cart.activation.ready.message.part2',
        link: 'notification.messages.billing.cart.activation.ready.link.cart',
        linkActivation: 'notification.messages.billing.cart.activation.ready.link.activation',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const BillingInvoiceFinish: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    const showUpgradeOrRestoreContent = notification.params.UPGRADE || notification.params.RESTORE;
    let upgradeOrRestoreContent = null;
    const linkURL = `/employer/invoice/finish?cartId=${notification.params.cart_id}&code=${key}`;

    if (showUpgradeOrRestoreContent && key === ComplexUserNotificationsTemplateKey.BillingCartActivated) {
        upgradeOrRestoreContent = (
            <>
                {notification.params.UPGRADE ? trls[TrlKeys[key].upgrade] : trls[TrlKeys[key].restore]}
                <NotificationLink to="{param[@key = 'UPGRADE' or @key = 'RESTORE']/@value}">
                    {notification.params.vacancy_name}
                </NotificationLink>
            </>
        );
    }

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Info}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>
                {trls[TrlKeys[key].first]}{' '}
                <NotificationLink to={linkURL}>
                    <span suppressHydrationWarning>
                        {format(trls[TrlKeys[key].link], {
                            '{0}': formatFromHyphenDateString(notification.params.purchase_date),
                        })}
                    </span>
                </NotificationLink>{' '}
                {showUpgradeOrRestoreContent ? upgradeOrRestoreContent : trls[TrlKeys[key].second]}{' '}
                {key === ComplexUserNotificationsTemplateKey.BillingCartActivationReady ? (
                    <NotificationLink to={linkURL}>{trls[TrlKeys[key].linkActivation]}</NotificationLink>
                ) : null}
            </NotificationSpacer>
        </Notification>
    );
};

export default translation(BillingInvoiceFinish);
