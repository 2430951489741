import { Fragment } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import translation from 'src/components/translation';
import { useIsHhru } from 'src/hooks/useSites';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden]: {
        first: 'supernova.notification.billing.delayed.cart.activation.content.1',
        second: 'supernova.notification.billing.delayed.cart.activation.content.2',
        link: 'supernova.notification.billing.delayed.cart.activation.carts',
        articleLink: 'supernova.notification.billing.delayed.cart.activation.details',
    },
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationExpired]: {
        first: 'supernova.notification.billing.delayed.cart.activation.expired.content.1',
        link: 'supernova.notification.billing.delayed.cart.activation.carts',
        articleLink: 'supernova.notification.billing.delayed.cart.activation.details',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const BillingCartActivationNotification: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const isHHRu = useIsHhru();
    const key = notification.templateKey as TemplateKeys;
    const articleId = notification.params.articleId;

    return (
        <Fragment>
            <NotificationText>
                {formatToReactComponent(trls[TrlKeys[key].first], {
                    '{}': <HumanDate showYear={ShowYear.NotCurrent} date={notification.params.expirationDate} />,
                })}
            </NotificationText>

            {key === ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden && (
                <NotificationText>{trls[TrlKeys[key].second]}</NotificationText>
            )}
            <div className="supernova-notification-links">
                <div className="supernova-notification-links__link">
                    <NotificationLink to="/employer/carts">{trls[TrlKeys[key].link]}</NotificationLink>
                </div>
                {articleId && (
                    <div className="supernova-notification-links__link">
                        <NotificationLink
                            to={`${
                                isHHRu &&
                                key === ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationExpired
                                    ? `https://feedback.hh.ru/knowledge-base/article/${articleId}`
                                    : `/article/${articleId}`
                            }`}
                        >
                            {trls[TrlKeys[key].articleLink]}
                        </NotificationLink>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default translation(BillingCartActivationNotification);
