import { FC, MutableRefObject, useCallback } from 'react';

import { DateType, UncontrolledDatePicker } from '@hh.ru/magritte-ui';

import { WINDOW_LOCALE } from 'Modules/formatDate';
import { formatDate } from 'src/components/InterviewReminder/utils/formatDate';

interface DatePickerProps {
    selectedDate: DateType;
    dateRef: MutableRefObject<string>;
}

const getDateWithoutTime = (date: Date, keepOffset = false) => {
    const d = new Date(date);
    if (!keepOffset) {
        d.setTime(d.getTime() - d.getTimezoneOffset() * 60000);
    }
    return d.toISOString().substring(0, 10);
};

const DatePicker: FC<DatePickerProps> = ({ selectedDate, dateRef }) => {
    const onDateSelect = useCallback(
        (date: DateType) => {
            const d = new Date(date);
            dateRef.current = formatDate(getDateWithoutTime(d), 'hyphen');
        },
        [dateRef]
    );

    const disabledDatesFunc = useCallback((startDate: DateType, endDate: DateType) => {
        const [start, end] = [startDate, endDate].map((d) => new Date(d));
        const currentDate = new Date();
        const result = [];
        while (getDateWithoutTime(start) < getDateWithoutTime(end)) {
            if (getDateWithoutTime(start) < getDateWithoutTime(currentDate)) {
                const date = new Date(start);
                result.push(date);
            }
            start.setDate(start.getDate() + 1);
        }
        return Promise.resolve(result);
    }, []);

    return (
        <UncontrolledDatePicker
            enabledCalendars="dd.mm.yyyy"
            headerMonthFormat="mm.yyyy"
            locale={WINDOW_LOCALE}
            selectedDate={new Date(selectedDate)}
            onDateSelect={onDateSelect}
            disabledDatesFunc={disabledDatesFunc}
        />
    );
};

export default DatePicker;
