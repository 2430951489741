import { useState, useCallback } from 'react';

import ModalHelper from 'bloko/common/modalHelper';

import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';

interface UseOverlayMenu {
    (): {
        overlay: OverlayTypes;
        showOverlay: OverlayTypeSetterFunc;
    };
}

const useOverlayMenu: UseOverlayMenu = () => {
    const [overlay, setOverlay] = useState<OverlayTypes>(OverlayTypes.None);

    const showOverlay = useCallback<OverlayTypeSetterFunc>(
        (type) => {
            setOverlay((overlay) => {
                if (overlay === type) {
                    ModalHelper.enableScroll();
                    return OverlayTypes.None;
                }

                if (type === OverlayTypes.None) {
                    ModalHelper.enableScroll();
                } else {
                    ModalHelper.disableScroll();
                }
                return type;
            });
        },
        [setOverlay]
    );

    return { overlay, showOverlay };
};

export default useOverlayMenu;
