import React from 'react';

import Text from 'bloko/blocks/text';

import CurrentUserFullName from 'src/components/SupernovaMainMenu/CurrentUserFullName';

const UserInfo: React.VFC = () => {
    return (
        <div className="supernova-dropdown-static-option">
            <Text Element="span" strong>
                <CurrentUserFullName />
            </Text>
        </div>
    );
};

export default UserInfo;
