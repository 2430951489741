import React, { RefObject, useRef, useState } from 'react';
import classnames from 'classnames';

import { UserCircleOutlinedSize24, UserCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import MultiAccountSwitchInfotip from 'src/components/SupernovaMainMenu/MultiAccountSwitchInfotip';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import EmployerProfilePopup from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerProfilePopup';

const EmployerProfile: React.FC<NavItem & { headerContentRef: RefObject<HTMLDivElement> }> = ({
    name,
    subItems,
    trackClicks,
    analytics,
    headerContentRef,
}) => {
    const employerId = useSelector(({ employerId }) => employerId);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const infotipRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const handleTogglePopup = () => {
        setVisible((visible) => !visible);
    };

    const handleClosePopup = () => {
        setVisible(false);
    };

    return (
        <MultiAccountSwitchInfotip infotipRef={infotipRef}>
            <SupernovaNaviItem data-qa="multiaccount-infotip" ref={infotipRef}>
                <SupernovaActionLink
                    data-qa={classnames(`mainmenu_${name}`, { 'mainmenu_sudo-sign': isSudo })}
                    name={name}
                    icon={<UserCircleOutlinedSize24 />}
                    activeIcon={<UserCircleFilledSize24 />}
                    trackClicks={trackClicks}
                    analytics={analytics}
                    onlyIconOnMobile
                    onClick={handleTogglePopup}
                >
                    <span data-qa="supernova-navi-client-id">№ {employerId}</span>
                </SupernovaActionLink>
                <EmployerProfilePopup
                    subItems={subItems}
                    onClose={handleClosePopup}
                    visible={visible}
                    activatorRef={headerContentRef}
                />
            </SupernovaNaviItem>
        </MultiAccountSwitchInfotip>
    );
};

export default EmployerProfile;
