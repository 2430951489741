import { FC, ReactNode, StrictMode, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import AppSmartBanner from 'src/components/AppSmartBanner';
import PostChangeModal from 'src/components/Applicant/JobSearchStatus/PostChangeModal';
import PostChangeModalRedesign from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign';
import Portal from 'src/components/CodeInjector/Portal';
import CookiesPolicyBanner from 'src/components/CookiesPolicy/Banner';
import CookiesPolicyInformer from 'src/components/CookiesPolicy/Informer';
import EmailVerification from 'src/components/EmailVerification';
import EmployerInformer from 'src/components/EmployerInformer';
import EmployerZpOnboarding from 'src/components/EmployerZpOnboarding';
// tempexp_PORTFOLIO-35883_start
import FloatChatikActivator from 'src/components/FloatChatikActivator';
// tempexp_PORTFOLIO-35883_end
import Footer from 'src/components/Footer';
import LoyaltySurveyModal from 'src/components/LoyaltySurveyModal';
import SiteExceptions from 'src/components/SiteExceptions';
import SubscriptionInformer from 'src/components/SubscriptionInformer';
import SupernovaMainMenu from 'src/components/SupernovaMainMenu';
import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';
import SupernovaOverlayMenu from 'src/components/SupernovaOverlayMenu';
import useOverlayMenu from 'src/components/SupernovaOverlayMenu/useOverlayMenu';
import TopPromoBanner from 'src/components/TopPromoBanner';
import UnsupportedBrowser from 'src/components/UnsupportedBrowser';
import useIsMenuHidden from 'src/hooks/useIsMenuHidden';
import { useSelector } from 'src/hooks/useSelector';
import { TopLevelSite } from 'src/models/topLevelSite';
import { UserType } from 'src/models/userType';

import LightPageLayoutHeader from 'src/app/layouts/BlokoLayouts/LightPageLayout/LightPageLayoutHeader';
import MainContentRefContext from 'src/app/layouts/MainContentRefContext';

const checkIsDashboard = (userType: UserType, pathname: string) => {
    const dashboardMenuRules = [
        { userType: UserType.Anonymous, pathname: '/' },
        { userType: UserType.BackOffice, pathname: '/' },
        { userType: UserType.NedoUser, pathname: '/' },
    ];

    return dashboardMenuRules.some((rule) => rule.userType === userType && rule.pathname === pathname);
};

interface FullPageLayoutProps {
    afterMenuContent?: ReactNode;
    useCleanContainer?: boolean;
    children?: ReactNode;
}

const FullPageLayout: FC<FullPageLayoutProps> = ({ children, afterMenuContent, useCleanContainer }) => {
    const { pathname } = useLocation();
    const errorCode = useSelector(({ errorCode }) => errorCode);
    const isLightPage = useSelector(({ isLightPage }) => isLightPage);
    const isLegacyPage = useSelector(({ request }) => request.luxPageName === 'LegacyPage');
    const mainContentRef = useRef(null);
    const mainContentVisible = useSelector(({ mainContentVisible }) => mainContentVisible);
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);
    const showZpEmployerOnboardingModal = useSelector(
        ({ zpEmployerOnboarding }) => zpEmployerOnboarding?.showOnboardingModal
    );
    const userType = useSelector(({ userType }) => userType);
    const isMenuHidden = useIsMenuHidden();
    const isDashboardMenu = checkIsDashboard(userType, pathname);
    const useXhhLayout = useSelector(({ useXhhLayout }) => useXhhLayout);

    const { overlay, showOverlay } = useOverlayMenu();

    if (isLightPage) {
        return (
            <>
                <StrictMode>
                    {isLegacyPage && !isMenuHidden && (
                        /* Параметр logLevel="info" выстален намеренно:
                         * так как на некоторых страницах могут некорректно обрабатываться ошибки FailFast
                         * из-за чего не будет правильно выстален шаблон xsl, но реакт попытается отрисовать портал
                         */
                        <Portal place="light-page-menu" logLevel={errorCode ? 'info' : undefined}>
                            <LightPageLayoutHeader afterMenuContent={afterMenuContent} />
                        </Portal>
                    )}
                </StrictMode>
                {children}
                <StrictMode>{showZpEmployerOnboardingModal && <EmployerZpOnboarding />}</StrictMode>
            </>
        );
    }

    const isBroadMainContent = !isLightPage && !isDashboardMenu && !isMenuHidden;
    const classNames = !useCleanContainer
        ? classnames('main-content', {
              'main-content_broad': isBroadMainContent && !useXhhLayout,
              'main-content_broad-spacing': isBroadMainContent && useXhhLayout,
          })
        : '';

    return (
        <>
            <StrictMode>
                <AppSmartBanner />
                <TopPromoBanner />
                <CookiesPolicyBanner />
                <CookiesPolicyInformer />
                <SiteExceptions />
                <UnsupportedBrowser />
                <SubscriptionInformer />
                <EmailVerification />
                {/* tempexp_PORTFOLIO-35883_next_line */}
                <FloatChatikActivator />
                {!isMenuHidden && (
                    <>
                        <IsDashboardContext.Provider value={isDashboardMenu}>
                            <SupernovaOverlayMenu overlay={overlay} showOverlay={showOverlay} />
                            <SupernovaMainMenu
                                afterMenuContent={afterMenuContent}
                                overlay={overlay}
                                showOverlay={showOverlay}
                            />
                        </IsDashboardContext.Provider>
                    </>
                )}
                {userType === UserType.Employer && <LoyaltySurveyModal />}
                {topLevelSite !== TopLevelSite.BY && <EmployerInformer />}
                {showZpEmployerOnboardingModal && <EmployerZpOnboarding />}
                {userType === UserType.Applicant && <PostChangeModal />}
                {userType === UserType.Applicant && <PostChangeModalRedesign />}
            </StrictMode>
            <div
                className={classnames('HH-MainContent HH-Supernova-MainContent', {
                    'g-hidden': !mainContentVisible,
                })}
            >
                <MainContentRefContext.Provider value={mainContentRef}>
                    <div className={classNames} ref={mainContentRef}>
                        {children}
                    </div>
                </MainContentRefContext.Provider>
                <StrictMode>
                    <Footer withSpacing={useXhhLayout} />
                </StrictMode>
            </div>
        </>
    );
};

export default FullPageLayout;
