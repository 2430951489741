import { Text, useBreakpoint, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './help-modal-buttons.less';

const TrlKeys = {
    city: 'personalManager.region.city',
    mobile: 'personalManager.region.mobile',

    msk: 'supernova.modal.help.phone.region.msk',
    other: 'supernova.modal.help.phone.region.other',
    regions: 'supernova.modal.help.phone.region.regions',
    spb: 'supernova.modal.help.phone.region.spb',

    zarplata: 'personalManager.region.zarplata',
};

const HelpLeftButtonsComponent: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const contactPhones = useSelector(({ contactPhones }) => contactPhones);

    return (
        <div className={isMobile ? styles.helpModalBottomSheetButtons : styles.helpModalLeftButtons}>
            {contactPhones.map(({ country, city, number, postfix }, index) => {
                return (
                    <div key={index} className={styles.helpModalPhoneContainer}>
                        <Text typography="label-3-regular" style="secondary">
                            {trls[TrlKeys[postfix]]}
                        </Text>
                        <Link
                            Element="a"
                            style="neutral"
                            typography="label-2-regular"
                            href={`tel:${country}${city}${number.replaceAll('-', '')}`}
                        >
                            {`${country} ${city} ${number}`}
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default translation(HelpLeftButtonsComponent);
