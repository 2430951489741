import { FC } from 'react';

import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';

const Switchers: FC<MenuItemRendererProps> = ({ menuItem, renderer, showOverlay }) => {
    if (menuItem.subItems === undefined) {
        return null;
    }

    return <div className="supernova-navi">{menuItem.subItems.map((menuItem) => renderer(menuItem, showOverlay))}</div>;
};

export default Switchers;
