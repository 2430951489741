import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ContactPhoneItem } from 'src/models/contactPhones';

interface AccountPhoneVerification {
    logoutBackurl: string;
    client: {
        firstName: string;
        lastName: string;
        midName: string;
        id?: string;
        isEmployer?: boolean;
        phone?: ContactPhoneItem;
    };
}

export default autoGeneratedReducer<AccountPhoneVerification>(null);
