import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteUserNotification, UserNotificationId } from 'src/models/userNotifications';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherDeleteApi {
        '/shards/notifications': {
            queryParams: {
                id: number;
            };
            response: void;
        };
    }
}

const useDeleteUserNotificationById = (id: UserNotificationId): [boolean, () => Promise<boolean>] => {
    const [isProgressRemove, setProgressRemove] = useState(false);
    const dispatch = useDispatch();

    const deleteNotification = useCallback(async () => {
        setProgressRemove(true);

        try {
            await fetcher.delete('/shards/notifications', {
                params: { id },
            });
        } catch (_) {
            setProgressRemove(false);
            return false;
        }

        setProgressRemove(false);
        dispatch(deleteUserNotification(id));
        return true;
    }, [dispatch, id]);

    return [isProgressRemove, deleteNotification];
};

export default useDeleteUserNotificationById;
