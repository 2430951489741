import { useRef } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    content: 'notifications.greetingsFromZarplata.content',
    link: 'notifications.greetingsFromZarplata.link',
};

const SiteWasUnavailable: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <SynchronizedNotification notification={notification} closeFuncRef={closeNotificationRef} onClose={onClose}>
            {trls[TrlKeys.content]}
            {NON_BREAKING_SPACE}
            <BlokoLink Element={Link} to="/article/32350">
                {trls[TrlKeys.link]}
            </BlokoLink>
        </SynchronizedNotification>
    );
};

export default translation(SiteWasUnavailable);
