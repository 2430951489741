import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import { EmployerCriteria } from 'src/models/vacancySearchCriteria';

const REPLACE_EMPLOYER_VACANCY_SEARCH_RESULT = 'REPLACE_EMPLOYER_VACANCY_SEARCH_RESULT';
const SET_IS_LOADING_EMPLOYER_VACANCIES = 'SET_IS_LOADING_EMPLOYER_VACANCIES';
const SET_SEARCH_LINE_TEXT = 'SET_SEARCH_LINE_TEXT';

export interface EmployerVacancySearch {
    itemsOnPage?: number;
    resultsFound?: number;
    totalPages?: number;
    vacancies: VacancySearchItem[];
    isLoading: boolean;
    totalUsedFilters?: number;
    paging?: Paging;
    criteria?: Partial<EmployerCriteria>;
    searchLineText?: string;
}

const INITIAL_STATE: EmployerVacancySearch = {
    vacancies: [],
    isLoading: true,
};

interface PayloadTypes {
    [REPLACE_EMPLOYER_VACANCY_SEARCH_RESULT]: EmployerVacancySearch;
    [SET_IS_LOADING_EMPLOYER_VACANCIES]: boolean;
    [SET_SEARCH_LINE_TEXT]: string;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const replaceEmployerVacanciesSearchResult = actionCreator(REPLACE_EMPLOYER_VACANCY_SEARCH_RESULT);
export const setIsLoadingEmployerVacancies = actionCreator(SET_IS_LOADING_EMPLOYER_VACANCIES);
export const setSearchLineText = actionCreator(SET_SEARCH_LINE_TEXT);

export default createReducer<EmployerVacancySearch, PayloadTypes>(INITIAL_STATE, {
    [REPLACE_EMPLOYER_VACANCY_SEARCH_RESULT]: (_state, { payload: newState }) => ({
        ...newState,
    }),
    [SET_IS_LOADING_EMPLOYER_VACANCIES]: (state, { payload: isLoading }) => ({ ...state, isLoading }),
    [SET_SEARCH_LINE_TEXT]: (state, { payload: searchLineText }) => ({ ...state, searchLineText }),
});
