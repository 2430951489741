import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import mapButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_map_button_button_click';
import mapButtonShown from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_map_button_element_shown';
import vacancySearchToggleVacancySnippetButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_toggle_vacancy_snippet_button_click';
import { Button, HSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import useSendFilterForm from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ViewToggler from 'src/components/VacancySearchContent/components/ViewToggler';

const enableVacancySnippetsAction = makeSetStoreField('enableVacancySnippets');

const TrlKeys = {
    info1: 'vacancySearch.viewSwitcher.info.1',
    info2: 'vacancySearch.viewSwitcher.info.2',
    tipFull: 'vacancySearch.viewSwitcher.tip.full',
    tipShort: 'vacancySearch.viewSwitcher.tip.short',
    ariaLabelShort: 'vacancySearch.viewSwitcher.ariaLabel.short',
    ariaLabelFull: 'vacancySearch.viewSwitcher.ariaLabel.full',
    buttonMap: 'vacancySearch.buttonMap',
};

const SearchViewSwitcher: TranslatedComponent = ({ trls }) => {
    const hasVacanciesWithAddress = useSelector((state) => state.vacancySearchResult.hasVacanciesWithAddress);
    const queries = useSelector((state) => state.vacancySearchResult.queries);
    const isVacancySnippetsEnabled = useSelector((state) => state.enableVacancySnippets);
    const isBackofficeUser = useSelector((state) => state.userType === UserType.BackOffice);
    const archiveSearch = useSelector((state) => state.archiveSearch);
    const dispatch = useDispatch();
    const sendFilterForm = useSendFilterForm();

    const onMapButtonClick = () => mapButtonClick();

    const setVacancySnippetEnable = useCallback(
        (value: boolean) => {
            if (value === isVacancySnippetsEnabled) {
                return;
            }
            vacancySearchToggleVacancySnippetButtonClick({ isFullDescription: value });
            dispatch(enableVacancySnippetsAction(value));
            sendFilterForm({ showClustersTip: false, isSearchSessionIdSaved: true });
        },
        [isVacancySnippetsEnabled, dispatch, sendFilterForm]
    );

    const togglerLeftButtonAction = useCallback(() => {
        setVacancySnippetEnable(false);
    }, [setVacancySnippetEnable]);

    const togglerRightButtonAction = useCallback(() => {
        setVacancySnippetEnable(true);
    }, [setVacancySnippetEnable]);

    const mapButtonDataQa = 'serp_settings__vacancy-map';
    const togglerLeftButtonQa = 'short_description';
    const togglerLeftButtonDisabled = isBackofficeUser && isVacancySnippetsEnabled;
    const togglerRightButtonQa = 'full_description';
    const togglerRightButtonDisabled = isBackofficeUser && !isVacancySnippetsEnabled;

    return (
        <HSpacingContainer default={16}>
            {!archiveSearch && hasVacanciesWithAddress && (
                <ElementShownAnchor fn={mapButtonShown}>
                    <Button
                        Element={Link}
                        to={`${paths.vacancySearchMap}?${queries?.map || ''}`}
                        mode="secondary"
                        style="accent"
                        data-qa={mapButtonDataQa}
                        onClick={onMapButtonClick}
                    >
                        {trls[TrlKeys.buttonMap]}
                    </Button>
                </ElementShownAnchor>
            )}
            <ViewToggler
                leftButtonPressed={!isVacancySnippetsEnabled}
                leftButtonQaText={togglerLeftButtonQa}
                leftButtonTooltipText={trls[TrlKeys.tipShort]}
                leftButtonAriaLabelText={trls[TrlKeys.ariaLabelShort]}
                leftButtonDisabled={togglerLeftButtonDisabled}
                leftButtonAction={togglerLeftButtonAction}
                rightButtonQaText={togglerRightButtonQa}
                rightButtonTooltipText={trls[TrlKeys.tipFull]}
                rightButtonAriaLabelText={trls[TrlKeys.ariaLabelFull]}
                rightButtonDisabled={togglerRightButtonDisabled}
                rightButtonAction={togglerRightButtonAction}
            />
        </HSpacingContainer>
    );
};

export default translation(SearchViewSwitcher);
