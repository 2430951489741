import { useState, useEffect } from 'react';
import classnames from 'classnames';

import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalHeader, ModalTitle, ModalFooter } from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import format from 'bloko/common/format';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';

import styles from './employer-zp-onboarding.less';

const TrlKeys = {
    nextBtnStart: 'employer.zpOnboardingModal.nextBtnStart',
    nextBtnContinue: 'employer.zpOnboardingModal.nextBtnContinue',
    nextBtnEnd: 'employer.zpOnboardingModal.nextBtnEnd',
    backBtn: 'employer.zpOnboardingModal.backBtn',
    startHeader: 'employer.zpOnboardingModal.start.header',
    startText: 'employer.zpOnboardingModal.start.text',
    activeVacanciesHeader: 'employer.zpOnboardingModal.activeVacancies.header',
    activeVacanciesText: 'employer.zpOnboardingModal.activeVacancies.text',
    activeVacanciesMoreHeader: 'employer.zpOnboardingModal.activeVacanciesMore.header',
    activeVacanciesMoreText: 'employer.zpOnboardingModal.activeVacanciesMore.text',
    archivedVacanciesHeader: 'employer.zpOnboardingModal.archivedVacancies.header',
    archivedVacanciesText: 'employer.zpOnboardingModal.archivedVacancies.text',
    ordersHeader: 'employer.zpOnboardingModal.orders.header',
    ordersText: 'employer.zpOnboardingModal.orders.text',
    closingDocsHeader: 'employer.zpOnboardingModal.closingDocs.header',
    closingDocsText: 'employer.zpOnboardingModal.closingDocs.text',
    subscriptionsHeader: 'employer.zpOnboardingModal.subscriptions.header',
    subscriptionText: 'employer.zpOnboardingModal.subscriptions.text',
    favouritesHeader: 'employer.zpOnboardingModal.favourites.header',
    favouritesText: 'employer.zpOnboardingModal.favourites.text',
    endHeader: 'employer.zpOnboardingModal.end.header',
    endText: 'employer.zpOnboardingModal.end.text',
    endAdditionalInfoText: 'employer.zpOnboardingModal.end.additionalInfoText',
    fromSteps: 'employer.zpOnboardingModal.fromSteps',
};

interface EmployerZpOnboardingStepsConfig {
    header: string;
    text: string;
    imageName: string;
    additionalInfo?: {
        link: string;
        text: string;
    };
}

const stepsConfig: EmployerZpOnboardingStepsConfig[] = [
    {
        header: TrlKeys.startHeader,
        text: TrlKeys.startText,
        imageName: 'Start',
    },
    {
        header: TrlKeys.activeVacanciesHeader,
        text: TrlKeys.activeVacanciesText,
        imageName: 'ActiveVacancies',
    },
    {
        header: TrlKeys.activeVacanciesMoreHeader,
        text: TrlKeys.activeVacanciesMoreText,
        imageName: 'ActiveVacanciesMore',
    },
    {
        header: TrlKeys.archivedVacanciesHeader,
        text: TrlKeys.archivedVacanciesText,
        imageName: 'ArchivedVacancies',
    },
    {
        header: TrlKeys.ordersHeader,
        text: TrlKeys.ordersText,
        imageName: 'Orders',
    },
    {
        header: TrlKeys.closingDocsHeader,
        text: TrlKeys.closingDocsText,
        imageName: 'ClosingDocs',
    },
    {
        header: TrlKeys.subscriptionsHeader,
        text: TrlKeys.subscriptionText,
        imageName: 'Subscriptions',
    },
    {
        header: TrlKeys.favouritesHeader,
        text: TrlKeys.favouritesText,
        imageName: 'Favourites',
    },
    {
        header: TrlKeys.endHeader,
        text: TrlKeys.endText,
        additionalInfo: {
            link: 'https://feedback.hh.ru/zp/knowledge-base/articles/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BE%D0%B4%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%D0%BC_1',
            text: TrlKeys.endAdditionalInfoText,
        },
        imageName: 'End',
    },
];

const EMPLOYER_ZP_ONBOARDING_MODAL = 'employerZpOnboardingModal';

const getNextButtonTrl = (isStart: boolean, isEnd: boolean): string => {
    let nextButtonTrl = '';
    if (isStart) {
        nextButtonTrl = TrlKeys.nextBtnStart;
    } else if (isEnd) {
        nextButtonTrl = TrlKeys.nextBtnEnd;
    } else {
        nextButtonTrl = TrlKeys.nextBtnContinue;
    }
    return nextButtonTrl;
};

const EmployerZpOnboarding: TranslatedComponent = ({ trls }) => {
    const [showModal, setShowModal] = useState(true);
    const [step, setStep] = useState(0);
    const isStart = step === 0;
    const isEnd = step === stepsConfig.length - 1;
    const breakpoint = useBreakpoint();
    const isAllowedScreen = breakpoint !== Breakpoint.XS;
    const onClose = () => {
        setShowModal(false);
        if (!isEnd) {
            Notices.markAsViewed(EMPLOYER_ZP_ONBOARDING_MODAL);
        }
    };
    const next = () => (isEnd ? onClose() : setStep(step + 1));
    const back = () => (isStart ? undefined : setStep(step - 1));

    useEffect(() => {
        if (isEnd) {
            Notices.markAsViewed(EMPLOYER_ZP_ONBOARDING_MODAL);
        }
    }, [isEnd]);

    if (!isAllowedScreen) {
        return null;
    }

    const nextBtnTrl = getNextButtonTrl(isStart, isEnd);
    const { header, text, imageName, additionalInfo } = stepsConfig[step];

    return (
        <Modal visible={showModal} onClose={onClose}>
            <ModalHeader>
                <ModalTitle data-qa="employerZpOnboarding-modal-header">{trls[header]}</ModalTitle>
                <VSpacing base={5} />
                <Text size={TextSize.Large}>
                    {trls[text]}
                    {additionalInfo && (
                        <BlokoLink href={additionalInfo.link} target="_blank">
                            {trls[additionalInfo.text]}
                        </BlokoLink>
                    )}
                </Text>
            </ModalHeader>
            <div
                className={classnames(styles.employerZpOnboardingImage, [
                    styles[`employerZpOnboardingImage${imageName}`],
                ])}
                data-qa="employerZpOnboarding-modal-img"
            />
            <ModalFooter>
                <div className={styles.employerZpOnboardingFooterWrapper}>
                    <Text size={TextSize.Medium} data-qa="employerZpOnboarding-modal-slideCount">
                        {format(trls[TrlKeys.fromSteps], {
                            '{0}': step + 1,
                            '{1}': stepsConfig.length,
                        })}
                    </Text>
                    <div className={styles.employerZpOnboardingFooterControls}>
                        {!isStart && !isEnd && (
                            <>
                                <Button onClick={back} data-qa="employerZpOnboarding-modal-backBtn">
                                    {trls[TrlKeys.backBtn]}
                                </Button>
                                <FormSpacer />
                            </>
                        )}
                        <Button kind={ButtonKind.Primary} onClick={next} data-qa="employerZpOnboarding-modal-nextBtn">
                            {trls[nextBtnTrl]}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(EmployerZpOnboarding);
