import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'supernova.notification.employer.change.email.link.remind.content',
};

const EmployerChangeEmailReminder: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return <Notification {...notification}>{trls[TrlKeys.content]}</Notification>;
};

export default translation(EmployerChangeEmailReminder);
