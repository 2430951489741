import { FC } from 'react';

import { LupaScaleSmallKindDefaultAppearanceOutlined } from 'bloko/blocks/icon';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

const InputIcon: FC = () => {
    const isXs = useBreakpoint() === Breakpoint.XS;

    if (isXs) {
        return (
            <span className="supernova-search-bold-icon">
                <span className="supernova-icon supernova-icon_search-primary" />
            </span>
        );
    }

    return <LupaScaleSmallKindDefaultAppearanceOutlined />;
};

export default InputIcon;
