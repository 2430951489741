import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { NavItems } from 'src/models/supernovaNaviMenu';

import LogoffButton from 'src/components/SupernovaMainMenu/EmployerProfile/LogoffButton';
import SwitchAccountButton from 'src/components/SupernovaMainMenu/EmployerProfile/SwitchAccountButton';

import styles from './styles.less';

interface AccountActionsProps {
    subItems?: NavItems;
}

const AccountActions: FC<AccountActionsProps> = ({ subItems }) => {
    return (
        <div className={styles.footer}>
            <VSpacingContainer default={12}>
                {subItems?.map((item) => {
                    switch (item.name) {
                        case 'logoffUser':
                            return <LogoffButton key={item.name} {...item} />;
                        case 'switchAccountEmployer':
                            return <SwitchAccountButton key={item.name} {...item} />;
                        default:
                            return null;
                    }
                })}
            </VSpacingContainer>
        </div>
    );
};

export default AccountActions;
