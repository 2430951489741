import { Snackbar } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    maxResumes: 'resumeList.maxResumes',
};

interface MaxResumesLimitSnackbarProps {
    visible?: boolean;
    onClose: () => void;
}

const MaxResumesLimitSnackbar: TranslatedComponent<MaxResumesLimitSnackbarProps> = ({ onClose, visible, trls }) => {
    const maxResumes = useSelector(({ resumeLimits }) => resumeLimits?.max || 20);

    if (!visible) {
        return null;
    }
    return (
        <Snackbar showClose autohideTime={3000} data-qa="max-resumes-snackbar-warning" onClose={onClose}>
            {format(trls[TrlKeys.maxResumes], { '{0}': maxResumes })}
        </Snackbar>
    );
};

export default translation(MaxResumesLimitSnackbar);
