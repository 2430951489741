import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ManagerType } from 'src/models/employerManagers.types';

export interface ManagerPhone {
    fullPhoneNumber?: string;
    phoneComment?: string;
}

export interface EmployerManager {
    id: string;
    text: string;
    additional: { shortFirstName: string; shortMiddleName: string; lastName: string };
    email: string;
    phone: ManagerPhone;
    additionalPhone: null | ManagerPhone;
    type: Exclude<ManagerType, ManagerType.McpGroup>;
    isMCP: boolean;
    humanPhone: string;
}

export default autoGeneratedReducer<EmployerManager[]>([]);
