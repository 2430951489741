import { Tabs, Tab } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

import { SupernovaSearchBaseState } from 'src/components/SupernovaSearch/useSearchBaseState';

const TrlKeys = {
    [SupernovaSearchName.Vacancies]: 'navi.search.tab.searchVacancy',
    [SupernovaSearchName.Resumes]: 'navi.search.tab.resumeSearch',
    [SupernovaSearchName.Employers]: 'navi.search.tab.employersList',
};

const SearchTabs: TranslatedComponent<SupernovaSearchBaseState> = ({ searchName, setSearchName, trls }) => (
    <Tabs activeItemId={searchName}>
        {[SupernovaSearchName.Vacancies, SupernovaSearchName.Resumes, SupernovaSearchName.Employers].map((name) => (
            <Tab id={name} key={name} onClick={() => setSearchName(name)} data-qa={name}>
                {trls[TrlKeys[name]]}
            </Tab>
        ))}
    </Tabs>
);

export default translation(SearchTabs);
