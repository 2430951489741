import { useCallback, useState } from 'react';

/**
 * Хук для бинарного стейта, возвращает коллбек для изменения значения на противоположное
 * 3-им параметром возвращается setState для явного задания значения флага
 *
 * Часто toggleState ставится напрямую в обработчики вроде onClick и получает аргументом event,
 * по этому для явного задания значения не получилось использовать метод toggleState с аргументом
 */

export type ToggleState = [boolean, () => void, (newState: boolean) => void];

export default (initialState: boolean): ToggleState => {
    const [state, setState] = useState(initialState);
    const toggleState = useCallback(() => {
        setState((state) => !state);
    }, []);
    const setExplicitState = useCallback((newState: boolean) => {
        setState(Boolean(newState));
    }, []);

    return [state, toggleState, setExplicitState];
};
