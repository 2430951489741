import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { NovaFilterKey } from 'src/models/novaFilters';

const SEARCH_CLUSTER_ORDER_AREA = 'SEARCH_CLUSTER_ORDER_AREA';
const SEARCH_CLUSTER_ORDER_DISTRICT = 'SEARCH_CLUSTER_ORDER_DISTRICT';
const SEARCH_CLUSTER_ORDER_METRO = 'SEARCH_CLUSTER_ORDER_METRO';
const SEARCH_CLUSTER_ORDER_PROF_ROLE = 'SEARCH_CLUSTER_ORDER_PROF_ROLE';
const SEARCH_CLUSTER_ORDER_INDUSTRY = 'SEARCH_CLUSTER_ORDER_INDUSTRY';
const SEARCH_CLUSTER_ORDER_SUB_INDUSTRY = 'SEARCH_CLUSTER_ORDER_SUB_INDUSTRY';
const SEARCH_CLUSTER_ORDER_SKILL = 'SEARCH_CLUSTER_ORDER_SKILL';
const SEARCH_CLUSTER_ORDER_DRIVER_LICENCE_TYPES = 'SEARCH_CLUSTER_ORDER_DRIVER_LICENCE_TYPES';
const SEARCH_CLUSTER_ORDER_LABEL = 'SEARCH_CLUSTER_ORDER_LABEL';

interface PayloadTypes {
    [SEARCH_CLUSTER_ORDER_AREA]: string[];
    [SEARCH_CLUSTER_ORDER_DISTRICT]: string[];
    [SEARCH_CLUSTER_ORDER_METRO]: string[];
    [SEARCH_CLUSTER_ORDER_PROF_ROLE]: string[];
    [SEARCH_CLUSTER_ORDER_INDUSTRY]: string[];
    [SEARCH_CLUSTER_ORDER_SUB_INDUSTRY]: string[];
    [SEARCH_CLUSTER_ORDER_SKILL]: string[];
    [SEARCH_CLUSTER_ORDER_DRIVER_LICENCE_TYPES]: string[];
    [SEARCH_CLUSTER_ORDER_LABEL]: string[];
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const setOrderArea = actionHelper(SEARCH_CLUSTER_ORDER_AREA);
export const setOrderDistrict = actionHelper(SEARCH_CLUSTER_ORDER_DISTRICT);
export const setOrderMetro = actionHelper(SEARCH_CLUSTER_ORDER_METRO);
export const setOrderProfRole = actionHelper(SEARCH_CLUSTER_ORDER_PROF_ROLE);
export const setOrderIndustry = actionHelper(SEARCH_CLUSTER_ORDER_INDUSTRY);
export const setOrderSubIndustry = actionHelper(SEARCH_CLUSTER_ORDER_SUB_INDUSTRY);
export const setOrderSkill = actionHelper(SEARCH_CLUSTER_ORDER_SKILL);
export const setOrderDriverLicenceTypes = actionHelper(SEARCH_CLUSTER_ORDER_DRIVER_LICENCE_TYPES);
export const setOrderLabel = actionHelper(SEARCH_CLUSTER_ORDER_LABEL);

type InitialState = Record<NovaFilterKey, string[]>;
const INITIAL_STATE = Object.values(NovaFilterKey).reduce((result, item) => {
    result[item] = [];
    return result;
}, {} as InitialState);

export default createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [SEARCH_CLUSTER_ORDER_AREA]: (state, { payload }) => {
        state[NovaFilterKey.Area] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_DISTRICT]: (state, { payload }) => {
        state[NovaFilterKey.District] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_METRO]: (state, { payload }) => {
        state[NovaFilterKey.Metro] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_PROF_ROLE]: (state, { payload }) => {
        state[NovaFilterKey.ProfessionalRole] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_INDUSTRY]: (state, { payload }) => {
        state[NovaFilterKey.Industry] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_SUB_INDUSTRY]: (state, { payload }) => {
        state[NovaFilterKey.SubIndustry] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_SKILL]: (state, { payload }) => {
        state[NovaFilterKey.Skill] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_DRIVER_LICENCE_TYPES]: (state, { payload }) => {
        state[NovaFilterKey.DriverLicenseTypes] = [...payload];
        return state;
    },
    [SEARCH_CLUSTER_ORDER_LABEL]: (state, { payload }) => {
        state[NovaFilterKey.Label] = [...payload];
        return state;
    },
});
