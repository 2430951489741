import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerField {
    '@name': string;
    '@status': string;
    conditions: {
        part: Array<{
            '@minlength'?: number;
            '@maxlength'?: number;
            '@regexp'?: string;
            '@required'?: boolean;
        }>;
    };
}

export default autoGeneratedReducer<EmployerField[]>([]);
