import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem, VacancySnippet } from 'src/models/vacancySearch/vacancySearchItem.types';

export const SET_SNIPPET_DATA = 'SET_SNIPPET_DATA';

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setSnippetData = actionCreator(SET_SNIPPET_DATA);

interface PayloadTypes {
    [SET_SNIPPET_DATA]: VacancySnippet;
}

export default createReducer<VacancySearchItem, PayloadTypes>(null, {
    [SET_SNIPPET_DATA]: (state, action) => {
        return { ...state, snippet: action.payload };
    },
});
