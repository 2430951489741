import { Platform } from 'src/models/locale.types';

import { useSelector } from 'src/hooks/useSelector';

export const usePlatform = (): Platform => useSelector(({ locale }) => locale.platform);

export const useIsHeadHunterPlatform = (): boolean => {
    const platform = usePlatform();
    return platform === Platform.HeadHunter;
};

export const useIsZarplataPlatform = (): boolean => {
    const platform = usePlatform();
    return platform === Platform.Zarplata;
};

export default {
    usePlatform,
    useIsHeadHunterPlatform,
    useIsZarplataPlatform,
};
