import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

export interface Employer {
    id: number;
    name: string;
    organizationFormId: number | null;
    vacanciesOpen: number;
}

interface EmployerListState {
    totalEmployersFound: number;
    employers: Employer[];
    paging: Paging | null;
}

export default autoGeneratedReducer<EmployerListState>({ totalEmployersFound: 0, employers: [], paging: null });
