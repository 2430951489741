import { PlatformInfo } from 'src/models/platformInfo';

import { useSelector } from 'src/hooks/useSelector';

export const usePlatformInfo = (): PlatformInfo => useSelector(({ platformInfo }) => platformInfo);

export const useIsIosJTBApplicantWebView = (): boolean => {
    const platformInfo = usePlatformInfo();
    return (
        platformInfo.userPlatform === 'IOS' && platformInfo.appType === 'applicant' && platformInfo.appDomain === 'JTB'
    );
};

export const useIsIosEmployerWebViewSpecificVersion = (applicationVersion: string): boolean => {
    const platformInfo = usePlatformInfo();
    return (
        platformInfo.userPlatform === 'IOS' &&
        platformInfo.appType === 'employer' &&
        Boolean(applicationVersion) &&
        platformInfo.appVersion === applicationVersion
    );
};
