import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import Analytics from '@hh.ru/analytics-js';
import { Avatar, Cell, CellText, Divider, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getOnlineStatus, OnlineStatus } from 'Utils/LastActivityTime';
import FormatMoney from 'src/components/FormatMoney';
import { makeResumeHref } from 'src/components/ResumeCard/utils/makeResumeHref';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { ResumeSalary } from 'src/models/resume/resume.types';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { MIDDLE_DOT } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    jobSearchStatus: {
        [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.change.version2.active_search',
        [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.change.version2.looking_for_offers',
        [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.change.version2.has_job_offer',
        [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.change.version2.accepted_job_offer',
        [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.change.version2.not_looking_for_job',
        [JobSearchStatus.Unknown]: '',
    },
};

export interface VacancySuitableResume {
    title: string;
    resumeHash: string;
    workExperience: string | null;
    salary: ResumeSalary | null;
    jobSearchStatus: JobSearchStatus | null;
    lastActivityTime: string | null;
    avatar: string | null;
    gender: Gender;
}

interface ResumeProps {
    resume: VacancySuitableResume;
    vacancyId: number;
    index: number;
}

const Resume: TranslatedComponent<ResumeProps> = ({ resume, vacancyId, index, trls }) => {
    const isZP = useIsZarplataPlatform();
    const ref = useRef<HTMLDivElement>(null);

    const onlineStatus = resume.lastActivityTime && getOnlineStatus(resume.lastActivityTime);

    const analyticsParams = useMemo(
        () => ({
            resumeTitle: resume.title,
            resumeWorkExperience: resume.workExperience,
            resumeSalaryAmount: resume.salary?.amount,
            resumeSalaryCurrency: resume.salary?.currency,
            resumeJobSearchStatus: resume.jobSearchStatus,
            resumeIsOnline: onlineStatus === OnlineStatus.Online,
            resumeAvatar: !!resume.avatar,
            resumeIndex: index,
        }),
        [
            index,
            onlineStatus,
            resume.avatar,
            resume.jobSearchStatus,
            resume.salary?.amount,
            resume.salary?.currency,
            resume.title,
            resume.workExperience,
        ]
    );

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'vacancy_suitable_resumes_banner_resume',
                vacancyId,
                ...analyticsParams,
            });
        }
    }, [analyticsParams, vacancyId]);

    return (
        <div
            ref={ref}
            onClick={() => {
                Analytics.sendHHEventButtonClick('vacancy_suitable_resumes_banner_resume', {
                    vacancyId,
                    ...analyticsParams,
                });
            }}
        >
            {index !== 0 && <Divider />}
            <Cell
                align="top"
                right={<ChevronRightOutlinedSize16 initial="tertiary" />}
                left={
                    <Avatar
                        aria-label="avatar"
                        mode="image"
                        image={resume.avatar ? resume.avatar : undefined}
                        size={48}
                        fallbackMode="placeholder"
                        placeholder={resume.gender === Gender.Unknown ? 'undefined' : resume.gender}
                        online={!isZP && !!resume.lastActivityTime && onlineStatus === OnlineStatus.Online}
                    />
                }
                vertPadding
                Element={Link}
                to={makeResumeHref({
                    hash: resume.resumeHash,
                    vacancyId,
                    id: '',
                    topicId: '',
                })}
                target="_blank"
            >
                <CellText>
                    <Text style="primary" typography="label-3-regular" maxLines={2}>
                        {resume.title}
                    </Text>
                    <VSpacing default={2} />
                    <Text style="secondary" typography="label-3-regular" Element="span" maxLines={3}>
                        {resume.workExperience}
                        {!!resume.workExperience && !!resume.salary && ` ${MIDDLE_DOT} `}
                        {!!resume.salary && (
                            <span className={styles.salary}>
                                <FormatMoney currency={resume.salary.currency}>{resume.salary.amount}</FormatMoney>
                            </span>
                        )}
                    </Text>
                    {!!resume.jobSearchStatus && (
                        <>
                            <VSpacing default={2} />
                            <Text
                                style={
                                    [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers].includes(
                                        resume.jobSearchStatus
                                    )
                                        ? 'positive'
                                        : 'secondary'
                                }
                                typography="label-3-regular"
                            >
                                {trls[TrlKeys.jobSearchStatus[resume.jobSearchStatus]]}
                            </Text>
                        </>
                    )}
                </CellText>
            </Cell>
        </div>
    );
};

export default translation(Resume);
