import React from 'react';

import Text from 'bloko/blocks/text';

import { useProfile } from 'src/hooks/useProfile';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import AccountTypeBadge from 'src/components/SupernovaMainMenu/AccountTypeBadge';
import CurrentUserFullName from 'src/components/SupernovaMainMenu/CurrentUserFullName';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';

const ApplicantInfo: React.FC<NavItem & React.PropsWithChildren> = ({
    children,
    name,
    trackClicks,
    trackElementShown,
}) => {
    const isMultiAccount = useSelector((state) => state.session.isMultiAccount);
    const profile = useProfile(true, true);
    if (profile && !isMultiAccount && !children) {
        return null;
    }
    return (
        <SupernovaDropdownSection>
            {isMultiAccount && (
                <div className="supernova-dropdown-static-option">
                    <AccountTypeBadge />
                </div>
            )}
            {!profile && (
                <SupernovaDropdownOption
                    name={name}
                    trackClicks={trackClicks}
                    trackElementShown={trackElementShown}
                    url="/applicant/settings?from=header_new&hhtmFromLabel=header_new"
                >
                    <Text Element="span" strong>
                        <CurrentUserFullName />
                    </Text>
                </SupernovaDropdownOption>
            )}

            {children}
        </SupernovaDropdownSection>
    );
};

export default ApplicantInfo;
