import React, { FC } from 'react';

import floatingButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/chat/chat_floating_button_click';
import { useChatik } from '@hh.ru/chatik-integration';
import { Badge, useBreakpoint } from '@hh.ru/magritte-ui';
import { ChevronUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';

import styles from './float-chatik-activator.less';

// tempexp_PORTFOLIO-35883_start
const FloatChatikActivator: FC = () => {
    const { isChatikOpen, openChatik } = useChatik();
    const { isMobile } = useBreakpoint();
    const isHhru = useIsHhru();

    const userType = useSelector(({ userType }) => userType);
    const unreadCount = useSelector(({ chatikCounters }) => chatikCounters.unreadCount || 0);

    const isActivatorAvailable = isHhru && !isMobile;

    const isApplicantExp = useExperiment('gh_chat_icon_appl', isActivatorAvailable && userType === UserType.Applicant);
    const isEmployerExp = useExperiment('gh_chat_icon_emp', isActivatorAvailable && userType === UserType.Employer);

    const handleChatikOpen = () => {
        floatingButtonClick({ unreadChatCount: unreadCount });
        openChatik({ hhtmFromLabel: 'chat_floating', view: 'medium' });
    };

    if (!isApplicantExp && !isEmployerExp) {
        return null;
    }

    return !isChatikOpen ? (
        <div className={styles.floatChatikActivator} onClick={handleChatikOpen}>
            <span className={styles.logo} />
            <span className={styles.activatorTitle}>Чаты</span>
            <span className={styles.indicators}>
                {unreadCount > 0 && (
                    <Badge size="medium" style="accent">
                        {unreadCount > 99 ? '99+' : unreadCount.toString()}
                    </Badge>
                )}
                <span className={styles.icon}>
                    <ChevronUpOutlinedSize24 />
                </span>
            </span>
        </div>
    ) : null;
};
// tempexp_PORTFOLIO-35883_end

export default FloatChatikActivator;
