import { FC } from 'react';

import { Checkbox, VSpacing, Cell, CellText } from '@hh.ru/magritte-ui';
import BlokoCheckbox from 'bloko/blocks/checkbox';

import { useSelector } from 'src/hooks/useSelector';
import { PosOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

interface PosSelectOptionProps {
    option: PosOption;
    checked: boolean;
    indeterminate?: boolean;
    handleChange: (option: PosOption, checked: boolean) => void;
    isMagritte?: boolean;
}

const PosSelectOption: FC<PosSelectOptionProps> = ({
    option,
    checked,
    indeterminate = false,
    handleChange,
    isMagritte,
}) => {
    const preparedTrls = useSelector((state) => state.searchQueries?.trls[CriteriaKey.Pos]);

    if (!preparedTrls) {
        return null;
    }

    const dataQa = `resumes-search-wizard-${CriteriaKey.Pos}-${option}`;

    return (
        <>
            {isMagritte ? (
                <Cell
                    align="top"
                    left={
                        <Checkbox
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={() => {
                                handleChange(option, !checked);
                            }}
                            data-qa={dataQa}
                        />
                    }
                >
                    <CellText maxLines={3} data-qa="serp__novafilter-item-text">
                        {preparedTrls[option]}
                    </CellText>
                </Cell>
            ) : (
                <BlokoCheckbox
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={({ target }) => handleChange(option, target.checked)}
                    data-qa={dataQa}
                >
                    {preparedTrls[option]}
                </BlokoCheckbox>
            )}
            <VSpacing default={16} />
        </>
    );
};

export default PosSelectOption;
