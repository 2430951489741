import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import Debug from 'HHC/Debug';
import NovaFilters, { NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import {
    setOrderArea,
    setOrderDistrict,
    setOrderIndustry,
    setOrderMetro,
    setOrderProfRole,
    setOrderSubIndustry,
} from 'src/models/searchClustersOrder';
import { Vacancy, BBox, GeoCluster } from 'src/models/searchMapResponse';
import { replaceVacancySearchResult, VacancySearchResult } from 'src/models/vacancySearch/vacancySearchResult';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/vacancymap/searchvacancymap';

interface Response {
    clusters: NovaFilters;
    searchClustersOrder: Record<NovaFilterKey, string[]>;
    found: number;
    geoClusters: GeoCluster[];
    mapInit: { bbox: BBox };
    precision: number;
    query: string;
    sendAnalytics: boolean;
    vacancySearchResult: VacancySearchResult;
    vacancies: Vacancy[];
    mapHasGeoClusters: boolean;
}

declare global {
    interface FetcherGetApi {
        [URL]: {
            queryParams: unknown;
            response: Response;
        };
    }
}

const searchClustersAction = makeSetStoreField('searchClusters');
const searchMapResponseAction = makeSetStoreField('searchMapResponse');
const searchSessionIdAction = makeSetStoreField('searchSessionId');

interface FetchVacanciesProps {
    dispatch: Dispatch<unknown>;
    query: string;
    abortSignal?: AbortSignal;
}

const fetchMapVacancies =
    ({ dispatch, query, abortSignal }: FetchVacanciesProps) =>
    async (): Promise<void> => {
        let response;
        try {
            response = await fetcher.get<typeof URL>(`${URL}?${query}`, { signal: abortSignal, params: undefined });
        } catch (error) {
            Debug.log('error', error);
            return;
        }

        const { clusters, searchClustersOrder, vacancySearchResult } = response;

        window.dispatchEvent(new CustomEvent('HH-VacancySearchMapFilter-Changed', { detail: response }));

        const actions: AnyAction[] = [
            setOrderArea(searchClustersOrder?.[NovaFilterKey.Area] || []),
            setOrderDistrict(searchClustersOrder?.[NovaFilterKey.District] || []),
            setOrderMetro(searchClustersOrder?.[NovaFilterKey.Metro] || []),
            setOrderProfRole(searchClustersOrder?.[NovaFilterKey.ProfessionalRole] || []),
            setOrderIndustry(searchClustersOrder?.[NovaFilterKey.Industry] || []),
            setOrderSubIndustry(searchClustersOrder?.[NovaFilterKey.SubIndustry] || []),
            searchClustersAction(clusters),
            replaceVacancySearchResult(vacancySearchResult),
            searchMapResponseAction({
                vacanciesFromResponse: response.vacancies,
                geoClusters: response.geoClusters,
                mapHasGeoClusters: response.mapHasGeoClusters,
                query: response.vacancySearchResult.queries.map || '',
                clusters: response.clusters,
                vacancySearchResult,
                sendResponse: true,
            }),
        ];
        const searchSessionId = vacancySearchResult.criteria?.[CriteriaKey.SearchSessionId];
        if (searchSessionId) {
            actions.push(searchSessionIdAction(searchSessionId));
        }
        dispatch(actions);
    };

export default fetchMapVacancies;
