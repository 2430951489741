import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { StateId, DISCARD_STATE_IDS } from 'src/models/employerNegotiations/stateId.types';

export enum TransitionType {
    ChangeTopic = 'changeTopic',
    Invite = 'invite',
    InviteAnother = 'inviteAnother',
    InviteWithAssessements = 'inviteWithAssessements',
    Consider = 'consider',
    Discard = 'discard',
    Complaint = 'complaint',
    Mail = 'mail',
}

export interface NegotiationLink<N extends StateId = StateId> {
    name: N;
    url: string | (() => void);
}
export type DiscardNegotiationLink = NegotiationLink<(typeof DISCARD_STATE_IDS)[number]>;

export type NegotiationLinks = {
    [key in Exclude<TransitionType, TransitionType.Discard>]?: string;
} & { [TransitionType.Discard]?: DiscardNegotiationLink[] };

export default autoGeneratedReducer<Record<string, NegotiationLinks>>({});
