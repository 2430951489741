import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import { useSelector } from 'src/hooks/useSelector';

const useUserTypeForMenu = (userTypeForCheck: SupernovaUserType): boolean => {
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    if (userTypeForCheck === SupernovaUserType.Applicant) {
        return userType === UserType.Applicant || SupernovaUserType.Applicant === supernovaUserType;
    }

    return userType === UserType.Employer || SupernovaUserType.Employer === supernovaUserType;
};

export default useUserTypeForMenu;
