import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum PersonalManagerRatingValue {
    Excellent = 'EXCELLENT',
    Good = 'GOOD',
    Bad = 'BAD',
}

type RatingTags = {
    [key in PersonalManagerRatingValue]?: { tagId: string }[];
};

export default autoGeneratedReducer<RatingTags>(null);
