import React, { useRef, useState } from 'react';

import { BottomSheet, Drop, Link, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import { useNotification } from 'src/components/Notifications/Provider';
import { employerAccountSwitchError } from 'src/components/SupernovaMainMenu/EmployerAccountSwitcherNotification';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import EmployerAccountSwitcherOption from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerAccountSwitcherOption';
import EmployerNameWithBadge from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerNameWithBadge';
import useHandleAccountSwitcherData from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useHandleAccountSwitcherData';

enum EmployerAccountType {
    Implant = 'implant',
    Division = 'division',
}

interface EmployerAccountSwitcherProps {
    employerName: string;
    hasDivisions: boolean;
    hasImplants: boolean;
    isNotVerifiedEmployerBannerExp: boolean;
}

const CHANGE_DIVISION_URL = '/shards/employer/divisions/change';
const SWITCH_IMPLANT_URL = '/employer/implants/switch';

declare global {
    interface FetcherPostApi {
        [SWITCH_IMPLANT_URL]: {
            queryParams: {
                hhid: string;
            };
            body: void;
            response: void;
        };
    }

    interface FetcherPutApi {
        [CHANGE_DIVISION_URL]: {
            queryParams: {
                divisionId: string;
            };
            body: void;
            response: void;
        };
    }
}

const EmployerAccountSwitcher: React.FC<EmployerAccountSwitcherProps & React.PropsWithChildren> = ({
    employerName,
    hasDivisions,
    hasImplants,
    isNotVerifiedEmployerBannerExp,
}) => {
    const { addNotification } = useNotification();

    const currentDivision = useSelector((store) => store.employerCurrentDivision);
    const divisions = useSelector((store) => store.employerDivisions);
    const implants = useSelector((store) => store.employerImplants);
    const activatorRef = useRef<HTMLDivElement>(null);

    const [dropVisible, setDropVisible] = useState(false);

    useHandleAccountSwitcherData();

    const toggleDropVisible = () => {
        setDropVisible((prev) => !prev);
    };

    const handleCloseDrop = () => {
        setDropVisible(false);
    };

    const handleChange = React.useCallback(
        (type: EmployerAccountType, value: string | number) => {
            const request = (() => {
                switch (type) {
                    case EmployerAccountType.Division:
                        return fetcher.put(CHANGE_DIVISION_URL, undefined, {
                            params: { divisionId: value.toString() },
                        });
                    case EmployerAccountType.Implant:
                        return fetcher.post(SWITCH_IMPLANT_URL, undefined, {
                            params: { hhid: value.toString() },
                        });
                    default:
                        return null;
                }
            })();

            request?.then(
                () => {
                    window.location.reload();
                },
                () => {
                    addNotification(employerAccountSwitchError);
                }
            );
        },
        [addNotification]
    );

    const dropContent = (
        <VSpacingContainer default={8}>
            <EmployerAccountSwitcherOption onClick={handleCloseDrop} checked>
                {employerName}
                {currentDivision && ` (${currentDivision.divisionName})`}
            </EmployerAccountSwitcherOption>
            {hasDivisions &&
                Object.values(divisions).map(
                    ({ divisionId, divisionName }) =>
                        divisionId !== currentDivision?.divisionId && (
                            <EmployerAccountSwitcherOption
                                onClick={() => handleChange(EmployerAccountType.Division, divisionId)}
                                key={divisionId}
                            >
                                {employerName} ({divisionName})
                            </EmployerAccountSwitcherOption>
                        )
                )}
            {hasImplants &&
                implants?.extraAccounts.map(({ employer, hhid }) => (
                    <EmployerAccountSwitcherOption
                        onClick={() => handleChange(EmployerAccountType.Implant, hhid)}
                        key={hhid}
                    >
                        {employer.name}
                    </EmployerAccountSwitcherOption>
                ))}
        </VSpacingContainer>
    );

    return (
        <>
            <div ref={activatorRef}>
                <Link onClick={toggleDropVisible} Element="button" data-qa="employer-account-switcher">
                    <EmployerNameWithBadge
                        isNotVerifiedEmployerBannerExp={isNotVerifiedEmployerBannerExp}
                        rightIcon={
                            dropVisible ? (
                                <ChevronUpOutlinedSize16 initial="secondary" />
                            ) : (
                                <ChevronDownOutlinedSize16 initial="secondary" />
                            )
                        }
                        employerName={employerName}
                    />
                </Link>
            </div>
            <Drop
                placement="bottom-center"
                width={432}
                space={300}
                visible={dropVisible}
                onClose={handleCloseDrop}
                activatorRef={activatorRef}
                closeByClickOutside
            >
                {dropContent}
            </Drop>
            <BottomSheet onClose={handleCloseDrop} visible={dropVisible}>
                {dropContent}
                <VSpacing default={16} />
            </BottomSheet>
        </>
    );
};

export default EmployerAccountSwitcher;
