import { ComponentType } from 'react';

import { ComplexUserNotificationsTemplateKey, SimpleUserNotificationsTemplateKey } from 'src/models/userNotifications';

import {
    ComplexNotificationComponentProps,
    SimpleNotificationComponentProps,
} from 'src/components/UserNotifications/common/notificationComponentProps';
import AdvertisementAgreement from 'src/components/UserNotifications/variants/AdvertisementAgreement';
import ApplicantFeedbackAboutVacancy from 'src/components/UserNotifications/variants/ApplicantFeedbackAboutVacancy';
import ApplicantJobSearchStatus from 'src/components/UserNotifications/variants/ApplicantJobSearchStatus';
import AutoInviteDisabled from 'src/components/UserNotifications/variants/AutoInviteDisabled';
import AutoInviteEnabled from 'src/components/UserNotifications/variants/AutoInviteEnabled';
import baseNotificationFactory from 'src/components/UserNotifications/variants/BaseNotification';
import BillingCartActivation from 'src/components/UserNotifications/variants/BillingCartActivation';
import BrandingTriggerUserNotification from 'src/components/UserNotifications/variants/BrandingTriggerUserNotification';
import CallTracking from 'src/components/UserNotifications/variants/CallTracking';
import EmailVerificationErrorAgain from 'src/components/UserNotifications/variants/EmailVerificationErrorAgain';
import EmailVerificationWasSent from 'src/components/UserNotifications/variants/EmailVerificationWasSent';
import EmployerHhRatingResults from 'src/components/UserNotifications/variants/EmployerHhRatingResults';
import EmployerItAccreditation from 'src/components/UserNotifications/variants/EmployerItAccreditation';
import EmployerOverspending from 'src/components/UserNotifications/variants/EmployerOverspending';
import EmployerPricesIncrease from 'src/components/UserNotifications/variants/EmployerPricesIncrease';
import EmployerYearResults from 'src/components/UserNotifications/variants/EmployerYearResults';
import EsiaAccountNotLinked from 'src/components/UserNotifications/variants/EsiaAccountNotLinked';
import GreetingsFromZarplata from 'src/components/UserNotifications/variants/GreetingsFromZarplata';
import HHRating from 'src/components/UserNotifications/variants/HHRating';
import HRSpace from 'src/components/UserNotifications/variants/HRSpace';
import LoyaltySurvey from 'src/components/UserNotifications/variants/LoyaltySurvey';
import NewYear from 'src/components/UserNotifications/variants/NewYear/NewYear';
import ResumeMergeSuccess from 'src/components/UserNotifications/variants/ResumeMergeSuccess';
import ScheduleInterview from 'src/components/UserNotifications/variants/ScheduleInterview';
import SetkaPromo from 'src/components/UserNotifications/variants/SetkaPromo';
import SiteWasUnavailable from 'src/components/UserNotifications/variants/SiteWasUnavailable';
import SkillVerificationExpiring from 'src/components/UserNotifications/variants/SkillVerificationExpiring';
import VacancyBlocked from 'src/components/UserNotifications/variants/VacancyBlocked';
import VacancyDraftPublicationReady from 'src/components/UserNotifications/variants/VacancyDraftPublicationReady';
import VacancyDraftSaved from 'src/components/UserNotifications/variants/VacancyDraftSaved';
import VacancyScheduledPublication from 'src/components/UserNotifications/variants/VacancyScheduledPublication';
import WorkCloseToHome from 'src/components/UserNotifications/variants/WorkCloseToHome';
import YouthSpecialProject from 'src/components/UserNotifications/variants/YouthSpecialProject';

type UserNotificationConfig = {
    [key in
        | SimpleUserNotificationsTemplateKey
        | ComplexUserNotificationsTemplateKey]?: key extends SimpleUserNotificationsTemplateKey
        ? ComponentType<SimpleNotificationComponentProps>
        : ComponentType<ComplexNotificationComponentProps>;
};

const userNotificationsConfig: UserNotificationConfig = {
    [SimpleUserNotificationsTemplateKey.SiteWasUnavailable]: SiteWasUnavailable,
    [SimpleUserNotificationsTemplateKey.GreetingsFromZarplata]: GreetingsFromZarplata,
    [SimpleUserNotificationsTemplateKey.EmployerOverspendingInfo]: EmployerOverspending,
    [SimpleUserNotificationsTemplateKey.EmailVerificationErrorAgain]: EmailVerificationErrorAgain,
    [SimpleUserNotificationsTemplateKey.EmailVerificationWasSent]: EmailVerificationWasSent,
    [SimpleUserNotificationsTemplateKey.EmailVerificationWasSentAgain]: EmailVerificationWasSent,
    [SimpleUserNotificationsTemplateKey.EmailVerificationCompleted]: baseNotificationFactory({ synchronized: false }),
    [SimpleUserNotificationsTemplateKey.EmailVerificationError]: baseNotificationFactory({
        synchronized: false,
        error: true,
    }),
    [SimpleUserNotificationsTemplateKey.EmailVerificationWasSentAgainTooOften]: baseNotificationFactory({
        error: true,
    }),
    [SimpleUserNotificationsTemplateKey.EmailVerificationWasSentAgainError]: baseNotificationFactory({ error: true }),
    [SimpleUserNotificationsTemplateKey.ManagerQuotaChangeFail]: baseNotificationFactory({ error: true }),
    [SimpleUserNotificationsTemplateKey.EsiaAccountNotLinked]: EsiaAccountNotLinked,
    [SimpleUserNotificationsTemplateKey.OauthConnectInternalError]: baseNotificationFactory({
        error: true,
        autoClose: true,
    }),
    [SimpleUserNotificationsTemplateKey.SurveyWasIgnored]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.SurveyWasFinished]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.PhoneHasBeenVerified]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.ManagerQuotaChangeSuccess]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.AllSurveysWereIgnored]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.AccountDeleted]: baseNotificationFactory(),
    [SimpleUserNotificationsTemplateKey.ScheduleInterview]: ScheduleInterview,
    [SimpleUserNotificationsTemplateKey.LoyaltySurvey]: LoyaltySurvey,
    [SimpleUserNotificationsTemplateKey.VacancyDraftSaved]: VacancyDraftSaved,
    [SimpleUserNotificationsTemplateKey.ResumeMergeSuccess]: ResumeMergeSuccess,
    [SimpleUserNotificationsTemplateKey.EmployerHhRatingResultsForApplicants]: EmployerHhRatingResults,
    [SimpleUserNotificationsTemplateKey.EmployerHhRatingResultsForEmployers]: EmployerHhRatingResults,
    [SimpleUserNotificationsTemplateKey.SkillVerificationExpiring]: SkillVerificationExpiring,
    [SimpleUserNotificationsTemplateKey.NewYear]: NewYear,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoQuotasFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPermissionFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPublicationFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationValidationFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationUnknownFailure]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess]: VacancyScheduledPublication,
    [ComplexUserNotificationsTemplateKey.VacancyDraftPublicationReady]: VacancyDraftPublicationReady,
    [ComplexUserNotificationsTemplateKey.EmployerPricesIncrease]: EmployerPricesIncrease,
    [ComplexUserNotificationsTemplateKey.ApplicantFeedbackAboutVacancy]: ApplicantFeedbackAboutVacancy,
    [ComplexUserNotificationsTemplateKey.CallTracking]: CallTracking,
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: HHRating,
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden]: BillingCartActivation,
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationExpired]: BillingCartActivation,
    [ComplexUserNotificationsTemplateKey.EmployerYearResults]: EmployerYearResults,
    [ComplexUserNotificationsTemplateKey.EmployerItAccreditation]: EmployerItAccreditation,
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: HHRating,
    // == Branding start ==
    [ComplexUserNotificationsTemplateKey.SuggestionVacancyMakeup]: BrandingTriggerUserNotification,
    [ComplexUserNotificationsTemplateKey.SuggestionEmployerMakeup]: BrandingTriggerUserNotification,
    [ComplexUserNotificationsTemplateKey.FillEmployerPageConstructor]: BrandingTriggerUserNotification,
    [ComplexUserNotificationsTemplateKey.FillEmployerVacancyPageConstructor]: BrandingTriggerUserNotification,
    [ComplexUserNotificationsTemplateKey.BuyVacancyPageConstructor]: BrandingTriggerUserNotification,
    [ComplexUserNotificationsTemplateKey.BuyEmployerPageConstructor]: BrandingTriggerUserNotification,
    // == Branding end ==
    [ComplexUserNotificationsTemplateKey.ApplicantJobSearchStatus]: ApplicantJobSearchStatus,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]: HRSpace,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]: HRSpace,
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]: HRSpace,
    // tempexp_28607_next-line
    [ComplexUserNotificationsTemplateKey.WorkCloseToHome]: WorkCloseToHome,
    [ComplexUserNotificationsTemplateKey.AdvertisementAgreement]: AdvertisementAgreement,
    [ComplexUserNotificationsTemplateKey.AutoInviteDisabled]: AutoInviteDisabled,
    [ComplexUserNotificationsTemplateKey.AutoInviteEnabled]: AutoInviteEnabled,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueReplacement]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueModerator]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueTraining]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueBadDescription]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDuePaidServices]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueCaptionMissing]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueRelocateFromBelarus]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.VacancyBlockedDueTwoInOne]: VacancyBlocked,
    [ComplexUserNotificationsTemplateKey.SetkaPromo]: SetkaPromo,
    [ComplexUserNotificationsTemplateKey.YouthSpecialProject]: YouthSpecialProject,
};

export default userNotificationsConfig;
