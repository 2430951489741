import { useState, ChangeEvent, useCallback, useRef } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import FormItem from 'bloko/blocks/form/FormItem';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip from 'src/components/Infotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    showConditions: 'employer.resumesSearch.showConditions',
    infotipContent: 'infotips.content.resumeSearchConditionsCheckbox',
};

const ShowConditions: TranslatedComponent = ({ trls }) => {
    const showSearchConditions = useSelector((state) => state.advancedSearch.showSearchConditions);
    const [checked, setChecked] = useState(showSearchConditions);
    const activatorRef = useRef(null);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }, []);
    return (
        <Column xs="4" s="8" m="5" l="7">
            <Infotip
                name="resumeSearchConditionsCheckbox"
                render={() => trls[TrlKeys.infotipContent]}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                show
                activatorRef={activatorRef}
            >
                <FormItem innerRef={activatorRef}>
                    {!checked && <input type="hidden" name="show_conditions" value="false" />}
                    <Checkbox
                        name="show_conditions"
                        value="true"
                        checked={checked}
                        onChange={onChange}
                        data-qa="resumesearch__show_conditions"
                    >
                        {trls[TrlKeys.showConditions]}
                    </Checkbox>
                </FormItem>
            </Infotip>
        </Column>
    );
};

export default translation(ShowConditions);
