import { useRef } from 'react';

import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    content: 'notifications.siteWasUnavailable.content',
    link: 'notifications.siteWasUnavailable.link',
};

const SiteWasUnavailable: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <SynchronizedNotification
            analyticsElementName="site-was-unavailable"
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
        >
            {trls[TrlKeys.content]}
            {NON_BREAKING_SPACE}
            <Link
                onClick={() => closeNotificationRef.current?.()}
                target="_blank"
                href={`/article/24746?from=notification&hhtmFrom=${hhtmSource}&hhtmFromLabel=user_notification`}
            >
                {trls[TrlKeys.link]}
            </Link>
        </SynchronizedNotification>
    );
};

export default translation(SiteWasUnavailable);
