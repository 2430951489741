import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import fetcher from 'src/utils/fetcher';

import { setVacanciesTabs, VacanciesTabsState } from 'src/models/employerVacancies/vacanciesTabs';

export enum VacanciesType {
    Active = 'active',
    Drafts = 'drafts',
    Archived = 'archived',
    Templates = 'templates',
    Unpremoderated = 'unpremoderated',
    Vr = 'vr',
    HiringManager = 'hiringManager',
}

export interface VacanciesCountState {
    [VacanciesType.Active]: {
        active: number;
        autoPublications: number;
        scheduledPublications: number;
        totalCount: number;
    };
    [VacanciesType.Drafts]: {
        totalCount: number;
    };
    [VacanciesType.Archived]: {
        totalCount: number;
    };
    deleted: {
        totalCount: number;
    };
    [VacanciesType.Templates]: {
        personalCount: number;
        sharedCount: number;
        globalCount: number;
        totalCount: number;
    };
    [VacanciesType.Unpremoderated]: {
        totalCount: number;
    };
    [VacanciesType.Vr]: {
        totalCount: number;
    };
    [VacanciesType.HiringManager]: {
        totalCount: number;
    };
}

export const setVacanciesCount = makeSetStoreField('vacanciesCount');

const VACANCIES_COUNT_URL = '/shards/employer/vacancies/count';

declare global {
    interface FetcherGetApi {
        [VACANCIES_COUNT_URL]: {
            queryParams: {
                managerId?: number;
                withTabs?: boolean;
            };
            response: {
                vacanciesCount: VacanciesCountState;
                tabs?: VacanciesTabsState;
            };
        };
    }
}

export const fetchAndUpdateVacanciesCount = async (
    dispatch: Dispatch<AnyAction>,
    employerManagerId?: number,
    withTabs?: boolean
): Promise<void> => {
    try {
        const response = await fetcher.get(VACANCIES_COUNT_URL, { params: { managerId: employerManagerId, withTabs } });
        dispatch(setVacanciesCount(response.vacanciesCount));
        if (withTabs && response.tabs) {
            dispatch(setVacanciesTabs(response.tabs));
        }
    } catch (error) {
        console.error(error);
    }
};

export default autoGeneratedReducer<VacanciesCountState>(null);
