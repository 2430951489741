import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Subscription {
    state: boolean;
    isDisabled: boolean;
    label: string;
    type: string;
}

interface SubscriptionInformer {
    isShow: boolean;
    applicantSubscriptions: Record<'email' | 'sms' | 'push', { disabled: boolean; subscription: Subscription[] }>;
    employerSubscriptions: {
        email: {
            mailingSubscriptionTypes: { mailingSubscriptionType: number[] }[];
            suitableResumesSubscriptionActive: boolean;
            eventMailingSubscriptionActive: boolean;
        };
        sms: {
            siteNewsAndAdvertising: boolean;
        };
    };
}

export default autoGeneratedReducer<SubscriptionInformer>({
    isShow: false,
    applicantSubscriptions: {
        email: {
            disabled: false,
            subscription: [],
        },
        sms: {
            disabled: false,
            subscription: [],
        },
        push: {
            disabled: false,
            subscription: [],
        },
    },
    employerSubscriptions: {
        email: {
            mailingSubscriptionTypes: [],
            suitableResumesSubscriptionActive: false,
            eventMailingSubscriptionActive: false,
        },
        sms: {
            siteNewsAndAdvertising: false,
        },
    },
});
