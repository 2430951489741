import React, { ComponentType } from 'react';

import { Button } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

type Props = {
    error: boolean;
    value: string;
    onAgain: () => void;
    onSelect: () => Promise<void>;
};

const TrlKeys = {
    again: 'zp.importResume.v2.copy.again',
    okay: 'zp.importResume.copy.okay',
};

const CopyFooter: ComponentType<Props> = translation(({ trls, value, error, onAgain, onSelect }) => {
    return error ? (
        <Button style="accent" mode="primary" onClick={onAgain}>
            {trls[TrlKeys.again]}
        </Button>
    ) : (
        <Button
            style="accent"
            mode="primary"
            onClick={onSelect}
            data-qa="import_resume-resume-import_button"
            disabled={!value}
        >
            {trls[TrlKeys.okay]}
        </Button>
    );
});

export default CopyFooter;
