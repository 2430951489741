import { createElement } from 'react';
import axios, { AxiosError } from 'axios';

import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import { isAccountLoginRequiredError } from 'Modules/AccountLoginInterceptor';
import defaultError from 'src/components/Notifications/DefaultError';
import networkError from 'src/components/Notifications/NetworkError';
import Notification from 'src/components/Notifications/Notification';
import { AddNotification } from 'src/components/Notifications/Provider/types';

interface RequestError {
    errorType: 'changeLocation' | 'networkError' | 'serverError';
    needToShowNotification: boolean;
}

export const extractRequestError = (error: unknown): RequestError => {
    const axiosError = error as AxiosError;
    const locationCanceler = {
        errorType: 'changeLocation',
        needToShowNotification: false,
    } as const;
    if (axios.isCancel(axiosError)) {
        return locationCanceler;
    }
    if (!('response' in axiosError)) {
        console.error('Not an axios error.', axiosError);
        return {
            errorType: 'serverError',
            needToShowNotification: false,
        };
    }
    const luxPageDisabled = axiosError.response && axiosError.response.status === 406;
    if (luxPageDisabled) {
        return locationCanceler;
    }
    const isNetworkError = !axiosError.response;
    if (isNetworkError) {
        return { errorType: 'networkError', needToShowNotification: true };
    }
    console.error('Error on data fetch.', axiosError);
    if (!isAccountLoginRequiredError(axiosError)) {
        return { errorType: 'serverError', needToShowNotification: true };
    }
    return { errorType: 'serverError', needToShowNotification: false };
};

const defaultRequestErrorHandler = (
    error: unknown,
    addNotification: AddNotification,
    uniqueType = false
): Omit<RequestError, 'needToShowNotification'> => {
    const errorInfo = extractRequestError(error);
    if (errorInfo.needToShowNotification) {
        const notification = errorInfo.errorType === 'networkError' ? networkError : defaultError;
        addNotification(notification, { uniqueType });
    }
    return {
        errorType: errorInfo.errorType,
    };
};

export const defaultMagritteRequestErrorHandler = (
    error: unknown,
    addNotification: AddNotification,
    uniqueType = false
): Omit<RequestError, 'needToShowNotification'> => {
    const errorInfo = extractRequestError(error);
    if (errorInfo.needToShowNotification) {
        const notification = errorInfo.errorType === 'networkError' ? networkError : defaultError;
        addNotification(Notification, {
            uniqueType,
            props: {
                children: createElement(notification.Element),
                showClose: false,
                addon: createElement(MinusCircleFilledSize24, { initial: 'negative' }),
            },
        });
    }
    return {
        errorType: errorInfo.errorType,
    };
};

export default defaultRequestErrorHandler;
