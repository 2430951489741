import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface EmployerDeedsListState {
    deeds: BillingDeed[];
}

export interface BillingDeed {
    id: string;
    name: string;
    number: string;
    price: number;
    creationTime: string;
    payerId: number;
    bills: string;
    deedFileUrl: string;
    invoiceFileUrl: string;
}

export const INITIAL_STATE: EmployerDeedsListState = {
    deeds: [],
};

export default autoGeneratedReducer<EmployerDeedsListState>(INITIAL_STATE);
