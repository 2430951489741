import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { BannerPlace, type BannerObject } from 'src/models/banners';

export interface Banner extends BannerObject {
    pp?: string;
    ps?: string;
    p2?: string;
    ownerId?: string;
    shouldHideExternalBanners?: boolean;
    useAdfoxMock?: boolean;
}

export default autoGeneratedReducer<Partial<Record<BannerPlace, Banner[]>>>({});
