import React, { useContext } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaLink from 'src/components/SupernovaMainMenu/NaviLink';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const VISIBLE_ON = [Breakpoint.M, Breakpoint.L];

const MyResumes: React.FC<NavItem> = ({ active, counter, name, trackClicks, analytics, translations, url }) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const userStat = useSelector(({ userStats }) => (counter && userStats ? userStats[counter] : null));

    if (isRedesignActive) {
        return (
            <SupernovaNaviItem active={active} visibleOn={VISIBLE_ON}>
                <SupernovaActionLink
                    name={name}
                    data-qa={`mainmenu_${name}`}
                    active={active}
                    trackClicks={trackClicks}
                    showBadge={!!userStat}
                    badgeCount={userStat || undefined}
                    analytics={analytics}
                    url={url}
                >
                    {translations.name}
                </SupernovaActionLink>
            </SupernovaNaviItem>
        );
    }
    return (
        <SupernovaNaviItem active={active} visibleOn={VISIBLE_ON}>
            <SupernovaLink
                active={active}
                data-qa={`mainmenu_${name}`}
                name={name}
                trackClicks={trackClicks}
                url={url}
                analytics={analytics}
            >
                {translations.name}
                {!!userStat && <SupernovaCounter>•</SupernovaCounter>}
            </SupernovaLink>
            <div className="supernova-navi-underline">{translations.name}</div>
        </SupernovaNaviItem>
    );
};

export default MyResumes;
