import { FC } from 'react';

import useIsClient from 'src/hooks/useIsClient';

import WebcallPreloader from 'src/components/Webcall/Preloader';
import WebcallDynamicWrapper from 'src/components/Webcall/WebcallDynamicWrapper';

const WebcallDynamicActivator: FC = () => {
    const isClient = useIsClient();

    if (isClient) {
        return (
            <>
                <WebcallDynamicWrapper />
                <WebcallPreloader dynamic />
            </>
        );
    }

    return null;
};

export default WebcallDynamicActivator;
