import { ComponentType, useMemo, useContext } from 'react';

import useTranslations, { LangTrls, T, TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

import CacheTranslationsContext from 'src/components/translation/CacheTranslationsContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translation = <P extends Record<string, any>>(
    WrappedComponent: ComponentType<TranslationHOCProps & P>
): ComponentType<P> => {
    const TranslationWrapper = (props: P) => {
        const trl = useSelector((state) => state.trl);
        const defaultLang = useSelector((state) => state.langs[0]);
        const trls = useTranslations(WrappedComponent, trl, defaultLang, !!process.env.SSR);
        const needCaching = useContext(CacheTranslationsContext);
        const trlsCached = useMemo(() => {
            return needCaching ? trls : (null as unknown as LangTrls);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [needCaching]);
        return <T {...props} trls={needCaching ? trlsCached : trls} Element={WrappedComponent} />;
    };

    return TranslationWrapper;
};

export default translation;
