import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useReplace } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';

import { FROM_URL_TO_LOCAL_PARAMS_MAP } from 'src/components/VacancyTargetingViewer/const';
import {
    computeFirstParamsFromUrlParams,
    CONDITIONS_TO_REPLACE_FROM_TEMPORARY_STORAGE,
} from 'src/components/VacancyTargetingViewer/vacancyTargetingViewerUtils';

const Features = {
    cpaTargetByVacancyIdStoreTimeMs: 'cpa_target_by_vacancyId_store_time',
    cpaTargetByVacancyAreaIdStoreTimeMs: 'cpa_target_by_vacancyAreaId_store_time',
};

const VacancyTargetingViewer = (): null => {
    const { pathname, search } = useSelector(({ router: { location } }) => location);
    const cpaTargetByVacancyIdStoreTimeMs = useSelector(
        ({ features }) => features[Features.cpaTargetByVacancyIdStoreTimeMs]
    );
    const cpaTargetByVacancyAreaIdStoreTimeMs = useSelector(
        ({ features }) => features[Features.cpaTargetByVacancyAreaIdStoreTimeMs]
    );
    const replace = useReplace();

    const dispatch = useDispatch();

    useEffect(() => {
        const url = urlParser(pathname + search);
        const params = computeFirstParamsFromUrlParams(FROM_URL_TO_LOCAL_PARAMS_MAP, url.params);
        const hasParamsValues = params && Object.values(params).filter((value) => value !== undefined).length > 0;
        const hasInconsistentCpaParams =
            hasParamsValues && params.cpaOfferAliasId && !params.cpaTargetVacancyAreaId && !params.cpaTargetVacancyId;

        if (!hasParamsValues || hasInconsistentCpaParams) {
            return;
        }

        CONDITIONS_TO_REPLACE_FROM_TEMPORARY_STORAGE.forEach((condition) =>
            condition({
                params,
                urlParams: url.params,
                features: {
                    cpaTargetByVacancyIdStoreTimeMs,
                    cpaTargetByVacancyAreaIdStoreTimeMs,
                },
            })
        );

        replace(url.href, undefined, { cancelFetchingData: true });
    }, [cpaTargetByVacancyAreaIdStoreTimeMs, cpaTargetByVacancyIdStoreTimeMs, dispatch, pathname, replace, search]);

    return null;
};

export default VacancyTargetingViewer;
