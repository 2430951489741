import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './footer-mobile-banner.less';

interface MobileBannerImageProps {
    hasAppLink: boolean;
}

const MobileBannerImage: FC<MobileBannerImageProps & PropsWithChildren> = ({ hasAppLink }) => (
    <div
        className={classnames(styles.footerMobileBannerImage, {
            [styles.footerMobileBannerImageApp]: hasAppLink,
        })}
    >
        <div className={styles.footerMobileBannerImagePhone}>
            <div className={styles.footerMobileBannerImageInvite}></div>
        </div>
    </div>
);

export default MobileBannerImage;
