import { Cell, CellText, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ActivityEvent, ActivityEventScore } from 'src/models/applicantActivityEvents';

const TrlKeys = {
    [ActivityEvent.VacancyView]: 'applicant.gamification.event.vacancy_view',
    [ActivityEvent.VacancyResponse]: 'applicant.gamification.event.vacancy_response',
    [ActivityEvent.VacancyShowContacts]: 'applicant.gamification.event.vacancy_show_contacts',
};

interface ActivityEventScoreListProps {
    eventScores: ActivityEventScore[];
}

const ActivityEventScoreList: TranslatedComponent<ActivityEventScoreListProps> = ({ trls, eventScores }) => {
    const reversedScores = [...eventScores].reverse();

    return (
        <ul>
            {reversedScores.map(({ event, eventScore }, index) => (
                <li key={event}>
                    <Cell
                        vertPadding={true}
                        showDivider={index + 1 !== reversedScores.length}
                        right={<Text style="secondary" typography="label-2-regular">{`+${eventScore}%`}</Text>}
                    >
                        <CellText>{trls[TrlKeys[event]]}</CellText>
                    </Cell>
                </li>
            ))}
        </ul>
    );
};

export default translation(ActivityEventScoreList);
