import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ResumeBaseFields } from 'src/models/resume/resume.types';

interface ModerationNote {
    value: string;
    field: keyof ResumeBaseFields;
    block: string;
    general: boolean | null;
    significant: boolean;
}

export default autoGeneratedReducer<ModerationNote[]>([]);
