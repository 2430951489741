import { UserType } from 'src/models/userType';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

const useIsRedesignGeoExpActive = (): boolean => {
    const userType = useSelector(({ userType }) => userType);

    const isRedesignGeoApplicantOrAnonExpActive = useExperiment('redesign_applicant_anon_geo', true, false);
    const isRedesignGeoEmployerExpActive = useExperiment('redesign_employer_geo', true, false);

    return (
        ([UserType.Anonymous, UserType.Applicant].includes(userType) && isRedesignGeoApplicantOrAnonExpActive) ||
        (userType === UserType.Employer && isRedesignGeoEmployerExpActive)
    );
};

export default useIsRedesignGeoExpActive;
