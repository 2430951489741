import { useEffect, useState, useRef, FC } from 'react';

import Debug from 'HHC/Debug';
import { useSelector } from 'src/hooks/useSelector';
import {
    isComplexUserNotification,
    isSimpleUserNotification,
    UserNotification,
    UserNotifications,
} from 'src/models/userNotifications';

import config from 'src/components/UserNotifications/config';

const DISABLED_NOTIFICATIONS_ON_FRONT = ['employerUniversity'];

const logUnknownNotificationKey = (key: string) => {
    const sampleRate = 0.2;
    const isSampled = Math.random() < sampleRate;

    if (isSampled && !DISABLED_NOTIFICATIONS_ON_FRONT.includes(key)) {
        Debug.log('out', 'Unknown priority user notification template key', {
            explain: key,
        });
    }
};

const UserNotificationsManager: FC = () => {
    const shownNotifications = useRef<UserNotification[]>([]);
    const userNotifications = useSelector(({ userNotifications }) => userNotifications);
    const [notifications, setNotifications] = useState<UserNotifications>([]);
    useEffect(() => {
        const notShownNotifications = userNotifications.reduce<UserNotifications>((result, notification) => {
            let index;
            if (isComplexUserNotification(notification)) {
                if (!notification.isPriority) {
                    return result;
                }
                const notificationId = notification.id;
                index = shownNotifications.current.findIndex(
                    (notification) => isComplexUserNotification(notification) && notification.id === notificationId
                );
            } else {
                const notificationId = notification.templateKey;
                index = shownNotifications.current.findIndex(
                    (notification) =>
                        isSimpleUserNotification(notification) && notification.templateKey === notificationId
                );
            }
            if (index === -1) {
                result.push(notification);
            }
            return result;
        }, []);

        if (notShownNotifications.length) {
            shownNotifications.current.push(...notShownNotifications);
            setNotifications((notifications) => [...notifications, ...notShownNotifications]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNotifications]);
    const closeHandler = (notification: UserNotification) => {
        if (isComplexUserNotification(notification)) {
            setNotifications((notifications) =>
                notifications.filter((item) => isSimpleUserNotification(item) || item.id !== notification.id)
            );
            return;
        }
        setNotifications((notifications) =>
            notifications.filter(
                (item) => isComplexUserNotification(item) || item.templateKey !== notification.templateKey
            )
        );
    };

    return (
        <>
            {notifications.map((notification) => {
                if (isComplexUserNotification(notification)) {
                    const Notification = config[notification.templateKey];
                    if (!Notification) {
                        logUnknownNotificationKey(notification.templateKey);
                        return null;
                    }

                    return (
                        <Notification
                            key={notification.id || notification.templateKey}
                            onClose={() => closeHandler(notification)}
                            {...notification}
                        />
                    );
                }

                const Notification = config[notification.templateKey];
                if (!Notification) {
                    logUnknownNotificationKey(notification.templateKey);
                    return null;
                }
                return (
                    <Notification
                        key={notification.templateKey}
                        onClose={() => closeHandler(notification)}
                        {...notification}
                    />
                );
            })}
        </>
    );
};

export default UserNotificationsManager;
