import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'notification.employer.autoProlongationCouldBeCanceled.planned',
    planedOne: 'notification.employer.autoProlongationCouldBeCanceled.planned.one',
    planedSome: 'notification.employer.autoProlongationCouldBeCanceled.planned.some',
    planedMany: 'notification.employer.autoProlongationCouldBeCanceled.planned.many',
    planedZero: 'notification.employer.autoProlongationCouldBeCanceled.planned.zero',
    vacancyOne: 'notification.employer.autoProlongationCouldBeCanceled.autoprolongation.one',
    vacancySome: 'notification.employer.autoProlongationCouldBeCanceled.autoprolongation.some',
    vacancyMany: 'notification.employer.autoProlongationCouldBeCanceled.autoprolongation.many',
    vacancyZero: 'notification.employer.autoProlongationCouldBeCanceled.autoprolongation.zero',
    canceledOne: 'notification.employer.autoProlongationCouldBeCanceled.couldbecanceled.one',
    canceledSome: 'notification.employer.autoProlongationCouldBeCanceled.couldbecanceled.some',
    canceledMany: 'notification.employer.autoProlongationCouldBeCanceled.couldbecanceled.many',
    canceledZero: 'notification.employer.autoProlongationCouldBeCanceled.couldbecanceled.zero',
    lastContent: 'notification.employer.autoProlongationCouldBeCanceled.publicationsRequired',
    link: 'notification.employer.autoProlongationCouldBeCanceled.link',
};

const VacancyAutoProlongation: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return (
        <Notification {...notification}>
            <NotificationText>
                <ConversionNumber
                    hasValue={false}
                    value={parseInt(notification.params.vacancy_count, 10)}
                    one={trls[TrlKeys.planedOne]}
                    some={trls[TrlKeys.planedSome]}
                    many={trls[TrlKeys.planedMany]}
                    zero={trls[TrlKeys.planedZero]}
                />{' '}
                <HumanDate textify date={notification.params.prolongation_date} showYear={ShowYear.Never} />{' '}
                <ConversionNumber
                    value={parseInt(notification.params.vacancy_count, 10)}
                    formatValue={(value) => NumberFormatter.format(String(value))}
                    one={trls[TrlKeys.vacancyOne]}
                    some={trls[TrlKeys.vacancySome]}
                    many={trls[TrlKeys.vacancyMany]}
                    zero={trls[TrlKeys.vacancyZero]}
                />{' '}
                <ConversionNumber
                    hasValue={false}
                    value={parseInt(notification.params.vacancy_count, 10)}
                    one={trls[TrlKeys.canceledOne]}
                    some={trls[TrlKeys.canceledSome]}
                    many={trls[TrlKeys.canceledMany]}
                    zero={trls[TrlKeys.canceledZero]}
                />
                <p>&#8212; {trls[TrlKeys.lastContent]}</p>
            </NotificationText>
            <NotificationLink to="/employer/vacancies" target="_blank">
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(VacancyAutoProlongation);
