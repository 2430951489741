import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface BrandSnippet {
    id: string;
    name: string;
}

interface BrandSnippets {
    remainingApplyCount: number;
    templates: BrandSnippet[];
}

export default autoGeneratedReducer<BrandSnippets>({
    remainingApplyCount: 0,
    templates: [],
});
