import { FC, PropsWithChildren } from 'react';

import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

interface ChatikNotificationProps {
    content?: string;
    title?: string;
}

const ChatikNotification: FC<ChatikNotificationProps & PropsWithChildren> = ({ content, title }) => {
    if (!content && !title) {
        return null;
    }

    return title ? (
        <>
            <Text strong>{title}</Text>
            <VSpacing base={1} />
            {content}
        </>
    ) : (
        <>{content}</>
    );
};

export default {
    Element: ChatikNotification,
    kind: 'error',
    autoClose: true,
};
