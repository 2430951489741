import createReducer from '@hh.ru/redux-create-reducer';

import { EmployerAutomationVacancies } from 'src/models/employerAutomation/index';

const UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES = 'UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES';

interface UpdateEmployerAutomationVacanciesAction {
    type: typeof UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES;
    payload: EmployerAutomationVacancies;
}
interface PayloadTypes {
    [UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES]: EmployerAutomationVacancies;
}

export const updateEmployerAutoActionVacancies = (
    newEmployerAutomationVacanciesData: EmployerAutomationVacancies
): UpdateEmployerAutomationVacanciesAction => ({
    type: UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES,
    payload: newEmployerAutomationVacanciesData,
});

export default createReducer<EmployerAutomationVacancies, PayloadTypes>(null, {
    [UPDATE_EMPLOYER_AUTO_ACTION_VACANCIES]: (state, { payload }) => {
        return { ...state, ...payload };
    },
});
