import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: {
        title: 'hhrating.registration.invitation.notification.title',
        description: 'hhrating.registration.invitation.notification.description',
        link: 'hhrating.registration.invitation.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: {
        title: 'hhrating.vote.invitation.notification.title',
        description: 'hhrating.vote.invitation.notification.description',
        link: 'hhrating.vote.invitation.notification.link',
    },
};

const notificationContent = {
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: {
        trlKeys: TrlKeys[ComplexUserNotificationsTemplateKey.HHRatingRegistration],
        link: '/shards/notifications/view_registration_hhrating',
        analyticsName: 'hhrating_registration_invitation',
    },
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: {
        trlKeys: TrlKeys[ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation],
        link: '/shards/notifications/view_vote_hhrating',
        analyticsName: 'hhrating_vote_invitation',
    },
};

const sendClickAnalytics = (eventName: string) => {
    // Internal analytics is sent from bff
    Analytics.sendEvent(eventName, 'view', 'priority');
};

const HHRating: TranslatedComponent<ComplexNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const { trlKeys, link, analyticsName } =
        notificationContent[notification.templateKey as keyof typeof notificationContent];
    const closeNotificationRef = useRef<VoidFunction>(null);
    const isInvitation = notification.templateKey === ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation;
    return (
        <SynchronizedNotification
            analyticsElementName={analyticsName}
            closeEvent={`${analyticsName}_close`}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
            kind={isInvitation ? NotificationKind.Rating : undefined}
        >
            <p>{trls[trlKeys.title]}</p>
            <p>{trls[trlKeys.description]}</p>
            <NotificationFooter>
                <Button
                    Element="a"
                    kind={isInvitation ? undefined : ButtonKind.Primary}
                    appearance={isInvitation ? ButtonAppearance.Filled : ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                    onClick={() => {
                        sendClickAnalytics(analyticsName);
                        closeNotificationRef.current?.();
                    }}
                    target="_blank"
                    href={`${link}?notification_medium=priority`}
                >
                    {trls[trlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(HHRating);
