import { FC, ReactNode, PropsWithChildren } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaUserNotificationsIcon, {
    SupernovaUserNotificationsIconKind,
} from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';

export interface NotificationLayoutProps {
    iconKind?: SupernovaUserNotificationsIconKind;
    dataQa?: DataQaInvariants;
    removeComponent?: ReactNode;
}

const NotificationLayout: FC<NotificationLayoutProps & PropsWithChildren> = ({
    children,
    dataQa,
    iconKind,
    removeComponent,
    ...props
}) => (
    <SupernovaDropdownSection notification>
        <div className="supernova-notification" data-qa={`notification ${dataQa || 'notification_warning'}`} {...props}>
            <SupernovaUserNotificationsIcon kind={iconKind || SupernovaUserNotificationsIconKind.Warning} />
            <div className="supernova-notification-content" data-qa="notification-content-wrapper">
                <Text typography="label-2-regular">{children}</Text>
            </div>
            {removeComponent && (
                <div className="supernova-notification-close" data-qa="notification-close">
                    {removeComponent}
                </div>
            )}
        </div>
    </SupernovaDropdownSection>
);

export default NotificationLayout;
