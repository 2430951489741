import { FC, useRef, memo } from 'react';
import classnames from 'classnames';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { ListOutlinedSize24, ListShortOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

interface Props {
    leftButtonPressed: boolean;
    leftButtonQaText: string;
    leftButtonTooltipText: string;
    leftButtonAriaLabelText: string;
    leftButtonDisabled?: boolean;
    leftButtonAction: () => void;
    rightButtonQaText: string;
    rightButtonTooltipText: string;
    rightButtonAriaLabelText: string;
    rightButtonDisabled?: boolean;
    rightButtonAction: () => void;
}

const ViewToggler: FC<Props> = ({
    leftButtonPressed,
    leftButtonQaText,
    leftButtonTooltipText,
    leftButtonAriaLabelText,
    leftButtonDisabled,
    leftButtonAction,
    rightButtonQaText,
    rightButtonTooltipText,
    rightButtonAriaLabelText,
    rightButtonDisabled,
    rightButtonAction,
}) => {
    const leftButtonRef = useRef(null);
    const rightButtonRef = useRef(null);

    return (
        <div className={styles.togglerWrapper}>
            <TooltipHover placement="bottom-center" activatorRef={leftButtonRef}>
                {leftButtonTooltipText}
            </TooltipHover>
            <button
                className={classnames(styles.togglerButton, {
                    [styles.togglerButtonActive]: leftButtonPressed,
                })}
                data-qa={leftButtonQaText}
                data-label={leftButtonAriaLabelText}
                onClick={leftButtonAction}
                ref={leftButtonRef}
                disabled={leftButtonDisabled}
            >
                <ListShortOutlinedSize24 />
            </button>
            <TooltipHover placement="bottom-center" activatorRef={rightButtonRef}>
                {rightButtonTooltipText}
            </TooltipHover>
            <button
                className={classnames(styles.togglerButton, {
                    [styles.togglerButtonActive]: !leftButtonPressed,
                })}
                data-qa={rightButtonQaText}
                data-label={rightButtonAriaLabelText}
                onClick={rightButtonAction}
                ref={rightButtonRef}
                disabled={rightButtonDisabled}
            >
                <ListOutlinedSize24 />
            </button>
        </div>
    );
};

export default memo(ViewToggler);
