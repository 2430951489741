import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum UserType {
    Anonymous = 'anonymous',
    NedoUser = 'nedouser',
    BackOffice = 'backoffice',
    Employer = 'employer',
    Applicant = 'applicant',
}

export default autoGeneratedReducer(UserType.Anonymous);
