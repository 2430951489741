import { IconColor } from '@hh.ru/magritte-ui';

import { SortIcons } from 'src/components/TableFlexible/TableFlexibleSorting';

export enum Sort {
    ByNameAsc = 'BY_NAME_ASC',
    ByNameDesc = 'BY_NAME_DESC',
    ByExpireTimeAsc = 'BY_EXPIRE_TIME_ASC',
    ByExpireTimeDesc = 'BY_EXPIRE_TIME_DESC',
    ByManagerNameAsc = 'BY_MANAGER_NAME_ASC',
    ByManagerNameDesc = 'BY_MANAGER_NAME_DESC',
    ByAllResponsesAsc = 'BY_ALL_RESPONSES_ASC',
    ByAllResponsesDesc = 'BY_ALL_RESPONSES_DESC',
    ByUnseenResponsesAsc = 'BY_UNSEEN_RESPONSES_ASC',
    ByUnseenResponsesDesc = 'BY_UNSEEN_RESPONSES_DESC',
    ByArchivationTimeAsc = 'BY_ARCHIVATION_TIME_ASC',
    ByArchivationTimeDesc = 'BY_ARCHIVATION_TIME_DESC',
}

export enum SortDirection {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING',
}

export interface SortOption<A extends Sort, D extends Sort> {
    order: {
        [SortDirection.Ascending]: A;
        [SortDirection.Descending]: D;
    };
    direction: {
        [key in A | D]: key extends A ? SortDirection.Ascending : SortDirection.Descending;
    } & { default: SortDirection; [index: string]: SortDirection };
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Down | SortIcons.Up;
        [SortDirection.Descending]: SortIcons.Up | SortIcons.Down;
    };
    iconColor?: IconColor;
}

export type SortByNameOption = SortOption<Sort.ByNameAsc, Sort.ByNameDesc>;
export type SortByExpireTimeOption = SortOption<Sort.ByExpireTimeAsc, Sort.ByExpireTimeDesc>;
export type SortByManagerNameOption = SortOption<Sort.ByManagerNameAsc, Sort.ByManagerNameDesc>;
export type SortByAllResponsesOption = SortOption<Sort.ByAllResponsesAsc, Sort.ByAllResponsesDesc>;
export type SortByUnseenResponsesOption = SortOption<Sort.ByUnseenResponsesAsc, Sort.ByUnseenResponsesDesc>;
export type SortByArchivationTimeOption = SortOption<Sort.ByArchivationTimeAsc, Sort.ByArchivationTimeDesc>;

export type VacancySortOption =
    | SortByNameOption
    | SortByExpireTimeOption
    | SortByManagerNameOption
    | SortByAllResponsesOption
    | SortByUnseenResponsesOption
    | SortByArchivationTimeOption;

export type VacancySortOptionArray = VacancySortOption[] & { 0: VacancySortOption; 1: VacancySortOption };

export const isVacancySortOptionArray = (
    value: VacancySortOption | VacancySortOptionArray
): value is VacancySortOptionArray => Array.isArray(value) && value.length > 1;

export const reversDirection = (sortDirection: SortDirection): SortDirection => {
    const DIRECTIONS_MAP = {
        [SortDirection.Ascending]: SortDirection.Descending,
        [SortDirection.Descending]: SortDirection.Ascending,
    };
    return DIRECTIONS_MAP[sortDirection];
};

export const DEFAULT_SORT = Sort.ByExpireTimeAsc;

export const SORT_BY_NAME: SortByNameOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByNameAsc,
        [SortDirection.Descending]: Sort.ByNameDesc,
    },
    direction: {
        [Sort.ByNameAsc]: SortDirection.Ascending,
        [Sort.ByNameDesc]: SortDirection.Descending,
        default: SortDirection.Ascending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Up,
        [SortDirection.Descending]: SortIcons.Down,
    },
};

export const SORT_BY_ALL_RESPONSES: SortByAllResponsesOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByAllResponsesAsc,
        [SortDirection.Descending]: Sort.ByAllResponsesDesc,
    },
    direction: {
        [Sort.ByAllResponsesAsc]: SortDirection.Ascending,
        [Sort.ByAllResponsesDesc]: SortDirection.Descending,
        default: SortDirection.Descending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Up,
        [SortDirection.Descending]: SortIcons.Down,
    },
    iconColor: 'accent',
};

export const SORT_BY_UNSEEN_RESPONSES: SortByUnseenResponsesOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByUnseenResponsesAsc,
        [SortDirection.Descending]: Sort.ByUnseenResponsesDesc,
    },
    direction: {
        [Sort.ByUnseenResponsesAsc]: SortDirection.Ascending,
        [Sort.ByUnseenResponsesDesc]: SortDirection.Descending,
        default: SortDirection.Descending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Up,
        [SortDirection.Descending]: SortIcons.Down,
    },
    iconColor: 'positive',
};

export const SORT_BY_EXPIRE_TIME: SortByExpireTimeOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByExpireTimeAsc,
        [SortDirection.Descending]: Sort.ByExpireTimeDesc,
    },
    direction: {
        [Sort.ByExpireTimeAsc]: SortDirection.Ascending,
        [Sort.ByExpireTimeDesc]: SortDirection.Descending,
        default: SortDirection.Ascending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Down,
        [SortDirection.Descending]: SortIcons.Up,
    },
};

export const SORT_BY_MANAGER_NAME: SortByManagerNameOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByManagerNameAsc,
        [SortDirection.Descending]: Sort.ByManagerNameDesc,
    },
    direction: {
        [Sort.ByManagerNameAsc]: SortDirection.Ascending,
        [Sort.ByManagerNameDesc]: SortDirection.Descending,
        default: SortDirection.Ascending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Up,
        [SortDirection.Descending]: SortIcons.Down,
    },
};

export const SORT_BY_ARCHIVATION_TIME: SortByArchivationTimeOption = {
    order: {
        [SortDirection.Ascending]: Sort.ByArchivationTimeAsc,
        [SortDirection.Descending]: Sort.ByArchivationTimeDesc,
    },
    direction: {
        [Sort.ByArchivationTimeAsc]: SortDirection.Ascending,
        [Sort.ByArchivationTimeDesc]: SortDirection.Descending,
        default: SortDirection.Ascending,
    },
    iconKind: {
        [SortDirection.Ascending]: SortIcons.Up,
        [SortDirection.Descending]: SortIcons.Down,
    },
};

export const RESPONSES_COLUMN_SORT_OPTIONS: VacancySortOptionArray = [SORT_BY_UNSEEN_RESPONSES, SORT_BY_ALL_RESPONSES];
