import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

export enum ActivationType {
    Auto = 'AUTO_ACTIVATION',
    Manual = 'MANUAL_ACTIVATION',
}

export interface ServiceActivationInfo {
    activationTime: null | string;
    allowedActivationPeriod: { startTime: string; endTime: string };
    bestPriceBefore: string;
    bestPriceBeforeNextDate?: string;
    currentActivationType: ActivationType;
    endDate?: string;
    hasLowerPrice: boolean;
    priceChangesSoon: boolean;
    priceExpiresSoon: boolean;
}

export interface EmployerInvoiceActivationInfo {
    currentActivationType: ActivationType | null;
    services: Record<string, ServiceActivationInfo>;
}

export const setEmployerInvoiceActivationInfo = makeSetStoreField('employerInvoiceActivationInfo');

const initialState = null;
export default autoGeneratedReducer<EmployerInvoiceActivationInfo>(initialState);
