import { FormItem } from 'bloko/blocks/form';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import AccountTypeBadge from 'src/components/SupernovaMainMenu/AccountTypeBadge';
import CurrentUserFullName from 'src/components/SupernovaMainMenu/CurrentUserFullName';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';

const TrlKeys = {
    client: 'navi.client',
};

const EmployerInfo: TranslatedComponent<NavItem> = ({ children, name, trls }) => {
    const employerId = useSelector((state) => state.employerId);
    const isMultiAccount = useSelector((state) => state.session.isMultiAccount);

    return (
        <SupernovaDropdownSection>
            <div className="supernova-dropdown-static-option">
                {isMultiAccount && (
                    <>
                        <AccountTypeBadge />
                        <VSpacing base={5} />
                    </>
                )}
                <FormItem />
                <Text data-qa={`mainmenu_${name}`} importance={TextImportance.Secondary}>
                    <CurrentUserFullName />
                    {` – ${trls[TrlKeys.client]} ${employerId}`}
                </Text>
            </div>
            {children}
        </SupernovaDropdownSection>
    );
};

export default translation(EmployerInfo);
