import { useCallback, useState } from 'react';

/**
 * Хук для бинарного стейта, возвращает два коллбека, которые устанавливают стейт в true и false
 */

export default (initialState: boolean): [boolean, () => void, () => void] => {
    const [state, setState] = useState(initialState);
    const setOn = useCallback(() => {
        setState(true);
    }, []);
    const setOff = useCallback(() => {
        setState(false);
    }, []);

    return [state, setOn, setOff];
};
