import { FC, RefObject, useState } from 'react';

import { BottomSheet, Drop, VSpacingContainer } from '@hh.ru/magritte-ui';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import { NavItems } from 'src/models/supernovaNaviMenu';

import SupernovaMagritteShowMoreItem from 'src/components/SupernovaMainMenu/SupernovaMagritteDropdown/SupernovaMagritteShowMoreItem';

interface SupernovaMagritteBottomSheetProps {
    activatorRef: RefObject<HTMLElement>;
    visible: boolean;
    onClose: () => void;
    items: NavItems;
}

const DEFAULT_TAB = 'default';

const SupernovaMagritteDropdown: FC<SupernovaMagritteBottomSheetProps> = ({
    visible,
    items,
    onClose,
    activatorRef,
}) => {
    const [currentTab, setCurrentTab] = useState<string>(DEFAULT_TAB);
    const isDefaultScreen = !currentTab || currentTab === DEFAULT_TAB;

    const handleClose = () => {
        onClose();
        setCurrentTab(DEFAULT_TAB);
    };

    const content = isDefaultScreen ? (
        <VSpacingContainer default={4} xs={8} s={8}>
            {items.map((item) => {
                switch (item.name) {
                    case 'help':
                    case 'anonSupport':
                    case 'support':
                        return <SupernovaMagritteShowMoreItem key={item.name} onClose={handleClose} {...item} />;
                    default:
                        return <SupernovaMagritteShowMoreItem key={item.name} {...item} />;
                }
            })}
        </VSpacingContainer>
    ) : (
        items
            .filter((item) => item.name === currentTab)
            .map((item) => (
                <div key={item.name}>
                    <DropdownContent item={item} onClose={handleClose} />
                </div>
            ))
    );

    return (
        <>
            <BottomSheet visible={visible} onClose={handleClose}>
                {content}
            </BottomSheet>
            <Drop
                visible={visible}
                onClose={handleClose}
                activatorRef={activatorRef}
                placement="bottom-left"
                space={300}
                style={{ marginTop: '-8px', marginLeft: '-16px' }}
            >
                {content}
            </Drop>
        </>
    );
};

export default SupernovaMagritteDropdown;
