import { RefObject, useEffect, useMemo, useState } from 'react';

import headerGeoDefinitionAreaFieldTouch from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_area_field_touch';
import headerGeoDefinitionSuggestButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_button_click';
import { usePush } from '@hh.ru/redux-spa-middleware';
import InputText from 'bloko/blocks/inputText';
import Suggest from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import LayerCssClass from 'bloko/common/constants/layersCssClasses';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import InputIcon from 'src/components/AreaSwitcher/Search/Icon';
import SuggestDomainItem, {
    suggestDomainItemKey,
    SuggestDomainItemProps,
} from 'src/components/AreaSwitcher/Search/SuggestDomainItem';

const TrlKeys = {
    label: 'area.switcher.search.label',
    placeholder: 'area.switcher.search.placeholder',
};

const WILDCARD = '%QUERY%';
const REMOTE = `/shards/area_switcher/search?q=${WILDCARD}&backUrl=`;

interface AreaSwitcherSearchProps {
    searchInputRef: RefObject<HTMLInputElement>;
}

const Search: TranslatedComponent<AreaSwitcherSearchProps> = ({ searchInputRef, trls }) => {
    const push = usePush();
    const { pathname, search } = useSelector((state) => state.router.location);
    const [isTriedToSearch, setIsTriedToSearch] = useState<boolean>(false);

    const dataProvider = useMemo(
        () => createRemoteDataProvider(`${REMOTE}${encodeURIComponent(`${pathname}${search}`)}`, WILDCARD),
        [pathname, search]
    ) as DataProvider<SuggestDomainItemProps>;
    const [value, setValue] = useState<SuggestDomainItemProps | undefined>(undefined);

    const isXs = useBreakpoint() === Breakpoint.XS;

    const onChange = (item: SuggestDomainItemProps) => {
        if (item && typeof item.areaId === 'number') {
            headerGeoDefinitionSuggestButtonClick({ selectedArea: item.areaId.toString() });
        }
        setValue(item);
        item?.href && push(item.href);
    };

    const handleChange = () => {
        setIsTriedToSearch(true);
    };

    useEffect(() => {
        if (isTriedToSearch) {
            headerGeoDefinitionAreaFieldTouch();
        }
    }, [isTriedToSearch]);

    return (
        <>
            {!isXs && (
                <>
                    <Text>{trls[TrlKeys.label]}</Text>
                    <VSpacing base={1} />
                </>
            )}
            <Suggest
                autoHighlightFirstSuggest={false}
                selectOnBlur={false}
                dataProvider={dataProvider}
                itemContent={SuggestDomainItem}
                itemKey={suggestDomainItemKey}
                onChange={onChange}
                value={value}
                layer={LayerCssClass.AboveOverlayContent}
            >
                <InputText
                    ref={searchInputRef}
                    placeholder={trls[TrlKeys.placeholder]}
                    icon={<InputIcon />}
                    onChange={handleChange}
                    data-qa="area-search-input"
                />
            </Suggest>
        </>
    );
};

export default translation(Search);
