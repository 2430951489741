import { Text } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    text: 'employer.publicationReadyDrafts.text',
    vacancy: 'employer.publicationReadyDrafts.vacancy',
    vacancies: 'employer.vacancyDraft.saveNotificaition.vacancies',
    waiting: 'employer.publicationReadyDrafts.waitingForPublication',
    titleOne: 'employer.publicationReadyDrafts.title.one',
    titleSome: 'employer.publicationReadyDrafts.title.some',
    titleMany: 'employer.publicationReadyDrafts.title.many',
};

const VacancyDraftPublicationReady: TranslatedComponent<ComplexUserNotification> = ({
    trls,
    templateKey,
    ...props
}) => {
    const { params } = props;
    const ready = parseInt(params.ready || '0', 10);

    if (ready === 0) {
        return null;
    }

    if (ready === 1) {
        return (
            <Notification templateKey={templateKey} {...props}>
                <NotificationLink to={`/employer/vacancy/create?draftId=${params.draft_id}`}>
                    {trls[TrlKeys.vacancy]}
                </NotificationLink>
                {NON_BREAKING_SPACE}
                {trls[TrlKeys.waiting]}
            </Notification>
        );
    }

    return (
        <Notification templateKey={templateKey} {...props}>
            <NotificationSpacer>
                <ConversionNumber
                    value={ready}
                    one={trls[TrlKeys.titleOne]}
                    some={trls[TrlKeys.titleSome]}
                    many={trls[TrlKeys.titleMany]}
                    hasValue
                />
            </NotificationSpacer>

            <Text style="secondary" typography="label-2-regular">
                {trls[TrlKeys.text]}
                {NON_BREAKING_SPACE}
                <NotificationLink to="/employer/vacancies">{trls[TrlKeys.vacancies]}</NotificationLink>
            </Text>
        </Notification>
    );
};

export default translation(VacancyDraftPublicationReady);
