import React, { useContext } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const Signup: React.FC<NavItem> = ({ name, trackClicks, translations, url, analytics }) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const userType = useSelector(({ userType }) => userType);

    return (
        <SupernovaNaviItem className="supernova-signup-wrapper" isButton visibleOn={VISIBLE_ON}>
            <SupernovaButton
                data-qa={name}
                isMagritte={isRedesignActive}
                name={name}
                secondary={userType !== UserType.NedoUser}
                tinted
                trackClicks={trackClicks}
                analytics={analytics}
                url={url}
            >
                {translations.name}
            </SupernovaButton>
        </SupernovaNaviItem>
    );
};

export default Signup;
