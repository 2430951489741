import createReducer from '@hh.ru/redux-create-reducer';

import { CountryCode } from 'src/models/countryId';
import { CurrencyType } from 'src/models/currencies.types';
import { EmployerState } from 'src/models/employerStatus';
import { PartyType } from 'src/models/partyType';
import { PaymentType } from 'src/models/paymentType';

export enum ChangeActivationTypeErrorCode {
    CartNotFound = 'CART_NOT_FOUND',
    UnknownActivationType = 'UNKNOWN_ACTIVATION_TYPE',
    CartActivationTypeIsNotChangeable = 'CART_ACTIVATION_TYPE_IS_NOT_CHANGEABLE',
}
interface Employer {
    maxCustomDiscountPercent: number;
    currency: CurrencyType;
    invoiceUnavailable?: boolean;
    availableWithoutBlockedMoney?: number;
}

export interface ChangeActivationTypeError {
    message: ChangeActivationTypeErrorCode;
}

export interface ChangeActivationTypeErrorResponse {
    error?: ChangeActivationTypeError;
}

export interface OrganisationType {
    organisationType: PartyType;
    available: boolean;
}

export interface InitialValues {
    [key: string]: string | boolean;
}

export interface Country {
    code: CountryCode;
    name: string;
}

export interface EmployerInvoicePurchase {
    backofficeEmployerId?: string;
    emailRequiredPaymentTypes?: Record<PartyType, PaymentType[]>;
    hasPayers: boolean;
    employerId: number | null;
    employerCountry: CountryCode;
    organisationTypes: OrganisationType[];
    canGiveCustomDiscountForPurchase: boolean;
    purchaseUrls: string | null;
    employer: Employer;
    employerState: EmployerState | null;
}

const initialState: EmployerInvoicePurchase = {
    hasPayers: false,
    employerId: null,
    employerCountry: CountryCode.Russia,
    organisationTypes: [],
    canGiveCustomDiscountForPurchase: false,
    purchaseUrls: null,
    employer: {
        maxCustomDiscountPercent: 0,
        currency: CurrencyType.RUR,
    },
    employerState: null,
};

export default createReducer<EmployerInvoicePurchase, object>(initialState, {});
