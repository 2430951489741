import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import NovaFilters, { NovaFilterKey } from 'src/models/novaFilters';
import { SearchClustersSettings } from 'src/models/searchClustersSettings';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import { VacancySearchQueries } from 'src/models/vacancySearch/vacancySearchQueries.types';
import { Criteria } from 'src/models/vacancySearchCriteria';

export const MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT = 5;
export const MIN_APPLICANT_SUITABLE_VACANCIES_COUNT = 1;

export interface SuitableVacancies {
    itemsOnPage: number;
    resultsFound: number;
    totalPages: number;
    lastActivityTimeByHHID: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    areaIds: string[];
    // tempexp_PORTFOLIO-30605_start
    queries?: Partial<VacancySearchQueries>;
    criteria?: Criteria;
    searchClusters: NovaFilters;
    searchClustersOrder: Record<NovaFilterKey, string[]>;
    searchClustersSettings: SearchClustersSettings;
    // // tempexp_PORTFOLIO-30605_end
}

export default autoGeneratedReducer<SuitableVacancies>(null);
