import { forwardRef, ForwardedRef, ReactElement, ComponentProps, ForwardRefRenderFunction } from 'react';

import { Suggest, Placeholder, SuggestProps, SuggestInputComponent } from '@hh.ru/magritte-ui';
import { TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

type SuggestPropsHelper<T, P extends SuggestInputComponent> = Omit<SuggestProps<T, P>, 'trls' | 'errorPlaceholder'>;

interface ForwardRefHelper {
    fwdRef?: ForwardedRef<HTMLElement>;
}

type SuggestWithDefaultErrorPlaceholderProps<T, P extends SuggestInputComponent> = SuggestPropsHelper<T, P> &
    ForwardRefHelper;

const TrlKeys = {
    resetButton: 'suggest.error.resetButton',
    title: 'suggest.error.title',
    description: 'suggest.error.description',
};

const SuggestWithDefaultErrorPlaceholderComponentRaw = function <T, P extends SuggestInputComponent>(
    props: SuggestWithDefaultErrorPlaceholderProps<T, P> & TranslationHOCProps
) {
    const { fwdRef, trls, ...suggestProps } = props;
    return (
        <Suggest
            {...suggestProps}
            ref={fwdRef}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={<Placeholder title={trls[TrlKeys.title]} description={trls[TrlKeys.description]} />}
        />
    );
};

const SuggestWithDefaultErrorPlaceholderTranslatedComponent = translation(
    SuggestWithDefaultErrorPlaceholderComponentRaw
);

const SuggestWithDefaultErrorPlaceholderComponent: ForwardRefRenderFunction<
    HTMLElement,
    ComponentProps<typeof SuggestWithDefaultErrorPlaceholderTranslatedComponent>
> = (props, ref) => {
    return <SuggestWithDefaultErrorPlaceholderTranslatedComponent {...props} fwdRef={ref} />;
};

export default forwardRef(SuggestWithDefaultErrorPlaceholderComponent) as <T, P extends SuggestInputComponent>(
    props: SuggestWithDefaultErrorPlaceholderProps<T, P> & { ref?: ForwardedRef<HTMLElement> }
) => ReactElement<T, P> | null;
