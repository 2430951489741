import { FC, RefObject } from 'react';
import classnames from 'classnames';

import { GridColumn, GridRow } from '@hh.ru/magritte-ui';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import VSpacing from 'bloko/blocks/vSpacing';

import Footer from 'src/components/AreaSwitcher/Footer';
import { AreaSwitcherScreens, TopLevelDomainsData } from 'src/components/AreaSwitcher/areaSwitcherTypes';

import TopLevelDomainsCitiesList from 'src/components/AreaSwitcher/TopLevelDomains/TopLevelDomainsCitiesList';

interface TopLevelDomainsProps {
    isDropdown?: boolean;
    searchInputRef: RefObject<HTMLInputElement>;
    setCurrentScreen: (val: AreaSwitcherScreens) => void;
    setSelectedAreaId: (val?: number) => void;
    topLevelDomainsData: TopLevelDomainsData | null;
    isLoading: boolean;
}

const TopLevelDomains: FC<TopLevelDomainsProps> = ({
    isDropdown,
    searchInputRef,
    setCurrentScreen,
    setSelectedAreaId,
    topLevelDomainsData,
    isLoading,
}) => {
    return (
        <GridColumn xs={4} s={8} m={12} l={12}>
            <div data-qa="area-switcher-welcome">
                <GridColumn xs={4} s={8} m={12} l={12}>
                    <div className="separator" />
                    <VSpacing base={6} />
                </GridColumn>
                {!!topLevelDomainsData && (
                    <>
                        <GridRow>
                            {topLevelDomainsData.topLevelDomains.map((domain) => (
                                <TopLevelDomainsCitiesList
                                    domain={domain}
                                    setCurrentScreen={setCurrentScreen}
                                    setSelectedAreaId={() => setSelectedAreaId(domain.areaId)}
                                    key={domain.areaId}
                                />
                            ))}
                        </GridRow>
                        <Footer searchInputRef={searchInputRef} />
                    </>
                )}
                {isLoading && (
                    <div
                        className={classnames({
                            'supernova-dropdown-static-option': isDropdown,
                        })}
                    >
                        <Loading scale={LoadingScale.Medium} />
                    </div>
                )}
            </div>
        </GridColumn>
    );
};

export default TopLevelDomains;
