import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import userStatusSelectFormSubmit, {
    FormSource,
    JobSearchStatus as JobSearchStatusAnalytics,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_form_submit';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import { showPostChangeModal } from 'src/models/applicant/jobSearchStatus';
import { JobSearchStatus, JobSearchStatusForm, JobSearchStatusSetted } from 'src/models/applicantUserStatuses';
import fetcher from 'src/utils/fetcher';

const FORM_ID_TO_SOURCE_MAPPING = {
    [JobSearchStatusForm.VacancyResponseNotification]: 'RESPONSE_SENT',
    [JobSearchStatusForm.RenewNotification]: 'USER_STATUS_NOTIFICATION',
    [JobSearchStatusForm.MyResumesXsModal]: 'RESUME_LIST_XS',
    [JobSearchStatusForm.MyResumesDesktop]: 'RESUME_LIST_LARGE',
    [JobSearchStatusForm.ResponsesPageBanner]: 'RESPONSE_OFFER_USER_STATUS',
} as const;

const formHasSource = (formId: JobSearchStatusForm): formId is keyof typeof FORM_ID_TO_SOURCE_MAPPING =>
    formId in FORM_ID_TO_SOURCE_MAPPING;

const shouldShowSuccessNotification = (formId: JobSearchStatusForm) =>
    ![JobSearchStatusForm.MyResumesDesktop, JobSearchStatusForm.MyResumesXsModal].includes(formId);

const applicantUserStatusesAction = makeSetStoreField('applicantUserStatuses');

declare global {
    interface FetcherPutApi {
        '/shards/user_statuses/job_search_status': {
            body: void;
            queryParams: { status: string };
            response: void;
        };
    }
}

const getAnalyticsFormSource = (formId: JobSearchStatusForm): FormSource => {
    if (formHasSource(formId)) {
        return FORM_ID_TO_SOURCE_MAPPING[formId];
    }

    return 'RESUME_LIST_LARGE';
};

const useSetJobSearchStatus = (formId: JobSearchStatusForm): ((newStatus: JobSearchStatus) => Promise<void>) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const applicantUserStatuses = useSelector((state) => state.applicantUserStatuses);

    return useCallback(
        (newStatus: JobSearchStatus) => {
            userStatusSelectFormSubmit({
                jobSearchStatus: newStatus.toUpperCase() as JobSearchStatusAnalytics,
                formSource: getAnalyticsFormSource(formId),
            });
            return fetcher
                .put('/shards/user_statuses/job_search_status', undefined, {
                    params: { status: newStatus },
                })
                .then(
                    () => {
                        dispatch(
                            applicantUserStatusesAction({
                                ...applicantUserStatuses,
                                jobSearchStatus: { name: newStatus as JobSearchStatusSetted },
                            })
                        );
                        if (newStatus === JobSearchStatus.AcceptedJobOffer) {
                            dispatch(showPostChangeModal(shouldShowSuccessNotification(formId)));
                        }
                        if (newStatus === JobSearchStatus.HasJobOffer) {
                            dispatch(showPostChangeModal(shouldShowSuccessNotification(formId)));
                        }
                    },
                    (error) => {
                        defaultRequestErrorHandler(error, addNotification);
                        throw error;
                    }
                );
        },
        [addNotification, applicantUserStatuses, dispatch, formId]
    );
};

export default useSetJobSearchStatus;
