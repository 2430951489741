import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { type CurrencyType } from 'src/models/currencies.types';

export interface PfpBalanceByAgreement {
    agreementId: string;
    possibleResponses: number;
    balance: number;
    agreementNumber?: string;
}

export interface PfpBalance {
    value: number;
    possibleResponses: number;
    allowUnblockMoney: true;
    balanceByAgreements: PfpBalanceByAgreement[];
}

export interface PfpReplenishmentConfig {
    minValue: number;
    maxValue: number;
}

export interface PricePayForPerformance {
    currency: CurrencyType;
    balance: PfpBalance;
    replenishmentConfig: PfpReplenishmentConfig;
    tabName: string;
}

const UPDATE_BALANCE = 'UPDATE_BALANCE';

interface PayloadPricePayForPerformanceTypes {
    [UPDATE_BALANCE]: PfpBalance;
}

const actionCreator = ActionCreatorHelper<PayloadPricePayForPerformanceTypes>();
export const updateBalance = actionCreator(UPDATE_BALANCE);

export default createReducer<PricePayForPerformance, PayloadPricePayForPerformanceTypes>(null, {
    [UPDATE_BALANCE]: (state, { payload: balance }) => {
        return { ...state, balance };
    },
});
