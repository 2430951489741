let history;

export const setHistory = (path) => {
    if (process.env.SSR) {
        const createHistory = require('history').createMemoryHistory;
        history = createHistory({
            initialEntries: [path],
            initialIndex: 0,
        });
    }
};

export const createHistory = () => {
    history = require('history').createBrowserHistory();
};

export const getHistory = () => history;
