import Cookies from 'bloko/common/Cookies';

import { ANON_SOCKET_COOKIE_NAME } from 'src/components/ChatikIntegration/constants';
import { UserType } from 'src/models/userType';

export const shouldUseCounterUpdater = ({
    userType,
    websocketOrigin,
    chatikOrigin,
    force,
}: {
    userType: UserType;
    force: boolean;
    websocketOrigin?: string;
    chatikOrigin?: string;
}): boolean => {
    if (!websocketOrigin || !chatikOrigin) {
        return false;
    }

    if (force) {
        return true;
    }

    /**
     * Для анонимов подключаемся к сокету только если есть кука
     * Сейчас она выставляется на сутки при открытии чата с поддержкой
     * Если будет много нагрузки, то можно сетить куку не при открытии чата,
     * а именно после отправки сообщения в поддержку
     */
    if (userType === UserType.Anonymous) {
        return typeof window === 'undefined' ? false : Boolean(Cookies.get(ANON_SOCKET_COOKIE_NAME));
    }

    return true;
};
