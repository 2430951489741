import { FC } from 'react';

import { GridLayout } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

import AreaSwitcherOverlay from 'src/components/SupernovaOverlayMenu/AreaSwitcher/Overlay';
import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';

interface OverlayContentProps {
    overlayType: OverlayTypes;
    setOverlayType: OverlayTypeSetterFunc;
}

const OverlayContent: FC<OverlayContentProps> = ({ overlayType, setOverlayType }) => {
    if (overlayType === OverlayTypes.None) {
        return null;
    }

    let overlay: React.ReactNode = null;

    switch (overlayType) {
        case OverlayTypes.AreaSwitcherOverlay:
            overlay = <AreaSwitcherOverlay />;
            break;
        default:
            return null;
    }

    return (
        <div className="supernova-overlay__content">
            <GridLayout>
                <div className="supernova-overlay-closer-wrapper">
                    <button
                        className="supernova-overlay-closer"
                        onClick={() => setOverlayType(OverlayTypes.None)}
                        type="button"
                    >
                        ✕
                    </button>
                </div>

                {overlay}
            </GridLayout>
        </div>
    );
};

export default translation(OverlayContent);
