import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

interface VacanciesShortState {
    vacanciesList: VacancySearchItem[];
    total?: number;
}

export default autoGeneratedReducer<VacanciesShortState>({
    vacanciesList: [],
    total: undefined,
});
