import { useRef, useState, FC, useEffect } from 'react';

import headerGeoDefinitionSuggestScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_screen_shown';
import { GridColumn, useBreakpoint } from '@hh.ru/magritte-ui';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import regionClarificationComponent from 'src/components/SupernovaOverlayMenu/RegionClarification';
import fetcher from 'src/utils/fetcher';

import AreaSwitcherMobileList from 'src/components/AreaSwitcher/AreaSwitcherMobileList';
import Search from 'src/components/AreaSwitcher/Search';
import SubdomainsByLetter from 'src/components/AreaSwitcher/SubdomainsByLetter';
import TopLevelDomains from 'src/components/AreaSwitcher/TopLevelDomains';
import { AreaSwitcherScreens, TopLevelDomainsData } from 'src/components/AreaSwitcher/areaSwitcherTypes';

const TOP_LEVEL_DOMAINS_URL = '/shards/area_switcher/top_level_domains';

declare global {
    interface FetcherGetApi {
        [TOP_LEVEL_DOMAINS_URL]: {
            response: TopLevelDomainsData;
            queryParams: { backUrl?: string; forCurrentDomain?: boolean };
        };
    }
}

interface AreaSwitcherContentProps {
    isDropdown?: boolean;
}

const AreaSwitcher: FC<AreaSwitcherContentProps> = ({ isDropdown }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [currentScreen, setCurrentScreen] = useState(AreaSwitcherScreens.TopLevelDomains);
    const [selectedAreaId, setSelectedAreaId] = useState<number | undefined>(0);
    const { removeNotification, addNotification } = useNotification();
    const { isXS } = useBreakpoint();

    const [topLevelDomainsData, setTopLevelDomainsData] = useState<TopLevelDomainsData | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        removeNotification(regionClarificationComponent);

        const getTopLevelDomains = async () => {
            let domains;

            setIsLoading(true);

            try {
                domains = await fetcher.get(TOP_LEVEL_DOMAINS_URL, {
                    signal: controller.signal,
                    params: {
                        backUrl: window.location.href,
                        ...(isXS ? { forCurrentDomain: true } : {}),
                    },
                });
            } catch (error) {
                defaultRequestErrorHandler(error, addNotification);
                return;
            } finally {
                setIsLoading(false);
            }

            setTopLevelDomainsData(domains);
        };

        void getTopLevelDomains();

        return () => controller.abort();
    }, [addNotification, isXS, removeNotification]);

    useEffect(() => {
        headerGeoDefinitionSuggestScreenShown({ hhtmSource: 'header_geo_defination_suggest' });
    }, []);

    return (
        <div>
            <GridColumn xs={4} s={7} m={10} l={10}>
                <Search searchInputRef={searchInputRef} />
            </GridColumn>
            {isXS ? (
                <AreaSwitcherMobileList isLoading={isLoading} topLevelDomains={topLevelDomainsData?.topLevelDomains} />
            ) : (
                <>
                    {currentScreen === AreaSwitcherScreens.TopLevelDomains && (
                        <TopLevelDomains
                            searchInputRef={searchInputRef}
                            isDropdown={isDropdown}
                            setCurrentScreen={setCurrentScreen}
                            setSelectedAreaId={setSelectedAreaId}
                            isLoading={isLoading}
                            topLevelDomainsData={topLevelDomainsData}
                        />
                    )}
                    {currentScreen === AreaSwitcherScreens.SubdomainsByLetter && (
                        <SubdomainsByLetter
                            searchInputRef={searchInputRef}
                            isDropdown={isDropdown}
                            setCurrentScreen={setCurrentScreen}
                            areaId={selectedAreaId}
                            currentDomainFirstLetter={
                                topLevelDomainsData?.currentDomainAreaId === selectedAreaId
                                    ? topLevelDomainsData?.currentDomainFirstLetter
                                    : ''
                            }
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default AreaSwitcher;
