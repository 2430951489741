import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Negotiations } from 'src/models/applicantNegotiations/applicantNegotiations';
import { Resume } from 'src/models/resume/resume.types';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import PopupType from 'src/models/vacancyResponsePopup/popupType.types';

interface BlackAndWhiteList {
    canAdd: number;
    containsEmployer: boolean;
    limit: number;
}

export interface ResumeVisibility {
    hash: string;
    id: number;
    accessType: ResumeAccessType;
    blacklist: BlackAndWhiteList;
    whitelist: BlackAndWhiteList;
    connectedEmployers: { employerId: number; name: string }[];
}

export enum ResumeInconsistencyType {
    Language = 'LANGUAGE',
    Area = 'AREA',
    Distance = 'DISTANCE',
    Experience = 'EXPERIENCE',
}

export interface ResumeInconsistency {
    id: number;
    inconsistencies: {
        inconsistency: {
            type: ResumeInconsistencyType;
            required: string;
            specifier?: string;
            languageTrl: string;
        }[];
    };
}

export interface VacancyResponsePopup {
    type?: PopupType;
    isQuestionResponse?: boolean;
    startedWithQuestion?: boolean;
    vacancy?: {
        companyUrl?: string;
        vacancyId: number;
        alreadyApplied: boolean;
        hasQuickResponse: boolean;
        hiddenResumeIds: string[];
        unusedResumeIds: string[];
        usedResumeIds: string[];
        unfinishedResumeIds: string[];
        letterMaxLength: number;
        responseImpossible: boolean;
        resumeVisibility: Record<string, ResumeVisibility>;
        resumeInconsistencies: {
            resume: ResumeInconsistency[];
        };
        negotiations: Negotiations;
        test: {
            hasTest: boolean;
            required: boolean;
            testGuid?: string;
        };
        shortVacancy: VacancySearchItem;
        resume: Pick<
            Resume,
            'id' | 'title' | 'accessType' | 'primaryEducation' | 'educationLevel' | 'specialization' | 'shortExperience'
        >;
    };
    onCancelCallback?: () => void;
}

export default autoGeneratedReducer<VacancyResponsePopup>({});
