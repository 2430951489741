import { H3 } from 'bloko/blocks/header';
import Vspacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { LoyaltySurveyType } from 'src/models/loyaltySurvey';

import Counter from 'src/components/LoyaltySurveyModal/Counter';
import { SKIP_ANSWER_VALUE } from 'src/components/LoyaltySurveyModal/constants';

interface StepHeaderProps {
    step: number;
    total: number;
    type: LoyaltySurveyType;
    answers: string[];
}

const TrlKeys = {
    stepsRadio: {
        '2': {
            [LoyaltySurveyType.Adv]: 'surveys.loyalty.ADV.popup.steps.1.header',
            [LoyaltySurveyType.Branding]: 'surveys.loyalty.BRANDING.popup.steps.1.header',
            [LoyaltySurveyType.Clickme]: 'surveys.loyalty.CLICKME.popup.steps.1.header',
            [LoyaltySurveyType.MainServices]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.1.header',
        },
        '3': {
            [LoyaltySurveyType.Adv]: 'surveys.loyalty.ADV.popup.steps.2.header',
            [LoyaltySurveyType.Branding]: 'surveys.loyalty.BRANDING.popup.steps.2.header',
            [LoyaltySurveyType.Clickme]: 'surveys.loyalty.CLICKME.popup.steps.2.header',
            [LoyaltySurveyType.MainServices]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.2.header',
        },
        '0': {
            [LoyaltySurveyType.Adv]: 'surveys.loyalty.ADV.popup.steps.3.header',
            [LoyaltySurveyType.Branding]: 'surveys.loyalty.BRANDING.popup.steps.3.header',
            [LoyaltySurveyType.Clickme]: 'surveys.loyalty.CLICKME.popup.steps.3.header',
            [LoyaltySurveyType.MainServices]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.3.header',
        },
    },
    stepText: {
        rate: 'surveys.loyalty.popup.steps.4.rate',
        max: {
            [LoyaltySurveyType.Adv]: 'surveys.loyalty.ADV.popup.steps.4.question.option.1',
            [LoyaltySurveyType.Branding]: 'surveys.loyalty.BRANDING.popup.steps.4.question.option.1',
            [LoyaltySurveyType.Clickme]: 'surveys.loyalty.CLICKME.popup.steps.4.question.option.1',
            [LoyaltySurveyType.MainServices]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.4.question.option.1',
        },
        min: {
            [LoyaltySurveyType.Adv]: 'surveys.loyalty.ADV.popup.steps.4.question.option.2',
            [LoyaltySurveyType.Branding]: 'surveys.loyalty.BRANDING.popup.steps.4.question.option.2',
            [LoyaltySurveyType.Clickme]: 'surveys.loyalty.CLICKME.popup.steps.4.question.option.2',
            [LoyaltySurveyType.MainServices]: 'surveys.loyalty.MAIN_SERVICES.popup.steps.4.question.option.2',
        },
    },
    lastQuestion: 'surveys.loyalty.popup.lastQuestion',
    counterPart: 'surveys.loyalty.popup.counterPart',
};

const StepHeader: TranslatedComponent<StepHeaderProps> = ({ trls, step, total, type, answers }) => {
    const indexStep = step.toString() as '0' | '3' | '2';
    const stepRadio = TrlKeys.stepsRadio[indexStep];

    const answerSkipped = answers[step - 1] === SKIP_ANSWER_VALUE;
    const title = stepRadio ? (
        <H3>{trls[stepRadio[type]]}</H3>
    ) : (
        <H3>
            {!answerSkipped && `${trls[TrlKeys.stepText.rate]} ${answers[step - 1]}.`}
            <p>{trls[TrlKeys.stepText[parseInt(answers[step - 1], 10) >= 9 ? 'max' : 'min'][type]]}</p>
        </H3>
    );

    const counter = step < total - 1 ? `${step + 1} ${trls[TrlKeys.counterPart]} ${total}` : trls[TrlKeys.lastQuestion];

    return (
        <>
            <Counter>{counter}</Counter>
            {title}
            <Vspacing base={4}></Vspacing>
        </>
    );
};
export default translation(StepHeader);
