import { FC, ReactNode } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import VerificationIcon from 'src/components/EmployerVerification/VerificationIcon';
import { TooltipType } from 'src/components/EmployerVerification/useEmployerVerificationState';

import styles from './styles.less';

interface EmployerNameWithBadgeProps {
    employerName: string;
    rightIcon?: ReactNode;
    isNotVerifiedEmployerBannerExp: boolean;
}

const EmployerNameWithBadge: FC<EmployerNameWithBadgeProps> = ({
    employerName,
    rightIcon,
    isNotVerifiedEmployerBannerExp,
}) => {
    return (
        <div className={styles.employerNameContainer}>
            <VerificationIcon
                tooltipType={TooltipType.Profile}
                isNotVerifiedEmployerBannerExp={isNotVerifiedEmployerBannerExp}
            />
            <Text maxLines={1} style="secondary" typography="label-3-regular">
                {employerName}
            </Text>
            {rightIcon}
        </div>
    );
};

export default EmployerNameWithBadge;
