import { useDispatch } from 'react-redux';

import { updateNote, PutNoteType } from 'src/models/notes';
import fetcher from 'src/utils/fetcher';

import { useSelector } from 'src/hooks/useSelector';

const SET_NOTES_URL = '/notes/set_note';

declare global {
    interface FetcherPostApi {
        [SET_NOTES_URL]: {
            body: { name: string; value: string; type?: PutNoteType; ttl?: number };
            response: void;
            queryParams: void;
        };
    }
}

const parseValue = (value: string): unknown => {
    try {
        return JSON.parse(value);
    } catch (e) {
        console.error(e);
        return null;
    }
};

interface SetOptions {
    shouldUpdateValueInStore?: boolean;
    putType?: PutNoteType;
    ttl?: number;
}

const defaultSetOptions = {
    shouldUpdateValueInStore: true,
    putType: PutNoteType.Account,
} as const;

// eslint-disable-next-line etc/no-misused-generics
const useNote = <T>(
    name: string
): readonly [value: T | null, setValue: (newValue: T, options?: SetOptions) => Promise<void>] => {
    const dispatch = useDispatch();
    const notes = useSelector(({ notes }) => notes);

    const noteValue = notes?.[name];
    const jsonValue = typeof noteValue === 'string' ? (parseValue(noteValue) as T) : null;

    const setNoteValue = async (newValue: T, setOptions: SetOptions = defaultSetOptions) => {
        const options: SetOptions = { ...defaultSetOptions, ...setOptions };
        const stringValue = JSON.stringify(newValue);
        await fetcher.postFormData(SET_NOTES_URL, {
            name,
            value: stringValue,
            type: options.putType,
            ttl: options.ttl,
        });

        if (options.shouldUpdateValueInStore) {
            dispatch(updateNote({ name, value: stringValue }));
        }
    };

    return [jsonValue, setNoteValue] as const;
};

export default useNote;
