import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

export const linkMap = {
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]:
        'https://hrspace.hh.ru/recruiter/?utm_source=hh.ru&utm_medium=banner&utm_campaign=notification_dms',
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]:
        'https://hrspace.hh.ru/?utm_source=hh.ru&utm_medium=banner&utm_campaign=notification_promo_employer',
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]:
        'https://hrspace.hh.ru/?utm_source=hh.ru&utm_medium=banner&utm_campaign=notification_promo_it_employer',
};

export const analyticsNameMap = {
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]: 'hrspace_promo_applicant',
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]: 'hrspace_promo_employer',
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]: 'hrspace_promo_it_employer',
};
