import React from 'react';

import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { NavItems } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';

interface MobileServiceProps {
    items?: NavItems;
}

const MobileServices: React.VFC<MobileServiceProps> = ({ items }) => {
    const filteredItems = items?.filter(({ name, subItems }) => {
        return name !== 'employerProjects.selected' && subItems?.length;
    });
    return (
        <React.Fragment>
            {filteredItems?.map(({ name, subItems, translations }, index) => {
                return (
                    <React.Fragment key={name}>
                        <SupernovaDropdownOption Element="div" name={name}>
                            <H2>{translations.name}</H2>
                        </SupernovaDropdownOption>
                        {subItems?.map(({ name, translations, ...props }) => (
                            <SupernovaDropdownOption key={name} name={name} {...props}>
                                {translations.name}
                            </SupernovaDropdownOption>
                        ))}
                        {index !== filteredItems.length - 1 && <VSpacing base={6} />}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default MobileServices;
