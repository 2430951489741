import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'src/hooks/useSelector';

import { DEEP_LINK_COMMAND_QUERY_KEY } from 'src/components/DeepLinkCommand/constants';
import { useOpenChatCommand } from 'src/components/DeepLinkCommand/hooks/useOpenChatCommand';
import { DeepLinkCommandNameMap, DeepLinkCommandName } from 'src/components/DeepLinkCommand/types';
import { getCommandFromQueryParams } from 'src/components/DeepLinkCommand/utils/getCommandFromQueryParams';
import { isDeepLinkAccessible } from 'src/components/DeepLinkCommand/utils/isDeepLinkAccessible';

export const DeepLinkCommandExecutor: FC = () => {
    const dispatch = useDispatch();
    const userType = useSelector(({ userType }) => userType);
    const { pathname } = useLocation();

    const openChatCommand = useOpenChatCommand();

    const commandMapRef = useRef<DeepLinkCommandNameMap>({
        [DeepLinkCommandName.OpenChat]: openChatCommand,
    });

    useEffect(() => {
        if (!isDeepLinkAccessible(userType)) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const deepLinkCommandName = getCommandFromQueryParams(queryParams);

        if (!deepLinkCommandName) {
            return;
        }

        const command = commandMapRef.current[deepLinkCommandName];

        if (command) {
            command(queryParams);

            queryParams.delete(DEEP_LINK_COMMAND_QUERY_KEY);
            const queryString = queryParams.toString();
            const path = window.location.pathname + queryString ? `?${queryString}` : '';

            window.history.replaceState(window.history.state, document.title, path);
        }
    }, [dispatch, pathname, userType]);

    return null;
};

export default DeepLinkCommandExecutor;
