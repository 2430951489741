import { FC, useState, useCallback, RefObject } from 'react';

import { HSpacing, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { SearchQueries } from 'src/models/search/resume/searchQueries';

import QueryConditionsMagritte from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/QueryConditions';
import MagritteSearchQuery from 'src/components/ResumeAdvancedSearch/SearchQueries/MagritteSearchQuery';
import QueryConditionsBloko from 'src/components/ResumeAdvancedSearch/SearchQueries/QueryConditions';
import QuerySettingsXsButton from 'src/components/ResumeAdvancedSearch/SearchQueries/QuerySettingsXsButton';
import RemoveButton from 'src/components/ResumeAdvancedSearch/SearchQueries/RemoveButton';
import SearchQueryInput from 'src/components/ResumeAdvancedSearch/SearchQueries/SearchQueryInput';

import styles from './search-query.less';

interface SearchQueryProps {
    query: SearchQueries;
    queries: SearchQueries[];
    onRemove: (index: number) => void;
    setFromSuggest: (value: boolean) => void;
    isNaviSearch?: boolean;
    autoInvite?: boolean;
    formRef?: RefObject<HTMLFormElement>;
    isMagritte?: boolean;
}

const SearchQuery: FC<SearchQueryProps> = ({
    query,
    queries,
    onRemove,
    setFromSuggest,
    isNaviSearch,
    autoInvite,
    formRef,
    isMagritte,
}) => {
    const [searchQuery, setSearchQuery] = useState(query);

    const { isXS, isMobile } = useBreakpoint();

    const handleFieldSubmit = useCallback(() => {
        formRef?.current?.requestSubmit?.();
    }, [formRef]);

    const QueryConditions = isMagritte ? QueryConditionsMagritte : QueryConditionsBloko;
    const isDesktopConditionsHidden = isMagritte ? isMobile : isXS;

    return (
        <div data-qa="resumesearch__block">
            <div className={styles.resumeSearchForm}>
                {isMagritte ? (
                    <MagritteSearchQuery
                        query={searchQuery}
                        setFromSuggest={setFromSuggest}
                        onSubmit={handleFieldSubmit}
                    />
                ) : (
                    <SearchQueryInput query={searchQuery} setFromSuggest={setFromSuggest} />
                )}
                {isMobile && (
                    <>
                        <HSpacing xs={8} s={isMagritte ? 8 : 0} default={0} />
                        <QuerySettingsXsButton
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            autoInvite={autoInvite}
                            isMagritte={isMagritte}
                        />
                    </>
                )}
                {queries.length > 1 && (
                    <>
                        <HSpacing default={isMagritte ? 8 : 12} />
                        <RemoveButton onRemove={() => onRemove(searchQuery.id)} isMagritte={isMagritte} />
                    </>
                )}
            </div>
            {isMagritte && !isDesktopConditionsHidden && <VSpacing default={8} />}
            <QueryConditions
                query={searchQuery}
                setQuery={setSearchQuery}
                isNaviSearch={isNaviSearch}
                autoInvite={autoInvite}
            />
            <VSpacing default={16} gteS={isMagritte ? 24 : 16} />
        </div>
    );
};

export default translation(SearchQuery);
