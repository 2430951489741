import { FC, useEffect } from 'react';

import uncheckedEmployerEvent, {
    State,
} from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/unchecked_employer_event';
import { Button, Text, VSpacing } from '@hh.ru/magritte-ui';

import NotVerifiedEmployerBanner from 'src/components/EmployerVerification/NotVerifiedEmployerBanner';
import {
    useEmployerVerificationState,
    BannerType,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import Form from 'src/components/Form';
import AccountTypeBadge from 'src/components/SupernovaMainMenu/AccountTypeBadge';
import CurrentUserFullName from 'src/components/SupernovaMainMenu/CurrentUserFullName';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { NavItems } from 'src/models/supernovaNaviMenu';

import EmployerAccountSwitcher from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerAccountSwitcher';
import EmployerNameWithBadge from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerNameWithBadge';
import EmployerProfileId from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerProfileId';

import styles from './styles.less';

interface EmployerProfileHeaderProps {
    subItems?: NavItems;
}

const EmployerProfileHeader: FC<EmployerProfileHeaderProps> = ({ subItems }) => {
    const employerName = useSelector((state) => state.employerName);
    const hasDivisions = useSelector((state) => state.employerHasDivisions);
    const hasImplants = useSelector((state) => state.session.hasExtraAccounts);
    const employerId = useSelector((state) => state.employerId);
    const isMultiAccount = useSelector((state) => state.session.isMultiAccount);
    const verificationExpEnabled = useSelector((state) => state.verificationExpEnabled);

    const { shouldShowExperiment, shouldShowIcon, shouldShowBanner, employerState } =
        useEmployerVerificationState(true);
    const isNotVerifiedEmployerBannerExp = useExperiment('ios_employer_verification_status', shouldShowExperiment);

    useEffect(() => {
        if (isNotVerifiedEmployerBannerExp && employerState) {
            uncheckedEmployerEvent({
                employer_id: employerId, // eslint-disable-line camelcase
                state: employerState.toLowerCase() as State,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sudoLogOffItem = subItems?.find((item) => item.name === 'logoffSudoUser');

    return (
        <div className={styles.headerContent}>
            {isMultiAccount && (
                <>
                    <AccountTypeBadge />
                    <VSpacing default={20} />
                </>
            )}

            <div className={styles.headerContentName}>
                <Text typography="title-5-semibold" data-qa="employerInfo">
                    <CurrentUserFullName />
                </Text>
            </div>
            <VSpacing default={8} />
            {hasDivisions || hasImplants ? (
                <EmployerAccountSwitcher
                    employerName={employerName}
                    hasDivisions={hasDivisions}
                    hasImplants={hasImplants}
                    isNotVerifiedEmployerBannerExp={
                        (isNotVerifiedEmployerBannerExp || verificationExpEnabled) && shouldShowIcon
                    }
                />
            ) : (
                <EmployerNameWithBadge
                    isNotVerifiedEmployerBannerExp={
                        (isNotVerifiedEmployerBannerExp || verificationExpEnabled) && shouldShowIcon
                    }
                    employerName={employerName}
                />
            )}
            <VSpacing default={16} />
            <EmployerProfileId employerId={employerId} />
            {sudoLogOffItem && (
                <>
                    <VSpacing default={20} />
                    <Form className={styles.fullwidthForm} action={sudoLogOffItem.url}>
                        <Button
                            data-qa={`mainmenu_${sudoLogOffItem.name}`}
                            stretched
                            size="medium"
                            style="accent"
                            mode="secondary"
                            type="submit"
                        >
                            {sudoLogOffItem.translations.name}
                        </Button>
                    </Form>
                </>
            )}
            {(isNotVerifiedEmployerBannerExp || verificationExpEnabled) && shouldShowBanner && (
                <>
                    <VSpacing default={32} />
                    <NotVerifiedEmployerBanner bannerType={BannerType.Profile} />
                </>
            )}
        </div>
    );
};

export default EmployerProfileHeader;
