import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SearchCatalogType } from 'src/models/searchCatalogType';

export enum CommonExtraFilter {
    PolnayaZanyatost = 'polnaya_zanyatost',
    ChastichnayaZanyatost = 'chastichnaya_zanyatost',
    ProektnayaRabota = 'proektnaya_rabota',
    PolniyDen = 'polniy_den',
    SmenniyGrafik = 'smenniy_grafik',
    VakhtoviyMetod = 'vakhtoviy_metod',
    GibkiyGrafik = 'gibkiy_grafik',
}

export enum VacancyExtraFilter {
    Podrabotka = 'podrabotka',
    BezOpytaRaboty = 'bez_opyta_raboty',
    UdalennayaRabota = 'udalennaya_rabota',
    ZaPoslednieTriDnya = 'za_poslednie_tri_dnya',
    ZaSutki = 'za_sutki',
    OtPryamihRabotodateley = 'ot_pryamih_rabotodateley',
    RabotaDlyaInvalidov = 'rabota_dlya_invalidov',
}

export interface SearchCatalog {
    type: SearchCatalogType;
    profession: string;
    relevantExtraFilters: CommonExtraFilter[] | VacancyExtraFilter[];
}

export default autoGeneratedReducer<SearchCatalog>(null);
