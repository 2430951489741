import { FC, Fragment, useEffect, useRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import userStatusSelectOfferCompanyElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_element_shown';
import userStatusSelectOfferCompanyScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_screen_shown';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'src/hooks/useSelector';
import { CompanyLogo, LogoType } from 'src/models/applicant/jobSearchStatus';

import { Offer } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/types';

import styles from './offers-content.less';

const ACCEPTABLE_LOGOS = [LogoType.VacancyPage, LogoType.Medium] as const;

interface OffersContentProps {
    addOffer: (employerId: number, vacancyId: number, resumeId: number) => void;
    selectedOffers: Offer[];
}

const findAcceptableLogo: (logos: CompanyLogo[] | undefined) => string | null = (logos) => {
    if (!logos) {
        return null;
    }

    const logo = logos.find((logo) => ACCEPTABLE_LOGOS.includes(logo['@type']));

    return logo?.['@url'] || null;
};

const OffersContent: FC<OffersContentProps & PropsWithChildren> = ({ addOffer, selectedOffers }) => {
    const possibleJobOffers = useSelector(({ applicantPossibleJobOffers }) => applicantPossibleJobOffers);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current && possibleJobOffers.length > 0) {
            userStatusSelectOfferCompanyScreenShown();
            userStatusSelectOfferCompanyElementShown(wrapperRef.current, {
                type: 'full',
                employerIdList: possibleJobOffers.map((offer) => offer.employerId).join(','),
                vacancyIdList: possibleJobOffers
                    .filter((offer) => Boolean(offer.vacancyId))
                    .map((offer) => offer.vacancyId)
                    .join(','),
            });
        }
    }, [possibleJobOffers]);

    if (possibleJobOffers.length === 0) {
        return null;
    }

    const selectedVacanciesIds = selectedOffers.map(({ vacancyId }) => vacancyId);
    return (
        <div ref={wrapperRef}>
            {possibleJobOffers.map((offer, index) => {
                const logoUrl = findAcceptableLogo(offer.logos?.logo);
                return (
                    <Fragment key={offer.vacancyId}>
                        <div
                            className={classnames(styles.offersContentWrapper, {
                                [styles.offersContentWrapperSelected]: selectedVacanciesIds.includes(offer.vacancyId),
                            })}
                            onClick={() => {
                                addOffer(offer.employerId, offer.vacancyId || 0, offer.resumeId || 0);
                            }}
                        >
                            <VSpacing base={2} />
                            <div className={styles.offersContentFlexbox}>
                                <div className={styles.offersContentTextContainer}>
                                    <VSpacing base={2} />
                                    <Text Element="div" size={TextSize.Large} strong>
                                        {offer.name}
                                    </Text>
                                    <VSpacing base={1} />
                                    <Text Element="div" importance={TextImportance.Secondary}>
                                        <div className={styles.offersContentVacancies}>
                                            {offer.vacancyNames.join(', ')}
                                        </div>
                                    </Text>
                                    <VSpacing base={2} />
                                </div>
                                <div className={styles.offersContentLogoWrapper}>
                                    {logoUrl && (
                                        <img className={styles.offersContentLogo} src={logoUrl} alt={offer.name} />
                                    )}
                                </div>
                            </div>
                            <VSpacing base={2} />
                        </div>
                        {index < possibleJobOffers.length - 1 && <VSpacing base={3} />}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default OffersContent;
