import { useRef } from 'react';

import Link from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    text: 'supernova.notification.prices.increase',
    moreLink: 'notifications.siteWasUnavailable.link',
};

const EmployerPricesIncrease: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <SynchronizedNotification
            analyticsElementName="employer_prices_increase"
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
        >
            <p>{trls[TrlKeys.text]}</p>
            <NotificationFooter>
                <Link
                    href={`/article/25341?from=notification&hhtmFrom=${hhtmSource}&hhtmFromLabel=user_notification`}
                    target="_blank"
                    onClick={() => closeNotificationRef.current?.()}
                >
                    {trls[TrlKeys.moreLink]}
                </Link>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(EmployerPricesIncrease);
