import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { OrderByOption } from 'src/models/search/searchCriteria.types';

export interface NovaSort {
    orderBy: OrderByOption;
    searchPeriod: string;
}

export default autoGeneratedReducer<NovaSort>({
    orderBy: OrderByOption.Relevance,
    searchPeriod: '0',
});
