import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.EmployerVerified]: {
        description: 'employer.verification.notification.verified',
    },
    [ComplexUserNotificationsTemplateKey.EmployerNotVerified]: {
        description: 'employer.verification.notification.notVerified',
    },
};

const notificationPropsByTemplateKey = {
    [ComplexUserNotificationsTemplateKey.EmployerVerified]: {
        iconKind: SupernovaUserNotificationsIconKind.Verified,
        dataQa: DataQaInvariants.Verified,
    },
    [ComplexUserNotificationsTemplateKey.EmployerNotVerified]: {
        iconKind: SupernovaUserNotificationsIconKind.Exclamation,
        dataQa: DataQaInvariants.Exclamation,
    },
};

const isEmployerVerificationNotification = (
    templateKey: ComplexUserNotificationsTemplateKey
): templateKey is keyof typeof TrlKeys => Object.keys(TrlKeys).includes(templateKey);

const EmployerVerification: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const templateKey = notification.templateKey;

    // temp_exp_35925_next_line
    const notificationShownRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: templateKey.split('.').join('_'),
    });

    if (!isEmployerVerificationNotification(templateKey)) {
        return null;
    }

    const notificationProps = notificationPropsByTemplateKey[templateKey];
    return (
        <Notification {...notificationProps} {...notification}>
            <span ref={notificationShownRef}>{trls[TrlKeys[templateKey].description]}</span>
        </Notification>
    );
};

export default translation(EmployerVerification);
