import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { MetallicVacancyType } from 'src/models/vacancy/metallicVacancyType.types';
import { PublicationTypePurchase } from 'src/models/vacancyCreate/availablePublicationTypes';

const FETCH_VACANCY_UPGRADES_FAILED = 'FETCH_VACANCY_UPGRADES_FAILED';
const FETCH_VACANCY_UPGRADES_START = 'FETCH_VACANCY_UPGRADES_START';
const FETCH_VACANCY_UPGRADES_SUCCESS = 'FETCH_VACANCY_UPGRADES_SUCCESS';

export interface Upgrade {
    canActivate: boolean;
    canBuy: boolean;
    canUpgrade: boolean;
    metallic: MetallicVacancyType;
    purchase: PublicationTypePurchase;
    quotasExhausted: boolean;
}

export interface BackUrl {
    upgrade: string;
    buy: string;
}

interface VacancyUpgrade {
    isFetching: boolean;
    backurl?: BackUrl;
    suitableUpgrades?: Upgrade[];
}

type VacancyUpgrades = Record<number, VacancyUpgrade>;

interface PayloadTypes {
    [FETCH_VACANCY_UPGRADES_FAILED]: number;
    [FETCH_VACANCY_UPGRADES_START]: number;
    [FETCH_VACANCY_UPGRADES_SUCCESS]: {
        id: number;
        data: VacancyUpgrade;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const fetchVacancyUpgradesFailed = actionCreator(FETCH_VACANCY_UPGRADES_FAILED);
export const fetchVacancyUpgradesStart = actionCreator(FETCH_VACANCY_UPGRADES_START);
export const fetchVacancyUpgradesSuccess = actionCreator(FETCH_VACANCY_UPGRADES_SUCCESS);
export const clearUpgradeInfo = actionCreator(FETCH_VACANCY_UPGRADES_FAILED);

export const INITIAL_STATE: VacancyUpgrades = {};

export default createReducer<VacancyUpgrades, PayloadTypes>(INITIAL_STATE, {
    [FETCH_VACANCY_UPGRADES_FAILED]: (state, action) => ({
        ...state,
        [action.payload]: { isFetching: false },
    }),
    [FETCH_VACANCY_UPGRADES_START]: (state, action) => ({
        ...state,
        [action.payload]: { isFetching: true },
    }),
    [FETCH_VACANCY_UPGRADES_SUCCESS]: (state, action) => ({
        ...state,
        [action.payload.id]: { ...action.payload.data, isFetching: false },
    }),
});
