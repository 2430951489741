import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface MetroStation {
    id: string;
    name: string;
    translit: string;
}

export interface MetroLine {
    id: string;
    name: string;
    translit: string;
    cityId: string;
    color: string;
    metroStations: Array<MetroStation>;
}

interface VacancyMetro {
    metroLines: Array<MetroLine> | null;
}

export default autoGeneratedReducer<VacancyMetro>({
    metroLines: null,
});
