import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

export const NETWORK_ERROR = 'NETWORK_ERROR';

const TrlKeys = {
    error: 'lux.error.network',
};

const NetworkError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.error]}</>;

export default {
    Element: translation(NetworkError),
    kind: 'error',
    autoClose: true,
};
