import { Link, LinkProps, Text, PolymorphicComponent } from '@hh.ru/magritte-ui';

import styles from './link-secondary.less';

const LinkSecondaryContrast: PolymorphicComponent<LinkProps, 'a'> = ({
    Element = 'a',
    typography,
    children,
    ...props
}) => (
    <Link Element={Element} {...props}>
        <span className={styles.linkSecondaryContrast}>
            <Text style="contrast-secondary" typography={typography}>
                {children}
            </Text>
        </span>
    </Link>
);

export default LinkSecondaryContrast;
