import { Tag } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    employer: 'account.type.badge.employer',
    applicant: 'account.type.badge.applicant',
};

const AccountTypeBadge: TranslatedComponent = ({ trls }) => {
    const userType = useSelector((state) => state.userType);

    if (userType !== UserType.Applicant && userType !== UserType.Employer) {
        return null;
    }

    return (
        <Tag>
            <span data-qa={`account-type-badge-${userType}`}>{trls[TrlKeys[userType]]}</span>
        </Tag>
    );
};

export default translation(AccountTypeBadge);
