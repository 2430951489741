import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    title: 'index.footer.menuitem.copyright',
};

const currentYear = new Date().getFullYear();

const FooterCopyright: TranslatedComponent = ({ trls }) => {
    return (
        <span suppressHydrationWarning>
            <Text style="secondary">
                {format(trls[TrlKeys.title], {
                    '{0}': currentYear,
                })}
            </Text>
        </span>
    );
};

export default translation(FooterCopyright);
