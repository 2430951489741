import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

interface Original {
    original: string;
}

interface Icon {
    dark: Original;
    light: Original;
    defaults: Original;
}

export enum ApplicantSkillsVerificationResultKeys {
    CourseUrl = 'course_url',
    BrandingSettings = 'branding_settings',
    CtaButtonEnabled = 'cta_button_enabled',
    AgeLabel = 'age_label',
    InternalId = 'internal_id',
    PracticeStatus = 'practice_status',
    AntifraudVerdict = 'antifraud_verdict',
}

export enum ResultVerdict {
    FraudDetected = 'FRAUD_DETECTED',
    Unknown = 'UNKNOWN',
    Verified = 'VERIFIED',
}

export interface SkillLevel {
    id: number;
    [ApplicantSkillsVerificationResultKeys.InternalId]: string;
    name: string;
    rank: number;
}

export interface ApplicantSkillsVerificationResultMethod {
    id: number;
    name: string;
    description: string;
    source: {
        id: number;
        name: string;
        href: string;
        description: string | null;
        icon: Icon;
        headline: Icon;
    };
    platform: string;
    icon: Icon;
    [ApplicantSkillsVerificationResultKeys.BrandingSettings]: {
        enabled: boolean;
        [ApplicantSkillsVerificationResultKeys.CtaButtonEnabled]: boolean;
        [ApplicantSkillsVerificationResultKeys.AgeLabel]: string;
    };
}

export interface ApplicantSkillsVerificationResultItem {
    id: number;
    level: SkillLevel | null;
    [ApplicantSkillsVerificationResultKeys.CourseUrl]: string;
    [ApplicantSkillsVerificationResultKeys.PracticeStatus]: string;
    content: string;
    name: string;
    category: string;
    result: {
        type: string;
        status: string;
        score: {
            max: number;
            actual: number;
        };
        [ApplicantSkillsVerificationResultKeys.AntifraudVerdict]: ResultVerdict;
    };
}

export interface ApplicantSkillsVerificationResult {
    method: ApplicantSkillsVerificationResultMethod;
    items: ApplicantSkillsVerificationResultItem[];
}

const REFRESH_APPLICANT_SKILLS_VERIFICATION_RESULTS = 'REFRESH_APPLICANT_SKILLS_VERIFICATION_RESULTS';

interface PayloadTypes {
    [REFRESH_APPLICANT_SKILLS_VERIFICATION_RESULTS]: null;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const refreshApplicantSkillsVerificationResults = actionCreator(REFRESH_APPLICANT_SKILLS_VERIFICATION_RESULTS);

const initialState = null;

export default createReducer<ApplicantSkillsVerificationResult | null, PayloadTypes>(initialState, {
    [REFRESH_APPLICANT_SKILLS_VERIFICATION_RESULTS]: () => initialState,
});
