import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ManagerAnnouncementBanner {
    body: string;
    click: string;
    isExternalAdvertising?: boolean;
    advertiserLegalName?: string;
    viewUrl: string;
}

export default autoGeneratedReducer<ManagerAnnouncementBanner>(null);
