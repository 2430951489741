import { FC, useContext, useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Breakpoint, useBreakpoint } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaLink from 'src/components/SupernovaMainMenu/NaviLink';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import useIsAuthorizedRedesign from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import useSendExpOnEmployerServicesClick from 'src/components/SupernovaMainMenu/hooks/useSendExpOnEmployerServicesClick';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const NAVI_ITEM_PROPS: Record<string, Record<string, unknown>> = {
    pricelistZp: {
        visibleOn: [Breakpoint.L],
    },
    pricelistZpShort: {
        visibleOn: [Breakpoint.L],
    },
    pricelist: {
        visibleOn: [Breakpoint.L],
    },
    pricelistShort: {
        visibleOn: [Breakpoint.L],
    },
    allServices: {
        visibleOn: [Breakpoint.L],
    },
    vacancy: {
        visibleOn: [Breakpoint.L, Breakpoint.M],
    },
    employerServices: {
        visibleOn: [Breakpoint.L],
    },
};

const NAVI_ITEM_ANOYMOUS_MAGRITTE_PROPS: Record<string, Record<string, unknown>> = {
    pricelistZp: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
    pricelist: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
    anonEmployerPriceShort: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
    allServices: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
    writeToUs: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
};

const isDisplayed = (name: string, breakpointInfo: ReturnType<typeof useBreakpoint>): boolean => {
    const { isGtM } = breakpointInfo;

    return name === 'allServices' ? isGtM : true;
};

const GenericNavItem: FC<NavItem> = ({
    active,
    counter,
    name,
    trackClicks,
    translations,
    url,
    arrowed = false,
    trackElementShown,
    analytics,
}) => {
    const isRedesignActive = useContext(isRedesignActiveContext);
    const isRedesignAuthorized = useIsAuthorizedRedesign();
    const handleExpSendCheck = useSendExpOnEmployerServicesClick(name);

    const userStat = useSelector(({ userStats }) => counter && userStats?.[counter]);
    const ref = useRef<HTMLDivElement>(null);
    const breakpointInfo = useBreakpoint();

    useEffect(() => {
        if (trackElementShown && ref.current && isDisplayed(name, breakpointInfo)) {
            Analytics.sendHHEventElementShown(ref.current, { name: `menu_item_${name}` });
        }
    }, [breakpointInfo, name, trackElementShown]);

    if (!url) {
        return null;
    }

    if (isRedesignActive) {
        return (
            <SupernovaNaviItem
                active={active}
                arrowed={arrowed}
                ref={ref}
                visibleOn={VISIBLE_ON}
                {...(isRedesignAuthorized ? NAVI_ITEM_PROPS?.[name] : NAVI_ITEM_ANOYMOUS_MAGRITTE_PROPS[name])}
            >
                <SupernovaActionLink
                    badgeCount={userStat}
                    showBadge={!!userStat}
                    name={name}
                    data-qa={`mainmenu_${name}`}
                    active={active}
                    onClick={handleExpSendCheck}
                    analytics={analytics}
                    trackClicks={trackClicks}
                    url={url}
                >
                    {translations.name}
                </SupernovaActionLink>
            </SupernovaNaviItem>
        );
    }

    return (
        <SupernovaNaviItem
            visibleOn={VISIBLE_ON}
            active={active}
            arrowed={arrowed}
            ref={ref}
            {...NAVI_ITEM_PROPS?.[name]}
        >
            <SupernovaLink
                active={active}
                data-qa={`mainmenu_${name}`}
                name={name}
                analytics={analytics}
                onClick={handleExpSendCheck}
                trackClicks={trackClicks}
                url={url}
            >
                {translations.name}
                {!!userStat && <SupernovaCounter>{userStat}</SupernovaCounter>}
            </SupernovaLink>

            {!arrowed && <div className="supernova-navi-underline">{translations.name}</div>}
        </SupernovaNaviItem>
    );
};

export default GenericNavItem;
