import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const OPEN_ANONYMOUS_SIGN_UP_MODAL = 'OPEN_ANONYMOUS_SIGN_UP_MODAL';
const CLOSE_ANONYMOUS_SIGN_UP_MODAL = 'CLOSE_ANONYMOUS_SIGN_UP_MODAL';

interface InitialState {
    type: 'mainPage' | null;
    opened: boolean;
    backUrl: string;
    alreadyShown: boolean;
}

export const INITIAL_STATE: InitialState = {
    type: null,
    opened: false,
    backUrl: '',
    alreadyShown: false,
};

interface PayloadTypes {
    [OPEN_ANONYMOUS_SIGN_UP_MODAL]: string;
    [CLOSE_ANONYMOUS_SIGN_UP_MODAL]: void;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const openAnonymousSignUpModal = ActionCreator(OPEN_ANONYMOUS_SIGN_UP_MODAL);
export const closeAnonymousSignUpModal = ActionCreator(CLOSE_ANONYMOUS_SIGN_UP_MODAL);

export default createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [OPEN_ANONYMOUS_SIGN_UP_MODAL]: (state, { payload }) => ({
        ...state,
        opened: true,
        backUrl: payload,
    }),
    [CLOSE_ANONYMOUS_SIGN_UP_MODAL]: (state) => ({
        ...state,
        opened: false,
        alreadyShown: true,
    }),
});
