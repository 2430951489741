import { FC, PropsWithChildren, KeyboardEvent, MouseEvent } from 'react';

import { useBreakpoint, Card, Avatar, Button, Cell, CellText } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './help-modal-personal-manager.less';

const TrlKeys = {
    personalManager: 'supernova.modal.help.personal.manager',
    personalManagerContact: 'supernova.modal.help.personal.manager.contact',
};

const WrapperCard: FC<
    PropsWithChildren<{
        external?: boolean;
        handleClick: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
        url: string;
        name: string;
    }>
> = ({ children, external, handleClick, url, name }) => {
    const { isMobile } = useBreakpoint();

    if (isMobile) {
        return (
            <Card
                borderRadius={24}
                padding={12}
                stretched
                showBorder
                actionCard
                Element={Link}
                to={url}
                target={external ? '_blank' : undefined}
                onClick={handleClick}
                data-qa={`mainmenu_${name}`}
            >
                {children}
            </Card>
        );
    }
    return (
        <Card borderRadius={24} padding={12} stretched showBorder>
            {children}
        </Card>
    );
};

const HelpModalPersonalManager: TranslatedComponent<{ item: NavItem }> = ({ trls, item }) => {
    const personalManager = useSelector(({ personalManager }) => personalManager);
    const { name, trackClicks, analytics, url, external, translations } = item;

    const { isMobile } = useBreakpoint();

    const trackClick = useTrackClicks(name, trackClicks, analytics);

    return (
        <WrapperCard external={external} handleClick={trackClick} url={url} name={name}>
            <div className={styles.helpCardContainer}>
                <Cell
                    left={
                        <Avatar
                            size={48}
                            mode="image"
                            image={personalManager.smallPhoto}
                            fallbackMode="placeholder"
                            aria-label="manager photo"
                        />
                    }
                    right={isMobile ? <ChevronRightOutlinedSize24 initial="secondary" /> : undefined}
                >
                    <CellText type="title">
                        {personalManager.firstName} {personalManager.lastName}
                    </CellText>
                    <CellText type="subtitle">{translations.name}</CellText>
                </Cell>
                {!isMobile && (
                    <Button
                        Element={Link}
                        target={external ? '_blank' : undefined}
                        to={url}
                        onClick={trackClick}
                        mode="secondary"
                        data-qa={`mainmenu_${name}`}
                    >
                        {trls[TrlKeys.personalManagerContact]}
                    </Button>
                )}
            </div>
        </WrapperCard>
    );
};

export default translation(HelpModalPersonalManager);
