import classnames from 'classnames';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaMainMenu from 'src/components/SupernovaMainMenu';
import useOverlayMenu from 'src/components/SupernovaOverlayMenu/useOverlayMenu';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { LightPageCommonProps } from 'src/app/layouts/BlokoLayouts/LightPageLayout/lightPage.types';

const TrlKeys = {
    logoTitle: 'navi.logo.title',
};

const LightPageLayoutHeader: TranslatedComponent<LightPageCommonProps> = ({ trls, promo, afterMenuContent }) => {
    const logoDomain = useSelector(({ logos }) => logos.logoDomain);
    const { overlay, showOverlay } = useOverlayMenu();
    const { isGtS } = useBreakpoint();

    return (
        <div className="light-page-header">
            {!isGtS && (
                <div className="light-page-header__xs">
                    <SupernovaMainMenu
                        afterMenuContent={afterMenuContent}
                        overlay={overlay}
                        showOverlay={showOverlay}
                    />
                </div>
            )}
            <div className="light-page-header__content">
                <a href="/">
                    <span className="light-page-header__img">
                        <span
                            className={classnames('supernova-logo', {
                                'supernova-logo_inversed': promo,
                                [`supernova-logo_${logoDomain}`]: logoDomain,
                            })}
                            title={trls[TrlKeys.logoTitle]}
                        />
                    </span>
                </a>
            </div>
        </div>
    );
};

export default translation(LightPageLayoutHeader);
