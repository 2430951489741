import { FC, PropsWithChildren } from 'react';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import VacancyScheduledPublicationNotification from 'src/components/VacancyScheduledPublicationNotification';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const VacancyScheduledPublication: FC<ComplexUserNotification & PropsWithChildren> = ({ templateKey, ...props }) => {
    const content = (
        <VacancyScheduledPublicationNotification Component={NotificationLink} templateKey={templateKey} {...props} />
    );

    if (
        templateKey === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess ||
        templateKey === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses
    ) {
        return (
            <Notification
                iconKind={SupernovaUserNotificationsIconKind.Success}
                dataQa={DataQaInvariants.Success}
                templateKey={templateKey}
                {...props}
            >
                {content}
            </Notification>
        );
    }

    return (
        <Notification templateKey={templateKey} {...props}>
            {content}
        </Notification>
    );
};

export default translation(VacancyScheduledPublication);
