import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { YearMonthDayString } from 'bloko/blocks/calendar/datesHelper';

import { StartAfterPayment, TariffProductType } from 'src/models/price/product.types';
import { ZpTariff } from 'src/models/price/zpTariff';

export const UNLIMITED_RESUME_THRESHOLD = 10000;

export enum ZpTabName {
    ZpResumeAccess = 'zp-resume-access',
    ZpPublications = 'zp-publications',
    ZpBundles = 'zp-bundles',
    ZpPromotion = 'zp-promotion',
}

export enum ZpProductFields {
    Period = 'period',
    CivCount = 'civCount',
    ProfRoleGroup = 'profRoleGroup',
    BusinessCount = 'businessCount',
    PromoCount = 'promoCount',
    AnonymousCount = 'anonymousCount',
    ActivationDate = 'activationDate',
    OptionCount = 'optionCount',
}

export interface ZpProductParams {
    [ZpProductFields.Period]?: number;
    [ZpProductFields.CivCount]?: number;
    [ZpProductFields.ProfRoleGroup]?: string;
    [ZpProductFields.BusinessCount]?: number;
    [ZpProductFields.PromoCount]?: number;
    [ZpProductFields.AnonymousCount]?: number;
    [ZpProductFields.ActivationDate]?: YearMonthDayString | StartAfterPayment;
    [ZpProductFields.OptionCount]?: number;
}

export const publicationsCountFields = [
    ZpProductFields.BusinessCount,
    ZpProductFields.PromoCount,
    ZpProductFields.AnonymousCount,
];

export type FieldName = keyof ZpProductParams;

export interface DescriptorField {
    name: FieldName;
    values?: string[];
    default: string;
    minValue?: YearMonthDayString;
    maxValue?: YearMonthDayString;
}

export interface Tariff {
    tariffName: ZpTariff;
    backoffice: boolean;
    fieldNames: FieldName[];
    fields: Record<FieldName, DescriptorField>;
    product: TariffProductType | null;
    loading: boolean;
    params: ZpProductParams;
    translations: { title: string };
}

export interface PriceZP {
    tabName: ZpTabName;
    tariffNames: ZpTariff[];
    tariffs: Record<ZpTariff, Tariff>;
    backofficeTariffNames: ZpTariff[];
    backofficeTariffs: Record<ZpTariff, Tariff>;
}

const UPDATE_ZP_TARIFF = 'UPDATE_ZP_TARIFF';

interface PayloadTypes {
    [UPDATE_ZP_TARIFF]: {
        tariffName: ZpTariff;
        isBackoffice: boolean;
        params?: ZpProductParams;
        loading?: boolean;
        product?: TariffProductType | null;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateZpTariff = actionCreator(UPDATE_ZP_TARIFF);

export default createReducer<PriceZP, PayloadTypes>(null, {
    [UPDATE_ZP_TARIFF]: (state, { payload: { tariffName, isBackoffice, params, loading, product } }) => {
        const tariff = isBackoffice ? state.backofficeTariffs[tariffName] : state.tariffs[tariffName];
        const newParams = params ?? tariff.params;
        const newProduct = product ?? tariff.product;
        const newLoading = loading ?? tariff.loading;
        const updatedTariff = { ...tariff, params: newParams, product: newProduct, loading: newLoading };
        return isBackoffice
            ? {
                  ...state,
                  backofficeTariffs: {
                      ...state.backofficeTariffs,
                      [tariffName]: updatedTariff,
                  },
              }
            : {
                  ...state,
                  tariffs: {
                      ...state.tariffs,
                      [tariffName]: updatedTariff,
                  },
              };
    },
});
