import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ArticleRubric, ArticleTopic, ArticleType, ArticleTypes } from 'src/models/articlePage/types';

export enum SocialShareIcons {
    Vk = 'Vk',
    Facebook = 'Facebook',
    Twitter = 'Twitter',
    Ok = 'Ok',
    Whatsapp = 'Whatsapp',
    Viber = 'Viber',
    Telegram = 'Telegram',
}

export interface ShareButtonProps {
    type: string;
    icon: keyof typeof SocialShareIcons;
    url: string;
}

export const ARTICLE_BREADCRUMBS = {
    ArticlesMark: 'articlesMark',
    PartnerMark: 'partnerMark',
    BusinessMark: 'businessMark',
    YoungProfessionalsMark: 'youngProfessionalsMark',
    PressReleaseMark: 'pressReleaseMark',
} as const;
export type ArticleBreadcrumb = (typeof ARTICLE_BREADCRUMBS)[keyof typeof ARTICLE_BREADCRUMBS];

export enum ImageType {
    Original = 'original',
    AdminPreview = 'admin_preview',
    Title = 'title',
    Cover = 'cover',
    Recomended = 'recomended',
    Preview = 'preview',
    Announce = 'announce',
    Related = 'related',
    Readmore = 'readmore',
    Landing = 'landing',
}

export enum InsiderDesignType {
    Simple = 'simple',
    Landing = 'landing',
}

export interface Image {
    type: ImageType;
    url: string;
    caption: string;
}

export interface ReadMoreArticle {
    code: string;
    title: string;
    images: Array<Image>;
}

interface ArticlePage {
    id: string;
    shareButtons: Array<ShareButtonProps>;
    type: ArticleType;
    showMainArticleImage: boolean;
    showShareButtons: boolean;
    breadcrumbs: Array<ArticleBreadcrumb>;
    readMore: ReadMoreArticle[];
    title: string;
    lead: string;
    announce: string;
    authorName: string;
    authorPost: string;
    publicationTime: string;
    eventStartDate?: string;
    eventStartDateShort?: string;
    eventEndDate?: string;
    images: Array<Image>;
    authorPhotos: Array<Image>;
    insiderDesignType: InsiderDesignType;
    rubrics: Array<{ id: string; code: ArticleRubric; priority: string }>;
    topics: Array<{ id: string; code: ArticleTopic; priority: string }>;
}

export default autoGeneratedReducer<ArticlePage>({
    id: '',
    shareButtons: [],
    type: ArticleTypes.Planet,
    showMainArticleImage: false,
    showShareButtons: false,
    breadcrumbs: [],
    readMore: [],
    title: '',
    lead: '',
    announce: '',
    authorName: '',
    authorPost: '',
    publicationTime: '',
    images: [],
    authorPhotos: [],
    insiderDesignType: InsiderDesignType.Simple,
    rubrics: [],
    topics: [],
});
