import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export interface AuthUrlType {
    'login-url': string;
    'login-form': string;
    'login-field-value': string;
    'login-field-value-entered': string;
    'fake-auth-field-name': string;
    'fake-auth-field-value': string;
    'backurl-field-name': string;
    'remember-field-name': string;
    'remember-field-value': string;
    backurl: string;
    failurl: string;
    'signup-form': string;
    'auth-employer-url': string;
    'remember-password': string;
    'agreement-form': string;
    'employer-agreement': string;
    'policy-url': string;
}

const AUTH_URL_UPDATE = 'AUTH_URL_UPDATE';

interface PayloadTypes {
    [AUTH_URL_UPDATE]: Partial<AuthUrlType>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const authUrlUpdate = actionCreator(AUTH_URL_UPDATE);

export default createReducer<AuthUrlType, PayloadTypes>(null, {
    [AUTH_URL_UPDATE]: (state, action) => {
        return { ...state, ...action.payload };
    },
});
