import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { ModelData } from 'bloko/common/tree/types';

import { RegionsListAdditional } from 'src/components/Price/RegionSelectorPopup/RegionsList';

import {
    DBAccessProductType,
    ProductIdType,
    RegionIdType,
    SubProductType,
    PeriodType,
} from 'src/models/price/product.types';
import { ServiceCode } from 'src/models/price/productCodes';

export interface GiftType {
    code: ServiceCode;
    codeTrl: string;
    products: Record<string, SubProductType>;
    profRoleGroups: string[];
    region: RegionIdType;
}

export type PriceDbAccessProducts = Record<ProductIdType, DBAccessProductType>;
export type PriceDbAccessRegionTrls = Record<RegionIdType, string>;

export interface PriceDbAccess {
    regions: RegionIdType[];
    regionPeriods: Record<RegionIdType, PeriodType[]>;
    regionPeriodsWithProfareas: Record<RegionIdType, PeriodType[]>;
    periodsProductsByRegion: Record<RegionIdType, Record<PeriodType, ProductIdType[]>>;
    products: PriceDbAccessProducts;
    gifts: GiftType[];
    defaultPeriod: PeriodType;
    hasAddContactsTab: boolean;
    isBundleSelectedByDefault: boolean;
    priceTrls: {
        regions: PriceDbAccessRegionTrls;
    };
    allPriceRegions: ModelData<RegionsListAdditional>[];
    allProfRoleGroups: ModelData[];
}

export default autoGeneratedReducer<PriceDbAccess>(null);
