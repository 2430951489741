import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import styles from './important-notifications.less';

export enum ImportantNotificationsKind {
    Ok,
    Warning,
}

const ImportantNotifications: FC<
    {
        kind?: ImportantNotificationsKind;
        hasDefaultLayout?: boolean;
    } & PropsWithChildren
> = ({ children, kind, hasDefaultLayout = true }) => {
    const content = hasDefaultLayout ? (
        <Column xs="4" s="8" m="12" l="16">
            {children}
        </Column>
    ) : (
        <>{children}</>
    );

    return (
        <div
            className={classnames(styles.importantNotifications, {
                [styles.importantNotificationsKindOk]: kind === ImportantNotificationsKind.Ok,
                [styles.importantNotificationsKindWarning]: kind === ImportantNotificationsKind.Warning,
            })}
        >
            <ColumnsWrapper>
                <VSpacing base={3} />
                {content}
                <VSpacing base={3} />
            </ColumnsWrapper>
        </div>
    );
};

export default ImportantNotifications;
