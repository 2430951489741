import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface PostponedActions {
    'add-to-favorites-sign'?: number;
    'complete-resume'?: string;
    'vacancy-saved-search-open'?: string;
    'autosearch-add'?: string;
    vacancy?: {
        vacancyId: number;
        name: string;
        compensation: number;
        acceptIncompleteResumes?: boolean;
        immediateRedirectUrl?: string;
    };
}

export default autoGeneratedReducer<PostponedActions>({});
