import { RefObject, useCallback, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Infotip, { InfoPlacement, InfoTheme } from 'src/components/Infotip';
import translation from 'src/components/translation';

const TrlKeys = {
    infotipText: 'infotips.content.multiaccount.switch.text',
    infotipButton: 'infotips.content.multiaccount.switch.button',
};

const MultiAccountSwitchInfotip: TranslatedComponent<{
    children: React.ReactElement;
    infotipRef: RefObject<HTMLElement>;
}> = ({ trls, children, infotipRef }) => {
    const [isInfotipVisible, setInfotipVisible] = useState(true);

    const onSeen = useCallback(() => {
        infotipRef.current &&
            Analytics.sendHHEventElementShown(infotipRef.current, {
                name: 'multiaccount_infotip',
            });
    }, [infotipRef]);

    return (
        <Infotip
            name="multiaccountSwitch"
            placement={InfoPlacement.BottomEnd}
            render={() => (
                <div data-qa="multiaccount-infotip">
                    {trls[TrlKeys.infotipText]}
                    <VSpacing base={5} />
                    <Button
                        kind={ButtonKind.Inversed}
                        appearance={ButtonAppearance.Outlined}
                        onClick={() => setInfotipVisible(false)}
                    >
                        {trls[TrlKeys.infotipButton]}
                    </Button>
                </div>
            )}
            theme={InfoTheme.Dark}
            show={isInfotipVisible}
            showCloseButton
            onClose={() => setInfotipVisible(false)}
            onSeen={onSeen}
            activatorRef={infotipRef}
        >
            {children}
        </Infotip>
    );
};

export default translation(MultiAccountSwitchInfotip);
