import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SearchField } from 'src/models/vacancySearch/vacancySearchDictionaries';
import { EducationOption, ItemsOnPageOption, LabelOption, SearchPeriodOption } from 'src/models/vacancySearchCriteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { CriteriaKey, ExperienceOption, OrderByOption, PartTimeOption } from 'src/models/search/searchCriteria.types';

enum Group {
    Area = 'AREA',
    NoGroupping = 'NO_GROUPING',
    WorkExperience = 'WORK_EXPERIENCE',
}

export interface StatisticGroup {
    id: Group;
    name: string;
    selected: boolean;
}

interface AdvancedVacancySearch {
    [CriteriaKey.Text]?: string;
    randomExampleNumber: 1 | 2 | 3 | 4 | 5;
    searchFields?: SearchField[];
    industrySelectedValues?: string[];
    [CriteriaKey.Salary]?: string;
    selectedValues: {
        [CriteriaKey.AcceptTemporary]?: boolean;
        [CriteriaKey.Education]?: EducationOption[];
        [CriteriaKey.Experience]?: ExperienceOption;
        [CriteriaKey.ExcludedText]?: string;
        [CriteriaKey.Employment]?: EmploymentLower[];
        [CriteriaKey.Schedule]?: ScheduleCamelCase[];
        [CriteriaKey.PartTime]?: PartTimeOption[];
        [CriteriaKey.Label]?: LabelOption[];
        [CriteriaKey.OrderBy]?: OrderByOption;
        [CriteriaKey.Industry]?: string[];
        [CriteriaKey.SearchPeriod]?: SearchPeriodOption;
        [CriteriaKey.ItemsOnPage]?: ItemsOnPageOption;
        [CriteriaKey.Salary]?: string;
        [CriteriaKey.Text]?: string;
        searchFields?: SearchField[];
        [CriteriaKey.OnlyWithSalary]?: boolean;
    };
    searchSettings?: {
        includeArchived: boolean;
        includeClosed: boolean;
        statisticGroups: StatisticGroup[];
    };
}

export default autoGeneratedReducer<AdvancedVacancySearch>({
    [CriteriaKey.Text]: '',
    randomExampleNumber: 1,
    searchFields: [],
    selectedValues: {},
});
