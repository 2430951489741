import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum GlobalStatisticsCategory {
    Vacancies = 'vacancies',
    Employers = 'employers',
    Resumes = 'resumes',
}

export default autoGeneratedReducer<{
    [GlobalStatisticsCategory.Vacancies]: number;
    [GlobalStatisticsCategory.Employers]: number;
    [GlobalStatisticsCategory.Resumes]: number;
}>(null);
