import { FC } from 'react';

import BlokoLink from 'bloko/blocks/link';

import { AreaDomain } from 'src/components/AreaSwitcher/areaSwitcherTypes';

interface AreaSwitcherTopLevelTitleProps {
    domain: AreaDomain;
}

const AreaSwitcherTopLevelTitle: FC<AreaSwitcherTopLevelTitleProps> = ({ domain }) => {
    const shouldRenderLinkName = !domain.isCurrentHost;

    return (
        <div
            className={domain.areaId ? `area-switcher-title area-switcher-title_${domain.areaId}` : ''}
            data-qa="area-switcher-title"
        >
            {shouldRenderLinkName ? (
                <BlokoLink href={domain.href} disableVisited>
                    {domain.name}
                </BlokoLink>
            ) : (
                <>{domain.name}</>
            )}
        </div>
    );
};

export default AreaSwitcherTopLevelTitle;
