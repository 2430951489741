import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SHOW_PICK_VACANCY_MODAL = 'SHOW_PICK_VACANCY_MODAL';

export interface PickVacancyOnResumeSearch {
    isBannerShow?: boolean;
    isEnablePickVacancy?: boolean;
    isShowModal?: boolean;
}

interface PayloadTypes {
    [SHOW_PICK_VACANCY_MODAL]: boolean;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const showPickVacancyModal = actionCreator(SHOW_PICK_VACANCY_MODAL);

export default createReducer<PickVacancyOnResumeSearch, PayloadTypes>(
    {},
    {
        [SHOW_PICK_VACANCY_MODAL]: (state, { payload }) => ({
            ...state,
            isShowModal: payload,
        }),
    }
);
