import { ChangeEvent, FC } from 'react';

import { Segment, Segmented, useBreakpoint } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';

import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './styles.less';

const UserTypeSegmented: FC<NavItem> = ({ subItems, name, trackClicks, analytics }) => {
    const { isS } = useBreakpoint();
    const push = usePush();
    const handleClick = useTrackClicks(name, trackClicks, analytics);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const clickedItem = subItems?.find((item) => item.name === event.target.value);
        handleClick();

        if (clickedItem?.url) {
            push(clickedItem.url);
        }
    };

    return (
        <SupernovaNaviItem userTypeSegmented>
            <div className={styles.segmentedContainer}>
                <Segmented
                    stretched={!isS}
                    onChange={handleChange}
                    selectedValue={subItems?.find((item) => item.active)?.name || ''}
                    mode="label"
                    size="small"
                >
                    {subItems?.map((subItem) => {
                        let pageAnalyticsEvent;
                        if (subItem.name === 'userTypeSegmentedApplicant') {
                            pageAnalyticsEvent = 'switch_role_applicant_header';
                        } else {
                            pageAnalyticsEvent = 'switch_role_employer_header';
                        }
                        return (
                            <Segment
                                data-page-analytics-event={pageAnalyticsEvent}
                                key={subItem.name}
                                value={subItem.name}
                            >
                                {subItem.translations.name}
                            </Segment>
                        );
                    })}
                </Segmented>
            </div>
        </SupernovaNaviItem>
    );
};

export default UserTypeSegmented;
