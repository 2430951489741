import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import urlParser from 'bloko/common/urlParser';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { NotificationLinkProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import VacancyScheduledPublicationNotification from 'src/components/VacancyScheduledPublicationNotification';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const LinkComponent: FC<NotificationLinkProps & PropsWithChildren> = ({ to, ...props }) => {
    const url = urlParser(to);
    url.params = { ...url.params, from: 'user-notification', hhtmFromLabel: 'user_notification' };
    return <BlokoLink Element={Link} disableVisited to={url.href} {...props} />;
};

const VacancyScheduledPublication: FC<ComplexUserNotification & PropsWithChildren> = ({
    params,
    templateKey,
    ...props
}) => {
    return (
        <SynchronizedNotification
            kind={
                templateKey === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess ||
                templateKey === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses
                    ? NotificationKind.Ok
                    : NotificationKind.Error
            }
            notification={{ params, templateKey, ...props }}
        >
            <p>
                <VacancyScheduledPublicationNotification
                    Component={LinkComponent}
                    params={params}
                    templateKey={templateKey}
                    {...props}
                />
            </p>
        </SynchronizedNotification>
    );
};

export default VacancyScheduledPublication;
