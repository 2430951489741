import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import { clearNotificationsAction } from 'src/models/notifications';

import defaultError, { DEFAULT_ERROR } from 'src/components/Notifications/DefaultError';
import networkError, { NETWORK_ERROR } from 'src/components/Notifications/NetworkError';
import { useNotification } from 'src/components/Notifications/Provider';

const config = {
    [DEFAULT_ERROR]: defaultError,
    [NETWORK_ERROR]: networkError,
};

const ReduxNotifications: FC = () => {
    const notifications = useSelector((store) => store.notifications);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    useEffect(() => {
        notifications.forEach((notification) => {
            if (notification.type in config) {
                addNotification(config[notification.type as keyof typeof config]);
            }
        });
        if (notifications.length) {
            dispatch(clearNotificationsAction());
        }
    }, [addNotification, dispatch, notifications]);

    return null;
};

export default ReduxNotifications;
