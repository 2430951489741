import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

export default (searchName: SupernovaSearchName): string | string[] => {
    const isOnboardingExp = useSelector(({ anonymousOnboarding }) => anonymousOnboarding.isOnboardingExp);
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const resumeSearchResultCriteria = useSelector(({ resumeSearchResult }) => resumeSearchResult.criteria);
    const vacancySearchResultCriteria = useSelector(({ vacancySearchResult }) => vacancySearchResult.criteria);
    const employersListQuery = useSelector(({ employersListQuery }) => employersListQuery);

    let searchInputValue;
    if (searchName === SupernovaSearchName.Vacancies && !isOnboardingExp) {
        searchInputValue = vacancySearchResultCriteria?.text;
    } else if (searchName === SupernovaSearchName.Vacancies && isOnboardingExp) {
        searchInputValue = criteriaTextUpdated;
    } else if (searchName === SupernovaSearchName.Resumes) {
        searchInputValue = resumeSearchResultCriteria?.text;
    } else if (searchName === SupernovaSearchName.Employers) {
        searchInputValue = employersListQuery;
    }
    searchInputValue = searchInputValue || '';

    return searchInputValue;
};
