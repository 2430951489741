import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    expired: 'supernova.notification.resume.renewal.expired',
    content: {
        RESUME_RENEWAL: 'supernova.notification.resume.renewal.RESUME_RENEWAL',
        RESUME_MARK: 'supernova.notification.resume.renewal.RESUME_MARK',
    },
    link: 'supernova.notification.resume.renewal.link',
};

const ResumeRenewalExpired: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return (
        <Notification dataQa={DataQaInvariants.ResumeRenewalExpired} {...notification}>
            <NotificationSpacer>
                {trls[TrlKeys.expired]}{' '}
                {trls[TrlKeys.content[notification.params.serviceType as 'RESUME_RENEWAL' | 'RESUME_MARK']]}
            </NotificationSpacer>
            <NotificationLink to="/applicant/services/findjob" target="_blank">
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(ResumeRenewalExpired);
