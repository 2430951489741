import { FC, PropsWithChildren } from 'react';

import BillingCartActivationNotification from 'src/components/BillingCartActivationNotification';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import { ComplexUserNotification } from 'src/models/userNotifications';

const BillingCartActivation: FC<ComplexUserNotification & PropsWithChildren> = (props) => {
    return (
        <Notification {...props}>
            <BillingCartActivationNotification {...props} />
        </Notification>
    );
};

export default BillingCartActivation;
