import { useRef, useState } from 'react';

import DropInfoInline from 'bloko/blocks/drop/InfoInline';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ImportResumeModal from 'src/components/ImportResumeModal';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';

const TrlKeys = {
    maxResumes: 'resumeList.maxResumes',
};

type Props = {
    url: string;
    name: string;
    translations: Record<string, string>;
    trackClicks?: boolean;
};

const CreateResumeMobileButton: TranslatedComponent<Props> = ({ trls, url, name, translations, trackClicks }) => {
    const [showResumeLimitsMessage, setShowResumeLimitsMessage] = useState(false);
    const remaining = useSelector(({ resumeLimits }) => resumeLimits?.remaining);
    const maxResumes = useSelector(({ resumeLimits }) => resumeLimits?.max || 20);
    const isZP = useIsZarplataPlatform();
    const buttonRef = useRef<HTMLDivElement>(null);
    const [importZpVisible, setImportZpVisible] = useState(false);

    const handleClick = () => {
        if (isZP) {
            setImportZpVisible(true);
        }
    };

    return (
        <div className="supernova-dropdown-static-option" ref={buttonRef}>
            {remaining === 0 ? (
                <DropInfoInline
                    show={showResumeLimitsMessage}
                    showCloseButton
                    onClose={() => setShowResumeLimitsMessage(false)}
                    render={() => (
                        <span data-qa="max-resume-warning">
                            {format(trls[TrlKeys.maxResumes], {
                                '{0}': maxResumes,
                            })}
                        </span>
                    )}
                >
                    <SupernovaButton name={name} primary onClick={() => setShowResumeLimitsMessage(true)}>
                        {translations.name}
                    </SupernovaButton>
                </DropInfoInline>
            ) : (
                <SupernovaButton
                    name={name}
                    primary
                    trackClicks={trackClicks}
                    url={isZP ? undefined : url}
                    rel="nofollow"
                    onClick={handleClick}
                >
                    {translations.name}
                </SupernovaButton>
            )}
            <ImportResumeModal
                visible={importZpVisible}
                onClose={() => setImportZpVisible(false)}
                url={'/applicant/resumes/new'}
                activatorRef={buttonRef}
            />
        </div>
    );
};

export default translation(CreateResumeMobileButton);
