import Analytics from '@hh.ru/analytics-js';

import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName, SupernovaSearchNameAnalyticsMapping } from 'src/models/supernovaSearchName';

export default (searchName: SupernovaSearchName | undefined, isAnonymousDashboardSearch: boolean): (() => void) => {
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const addEventPixelsRu = useAddEventPixelsRu();
    return (): void => {
        if (!searchName || !(searchName in SupernovaSearchNameAnalyticsMapping)) {
            return;
        }
        if (isAnonymousDashboardSearch) {
            addEventPixelsRu('B2C_MAIN_PAGE_SEARCH');
        }
        window.txqReachGoal?.('search');
        void Analytics.sendHHEvent('form_submit', {
            formName: SupernovaSearchNameAnalyticsMapping[searchName],
            text: criteriaTextUpdated,
        });
    };
};
