export const SKIP_ANSWER_VALUE = '99';
export const FINAL_STEP = 3;

export enum FormSteps {
    First = 'first',
    Second = 'second',
    Third = 'third',
    Fourth = 'fourth',
}

export interface SurveyResultType {
    [FormSteps.First]?: string;
    [FormSteps.Second]?: string;
    [FormSteps.Third]?: string;
    [FormSteps.Fourth]?: string;
}

export const FORM_STEPS_ARRAY = [FormSteps.Third, FormSteps.Fourth, FormSteps.First, FormSteps.Second];

export const DEFAULT_ANSWER_CONFIG = {
    [FormSteps.First]: SKIP_ANSWER_VALUE,
    [FormSteps.Second]: SKIP_ANSWER_VALUE,
    [FormSteps.Third]: SKIP_ANSWER_VALUE,
    [FormSteps.Fourth]: '',
};
