import { FC } from 'react';

import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';

interface ChatikNotificationProps {
    content?: string;
    error?: boolean;
}

const ChatikErrorNotificationMagritte: FC<ChatikNotificationProps & NotificationProps> = ({
    content,
    error,
    removeNotification,
}) => {
    const { isMobile } = useBreakpoint();

    return content ? (
        <Notification
            addon={error ? <MinusCircleFilledSize24 initial="negative" /> : undefined}
            removeNotification={removeNotification}
        >
            <Text style="contrast" typography="label-2-regular" maxLines={isMobile ? 3 : 2}>
                {content}
            </Text>
        </Notification>
    ) : null;
};

export default ChatikErrorNotificationMagritte;
