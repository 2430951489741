import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export enum BillStatus {
    Discarded = 'DISCARDED',
    NotPayed = 'NOT_PAYED',
    PayedByCard = 'PAYED_BY_CARD',
    Payed = 'PAYED',
    Postpaid = 'POSTPAID',
    Barter = 'BARTER',
    Unknown = 'UNKNOWN',
}

export interface Bill {
    status: BillStatus;
    isElectronic: boolean;
    billId: string;
    number: string;
    printFormUrl: string;
    extraPaymentAmountInCents?: {
        amount: number;
        currency: CurrencyType;
    };
    priceInCents: {
        amount: number;
        currency: CurrencyType;
    };
}

export enum BackUrlType {
    Url = 'URL',
    UpgradeUrl = 'UPGRADE_URL',
    AppScheme = 'APP_SCHEME',
}

export interface BackUrl {
    url: string;
    type: BackUrlType;
}

interface EmployerInvoiceFinish {
    draftId: string | null;
    shouldShowAppPromo: boolean;
    delayedActivationWarning?: {
        isoExpirationDate: string;
        articleId: string;
    };
    bill?: Bill;
    priceUrl: string;
    tryToPayAgainUrl?: string;
    duplicateCartParams: Record<string, string>;
    activateCartParams: Record<string, string>;
    brandingAdvUrl?: string;
    employerBudgetUrl?: string;
    backUrl?: BackUrl;
    showVacanciesInstruction: boolean;
    hasVacancyDrafts: boolean;
}

export default autoGeneratedReducer<EmployerInvoiceFinish>(null);
