export enum ChangeTopicActionError {
    InvitationLimit = 'INVITATION_LIMIT_EXCEEDED',
    CantInvite = 'VACANCY_CANT_INVITE_TO',
    ArchiveTopic = 'CANT_CHANGE_ARCHIVE_TOPIC_STATE',
    ReadOnlyTopic = 'CANT_CHANGE_READ_ONLY_TOPIC',
    ResumesUnavailable = 'RESUMES_UNAVAILABLE',
    VacancyUnavailable = 'VACANCY_UNAVAILABLE',
    ManagerLimit = 'MANAGER_LIMIT_EXCEED',
    EmployerLimit = 'EMPLOYER_LIMIT_EXCEED',
    NoFaService = 'NO_FA_SERVICE',
    FunnelStageNotFound = 'FUNNEL_STAGE_NOT_FOUND',
    FunnelByVacancyLocked = 'FUNNEL_BY_VACANCY_LOCKED',
    AddressUnavailable = 'ADDRESS_UNAVAILABLE',
    CantInviteToPfpVacancy = 'CANT_INVITE_TO_PFP_VACANCY',
}

export enum ChangeTopicFieldError {
    MailTooLong = 'MAIL_TOO_LONG',
    MailInappropriateLanguage = 'MAIL_INAPPROPRIATE_LANGUAGE',
    MailResourcePolicyViolation = 'MAIL_RESOURCE_POLICY_VIOLATION',
}

export enum ChangeTopicContentError {
    Default = 'DEFAULT',
    NetworkError = 'NETWORK_ERROR',
}

export const ChangeTopicError = { ...ChangeTopicActionError, ...ChangeTopicFieldError };
export type ChangeTopicError = typeof ChangeTopicError;

export const isChangeTopicActionError = (error: string): error is ChangeTopicActionError => {
    return Object.values(ChangeTopicActionError).includes(error);
};

export const isChangeTopicContentError = (error: string): error is ChangeTopicContentError => {
    return Object.values(ChangeTopicContentError).includes(error);
};

export enum ChangeTopicFormErrorCode {
    UnavailableState = 'UNAVAILABLE_STATE',
    ToAnotherTopicExists = 'TO_ANOTHER_TOPIC_EXISTS',
    CannotViewResponses = 'CANNOT_VIEW_RESPONSES',
    ResumeContactsUnavailable = 'RESUME_CONTACTS_UNAVAILABLE',
    DefaultErrorText = 'defaultErrorText',
    NoResumes = 'applicantChoose',
    NoVacancies = 'noVacancy',
    NoSendMessageAllowed = 'noSendMessageAllowed',
}
