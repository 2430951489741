import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { UserType } from 'src/models/userType';

export enum ChatBotMessengerTypes {
    Viber = 'viber',
    Vk = 'vk',
    Telegram = 'telegram',
}

interface ChatBotMessenger {
    type: ChatBotMessengerTypes;
    icon: 'Viber' | 'Vk' | 'Telegram';
    href: string;
}

export interface ChatBot {
    messengers: ChatBotMessenger[];
    page: string;
    genericUserType: UserType.Anonymous | UserType.Applicant;
    source: string;
    href: string;
}

export default autoGeneratedReducer<ChatBot>(null);
