import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import format from 'bloko/common/format';
import useServerEnv from 'bloko/common/hooks/useServerEnv';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import { extractRequestUrlFromForm } from 'src/models/anonymousSignUp/utils';
import { Platform } from 'src/models/locale.types';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

import AdvancedSearchButton from 'src/components/SupernovaSearch/AdvancedSearchButton';
import HiddenFields from 'src/components/SupernovaSearch/HiddenFields';
import SearchButton from 'src/components/SupernovaSearch/SearchButton';
import SearchNameSelect from 'src/components/SupernovaSearch/SearchNameSelect';
import SearchSuggest from 'src/components/SupernovaSearch/SearchSuggest';
import VacancySavedSearchButton from 'src/components/SupernovaSearch/VacancySavedSearchButton';
import { SupernovaSearchBaseState } from 'src/components/SupernovaSearch/useSearchBaseState';
import useSearchNavItemProps from 'src/components/SupernovaSearch/useSearchNavItemProps';
import useSendFormSubmitEvent from 'src/components/SupernovaSearch/useSendFormSubmitEvent';

import styles from './styles.less';

const TrlKeys = {
    fieldsOne: 'navi.search.tab.field.one',
    fieldsSome: 'navi.search.tab.field.some',
    fieldsMany: 'navi.search.tab.field.many',
    fieldsCount: 'navi.search.tab.searchConditions.count',
};

type SupernovaSearchProps = SupernovaSearchBaseState & {
    isAnonymousDashboardSearch?: boolean;
};

export const SUPERNOVA_SEARCH_FORM_ID = 'supernova_search_form';

const SupernovaSearch: TranslatedComponent<SupernovaSearchProps> = ({
    searchName,
    setSearchName,
    isEmployerSearch,
    trls,
    isAnonymousDashboardSearch = false,
}) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const formRef = useRef<HTMLFormElement>(null);
    const lastSearchNameRef = useRef(searchName);
    const { isMobile } = useBreakpoint();
    const isServerEnv = useServerEnv();

    // TODO: После перевода на магритт модалки авторизации выпилить этот ресет компонента строки поиска.
    // https://jira.hh.ru/browse/HH-222290.
    const [shouldResetSuggest, resetSuggest] = useState(false);

    const platform = useSelector(({ locale }) => locale.platform);
    const textConditionsCount = useSelector(({ resumeSearchResult }) =>
        resumeSearchResult.criteria?.text?.length ? resumeSearchResult.criteria.text.length - 1 : 0
    );
    const showResumeSearchConditions = useSelector(({ showResumeSearchConditions }) => showResumeSearchConditions);

    const searchProps = useSearchNavItemProps(searchName);
    const sendFormSubmitEvent = useSendFormSubmitEvent(searchName, isAnonymousDashboardSearch);
    const isMapVacancySearch = useSelector(({ isMapVacancySearch }) => isMapVacancySearch);

    useEffect(() => {
        if (lastSearchNameRef.current !== searchName) {
            lastSearchNameRef.current = searchName;
            formRef.current && formRef.current.submit();
        }
    }, [searchName]);

    const onFormSubmit = useCallback(() => {
        sendFormSubmitEvent();
        formRef.current?.requestSubmit();
    }, [sendFormSubmitEvent]);

    const showAdditionalParamsText = !showResumeSearchConditions && textConditionsCount > 0;
    const showSearchNameSelect = platform !== Platform.Zarplata;

    const isIndexPage = useSelector(({ router }) => router.location.pathname === paths.indexPage);
    const { isShowButton } = useSelector(({ vacancySearchResult }) => vacancySearchResult.savedSearches);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const isMobileInlineView = !isEmployerSearch && isMobile;
    const isMobileIndexPage = isIndexPage && isMobile;
    const savedSearchButtonHidden = !isShowButton || searchName !== SupernovaSearchName.Vacancies || isMobileIndexPage;
    const advancedSearchButtonHidden =
        searchName === SupernovaSearchName.Employers || (isMobile && isAnonymousDashboardSearch);
    const submitButtonHidden = isMobileInlineView || (isMobileIndexPage && !isEmployerSearch);
    const searchNameSelectHidden = isMobileInlineView || !(isEmployerSearch && showSearchNameSelect);

    if (!searchProps) {
        return null;
    }

    const action =
        isMapVacancySearch && searchName === SupernovaSearchName.Vacancies
            ? paths.vacancySearchMap
            : searchProps.action;

    return (
        <Form
            id={SUPERNOVA_SEARCH_FORM_ID}
            onSubmit={(event) => {
                if (mainPageSignUpEnable) {
                    const form = event.target as HTMLFormElement;
                    const backUrl = extractRequestUrlFromForm(form);
                    event.preventDefault();
                    dispatch(openAnonymousSignUpModal(backUrl));
                    resetSuggest(true);
                }
                sendFormSubmitEvent();
            }}
            action={action}
            ref={formRef}
            method="GET"
            data-hh-tab-id={searchName}
        >
            <div
                className={classnames(styles.supernovaWrapper, {
                    [styles.supernovaWrapperInline]: isMobileInlineView,
                })}
            >
                <div className={styles.supernovaSearch}>
                    <SearchSuggest
                        key={Number(shouldResetSuggest)}
                        searchName={searchName}
                        isDashboardMobileSearch={!isServerEnv && isMobile && !isEmployerSearch}
                        submitForm={onFormSubmit}
                    />
                    {isEmployerSearch && showAdditionalParamsText && (
                        <>
                            <VSpacing default={8} />
                            <Text typography="label-2-regular" style="secondary">
                                <Conversion
                                    one={trls[TrlKeys.fieldsOne]}
                                    some={trls[TrlKeys.fieldsSome]}
                                    many={trls[TrlKeys.fieldsMany]}
                                    value={textConditionsCount}
                                    hasValue={false}
                                    format={(trl) =>
                                        format(trls[TrlKeys.fieldsCount], {
                                            '{0}': `${textConditionsCount} ${trl}`,
                                        })
                                    }
                                />
                            </Text>
                        </>
                    )}
                    <HiddenFields searchName={searchName} />
                </div>
                {!searchNameSelectHidden && (
                    <div className={styles.supernovaSearchSelect}>
                        <SearchNameSelect searchName={searchName} setSearchName={setSearchName} />
                    </div>
                )}
                {!submitButtonHidden && (
                    <div
                        className={classnames(styles.submitButton, {
                            [styles.submitButtonEmployer]: isEmployerSearch,
                            [styles.submitButtonCompressed]: isAnonymousMagritteExp,
                        })}
                    >
                        <SearchButton stretched />
                    </div>
                )}
                {!advancedSearchButtonHidden && <AdvancedSearchButton searchName={searchName} shortView />}
                {!savedSearchButtonHidden && <VacancySavedSearchButton searchName={searchName} isMobile={isMobile} />}
            </div>
        </Form>
    );
};

export default translation(SupernovaSearch);
