import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonScale } from 'bloko/blocks/button';
import Notification from 'bloko/blocks/notificationManager/Notification';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SendHHAnalytics from 'HH/SendHHAnalytics';
import RemindModal from 'src/components/InterviewReminder';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { CurrentInterview, removeInterviewRemind, setInterviewRemind } from 'src/models/candidatesList';

const AUTO_CLOSE_DELAY_MS = 10 * 1000;

const TrlKeys = {
    body: 'resume.interview.schedule.notification.body',
    defaultBody: 'resume.interview.schedule.notification.body.default',
    button: 'resume.interview.schedule.notification.button',
};

const ScheduleInterview: TranslatedComponent<SimpleNotificationComponentProps> = ({ trls, ...notification }) => {
    const { firstName, lastName, topicId } = notification.params;
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : null;

    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);

    const remindInfo = useSelectorNonNullable(({ scheduleInterviewRemindInfo }) => scheduleInterviewRemindInfo);

    const handleClick = useCallback(() => {
        setModalVisible(true);
        void Analytics.sendHHEventButtonClick('schedule_interview_open', { from: 'notification' });
    }, []);

    const handleClose = useCallback(() => {
        setModalVisible(false);
    }, []);

    const handleChangeRemind = useCallback(
        (currentInterview: CurrentInterview) => {
            dispatch(setInterviewRemind({ currentInterview }));
        },
        [dispatch]
    );

    const handleDeleteRemind = useCallback(() => {
        dispatch(removeInterviewRemind({ id: topicId }));
    }, [dispatch, topicId]);

    useEffect(() => {
        void SendHHAnalytics({ goal: 'schedule_interview_notification_shown' });
        const url = urlParser(window.location.search);
        delete url.params.interviewedTopicId;
        window.history.replaceState(window.history.state, document.title, url.search);
    }, []);

    return (
        <>
            <Notification autoClose autoCloseDelay={AUTO_CLOSE_DELAY_MS}>
                <div>
                    {fullName ? format(trls[TrlKeys.body], { '{0}': fullName }) : trls[TrlKeys.defaultBody]}
                    <NotificationFooter>
                        <Button scale={ButtonScale.Small} onClick={handleClick}>
                            {trls[TrlKeys.button]}
                        </Button>
                    </NotificationFooter>
                </div>
            </Notification>
            <RemindModal
                visible={modalVisible}
                topicId={topicId}
                remindInfo={remindInfo}
                onClose={handleClose}
                onChange={handleChangeRemind}
                onDelete={handleDeleteRemind}
                fromNotification
            />
        </>
    );
};

export default translation(ScheduleInterview);
