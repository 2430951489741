export default (tokensVersion: string): { tokensString: string; themeClassName: string } => {
    const [major, minor, patch] = tokensVersion.split('.');
    const tokensString = `
.magritte-zp-day-theme {
  --magritte-color-background-primary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-background-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-background-tertiary-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-background-accent-secondary-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-background-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-background-positive-secondary-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-background-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-background-negative-secondary-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-background-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-background-warning-secondary-v${major}-${minor}-${patch}: #FFF3E6;
  --magritte-color-background-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-background-special-secondary-v${major}-${minor}-${patch}: #F7F4FF;
  --magritte-color-background-special-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-background-body-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-background-overlay-v${major}-${minor}-${patch}: #20262b99;
  --magritte-color-background-dreamy-secondary-v${major}-${minor}-${patch}: #E8F7FE;
  --magritte-color-background-dreamy-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-background-lovely-secondary-v${major}-${minor}-${patch}: #FFF1F3;
  --magritte-color-background-lovely-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-background-vivid-secondary-v${major}-${minor}-${patch}: #FDF4D7;
  --magritte-color-background-vivid-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-background-constant-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-constant-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-background-inverse-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-inverse-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-background-transparent-v${major}-${minor}-${patch}: #00000000;
  --magritte-color-background-state-primary-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-background-state-primary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-background-state-primary-disabled-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-background-state-secondary-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-background-state-secondary-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-background-state-secondary-disabled-v${major}-${minor}-${patch}: #f1f4f97a;
  --magritte-color-background-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #D8DBDE;
  --magritte-color-background-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-background-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #f1f4f77a;
  --magritte-color-background-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-background-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-background-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #e8f9ec7a;
  --magritte-color-background-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-background-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-background-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #fef2ef7a;
  --magritte-color-background-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #FFF3E6;
  --magritte-color-background-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #FFF3E6;
  --magritte-color-background-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #fff3e67a;
  --magritte-color-background-state-special-secondary-hovered-v${major}-${minor}-${patch}: #F7F4FF;
  --magritte-color-background-state-special-secondary-pressed-v${major}-${minor}-${patch}: #F7F4FF;
  --magritte-color-background-state-special-secondary-disabled-v${major}-${minor}-${patch}: #f7f4ff7a;
  --magritte-color-background-state-tertiary-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-background-state-tertiary-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-background-state-tertiary-disabled-v${major}-${minor}-${patch}: #f1f4f97a;
  --magritte-color-background-state-accent-hovered-v${major}-${minor}-${patch}: #82ACC9;
  --magritte-color-background-state-accent-pressed-v${major}-${minor}-${patch}: #82ACC9;
  --magritte-color-background-state-accent-disabled-v${major}-${minor}-${patch}: #7195af7a;
  --magritte-color-background-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-background-state-positive-pressed-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-background-state-positive-disabled-v${major}-${minor}-${patch}: #0dc2677a;
  --magritte-color-background-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-background-state-negative-pressed-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-background-state-negative-disabled-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-background-state-warning-hovered-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-background-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-background-state-warning-disabled-v${major}-${minor}-${patch}: #d17c097a;
  --magritte-color-background-state-special-hovered-v${major}-${minor}-${patch}: #c4acf9;
  --magritte-color-background-state-special-pressed-v${major}-${minor}-${patch}: #c4acf9;
  --magritte-color-background-state-special-disabled-v${major}-${minor}-${patch}: #b99bf87a;
  --magritte-color-background-state-dreamy-secondary-hovered-v${major}-${minor}-${patch}: #E8F7FE;
  --magritte-color-background-state-dreamy-secondary-pressed-v${major}-${minor}-${patch}: #E8F7FE;
  --magritte-color-background-state-dreamy-secondary-disabled-v${major}-${minor}-${patch}: #e8f7fe7a;
  --magritte-color-background-state-lovely-secondary-hovered-v${major}-${minor}-${patch}: #FFF1F3;
  --magritte-color-background-state-lovely-secondary-pressed-v${major}-${minor}-${patch}: #FFF1F3;
  --magritte-color-background-state-lovely-secondary-disabled-v${major}-${minor}-${patch}: #fff1f37a;
  --magritte-color-background-state-vivid-secondary-hovered-v${major}-${minor}-${patch}: #FDF4D7;
  --magritte-color-background-state-vivid-secondary-pressed-v${major}-${minor}-${patch}: #FDF4D7;
  --magritte-color-background-state-vivid-secondary-disabled-v${major}-${minor}-${patch}: #fdf4d77a;
  --magritte-color-background-state-dreamy-hovered-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-background-state-dreamy-pressed-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-background-state-dreamy-disabled-v${major}-${minor}-${patch}: #5ac8ff7a;
  --magritte-color-background-state-lovely-hovered-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-background-state-lovely-pressed-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-background-state-lovely-disabled-v${major}-${minor}-${patch}: #ff3c777a;
  --magritte-color-background-state-vivid-hovered-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-background-state-vivid-pressed-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-background-state-vivid-disabled-v${major}-${minor}-${patch}: #f7d0447a;
  --magritte-color-background-state-constant-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-constant-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-constant-disabled-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-background-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-background-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-background-state-inverse-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-inverse-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-inverse-disabled-v${major}-${minor}-${patch}: #000000;
  --magritte-color-background-state-inverse-secondary-hovered-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-background-state-inverse-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-background-state-inverse-secondary-disabled-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-stroke-neutral-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-neutral-inner-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-stroke-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-stroke-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-stroke-warning-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-stroke-special-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-stroke-neutral-phantom-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-overlay-v${major}-${minor}-${patch}: #5e6c773d;
  --magritte-color-stroke-state-neutral-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-neutral-disabled-v${major}-${minor}-${patch}: #dce3eb7a;
  --magritte-color-stroke-state-accent-hovered-v${major}-${minor}-${patch}: #618096;
  --magritte-color-stroke-state-accent-disabled-v${major}-${minor}-${patch}: #7195af7a;
  --magritte-color-stroke-state-positive-hovered-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-stroke-state-positive-disabled-v${major}-${minor}-${patch}: #0dc2677a;
  --magritte-color-stroke-state-negative-hovered-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-stroke-state-negative-disabled-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-stroke-state-warning-hovered-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-stroke-state-warning-disabled-v${major}-${minor}-${patch}: #d17c097a;
  --magritte-color-stroke-state-special-hovered-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-stroke-state-special-disabled-v${major}-${minor}-${patch}: #9054ea7a;
  --magritte-color-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-stroke-state-neutral-phantom-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-neutral-phantom-disabled-v${major}-${minor}-${patch}: #dce3eb7a;
  --magritte-color-stroke-state-neutral-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-neutral-phantom-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-accent-pressed-v${major}-${minor}-${patch}: #618096;
  --magritte-color-stroke-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-stroke-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-stroke-state-warning-pressed-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-stroke-state-special-pressed-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-stroke-state-neutral-inner-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-neutral-inner-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-stroke-state-neutral-inner-disabled-v${major}-${minor}-${patch}: #dce3eb7a;
  --magritte-color-text-primary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-text-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-text-tertiary-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-text-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-text-accent-secondary-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-text-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-text-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-text-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-text-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-text-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-text-warning-secondary-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-text-special-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-text-special-secondary-v${major}-${minor}-${patch}: #a579f4;
  --magritte-color-text-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-contrast-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-text-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-constant-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-text-inverse-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-inverse-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-text-state-primary-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-text-state-primary-disabled-v${major}-${minor}-${patch}: #0000007a;
  --magritte-color-text-state-secondary-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-text-state-secondary-disabled-v${major}-${minor}-${patch}: #7686947a;
  --magritte-color-text-state-tertiary-hovered-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-text-state-tertiary-disabled-v${major}-${minor}-${patch}: #aabbca7a;
  --magritte-color-text-state-accent-hovered-v${major}-${minor}-${patch}: #618096;
  --magritte-color-text-state-accent-disabled-v${major}-${minor}-${patch}: #7195af7a;
  --magritte-color-text-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #8FA7B8;
  --magritte-color-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #a3bfd27a;
  --magritte-color-text-state-positive-hovered-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-text-state-positive-disabled-v${major}-${minor}-${patch}: #0dc2677a;
  --magritte-color-text-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #83d99c7a;
  --magritte-color-text-state-negative-hovered-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-text-state-negative-disabled-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-text-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #ff8a777a;
  --magritte-color-text-state-warning-hovered-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-text-state-warning-disabled-v${major}-${minor}-${patch}: #d17c097a;
  --magritte-color-text-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-text-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #ff99007a;
  --magritte-color-text-state-special-hovered-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-text-state-special-disabled-v${major}-${minor}-${patch}: #9054ea7a;
  --magritte-color-text-state-special-secondary-hovered-v${major}-${minor}-${patch}: #a579f4;
  --magritte-color-text-state-special-secondary-disabled-v${major}-${minor}-${patch}: #a579f47a;
  --magritte-color-text-state-contrast-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-state-contrast-disabled-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-text-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #ffffff3d;
  --magritte-color-text-state-primary-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-text-state-secondary-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-text-state-tertiary-pressed-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-text-state-accent-pressed-v${major}-${minor}-${patch}: #618096;
  --magritte-color-text-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #8FA7B8;
  --magritte-color-text-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-text-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-text-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-text-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-text-state-warning-pressed-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-text-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-text-state-special-pressed-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-text-state-special-secondary-pressed-v${major}-${minor}-${patch}: #a579f4;
  --magritte-color-text-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-text-state-constant-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-state-constant-disabled-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-text-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #ffffff3d;
  --magritte-color-text-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-text-state-inverse-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-text-state-inverse-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-text-state-inverse-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-text-state-inverse-secondary-hovered-v${major}-${minor}-${patch}: #B9B9B9;
  --magritte-color-text-state-inverse-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-text-state-inverse-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-primary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-icon-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-icon-tertiary-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-icon-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-icon-accent-secondary-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-icon-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-icon-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-icon-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-icon-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-icon-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-icon-warning-secondary-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-icon-special-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-icon-special-secondary-v${major}-${minor}-${patch}: #a579f4;
  --magritte-color-icon-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-contrast-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-icon-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-constant-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-icon-inverse-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-inverse-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-icon-dreamy-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-icon-dreamy-secondary-v${major}-${minor}-${patch}: #9cddff;
  --magritte-color-icon-lovely-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-icon-lovely-secondary-v${major}-${minor}-${patch}: #ff859f;
  --magritte-color-icon-vivid-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-icon-vivid-secondary-v${major}-${minor}-${patch}: #f9e193;
  --magritte-color-icon-state-primary-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-icon-state-primary-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-icon-state-primary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-secondary-hovered-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-icon-state-secondary-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-icon-state-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-tertiary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-icon-state-tertiary-pressed-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-icon-state-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-accent-hovered-v${major}-${minor}-${patch}: #618096;
  --magritte-color-icon-state-accent-pressed-v${major}-${minor}-${patch}: #618096;
  --magritte-color-icon-state-accent-disabled-v${major}-${minor}-${patch}: #7195af7a;
  --magritte-color-icon-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #8FA7B8;
  --magritte-color-icon-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #8FA7B8;
  --magritte-color-icon-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #a3bfd27a;
  --magritte-color-icon-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-icon-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-icon-state-positive-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #a5e2b6;
  --magritte-color-icon-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-icon-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-icon-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-icon-state-negative-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-icon-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-icon-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-warning-hovered-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-icon-state-warning-pressed-v${major}-${minor}-${patch}: #d17c09;
  --magritte-color-icon-state-warning-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #ffa439;
  --magritte-color-icon-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-icon-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-special-hovered-v${major}-${minor}-${patch}: #9a67ef;
  --magritte-color-icon-state-special-pressed-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-icon-state-special-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-special-secondary-hovered-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-icon-state-special-secondary-pressed-v${major}-${minor}-${patch}: #a579f4;
  --magritte-color-icon-state-special-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-icon-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-state-contrast-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #B9B9B9;
  --magritte-color-icon-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-icon-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-constant-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-icon-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-state-constant-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #B9B9B9;
  --magritte-color-icon-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-icon-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-inverse-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-icon-state-inverse-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-icon-state-inverse-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-inverse-secondary-hovered-v${major}-${minor}-${patch}: #B9B9B9;
  --magritte-color-icon-state-inverse-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-icon-state-inverse-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-dreamy-hovered-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-icon-state-dreamy-pressed-v${major}-${minor}-${patch}: #5ac8ff;
  --magritte-color-icon-state-dreamy-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-dreamy-secondary-hovered-v${major}-${minor}-${patch}: #9cddff;
  --magritte-color-icon-state-dreamy-secondary-pressed-v${major}-${minor}-${patch}: #9cddff;
  --magritte-color-icon-state-dreamy-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-lovely-hovered-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-icon-state-lovely-pressed-v${major}-${minor}-${patch}: #ff3c77;
  --magritte-color-icon-state-lovely-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-lovely-secondary-hovered-v${major}-${minor}-${patch}: #ff859f;
  --magritte-color-icon-state-lovely-secondary-pressed-v${major}-${minor}-${patch}: #ff859f;
  --magritte-color-icon-state-lovely-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-vivid-hovered-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-icon-state-vivid-pressed-v${major}-${minor}-${patch}: #f7d044;
  --magritte-color-icon-state-vivid-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-icon-state-vivid-secondary-hovered-v${major}-${minor}-${patch}: #f9e193;
  --magritte-color-icon-state-vivid-secondary-pressed-v${major}-${minor}-${patch}: #f9e193;
  --magritte-color-icon-state-vivid-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-card-background-blank-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-card-background-neutral-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-card-background-extra-1-v${major}-${minor}-${patch}: #EDF6FF;
  --magritte-color-component-card-background-extra-2-v${major}-${minor}-${patch}: #FFF1F3;
  --magritte-color-component-card-background-extra-3-v${major}-${minor}-${patch}: #E8F7FE;
  --magritte-color-component-card-background-extra-4-v${major}-${minor}-${patch}: #FDF4D7;
  --magritte-color-component-card-background-extra-5-v${major}-${minor}-${patch}: #F7F4FF;
  --magritte-color-component-card-background-contrast-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-positive-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-component-card-background-negative-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-component-card-background-warning-v${major}-${minor}-${patch}: #FFF3E6;
  --magritte-color-component-card-background-constant-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-stroke-neutral-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-card-stroke-neutral-inner-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-card-stroke-phantom-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-card-stroke-phantom-inner-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-card-stroke-state-neutral-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-card-stroke-state-neutral-pressed-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-card-stroke-state-neutral-inner-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-card-stroke-state-neutral-inner-pressed-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-card-stroke-state-phantom-hovered-v${major}-${minor}-${patch}: #ccd5df00;
  --magritte-color-component-card-stroke-state-phantom-pressed-v${major}-${minor}-${patch}: #ccd5df00;
  --magritte-color-component-card-stroke-state-phantom-inner-hovered-v${major}-${minor}-${patch}: #ccd5df00;
  --magritte-color-component-card-stroke-state-phantom-inner-pressed-v${major}-${minor}-${patch}: #ccd5df00;
  --magritte-color-component-card-stroke-state-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-card-stroke-state-neutral-inner-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-card-stroke-state-phantom-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-card-stroke-state-phantom-inner-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-card-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-card-background-state-blank-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-card-background-state-blank-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-card-background-state-blank-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-card-background-state-neutral-hovered-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-card-background-state-neutral-pressed-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-card-background-state-neutral-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-card-background-state-extra-1-hovered-v${major}-${minor}-${patch}: #e7f2ff;
  --magritte-color-component-card-background-state-extra-1-pressed-v${major}-${minor}-${patch}: #e7f2ff;
  --magritte-color-component-card-background-state-extra-1-disabled-v${major}-${minor}-${patch}: #f3f9ff;
  --magritte-color-component-card-background-state-extra-2-hovered-v${major}-${minor}-${patch}: #feecef;
  --magritte-color-component-card-background-state-extra-2-pressed-v${major}-${minor}-${patch}: #feecef;
  --magritte-color-component-card-background-state-extra-2-disabled-v${major}-${minor}-${patch}: #fff5f7;
  --magritte-color-component-card-background-state-extra-3-hovered-v${major}-${minor}-${patch}: #e0f4fe;
  --magritte-color-component-card-background-state-extra-3-pressed-v${major}-${minor}-${patch}: #e0f4fe;
  --magritte-color-component-card-background-state-extra-3-disabled-v${major}-${minor}-${patch}: #eff9fe;
  --magritte-color-component-card-background-state-extra-4-hovered-v${major}-${minor}-${patch}: #fdf0c7;
  --magritte-color-component-card-background-state-extra-4-pressed-v${major}-${minor}-${patch}: #fdf0c7;
  --magritte-color-component-card-background-state-extra-4-disabled-v${major}-${minor}-${patch}: #fdf8e7;
  --magritte-color-component-card-background-state-extra-5-hovered-v${major}-${minor}-${patch}: #f3eeff;
  --magritte-color-component-card-background-state-extra-5-pressed-v${major}-${minor}-${patch}: #f3eeff;
  --magritte-color-component-card-background-state-extra-5-disabled-v${major}-${minor}-${patch}: #f9f7ff;
  --magritte-color-component-card-background-state-contrast-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-state-contrast-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-state-contrast-disabled-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-state-positive-hovered-v${major}-${minor}-${patch}: #e0f6e5;
  --magritte-color-component-card-background-state-positive-pressed-v${major}-${minor}-${patch}: #e0f6e5;
  --magritte-color-component-card-background-state-positive-disabled-v${major}-${minor}-${patch}: #f0fbf3;
  --magritte-color-component-card-background-state-negative-hovered-v${major}-${minor}-${patch}: #fdede9;
  --magritte-color-component-card-background-state-negative-pressed-v${major}-${minor}-${patch}: #fdede9;
  --magritte-color-component-card-background-state-negative-disabled-v${major}-${minor}-${patch}: #fff6f4;
  --magritte-color-component-card-background-state-warning-hovered-v${major}-${minor}-${patch}: #ffeedd;
  --magritte-color-component-card-background-state-warning-pressed-v${major}-${minor}-${patch}: #ffeedd;
  --magritte-color-component-card-background-state-warning-disabled-v${major}-${minor}-${patch}: #fff7ee;
  --magritte-color-component-card-background-state-constant-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-state-constant-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-card-background-state-constant-disabled-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-background-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-background-neutral-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-button-background-neutral-tertiary-v${major}-${minor}-${patch}: #eef1f700;
  --magritte-color-component-button-background-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-background-accent-secondary-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-component-button-background-accent-tertiary-v${major}-${minor}-${patch}: #e7f2ff00;
  --magritte-color-component-button-background-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-background-positive-secondary-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-component-button-background-positive-tertiary-v${major}-${minor}-${patch}: #e0f6e500;
  --magritte-color-component-button-background-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-background-negative-secondary-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-component-button-background-negative-tertiary-v${major}-${minor}-${patch}: #fdede900;
  --magritte-color-component-button-background-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-contrast-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-contrast-tertiary-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-background-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-constant-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-constant-tertiary-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-background-state-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-button-background-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #f4f6fb00;
  --magritte-color-component-button-background-state-accent-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-button-background-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-accent-tertiary-disabled-v${major}-${minor}-${patch}: #f4f6fb00;
  --magritte-color-component-button-background-state-positive-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-button-background-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-positive-tertiary-disabled-v${major}-${minor}-${patch}: #f4f6fb00;
  --magritte-color-component-button-background-state-negative-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-button-background-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-negative-tertiary-disabled-v${major}-${minor}-${patch}: #f4f6fb00;
  --magritte-color-component-button-background-state-contrast-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-background-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #20262b;
  --magritte-color-component-button-background-state-contrast-tertiary-disabled-v${major}-${minor}-${patch}: #20262b00;
  --magritte-color-component-button-background-state-neutral-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-state-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-background-state-neutral-focused-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-background-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-button-background-state-neutral-secondary-focused-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-button-background-state-neutral-tertiary-hovered-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-button-background-state-neutral-tertiary-pressed-v${major}-${minor}-${patch}: #eef1f700;
  --magritte-color-component-button-background-state-neutral-tertiary-focused-v${major}-${minor}-${patch}: #eef1f700;
  --magritte-color-component-button-background-state-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-button-background-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-background-state-accent-focused-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-background-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #F9FAFB;
  --magritte-color-component-button-background-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-component-button-background-state-accent-secondary-focused-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-component-button-background-state-accent-tertiary-hovered-v${major}-${minor}-${patch}: #F9FAFB;
  --magritte-color-component-button-background-state-accent-tertiary-pressed-v${major}-${minor}-${patch}: #e7f2ff00;
  --magritte-color-component-button-background-state-accent-tertiary-focused-v${major}-${minor}-${patch}: #e7f2ff00;
  --magritte-color-component-button-background-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-button-background-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-background-state-positive-focused-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-background-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #f0fbf3;
  --magritte-color-component-button-background-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-component-button-background-state-positive-secondary-focused-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-component-button-background-state-positive-tertiary-hovered-v${major}-${minor}-${patch}: #f0fbf3;
  --magritte-color-component-button-background-state-positive-tertiary-pressed-v${major}-${minor}-${patch}: #e0f6e500;
  --magritte-color-component-button-background-state-positive-tertiary-focused-v${major}-${minor}-${patch}: #e0f6e500;
  --magritte-color-component-button-background-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-button-background-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-background-state-negative-focused-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-background-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #fff6f4;
  --magritte-color-component-button-background-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-component-button-background-state-negative-secondary-focused-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-component-button-background-state-negative-tertiary-hovered-v${major}-${minor}-${patch}: #fff6f4;
  --magritte-color-component-button-background-state-negative-tertiary-pressed-v${major}-${minor}-${patch}: #fdede900;
  --magritte-color-component-button-background-state-negative-tertiary-focused-v${major}-${minor}-${patch}: #fdede900;
  --magritte-color-component-button-background-state-contrast-hovered-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-button-background-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-state-contrast-focused-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-button-background-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-state-contrast-secondary-focused-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-state-contrast-tertiary-hovered-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-button-background-state-contrast-tertiary-pressed-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-background-state-contrast-tertiary-focused-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-background-state-constant-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-background-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #20262b;
  --magritte-color-component-button-background-state-constant-tertiary-disabled-v${major}-${minor}-${patch}: #20262b00;
  --magritte-color-component-button-background-state-constant-hovered-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-button-background-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-state-constant-focused-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-background-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-button-background-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-state-constant-secondary-focused-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-background-state-constant-tertiary-hovered-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-button-background-state-constant-tertiary-pressed-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-background-state-constant-tertiary-focused-v${major}-${minor}-${patch}: #343c4300;
  --magritte-color-component-button-text-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-neutral-secondary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-text-neutral-tertiary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-text-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-text-accent-tertiary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-text-positive-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-positive-secondary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-text-positive-tertiary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-text-negative-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-negative-secondary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-text-negative-tertiary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-text-contrast-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-text-contrast-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-contrast-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-constant-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-text-constant-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-constant-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-neutral-secondary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-icon-neutral-tertiary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-icon-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-icon-accent-tertiary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-icon-positive-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-positive-secondary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-icon-positive-tertiary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-icon-negative-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-negative-secondary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-icon-negative-tertiary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-icon-contrast-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-icon-contrast-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-contrast-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-constant-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-icon-constant-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-icon-constant-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-counter-text-neutral-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-counter-text-neutral-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-counter-text-neutral-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-counter-text-accent-v${major}-${minor}-${patch}: #B1C5D3;
  --magritte-color-component-button-counter-text-accent-secondary-v${major}-${minor}-${patch}: #B1C5D3;
  --magritte-color-component-button-counter-text-accent-tertiary-v${major}-${minor}-${patch}: #B1C5D3;
  --magritte-color-component-button-counter-text-positive-v${major}-${minor}-${patch}: #c1edcc;
  --magritte-color-component-button-counter-text-positive-secondary-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-button-counter-text-positive-tertiary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-button-counter-text-negative-v${major}-${minor}-${patch}: #ffdad1;
  --magritte-color-component-button-counter-text-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-button-counter-text-negative-tertiary-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-component-button-counter-text-contrast-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-counter-text-contrast-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-counter-text-contrast-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-counter-text-constant-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-counter-text-constant-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-counter-text-constant-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-counter-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-counter-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-counter-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-accent-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-counter-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-positive-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-counter-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-negative-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-counter-text-state-contrast-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-counter-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-counter-text-state-contrast-tertiary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-counter-text-state-constant-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-counter-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-counter-text-state-constant-tertiary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-loader-icon-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-neutral-secondary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-loader-icon-neutral-tertiary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-loader-icon-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-loader-icon-accent-tertiary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-button-loader-icon-positive-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-positive-secondary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-loader-icon-positive-tertiary-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-button-loader-icon-negative-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-negative-secondary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-loader-icon-negative-tertiary-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-button-loader-icon-contrast-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-loader-icon-contrast-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-contrast-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-constant-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-button-loader-icon-constant-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-loader-icon-constant-tertiary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-button-text-state-neutral-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-accent-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-accent-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-positive-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-positive-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-negative-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-negative-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-text-state-contrast-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-button-text-state-contrast-tertiary-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-text-state-constant-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-button-text-state-constant-tertiary-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-icon-state-neutral-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-accent-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-accent-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-positive-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-positive-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-negative-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-negative-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-button-icon-state-contrast-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-icon-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-button-icon-state-contrast-tertiary-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-icon-state-constant-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-icon-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-button-icon-state-constant-tertiary-disabled-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-button-stroke-state-neutral-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-button-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-button-stroke-state-positive-focused-accessible-v${major}-${minor}-${patch}: #0dc2677a;
  --magritte-color-component-button-stroke-state-contrast-focused-accessible-v${major}-${minor}-${patch}: #a4c9fe7a;
  --magritte-color-component-button-stroke-state-negative-focused-accessible-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-component-button-stroke-state-constant-focused-accessible-v${major}-${minor}-${patch}: #a4c9fe7a;
  --magritte-color-component-button-postfix-text-neutral-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-postfix-text-neutral-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-postfix-text-neutral-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-postfix-text-accent-v${major}-${minor}-${patch}: #a4c9fe;
  --magritte-color-component-button-postfix-text-accent-secondary-v${major}-${minor}-${patch}: #5e9eff;
  --magritte-color-component-button-postfix-text-accent-tertiary-v${major}-${minor}-${patch}: #76adfe;
  --magritte-color-component-button-postfix-text-positive-v${major}-${minor}-${patch}: #c1edcc;
  --magritte-color-component-button-postfix-text-positive-secondary-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-button-postfix-text-positive-tertiary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-button-postfix-text-negative-v${major}-${minor}-${patch}: #ffdad1;
  --magritte-color-component-button-postfix-text-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-button-postfix-text-negative-tertiary-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-component-button-postfix-text-contrast-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-postfix-text-contrast-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-postfix-text-contrast-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-postfix-text-constant-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-button-postfix-text-constant-secondary-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-button-postfix-text-constant-tertiary-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-button-postfix-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-postfix-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-postfix-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-accent-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-postfix-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-positive-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-button-postfix-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-negative-tertiary-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-button-postfix-text-state-contrast-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-postfix-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-postfix-text-state-contrast-tertiary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-button-postfix-text-state-constant-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-postfix-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #3f4850;
  --magritte-color-component-button-postfix-text-state-constant-tertiary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-switch-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-switch-background-container-checked-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-switch-background-container-unchecked-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-switch-background-toggle-checked-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-toggle-unchecked-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-container-checked-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-switch-background-state-container-checked-pressed-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-switch-background-state-container-unchecked-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-switch-background-state-container-unchecked-pressed-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-switch-background-state-toggle-checked-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-toggle-checked-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-toggle-unchecked-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-toggle-unchecked-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-container-checked-disabled-v${major}-${minor}-${patch}: #0dc2677a;
  --magritte-color-component-switch-background-state-container-unchecked-disabled-v${major}-${minor}-${patch}: #dce3eb7a;
  --magritte-color-component-switch-background-state-toggle-checked-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-background-state-toggle-unchecked-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-switch-icon-state-toggle-checked-disabled-v${major}-${minor}-${patch}: #0ea6587a;
  --magritte-color-component-switch-icon-state-toggle-unchecked-disabled-v${major}-${minor}-${patch}: #7686947a;
  --magritte-color-component-tabs-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-tabs-background-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-tabs-background-unselected-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-tabs-background-state-unselected-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-tabs-background-state-unselected-focused-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-tabs-background-state-selected-focused-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-tabs-background-state-unselected-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-tabs-text-selected-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-tabs-text-unselected-v${major}-${minor}-${patch}: #6a7885;
  --magritte-color-component-tabs-counter-text-selected-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-tabs-counter-text-unselected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-tabs-postfix-text-selected-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-tabs-postfix-text-unselected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-input-stroke-state-positive-focused-accessible-v${major}-${minor}-${patch}: #0d88477a;
  --magritte-color-component-input-stroke-state-negative-focused-accessible-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-component-input-stroke-state-field-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-input-stroke-state-field-focused-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-input-stroke-state-field-invalid-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-stroke-state-field-disabled-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-input-stroke-state-field-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-stroke-state-field-succeed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-input-stroke-state-field-applied-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-background-field-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-input-background-state-field-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-input-background-state-field-focused-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-input-background-state-field-invalid-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-input-background-state-field-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-input-background-state-field-succeed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-input-stroke-field-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-input-text-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-text-label-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-placeholder-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-limit-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-description-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-postfix-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-content-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-label-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-placeholder-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-limit-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-description-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-postfix-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-content-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-text-state-content-focused-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-text-state-content-invalid-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-text-state-content-invalid-attention-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-text-state-label-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-label-focused-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-label-invalid-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-label-invalid-attention-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-text-state-placeholder-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-limit-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-placeholder-focused-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-limit-focused-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-placeholder-invalid-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-text-state-limit-invalid-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-text-state-description-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-description-focused-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-description-invalid-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-text-state-content-succeed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-text-state-postfix-invalid-attention-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-input-text-state-postfix-invalid-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-postfix-focused-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-text-state-postfix-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-input-counter-background-content-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-input-counter-background-state-content-disabled-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-input-counter-text-state-content-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-input-counter-text-content-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-input-icon-content-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-icon-grip-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-icon-chevron-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-icon-state-content-hovered-v${major}-${minor}-${patch}: #6a7885;
  --magritte-color-component-input-icon-state-grip-hovered-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-icon-state-content-focused-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-icon-state-content-invalid-v${major}-${minor}-${patch}: #6a7885;
  --magritte-color-component-input-icon-state-content-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-icon-state-chevron-hovered-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-input-icon-state-chevron-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-input-icon-state-content-applied-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-icon-state-chevron-focused-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-input-icon-state-chevron-invalid-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-input-chips-background-state-neutral-disable-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-input-chips-text-state-neutral-disable-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-input-chips-icon-state-neutral-disable-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-cell-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-cell-background-state-hovered-v${major}-${minor}-${patch}: #eef1f7cc;
  --magritte-color-component-cell-background-state-pressed-v${major}-${minor}-${patch}: #dce3ebcc;
  --magritte-color-component-divider-stroke-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-link-text-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-link-text-neutral-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-link-text-neutral-tertiary-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-text-accent-secondary-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-text-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-text-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-link-text-warning-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-text-warning-secondary-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-text-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-text-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-link-text-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-contrast-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-text-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-constant-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-text-state-neutral-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-text-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-text-state-neutral-tertiary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-text-state-neutral-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-text-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-text-state-neutral-tertiary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-link-text-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-text-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-text-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-text-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-text-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-text-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-text-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-warning-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-text-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-text-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-text-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-text-state-warning-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-text-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-text-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-text-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-text-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-text-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-text-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-neutral-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-neutral-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-neutral-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-accent-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-accent-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-accent-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-positive-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-positive-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-positive-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-warning-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-warning-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-warning-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-negative-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-negative-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-negative-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-contrast-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-contrast-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-contrast-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-constant-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-text-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-text-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-text-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-text-state-constant-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-text-state-constant-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-text-state-constant-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-link-icon-neutral-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-link-icon-neutral-tertiary-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-icon-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-icon-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-icon-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-link-icon-warning-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-icon-warning-secondary-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-icon-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-icon-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-link-icon-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-contrast-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-icon-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-constant-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-icon-state-neutral-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-icon-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-icon-state-neutral-tertiary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-icon-state-neutral-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-icon-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-icon-state-neutral-tertiary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-icon-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-link-icon-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-icon-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-icon-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-icon-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-icon-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-icon-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-icon-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-icon-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-warning-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-icon-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-icon-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-icon-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-icon-state-warning-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-icon-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-icon-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-icon-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-icon-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-icon-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-icon-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-neutral-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-neutral-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-neutral-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-accent-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-accent-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-accent-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-positive-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-positive-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-positive-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-warning-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-warning-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-warning-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-negative-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-negative-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-negative-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-contrast-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-contrast-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-contrast-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-constant-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-icon-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-icon-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-icon-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-icon-state-constant-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-icon-state-constant-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-icon-state-constant-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-link-underline-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-link-underline-neutral-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-link-underline-neutral-tertiary-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-underline-accent-secondary-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-underline-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-underline-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-link-underline-warning-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-underline-warning-secondary-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-underline-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-underline-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-link-underline-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-contrast-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-underline-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-constant-secondary-v${major}-${minor}-${patch}: #ffffff7a;
  --magritte-color-component-link-underline-state-neutral-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-underline-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-underline-state-neutral-tertiary-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-link-underline-state-neutral-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-underline-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-underline-state-neutral-tertiary-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-link-underline-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-neutral-tertiary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-link-underline-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-underline-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-link-underline-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-link-underline-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-positive-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-underline-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-link-underline-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-underline-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-link-underline-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-warning-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-underline-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-link-underline-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-underline-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-link-underline-state-warning-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-negative-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-underline-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-link-underline-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-underline-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-link-underline-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-underline-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-underline-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-neutral-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-neutral-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-neutral-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-accent-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-accent-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-accent-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-positive-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-positive-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-positive-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-warning-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-warning-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-warning-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-negative-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-negative-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-negative-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-contrast-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-contrast-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-contrast-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-constant-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-underline-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-link-underline-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-link-underline-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-link-underline-state-constant-visited-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-link-underline-state-constant-visited-hovered-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-link-underline-state-constant-visited-pressed-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-counter-background-content-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-counter-background-attention-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-counter-background-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-counter-background-neutral-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-counter-background-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-background-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-background-state-content-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-background-state-attention-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-background-state-accent-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-background-state-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-background-state-contrast-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-background-state-constant-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-counter-text-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-counter-text-attention-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-counter-text-attention-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-counter-text-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-text-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-counter-text-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-text-neutral-secondary-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-counter-text-contrast-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-counter-text-contrast-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-text-constant-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-counter-text-constant-secondary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-counter-text-state-content-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-attention-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-attention-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-counter-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-tag-background-neutral-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-tag-background-positive-v${major}-${minor}-${patch}: #E8F9EC;
  --magritte-color-component-tag-background-negative-v${major}-${minor}-${patch}: #FEF2EF;
  --magritte-color-component-tag-background-warning-v${major}-${minor}-${patch}: #FFF3E6;
  --magritte-color-component-tag-background-special-v${major}-${minor}-${patch}: #F7F4FF;
  --magritte-color-component-tag-background-state-neutral-disabled-v${major}-${minor}-${patch}: #f1f4f97a;
  --magritte-color-component-tag-background-state-positive-disabled-v${major}-${minor}-${patch}: #e8f9ec7a;
  --magritte-color-component-tag-background-state-negative-disabled-v${major}-${minor}-${patch}: #fef2ef7a;
  --magritte-color-component-tag-background-state-warning-disabled-v${major}-${minor}-${patch}: #fff3e67a;
  --magritte-color-component-tag-background-state-special-disabled-v${major}-${minor}-${patch}: #f7f4ff7a;
  --magritte-color-component-tag-text-neutral-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-tag-text-positive-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-tag-text-negative-v${major}-${minor}-${patch}: #ee4735;
  --magritte-color-component-tag-text-warning-v${major}-${minor}-${patch}: #ac6600;
  --magritte-color-component-tag-text-special-v${major}-${minor}-${patch}: #883be9;
  --magritte-color-component-tag-text-state-neutral-disabled-v${major}-${minor}-${patch}: #5e6c777a;
  --magritte-color-component-tag-text-state-positive-disabled-v${major}-${minor}-${patch}: #00984f7a;
  --magritte-color-component-tag-text-state-negative-disabled-v${major}-${minor}-${patch}: #ee47357a;
  --magritte-color-component-tag-text-state-warning-disabled-v${major}-${minor}-${patch}: #ac66007a;
  --magritte-color-component-tag-text-state-special-disabled-v${major}-${minor}-${patch}: #883be97a;
  --magritte-color-component-tag-icon-neutral-v${major}-${minor}-${patch}: #5e6c77;
  --magritte-color-component-tag-icon-positive-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-tag-icon-negative-v${major}-${minor}-${patch}: #ee4735;
  --magritte-color-component-tag-icon-warning-v${major}-${minor}-${patch}: #ac6600;
  --magritte-color-component-tag-icon-special-v${major}-${minor}-${patch}: #883be9;
  --magritte-color-component-tag-icon-state-neutral-disabled-v${major}-${minor}-${patch}: #5e6c777a;
  --magritte-color-component-tag-icon-state-positive-disabled-v${major}-${minor}-${patch}: #00984f7a;
  --magritte-color-component-tag-icon-state-negative-disabled-v${major}-${minor}-${patch}: #ee47357a;
  --magritte-color-component-tag-icon-state-warning-disabled-v${major}-${minor}-${patch}: #ac66007a;
  --magritte-color-component-tag-icon-state-special-disabled-v${major}-${minor}-${patch}: #883be97a;
  --magritte-color-component-tooltip-background-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-tooltip-text-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-tooltip-stroke-content-v${major}-${minor}-${patch}: #3B3B3B;
  --magritte-color-component-tab-bar-background-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-tab-bar-text-unselected-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-tab-bar-text-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-tab-bar-icon-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-tab-bar-icon-unselected-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-tab-bar-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-navigation-bar-text-title-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-navigation-bar-text-subtitle-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-navigation-bar-background-content-v${major}-${minor}-${patch}: #ffffff00;
  --magritte-color-component-action-text-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-action-text-neutral-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-action-text-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-positive-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-action-text-positive-secondary-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-action-text-negative-v${major}-${minor}-${patch}: #d64030;
  --magritte-color-component-action-text-negative-secondary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-action-text-warning-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-action-text-warning-secondary-v${major}-${minor}-${patch}: #ffa439;
  --magritte-color-component-action-text-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-text-contrast-secondary-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-text-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-text-constant-secondary-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-warning-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-neutral-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-action-text-state-neutral-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-action-text-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-action-text-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-action-text-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-state-accent-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-text-state-positive-pressed-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-action-text-state-positive-hovered-v${major}-${minor}-${patch}: #0ea658;
  --magritte-color-component-action-text-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-action-text-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-action-text-state-negative-pressed-v${major}-${minor}-${patch}: #d64030;
  --magritte-color-component-action-text-state-negative-hovered-v${major}-${minor}-${patch}: #ee4735;
  --magritte-color-component-action-text-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-action-text-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-component-action-text-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-action-text-state-warning-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-action-text-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #ffa439;
  --magritte-color-component-action-text-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-action-text-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-text-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-action-text-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-text-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-text-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-text-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-text-state-constant-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-action-text-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-text-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-icon-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-action-icon-neutral-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-action-icon-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-accent-secondary-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-positive-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-action-icon-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-action-icon-negative-v${major}-${minor}-${patch}: #d64030;
  --magritte-color-component-action-icon-negative-secondary-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-component-action-icon-warning-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-action-icon-warning-secondary-v${major}-${minor}-${patch}: #ffa439;
  --magritte-color-component-action-icon-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-icon-contrast-secondary-v${major}-${minor}-${patch}: #9E9E9E;
  --magritte-color-component-action-icon-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-icon-constant-secondary-v${major}-${minor}-${patch}: #9E9E9E;
  --magritte-color-component-action-icon-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-positive-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-warning-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-negative-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-neutral-pressed-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-action-icon-state-neutral-hovered-v${major}-${minor}-${patch}: #54606a;
  --magritte-color-component-action-icon-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-action-icon-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-action-icon-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-state-accent-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-action-icon-state-positive-pressed-v${major}-${minor}-${patch}: #00984f;
  --magritte-color-component-action-icon-state-positive-hovered-v${major}-${minor}-${patch}: #0ea658;
  --magritte-color-component-action-icon-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-action-icon-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-action-icon-state-negative-pressed-v${major}-${minor}-${patch}: #d64030;
  --magritte-color-component-action-icon-state-negative-hovered-v${major}-${minor}-${patch}: #ee4735;
  --magritte-color-component-action-icon-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-action-icon-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #ff9f8f;
  --magritte-color-component-action-icon-state-warning-pressed-v${major}-${minor}-${patch}: #e38805;
  --magritte-color-component-action-icon-state-warning-hovered-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-action-icon-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #ffa439;
  --magritte-color-component-action-icon-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-action-icon-state-contrast-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-icon-state-contrast-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-action-icon-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-icon-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-icon-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-action-icon-state-constant-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-action-icon-state-constant-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-action-icon-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-icon-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #ABABAB;
  --magritte-color-component-action-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-neutral-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-positive-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-warning-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-negative-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-action-stroke-state-contrast-focused-accessible-v${major}-${minor}-${patch}: #a4c9fe7a;
  --magritte-color-component-action-stroke-state-neutral-hovered-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-neutral-pressed-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-neutral-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-accent-hovered-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-accent-pressed-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-accent-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-negative-hovered-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-negative-pressed-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-negative-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-contrast-hovered-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-contrast-pressed-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-contrast-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-constant-focused-accessible-v${major}-${minor}-${patch}: #a4c9fe7a;
  --magritte-color-component-action-stroke-state-constant-hovered-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-constant-pressed-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-state-constant-disabled-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-background-neutral-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-accent-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-positive-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-warning-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-negative-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-contrast-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-neutral-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-accent-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-positive-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-warning-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-negative-secondary-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-contrast-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-constant-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-constant-secondary-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-neutral-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-neutral-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-accent-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-accent-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-accent-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-negative-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-negative-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-action-background-state-negative-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-contrast-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-contrast-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-contrast-disabled-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-action-background-state-neutral-secondary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-neutral-secondary-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-neutral-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-accent-secondary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-accent-secondary-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-accent-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-secondary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-secondary-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-positive-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-secondary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-secondary-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-warning-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-negative-secondary-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-negative-secondary-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-negative-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-action-background-state-contrast-secondary-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-contrast-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-contrast-secondary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-constant-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-constant-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-constant-disabled-v${major}-${minor}-${patch}: #49545d;
  --magritte-color-component-action-background-state-constant-secondary-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-constant-secondary-pressed-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-background-state-constant-secondary-disabled-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-action-stroke-neutral-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-accent-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-negative-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-contrast-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-action-stroke-constant-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-loader-icon-content-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-snackbar-background-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-snackbar-icon-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-snackbar-icon-positive-v${major}-${minor}-${patch}: #5bcf83;
  --magritte-color-component-snackbar-icon-negative-v${major}-${minor}-${patch}: #fe725f;
  --magritte-color-component-snackbar-stroke-content-v${major}-${minor}-${patch}: #3B3B3B;
  --magritte-color-component-snackbar-text-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-snackbar-text-action-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-snackbar-text-state-action-hovered-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-snackbar-text-state-action-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-snackbar-text-state-action-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-bottom-sheet-background-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-bottom-sheet-grabber-content-v${major}-${minor}-${patch}: #bbc8d48f;
  --magritte-color-component-drop-background-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-_overlay-background-content-v${major}-${minor}-${patch}: #20262b99;
  --magritte-color-component-chips-background-checked-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-background-unchecked-neutral-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-chips-background-checked-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-background-unchecked-accent-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-component-chips-background-state-checked-neutral-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-chips-background-state-checked-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-background-state-checked-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-chips-background-state-unchecked-neutral-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-chips-background-state-unchecked-neutral-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-chips-background-state-unchecked-neutral-disabled-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-chips-background-state-checked-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-chips-background-state-checked-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-background-state-checked-accent-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-chips-background-state-unchecked-accent-hovered-v${major}-${minor}-${patch}: #F9FAFB;
  --magritte-color-component-chips-background-state-unchecked-accent-pressed-v${major}-${minor}-${patch}: #F1F4F7;
  --magritte-color-component-chips-background-state-unchecked-accent-disabled-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-chips-text-checked-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-unchecked-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-text-checked-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-unchecked-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-text-state-checked-neutral-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-state-checked-neutral-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-state-checked-neutral-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-text-state-unchecked-neutral-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-text-state-unchecked-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-text-state-unchecked-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-text-state-checked-accent-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-state-checked-accent-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-text-state-checked-accent-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-text-state-unchecked-accent-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-text-state-unchecked-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-text-state-unchecked-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-chips-icon-checked-neutral-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-unchecked-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-icon-checked-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-unchecked-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-icon-state-checked-neutral-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-state-checked-neutral-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-state-checked-neutral-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-icon-state-unchecked-neutral-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-icon-state-unchecked-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chips-icon-state-unchecked-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-icon-state-checked-accent-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-state-checked-accent-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chips-icon-state-checked-accent-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-icon-state-unchecked-accent-hovered-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-icon-state-unchecked-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chips-icon-state-unchecked-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-counter-text-checked-neutral-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-chips-counter-text-unchecked-neutral-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-chips-counter-text-checked-accent-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-chips-counter-text-unchecked-accent-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-chips-counter-text-state-checked-neutral-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-counter-text-state-unchecked-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-counter-text-state-checked-accent-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-counter-text-state-unchecked-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-postfix-text-checked-neutral-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-chips-postfix-text-unchecked-neutral-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-chips-postfix-text-checked-accent-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-chips-postfix-text-unchecked-accent-v${major}-${minor}-${patch}: #A3BFD2;
  --magritte-color-component-chips-postfix-text-state-checked-neutral-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-postfix-text-state-unchecked-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-chips-postfix-text-state-checked-accent-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-chips-postfix-text-state-unchecked-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-avatar-online-content-v${major}-${minor}-${patch}: #0ea658;
  --magritte-color-component-avatar-online-positive-v${major}-${minor}-${patch}: #0ea658;
  --magritte-color-component-avatar-online-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-avatar-online-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-avatar-background-color-01-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-avatar-background-color-02-v${major}-${minor}-${patch}: #ff859f;
  --magritte-color-component-avatar-background-color-03-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-avatar-background-color-04-v${major}-${minor}-${patch}: #e8c33d;
  --magritte-color-component-avatar-background-color-05-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-avatar-background-color-06-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-avatar-background-color-07-v${major}-${minor}-${patch}: #76adfe;
  --magritte-color-component-avatar-background-color-08-v${major}-${minor}-${patch}: #50b5e8;
  --magritte-color-component-avatar-background-color-09-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-avatar-text-letters-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-avatar-stroke-image-v${major}-${minor}-${patch}: #00000014;
  --magritte-color-component-avatar-stroke-placeholder-v${major}-${minor}-${patch}: #6a788514;
  --magritte-color-component-avatar-stroke-letters-v${major}-${minor}-${patch}: #0000000a;
  --magritte-color-component-avatar-stroke-icon-v${major}-${minor}-${patch}: #0000000a;
  --magritte-color-component-avatar-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-swipe-button-background-neutral-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-swipe-button-background-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-swipe-button-background-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-swipe-button-background-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-swipe-button-background-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-swipe-button-background-special-v${major}-${minor}-${patch}: #883be9;
  --magritte-color-component-swipe-button-background-state-neutral-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-swipe-button-background-state-neutral-hovered-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-swipe-button-background-state-accent-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-swipe-button-background-state-accent-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-swipe-button-background-state-positive-pressed-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-swipe-button-background-state-positive-hovered-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-swipe-button-background-state-negative-pressed-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-swipe-button-background-state-negative-hovered-v${major}-${minor}-${patch}: #fdb5a7;
  --magritte-color-component-swipe-button-background-state-warning-pressed-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-swipe-button-background-state-warning-hovered-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-swipe-button-background-state-special-pressed-v${major}-${minor}-${patch}: #883be9;
  --magritte-color-component-swipe-button-background-state-special-hovered-v${major}-${minor}-${patch}: #af8bf5;
  --magritte-color-component-swipe-button-text-label-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-swipe-button-text-state-label-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-swipe-button-text-state-label-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-swipe-button-icon-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-swipe-button-icon-state-content-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-swipe-button-icon-state-content-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-modal-background-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-modal-background-action-v${major}-${minor}-${patch}: #20262b99;
  --magritte-color-component-breadcrumbs-text-primary-v${major}-${minor}-${patch}: #20262b;
  --magritte-color-component-breadcrumbs-text-secondary-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-breadcrumbs-text-tertiary-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-breadcrumbs-text-state-secondary-hovered-v${major}-${minor}-${patch}: #6a7885;
  --magritte-color-component-breadcrumbs-text-state-secondary-pressed-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-progress-bar-background-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-progress-bar-foreground-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-progress-bar-foreground-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-progress-bar-foreground-special-v${major}-${minor}-${patch}: #883be9;
  --magritte-color-component-progress-bar-foreground-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-progress-bar-foreground-negative-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-dot-pages-background-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-dot-pages-foreground-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-background-content-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-number-pages-stroke-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-number-pages-item-text-unselected-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-number-pages-item-text-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-item-text-state-unselected-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-item-text-state-unselected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-item-icon-content-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-number-pages-item-icon-state-content-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-item-icon-state-content-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-number-pages-item-background-selected-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-number-pages-item-background-unselected-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-number-pages-item-background-content-v${major}-${minor}-${patch}: #dce3eb00;
  --magritte-color-component-number-pages-item-background-state-unselected-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-number-pages-item-background-state-content-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-number-pages-item-background-state-unselected-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-number-pages-item-background-state-content-pressed-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-number-pages-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-time-picker-background-selection-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-date-picker-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-date-picker-item-text-unselected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-text-selected-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-neutral-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-text-neutral-secondary-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-date-picker-item-text-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-stroke-today-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-date-picker-item-background-unselected-v${major}-${minor}-${patch}: #ffffff00;
  --magritte-color-component-date-picker-item-background-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-background-selected-preview-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-date-picker-item-background-neutral-v${major}-${minor}-${patch}: #ffffff00;
  --magritte-color-component-date-picker-item-background-info-primary-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-date-picker-item-background-info-secondary-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-date-picker-item-background-state-unselected-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-date-picker-item-background-state-unselected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-background-state-unselected-disabled-v${major}-${minor}-${patch}: #ffffff00;
  --magritte-color-component-date-picker-item-background-state-selected-hovered-v${major}-${minor}-${patch}: #343c43;
  --magritte-color-component-date-picker-item-background-state-selected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-background-state-selected-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-date-picker-item-background-state-selected-range-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-date-picker-item-background-state-info-primary-hovered-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-date-picker-item-background-state-info-secondary-hovered-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-date-picker-item-background-state-info-primary-pressed-v${major}-${minor}-${patch}: #ff8a77;
  --magritte-color-component-date-picker-item-background-state-info-secondary-pressed-v${major}-${minor}-${patch}: #fdb972;
  --magritte-color-component-date-picker-item-text-state-unselected-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-text-state-unselected-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-state-unselected-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-date-picker-item-text-state-selected-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-state-selected-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-state-selected-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-item-text-state-selected-range-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-text-state-neutral-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-item-text-state-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-background-overflow-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-date-picker-header-text-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-header-text-content-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-date-picker-header-text-state-content-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-header-text-state-content-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-date-picker-header-icon-content-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-date-picker-header-icon-state-content-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-date-picker-header-icon-state-content-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-table-background-primary-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-table-background-state-primary-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-table-background-state-primary-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-table-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-table-text-neutral-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-table-text-state-neutral-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-table-text-state-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-table-text-state-neutral-disabled-v${major}-${minor}-${patch}: #7686947a;
  --magritte-color-component-table-icon-neutral-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-table-icon-state-neutral-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-table-icon-state-neutral-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-table-icon-state-neutral-disabled-v${major}-${minor}-${patch}: #7686947a;
  --magritte-color-component-secondary-tabs-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-secondary-tabs-text-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-secondary-tabs-text-unselected-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-secondary-tabs-indicator-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-secondary-tabs-text-state-unselected-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-secondary-tabs-text-state-unselected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-secondary-tabs-counter-text-selected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-secondary-tabs-counter-text-unselected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-secondary-tabs-postfix-text-selected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-secondary-tabs-postfix-text-unselected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-segmented-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-segmented-stroke-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-segmented-background-content-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-segmented-item-text-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-text-unselected-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-segmented-item-text-state-unselected-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-text-state-unselected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-icon-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-icon-unselected-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-segmented-item-icon-state-unselected-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-icon-state-unselected-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-segmented-item-background-selected-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-segmented-item-background-unselected-v${major}-${minor}-${patch}: #ffffff00;
  --magritte-color-component-form-label-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-form-label-text-content-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-text-state-content-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-text-state-content-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-text-state-content-disabled-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-icon-content-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-icon-state-content-hovered-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-icon-state-content-pressed-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-icon-state-content-disabled-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-form-label-counter-content-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-form-label-counter-state-content-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-skeleton-background-content-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-scrollable-container-fade-content-v${major}-${minor}-${patch}: linear-gradient(0deg, #00000000 0%, #000000 100%);
  --magritte-color-component-chat-bubble-background-incoming-v${major}-${minor}-${patch}: #F8F8F8;
  --magritte-color-component-chat-bubble-background-outgoing-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chat-bubble-background-bot-v${major}-${minor}-${patch}: #f9f7ff;
  --magritte-color-component-chat-bubble-background-preview-v${major}-${minor}-${patch}: #f3f9ff;
  --magritte-color-component-chat-bubble-border-incoming-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-chat-bubble-border-outgoing-v${major}-${minor}-${patch}: #557B96;
  --magritte-color-component-chat-bubble-border-bot-v${major}-${minor}-${patch}: #f3eeff;
  --magritte-color-component-chat-bubble-border-image-v${major}-${minor}-${patch}: #0000001a;
  --magritte-color-component-chat-bubble-snippet-background-incoming-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-chat-bubble-snippet-background-outgoing-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-chat-bubble-snippet-background-bot-v${major}-${minor}-${patch}: #f3eeff;
  --magritte-color-component-chat-bubble-snippet-line-incoming-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chat-bubble-snippet-line-outgoing-v${major}-${minor}-${patch}: #f3f9ff;
  --magritte-color-component-chat-bubble-snippet-line-bot-v${major}-${minor}-${patch}: #b99bf8;
  --magritte-color-component-chat-bubble-attach-background-incoming-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chat-bubble-attach-background-outgoing-v${major}-${minor}-${patch}: #f3f9ff;
  --magritte-color-component-chat-bubble-attach-background-bot-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-component-chat-bubble-attach-icon-incoming-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chat-bubble-attach-icon-outgoing-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-chat-bubble-attach-icon-bot-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-chat-bubble-button-background-content-v${major}-${minor}-${patch}: #f3eeff;
  --magritte-color-component-chat-bubble-button-background-state-content-hovered-v${major}-${minor}-${patch}: #e7defe;
  --magritte-color-component-chat-bubble-button-background-state-content-pressed-v${major}-${minor}-${patch}: #e7defe;
  --magritte-color-component-chat-bubble-button-background-state-content-disabled-v${major}-${minor}-${patch}: #F4F6FB;
  --magritte-color-component-chat-bubble-button-text-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chat-bubble-button-text-state-content-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-chat-bubble-button-icon-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chat-bubble-button-icon-state-content-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-chat-bubble-button-counter-text-content-v${major}-${minor}-${patch}: #8da0b0;
  --magritte-color-component-chat-bubble-button-counter-text-state-content-disabled-v${major}-${minor}-${patch}: #BBC8D4;
  --magritte-color-component-chat-bubble-button-loader-icon-content-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-chat-bubble-button-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-checkable-card-background-unchecked-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-checked-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-unchecked-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-unchecked-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-unchecked-tone-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-checkable-card-background-state-unchecked-tone-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-checkable-card-background-state-unchecked-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-checked-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-checked-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-background-state-checked-tone-hovered-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-checkable-card-background-state-checked-tone-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-checkable-card-background-state-checked-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-card-stroke-unchecked-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-checkable-card-stroke-checked-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-checkable-card-stroke-state-unchecked-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-card-stroke-state-unchecked-pressed-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-card-stroke-state-checked-hovered-v${major}-${minor}-${patch}: #90ACC1;
  --magritte-color-component-checkable-card-stroke-state-checked-pressed-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-checkable-card-stroke-state-unchecked-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-checkable-card-stroke-state-checked-disabled-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-card-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-checkable-background-unchecked-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-background-checked-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-checkable-background-state-unchecked-hovered-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-background-state-unchecked-pressed-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-background-state-unchecked-disabled-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-checkable-background-state-checked-hovered-v${major}-${minor}-${patch}: #FFEC66;
  --magritte-color-component-checkable-background-state-checked-pressed-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-checkable-background-state-checked-disabled-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-stroke-unchecked-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-checkable-stroke-checked-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-checkable-stroke-state-unchecked-hovered-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-stroke-state-unchecked-pressed-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-stroke-state-checked-hovered-v${major}-${minor}-${patch}: #FFEC66;
  --magritte-color-component-checkable-stroke-state-checked-pressed-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-checkable-stroke-state-unchecked-disabled-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-checkable-stroke-state-checked-disabled-v${major}-${minor}-${patch}: #CCD5DF;
  --magritte-color-component-checkable-stroke-state-invalid-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-checkable-stroke-state-negative-focused-accessible-v${major}-${minor}-${patch}: #ff4d3a7a;
  --magritte-color-component-checkable-stroke-state-accent-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-checkable-icon-checked-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-checkable-icon-state-unchecked-hovered-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-checkable-icon-state-checked-hovered-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-checkable-icon-state-checked-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-branded-button-stroke-state-apple-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-yandex-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-vk-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-mail-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-ok-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-tinkoff-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-google-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-esia-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-more-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-stroke-state-t-bank-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-branded-button-background-apple-v${major}-${minor}-${patch}: #171C20;
  --magritte-color-component-branded-button-background-apple-secondary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-apple-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-yandex-v${major}-${minor}-${patch}: #fc3f1d;
  --magritte-color-component-branded-button-background-yandex-secondary-v${major}-${minor}-${patch}: #FFECE8;
  --magritte-color-component-branded-button-background-yandex-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-vk-v${major}-${minor}-${patch}: #0077ff;
  --magritte-color-component-branded-button-background-vk-secondary-v${major}-${minor}-${patch}: #E6F1FF;
  --magritte-color-component-branded-button-background-vk-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-mail-v${major}-${minor}-${patch}: #ff9e00;
  --magritte-color-component-branded-button-background-mail-secondary-v${major}-${minor}-${patch}: #FFF0D9;
  --magritte-color-component-branded-button-background-mail-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-ok-v${major}-${minor}-${patch}: #f38231;
  --magritte-color-component-branded-button-background-ok-secondary-v${major}-${minor}-${patch}: #FDEFE4;
  --magritte-color-component-branded-button-background-ok-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-tinkoff-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-tinkoff-secondary-v${major}-${minor}-${patch}: #FBF4C1;
  --magritte-color-component-branded-button-background-tinkoff-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-google-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-branded-button-background-google-secondary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-google-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-esia-v${major}-${minor}-${patch}: #0D4cd3;
  --magritte-color-component-branded-button-background-esia-secondary-v${major}-${minor}-${patch}: #E7EDFB;
  --magritte-color-component-branded-button-background-esia-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-more-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-branded-button-background-more-secondary-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-more-tertiary-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-t-bank-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-t-bank-secondary-v${major}-${minor}-${patch}: #FBF4C1;
  --magritte-color-component-branded-button-background-t-bank-tertiary-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-state-apple-hovered-v${major}-${minor}-${patch}: #2A333B;
  --magritte-color-component-branded-button-background-state-apple-pressed-v${major}-${minor}-${patch}: #171C20;
  --magritte-color-component-branded-button-background-state-apple-focused-v${major}-${minor}-${patch}: #171C20;
  --magritte-color-component-branded-button-background-state-apple-secondary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-apple-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-apple-secondary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-apple-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-apple-secondary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-apple-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-yandex-hovered-v${major}-${minor}-${patch}: #E3391A;
  --magritte-color-component-branded-button-background-state-yandex-pressed-v${major}-${minor}-${patch}: #fc3f1d;
  --magritte-color-component-branded-button-background-state-yandex-focused-v${major}-${minor}-${patch}: #fc3f1d;
  --magritte-color-component-branded-button-background-state-yandex-secondary-hovered-v${major}-${minor}-${patch}: #E5D4D1;
  --magritte-color-component-branded-button-background-state-yandex-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-yandex-secondary-pressed-v${major}-${minor}-${patch}: #FFECE8;
  --magritte-color-component-branded-button-background-state-yandex-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-yandex-secondary-focused-v${major}-${minor}-${patch}: #FFECE8;
  --magritte-color-component-branded-button-background-state-yandex-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-vk-hovered-v${major}-${minor}-${patch}: #006BE5;
  --magritte-color-component-branded-button-background-state-vk-pressed-v${major}-${minor}-${patch}: #0077ff;
  --magritte-color-component-branded-button-background-state-vk-focused-v${major}-${minor}-${patch}: #0077ff;
  --magritte-color-component-branded-button-background-state-vk-secondary-hovered-v${major}-${minor}-${patch}: #CFD9E5;
  --magritte-color-component-branded-button-background-state-vk-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-vk-secondary-pressed-v${major}-${minor}-${patch}: #E6F1FF;
  --magritte-color-component-branded-button-background-state-vk-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-vk-secondary-focused-v${major}-${minor}-${patch}: #E6F1FF;
  --magritte-color-component-branded-button-background-state-vk-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-mail-hovered-v${major}-${minor}-${patch}: #E58E00;
  --magritte-color-component-branded-button-background-state-mail-pressed-v${major}-${minor}-${patch}: #ff9e00;
  --magritte-color-component-branded-button-background-state-mail-focused-v${major}-${minor}-${patch}: #ff9e00;
  --magritte-color-component-branded-button-background-state-mail-secondary-hovered-v${major}-${minor}-${patch}: #E5D8C3;
  --magritte-color-component-branded-button-background-state-mail-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-mail-secondary-pressed-v${major}-${minor}-${patch}: #FFF0D9;
  --magritte-color-component-branded-button-background-state-mail-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-mail-secondary-focused-v${major}-${minor}-${patch}: #FFF0D9;
  --magritte-color-component-branded-button-background-state-mail-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-ok-hovered-v${major}-${minor}-${patch}: #D9742C;
  --magritte-color-component-branded-button-background-state-ok-pressed-v${major}-${minor}-${patch}: #f38231;
  --magritte-color-component-branded-button-background-state-ok-focused-v${major}-${minor}-${patch}: #f38231;
  --magritte-color-component-branded-button-background-state-ok-secondary-hovered-v${major}-${minor}-${patch}: #E3D6CD;
  --magritte-color-component-branded-button-background-state-ok-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-ok-secondary-pressed-v${major}-${minor}-${patch}: #FDEFE4;
  --magritte-color-component-branded-button-background-state-ok-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-ok-secondary-focused-v${major}-${minor}-${patch}: #FDEFE4;
  --magritte-color-component-branded-button-background-state-ok-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-tinkoff-hovered-v${major}-${minor}-${patch}: #CCB124;
  --magritte-color-component-branded-button-background-state-tinkoff-pressed-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-state-tinkoff-focused-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-state-tinkoff-secondary-hovered-v${major}-${minor}-${patch}: #E0DAAD;
  --magritte-color-component-branded-button-background-state-tinkoff-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-tinkoff-secondary-pressed-v${major}-${minor}-${patch}: #FBF4C1;
  --magritte-color-component-branded-button-background-state-tinkoff-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-tinkoff-secondary-focused-v${major}-${minor}-${patch}: #FBF4C1;
  --magritte-color-component-branded-button-background-state-tinkoff-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-google-hovered-v${major}-${minor}-${patch}: #111;
  --magritte-color-component-branded-button-background-state-google-pressed-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-branded-button-background-state-google-focused-v${major}-${minor}-${patch}: #111;
  --magritte-color-component-branded-button-background-state-google-secondary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-google-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-google-secondary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-google-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-google-secondary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-google-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-esia-hovered-v${major}-${minor}-${patch}: #105CFF;
  --magritte-color-component-branded-button-background-state-esia-pressed-v${major}-${minor}-${patch}: #0D4CD3;
  --magritte-color-component-branded-button-background-state-esia-focused-v${major}-${minor}-${patch}: #0D4CD3;
  --magritte-color-component-branded-button-background-state-esia-secondary-hovered-v${major}-${minor}-${patch}: #CFD4E0;
  --magritte-color-component-branded-button-background-state-esia-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-esia-secondary-pressed-v${major}-${minor}-${patch}: #E7EDFB;
  --magritte-color-component-branded-button-background-state-esia-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-esia-secondary-focused-v${major}-${minor}-${patch}: #E7EDFB;
  --magritte-color-component-branded-button-background-state-esia-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-more-hovered-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-more-pressed-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-branded-button-background-state-more-focused-v${major}-${minor}-${patch}: #F1F4F9;
  --magritte-color-component-branded-button-background-state-more-secondary-hovered-v${major}-${minor}-${patch}: #E2E2E2;
  --magritte-color-component-branded-button-background-state-more-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-more-secondary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-more-tertiary-pressed-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-more-secondary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-more-tertiary-focused-v${major}-${minor}-${patch}: #F5F5F5;
  --magritte-color-component-branded-button-background-state-apple-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-yandex-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-vk-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-mail-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-ok-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-tinkoff-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-google-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-esia-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-more-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-more-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-more-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-apple-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-apple-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-yandex-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-yandex-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-vk-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-vk-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-mail-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-mail-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-ok-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-ok-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-tinkoff-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-tinkoff-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-google-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-google-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-esia-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-esia-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-t-bank-hovered-v${major}-${minor}-${patch}: #CCB124;
  --magritte-color-component-branded-button-background-state-t-bank-secondary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-t-bank-tertiary-hovered-v${major}-${minor}-${patch}: #DBDBDB;
  --magritte-color-component-branded-button-background-state-t-bank-pressed-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-state-t-bank-secondary-pressed-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-state-t-bank-tertiary-pressed-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-state-t-bank-focused-v${major}-${minor}-${patch}: #FFDD2D;
  --magritte-color-component-branded-button-background-state-t-bank-secondary-focused-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-state-t-bank-tertiary-focused-v${major}-${minor}-${patch}: #F1F1F1;
  --magritte-color-component-branded-button-background-state-t-bank-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-t-bank-secondary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-background-state-t-bank-tertiary-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-branded-button-text-apple-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-apple-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-apple-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-yandex-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-yandex-secondary-v${major}-${minor}-${patch}: #fc3f1d;
  --magritte-color-component-branded-button-text-yandex-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-vk-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-vk-secondary-v${major}-${minor}-${patch}: #0077ff;
  --magritte-color-component-branded-button-text-vk-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-mail-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-mail-secondary-v${major}-${minor}-${patch}: #F39701;
  --magritte-color-component-branded-button-text-mail-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-ok-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-ok-secondary-v${major}-${minor}-${patch}: #f38231;
  --magritte-color-component-branded-button-text-ok-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-google-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-google-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-google-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-esia-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-text-esia-secondary-v${major}-${minor}-${patch}: #0D4cd3;
  --magritte-color-component-branded-button-text-esia-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-tinkoff-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-tinkoff-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-tinkoff-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-more-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-more-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-more-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-t-bank-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-t-bank-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-t-bank-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-apple-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-apple-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-apple-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-yandex-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-yandex-secondary-v${major}-${minor}-${patch}: #fc3f1d;
  --magritte-color-component-branded-button-icon-yandex-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-vk-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-vk-secondary-v${major}-${minor}-${patch}: #0077ff;
  --magritte-color-component-branded-button-icon-vk-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-mail-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-mail-secondary-v${major}-${minor}-${patch}: #F39701;
  --magritte-color-component-branded-button-icon-mail-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-ok-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-ok-secondary-v${major}-${minor}-${patch}: #f38231;
  --magritte-color-component-branded-button-icon-ok-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-google-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-google-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-google-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-esia-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-branded-button-icon-esia-secondary-v${major}-${minor}-${patch}: #0D4cd3;
  --magritte-color-component-branded-button-icon-esia-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-tinkoff-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-tinkoff-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-tinkoff-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-more-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-more-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-more-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-t-bank-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-t-bank-secondary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-icon-t-bank-tertiary-v${major}-${minor}-${patch}: #0d1115;
  --magritte-color-component-branded-button-text-state-apple-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-apple-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-apple-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-yandex-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-yandex-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-yandex-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-vk-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-vk-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-vk-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-mail-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-mail-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-mail-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-ok-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-ok-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-ok-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-google-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-google-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-google-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-esia-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-esia-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-esia-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-tinkoff-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-tinkoff-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-tinkoff-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-more-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-more-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-more-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-t-bank-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-t-bank-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-text-state-t-bank-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-apple-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-yandex-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-vk-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-mail-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-ok-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-google-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-esia-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-tinkoff-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-more-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-more-secondary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-more-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-branded-button-icon-state-t-bank-tertiary-disabled-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-stepper-background-default-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-stepper-background-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-stepper-background-warning-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-component-stepper-background-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-stepper-background-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-stepper-background-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-stepper-stroke-default-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-stepper-stroke-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-stepper-text-primary-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-stepper-text-secondary-v${major}-${minor}-${patch}: #768694;
  --magritte-color-component-stepper-text-tertiary-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-stepper-text-positive-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-component-stepper-text-positive-secondary-v${major}-${minor}-${patch}: #83d99c;
  --magritte-color-component-badge-background-attention-v${major}-${minor}-${patch}: #FFE000;
  --magritte-color-component-badge-background-accent-v${major}-${minor}-${patch}: #7195AF;
  --magritte-color-component-badge-background-neutral-v${major}-${minor}-${patch}: #EEF1F7;
  --magritte-color-component-badge-background-contrast-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-badge-background-constant-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-badge-background-state-attention-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-badge-background-state-accent-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-badge-background-state-neutral-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-badge-background-state-contrast-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-badge-background-state-constant-disabled-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-badge-text-attention-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-badge-text-accent-v${major}-${minor}-${patch}: #ffffff;
  --magritte-color-component-badge-text-neutral-v${major}-${minor}-${patch}: #8293a2;
  --magritte-color-component-badge-text-contrast-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-badge-text-constant-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-badge-text-state-attention-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-badge-text-state-accent-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-badge-text-state-neutral-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-badge-text-state-contrast-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-badge-text-state-constant-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-rating-icon-selected-v${major}-${minor}-${patch}: #FFBE5D;
  --magritte-color-component-rating-icon-unselected-v${major}-${minor}-${patch}: #DCE3EB;
  --magritte-color-component-rating-icon-state-unselected-hovered-v${major}-${minor}-${patch}: #FFBE5D;
  --magritte-color-component-rating-icon-state-unselected-pressed-v${major}-${minor}-${patch}: #FFBE5D;
  --magritte-color-component-rating-text-selected-v${major}-${minor}-${patch}: #000000;
  --magritte-color-component-rating-text-unselected-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-rating-stroke-state-focused-accessible-v${major}-${minor}-${patch}: #0070ff7a;
  --magritte-color-component-rating-text-state-disabled-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-component-form-helper-text-state-content-invalid-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-component-form-helper-text-state-content-disabled-v${major}-${minor}-${patch}: #96aabb;
  --magritte-color-component-form-helper-text-content-v${major}-${minor}-${patch}: #768694;
  --magritte-color-visualisation-line-1-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-visualisation-line-2-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-visualisation-line-3-v${major}-${minor}-${patch}: #0070ff;
  --magritte-color-visualisation-line-4-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-visualisation-line-5-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-visualisation-line-6-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-color-visualisation-point-1-v${major}-${minor}-${patch}: #ff4d3a;
  --magritte-color-visualisation-point-2-v${major}-${minor}-${patch}: #0dc267;
  --magritte-color-visualisation-point-3-v${major}-${minor}-${patch}: #0070ff;
  --magritte-color-visualisation-point-4-v${major}-${minor}-${patch}: #9054ea;
  --magritte-color-visualisation-point-5-v${major}-${minor}-${patch}: #ff9900;
  --magritte-color-visualisation-point-6-v${major}-${minor}-${patch}: #AABBCA;
  --magritte-gradient-component-modal-overlay-v${major}-${minor}-${patch}: linear-gradient(180deg, #00000000 0%, #0000007a 100%);
  --magritte-gradient-component-modal-background-fade-v${major}-${minor}-${patch}: linear-gradient(180deg, #00000000 0%, #0000007a 100%);
  --magritte-gradient-component-upload-background-fade-v${major}-${minor}-${patch}: linear-gradient(180deg, #00000000 20.5%, #00000000 42.5%, #0000000f 63.5%, #0000001a 75.5%, #00000026 87.5%, #00000040 100%);;
  --magritte-gradient-visualisation-fill-1-v${major}-${minor}-${patch}: linear-gradient(0deg, #ff4d3a00 0%, #ff4d3a 100%);
  --magritte-gradient-visualisation-fill-2-v${major}-${minor}-${patch}: linear-gradient(0deg, #0dc26700 0%, #0dc267 100%);
  --magritte-gradient-visualisation-fill-3-v${major}-${minor}-${patch}: linear-gradient(0deg, #0070ff00 0%, #0070ff 100%);
  --magritte-gradient-visualisation-fill-4-v${major}-${minor}-${patch}: linear-gradient(0deg, #9054ea00 0%, #9054ea 100%);
  --magritte-gradient-visualisation-fill-5-v${major}-${minor}-${patch}: linear-gradient(0deg, #ff990000 0%, #ff9900 100%);
  --magritte-gradient-visualisation-fill-6-v${major}-${minor}-${patch}: linear-gradient(0deg, #aabbca00 0%, #AABBCA 100%);
  --magritte-shadow-level-3-color-v${major}-${minor}-${patch}: #7090b052;
  --magritte-shadow-level-3-type-v${major}-${minor}-${patch}: dropShadow;
  --magritte-shadow-level-3-x-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-3-y-v${major}-${minor}-${patch}: 12px;
  --magritte-shadow-level-3-blur-v${major}-${minor}-${patch}: 24px;
  --magritte-shadow-level-3-spread-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-2-color-v${major}-${minor}-${patch}: #7090b03d;
  --magritte-shadow-level-2-type-v${major}-${minor}-${patch}: dropShadow;
  --magritte-shadow-level-2-x-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-2-y-v${major}-${minor}-${patch}: 8px;
  --magritte-shadow-level-2-blur-v${major}-${minor}-${patch}: 16px;
  --magritte-shadow-level-2-spread-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-1-color-v${major}-${minor}-${patch}: #7090b029;
  --magritte-shadow-level-1-type-v${major}-${minor}-${patch}: dropShadow;
  --magritte-shadow-level-1-x-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-1-y-v${major}-${minor}-${patch}: 4px;
  --magritte-shadow-level-1-blur-v${major}-${minor}-${patch}: 12px;
  --magritte-shadow-level-1-spread-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-0-x-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-0-y-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-0-blur-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-0-spread-v${major}-${minor}-${patch}: 0px;
  --magritte-shadow-level-0-color-v${major}-${minor}-${patch}: #00000000;
  --magritte-shadow-level-0-type-v${major}-${minor}-${patch}: dropShadow;
}`;

    return { tokensString, themeClassName: 'magritte-zp-day-theme' };
};
