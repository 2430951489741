import Button, { ButtonScale } from 'bloko/blocks/button';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    title: 'resumeCreationScenario.notification.title',
    text: 'resumeCreationScenario.notification.text',
    button: 'resumeCreationScenario.notification.button',
};

interface Props {
    onClick: () => void;
    onClose: () => void;
    removeNotification: () => void;
}

const ResumeCreationScenarioNotification: TranslatedComponent<Props> = ({ trls, onClick, removeNotification }) => {
    const handleClick = () => {
        onClick();
        removeNotification();
    };

    return (
        <>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            {trls[TrlKeys.text]}
            <VSpacing base={4} />
            <Button onClick={handleClick} scale={ButtonScale.Small}>
                {trls[TrlKeys.button]}
            </Button>
        </>
    );
};

export default {
    Element: translation(ResumeCreationScenarioNotification),
    kind: 'message',
    onClose: (_: unknown, { onClose }: Props): void => onClose(),
};
