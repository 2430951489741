import Link from 'bloko/blocks/link';
import Notification, { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    text: 'emailVerification.wasComplited.error.again',
    link: 'emailVerification.wasComplited.error.link',
};

const EmailVerificationErrorAgain: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    params,
}) => (
    <Notification kind={NotificationKind.Error} onClose={onClose}>
        {formatToReactComponent(trls[TrlKeys.text], {
            '{0}': <Link href={params.currentUrl}>{trls[TrlKeys.link]}</Link>,
        })}
    </Notification>
);

export default translation(EmailVerificationErrorAgain);
