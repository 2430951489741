import { FC, useEffect, useRef, PropsWithChildren } from 'react';

import { MarkAsViewedEvent } from 'Modules/Notices';
import useMarkAsRead from 'src/components/Applicant/JobSearchStatus/useMarkAsRead';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import Content from 'src/components/UserNotifications/variants/ApplicantJobSearchStatus/Content';

const ApplicantJobSearchStatus: FC<ComplexNotificationComponentProps & PropsWithChildren> = ({
    onClose,
    ...notification
}) => {
    const triggerName = useSelector((state) => state.applicantUserStatusesTrigger);
    const markAsReadRef = useRef(useMarkAsRead(triggerName));
    useEffect(() => {
        if (notification.id === undefined) {
            markAsReadRef.current();
        }
    }, [notification.id]);

    return (
        <SynchronizedNotification
            notification={notification}
            markAsViewedEvent={notification.id === undefined ? MarkAsViewedEvent.None : MarkAsViewedEvent.OnShow}
            onClose={onClose}
        >
            <Content onClose={onClose} />
        </SynchronizedNotification>
    );
};

export default translation(ApplicantJobSearchStatus);
