import { FC, useState, useEffect, useCallback, RefObject, SyntheticEvent } from 'react';

import { KeyCode } from 'bloko/common/constants/keyboard';

import { NavItems } from 'src/models/supernovaNaviMenu';

import HelpModal from 'src/components/SupernovaMainMenu/HelpModal';

const HELP_MODAL = 'help';

const MODAL_COMPONENTS = {
    [HELP_MODAL]: HelpModal,
};

export type SupernovaModalType = keyof typeof MODAL_COMPONENTS;

interface SupernovaModalsProps {
    modalType?: SupernovaModalType;
    activatorRef: RefObject<HTMLButtonElement>;
    subItems?: NavItems;
    onClose?: () => void;
}

const SupernovaModals: FC<SupernovaModalsProps> = ({ modalType, activatorRef, subItems, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [openedWithKeyBoard, setOpenedWithKeyBoard] = useState(false);

    const handleOpen = useCallback(() => {
        setIsVisible(true);
    }, []);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.keyCode === KeyCode.Enter) {
            setOpenedWithKeyBoard(true);
        }
    }, []);

    useEffect(() => {
        const activator = activatorRef.current;

        if (activator) {
            activator.addEventListener('click', handleOpen);
            activator.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (activator) {
                activator.removeEventListener('click', handleOpen);
                activator.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [handleOpen, activatorRef, handleKeyDown]);

    const isKeyboardEvent = (event?: SyntheticEvent): event is SyntheticEvent<Element, KeyboardEvent> =>
        event?.nativeEvent?.type === 'keydown';
    const handleCloseModal = (event?: SyntheticEvent) => {
        if (isKeyboardEvent(event) && event.nativeEvent.keyCode === KeyCode.ESC && openedWithKeyBoard) {
            event.preventDefault();
            activatorRef.current?.focus();
        }

        setIsVisible(false);
        setOpenedWithKeyBoard(false);
    };

    if (!modalType) {
        return null;
    }

    const ModalComponent = MODAL_COMPONENTS[modalType];

    return (
        <ModalComponent
            isVisible={isVisible}
            handleCloseModal={handleCloseModal}
            subItems={subItems}
            onClose={onClose}
        />
    );
};

export default SupernovaModals;
