import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';
import { analyticsNameMap, linkMap } from 'src/utils/notifications/HRSpace';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]: {
        title: 'hrspace.notification.applicant.title',
        description: 'hrspace.notification.applicant.description',
        link: 'hrspace.notification.applicant.link',
    },
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]: {
        title: 'hrspace.notification.employer.title',
        description: 'hrspace.notification.employer.description',
        link: 'hrspace.notification.employer.link',
    },
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]: {
        title: 'hrspace.notification.it_employer.title',
        description: 'hrspace.notification.it_employer.description',
        link: 'hrspace.notification.it_employer.link',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const HRSpace: TranslatedComponent<ComplexNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    const closeNotificationRef = useRef<VoidFunction>(null);

    return (
        <SynchronizedNotification
            analyticsElementName={analyticsNameMap[key]}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
            closeEvent={`${analyticsNameMap[key]}_close`}
        >
            <NotificationHeading>{trls[TrlKeys[key].title]}</NotificationHeading>
            <p>{trls[TrlKeys[key].description]}</p>
            <NotificationFooter>
                <Button
                    kind={ButtonKind.Primary}
                    appearance={ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                    Element="a"
                    target="_blank"
                    onClick={() => {
                        Analytics.sendEvent(key, 'view', 'priority');
                        Analytics.sendHHEventButtonClick(`${analyticsNameMap[key]}_link_priority`);
                        closeNotificationRef.current?.();
                    }}
                    href={linkMap[key]}
                >
                    {trls[TrlKeys[key].link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(HRSpace);
