import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import VacancyDefaultBody from 'src/models/vacancy/defaultBody';

interface VacancyTemplateForEdit extends VacancyDefaultBody {
    shared: boolean;
    canModify: boolean;
}

export default autoGeneratedReducer<VacancyTemplateForEdit>(null);
