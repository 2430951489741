import React from 'react';

import { BarsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Breakpoint } from 'bloko/common/media';
import { Placement } from 'bloko/common/metrics';

import { NavItem } from 'src/models/supernovaNaviMenu';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';

const ARROW_BREAKPOINTS = [Breakpoint.XS, Breakpoint.S, Breakpoint.M];
const FULL_WIDTH_BREAKPOINTS = [Breakpoint.XS, Breakpoint.S, Breakpoint.M];
const VISIBLE_ON = [Breakpoint.S];

const DropdownMenu: React.VFC<NavItem> = ({ name, subItems, trackClicks, analytics }) => {
    const [currentTab, setCurrentTab] = React.useState<string>();

    const render = React.useCallback(
        (onClose: () => void) => {
            return (
                <div className="supernova-dropdown-menu">
                    <div className="supernova-dropdown-menu-tabs">
                        <SupernovaDropdownSection>
                            {subItems?.map((item) => (
                                <SupernovaDropdownOption
                                    key={item.name}
                                    name={item.name}
                                    onClick={() => {
                                        setCurrentTab(item.name);
                                    }}
                                    trackClicks={item.trackClicks}
                                    trackElementShown={item.trackElementShown}
                                    url={item.url}
                                    modalType={item.modalType}
                                    counter={item.counter}
                                    subItems={item.subItems}
                                    onClose={onClose}
                                >
                                    {item.translations.name}
                                </SupernovaDropdownOption>
                            ))}
                        </SupernovaDropdownSection>
                    </div>
                    <div className="supernova-dropdown-menu-content-wrapper">
                        {subItems
                            ?.filter((item) => item.name === currentTab)
                            .map((item) => (
                                <div className="supernova-dropdown-menu-content" key={item.name}>
                                    <DropdownContent item={item} onClose={onClose} />
                                </div>
                            ))}
                    </div>
                </div>
            );
        },
        [currentTab, subItems]
    );

    return (
        <SupernovaNaviItem visibleOn={VISIBLE_ON}>
            <SupernovaDropdown
                activator={
                    <SupernovaActionLink
                        icon={<BarsOutlinedSize24 />}
                        name={name}
                        analytics={analytics}
                        trackClicks={trackClicks}
                        data-qa={`mainmenu_${name}`}
                    />
                }
                anchorFullHeight
                arrowBreakpoints={ARROW_BREAKPOINTS}
                fullWidthBreakpoints={FULL_WIDTH_BREAKPOINTS}
                placement={Placement.BottomEnd}
                render={render}
            />
        </SupernovaNaviItem>
    );
};

export default DropdownMenu;
