import { useState, useEffect } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const INFO_NAME = 'vacancy_saved_search_info';

export const hideSavedSearchInfo = (): void => {
    Notices.markAsViewed(INFO_NAME);
};

const TrlKeys = {
    info: 'search.vacancy.savedSearch.info',
    infoOk: 'search.vacancy.savedSearch.info.ok',
};

const SavedSearchInfo: TranslatedComponent<{ forceClose?: boolean }> = ({ trls, children, forceClose }) => {
    const initIsShowInfo = useSelector(({ infoTip }) => infoTip.name === INFO_NAME);

    const [isShowInfo, setShowInfo] = useState(initIsShowInfo);

    const hideInfo = () => {
        setShowInfo(false);
        hideSavedSearchInfo();
    };

    useEffect(() => {
        if (forceClose) {
            setShowInfo(false);
        }
    }, [forceClose]);

    return (
        <MagritteInfotip
            name={INFO_NAME}
            placement="bottom-right"
            visible={isShowInfo}
            autoHide={false}
            closeByClickOutside={false}
            showBannerOnMobile={false}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            render={({ activatorRef }) => <span ref={activatorRef}>{children}</span>}
            useDefaultHost
            buttons={
                <Button onClick={hideInfo} style="contrast" mode="primary" size="small">
                    {trls[TrlKeys.infoOk]}
                </Button>
            }
        >
            {trls[TrlKeys.info]}
        </MagritteInfotip>
    );
};

export default translation(SavedSearchInfo);
