import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card, HSpacingContainer } from '@hh.ru/magritte-ui';
import { WhatsappBrandSize24, TelegramBrandSize24, ViberBrandSize24 } from '@hh.ru/magritte-ui/service';

import { useSelector } from 'src/hooks/useSelector';
import { MessengerType } from 'src/models/messengers';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import styles from './help-modal-messengers.less';

const MESSENGERS_ICONS = {
    [MessengerType.Telegram]: <TelegramBrandSize24 />,
    [MessengerType.Whatsapp]: <WhatsappBrandSize24 />,
    [MessengerType.Viber]: <ViberBrandSize24 />,
};

const MESSENGERS_CARD_STYLES = {
    [MessengerType.Telegram]: 'extra-1',
    [MessengerType.Whatsapp]: 'positive',
    [MessengerType.Viber]: 'extra-5',
} as const;

const MESSENGERS = [
    {
        href: 'https://telegram.me/hh_applicant_bot',
        messenger: MessengerType.Telegram,
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: MessengerType.Viber,
    },
];

const EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/hh_ru_bot',
        messenger: MessengerType.Telegram,
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: MessengerType.Viber,
    },
];

const NEW_EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/reg_hh_bot',
        messenger: MessengerType.Telegram,
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: MessengerType.Viber,
    },
];

const logMessengerClick = (_: string, messenger: MessengerType) => {
    Analytics.sendHHEventButtonClick('menu_support_item', { item: messenger.toLowerCase() });
};

const HelpModalMessengers: FC = () => {
    const employerStatus = useSelector(({ employerStatus }) => employerStatus);
    const messengers = useSelector(({ messengers }) => messengers);
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    let messengersToRender;
    if (userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer) {
        messengersToRender = EMPLOYER_MESSENGERS;
        if (employerStatus.state === 'NEW') {
            const whatsapp = messengers.find(({ messengerType }) => messengerType === MessengerType.Whatsapp);
            if (whatsapp) {
                messengersToRender = [
                    ...NEW_EMPLOYER_MESSENGERS,
                    {
                        href: `https://wa.me/${whatsapp.country}${whatsapp.city}${whatsapp.number}`,
                        messenger: MessengerType.Whatsapp,
                    },
                ];
            }
        }
    } else {
        messengersToRender = MESSENGERS;
    }

    return (
        <HSpacingContainer default={12}>
            {messengersToRender.map(({ href, messenger }) => {
                return (
                    <Card
                        key={messenger}
                        Element="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => logMessengerClick(userType, messenger)}
                        actionCard
                        style={MESSENGERS_CARD_STYLES[messenger]}
                        borderRadius={12}
                        padding={16}
                        stretched
                        aria-label={messenger}
                    >
                        <div className={styles.helpModalMessenger}>{MESSENGERS_ICONS[messenger]}</div>
                    </Card>
                );
            })}
        </HSpacingContainer>
    );
};

export default HelpModalMessengers;
