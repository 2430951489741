import { FC } from 'react';

import headerGeoDefinitionSuggestButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_button_click';
import BlokoLink from 'bloko/blocks/link';

import { AreaDomain } from 'src/components/AreaSwitcher/areaSwitcherTypes';

interface CitiesListProps {
    domain: AreaDomain[];
}

const CitiesList: FC<CitiesListProps> = ({ domain }) => (
    <ul className="area-switcher-cities">
        {domain.map(
            (domain) =>
                !!domain && (
                    <li className="area-switcher-city" key={domain.areaId}>
                        {!domain.isCurrentHost ? (
                            <BlokoLink
                                onClick={() => {
                                    if (typeof domain.areaId === 'number') {
                                        headerGeoDefinitionSuggestButtonClick({
                                            selectedArea: domain.areaId.toString(),
                                        });
                                    }
                                }}
                                href={domain.href}
                                disableVisited
                            >
                                {domain.name}
                            </BlokoLink>
                        ) : (
                            <>{domain.name}</>
                        )}
                    </li>
                )
        )}
    </ul>
);

export default CitiesList;
