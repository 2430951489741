import { useRef } from 'react';

import Button from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import AutoInviteOnboardingModal from 'src/components/AutoInviteOnboardingModal';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

const TrlKeys = {
    header: 'employer.candidates.autoinvite.notification.enabled.header',
    text: 'employer.candidates.autoinvite.notification.enabled.text',
    button: 'employer.candidates.autoinvite.notification.enabled.button',
};

const AutoInviteEnabled: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const closeNotificationRef = useRef<VoidFunction>(null);
    const [modalVisibility, showModal, hideModal] = useOnOffState(false);

    return (
        <>
            <SynchronizedNotification
                kind={NotificationKind.Ok}
                notification={notification}
                closeFuncRef={closeNotificationRef}
                onClose={onClose}
                analyticsElementName="auto-invite-enabled"
                markAsViewedEvent={notification.id === undefined ? MarkAsViewedEvent.None : MarkAsViewedEvent.OnShow}
            >
                <NotificationHeading>{trls[TrlKeys.header]}</NotificationHeading>
                <Text>{trls[TrlKeys.text]}</Text>
                <NotificationFooter>
                    <Button
                        onClick={() => {
                            closeNotificationRef.current?.();
                            showModal();
                        }}
                    >
                        {trls[TrlKeys.button]}
                    </Button>
                </NotificationFooter>
            </SynchronizedNotification>
            <AutoInviteOnboardingModal modalVisibility={modalVisibility} hideModal={hideModal} />
        </>
    );
};

export default translation(AutoInviteEnabled);
