export const extractRequestUrlFromForm = (form: HTMLFormElement): string => {
    const formData = new FormData(form);
    const queryParams = new URLSearchParams();
    for (const [key, value] of formData.entries()) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        queryParams.append(key, value.toString());
    }

    return `${form.action}?${queryParams.toString()}`;
};
