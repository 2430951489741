export enum FoundOnHHVariants {
    FoundOnHH = 'found_on_hh',
    NotFoundOnHH = 'not_found_on_hh',
}

export enum ReasonVariants {
    WithoutResponse = 'got_message',
    ContactedByMyself = 'message_myself',
    Other = 'other',
}

export enum ModalStep {
    FoundOnHHStep = 'found-on-hh-step',
    ChooseOfferStep = 'choose-offer-step',
    ChooseReasonStep = 'choose-reason-step',
    WriteWhatConfused = 'write-what-confused',
}

export interface Offer {
    employerId: number;
    vacancyId: number;
    resumeId: number;
}
