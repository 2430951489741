import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSelector } from 'src/hooks/useSelector';
import { EmployerBillingInfo } from 'src/models/employerBillingInfo';
import fetcher from 'src/utils/fetcher';

const BILLING_INFO_API_PATH = '/shards/employer/billing_info';

declare global {
    interface FetcherGetApi {
        [BILLING_INFO_API_PATH]: {
            queryParams: void;
            response: EmployerBillingInfo;
        };
    }
}

const billingInfoAction = makeSetStoreField('employerBillingInfo');

const useEmployerBillingInfo = (): {
    billingInfo: EmployerBillingInfo;
    fetchEmployerBillingInfo: () => Promise<void>;
} => {
    const billingInfo = useSelector(({ employerBillingInfo }) => employerBillingInfo);
    const dispatch = useDispatch();

    const fetchData = useCallback(async () => {
        try {
            const data = await fetcher.get(BILLING_INFO_API_PATH);
            dispatch(billingInfoAction(data));
        } catch (e) {
            console.error(e);
        }
    }, [dispatch]);

    const fetchBillingInfo = useCallback(() => {
        if (!billingInfo?.employer) {
            try {
                void fetchData();
            } catch (e) {
                console.error(e);
            }
        }
    }, [billingInfo, fetchData]);

    useEffect(() => {
        fetchBillingInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingInfo]);

    return {
        billingInfo,
        fetchEmployerBillingInfo: fetchData,
    };
};

export default useEmployerBillingInfo;
