const bodyHasActiveOverlayClass = 'has-active-overlay';

// Ставит/снимает класс has-active-overlay у body
//
// Нужно использовать при показе или скрытии оверлеев
// располагающихся в правом нижнем углу для избежания перекрытия
//
// При наличии этого класса активные элементы находящиеся
// в правом нижнем углу скрываются
//
// Если нужно расположить активный элемент в правом нижнем углу
// и избежать пересечений с оверлеями базирующимися там же,
// то следеут скрывать его при наличии у родителя этого класса
//
// Например:
// .my-awesome-activator {
//     .has-active-overlay & {
//         display: none;
//     }
// }
//

export default {
    overlayOpened: (): void => {
        if (!document.body.classList.contains(bodyHasActiveOverlayClass)) {
            document.body.classList.add(bodyHasActiveOverlayClass);
        }
    },
    overlayClosed: (): void => {
        if (document.body.classList.contains(bodyHasActiveOverlayClass)) {
            document.body.classList.remove(bodyHasActiveOverlayClass);
        }
    },
};
