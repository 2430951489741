import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { DisableReason } from 'src/models/autoInvite';
import { ComplexUserNotification } from 'src/models/userNotifications';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    header: 'employer.candidates.autoinvite.notification.header',
    vacancy: 'employer.candidates.autoinvite.notification.vacancy',
    disableReason: {
        noServices: 'employer.candidates.autoinvite.notification.disableReason.noServices',
        noQuotas: 'employer.candidates.autoinvite.notification.disableReason.noQuotas',
        finished: 'employer.candidates.autoinvite.notification.disableReason.finished',
    },
};
const AutoInviteDisable: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const getDisableReasonTrl = (disableReason?: DisableReason) => {
        if (disableReason === DisableReason.NoAvailableService) {
            return trls[TrlKeys.disableReason.noServices];
        } else if (disableReason === DisableReason.QuotaExceeded) {
            return trls[TrlKeys.disableReason.noQuotas];
        } else if (disableReason === DisableReason.Finished) {
            return trls[TrlKeys.disableReason.finished];
        }
        return null;
    };

    return (
        <Notification iconKind={SupernovaUserNotificationsIconKind.Warning} {...notification}>
            <NotificationSpacer>{trls[TrlKeys.header]}</NotificationSpacer>

            <Text style="secondary" typography="label-2-regular">
                <NotificationSpacer>
                    {getDisableReasonTrl(notification.params.disableReason as DisableReason)}
                </NotificationSpacer>
                {trls[TrlKeys.vacancy]}
                {NON_BREAKING_SPACE}
                <NotificationLink
                    to={`/employer/vacancyresponses/autoinvite?vacancyId=${notification.params.vacancyId}`}
                >
                    {notification.params.vacancyTitle}
                </NotificationLink>
            </Text>
        </Notification>
    );
};

export default translation(AutoInviteDisable);
