import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CommentsOwner } from 'src/components/ResumeComments/types';

export interface ResumeComment {
    id: number;
    body: string;
    accessType: string;
    userName: string;
    creationTime: number;
    editable: boolean;
    complaintType?: null;
    creationDate?: number;
    creationTimeDetails?: {
        day: string;
        hour: string;
        minute: string;
        month: string;
        year: string;
    };
    userId?: CommentsOwner;
}

export interface ResumeCommentsList {
    data: ResumeComment[];
    maxOffset: number;
    total: number;
    ts: number;
}

export enum Action {
    Fetching = 'resumeComments/Fetching',
    Fetched = 'resumeComments/Fetched',
    Prepend = 'resumeComments/prepend',
    AddComments = 'resumeComments/addComments',
    Edit = 'resumeComments/edit',
    Clear = 'resumeComments/clear',
}

export enum Status {
    Initial = 'INITIAL',
    Fetching = 'FETCHING',
    Complete = 'COMPLETE',
    Dirty = 'DIRTY',
}

export interface ResumeCommentState {
    items: Record<number, ResumeComment>;
    status: Status;
    total?: number;
    maxOffset?: number;
}

interface PayloadTypes {
    [Action.Fetching]: void;
    [Action.Fetched]: { data: ResumeComment[] } | void;
    [Action.Prepend]: ResumeComment;
    [Action.AddComments]: { items: Record<number, ResumeComment> };
    [Action.Edit]: ResumeComment;
    [Action.Clear]: void;
}

const actionCreatorHelper = ActionCreatorHelper<PayloadTypes>();

export const startFetch = actionCreatorHelper(Action.Fetching);
export const loadComments = actionCreatorHelper(Action.Fetched);
export const addComment = actionCreatorHelper(Action.Prepend);
export const addComments = actionCreatorHelper(Action.AddComments);
export const editComment = actionCreatorHelper(Action.Edit);
export const clearComments = actionCreatorHelper(Action.Clear);

const INITIAL_STATE = {
    status: Status.Initial,
    items: {},
};

export default createReducer<ResumeCommentState, PayloadTypes>(INITIAL_STATE, {
    [Action.Fetching]: (state) => ({
        ...state,
        status: Status.Fetching,
    }),
    [Action.Fetched]: (state, action) => {
        const resumeComments = action.payload?.data || [];

        return {
            ...state,
            items: {
                ...state.items,
                ...resumeComments.reduce(
                    (result, item) => {
                        result[item.id] = item;
                        return result;
                    },
                    {} as Record<number, ResumeComment>
                ),
            },
            status: Status.Complete,
        };
    },
    [Action.Clear]: () => ({ ...INITIAL_STATE }),
    [Action.Prepend]: (state, action) => ({
        ...state,
        items: {
            ...state.items,
            [action.payload.id]: action.payload,
        },
    }),
    [Action.AddComments]: (state, action) => ({
        ...state,
        items: {
            ...state.items,
            ...action.payload.items,
        },
    }),
    [Action.Edit]: (state, action) => ({
        ...state,
        items: {
            ...state.items,
            [action.payload.id]: action.payload,
        },
    }),
});
