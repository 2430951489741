import { useCallback } from 'react';

import { UserType } from 'src/models/userType';

import { useExperimentGroupCheckFunction } from 'src/hooks/useExperimentCheckFunction';
import { useSelector } from 'src/hooks/useSelector';

const useSendCheckGeoSwitcherRedesignExp = (): (() => void) => {
    const sendExpCheck = useExperimentGroupCheckFunction();
    const userType = useSelector(({ userType }) => userType);

    return useCallback(() => {
        const activeCheckName =
            userType === UserType.Employer ? 'redesign_employer_geo' : 'redesign_applicant_anon_geo';
        sendExpCheck(activeCheckName);
    }, [sendExpCheck, userType]);
};

export default useSendCheckGeoSwitcherRedesignExp;
