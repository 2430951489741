import { useRef } from 'react';

import Link from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'vacancy.call.tracking.tele2owners.warning.title',
    text: 'vacancy.call.tracking.tele2owners.warning.notification',
    link: 'vacancy.call.tracking.tele2owners.warning.link',
};

const CallTracking: TranslatedComponent<ComplexNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <SynchronizedNotification
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
        >
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <p>{trls[TrlKeys.text]}</p>
            <NotificationFooter>
                <Link
                    href={`/article/28992?from=notification&hhtmFrom=${hhtmSource}&hhtmFromLabel=user_notification`}
                    target="_blank"
                    onClick={() => closeNotificationRef.current?.()}
                >
                    {trls[TrlKeys.link]}
                </Link>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(CallTracking);
