import { FC } from 'react';

import { Skeleton } from '@hh.ru/magritte-ui';

import styles from 'src/components/SupernovaMainMenu/EmployerProfile/styles.less';

const SkeletonInformer: FC = () => {
    return <Skeleton loading borderRadius={16} additionalClass={styles.informersItemSkeleton} />;
};

export default SkeletonInformer;
