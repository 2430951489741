import { Ref } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { NavItem } from 'src/models/supernovaNaviMenu';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const NEW_VACANCY_CREATE_BUTTON_EMPLOYER_MENU = 'new_vacancy_create_button_employer_menu';

const TrlKeys = {
    onboardingText: 'vacancyCreate.menu.onboardingTooltip',
};

const VacancyCreate: TranslatedComponent<NavItem> = ({
    active,
    name,
    translations,
    trackClicks,
    url,
    analytics,
    trls,
}) => {
    const isZp = useIsZarplataPlatform();

    return (
        <SupernovaNaviItem active={active} isButton visibleOn={VISIBLE_ON}>
            <MagritteInfotip
                name={NEW_VACANCY_CREATE_BUTTON_EMPLOYER_MENU}
                placement="bottom-center"
                showClose
                visible
                maxWidth={320}
                showBannerOnMobile={false}
                guardDistanceToActivator={35}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                useDefaultHost
                closeByClickOutside={false}
                render={({ activatorRef }) => (
                    <SupernovaButton
                        isMagritte
                        magritteStyle={isZp ? 'neutral' : 'contrast'}
                        ref={activatorRef as Ref<HTMLButtonElement>}
                        magritteMode="secondary"
                        active={active}
                        data-qa={`mainmenu_${name}`}
                        analytics={analytics}
                        name={name}
                        trackClicks={trackClicks}
                        url={url}
                        magritteIcon={<PlusOutlinedSize16 />}
                    >
                        {translations.name}
                    </SupernovaButton>
                )}
            >
                {trls[TrlKeys.onboardingText]}
            </MagritteInfotip>
        </SupernovaNaviItem>
    );
};

export default translation(VacancyCreate);
