import { useState } from 'react';

import Button, { ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChangeForm from 'src/components/Applicant/JobSearchStatus/ChangeForm';
import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { JobSearchStatus, JobSearchStatusForm } from 'src/models/applicantUserStatuses';

import styles from './applicant-job-search-status.less';

const TrlKeys = {
    heading: 'notification.applicant.job.search.status.heading',
    save: 'notification.applicant.job.search.status.save',
};

const CHANGE_FORM_ID = JobSearchStatusForm.RenewNotification;

const Content: TranslatedComponent<{ onClose: VoidFunction }> = ({ onClose, trls }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const { addNotification } = useNotification();

    return (
        <div data-qa="applicant-job-search-status-notification">
            <NotificationHeading>{trls[TrlKeys.heading]}</NotificationHeading>
            <div className={styles.wrapper}>
                <VSpacing base={2} />
                <ChangeForm
                    setDisabled={setIsDisabled}
                    onSuccess={(newStatus) => {
                        if (newStatus !== JobSearchStatus.AcceptedJobOffer) {
                            addNotification(applicantJobSearchStatusSuccess);
                        }
                        onClose();
                    }}
                    isCompact
                    formId={CHANGE_FORM_ID}
                />
            </div>
            <NotificationFooter>
                <Button
                    scale={ButtonScale.Small}
                    kind={ButtonKind.Primary}
                    data-qa="applicant-job-search-status-notification-confirm"
                    type={ButtonType.Submit}
                    form={CHANGE_FORM_ID}
                    disabled={isDisabled}
                >
                    {trls[TrlKeys.save]}
                </Button>
            </NotificationFooter>
        </div>
    );
};

export default translation(Content);
