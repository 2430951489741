import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface EmployerAutomationStatistics {
    employerId?: number;
    employerManagerId?: number;
    dataPeriodDays: number;
    coreData: {
        responses: number;
        invitations: number;
        notProcessedNotAutomatedResponses: number;
        autoActionsPassed: number;
        autoActionsFailed: number;
        autoInvitations: number;
        activeVacancyCount: number;
        activeVacancyOkForAutoInvitationCount: number;
        activeAutoActionVacancyCount: number;
        activeAutoInvitationVacancyCount: number;
    };
    processedData: {
        totalPossibleEconomyMinutes: number;
        totalPossibleEconomyMinutesPerDay: number;
        autoActionPossibleEconomyMinutes: number;
        autoActionPossibleEconomyMinutesPerDay: number;
        autoActionPossibleEconomyMinutesByRatioApproximation: number;
        autoInvitationPossibleEconomyMinutes: number;
        autoInvitationPossibleEconomyMinutesPerDay: number;
        totalCurrentEconomyMinutes: number;
        totalCurrentEconomyMinutesPerDay: number;
        autoActionCurrentEconomyMinutes: number;
        autoActionCurrentEconomyMinutesPerDay: number;
        autoInvitationCurrentEconomyMinutes: number;
        autoInvitationCurrentEconomyMinutesPerDay: number;
        autoActionEconomyMinutesLeft: number;
        autoActionSavedCandidateCount: number;
        autoInvitationEconomyMinutesLeft: number;
        autoInvitationEconomyRubLeft: number;
        notAutomatedVacancyOkForAutoInvitationCount: number;
    };
}

export default autoGeneratedReducer<EmployerAutomationStatistics>(null);
