import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    description: 'youth.special.project.supernova.notification.description',
    link: 'youth.special.project.supernova.notification.link',
};

const YouthSpecialProject: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.New}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>
                <p>{trls[TrlKeys.description]}</p>
            </NotificationSpacer>
            <NotificationLink
                target="_blank"
                to="/shards/notifications/youth_special_project_visit"
                onClick={() => {
                    Analytics.sendHHEventButtonClick(`youth_special_project_link_supernova`);
                }}
            >
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(YouthSpecialProject);
