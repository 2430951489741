import { useRef } from 'react';

import Link from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'applicant.feedback.about.vacancy.notification.title',
    text: 'applicant.feedback.about.vacancy.notification',
    link: 'applicant.feedback.about.vacancy.notification.link',
};

const ApplicantFeedbackAboutVacancy: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <SynchronizedNotification
            kind={NotificationKind.Ok}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
        >
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <p>{trls[TrlKeys.text]}</p>
            <NotificationFooter>
                <Link
                    href={`/applicant/negotiations?from=notification&hhtmFrom=${hhtmSource}&hhtmFromLabel=user_notification`}
                    target="_blank"
                    onClick={() => closeNotificationRef.current?.()}
                >
                    {trls[TrlKeys.link]}
                </Link>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(ApplicantFeedbackAboutVacancy);
