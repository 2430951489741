import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'employer.reviews.notify.employer.can.enable.supernova.notification',
    link: 'employer.reviews.notify.employer.can.enable.supernova.notification.link',
};

const EmployerReviewCanEnable: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification
        iconKind={SupernovaUserNotificationsIconKind.Info}
        dataQa={DataQaInvariants.EmployerReviewCanEnable}
        {...notification}
    >
        <NotificationText multiline>{trls[TrlKeys.content]}</NotificationText>
        <NotificationLink to="/employer/reviews-settings">{trls[TrlKeys.link]}</NotificationLink>
    </Notification>
);

export default translation(EmployerReviewCanEnable);
