import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { DateIsoString } from 'bloko/blocks/calendar/datesHelper';

export enum ApplicantRsvCompetencyLevelType {
    Basic = 'basic',
    Intermediate = 'intermediate',
    Advanced = 'advanced',
}

export enum ApplicantRsvCompetencyLevelUuid {
    Basic = '68b19575-4f6a-47a7-be54-7443d267fad9',
    Intermediate = '922cfc61-00d0-4d32-a1d1-d56823d0a9c1',
    Advanced = '91d4fc28-d88d-47ac-93f5-04263e4e2cc6',
}

export interface ApplicantRsvCompetencyDto {
    competencyUuid: string;
    competencyName: string;
    competencyDescription: string;
    competencyLevelUuid: ApplicantRsvCompetencyLevelUuid;
    competencyLevelName: string;
    competencyWithLevelDescription: string;
}

export interface ApplicantRsvCompetencyListDto {
    updatedAt: DateIsoString;
    visible: boolean;
    competenciesData: ApplicantRsvCompetencyDto[];
}

export const applicantRsvCompetencyLevelMap = {
    [ApplicantRsvCompetencyLevelUuid.Basic]: ApplicantRsvCompetencyLevelType.Basic,
    [ApplicantRsvCompetencyLevelUuid.Intermediate]: ApplicantRsvCompetencyLevelType.Intermediate,
    [ApplicantRsvCompetencyLevelUuid.Advanced]: ApplicantRsvCompetencyLevelType.Advanced,
} as const;

interface ResumeShort {
    creationTime: number;
    firstName: string;
    lastName: string;
    hash: string;
    hhid: number;
    id: number;
    lastChangeTime: number;
    lastEditTime: number;
    markService: boolean;
    moderationTime: string;
    permission: string;
    renewalService: boolean;
    status: string;
    userId: number;
    title: string;
}

interface VacancyShort {
    name: string;
    id: number;
}

export interface RsvCompetencyData {
    list: ApplicantRsvCompetencyListDto;
    resumeShort: ResumeShort;
    vacancy?: VacancyShort;
}

export const applicantRsvCompetencyLevelOrder: Record<string, number> = {
    [ApplicantRsvCompetencyLevelUuid.Basic]: 0,
    [ApplicantRsvCompetencyLevelUuid.Intermediate]: 1,
    [ApplicantRsvCompetencyLevelUuid.Advanced]: 2,
};

export default autoGeneratedReducer<RsvCompetencyData>(null);
