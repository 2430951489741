import { FC, Fragment, useCallback } from 'react';
import classNames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';

import PosSelectOption from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelectOption';
import {
    EXTERNAL_OPTIONS,
    WORKPLACE_OPTIONS,
    includesSome,
    includesEvery,
} from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { PosOption } from 'src/models/search/resume/searchQueries';

import styles from './styles.less';

interface PosSelectProps {
    selectedValues: PosOption[];
    setSelectedValues: (value: PosOption[]) => void;
    isMagritte?: boolean;
}

const PosSelect: FC<PosSelectProps> = ({ selectedValues, setSelectedValues, isMagritte }) => {
    const isZarplataPlatform = useIsZarplataPlatform();
    const workPlaceOptionsFiltered = isZarplataPlatform
        ? WORKPLACE_OPTIONS.filter((item) => item !== PosOption.WorkplaceOrganization)
        : [...WORKPLACE_OPTIONS];

    const handleChange = useCallback(
        (option: PosOption, checked: boolean) => {
            let newValues: PosOption[];
            if (checked) {
                // было выбрано "везде", а теперь выбрали что-то другое - возвращаем только выбранный пункт
                if (selectedValues.includes(PosOption.FullText)) {
                    newValues = [option];
                } else {
                    newValues = [...selectedValues, option];
                }
                if (includesEvery(newValues, WORKPLACE_OPTIONS)) {
                    // указали все три чайлда от "в опыте работы" - снимаем галки с чайлдов, ставим только паренту
                    newValues = [
                        ...newValues.filter((value) => !WORKPLACE_OPTIONS.includes(value)),
                        PosOption.Workplaces,
                    ];
                } else if (option === PosOption.Workplaces) {
                    // указали "в опыте работы" - снимаем галки с чайлдов
                    newValues = [...newValues.filter((value) => !WORKPLACE_OPTIONS.includes(value))];
                }
                // указали все пункты или выбрали "Везде" - ставим галочку только на "Везде"
                if (includesEvery(newValues, EXTERNAL_OPTIONS) || option === PosOption.FullText) {
                    newValues = [PosOption.FullText];
                }
            } else if (WORKPLACE_OPTIONS.includes(option) && selectedValues.includes(PosOption.Workplaces)) {
                // был выбран вариант "в опыте работы", сняли галочку у его чайлда - снимаем с "в опыте работы", проставляем всем чайлдам кроме выбранного
                newValues = [
                    ...selectedValues.filter((value) => value !== PosOption.Workplaces),
                    ...WORKPLACE_OPTIONS.filter((value) => value !== option),
                ];
            } else if (selectedValues.length === 1) {
                // была всего одна галочка и мы ее отжали - ставим галочку на "Везде"
                newValues = [PosOption.FullText];
            } else {
                newValues = selectedValues.filter((value) => value !== option);
            }
            setSelectedValues(newValues);
        },
        [selectedValues, setSelectedValues]
    );

    return (
        <>
            {[PosOption.FullText, ...EXTERNAL_OPTIONS].map((option) => (
                <Fragment key={option}>
                    <PosSelectOption
                        option={option}
                        checked={selectedValues.includes(option)}
                        indeterminate={
                            option === PosOption.Workplaces && includesSome(selectedValues, WORKPLACE_OPTIONS)
                        }
                        handleChange={handleChange}
                        isMagritte={isMagritte}
                    />
                    {option === PosOption.Workplaces &&
                        workPlaceOptionsFiltered.map((option) => (
                            <div
                                className={classNames(isMagritte ? styles.secondLevel : styles.blokoSecondLevel)}
                                key={option}
                            >
                                <PosSelectOption
                                    option={option}
                                    checked={
                                        selectedValues.includes(option) || selectedValues.includes(PosOption.Workplaces)
                                    }
                                    handleChange={handleChange}
                                    isMagritte={isMagritte}
                                />
                            </div>
                        ))}
                    {!isMagritte && option === PosOption.FullText && (
                        <>
                            <div className="horizontal-divider" />
                            <VSpacing default={16} />
                        </>
                    )}
                </Fragment>
            ))}
        </>
    );
};

export default PosSelect;
