import { FC } from 'react';

import BlokoLink from 'bloko/blocks/link';

import BrandingTriggerNotification from 'src/components/BrandingTriggerNotification';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';

const BrandingTriggerUserNotification: FC<ComplexNotificationComponentProps> = ({ onClose, ...notification }) => (
    <BrandingTriggerNotification
        notificationId={notification.id}
        templateKey={notification.templateKey}
        place="screen"
        linkParams={{ hhtmFromLabel: 'notification_prolongation' }}
    >
        {({ Action, Content, sendOnRemoveEvent, acknowledgeNotification }) => (
            <SynchronizedNotification
                notification={notification}
                onClose={() => {
                    sendOnRemoveEvent?.();
                    acknowledgeNotification();
                    onClose();
                }}
            >
                <Content />
                <BlokoLink Element={Action} disableVisited />
            </SynchronizedNotification>
        )}
    </BrandingTriggerNotification>
);

export default BrandingTriggerUserNotification;
