import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SalaryStatItem {
    name?: string;
    num: number[];
}

export interface SalaryStat {
    grouping: string;
    items: SalaryStatItem[];
}

export default autoGeneratedReducer<SalaryStat>(null);
