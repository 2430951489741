import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import DriverLicenseOption from 'src/models/driverLicense.types';
import { EducationLevel, Gender } from 'src/models/resume/resumeCommon.types';
import { SearchPeriodOption, RelocationOption } from 'src/models/resumeSearchCriteria';
import { LogicOption, ExpPeriodOption, ExpCompanySizeOption, PosOption } from 'src/models/search/resume/searchQueries';
import {
    CriteriaKey,
    OrderByOption,
    ExperienceOption,
    FilterExpPeriodOption,
} from 'src/models/search/searchCriteria.types';

export interface ResumeSearchDictionaries {
    [CriteriaKey.SearchPeriod]: SearchPeriodOption[];
    [CriteriaKey.OrderBy]: OrderByOption[];
    [CriteriaKey.Logic]: LogicOption[];
    [CriteriaKey.Pos]: PosOption[];
    [CriteriaKey.ExpPeriod]: ExpPeriodOption[];
    [CriteriaKey.ExpCompanySize]: ExpCompanySizeOption[];
    [CriteriaKey.ExpIndustry]: string[];
    [CriteriaKey.FilterExpPeriodForIndustry]: FilterExpPeriodOption[];
    [CriteriaKey.Relocation]: RelocationOption[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.Experience]: ExperienceOption[];
    [CriteriaKey.Education]: EducationLevel[];
    [CriteriaKey.Gender]: Gender[];
    [CriteriaKey.Schedule]: ScheduleCamelCase[];
    [CriteriaKey.DriverLicenseTypes]: DriverLicenseOption[];
    [CriteriaKey.JobSearchStatus]: JobSearchStatus[];
    searchDefaults?: {
        [CriteriaKey.ExpCompanySize]: ExpCompanySizeOption;
        [CriteriaKey.ExpIndustry]: string;
        [CriteriaKey.ExpPeriod]: ExpPeriodOption;
        [CriteriaKey.FilterExpPeriodForIndustry]: FilterExpPeriodOption;
        [CriteriaKey.Gender]: Gender;
        [CriteriaKey.Logic]: LogicOption;
        [CriteriaKey.Pos]: PosOption;
    };
}

export default autoGeneratedReducer<ResumeSearchDictionaries>({
    [CriteriaKey.SearchPeriod]: [],
    [CriteriaKey.OrderBy]: [],
    [CriteriaKey.Logic]: [],
    [CriteriaKey.Pos]: [],
    [CriteriaKey.ExpPeriod]: [],
    [CriteriaKey.ExpCompanySize]: [],
    [CriteriaKey.ExpIndustry]: [],
    [CriteriaKey.FilterExpPeriodForIndustry]: [],
    [CriteriaKey.Relocation]: [],
    [CriteriaKey.Experience]: [],
    [CriteriaKey.Employment]: [],
    [CriteriaKey.Education]: [],
    [CriteriaKey.Gender]: [],
    [CriteriaKey.Schedule]: [],
    [CriteriaKey.DriverLicenseTypes]: [],
    [CriteriaKey.JobSearchStatus]: [],
});
