import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

interface PrintVersion {
    followLetter?: boolean;
    comments?: boolean;
    history?: boolean;
    isPrintVersion?: boolean;
    industries?: boolean;
}

const INITIAL_STATE: PrintVersion = {};
const CHANGE_VIEW = 'printVersion/changeView';

interface PayloadTypes {
    [CHANGE_VIEW]: PrintVersion;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const changeView = actionCreator(CHANGE_VIEW);

export default createReducer<PrintVersion, PayloadTypes>(INITIAL_STATE, {
    [CHANGE_VIEW]: (state, action) => ({ ...state, ...action.payload }),
});
