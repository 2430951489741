import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import { isObject } from 'Utils/Utils';

export interface StateItem {
    data: string;
    invalidateTs: number;
    additionalParams?: Record<string, string>;
}
export type State = StateItem[];

const invalidateState = (state: State): State => {
    const currentTs = +new Date();

    return state.filter(({ invalidateTs }) => currentTs < invalidateTs);
};

const isStateValid = (state: unknown): state is State => {
    return Boolean(
        state &&
            Array.isArray(state) &&
            state.every((item) => isObject(item) && 'invalidateTs' in item && 'data' in item)
    );
};

const getTemporaryStorageState = (storageField: string): State => {
    let state: State;

    try {
        state = JSON.parse(LocalStorageWrapper.getItem(storageField) ?? '') as State;

        if (!isStateValid(state)) {
            state = [];
        }

        state = invalidateState(state);
    } catch (_) {
        state = [];
    }

    return state;
};

const saveToTemporaryStorage = (storageField: string, state: State): void =>
    LocalStorageWrapper.setItem(storageField, JSON.stringify(state));

export const replaceTemporaryStorageData = (
    storageField: string,
    itemLifeTime: number,
    replaceItem: StateItem['data'],
    additionalParams?: StateItem['additionalParams']
): void => {
    const state = invalidateState(getTemporaryStorageState(storageField));

    if (replaceItem) {
        const newState = state.filter((stateItem) => stateItem.data !== replaceItem);
        newState.push({
            invalidateTs: +new Date() + itemLifeTime,
            data: replaceItem,
            ...(additionalParams && { additionalParams }),
        });

        saveToTemporaryStorage(storageField, newState);
    }
};

export const checkIsItemExistsInTemporaryStorage = (
    storageField: string,
    replaceItem: StateItem['data'],
    removeIfExists = false
): StateItem | undefined => {
    const state = invalidateState(getTemporaryStorageState(storageField));

    const itemIndex = state.findIndex((stateItem) => stateItem.data === replaceItem);
    const item = itemIndex >= 0 ? state[itemIndex] : undefined;

    if (removeIfExists && item) {
        state.splice(itemIndex, 1);
    }

    saveToTemporaryStorage(storageField, state);

    return item;
};

export const checkIsItemExistsAndRemoveItInTemporaryStorage = (
    storageField: string,
    replaceItem: StateItem['data']
): StateItem | undefined => {
    return checkIsItemExistsInTemporaryStorage(storageField, replaceItem, true);
};
