import createReducer from '@hh.ru/redux-create-reducer';

import { BillType } from 'src/models/billType';
import CartStatus from 'src/models/cartStatus';
import { CurrencyType } from 'src/models/currencies.types';
import { PaymentType } from 'src/models/paymentType';

export enum ServiceActivationType {
    Manual = 'MANUAL_ACTIVATION',
    Auto = 'AUTO_ACTIVATION',
}

export interface Service {
    // Будет string, ждем бек
    areaIds: (string | number)[];
    code: string;
    name: string;
    period: number;
    // Будет string, ждем бек
    profRoleGroupIds?: (string | number)[];
    activationTime?: string;
    currentActivationType?: ServiceActivationType;
}

export interface ActivationInfo {
    priceValidUntilDate: string;
    showPriceChangingNotification: boolean;
}

export interface DeferredCart {
    agreementName: string;
    agreementNumber: string;
    billFilePdfUrl?: string;
    billUid?: string;
    blockedAmount: number;
    cartId: string;
    cost: number;
    creationTime: number;
    currency: CurrencyType;
    payerName: string;
    service: Service[];
    status: CartStatus;
    billId: number;
    paymentType: PaymentType;
    billType: BillType;
    isAssistLinkAvailable: boolean;
    activationInfo?: ActivationInfo;
}

export enum EmployerCartsNotificationKind {
    Activate = 'Activate',
    Purchase = 'Purchase',
}

interface EmployerCartsState {
    selectedCartId?: string;
    canBuyProducts: boolean;
    carts: DeferredCart[];
    employerId: string;
    notificationInfo: {
        enabled: boolean;
        expirationDate?: string;
        kind?: EmployerCartsNotificationKind;
    };
}

type EmployerCartsPartialType = Partial<EmployerCartsState>;

interface UpdateEmployerCartsAction {
    type: typeof UPDATE_EMPLOYER_CARTS_DATA;
    payload: EmployerCartsPartialType;
}

const UPDATE_EMPLOYER_CARTS_DATA = 'UPDATE_EMPLOYER_CARTS_DATA';

interface PayloadTypes {
    [UPDATE_EMPLOYER_CARTS_DATA]: EmployerCartsPartialType;
}

export const updateEmployerCarts = (newEmployerCartsData: EmployerCartsPartialType): UpdateEmployerCartsAction => ({
    type: UPDATE_EMPLOYER_CARTS_DATA,
    payload: newEmployerCartsData,
});

export default createReducer<EmployerCartsState, PayloadTypes>(null, {
    [UPDATE_EMPLOYER_CARTS_DATA]: (state, { payload }) => {
        return { ...state, ...payload };
    },
});
