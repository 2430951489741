import formatDate from 'date-fns/format';

import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import checkPlaceholder from 'src/components/SupernovaMainMenu/UserNotifications/checkPlaceholder';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccesses]: {
        content: 'vacancy.auto.update.successes',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccess]: {
        content: 'vacancy.auto.update.success',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublications]: {
        content: 'vacancy.auto.update.no.publications.checks',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuota]: {
        content: 'vacancy.auto.update.no.quota.checks',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissions]: {
        content: 'vacancy.auto.update.no.permissions.checks',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermission]: {
        content: 'vacancy.auto.update.no.permissions.check',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotaFailure]: {
        content: 'vacancy.auto.update.no.quota.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotaFailures]: {
        content: 'vacancy.auto.update.no.quota.failures',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublicationFailure]: {
        content: 'vacancy.auto.update.no.publications.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublicationFailures]: {
        content: 'vacancy.auto.update.no.publications.failures',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissionFailure]: {
        content: 'vacancy.auto.update.no.permissions.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPermissionFailures]: {
        content: 'vacancy.auto.update.no.permissions.failures',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateTooMuchFailure]: {
        content: 'vacancy.auto.update.too.much.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateTooMuchFailures]: {
        content: 'vacancy.auto.update.too.much.failures',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoPublication]: {
        content: 'vacancy.auto.update.no.publications.check',
        statisticsOne: 'statistics.global.vacancy.one',
        statisticsSome: 'statistics.global.vacancy.some',
        statisticsMany: 'statistics.global.vacancy.many',
        statisticsZero: 'statistics.global.vacancy.zero',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoUpdateNoQuotas]: {
        content: 'vacancy.auto.update.no.quota.check',
    },
    vacancySome: 'vacancy.some',
    vacancyMany: 'vacancy.many',
    vacancyUpdateIn: 'vacancy.auto.update.in',
    vacancyBuy: 'vacancy.auto.update.buy',
    successesOne: 'vacancy.auto.update.successes.one',
    successesSome: 'vacancy.auto.update.successes.some',
    successesMany: 'vacancy.auto.update.successes.many',
};

type TemplateKeys = Exclude<
    keyof typeof TrlKeys,
    | 'vacancySome'
    | 'vacancyMany'
    | 'vacancyUpdateIn'
    | 'vacancyBuy'
    | 'successesOne'
    | 'successesSome'
    | 'successesMany'
>;

const VacancyAutoUpdate: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    const vacancyLink = (
        <NotificationLink to={`/vacancy/${notification.params.vacancy_id}`} target="_blank">
            {notification.params.vacancy_name}
        </NotificationLink>
    );

    if (
        key === ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccess ||
        key === ComplexUserNotificationsTemplateKey.VacancyAutoUpdateSuccesses
    ) {
        return (
            <Notification
                iconKind={SupernovaUserNotificationsIconKind.Success}
                dataQa={DataQaInvariants.Success}
                {...notification}
            >
                {formatToReactComponent(trls[TrlKeys[key].content], {
                    '{0}': vacancyLink,
                    '{1}': (
                        <ConversionNumber
                            formatValue={(value) => NumberFormatter.format(String(value))}
                            value={parseInt(notification.params.count, 10)}
                            one={trls[TrlKeys.successesOne]}
                            some={trls[TrlKeys.successesSome]}
                            many={trls[TrlKeys.successesMany]}
                            zero={trls[TrlKeys.successesMany]}
                        />
                    ),
                })}
            </Notification>
        );
    }

    const content = trls[TrlKeys[key].content];

    return (
        <Notification {...notification}>
            {formatToReactComponent(content, {
                '{0}': checkPlaceholder(content, '{0}') ? (
                    <ConversionNumber
                        formatValue={(value) => NumberFormatter.format(String(value))}
                        value={parseInt(notification.params.count, 10)}
                        one={trls[TrlKeys.vacancySome]}
                        some={trls[TrlKeys.vacancyMany]}
                        many={trls[TrlKeys.vacancyMany]}
                        zero={trls[TrlKeys.vacancyMany]}
                    />
                ) : (
                    ''
                ),
                '{1}': checkPlaceholder(content, '{1}') ? (
                    <>
                        {formatDate(new Date(notification.params.update_time_from), 'dd.MM.yyyy')}{' '}
                        {trls[TrlKeys.vacancyUpdateIn]}
                        {NON_BREAKING_SPACE}
                        {formatDate(new Date(notification.params.update_time_from), 'HH:mm')}–
                        {formatDate(new Date(notification.params.update_time_to), 'HH:mm')}
                    </>
                ) : (
                    ''
                ),
                '{2}': vacancyLink,
                '{3}': (
                    <NotificationLink to="/price/publications" target="_blank">
                        {trls[TrlKeys.vacancyBuy]}
                    </NotificationLink>
                ),
            })}
        </Notification>
    );
};

export default translation(VacancyAutoUpdate);
