import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const UPDATE_NOTE = 'UPDATE_NOTE';

interface PayloadTypes {
    [UPDATE_NOTE]: {
        name: string;
        value: string;
    };
}

export enum PutNoteType {
    Account = 'put_account_note',
    Browser = 'put_browser_note',
    User = 'put_user_note',
    Session = 'put_session_note',
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateNote = ActionCreator(UPDATE_NOTE);

export default createReducer<Record<string, string>, PayloadTypes>(
    {},
    {
        [UPDATE_NOTE]: (state, action) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
            };
        },
    }
);
