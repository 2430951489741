import { RProgress } from '@hh.ru/rprogress';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

const COLOR_RED = '#d9000e';
const COLOR_BLUE = '#7195af';

const LinearProgress = () => {
    const isZP = useIsZarplataPlatform();

    return <RProgress color={isZP ? COLOR_BLUE : COLOR_RED} />;
};

export default LinearProgress;
