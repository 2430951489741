import axios from 'HHC/Axios';

const markAsViewed = (name: string, data?: unknown): void => {
    axios.post('/notices/mark_as_viewed', { name, ...(data ? { data } : {}) }).catch(console.error);
};

export enum MarkAsViewedEvent {
    None = 'NONE',
    OnShow = 'ON_SHOW',
    OnHide = 'ON_HIDE',
}

export default { markAsViewed };
