import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ResponseType } from 'src/components/VacancyResponseLink';

export const VACANCY_CHAT_INFO_UPDATE = 'VACANCY_CHAT_INFO_UPDATE';
export const VACANCY_CHAT_INFO_SET_FETCHING = 'VACANCY_CHAT_INFO_SET_FETCHING';

export interface VacancyChatInfoType {
    type?: ResponseType;
    chatId?: number;
}

interface VacancyChatInfoUpdate {
    data: VacancyChatInfoType | null;
    vacancyId: number;
}
interface VacancyChatInfoSetFetching {
    isFetching: boolean;
    vacancyId: number;
}

interface PayloadTypes {
    [VACANCY_CHAT_INFO_UPDATE]: VacancyChatInfoUpdate;
    [VACANCY_CHAT_INFO_SET_FETCHING]: VacancyChatInfoSetFetching;
}
const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const vacancyChatInfoUpdate = actionHelper(VACANCY_CHAT_INFO_UPDATE);
export const vacancyChatInfoSetFetching = actionHelper(VACANCY_CHAT_INFO_SET_FETCHING);

export default createReducer<
    { [vacancyId: number]: { data: VacancyChatInfoType | null; isFetching: boolean } },
    PayloadTypes
>(
    {},
    {
        [VACANCY_CHAT_INFO_UPDATE]: (state, { payload }) => {
            return {
                ...state,
                [payload.vacancyId]: { data: payload.data, isFetching: false },
            };
        },

        [VACANCY_CHAT_INFO_SET_FETCHING]: (state, { payload }) => ({
            ...state,
            [payload.vacancyId]: {
                ...state[payload.vacancyId],
                isFetching: payload.isFetching,
            },
        }),
    }
);
