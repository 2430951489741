import { RefObject } from 'react';

import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './area-switcher-footer.less';

const TrlKeys = {
    text: 'area.switcher.search.footerMessage',
    link: 'area.switcher.search.footerLinkText',
};

interface AreaSwitcherFooterProps {
    searchInputRef: RefObject<HTMLInputElement>;
}

const Footer: TranslatedComponent<AreaSwitcherFooterProps> = ({ searchInputRef, trls }) => {
    const onClick = () => searchInputRef?.current?.focus();

    return (
        <div className={styles.areaSwitcherFooter}>
            <VSpacing base={10} />
            <Text>
                {trls[TrlKeys.text]}
                {NON_BREAKING_SPACE}
                <BlokoLink appearance={LinkAppearance.Pseudo} onClick={onClick}>
                    {trls[TrlKeys.link]}
                </BlokoLink>
            </Text>
            <VSpacing base={10} />
        </div>
    );
};

export default translation(Footer);
