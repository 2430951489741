import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'supernova.notification.employer.2fa.enabled.soon',
    link: 'supernova.notification.employer.2fa.enabled.soon.link',
};

const Employer2fa: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);

    return (
        <Notification {...notification}>
            <NotificationSpacer>
                {formatToReactComponent(trls[TrlKeys.content], {
                    '{0}': topLevelSite,
                })}
            </NotificationSpacer>
            <NotificationLink to="/article/27435" target="_blank">
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(Employer2fa);
