import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { PixelEventName } from '@hh.ru/analytics-js/lib/pixels/events';

import { useIsHhru } from 'src/hooks/useSites';

type UseAddEventPixelsRu = () => (eventName: PixelEventName) => void;

export const useAddEventPixelsRu: UseAddEventPixelsRu = () => {
    const isHhru = useIsHhru();

    return useCallback(
        (eventName: PixelEventName): void => {
            if (isHhru) {
                Analytics.addEventPixels(eventName);
            }
        },
        [isHhru]
    );
};
