import { FormEvent, Fragment, useEffect, useRef, useState } from 'react';

import userStatusSelectButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_button_click';
import userStatusSelectElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_element_shown';
import { JobSearchStatus as JobSearchStatusAnalytics } from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_form_submit';
import Radio from 'bloko/blocks/radio';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { JobSearchStatus, JobSearchStatusSetted, JobSearchStatusForm } from 'src/models/applicantUserStatuses';

import useSetJobSearchStatus from 'src/components/Applicant/JobSearchStatus/useSetJobSearchStatus';

const STATUSES = [
    JobSearchStatus.ActiveSearch,
    JobSearchStatus.LookingForOffers,
    JobSearchStatus.HasJobOffer,
    JobSearchStatus.AcceptedJobOffer,
    JobSearchStatus.NotLookingForJob,
] as const;

const TrlKeys = {
    status: {
        [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.change.version2.active_search',
        [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.change.version2.looking_for_offers',
        [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.change.version2.has_job_offer',
        [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.change.version2.accepted_job_offer',
        [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.change.version2.not_looking_for_job',
    },
};

interface ChangeFormProps {
    onSuccess: (status: JobSearchStatus) => void;
    formId: JobSearchStatusForm;
    currentStatus?: JobSearchStatus;
    setDisabled?: (isDisabled: boolean) => void;
    isCompact?: boolean;
    statusesList?: JobSearchStatusSetted[];
}

const ChangeForm: TranslatedComponent<ChangeFormProps> = ({
    onSuccess,
    currentStatus,
    setDisabled,
    isCompact,
    statusesList,
    formId,
    trls,
}) => {
    const [newStatus, setNewStatus] = useState(currentStatus || JobSearchStatus.Unknown);
    const setJobSearchStatus = useSetJobSearchStatus(formId);
    const originalStatus = useRef(newStatus);
    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            userStatusSelectElementShown(formRef.current);
        }
    }, []);

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        setDisabled?.(true);
        event.preventDefault();

        setJobSearchStatus(newStatus)
            .then(() => {
                onSuccess(newStatus);
            })
            .catch(() => {
                setDisabled?.(false);
            });
    };

    const calculateDisabled = (newStatus: JobSearchStatus) => {
        setDisabled?.(originalStatus.current === newStatus);
    };

    const onStatusChange = (status: JobSearchStatusSetted) => {
        setNewStatus(status);
        calculateDisabled(status);
        userStatusSelectButtonClick({ jobSearchStatus: status.toUpperCase() as JobSearchStatusAnalytics });
    };

    const spacing = isCompact ? 3 : 4;
    const statusesToChoose = statusesList || STATUSES;

    return (
        <Form
            name="job-search-status-form"
            id={formId}
            onSubmit={onSubmit}
            data-qa="job-search-status-form"
            ref={formRef}
        >
            {statusesToChoose.map((status) => {
                const checked = newStatus === status;
                return (
                    <Fragment key={status}>
                        <Radio
                            value={newStatus}
                            checked={checked}
                            wrapperProps={{
                                'data-qa': `job-search-change_${status}`,
                            }}
                            onChange={() => onStatusChange(status)}
                        >
                            <Text>{trls[TrlKeys.status[status]]}</Text>
                        </Radio>
                        <VSpacing base={spacing} />
                    </Fragment>
                );
            })}
        </Form>
    );
};

export default translation(ChangeForm);
