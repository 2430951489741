import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';
import Textarea from 'bloko/blocks/textarea';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { FORM_STEPS_ARRAY, SKIP_ANSWER_VALUE } from 'src/components/LoyaltySurveyModal/constants';
import translation from 'src/components/translation';

import styles from './steps.less';

interface StepProps {
    step: number;
    answers: string[];
}

const MAX_LENGTH = 2000;

const TrlKeys = {
    steps: {
        '2': {
            '1': 'surveys.loyalty.popup.steps.1.1',
            '2': 'surveys.loyalty.popup.steps.1.2',
            '3': 'surveys.loyalty.popup.steps.1.3',
            '4': 'surveys.loyalty.popup.steps.1.4',
            '5': 'surveys.loyalty.popup.steps.1.5',
        },
        '3': {
            '1': 'surveys.loyalty.popup.steps.2.1',
            '2': 'surveys.loyalty.popup.steps.2.2',
            '3': 'surveys.loyalty.popup.steps.2.3',
            '4': 'surveys.loyalty.popup.steps.2.4',
            '5': 'surveys.loyalty.popup.steps.2.5',
        },
        '0': {
            min: 'surveys.loyalty.popup.steps.3.min',
            max: 'surveys.loyalty.popup.steps.3.max',
        },
    },
};

const SELECTOR_VALUES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const VALUES_FIRST_SECOND_QUESTIONS: Array<'1' | '2' | '3' | '4' | '5'> = ['5', '4', '3', '2', '1'];

const Steps: TranslatedComponent<StepProps> = ({ trls, step, answers }) => {
    switch (step) {
        case 2:
        case 3:
            return (
                <>
                    {VALUES_FIRST_SECOND_QUESTIONS.map((value) => (
                        <FormItem key={`${step}-${value}`}>
                            <Radio name={FORM_STEPS_ARRAY[step]} value={value} defaultChecked={answers[step] === value}>
                                {trls[TrlKeys.steps[step][value]]}
                            </Radio>
                        </FormItem>
                    ))}
                </>
            );
        case 0:
            return (
                <>
                    <div className={styles.stepsSelector}>
                        {SELECTOR_VALUES.map((index) => (
                            <div className={styles.stepsSelectorRadio} key={index}>
                                <Radio
                                    name={FORM_STEPS_ARRAY[step]}
                                    value={index}
                                    defaultChecked={answers[step] === index}
                                >
                                    <div className={styles.stepsSelectorLabel} data-qa="loyalty-survey-selector">
                                        {index}
                                    </div>
                                </Radio>
                            </div>
                        ))}
                    </div>
                    <div className={styles.stepsSelectorDescription}>
                        <div className={styles.stepsSelectorDescriptionMin}>{trls[TrlKeys.steps[step].min]}</div>
                        <div className={styles.stepsSelectorDescriptionMax}>{trls[TrlKeys.steps[step].max]}</div>
                    </div>
                </>
            );
        case 1: {
            return (
                <Textarea
                    defaultValue={answers[step] === SKIP_ANSWER_VALUE ? '' : answers[step]}
                    autoFocus
                    name="fourth"
                    noresize
                    data-qa="loyalty-survey-textarea"
                    rows={6}
                    maxLength={MAX_LENGTH}
                />
            );
        }
        default:
            return null;
    }
};
export default translation(Steps);
