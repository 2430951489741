import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import fetcher from 'src/utils/fetcher';

const searchCountsAction = makeSetStoreField('searchCounts');

const EMPLOYER_VACACNCY_COUNTS_URL = '/shards/employerview/employer_vacancy_search_count';

declare global {
    interface FetcherGetApi {
        [EMPLOYER_VACACNCY_COUNTS_URL]: {
            response: { counts: number };
            queryParams: undefined;
        };
    }
}

interface Params {
    addNotification: AddNotification;
    dispatch: Dispatch;
    query: string;
    value: number;
    abortSignal?: AbortSignal;
}

type FetchEmployerVacancyCount = (params: Params) => Promise<void>;

export const fetchEmployerVacancyCount: FetchEmployerVacancyCount = async ({
    dispatch,
    addNotification,
    query,
    value,
    abortSignal,
}: Params) => {
    dispatch(searchCountsAction({ isLoad: true, value }));
    let response;
    try {
        response = await fetcher.get(
            `${EMPLOYER_VACACNCY_COUNTS_URL}?${query}` as typeof EMPLOYER_VACACNCY_COUNTS_URL,
            {
                params: undefined,
                signal: abortSignal,
            }
        );
    } catch (error) {
        if (fetcher.isCancel(error)) {
            return;
        }
        defaultRequestErrorHandler(error, addNotification);
    } finally {
        dispatch(searchCountsAction({ isLoad: false, value: response?.counts ?? 0 }));
    }
};

export default fetchEmployerVacancyCount;
