import { FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import paths from 'src/app/routePaths';
import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import FooterMobileBanner from 'src/components/Footer/MobileBanner';
import FooterNav from 'src/components/Footer/Nav';
import FooterServices from 'src/components/Footer/Services';

import styles from './footer.less';

interface FooterProps {
    withSpacing: boolean;
}

const Footer: FC<FooterProps> = ({ withSpacing }) => {
    const isWebView = useSelector(({ isWebView }) => isWebView);
    const hideLayout = useSelector(({ hideLayout }) => hideLayout);
    const isIFrameView = useSelector(({ isIFrame }) => isIFrame);
    const query = useSelector(({ router }) => router.location.query);
    const footer = useSelector(({ footer }) => footer);
    const { pathname } = useSelector(({ router }) => router.location);
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const { isHideFooter } = useApplicantRenewedAuthExp();

    if (
        hideLayout ||
        isWebView ||
        query?.print !== undefined ||
        isIFrameView ||
        pathname === paths.vacancySearchMap ||
        isHideFooter
    ) {
        return null;
    }

    const renderFooter = () =>
        footer.map(({ name, subItems }) => {
            switch (name) {
                case 'footerServices':
                    return <FooterServices key={name} items={subItems} redesigned={isEmployer} />;
                case 'footerMobileBanner':
                    return <FooterMobileBanner key={name} />;
                case 'footerNav':
                    return <FooterNav key={name} items={subItems} />;
            }

            return null;
        });

    return (
        <div className={styles.footer} data-cms="footer" data-qa="footer">
            {withSpacing && <VSpacing base={4} />}
            {renderFooter()}
        </div>
    );
};

export default Footer;
