import { ComponentType } from 'react';

import { Select } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

import { ImportResume } from 'src/components/ImportResumeModal/types';

const TrlKeys = {
    placeholder: 'zp.importResume.resume.placeholder',
};

interface ResumeListProps {
    resumes: ImportResume[];
    value: string;
    onChange: (value: string) => void;
}

const ResumeList: ComponentType<ResumeListProps> = translation(({ trls, resumes, value, onChange }) => {
    return (
        <Select
            type="radio"
            name={'resumes'}
            value={value}
            onChange={onChange}
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.placeholder],
                stretched: true,
                'data-qa': 'import_resume-resume-select',
            }}
            options={resumes}
        />
    );
});

export default ResumeList;
