import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerConsumptionSegmentType {
    XLarge = 'XLarge',
    XMedium = 'XMedium',
    XSmall = 'XSmall',
    XMicro = 'XMicro',
}

export default autoGeneratedReducer<EmployerConsumptionSegmentType>(null);
