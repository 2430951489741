import { FC } from 'react';

import paths from 'src/app/routePaths';
import { useSelector } from 'src/hooks/useSelector';

const EmployerSearchHiddenFields: FC = () => {
    const defaultCountryCompanySearchId = useSelector(
        ({ defaultCountryCompanySearchId }) => defaultCountryCompanySearchId
    );
    const { pathname } = useSelector((state) => state.router.location);
    const isEmployerSearchPage = pathname === paths.employersList;

    // There is a special areaId input on employer search page, it handles default value
    if (!defaultCountryCompanySearchId || isEmployerSearchPage) {
        return null;
    }
    return (
        <>
            <input type="hidden" name="areaId" value={defaultCountryCompanySearchId} />
        </>
    );
};

export default EmployerSearchHiddenFields;
