import { type ReactElement } from 'react';

export enum Step {
    Choose = 'choose',
    Auth = 'auth',
    Import = 'import',
    Copy = 'copy',
    Error = 'error',
}

export interface Resume {
    id: string;
    title: string;
}

export interface ImportResume {
    label: string;
    value: string;
}

export type Steps = {
    [key in Step]: {
        content: ReactElement;
        footer?: ReactElement;
    };
};
