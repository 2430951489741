import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLayout from 'src/components/SupernovaMainMenu/UserNotifications/Notification/Layout';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    content: 'supernova.notifications.empty',
};

const EmptyNotification: TranslatedComponent = ({ trls }) => {
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);

    if (isApplicant) {
        return null;
    }

    return (
        <NotificationLayout iconKind={SupernovaUserNotificationsIconKind.Empty} dataQa={DataQaInvariants.Empty}>
            {trls[TrlKeys.content]}
        </NotificationLayout>
    );
};

export default translation(EmptyNotification);
