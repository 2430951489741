// tempexp_28607_file
import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { WORK_CLOSE_TO_HOME_LINK, WorkCloseToHomeVariant } from 'src/utils/notifications/WorkCloseToHome';

const AUTO_CLOSE_DELAY_MS = 10000;

const TrlKeys = {
    [WorkCloseToHomeVariant.First]: {
        title: 'work.close.to.home.notification.variant1.title',
        description: 'work.close.to.home.notification.variant1.description',
        link: 'work.close.to.home.notification.variant1.link',
    },
    [WorkCloseToHomeVariant.Second]: {
        title: 'work.close.to.home.notification.variant2.title',
        description: 'work.close.to.home.notification.variant2.description',
        link: 'work.close.to.home.notification.variant2.link',
    },
    [WorkCloseToHomeVariant.Third]: {
        title: 'work.close.to.home.notification.variant3.title',
        description: 'work.close.to.home.notification.variant3.description',
        link: 'work.close.to.home.notification.variant3.link',
    },
};

const WorkCloseToHome: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const variant = useSelector(({ workCloseToHomeVariant }) => workCloseToHomeVariant);
    const trlKeys = TrlKeys[variant];
    const link = WORK_CLOSE_TO_HOME_LINK;
    const analyticsName = `work_close_to_home_priority_variant_${variant}`;
    const closeNotificationRef = useRef<VoidFunction>(null);
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const coversSearch = breakpoint === Breakpoint.XS || breakpoint === Breakpoint.S;
    if (coversSearch) {
        return null;
    }
    return (
        <SynchronizedNotification
            autoClose
            autoCloseDelay={AUTO_CLOSE_DELAY_MS}
            analyticsElementName={analyticsName}
            closeEvent={`${analyticsName}_close`}
            markAsViewedEvent={MarkAsViewedEvent.OnShow}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
            kind={NotificationKind.Special}
        >
            <NotificationHeading>{trls[trlKeys.title]}</NotificationHeading>
            <p>{trls[trlKeys.description]}</p>
            <NotificationFooter>
                <Button
                    Element={Link}
                    scale={ButtonScale.Small}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick(analyticsName);
                        closeNotificationRef.current?.();
                    }}
                    target="_blank"
                    to={link}
                    additionalQueryParams={{ hhtmFromLabel: 'user_notification' }}
                >
                    {trls[trlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(WorkCloseToHome);
