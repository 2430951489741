import { useSelector } from 'src/hooks/useSelector';

export const useHiddenAreaList = (): number[] | string[] => {
    const proxiedSearchFormParams = useSelector(
        ({ vacancySearchResult, resumeSearchResult }) =>
            vacancySearchResult.proxiedSearchFormParams || resumeSearchResult.proxiedSearchFormParams
    );
    const isSearchPage = Boolean(proxiedSearchFormParams);
    const proxiedAreaIds = proxiedSearchFormParams?.area;
    const savedAreaIds = useSelector(({ savedAreaIds }) => savedAreaIds);

    if (proxiedAreaIds?.length) {
        return proxiedAreaIds;
    }

    // We only add detected areas when search from pages other than search result page
    if (isSearchPage) {
        return [];
    }

    return savedAreaIds || [];
};
