import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AccountConnect {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    email?: string;
    oauthSystem?: string;
    companyName?: string;
    isEmployer?: boolean;
}

export default autoGeneratedReducer<AccountConnect>({});
