import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export interface ProfessionsListProfession {
    catalogPath: string;
    compensationFrom: number;
    compensationTo: number;
    count: number;
    id: string;
    name: string;
    query: string;
    quirk: string;
    professions: ProfessionsListProfession[];
}

interface ProfessionsList {
    currency: CurrencyType;
    professions?: ProfessionsListProfession[];
    [`remote_count`]: number;
}

export default autoGeneratedReducer<ProfessionsList>(null);
