import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ApplicantRenewedAuthExpState {
    isApplicantRenewedAuthExp: boolean;
    isApplicantRenewedAuthExpB: boolean;
    isApplicantRenewedAuthExpC: boolean;
    isApplicantRenewedAuthExpD: boolean;
}

const initialState: ApplicantRenewedAuthExpState = {
    isApplicantRenewedAuthExp: false,
    isApplicantRenewedAuthExpB: false,
    isApplicantRenewedAuthExpC: false,
    isApplicantRenewedAuthExpD: false,
};

const applicantRenewedAuthExp = autoGeneratedReducer<ApplicantRenewedAuthExpState>(initialState);

export default applicantRenewedAuthExp;
