import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import SearchType from 'src/components/NovaFilters/SearchType';

const DEFAULT_CLUSTERS_SETTINGS: SearchClustersSettings = {
    send: false,
    type: SearchType.Resume,
    actionPath: '/search/resume',
};

export interface SearchClustersSettings {
    send: boolean;
    actionPath: string;
    type: SearchType;
}

export default autoGeneratedReducer<SearchClustersSettings>(DEFAULT_CLUSTERS_SETTINGS);
