import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CriteriaKey, ExperienceOption, OrderByOption, PartTimeOption } from 'src/models/search/searchCriteria.types';
import { EducationOption, LabelOption, SearchPeriodOption } from 'src/models/vacancySearchCriteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

export enum SearchField {
    Name = 'name',
    CompanyName = 'company_name',
    Description = 'description',
}

export interface VacancySearchDictionaries {
    searchPeriod: SearchPeriodOption[];
    orderBy: OrderByOption[];
    searchFields: SearchField[];
    [CriteriaKey.Education]: EducationOption[];
    [CriteriaKey.Experience]: ExperienceOption[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.Schedule]: ScheduleCamelCase[];
    partTime: PartTimeOption[];
    labels: LabelOption[];
}

export default autoGeneratedReducer<VacancySearchDictionaries>({
    searchPeriod: [],
    orderBy: [],
    searchFields: [],
    [CriteriaKey.Education]: [],
    [CriteriaKey.Experience]: [],
    [CriteriaKey.Employment]: [],
    [CriteriaKey.Schedule]: [],
    partTime: [],
    labels: [],
});
