import Analytics from '@hh.ru/analytics-js';
import { ChatikProviderProps } from '@hh.ru/chatik-integration';

type OnResizeEndCallback = Required<ChatikProviderProps>['onResizeEnd'];
type OnDragEndCallback = Required<ChatikProviderProps>['onDragEnd'];

/**
 * Если передвижение (ресайз или драг-н-дроп) было на меньшее кол-во пикселей,
 * то считаем, что это было сделано случайно и не отправляем событие в аналитику.
 */
const MOVEMENT_LOGGING_GAP = 44;

export const onResizeEnd: OnResizeEndCallback = (from, to) => {
    const heightDiff = Math.abs(from.height - to.height);
    const widthDiff = Math.abs(from.width - to.width);

    if (widthDiff < MOVEMENT_LOGGING_GAP && heightDiff < MOVEMENT_LOGGING_GAP) {
        return;
    }

    Analytics.sendHHEventButtonClick('chat_resize', {
        heightFrom: from.height,
        widthFrom: from.width,
        heightTo: to.height,
        widthTo: to.width,
    });
};

export const onDragEnd: OnDragEndCallback = (from, to) => {
    const topDiff = Math.abs(from.top - to.top);
    const leftDiff = Math.abs(from.left - to.left);

    if (topDiff < MOVEMENT_LOGGING_GAP && leftDiff < MOVEMENT_LOGGING_GAP) {
        return;
    }

    Analytics.sendHHEventButtonClick('chat_move', {
        topFrom: from.top,
        bottomFrom: from.bottom,
        leftFrom: from.left,
        rightFrom: from.right,
        topTo: to.top,
        bottomTo: to.bottom,
        leftTo: to.left,
        rightTo: to.right,
    });
};
