import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Session {
    isFallback: boolean;
    securityCheckFailed: boolean;
    hasExtraAccounts: boolean;
    isMultiAccount: boolean;
}

export default autoGeneratedReducer<Session>({
    isFallback: false,
    securityCheckFailed: false,
    hasExtraAccounts: false,
    isMultiAccount: false,
});
