import { FC, useCallback, useEffect, useRef } from 'react';

import Link, { LinkAppearance } from 'bloko/blocks/link';

import NoIndex from 'src/components/NoIndex';
import GeoSwitcherPopup from 'src/components/SupernovaMainMenu/GeoSwitcherPopup';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';
import { OverlayTypes } from 'src/components/SupernovaOverlayMenu/Overlay';
import RegionClarificationTooltip from 'src/components/SupernovaOverlayMenu/RegionClarificationTooltip';
import useHandleOpenGeoSwitcher from 'src/hooks/useHandleOpenGeoSwitcher';
import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import useSendCheckGeoSwitcherRedesignExp from 'src/hooks/useSendCheckGeoSwitcherRedesignExp';

const MenuItem: FC<MenuItemRendererProps> = ({ menuItem, showOverlay }) => {
    const ref = useRef(null);
    const handleTrackClick = useTrackClicks(menuItem.name, menuItem.trackClicks, menuItem.analytics);
    const openGeoSwitcher = useHandleOpenGeoSwitcher(() => showOverlay(OverlayTypes.AreaSwitcherOverlay));
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();
    const { translations } = menuItem;
    const name = isRedesignGeoExpActive ? translations.geoExpName || translations.name : translations.name;
    const sendCheck = useSendCheckGeoSwitcherRedesignExp();

    const handleClick = useCallback(() => {
        openGeoSwitcher();

        handleTrackClick();
    }, [handleTrackClick, openGeoSwitcher]);

    useEffect(() => {
        if (translations.geoExpName) {
            sendCheck();
        }
    }, [sendCheck, translations.geoExpName]);

    return (
        <div
            className="supernova-navi-item
                    supernova-navi-item_lvl-1
                    supernova-navi-item_area-switcher
                    HH-Supernova-RegionClarification-Container"
            ref={ref}
        >
            <NoIndex>
                <Link
                    data-qa="mainmenu_areaSwitcher"
                    appearance={LinkAppearance.Pseudo}
                    title={name}
                    onClick={handleClick}
                >
                    <span className="supernova-navi-item_area-switcher-button">{name}</span>
                </Link>
            </NoIndex>

            <RegionClarificationTooltip showOverlay={showOverlay} activatorRef={ref} />
            {isRedesignGeoExpActive && <GeoSwitcherPopup placement="bottom-left" activatorRef={ref} />}
        </div>
    );
};

export default MenuItem;
