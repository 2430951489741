import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

const REDUCE_VAC_COUNT = 'REDUCE_VAC_COUNT';

interface EmployerUserTests {
    paging?: Paging;
    tests: Array<{
        description: string;
        extraDescription: string;
        id: number;
        name: string;
        vacCount: number;
        guid: string;
    }>;
}

interface PayloadUserTests {
    [REDUCE_VAC_COUNT]: number;
}

const actionCreator = ActionCreatorHelper<PayloadUserTests>();

export const reduceVacCount = actionCreator(REDUCE_VAC_COUNT);

export default createReducer<EmployerUserTests, PayloadUserTests>(
    { tests: [] },
    {
        [REDUCE_VAC_COUNT]: (state, { payload }) => {
            return {
                ...state,
                tests: state.tests.map((test) =>
                    test.id === payload ? { ...test, vacCount: test.vacCount - 1 } : test
                ),
            };
        },
    }
);
