import { FC } from 'react';

import { GridColumn } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';

import AreaSwitcher from 'src/components/AreaSwitcher';

const Overlay: FC = () => {
    return (
        <GridColumn xs={4} s={8} m={12} l={12}>
            <VSpacing base={6} />
            <AreaSwitcher />
        </GridColumn>
    );
};

export default Overlay;
