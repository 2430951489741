import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    link: 'supernova.notification.setka.promo.applicant.link',
    title: 'supernova.notification.setka.promo.applicant.title',
    content: 'supernova.notification.setka.promo.applicant.content',
};

const SetkaPromo: TranslatedComponent<ComplexNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const closeNotificationRef = useRef<VoidFunction>(null);

    return (
        <SynchronizedNotification
            kind={NotificationKind.Ok}
            analyticsElementName="setka_promo_applicant_notification"
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
            closeEvent={`setka_promo_applicant_notification_close`}
        >
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <p>{trls[TrlKeys.content]}</p>
            <NotificationFooter>
                <Button
                    kind={ButtonKind.Primary}
                    appearance={ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                    Element="a"
                    target="_blank"
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('setka_promo_applicant_notification_close_link_priority');
                        closeNotificationRef.current?.();
                    }}
                    href="https://4284841.redirect.appmetrica.yandex.com/?appmetrica_tracking_id=317055592281706223&utm_medium=notification&utm_source=site_hh&utm_campaign=notification_on_site_0624"
                >
                    {trls[TrlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(SetkaPromo);
