import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ClickmeProductType, ProductIdType } from 'src/models/price/product.types';

export enum NotificationsSuccess {
    NotificationPayment = 'NOTIFICATION_PAYMENT',
    NotificationFormats = 'NOTIFICATION_FORMATS',
    NotificationPrice = 'NOTIFICATION_PRICE',
    NotificationDetails = 'NOTIFICATION_DETAILS',
}

export enum FormComponent {
    InputString = 'INPUT_STRING',
    InputBigString = 'INPUT_BIG_STRING',
    InputPeriod = 'INPUT_PERIOD',
    InputDate = 'INPUT_DATE',
    InputStartWeek = 'INPUT_START_WEEK',
}

export enum Params {
    Link = 'link',
    Date = 'date',
    Regions = 'regions',
    Period = 'period',
    Targeting = 'targeting',
}

export enum ServiceInputType {
    VacancyLink = 'INPUT_TYPE_VACANCY_LINK',
    StartWeek = 'INPUT_TYPE_START_WEEK',
    AdvRegions = 'INPUT_TYPE_ADV_REGIONS',
    PageLink = 'INPUT_TYPE_PAGE_LINK',
    Period = 'INPUT_TYPE_PERIOD',
    BannerTargettiong = 'INPUT_TYPE_BANNER_TARGETING',
    MailingDate = 'INPUT_TYPE_MAILING_DATE',
    MailingRegion = 'INPUT_TYPE_MAILING_REGION',
    MailingTargetting = 'INPUT_TYPE_MAILING_TARGETING',
    PostDate = 'INPUT_TYPE_POST_DATE',
}

type FormItem = {
    code: ServiceInputType;
} & (
    | {
          component: FormComponent.InputString;
          param: Params.Regions | Params.Link;
      }
    | {
          component: FormComponent.InputBigString;
          param: Params.Targeting;
      }
    | {
          component: FormComponent.InputPeriod;
          param: Params.Period;
      }
    | {
          component: FormComponent.InputStartWeek;
          param: Params.Date;
      }
    | {
          component: FormComponent.InputDate;
          param: Params.Date;
      }
);

interface Body {
    [Params.Link]: string;
    [Params.Date]: string;
    [Params.Regions]: string;
    [Params.Period]: { start: string; end: string };
    [Params.Targeting]: string;
}

export interface CardConfig {
    formConfig: FormItem[];
    initBody: Body;
}

interface PriceItemDescription {
    paragraph: string;
    list?: string[];
}

export enum PriceItemCode {
    Clickme = 'clickme',
    VacancyOfTheDay = 'vacancy_of_the_day',
    SmsSubscription = 'sms_subscription',
    EmailSubscription = 'email_subscription',
    Telegram = 'telegram',
    Social = 'social',
    CompanyOfTheDay = 'company_of_the_day',
    Banners = 'banners',
}

export interface PriceItem {
    code: PriceItemCode;
    title: { main: string; form: string };
    description: PriceItemDescription[];
    price?: { min?: number; value: number };
    cardConfig: CardConfig;
    notification: NotificationsSuccess;
}

export interface PriceClickmeProducts {
    products?: Record<ProductIdType, ClickmeProductType>;
    countProductsByCode?: Record<string, Record<string, string>>;
    items?: PriceItem[];
    minValue: number;
    linkPriceList?: string;
}

export default autoGeneratedReducer<PriceClickmeProducts>({} as PriceClickmeProducts);
