import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum MicroFrontendServiceName {
    EmployerReviewsFront = 'employerReviewsFront',
    ApplicantServicesFront = 'applicantServicesFront',
    MentorsFront = 'mentorsFront',
    SupportFront = 'supportFront',
    VrSurveyFront = 'vrSurveyFront',
    ResumeProfileFront = 'resumeProfileFront',
    SkillsFront = 'skillsFront',
    BrandingFront = 'brandingFront',
}

export type RemoteServiceName = 'applicant_services_front' | 'support_front' | 'branding_front';

export interface SerivceInfo {
    host: string;
    url: string;
    id: string;
    isSuccessSSR: boolean;
    useSSRResult: boolean;
    scripts?: string[];
    styles?: string[];
    globalServiceName: MicroFrontendServiceName;
    remoteServiceName?: RemoteServiceName;
    remoteEntry?: string;
    inlineScript?: string;
}

export type MicroFrontends = Record<string, SerivceInfo>;

const initialState = {};
const UPDATE_MICRO_FRONTENDS = 'UPDATE_MICRO_FRONTENDS';
const DELETE_MICRO_FRONTEND = 'DELETE_MICRO_FRONTEND';

interface PayloadTypes {
    [UPDATE_MICRO_FRONTENDS]: MicroFrontends;
    [DELETE_MICRO_FRONTEND]: string;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateMicroFrontends = actionCreator(UPDATE_MICRO_FRONTENDS);
export const deleteMicroFrontend = actionCreator(DELETE_MICRO_FRONTEND);

export default createReducer<MicroFrontends, PayloadTypes>(initialState, {
    [UPDATE_MICRO_FRONTENDS]: (state, { payload }) => {
        return { ...state, ...payload };
    },
    [DELETE_MICRO_FRONTEND]: (state, { payload }) => {
        return Object.entries(state)
            .filter(([name]) => name !== payload)
            .reduce((prev, [name, service]) => ({ ...prev, [name]: service }), {});
    },
});
