import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { DBAccessProductType, PublicationProductType } from 'src/models/price/product.types';

interface AnonymousEmployerState {
    publicationPrice: PublicationProductType | null;
    dbAccessPrice: DBAccessProductType | null;
    applicantCount: number | null;
    suggestedProfessions: string[];
    averageEmployersOnline: number | null;
    statisticsDate: string | null;
}

export default autoGeneratedReducer<AnonymousEmployerState>({
    suggestedProfessions: [],
    applicantCount: null,
    publicationPrice: null,
    dbAccessPrice: null,
    averageEmployersOnline: null,
    statisticsDate: null,
});
