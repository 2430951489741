import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export default autoGeneratedReducer<{
    agreementUrl: string;
    policyUrl: string;
    backUrl: string;
    isSudo: boolean;
}>({
    agreementUrl: '',
    policyUrl: '',
    backUrl: '',
    isSudo: false,
});
