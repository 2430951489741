import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Action, autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { AppStore } from 'src/app/store';

export enum LogoType {
    VacancyPage = 'vacancyPage',
    Medium = 'medium',
    SearchResultsPage = 'searchResultsPage',
    Small = 'small',
    EmployerPage = 'employerPage',
    Original = 'ORIGINAL',
}

export interface CompanyLogo {
    '@type': LogoType;
    '@url': string;
}

interface PossibleOfferEmployer {
    name: string;
    employerId: number;
    logos: {
        logo: CompanyLogo[];
    };
    vacancyNames: string[];
    hasInvitationTopic: boolean;
    topicIds: number[];
    vacancyId?: number;
    resumeId?: number;
}

interface JobSearchStatusPostChangeModal {
    visible?: boolean;
    visibleForApplicantResumes?: boolean;
    showStatusChangeNotification?: boolean;
}

export type PossibleJobOffers = PossibleOfferEmployer[];

export const possibleJobOffersDefaultValue: PossibleJobOffers = [];

export const jobSearchStatusPostChangeModal = autoGeneratedReducer<JobSearchStatusPostChangeModal>(null);
const setJobSearchStatusPostChangeModal = makeSetStoreField('jobSearchStatusPostChangeModal');

export const showPostChangeModal: ActionCreator<ThunkAction<void, AppStore, unknown, Action>> =
    (showStatusChangeNotification: boolean, isFromApplicantResumes = false) =>
    (dispatch) => {
        if (isFromApplicantResumes) {
            dispatch(
                setJobSearchStatusPostChangeModal({
                    visibleForApplicantResumes: true,
                    showStatusChangeNotification,
                })
            );
        } else {
            dispatch(
                setJobSearchStatusPostChangeModal({
                    visible: true,
                    showStatusChangeNotification,
                })
            );
        }
    };

export const hidePostChangeModal: ActionCreator<ThunkAction<void, AppStore, unknown, Action>> =
    (isFromApplicantResumes = false) =>
    (dispatch) => {
        if (isFromApplicantResumes) {
            dispatch(
                setJobSearchStatusPostChangeModal({
                    visibleForApplicantResumes: false,
                    showStatusChangeNotification: false,
                })
            );
        } else {
            dispatch(
                setJobSearchStatusPostChangeModal({
                    visible: false,
                    showStatusChangeNotification: false,
                })
            );
        }
    };

export const applicantPossibleJobOffers = autoGeneratedReducer<PossibleJobOffers>(possibleJobOffersDefaultValue);
export const setApplicantPossibleJobOffers = makeSetStoreField('applicantPossibleJobOffers');
