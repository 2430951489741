export interface FetcherError {
    isFetcherError: boolean;
}

export const isFetcherError = (maybeError: unknown): maybeError is FetcherError =>
    typeof maybeError === 'object' && maybeError !== null && 'isFetcherError' in maybeError;

export const mixFetcherError = <T>(err: T): T & (T | FetcherError) => {
    if (typeof err === 'object' && err !== null) {
        (err as unknown as FetcherError).isFetcherError = true;
    }

    return err;
};
