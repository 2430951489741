import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface CountriesProfileVisibilityAgreement {
    vacancyId?: number;
    show: boolean;
    confirmed: boolean;
    countryType: string;
}

export default autoGeneratedReducer<CountriesProfileVisibilityAgreement>({
    show: false,
    confirmed: false,
    countryType: 'common',
});
