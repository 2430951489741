import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import UserLabel from 'src/utils/constants/userLabels';

export const USER_LABELS_FOR_VACANCIES_ADD = 'USER_LABELS_FOR_VACANCIES_ADD';
export const USER_LABELS_FOR_VACANCIES_REMOVE = 'USER_LABELS_FOR_VACANCIES_REMOVE';
export const INITIAL_STATE = {};

interface PayloadTypes {
    [USER_LABELS_FOR_VACANCIES_ADD]: { vacancyId: number; labels: UserLabel[] };
    [USER_LABELS_FOR_VACANCIES_REMOVE]: { vacancyId: number; label: UserLabel };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const addUserLabelsForVacancies = actionCreator(USER_LABELS_FOR_VACANCIES_ADD);
export const removeUserLabelsForVacancies = actionCreator(USER_LABELS_FOR_VACANCIES_REMOVE);

export default createReducer<{ [vacancyId: number]: UserLabel[] }, PayloadTypes>(INITIAL_STATE, {
    [USER_LABELS_FOR_VACANCIES_ADD]: (state, { payload }) => {
        const labels = state[payload.vacancyId] ? state[payload.vacancyId] : [];

        return {
            ...state,
            [payload.vacancyId]: [...labels, ...payload.labels],
        };
    },
    [USER_LABELS_FOR_VACANCIES_REMOVE]: (state, { payload }) => ({
        ...state,
        [payload.vacancyId]: state[payload.vacancyId].filter((label) => label !== payload.label),
    }),
});
