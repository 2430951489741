import { useCallback, useEffect, useRef, useState } from 'react';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { TopLevelDomainsData } from 'src/components/AreaSwitcher/areaSwitcherTypes';
import { useNotification } from 'src/components/Notifications/Provider';
import fetcher from 'src/utils/fetcher';

const TOP_LEVEL_DOMAINS_URL = '/shards/area_switcher/top_level_domains';

declare global {
    interface FetcherGetApi {
        [TOP_LEVEL_DOMAINS_URL]: {
            response: TopLevelDomainsData;
            queryParams: { backUrl?: string; forCurrentDomain?: boolean };
        };
    }
}

const useGetTopLevelDomains = (): { isLoading: boolean; topLevelDomainsData: TopLevelDomainsData | null } => {
    const [isLoading, setIsLoading] = useState(true);
    const { addNotification } = useNotification();
    const [topLevelDomainsData, setTopLevelDomainsData] = useState<TopLevelDomainsData | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    const getTopLevelDomains = useCallback(async () => {
        let domains;

        setIsLoading(true);

        try {
            domains = await fetcher.get(TOP_LEVEL_DOMAINS_URL, {
                signal: abortControllerRef.current?.signal,
                params: {
                    backUrl: window.location.href,
                    forCurrentDomain: true,
                },
            });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        } finally {
            setIsLoading(false);
        }

        setTopLevelDomainsData(domains);
    }, [addNotification]);

    useEffect(() => {
        const controller = new AbortController();

        abortControllerRef.current = controller;

        void getTopLevelDomains();

        return () => controller.abort();
    }, [addNotification, getTopLevelDomains]);

    return { isLoading, topLevelDomainsData };
};

export default useGetTopLevelDomains;
