import { FC } from 'react';

import { Loader, VSpacing } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const GeoSwitcherPopupLoader: FC = () => {
    return (
        <div className={styles.loaderContainer}>
            <VSpacing xs={64} s={64} default={80} />
            <div className={styles.loader}>
                <Loader />
            </div>
            <VSpacing xs={64} s={64} default={80} />
        </div>
    );
};

export default GeoSwitcherPopupLoader;
