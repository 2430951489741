import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';
import { LEFT_DOUBLE_QUOTE, RIGHT_DOUBLE_QUOTE } from 'src/utils/constants/symbols';

const TrlKeys = {
    content: 'employer.reviews.notify.feedback.created.supernova.notification',
    link: 'employer.reviews.notify.feedback.created.supernova.notification.link',
};

const EmployerReviewFeedbackCreated: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification
        iconKind={SupernovaUserNotificationsIconKind.Info}
        dataQa={DataQaInvariants.EmployerReviewFeedbackCreated}
        {...notification}
    >
        <NotificationText multiline>
            {trls[TrlKeys.content]} {LEFT_DOUBLE_QUOTE}
            {notification.params.companyName}
            {RIGHT_DOUBLE_QUOTE}
        </NotificationText>
        <NotificationLink to={`/employer_reviews/mine?reviewId=${notification.params.employerReviewId}`}>
            {trls[TrlKeys.link]}
        </NotificationLink>
    </Notification>
);

export default translation(EmployerReviewFeedbackCreated);
