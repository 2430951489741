import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    pfpBalance: 'employer.employerProfile.popup.informers.pfpBalance',
};

// Баланс для оплаты откликов на вакансии PFP (Pay For Performance)
const PfpBalanceInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const currency = billingInfo?.billingSummary?.currency || CurrencyType.RUR;
    const moneyCodes = billingInfo?.billingSummary?.serviceCategories?.moneyCodes;

    if (!moneyCodes) {
        return null;
    }

    return (
        <Informer data-qa="pfp_money_count" title={trls[TrlKeys.pfpBalance]}>
            <FormatMoney currency={currency}>{moneyCodes.count}</FormatMoney>
        </Informer>
    );
};

export default translation(PfpBalanceInformer);
