import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { BrandedTemplate } from 'src/models/brandedVacancyTemplates';
import { CurrencyType } from 'src/models/currencies.types';

type RankType = 'LAST_CHANGE_TIME_EXCEPT_EMPLOYER_INBOX' | 'RELEVANCE';

export interface EmployerSearchPublicationSettings {
    useOpenResumeNewWindow: boolean;
    useSendSmsToInvite: boolean;
    useTemplateVacancy: boolean;
    useWantWorkAutoreply: boolean;
    vacancyResponseRankType: RankType;
    htmlEditor: 'CKEDITOR' | 'JSXEDITOR' | 'NONE';
    currencyCode: CurrencyType;
    templateVacancy: string;
    brandingTemplates: BrandedTemplate[];
    defaultTemplateName: string | null;
    employerManagerId?: string;
}

export default autoGeneratedReducer<EmployerSearchPublicationSettings>({
    useOpenResumeNewWindow: false,
    useSendSmsToInvite: false,
    useTemplateVacancy: false,
    useWantWorkAutoreply: false,
    vacancyResponseRankType: 'LAST_CHANGE_TIME_EXCEPT_EMPLOYER_INBOX',
    htmlEditor: 'NONE',
    currencyCode: CurrencyType.RUR,
    templateVacancy: '',
    brandingTemplates: [],
    defaultTemplateName: null,
});
