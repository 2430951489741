import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios, { Canceler } from 'axios';

import { PossibleJobOffers, setApplicantPossibleJobOffers } from 'src/models/applicant/jobSearchStatus';
import fetcher from 'src/utils/fetcher';

import { ModalStep } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/types';

type FetchOffersFunction = (args: { setModalStep: (step: ModalStep) => void }) => void;

type UseFetchPossibleJobOffersHook = () => {
    loading: boolean;
    fetchOffers: FetchOffersFunction;
    cancelFetch: () => void;
};

declare global {
    interface FetcherGetApi {
        '/shards/applicant/negotiations/possible_job_offers': {
            queryParams: unknown;
            response: {
                possibleJobOffers?: PossibleJobOffers;
            };
        };
    }
}

const useFetchPossibleJobOffers: UseFetchPossibleJobOffersHook = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const cancelFetchRef = useRef<Canceler | null>(null);

    const cancelFetch = () => {
        cancelFetchRef.current?.();
    };

    const fetchOffers: FetchOffersFunction = async ({ setModalStep }) => {
        setLoading(true);
        dispatch(setApplicantPossibleJobOffers([]));

        cancelFetchRef.current?.();
        const cancelToken = new axios.CancelToken((cancel: Canceler) => {
            cancelFetchRef.current = cancel;
        });

        let data = null;
        try {
            data = await fetcher.get('/shards/applicant/negotiations/possible_job_offers', {
                params: {},
                cancelToken,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

        if (!data || !data.possibleJobOffers || data.possibleJobOffers.length === 0) {
            setModalStep(ModalStep.ChooseReasonStep);
            return;
        }

        setModalStep(ModalStep.ChooseOfferStep);
        dispatch(setApplicantPossibleJobOffers(data.possibleJobOffers));
    };

    return { loading, fetchOffers, cancelFetch };
};

export default useFetchPossibleJobOffers;
