import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { FoundOnHHVariants } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/types';

import styles from './found-on-hh-content.less';

const TrlKeys = {
    [FoundOnHHVariants.FoundOnHH]: 'jobSearchStatus.postChangeModal.foundOnHH.yes',
    [FoundOnHHVariants.NotFoundOnHH]: 'jobSearchStatus.postChangeModal.foundOnHH.no',
};

interface FoundOnHHContentProps {
    value: FoundOnHHVariants | null;
    onSet: (value: FoundOnHHVariants) => void;
}

const FoundOnHHContent: TranslatedComponent<FoundOnHHContentProps> = ({ value, onSet, trls }) => {
    const renderRadio = (variant: FoundOnHHVariants) => (
        <label data-qa={`post_change_modal_variant_${variant}`} className={styles.surveyItem}>
            <Radio
                checked={value === variant}
                onChange={() => {
                    onSet(variant);
                }}
            >
                {trls[TrlKeys[variant]]}
            </Radio>
        </label>
    );

    return (
        <>
            <VSpacing base={0} xs={2} />
            {renderRadio(FoundOnHHVariants.FoundOnHH)}
            <VSpacing base={2} xs={4} />
            <hr className={styles.delimiter} />
            <VSpacing base={0} xs={4} />
            {renderRadio(FoundOnHHVariants.NotFoundOnHH)}
            <VSpacing base={0} xs={2} />
        </>
    );
};

export default translation(FoundOnHHContent);
