import createReducer from '@hh.ru/redux-create-reducer';

import { PublicationTypePurchase } from 'src/models/vacancyCreate/availablePublicationTypes';
import { VacancyProperties } from 'src/models/vacancyProperties';

const SET_DRAFT_PURCHASE_INFO = 'SET_DRAFT_PURCHASE_INFO';

export const INITIAL_STATE = {};

export interface PurchaseDraft {
    purchase: PublicationTypePurchase;
    source: string;
    unableToPurchase: {
        publicationType: string;
        count: number;
        vacancyProperties: VacancyProperties;
    }[];
}

type PurchaseDraftState = { [draftId: number]: PurchaseDraft };

interface PayloadTypes {
    [SET_DRAFT_PURCHASE_INFO]: PurchaseDraftState;
}

export const setDraftPurchaseInfo = (
    draftId: number,
    info: PurchaseDraft
): { type: 'SET_DRAFT_PURCHASE_INFO'; payload: { [draftId: number]: PurchaseDraft } } => ({
    type: SET_DRAFT_PURCHASE_INFO,
    payload: { [draftId]: info },
});

export default createReducer<PurchaseDraftState, PayloadTypes>(INITIAL_STATE, {
    [SET_DRAFT_PURCHASE_INFO]: (state, action) => ({ ...state, ...action.payload }),
});
