import { useMemo } from 'react';

import { ChatikProviderProps } from '@hh.ru/chatik-integration';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';

import paths from 'src/app/routePaths';
import chatikErrorNotification from 'src/components/ChatikIntegration/components/ChatikErrorNotification';
import chatikErrorNotificationMagritte from 'src/components/Notifications/ChatikErrorNotificationMagritte';
import { useNotification } from 'src/components/Notifications/Provider';
import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';
import { WebCallData } from 'src/components/Webcall/types';
import { useSelector } from 'src/hooks/useSelector';
import { UXFeedback } from 'src/utils/uxfeedback';

type InitParams = ChatikProviderProps['initParams'];

interface ShowNotificationParams {
    magritted?: boolean;
    content: string;
    title?: string;
    kind?: NotificationKind;
}

const useInitParams = (origin: string): InitParams => {
    const webviewAppType = useSelector((state) => state.webviewAppType);
    const displayType = useSelector((state) => state.displayType);

    const { addNotification } = useNotification();

    const initParams = useMemo(
        () =>
            ({
                origin,
                webviewAppType,
                displayType,
                incomingMessageHandlers: {
                    showNotification: ({ magritted, ...rest }: ShowNotificationParams) => {
                        if (magritted) {
                            addNotification(chatikErrorNotificationMagritte, {
                                props: { ...rest, error: rest.kind === NotificationKind.Error },
                            });
                        } else {
                            addNotification(chatikErrorNotification, {
                                uniqueType: true,
                                props: rest,
                            });
                        }
                    },
                    uxfbEvent: (data: { name: string }) => {
                        UXFeedback.sendEvent(data.name);
                    },
                    openVacancySearch: () => {
                        window.location.assign(paths.vacancySearch);
                    },
                    startCall: (data: WebCallData) => {
                        const detail = {
                            ...data,
                            widgetId: 'webcall-front-dynamic',
                        };
                        window.dispatchEvent(new CustomEvent(WEBCALL_CUSTOM_EVENTS.INIT_CALL, { detail }));
                    },
                },
            }) as InitParams,
        [origin, webviewAppType, displayType, addNotification]
    );

    return initParams;
};
export default useInitParams;
