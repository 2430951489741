import { FC, PropsWithChildren } from 'react';

import Debug from 'HHC/Debug';
import config from 'src/components/SupernovaMainMenu/UserNotifications/config';
import EmptyAutoSearchVacancy from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmptyAutoSearchVacancy';
import EmptyNotification from 'src/components/SupernovaMainMenu/UserNotifications/variants/EmptyNotification';
import { ComplexUserNotificationsTemplateKey, ComplexUserNotification } from 'src/models/userNotifications';

interface NotificationTextProps {
    userNotifications: ComplexUserNotification[];
    close?: VoidFunction;
}

const Content: FC<NotificationTextProps & PropsWithChildren> = ({ close, userNotifications }) => {
    return (
        <div data-qa="user-notifications-content">
            {userNotifications.map((notification) => {
                const SupernovaUserNotification = config[notification.templateKey];

                if (!SupernovaUserNotification) {
                    Debug.log('out', 'Unknown user notification template key', {
                        explain: notification.templateKey,
                    });
                    return null;
                }

                return <SupernovaUserNotification key={notification.id} close={close} {...notification} />;
            })}
            {userNotifications.length === 0 && <EmptyNotification />}
            {!userNotifications.find(
                ({ templateKey }) => templateKey === ComplexUserNotificationsTemplateKey.AutoSearchVacancies
            ) && <EmptyAutoSearchVacancy />}
        </div>
    );
};

export default Content;
