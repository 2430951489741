import { FC } from 'react';

import notificationCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/notifications/notification_close_button_click';
import { Snackbar, SnackbarProps } from '@hh.ru/magritte-ui';

import NotificationAnalytics from 'src/components/Notifications/NotificationAnalytics';

interface NotificationProps {
    onClose?: () => void;
    removeNotification: () => void;
    notificationName?: string;
}

const Notification: FC<NotificationProps & SnackbarProps> = ({
    children,
    onClose,
    notificationName,
    removeNotification,
    ...props
}) => {
    const handleClose = () => {
        notificationName && notificationCloseButtonClick({ notificationName });
        onClose?.();
        removeNotification();
    };
    return (
        <Snackbar {...props} onClose={handleClose} showClose>
            {notificationName ? (
                <NotificationAnalytics notificationName={notificationName}>{children}</NotificationAnalytics>
            ) : (
                children
            )}
        </Snackbar>
    );
};

export default Notification;
