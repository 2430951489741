import React from 'react';

import useSupportChat, { SupportChatContext } from 'src/components/SupportChat/useSupportChat';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

const Features = {
    dedicatedAdminWebimLocationInHelp: 'dedicated_admin_webim_location_in_help',
};

const SupportChatProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const userType = useSelector(({ userType }) => userType);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);
    const dedicatedAdminWebimLocationInHelp = useSelector(
        ({ features }) => features[Features.dedicatedAdminWebimLocationInHelp]
    );

    const [shouldRender, setShouldRender] = React.useState(userType !== UserType.Anonymous && !additionalCheck);

    let postfix =
        userType === UserType.Anonymous && supernovaUserType === SupernovaUserType.Employer
            ? '_employer_menu'
            : '_menu';
    if (dedicatedAdminWebimLocationInHelp) {
        postfix = '';
    }
    const { chatInstance, isActive } = useSupportChat(postfix, shouldRender);

    return (
        <SupportChatContext.Provider value={{ chatInstance, isActive, setShouldRender }}>
            {children}
        </SupportChatContext.Provider>
    );
};

export default SupportChatProvider;
