import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import SiteException from 'src/components/SiteExceptions/SiteException';

const TrlKeys = {
    notSafeSession: 'index.firstpage.not.safe.session',
    noScript: 'index.firstpage.noscript',
    anonymous: 'exceptions.session.anonymous',
};

const SiteExceptions: TranslatedComponent = ({ trls }) => {
    const session = useSelector(({ session }) => session);

    return (
        <>
            {session.isFallback && (
                <SiteException hasRemoveIcon cookieName={'ignoreException'}>
                    {trls[TrlKeys.anonymous]}
                </SiteException>
            )}
            {session.securityCheckFailed && <SiteException>{trls[TrlKeys.notSafeSession]}</SiteException>}
            <NoIndex>
                <noscript>
                    <SiteException>{trls[TrlKeys.noScript]}</SiteException>
                </noscript>
            </NoIndex>
        </>
    );
};

export default translation(SiteExceptions);
