import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationFailure]: {
        content: 'vacancy.auto.publication.no.quotas.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationNoPermission]: {
        content: 'vacancy.auto.publication.no.permission.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationNoPublication]: {
        content: 'vacancy.auto.publication.no.publication.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationValidationFailure]: {
        content: 'vacancy.auto.publication.validation.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationUnknown]: {
        content: 'vacancy.auto.publication.unknown.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccesses]: {
        content: 'vacancy.auto.publication.successes',
        statisticsOne: 'statistics.global.vacancy.one',
        statisticsSome: 'statistics.global.vacancy.some',
        statisticsMany: 'statistics.global.vacancy.many',
        statisticsZero: 'statistics.global.vacancy.zero',
    },
    [ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccess]: {
        content: 'vacancy.auto.publication.success',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const VacancyAutoPublication: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    if (
        key === ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccesses ||
        key === ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccess
    ) {
        return (
            <Notification
                iconKind={SupernovaUserNotificationsIconKind.Success}
                dataQa={DataQaInvariants.Success}
                {...notification}
            >
                {key === ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccesses && (
                    <>
                        <NotificationLink to="/employer/vacancies" target="_blank">
                            {notification.params.key}
                            {notification.params.count && (
                                <ConversionNumber
                                    formatValue={(value) => NumberFormatter.format(String(value))}
                                    value={parseInt(notification.params.count, 10)}
                                    one={trls[TrlKeys[key].statisticsOne]}
                                    some={trls[TrlKeys[key].statisticsSome]}
                                    many={trls[TrlKeys[key].statisticsMany]}
                                    zero={trls[TrlKeys[key].statisticsZero]}
                                />
                            )}
                        </NotificationLink>
                        {trls[TrlKeys[key].content]}
                    </>
                )}
                {key === ComplexUserNotificationsTemplateKey.VacancyAutoPublicationSuccess && (
                    <>
                        {formatToReactComponent(trls[TrlKeys[key].content], {
                            '{0}': (
                                <NotificationLink to={`/vacancy/${notification.params.vacancy_id}`} target="_blank">
                                    «{notification.params.name}»
                                </NotificationLink>
                            ),
                        })}
                    </>
                )}
            </Notification>
        );
    }

    const typeTrl = notification.params.type_trl;

    return (
        <Notification {...notification}>
            <NotificationSpacer>
                {formatToReactComponent(trls[TrlKeys[key].content], {
                    '{0}': (
                        <NotificationLink
                            to={`/employer/vacancy/create?draftId=${notification.params.draftId}`}
                            target="_blank"
                        >
                            «{notification.params.name}»
                        </NotificationLink>
                    ),
                })}
                {key === ComplexUserNotificationsTemplateKey.VacancyAutoPublicationNoPublication && typeTrl}
            </NotificationSpacer>
        </Notification>
    );
};

export default translation(VacancyAutoPublication);
