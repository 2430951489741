export interface Paging {
    first: number;
    last: number;
    current: number;
}

export enum FilterType {
    All = 'all',
    Subscribed = 'subscribed',
    Unsubscribed = 'unsubscribed',
}

export enum LoadingActivityName {
    Subscribe = 'subscribe',
    Unsubscribe = 'unsubscribe',
    Delete = 'delete',
}

export enum BOActivityName {
    UnsubscribeAll = 'unsubscribe_all',
    DeleteAll = 'delete_all',
}

export enum RenameUrl {
    Vacancy = '/vacancysavedsearch/rename',
    Resume = '/resumesavedsearch/rename',
}
