import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const UPDATE_FLAG = 'UPDATE_FLAG';

export enum VacancyResponseFlag {
    AutoActionEnabled = 'autoActionEnabled',
    HasMultipleVacancies = 'hasMultipleVacancies',
    CanCommentResumes = 'canCommentResumes',
    IsArchived = 'isArchived',
    ShowGiftedResumes = 'showGiftedResumes',
}

interface PayloadTypes {
    [UPDATE_FLAG]: [name: VacancyResponseFlag, value: boolean];
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateFlag = ActionCreator(UPDATE_FLAG);

type VacancyResponseFlags = {
    [key in VacancyResponseFlag]?: boolean;
};

export default createReducer<VacancyResponseFlags, PayloadTypes>(
    {},
    {
        [UPDATE_FLAG]: (state, { payload }) => ({ ...state, [payload[0]]: payload[1] }),
    }
);
