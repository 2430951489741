import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import {
    RegionTypes,
    VacancyGroupsState,
    VacanciesGroup,
    VacanciesToProfRole,
    ProfRoleExpanded,
    FirstPageLoadedWithRoles,
} from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

const RESET_TO_DEFAULT = 'RESET_TO_DEFAULT';
const ADD_VACANCIES_TO_PROFROLES = 'ADD_VACANCIES_TO_PROFROLES';
const SET_PROFROLE_EXPANDED = 'SET_PROFROLE_EXPANDED';
const SET_FIRST_PAGE_LOADED_WITH_ROLES = 'SET_FIRST_PAGE_LOADED_WITH_ROLES';
const ADD_PROF_ROLES = 'ADD_PROF_ROLES';

const INITIAL_STATE = {
    [RegionTypes.Current]: {},
};

interface PayloadTypes {
    [RESET_TO_DEFAULT]: VacancyGroupsState;
    [ADD_VACANCIES_TO_PROFROLES]: VacanciesToProfRole;
    [SET_PROFROLE_EXPANDED]: ProfRoleExpanded;
    [SET_FIRST_PAGE_LOADED_WITH_ROLES]: FirstPageLoadedWithRoles;
    [ADD_PROF_ROLES]: VacanciesGroup;
}
const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const resetToDefault = ActionCreator(RESET_TO_DEFAULT);

export const addVacanciesToProfRole = ActionCreator(ADD_VACANCIES_TO_PROFROLES);

export const setProfRoleExpanded = ActionCreator(SET_PROFROLE_EXPANDED);

export const firstPageLoadedWithRoles = ActionCreator(SET_FIRST_PAGE_LOADED_WITH_ROLES);

export const addProfRoles = ActionCreator(ADD_PROF_ROLES);

export default createReducer<VacancyGroupsState, PayloadTypes>(INITIAL_STATE, {
    [RESET_TO_DEFAULT]: (state, { payload }) => {
        return {
            ...state,
            ...payload,
        };
    },
    [ADD_VACANCIES_TO_PROFROLES]: (state, { payload }) => {
        const regionPropName = payload.regionType;
        const newState = { ...state };
        const dataRegion = state[regionPropName];
        const modifedDataRegion = {
            ...dataRegion,
            countsByProfRole: dataRegion?.countsByProfRole?.map((profRole) => {
                if (profRole.profRoleId === payload.profRoleId) {
                    return {
                        ...profRole,
                        ...payload.data,
                        count: profRole.count,
                        vacancies:
                            profRole.vacancies && payload.data?.vacancies
                                ? [...profRole.vacancies, ...payload.data.vacancies]
                                : payload.data.vacancies,
                    };
                }
                return profRole;
            }),
        };
        newState[regionPropName] = modifedDataRegion;
        return newState;
    },
    [SET_PROFROLE_EXPANDED]: (state, { payload }) => {
        const regionPropName = payload.regionType;
        const newState = { ...state };
        const dataRegion = state[regionPropName];
        const modifedDataRegion = {
            ...dataRegion,
            countsByProfRole: dataRegion?.countsByProfRole?.map((profRole) => {
                if (profRole.profRoleId === payload.profRoleId) {
                    return {
                        ...profRole,
                        expanded: payload.expanded,
                    };
                }
                return profRole;
            }),
        };
        newState[regionPropName] = modifedDataRegion;
        return newState;
    },
    [SET_FIRST_PAGE_LOADED_WITH_ROLES]: (state, { payload }) => {
        const regionPropName = payload.regionType;
        const newState = { ...state };
        const dataRegion = state[regionPropName];
        const modifedDataRegion = {
            ...dataRegion,
            countsByProfRole: dataRegion?.countsByProfRole?.map((profRole) => {
                if (profRole.profRoleId === payload.profRoleId) {
                    return {
                        ...profRole,
                        firstPageLoaded: true,
                    };
                }
                return profRole;
            }),
        };
        newState[regionPropName] = modifedDataRegion;
        return newState;
    },
    [ADD_PROF_ROLES]: (state, { payload }) => {
        return {
            ...state,
            currentRegion: {
                ...state.currentRegion,
                count: payload.count,
                countsByProfArea: payload.countsByProfArea,
                countsByProfCategory: payload.countsByProfCategory,
                countsByProfRole: payload.countsByProfRole,
                vacancies: payload.vacancies,
            },
        };
    },
});
