import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { LangsList } from 'src/models/resume/resumeCommon.types';

interface ResumeFormVacancy {
    id: LangsList;
    text: string;
}

export default autoGeneratedReducer<ResumeFormVacancy[]>([]);
