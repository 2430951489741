import { FC, SyntheticEvent } from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import HelpModalButtonLink from 'src/components/SupernovaMainMenu/HelpModal/HelpModalButtonLinks/HelpModalButtonLink';

import styles from './help-modal-button-links.less';

const HelpModalButtonLinks: FC<{
    item?: NavItem;
    handleCloseModal: (event?: SyntheticEvent) => void;
    onClose?: () => void;
}> = ({ item, handleCloseModal, onClose }) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;

    const helpModalButtonLinks = item?.subItems;

    if (!helpModalButtonLinks?.length) {
        return null;
    }

    const isSupportChatEnabled = helpModalButtonLinks.some((item) => item.name === 'supportChat');
    const numberOfCalculatedElements = helpModalButtonLinks.length - (isSupportChatEnabled && !isEmployer ? 1 : 0);
    const wideCardCondition = (item: NavItem, index: number) =>
        (item.name === 'supportChat' && !isEmployer) || (!!(numberOfCalculatedElements % 2) && index === 0);

    return (
        <>
            {isEmployer && <VSpacing default={24} />}
            <div className={styles.helpButtonLinksContainer}>
                {helpModalButtonLinks.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={classnames({
                                [styles.helpButtonLinkContainerWide]: wideCardCondition(item, index),
                            })}
                        >
                            <HelpModalButtonLink item={item} handleCloseModal={handleCloseModal} onClose={onClose} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default HelpModalButtonLinks;
