import {
    cloneElement,
    InputHTMLAttributes,
    FocusEventHandler,
    DetailedHTMLProps,
    FC,
    ReactElement,
    useState,
    PropsWithChildren,
} from 'react';

import { InputProps } from 'bloko/blocks/inputText';
import Suggest, { SuggestProps } from 'bloko/blocks/suggest';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import MobileSuggest, { MobileSuggestProps } from 'src/components/SupernovaSearch/SmartSuggest/MobileSuggest';

interface SmartSuggestProps extends SuggestProps {
    submitButtonTitle?: MobileSuggestProps['submitButtonTitle'];
    onSubmit?: MobileSuggestProps['onSubmit'];
    mobileInputProps?: InputProps;
    inputValue?: string;
    onMobileSuggestShown?: () => void;
}

const SmartSuggest: FC<SmartSuggestProps & PropsWithChildren> = ({
    children,
    onSubmit,
    submitButtonTitle,
    mobileInputProps,
    inputValue,
    onMobileSuggestShown,
    ...suggestProps
}) => {
    const [showXsModal, setShowXsModal] = useState<boolean>(false);
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;

    const renderClonedInputElement = () => {
        const input = children as DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

        const handleInputFocus: FocusEventHandler<HTMLInputElement> = (...rest) => {
            input.onFocus?.(...rest);
            setShowXsModal(true);
            onMobileSuggestShown?.();
        };
        return cloneElement(input as ReactElement, { onFocus: handleInputFocus, value: inputValue || '' });
    };

    if (!isXs) {
        return <Suggest {...suggestProps}>{children}</Suggest>;
    }

    return (
        <>
            {renderClonedInputElement()}
            {showXsModal && (
                <MobileSuggest
                    isShown={showXsModal}
                    onClose={() => setShowXsModal(false)}
                    onSubmit={(inputValue) => {
                        onSubmit?.(inputValue);
                        setShowXsModal(false);
                    }}
                    submitButtonTitle={submitButtonTitle}
                    dataProvider={suggestProps.dataProvider}
                    value={inputValue}
                    onChange={suggestProps.onChange}
                    itemContent={suggestProps.itemContent}
                    debounceDelay={suggestProps.debounceDelay}
                    limit={suggestProps.limit}
                    inputProps={mobileInputProps}
                />
            )}
        </>
    );
};

export default SmartSuggest;
