type ListenerType = (event: CustomEvent) => void;

const events: Array<CustomEvent> = [];
const listeners: Array<ListenerType | null> = [];

export const subscribeToNotLuxDataUpdate = (listener: ListenerType): (() => void) => {
    events.forEach(listener);
    listeners.push(listener);
    const index = listeners.length - 1;
    return () => {
        listeners[index] = null;
    };
};

export const dispatchNotLuxDataUpdate = (detail: unknown): void => {
    const event = new CustomEvent('HH-NotLuxData-Updated', { detail });
    listeners.forEach((listener) => {
        listener !== null && listener(event);
    });
    events.push(event);
};
