import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ConfigState {
    hhcdnHost: string;
    staticHost: string;
    imageResizingCdnHost: string;
    devBuildNotifyEnabled: boolean;
}

export default autoGeneratedReducer<ConfigState>({
    hhcdnHost: '',
    staticHost: '',
    imageResizingCdnHost: '',
    devBuildNotifyEnabled: false,
});
