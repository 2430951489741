import { Dispatch } from 'react';

import { Action } from '@hh.ru/redux-create-reducer';

import statsSender from 'HHC/Performance/StatsSender';
import { AppStore } from 'src/app/store';
import {
    deleteMicroFrontend,
    MicroFrontendServiceName,
    RemoteServiceName,
    SerivceInfo,
} from 'src/models/microFrontends';
import { addUserNotifications } from 'src/models/userNotifications';
import fetcher from 'src/utils/fetcher';
import { getStore } from 'src/utils/typedStore';

interface ServiceResponse {
    renderResult: string;
    inlineScript?: string;
    styles?: string[];
    scripts: string[];
    globalServiceName: MicroFrontendServiceName;
    remoteServiceName?: RemoteServiceName;
    remoteEntry?: string;
    isSuccessSSR: boolean;
    proxiedState?: Partial<AppStore>;
    noContent?: boolean;
}

interface RemoteWebpackModule {
    init: (moduleName: string) => Promise<() => { default: () => void }>;
    get: (moduleName: string) => Promise<() => { default: () => void }>;
    inited: boolean;
}

declare global {
    interface FetcherGetApi {
        [`SERVICE_URL`]: {
            response: ServiceResponse;
            queryParams: unknown;
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface Window extends Record<RemoteServiceName, RemoteWebpackModule> {}
}

interface ScriptAttributes {
    src?: string;
    type: string;
    innerHTML?: string;
    crossOrigin?: string;
    onload?: () => void;
    onerror?: (src?: string) => void;
    remote?: RemoteServiceName;
}

interface LinkAttributes {
    href: string;
    type: string;
    rel: string;
}

const putLink = (props: LinkAttributes, context: HTMLElement) => {
    const node = document.createElement('link');

    const promise = new Promise<void>((resolve) => {
        node.addEventListener('load', () => {
            resolve();
        });
    });

    Object.keys(props).forEach((prop) => {
        node[prop] = props[prop];
    });

    context.appendChild(node);
    return promise;
};

const putScript = ({ remote, onerror, onload, ...props }: ScriptAttributes, context: HTMLElement) => {
    const node = document.createElement('script');

    Object.entries(props).forEach(([prop, value]) => {
        const attr = prop as Exclude<keyof ScriptAttributes, 'onload' | 'onerror' | 'remote'>;
        node[attr] = value;
    });

    if (onload) {
        node.addEventListener('load', onload);
    }

    if (onerror) {
        node.addEventListener('error', () => onerror(props.src));
    }

    if (remote) {
        node.dataset.webpack = remote;
    }

    context.appendChild(node);
};

const getRemoteWebpackModuleLink = (remote: RemoteServiceName) => {
    const linkToRemoteWebpackModule = window[remote];
    return linkToRemoteWebpackModule;
};

const getExistingRemote = (remote: RemoteServiceName | undefined): HTMLScriptElement | null => {
    if (!remote) {
        return null;
    }
    return document.querySelector(`[data-webpack="${remote}"]`);
};

const loadingRemoteEntry: Record<string, Promise<void>> = {};

const getOrLoadRemote = async (
    remote: RemoteServiceName,
    shareScope: string,
    remoteFallbackUrl: string,
    container: HTMLElement
) => {
    const loadEntry = new Promise<void>((resolve) => {
        const existingRemote = getExistingRemote(remote);
        const onload = async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await __webpack_init_sharing__('default');
            const linkToRemoteWebpackModule = getRemoteWebpackModuleLink(remote);

            if (linkToRemoteWebpackModule.inited) {
                resolve();
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line camelcase,@typescript-eslint/no-unsafe-member-access
            await linkToRemoteWebpackModule.init(__webpack_share_scopes__[shareScope] as string);
            linkToRemoteWebpackModule.inited = true;
            resolve();
        };

        const onError = (src?: string) => {
            let remoteScriptResource: PerformanceEntry[] | undefined;

            if (PerformanceObserver?.supportedEntryTypes?.includes?.('resource')) {
                remoteScriptResource = performance.getEntriesByType('resource').filter((entry) => {
                    return src ? entry.name.includes(src) : false;
                });
            }

            const remoteScriptResourceDuration = remoteScriptResource?.reduce((prev, item) => prev + item.duration, 0);

            statsSender.sendMetrics({
                metricName: 'mf-load-error',
                valueInMs: remoteScriptResourceDuration,
            });
        };

        function loadScript() {
            putScript(
                {
                    src: remoteFallbackUrl,
                    type: 'text/javascript',
                    crossOrigin: 'anonymous',
                    onload,
                    onerror: onError,
                    remote,
                },
                container
            );
        }

        if (!(remote in window)) {
            if (existingRemote) {
                void loadingRemoteEntry[remote].then(() => {
                    void onload();
                });
            } else {
                loadScript();
            }
        } else {
            void onload();
        }
    });

    loadingRemoteEntry[remote] = loadingRemoteEntry[remote] ?? loadEntry;
    return loadEntry;
};

interface AppOptions {
    unmountApp?: boolean;
    container: HTMLDivElement;
    identifier: string;
    place: string;
}

export interface RemoteModule {
    (options: AppOptions): void;
}
export type RemoteModuleOrNull = RemoteModule | null;
export type RemoteModuleWithGlobalServiceNameOrNull = [RemoteModuleOrNull, MicroFrontendServiceName] | null;

const loggingPlaces: Record<string, { hasDefault: boolean; moduleValue: number }> = {};

const getRemoteModule = async (
    remote: RemoteServiceName | undefined,
    url: string | undefined,
    container: HTMLDivElement
): Promise<RemoteModuleOrNull> => {
    if (remote && url) {
        try {
            await getOrLoadRemote(remote, 'default', url, container);
        } catch (_) {
            return null;
        }
        const linkToRemoteWebpackModule = getRemoteWebpackModuleLink(remote);
        const factory = await linkToRemoteWebpackModule.get('./App');

        const Module = factory();

        loggingPlaces[container.classList.value] = {
            hasDefault: true,
            moduleValue: Module.default.toString().length,
        };

        return Module.default;
    }

    return null;
};

const getAndLoadARemoteApp = async (
    remote: RemoteServiceName | undefined,
    globalServiceName: MicroFrontendServiceName,
    url: string | undefined,
    container: HTMLDivElement,
    identifier: string,
    place: string
): Promise<RemoteModuleOrNull> => {
    let initApp = await getRemoteModule?.(remote, url, container);

    if (window.globalServiceVars?.[globalServiceName]?.hasSupportToDestroyApp && initApp) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initApp = initApp();
    }
    initApp?.({ unmountApp: false, container, identifier, place });
    return initApp;
};

type StateLoaderMapping = {
    [K in keyof AppStore]?: LoaderFunction<AppStore[K]>;
};
type LoaderFunction<T> = (dispatch: Dispatch<Action>, value: T) => void;
type ProxiedState = Partial<AppStore>;

const STATE_LOADERS_MAPPING: StateLoaderMapping = {
    userNotifications: (dispatch, value) => {
        dispatch(addUserNotifications(value));
    },
} as const;

const processProxiedState = async (proxiedState: ProxiedState) => {
    const store = await getStore();
    Object.keys(STATE_LOADERS_MAPPING).forEach((storeFieldName) => {
        const value = proxiedState[storeFieldName];
        const loader = STATE_LOADERS_MAPPING[storeFieldName];
        if (value !== undefined && loader) {
            (loader as LoaderFunction<typeof value>)(store.dispatch, value);
        }
    });
};

const loadProxyService = async (
    place: string,
    serviceInfo: SerivceInfo,
    container: HTMLDivElement | null,
    identifier: string,
    isFullPage?: boolean
): Promise<RemoteModuleWithGlobalServiceNameOrNull> => {
    if (serviceInfo === undefined) {
        return null;
    }
    const loadService = async (data: ServiceResponse): Promise<RemoteModuleWithGlobalServiceNameOrNull> => {
        if (container === null) {
            return null;
        }

        container.classList.add(`HH-ProxyExternalServiceLoader-${data.globalServiceName}`);

        if (data.isSuccessSSR && data.renderResult !== '' && data.remoteServiceName) {
            if (data.inlineScript) {
                putScript(
                    {
                        type: 'text/javascript',
                        innerHTML: data.inlineScript,
                    },
                    container
                );
            }

            const moduleApp = await getAndLoadARemoteApp(
                data.remoteServiceName,
                data.globalServiceName,
                data.remoteEntry,
                container,
                identifier,
                place
            );
            return [moduleApp, data.globalServiceName];
        }

        container.innerHTML = '';
        const ssrRenderNode = document.createElement('div');
        ssrRenderNode.innerHTML = data.renderResult;
        container.appendChild(ssrRenderNode);

        data.styles?.forEach((href) => {
            const attr: LinkAttributes = { href, type: 'text/css', rel: 'stylesheet' };
            void putLink(attr, container);
        });

        if (data.inlineScript) {
            putScript(
                {
                    type: 'text/javascript',
                    innerHTML: data.inlineScript,
                },
                container
            );
        }

        data.scripts?.forEach((src) => {
            putScript({ src: `${src}`, type: 'text/javascript', crossOrigin: 'anonymous' }, container);
        });

        // TODO Удалить все условие после перехода фронтов на front-static-app >= 1.1.2
        if (data.remoteEntry) {
            const notLoadedScript = getExistingRemote(data.remoteServiceName);

            /**
             * Скрипт, который был вставлен через innerHTML загружен не будет, нужно отличать от случая, когда скрипт
             * кладет SSR. В случае с SSR мы используем уже загруженный скрипт
             */
            if (notLoadedScript) {
                notLoadedScript.dataset.webpack = '';
            }

            const moduleApp = await getAndLoadARemoteApp(
                data.remoteServiceName,
                data.globalServiceName,
                data.remoteEntry,
                container,
                identifier,
                place
            );
            return [moduleApp, data.globalServiceName];
        }

        return null;
    };

    const renderResult = container?.innerHTML ?? '';

    if ((serviceInfo.isSuccessSSR && renderResult !== '') || serviceInfo.useSSRResult) {
        return loadService({
            scripts: serviceInfo.scripts ?? [],
            styles: serviceInfo.styles ?? [],
            globalServiceName: serviceInfo.globalServiceName,
            remoteServiceName: serviceInfo.remoteServiceName,
            inlineScript: serviceInfo.inlineScript,
            remoteEntry: serviceInfo.remoteEntry,
            renderResult: renderResult ?? '',
            isSuccessSSR: serviceInfo.isSuccessSSR,
        });
    }

    let data;
    try {
        data = await fetcher.get<'SERVICE_URL'>(serviceInfo.url, {
            headers: {
                'X-Proxied-Type': isFullPage ? '' : 'Component',
                'X-Proxied-Place': place,
                'X-Proxied-Page-Name': window.globalVars.pageName,
                'X-Proxied-Hhtm-Source': window.globalVars.analyticsParams.hhtmSource,
                'X-Static-Version': window.globalVars.build,
            },
            params: {},
        });
    } catch (error) {
        console.error(error);
        return null;
    }

    if (data.noContent) {
        const store = await getStore();
        store.dispatch(deleteMicroFrontend(place));
        return null;
    }

    void processProxiedState(data.proxiedState || {});
    return loadService(data);
};

export default loadProxyService;
