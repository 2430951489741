import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import Text from 'bloko/blocks/text';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { Breakpoint } from 'bloko/common/media';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './new-year.less';

const TrlKeys = {
    emoji: 'marketing.special.b2cpm.newyear.notification.emoji',
    title: 'marketing.special.b2cpm.newyear.notification.title',
    description: 'marketing.special.b2cpm.newyear.notification.description',
    link: 'marketing.special.b2cpm.newyear.notification.link',
};

const analyticsName = 'newyear_notification';

const NewYear: TranslatedComponent<SimpleNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const closeNotificationRef = useRef<VoidFunction>(null);
    const link = useSelector((state) => state.newYearNotificationLink);
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const isSmallScreen = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);
    if (isSmallScreen) {
        return null;
    }
    return (
        <SynchronizedNotification
            analyticsElementName={analyticsName}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            markAsViewedEvent={MarkAsViewedEvent.OnShow}
            onClose={onClose}
            closeEvent={`${analyticsName}_close`}
            kind={NotificationKind.Ok}
        >
            <span className={styles.emoji}>{trls[TrlKeys.emoji]}</span>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <Text>{trls[TrlKeys.description]}</Text>
            <NotificationFooter>
                <Button
                    scale={ButtonScale.Small}
                    Element={Link}
                    target="_blank"
                    onClick={() => {
                        Analytics.sendHHEventButtonClick(`${analyticsName}_link`);
                        closeNotificationRef.current?.();
                    }}
                    to={link}
                    appearance={ButtonAppearance.Filled}
                    kind={ButtonKind.Success}
                >
                    {trls[TrlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(NewYear);
