import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    link: 'supernova.notification.menu.setka.promo.applicant.link',
    content: 'supernova.notification.menu.setka.promo.applicant.content',
};

const SetkaPromo: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return (
        <Notification
            dataQa={DataQaInvariants.SetkaPromo}
            {...notification}
            iconKind={SupernovaUserNotificationsIconKind.Setka}
            onRemove={() => {
                Analytics.sendHHEventButtonClick('setka_promo_link_click_remove', { from: 'notification' });
            }}
        >
            <NotificationSpacer>
                <p>{trls[TrlKeys.content]}</p>
            </NotificationSpacer>
            <NotificationLink
                to="https://4284841.redirect.appmetrica.yandex.com/?appmetrica_tracking_id=317055592281706223&utm_medium=notification&utm_source=site_hh&utm_campaign=notification_in_bell_0624"
                target="_blank"
                onClick={() => Analytics.sendHHEvent('setka_promo_supernova_link_click')}
            >
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(SetkaPromo);
