import axios from 'HHC/Axios';
import Debug from 'HHC/Debug';

export const markUserNotificationsAsViewed = async (ids: number[] | string[] | string | number): Promise<void> => {
    try {
        await axios.post(
            '/shards/notifications/mark_as_viewed',
            Array.isArray(ids) ? ids.map(Number) : ([] as unknown[]).concat(ids).map(Number)
        );
    } catch (err) {
        Debug.log('error', err);
    }
};
