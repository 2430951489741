import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import { PfpBalance } from 'src/models/price/pricePayForPerformance';

export enum PfpStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED',
}

export interface PfpDetails {
    currency: CurrencyType;
    balance?: PfpBalance;
    lowBalance?: boolean;
    relevantActionPrice: number;
}

export interface PfpInfo {
    pfpStatus: PfpStatus;
    pfpDetails?: PfpDetails;
}

const SET_PFP_INFO = 'SET_PFP_INFO';

interface PayloadPfpInfoTypes {
    [SET_PFP_INFO]: PfpInfo;
}

const actionCreator = ActionCreatorHelper<PayloadPfpInfoTypes>();

export const setPfpInfo = actionCreator(SET_PFP_INFO);

export default createReducer<PfpInfo, PayloadPfpInfoTypes>(null, {
    [SET_PFP_INFO]: (_state, { payload }) => {
        return { ...payload };
    },
});
