import { ComponentType, RefObject, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Action,
    ActionList,
    Modal,
    BottomSheet,
    BottomSheetFooter,
    NavigationBar,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';

import translation from 'src/components/translation';

import { useCopyStep, useImportStep } from 'src/components/ImportResumeModal/hooks';
import AuthStep from 'src/components/ImportResumeModal/steps/AuthStep';
import ChooseStep from 'src/components/ImportResumeModal/steps/ChooseStep';
import CopyFooter from 'src/components/ImportResumeModal/steps/CopyStep/CopyFooter';
import CopyStep from 'src/components/ImportResumeModal/steps/CopyStep/CopyStep';
import ErrorFooter from 'src/components/ImportResumeModal/steps/ErrorStep/ErrorFooter';
import ErrorStep from 'src/components/ImportResumeModal/steps/ErrorStep/ErrorStep';
import ImportFooter from 'src/components/ImportResumeModal/steps/ImportStep/ImportFooter';
import ImportStep from 'src/components/ImportResumeModal/steps/ImportStep/ImportStep';
import { Step, Steps } from 'src/components/ImportResumeModal/types';

interface ImportResumeModalProps {
    onClose: () => void;
    visible: boolean;
    url: string;
    activatorRef?: RefObject<HTMLElement>;
}

const blockCloseBlokoDrop = makeSetStoreField('blockCloseMobileDrop');

const TrlKeys = {
    title: 'zp.importResume.v2.title',
};

const ImportResumeModal: ComponentType<ImportResumeModalProps> = translation(
    ({ trls, onClose, visible, url, activatorRef }) => {
        const dispatch = useDispatch();
        const [step, setStep] = useState<Step>(Step.Choose);

        const { isMobile } = useBreakpoint();

        useEffect(() => {
            dispatch(blockCloseBlokoDrop(visible));
            return () => {
                blockCloseBlokoDrop(false);
            };
        }, [dispatch, visible]);

        const handleSetStep = useCallback((step: Step) => setStep(step), []);

        const onAuth = () => {
            setStep(Step.Auth);
        };
        const onChoose = () => {
            setStep(Step.Choose);
        };

        const [copyStep, copyStepHandlers] = useCopyStep(onClose);
        const { copyValue, copyError } = copyStep;
        const { onCopyAgain, onCopySelect, onSetCopyValue, resetCopyError } = copyStepHandlers;

        const [importStep, importStepHandlers] = useImportStep(onClose);
        const { importValue, importError } = importStep;
        const { onImportAgain, onImportSelect, onSetImportValue, resetImportError } = importStepHandlers;

        useEffect(() => {
            if (visible) {
                setStep(Step.Choose);
            }
        }, [visible]);

        useEffect(() => {
            if (!visible) {
                onSetCopyValue('');
                onSetImportValue('');
                resetCopyError();
                resetImportError();
            }
        }, [visible, onSetCopyValue, onSetImportValue, resetCopyError, resetImportError]);

        const STEPS: Steps = {
            [Step.Choose]: {
                content: <ChooseStep url={url} onSetStep={handleSetStep} />,
            },
            [Step.Auth]: {
                content: <AuthStep onSetStep={handleSetStep} />,
            },
            [Step.Import]: {
                content: (
                    <ImportStep
                        value={importValue}
                        error={importError}
                        onSetValue={onSetImportValue}
                        onSetStep={handleSetStep}
                    />
                ),
                footer: (
                    <ImportFooter
                        value={importValue}
                        error={importError}
                        onAgain={onImportAgain}
                        onSelect={onImportSelect}
                        onAuth={onAuth}
                    />
                ),
            },
            [Step.Copy]: {
                content: (
                    <CopyStep
                        onSetStep={handleSetStep}
                        onSetValue={onSetCopyValue}
                        value={copyValue}
                        error={copyError}
                    />
                ),
                footer: (
                    <CopyFooter value={copyValue} error={copyError} onAgain={onCopyAgain} onSelect={onCopySelect} />
                ),
            },
            [Step.Error]: {
                content: <ErrorStep />,
                footer: <ErrorFooter onAuth={onAuth} onChoose={onChoose} />,
            },
        };

        const content = STEPS[step].content;
        const footer = STEPS[step].footer;

        if (step === Step.Choose && activatorRef && !isMobile) {
            <ActionList
                visible={visible}
                onClose={onClose}
                dropProps={{
                    activatorRef,
                    maxWidth: 350,
                    placement: 'bottom-right',
                }}
            >
                {content}
            </ActionList>;
        }

        if (isMobile) {
            return (
                <>
                    <BottomSheet
                        header={
                            step !== Step.Choose ? (
                                <NavigationBar
                                    title={trls[TrlKeys.title]}
                                    right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                                    showDivider="always"
                                />
                            ) : undefined
                        }
                        visible={visible}
                        onClose={onClose}
                        footer={!!footer && <BottomSheetFooter>{footer}</BottomSheetFooter>}
                    >
                        {content}
                    </BottomSheet>
                </>
            );
        }

        return (
            <Modal
                data-qa="zp-import-resume-modal"
                title={trls[TrlKeys.title]}
                visible={visible}
                onClose={onClose}
                actions={
                    <Action
                        mode="primary"
                        style="neutral"
                        disablePadding
                        showBackground={false}
                        onClick={onClose}
                        icon={CrossOutlinedSize24}
                    />
                }
                footer={!!footer && <ActionBar primaryActions={footer} />}
            >
                {content}
            </Modal>
        );
    }
);

export default ImportResumeModal;
