import { useState } from 'react';

import headerGeoDefinitionEmptyResultShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_empty_result_element_shown';
import headerGeoDefinitionSuggestButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_button_click';
import {
    Cell,
    CellText,
    CheckableCard,
    Radio,
    VSpacing,
    VSpacingContainer,
    Text,
    useBreakpoint,
    Card,
} from '@hh.ru/magritte-ui';
import { ArrowSquareOutlinedSize16, MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';

import styles from './styles.less';

export interface GeoSwitcherPopupRegionData {
    href: string;
    name: string;
    areaId?: number;
    region?: string;
    isCurrentHost?: boolean;
    isOtherDomain?: boolean;
    topLevelDomain?: string;
}

interface GeoSwitcherPopupRegionsListProps {
    items: GeoSwitcherPopupRegionData[];
    lastSearchQuery: string;
    isPopular?: boolean;
}

const TrlKeys = {
    notFoundTitle: 'geoSwitcher.popup.search.notFound.title',
    notFoundDescription: 'geoSwitcher.popup.search.notFound.description',
    popularTitle: 'geoSwitcher.popup.popular.title',
};

const GeoSwitcherPopupRegionsList: TranslatedComponent<GeoSwitcherPopupRegionsListProps> = ({
    items,
    trls,
    isPopular,
    lastSearchQuery,
}) => {
    const { isMobile } = useBreakpoint();
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const handleGeoSwitcherItemClick = ({ href, name, areaId }: GeoSwitcherPopupRegionData) => {
        if (typeof areaId === 'number') {
            headerGeoDefinitionSuggestButtonClick({ selectedArea: areaId.toString() });
        }
        window.location.href = href;
        setSelectedItem(name);
    };

    if (!items.length) {
        return (
            <ElementShownAnchor fn={headerGeoDefinitionEmptyResultShown} requestedArea={lastSearchQuery}>
                <div className={styles.notFound}>
                    <VSpacing default={64} xs={40} s={40} />
                    <div className={styles.notFoundIcon}>
                        <MagnifierOutlinedSize24 initial="tertiary" />
                    </div>
                    <VSpacing default={32} />
                    <Text typography="title-5-semibold">{trls[TrlKeys.notFoundTitle]}</Text>
                    <VSpacing default={8} />
                    <Text style="secondary" typography="paragraph-2-regular">
                        {trls[TrlKeys.notFoundDescription]}
                    </Text>
                    <VSpacing default={64} xs={40} s={40} />
                </div>
            </ElementShownAnchor>
        );
    }

    return (
        <div>
            {isPopular && (
                <Card padding={16}>
                    <Text style="secondary" typography="label-2-regular">
                        {trls[TrlKeys.popularTitle]}
                    </Text>
                </Card>
            )}
            <VSpacingContainer default={12} xs={8} s={8}>
                {items.map((item) => {
                    const { name, href, isCurrentHost, region, topLevelDomain, isOtherDomain } = item;
                    const checked = selectedItem ? selectedItem === name : !!isCurrentHost;

                    const radioEl = <Radio readOnly checked={checked} />;
                    const otherHostText = isOtherDomain && (
                        <div className={styles.hostText}>
                            <ArrowSquareOutlinedSize16 initial="secondary" />
                            <Text style="secondary" typography="label-3-regular">
                                {topLevelDomain}
                            </Text>
                        </div>
                    );

                    return (
                        <CheckableCard
                            onChange={() => handleGeoSwitcherItemClick(item)}
                            key={`${name}-${href}`}
                            type="radio"
                            padding={16}
                            borderRadius={16}
                            checked={checked}
                        >
                            <Cell
                                right={isMobile ? radioEl : otherHostText || undefined}
                                left={isMobile ? undefined : radioEl}
                            >
                                <CellText>{name}</CellText>
                                {region && <CellText type="subtitle">{region}</CellText>}
                                {isMobile && otherHostText ? (
                                    <CellText type="subtitle">{otherHostText}</CellText>
                                ) : null}
                            </Cell>
                        </CheckableCard>
                    );
                })}
            </VSpacingContainer>
        </div>
    );
};

export default translation(GeoSwitcherPopupRegionsList);
