import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyInternalInfo {
    vacancyId: number;
    vacancyPremoderateStatus: string;
    approved: boolean;
    canChangeClosureStatus: boolean;
    canBeProlongated: boolean;
    canBeArchived: boolean;
    userTestId: number;
    ownerEmployerManagerId: number;
    ownerEmployerManagerHhid: number;
    daysBeingPublic?: number;
    freeRestoreDays: number;
}

export default autoGeneratedReducer<VacancyInternalInfo>(null);
