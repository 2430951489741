import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Webcall {
    stable: boolean;
    enabled: boolean;
}

export default autoGeneratedReducer<Webcall>({
    stable: true,
    enabled: true,
});
