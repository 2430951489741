import { CountryId } from 'src/models/countryId';

import { useSelector } from 'src/hooks/useSelector';

export const useCountryId = (): CountryId => useSelector(({ countryId }) => countryId);

export const useIsRussia = (): boolean => {
    const countryId = useCountryId();
    return countryId === CountryId.Russia;
};

export const useIsBelarus = (): boolean => {
    const countryId = useCountryId();
    return countryId === CountryId.Belarus;
};

export const useIsKazakhstan = (): boolean => {
    const countryId = useCountryId();
    return countryId === CountryId.Kazakhstan;
};
