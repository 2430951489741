import React from 'react';

import { NavItem, NavItems } from 'src/models/supernovaNaviMenu';

import AccountActions from 'src/components/SupernovaMainMenu/AccountActions';
import ApplicantInfo from 'src/components/SupernovaMainMenu/ApplicantInfo';
import EmployerInfo from 'src/components/SupernovaMainMenu/EmployerInfo';
import EmployersReviews from 'src/components/SupernovaMainMenu/EmployersReviews';
import Kakdela from 'src/components/SupernovaMainMenu/Kakdela';
import LogoffSudoUser from 'src/components/SupernovaMainMenu/LogoffSudoUser';
import LogoffUser from 'src/components/SupernovaMainMenu/LogoffUser';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupportChatActivator from 'src/components/SupernovaMainMenu/SupportChatActivator';
import SwitchAccount from 'src/components/SupernovaMainMenu/SwitchAccount';
import UserInfo from 'src/components/SupernovaMainMenu/UserInfo';
import UserNotificationsMobile from 'src/components/SupernovaMainMenu/UserNotifications/Mobile';
import UserTypeSwitcher from 'src/components/SupernovaMainMenu/UserTypeSwitcher';

interface DropdownContentProps {
    item?: NavItem;
    items?: NavItems;
    onClose?: () => void;
    visible?: boolean;
}

const DropdownContent: React.VFC<DropdownContentProps> = ({ item, items, onClose, visible }) => {
    if (items) {
        return (
            <React.Fragment>
                {items.map((item) => {
                    return <DropdownContent key={item.name} item={item} onClose={onClose} />;
                })}
            </React.Fragment>
        );
    }

    if (item) {
        switch (item.name) {
            case 'applicantInfo':
                return (
                    <ApplicantInfo {...item}>
                        {item.subItems?.length ? <DropdownContent items={item.subItems} /> : null}
                    </ApplicantInfo>
                );
            case 'employerInfo':
                return (
                    <EmployerInfo {...item}>
                        <DropdownContent items={item.subItems} />
                    </EmployerInfo>
                );
            case 'employersReviews':
                return <EmployersReviews {...item} />;
            case 'kakdelaFeedbackPlatform':
                return <Kakdela {...item} />;
            case 'logoffUser':
                return <LogoffUser {...item} />;
            case 'accountActions':
                return (
                    <AccountActions {...item}>
                        <DropdownContent items={item.subItems} />
                    </AccountActions>
                );
            case 'logoffSudoUser':
                return <LogoffSudoUser {...item} />;
            case 'mainContent':
            case 'pricelist':
            case 'employerServices':
            case 'vacancy':
            case 'allServices':
            case 'pricelistZp':
            case 'pricelistShort':
            case 'pricelistZpShort':
            case 'myResumes':
            case 'vacancyResponses':
                return (
                    <SupernovaDropdownSection mainContent support={Boolean(item.params?.[0]?.support)}>
                        <DropdownContent items={item.subItems} onClose={onClose} />
                    </SupernovaDropdownSection>
                );
            case 'support':
            case 'help':
            case 'anonSupport':
                return null;
            case 'supportChat':
                return <SupportChatActivator {...item} close={onClose} />;
            case 'userInfo':
                return <UserInfo />;
            case 'userTypeSwitcherApplicant':
            case 'userTypeSwitcherEmployer':
                return <UserTypeSwitcher {...item} />;
            case 'userNotifications':
                return <UserNotificationsMobile visible={visible} close={onClose} />;
            case 'switchAccountEmployer':
            case 'switchAccountApplicant':
                return <SwitchAccount {...item} />;
        }

        return <SupernovaDropdownOption {...item}>{item.translations.name}</SupernovaDropdownOption>;
    }

    return null;
};

export default DropdownContent;
