import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setIsGeoSwitcherActive } from 'src/models/isGeoSwitcherActive';

import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import useSendCheckGeoSwitcherRedesignExp from 'src/hooks/useSendCheckGeoSwitcherRedesignExp';

const useHandleOpenGeoSwitcher = (defaultOpenGeoSwitcher = () => {}): (() => void) => {
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();
    const sendExpCheck = useSendCheckGeoSwitcherRedesignExp();
    const dispatch = useDispatch();

    return useCallback(() => {
        sendExpCheck();

        if (isRedesignGeoExpActive) {
            dispatch(setIsGeoSwitcherActive(true));
        } else {
            defaultOpenGeoSwitcher();
        }
    }, [defaultOpenGeoSwitcher, dispatch, isRedesignGeoExpActive, sendExpCheck]);
};

export default useHandleOpenGeoSwitcher;
