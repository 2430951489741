import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
interface TemplateType<T> {
    template: string;
    title: string;
    type: string;
    visibility: {
        sms: boolean;
        visibility: T;
    };
}

type VariableVisibilityType = 'COMMON' | 'ANONYMOUS' | 'BOTH';

export interface VariableType {
    name: string;
    translation: string;
    visibility: {
        sms: boolean;
        vacancy: boolean;
        visibility: VariableVisibilityType;
    };
}

interface PayloadTypes {
    [UPDATE_TEMPLATE]: { type: string; template: string };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateTemplate = ActionCreator(UPDATE_TEMPLATE);

interface EmployerMailTemplatesType {
    transliteration: {
        mapping: Record<string, string>;
        common: Record<string, string>;
        anonymous: Record<string, string>;
    };
    variables: VariableType[];
    mailTemplates: { anonymous: TemplateType<'ANONYMOUS'>[]; common: TemplateType<'COMMON'>[] };
}
export default createReducer<EmployerMailTemplatesType, PayloadTypes>(
    {
        mailTemplates: { anonymous: [], common: [] },
        variables: [],
        transliteration: { mapping: {}, common: {}, anonymous: {} },
    },
    {
        [UPDATE_TEMPLATE]: (state, action) => {
            const { type, template } = action.payload;

            return {
                ...state,
                mailTemplates: {
                    anonymous: state.mailTemplates.anonymous.map((value) => {
                        if (type === value.type) {
                            return {
                                ...value,
                                template,
                                visibility: { ...value.visibility },
                            };
                        }
                        return {
                            ...value,
                            visibility: { ...value.visibility },
                        };
                    }),
                    common: state.mailTemplates.common.map((value) => {
                        if (type === value.type) {
                            return {
                                ...value,
                                template,
                                visibility: { ...value.visibility },
                            };
                        }
                        return {
                            ...value,
                            visibility: { ...value.visibility },
                        };
                    }),
                },
                variables: state.variables.map((value) => ({ ...value, visibility: { ...value.visibility } })),
                transliteration: {
                    mapping: { ...state.transliteration.mapping },
                    common: { ...state.transliteration.common },
                    anonymous: { ...state.transliteration.anonymous },
                },
            };
        },
    }
);
