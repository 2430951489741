import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export interface Currency {
    code: CurrencyType;
    name: string;
    rate: number;
    site: boolean;
}

export interface Currencies {
    default: CurrencyType;
    list: Currency[];
}

export default autoGeneratedReducer<Currencies>({ default: CurrencyType.RUR, list: [] });
