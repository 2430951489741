import { useEffect, useRef, VFC } from 'react';

import notificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/notifications/notification_element_shown';

interface NotificationAnalyticsProps {
    notificationName: string;
    children: React.ReactNode;
}

const NotificationAnalytics: VFC<NotificationAnalyticsProps> = ({ notificationName, children }) => {
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            notificationElementShown(elementRef.current, { notificationName });
        }
    }, [notificationName]);
    return <div ref={elementRef}>{children}</div>;
};

export default NotificationAnalytics;
