import { useRef, ReactElement, ComponentProps, useEffect, SyntheticEvent } from 'react';

import Analytics from '@hh.ru/analytics-js';
import notVerirfiedStatusTooltipElementShown, {
    ViewLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/not_verirfied_status_tooltip_element_shown';
import { Tooltip, Text, BottomSheet, VSpacing, Button, BottomSheetFooter, TooltipHover } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TooltipType } from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

import styles from './styles.less';

interface NotVerifiedEmployerIconProps {
    icon: ReactElement;
    tipContent: ReactElement | string;
    placement: ComponentProps<typeof Tooltip>['placement'];
    actionButton?: ReactElement;
    tooltipActionProps?: { buttons?: (onClose: VoidFunction) => ReactElement; showClose: boolean };
    analyticsParams?: {
        vacancyId?: string;
        draftId?: string;
        viewLabel: ViewLabel;
        tooltipType: TooltipType;
    };
    shouldShowTooltip?: boolean;
    isHoverTip?: boolean;
}

const TrlKeys = {
    close: 'employer.notVerifiedBanner.close',
};

const NotVerifiedEmployerIcon: TranslatedComponent<NotVerifiedEmployerIconProps> = ({
    trls,
    icon,
    tipContent,
    placement,
    actionButton,
    tooltipActionProps,
    analyticsParams,
    shouldShowTooltip = true,
    isHoverTip = false,
}) => {
    const [isVisible, showTooltip, hideTooltip] = useOnOffState(false);
    const activatorRef = useRef<HTMLSpanElement>(null);
    const notVerirfiedStatusTooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isVisible && notVerirfiedStatusTooltipRef.current && analyticsParams) {
            notVerirfiedStatusTooltipElementShown(notVerirfiedStatusTooltipRef.current, analyticsParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickIcon = (e: SyntheticEvent) => {
        e.stopPropagation();

        showTooltip();
    };

    return (
        <>
            <span className={styles.verificationEmployerIcon} ref={activatorRef} onClick={handleClickIcon}>
                {icon}
            </span>
            {shouldShowTooltip && (
                <div ref={notVerirfiedStatusTooltipRef}>
                    <BottomSheet
                        visible={isVisible}
                        footer={
                            <BottomSheetFooter>
                                <VSpacing default={12} />
                                {actionButton}
                                <Button mode="secondary" style="accent" onClick={hideTooltip}>
                                    {trls[TrlKeys.close]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        onClose={hideTooltip}
                    >
                        <>
                            <VSpacing default={16} />
                            <Text typography="label-1-regular">{tipContent}</Text>
                        </>
                    </BottomSheet>
                    {isHoverTip ? (
                        <TooltipHover
                            onClose={hideTooltip}
                            activatorRef={activatorRef}
                            placement={placement}
                            forcePlacement
                            onAppear={() =>
                                // temp_exp_35925_next_line
                                Analytics.sendHHEvent('element_shown', {
                                    elementName: 'verification_status_tooltip_hover',
                                    viewLabel: analyticsParams?.viewLabel,
                                    tooltipType: analyticsParams?.tooltipType,
                                })
                            }
                            {...tooltipActionProps}
                        >
                            <Text>{tipContent}</Text>
                        </TooltipHover>
                    ) : (
                        <Tooltip
                            visible={isVisible}
                            onClose={hideTooltip}
                            activatorRef={activatorRef}
                            placement={placement}
                            {...tooltipActionProps}
                        >
                            <Text>{tipContent}</Text>
                        </Tooltip>
                    )}
                </div>
            )}
        </>
    );
};
export default translation(NotVerifiedEmployerIcon);
