import { ITEMS_ON_CHAMELEON_PAGE } from 'src/models/employerVacancySearch/const';
import { getTemplateCriteria } from 'src/models/employerVacancyTemplateFilter/getTemplateCriteria';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import { GetSearchParamsArgs } from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/types';
import getVacancySearchParams, {
    VacancySearchParams,
} from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancy';

export interface EmployerVacancySearchParams extends VacancySearchParams {
    [CriteriaKey.CurrentEmployerId]: number;
    [CriteriaKey.Department]?: string;
    [CriteriaKey.ItemsOnPage]?: string;
    [CriteriaKey.HiddenFilters]?: string[];
}

const getEmployerVacancySearchParams = (args: GetSearchParamsArgs): EmployerVacancySearchParams => {
    const vacancySearchParams = getVacancySearchParams(args);
    const { searchLineText, criteria } = args.employerVacancySearch;
    const templateFilter = { ...args.employerVacancyTemplateFilter };
    return {
        ...vacancySearchParams,
        ...templateFilter,
        [CriteriaKey.CurrentEmployerId]: Number(args.employerInfo.id),
        [CriteriaKey.ItemsOnPage]: args.hasChameleon ? ITEMS_ON_CHAMELEON_PAGE : undefined,
        [CriteriaKey.HiddenFilters]: getTemplateCriteria(templateFilter),
        [CriteriaKey.Text]: searchLineText ?? criteria?.[CriteriaKey.Text] ?? '',
        [CriteriaKey.SearchField]:
            templateFilter[CriteriaKey.SearchField] && !searchLineText
                ? templateFilter[CriteriaKey.SearchField]
                : undefined,
    } as EmployerVacancySearchParams;
};

export default getEmployerVacancySearchParams;
