import { FC } from 'react';

import BrandingTriggerNotification from 'src/components/BrandingTriggerNotification';
import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import { ComplexUserNotification } from 'src/models/userNotifications';

const BrandingTriggerSupernovaNotification: FC<ComplexUserNotification> = (notification) => (
    <BrandingTriggerNotification
        place="supernova"
        LinkComponent={NotificationLink}
        notificationId={notification.id}
        templateKey={notification.templateKey}
    >
        {({ Content, Action, sendOnRemoveEvent }) => (
            <Notification
                {...notification}
                iconKind={SupernovaUserNotificationsIconKind.Info}
                dataQa={DataQaInvariants.Info}
                onRemove={sendOnRemoveEvent}
            >
                <NotificationText>
                    <Content />
                </NotificationText>
                <Action />
            </Notification>
        )}
    </BrandingTriggerNotification>
);

export default BrandingTriggerSupernovaNotification;
