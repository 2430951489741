import React, { forwardRef, ForwardRefRenderFunction, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { Breakpoint } from '@hh.ru/magritte-ui';

import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';

import styles from './supernova-navi-item.less';

interface SupernovaNaviItemProps {
    active?: boolean;
    isButton?: boolean;
    userTypeSwitcher?: boolean;
    userTypeSegmented?: boolean;
    arrowed?: boolean;
    className?: string;
    visibleOn?: Breakpoint[];
    'data-qa'?: string;
}

const SupernovaNaviItem: ForwardRefRenderFunction<HTMLDivElement, SupernovaNaviItemProps & React.PropsWithChildren> = (
    { active, children, isButton, userTypeSwitcher, userTypeSegmented, arrowed = false, className, visibleOn, ...rest },
    ref
) => {
    const { pathname } = useLocation();
    const isDashboard = useContext(IsDashboardContext);

    return (
        <div
            className={classnames('supernova-navi-item', 'supernova-navi-item_lvl-2', className, {
                'supernova-navi-item_active': active,
                'supernova-navi-item_button': isButton,
                'supernova-navi-item_user-type-switcher': userTypeSwitcher,
                'supernova-navi-item_user-type-segmented': userTypeSegmented,
                'supernova-navi-item_dashboard': isDashboard || pathname === '/employer',
                'supernova-navi-item_arrow': arrowed,
                [styles.hidden]: visibleOn ? visibleOn.length !== 0 : false,
                [styles.xs]: visibleOn?.includes(Breakpoint.XS),
                [styles.s]: visibleOn?.includes(Breakpoint.S),
                [styles.m]: visibleOn?.includes(Breakpoint.M),
                [styles.l]: visibleOn?.includes(Breakpoint.L),
            })}
            ref={ref}
            {...rest}
        >
            {children}
        </div>
    );
};
export default forwardRef(SupernovaNaviItem);
