import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export interface AuctionConfirmationModalData {
    price: number;
    currency: CurrencyType;
}

export interface AuctionConfirmationModal {
    visible: boolean;
    data: AuctionConfirmationModalData;
}
const TOOGLE_CONFIRMATION_MODAL = 'TOOGLE_CONFIRMATION_MODAL';
const SET_CONFIRMATION_MODAL_DATA = 'SET_CONFIRMATION_MODAL_DATA';

interface PayloadAuctionDataTypes {
    [TOOGLE_CONFIRMATION_MODAL]: boolean;
    [SET_CONFIRMATION_MODAL_DATA]: AuctionConfirmationModalData;
}

const actionCreator = ActionCreatorHelper<PayloadAuctionDataTypes>();
export const toogleConfirmationModal = actionCreator(TOOGLE_CONFIRMATION_MODAL);
export const setConfirmationModalData = actionCreator(SET_CONFIRMATION_MODAL_DATA);

export default createReducer<AuctionConfirmationModal, PayloadAuctionDataTypes>(
    { visible: false, data: { price: 0, currency: CurrencyType.RUR } },
    {
        [TOOGLE_CONFIRMATION_MODAL]: (state, { payload }) => {
            return { ...state, visible: payload };
        },
        [SET_CONFIRMATION_MODAL_DATA]: (state, { payload }) => {
            return { ...state, data: payload };
        },
    }
);
