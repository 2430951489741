import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum UserSearchPointSource {
    Address = 'ADDRESS',
    PointsLog = 'POINTS_LOG',
}

interface UserSearchPoints {
    userId: number;
    lat: number;
    lng: number;
    source: UserSearchPointSource;
}

type UserSearchPointsByResumeHash = Record<string, UserSearchPoints>;

export default autoGeneratedReducer<UserSearchPointsByResumeHash>({});
