import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export enum BillStatus {
    Payed = 'employer.bill.state.payed',
    NotPayed = 'employer.bill.state.notPayed',
    Discarded = 'employer.bill.state.discarded',
    PayedByCard = 'employer.bill.state.payedbycard',
    PostPaid = 'employer.bill.state.postpaid',
    Barter = 'employer.bill.state.barter',
    PaidByReceipt = 'employer.bill.state.paid_by_receipt',
}

export interface Bill {
    price: number;
    status: BillStatus;
    date?: string;
    files: {
        billFileUrlDoc?: string;
        billFileUrlPdf?: string;
    };
    uid: string;
    paymentTime?: string;
    agreementNumber: string;
    payerName: string;
    agreementType: string;
}

interface AccountHistoryReplenishments {
    bills: Bill[];
    currency: CurrencyType;
    documentLinksVisibility: boolean;
}

export default autoGeneratedReducer<AccountHistoryReplenishments>({
    bills: [],
    documentLinksVisibility: false,
    currency: CurrencyType.RUR,
});
