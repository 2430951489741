import React from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { Account } from 'src/models/account';
import { UserType } from 'src/models/userType';

const render = (account: Account) => {
    const { firstName, middleName, lastName } = account;
    return <React.Fragment>{[firstName, middleName, lastName].join(' ')}</React.Fragment>;
};

const allFieldsEmpty = ({ firstName, middleName, lastName }: Account) =>
    firstName === null && middleName === null && lastName === null;

const CurrentUserFullName: React.VFC = () => {
    const { account, actualAccount, hhidAccount, userType } = useSelector(
        ({ account, actualAccount, hhidAccount, userType }) => ({
            account,
            actualAccount,
            hhidAccount,
            userType,
        })
    );

    if (account && !allFieldsEmpty(account)) {
        return render(account);
    } else if (actualAccount && !allFieldsEmpty(actualAccount)) {
        return render(actualAccount);
    } else if (userType === UserType.NedoUser && hhidAccount && !allFieldsEmpty(hhidAccount)) {
        return render(hhidAccount);
    }

    return null;
};

export default CurrentUserFullName;
