import { Button } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import FormItem from 'bloko/blocks/form/FormItem';
import { CrossScaleSmallEnclosedFalse, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    buttonText: 'search.resume.multisearch.fieldRemove.button',
};

const RemoveButton: TranslatedComponent<{ onRemove: () => void; isMagritte?: boolean }> = ({
    trls,
    onRemove,
    isMagritte,
}) => {
    const dataQa = 'resumes-search-wizard-item-remove';

    return isMagritte ? (
        <Button
            mode="secondary"
            style="neutral"
            onClick={onRemove}
            data-qa={dataQa}
            icon={<TrashOutlinedSize24 />}
            aria-label={trls[TrlKeys.buttonText]}
            hideLabel={true}
        />
    ) : (
        <FormItem baseline>
            <IconDynamic>
                <IconLink Element="button" type="button" onClick={onRemove} data-qa={dataQa}>
                    <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                </IconLink>
            </IconDynamic>
        </FormItem>
    );
};

export default translation(RemoveButton);
