import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum ContextViewMode {
    Banner = 'banner',
    Title = 'title',
}

export interface Filters {
    trls: Array<string>;
    query: Record<string, string>;
}

export interface ContextVacancy {
    name: string;
    id: number;
    filters: Filters;
}

export interface ResumeSearchContext {
    viewMode: ContextViewMode;
    vacancy: ContextVacancy | null;
    managerActiveVacancies: Array<ContextVacancy>;
}

const RESUME_SEARCH_CONTEXT_BANNER_CLOSE = 'RESUME_SEARCH_CONTEXT_BANNER_CLOSE';
const SET_RESUME_SEARCH_CONTEXT_ACTIVE_VACANCIES = 'SET_RESUME_SEARCH_CONTEXT_ACTIVE_VACANCIES';

interface PayloadTypes {
    [RESUME_SEARCH_CONTEXT_BANNER_CLOSE]: void;
    [SET_RESUME_SEARCH_CONTEXT_ACTIVE_VACANCIES]: Array<ContextVacancy>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const resumeSearchContextBannerClose = actionCreator(RESUME_SEARCH_CONTEXT_BANNER_CLOSE);
export const setResumeSearchContextActiveVacancies = actionCreator(SET_RESUME_SEARCH_CONTEXT_ACTIVE_VACANCIES);

export default createReducer<ResumeSearchContext, PayloadTypes>(null, {
    [RESUME_SEARCH_CONTEXT_BANNER_CLOSE]: (state) => ({
        ...state,
        viewMode: ContextViewMode.Title,
        vacancy: null,
    }),
    [SET_RESUME_SEARCH_CONTEXT_ACTIVE_VACANCIES]: (state, action) => ({
        ...state,
        managerActiveVacancies: action.payload,
    }),
});
