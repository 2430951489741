import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ReadOnlyBanner {
    days: number;
    isReadOnly: boolean;
}

export default autoGeneratedReducer<ReadOnlyBanner>({
    days: 0,
    isReadOnly: false,
});
