import { FC, ReactNode } from 'react';

import { Card, ScrollableItem, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import styles from 'src/components/SupernovaMainMenu/EmployerProfile/styles.less';

interface InformerProps {
    children: ReactNode;
    title: ReactNode;
    'data-qa'?: string;
}

const Informer: FC<InformerProps> = ({ children, title, ...props }) => {
    const { isMobile } = useBreakpoint();

    const content = (
        <div className={styles.informersItem} {...props}>
            <Card style="neutral" stretched borderRadius={16} padding={16}>
                <Text typography="label-3-regular" style="secondary">
                    {title}
                </Text>
                <VSpacing default={6} />
                <Text typography="subtitle-1-semibold" style="primary">
                    {children}
                </Text>
            </Card>
        </div>
    );

    if (isMobile) {
        return <ScrollableItem className={styles.informersItemScrollable}>{content}</ScrollableItem>;
    }
    return content;
};

export default Informer;
