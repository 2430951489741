import { FC, PropsWithChildren } from 'react';

import BillingCartActivationNotification from 'src/components/BillingCartActivationNotification';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexUserNotification } from 'src/models/userNotifications';

const BillingCartActivation: FC<ComplexUserNotification & PropsWithChildren> = (props) => {
    return (
        <SynchronizedNotification notification={props}>
            <BillingCartActivationNotification {...props} />
        </SynchronizedNotification>
    );
};

export default BillingCartActivation;
