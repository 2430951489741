import { useRef, useLayoutEffect, useContext, FC, memo, PropsWithChildren } from 'react';

import Debug from 'HHC/Debug';
import isGoogleTranslateEnv from 'Modules/googleTranslateChecker';

import { CodeInjectorContext, makeLuxDivider, DIVIDER_NODE_NAME } from 'src/components/CodeInjector';

interface LuxDividerRendererProps {
    styleClass?: string;
    place: string;
}

/* Чистить кеш не нужно. Страницы где используется компонент ContainerForXSL
 * не поддерживают SPA, мы храним по сути ссылку на DOM который видит юзер.
 * */
const fallbackCacheIncorrectHydration: Record<string, string> = {};

/* До инициализации JS данные уже находятся в DOM, но в случае ошибки гидрирования доступ
 * можно получить только в фазе рендера, сохраняю ссылки на DOM, только в browser окружении,
 * дабы при ошибке гидрирования вернуть контент обратно
 * */
if (!process.env.SSR) {
    [...document.querySelectorAll('.HH-ContainerForXSL')].forEach((container) => {
        if (container instanceof HTMLElement && container.dataset.place) {
            fallbackCacheIncorrectHydration[container.dataset.place] = container.innerHTML;
        }
    });
}

const ContainerForXSL: FC<LuxDividerRendererProps & PropsWithChildren> = ({ place, styleClass = '' }) => {
    const containerElementRef = useRef<HTMLDivElement>(null);
    const dividerContext = useContext(CodeInjectorContext);
    const jsClass = `.HH-${place.toUpperCase()}-INVISIBLE`;
    const selectorForHackHydration = `HH-ContainerForXSL-${place}`;

    // Мы мутируем объект, который определяется в серверном рендере
    // Аналогично тому, как это происходит в react-head, это позволяет за один проход рендера понять,
    // что контент страницы нужно разделить на 2 части
    if (process.env.SSR) {
        dividerContext.placesNamesForXSL.push(place);
    }

    useLayoutEffect(() => {
        if (!containerElementRef.current) {
            return;
        }

        const element = document.querySelector(jsClass);

        if (element && element.classList.contains('g-invisible')) {
            element.classList.remove('g-invisible');
            containerElementRef.current.appendChild(element);
        }

        if (
            containerElementRef.current.firstElementChild?.tagName?.toLowerCase?.() === DIVIDER_NODE_NAME &&
            fallbackCacheIncorrectHydration[place]
        ) {
            containerElementRef.current.innerHTML = fallbackCacheIncorrectHydration[place];

            if (!isGoogleTranslateEnv()) {
                Debug.log('error out', new Error(`ContainerForXSL hydration warning. Place ${place}`));
            }
        }
    });

    return (
        <div
            ref={containerElementRef}
            className={`${styleClass} HH-ContainerForXSL ${selectorForHackHydration}`}
            data-place={place}
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{
                __html: process.env.SSR
                    ? makeLuxDivider(place)
                    : (document.querySelector(`.${selectorForHackHydration}`)?.innerHTML ?? makeLuxDivider(place)),
            }}
        />
    );
};

export default memo(ContainerForXSL, () => true);
