import { RefObject, useCallback } from 'react';

import { Breakpoint, Drop, BottomSheet, NavigationBar } from '@hh.ru/magritte-ui';
import { BellOutlinedSize24, BellFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import Content from 'src/components/SupernovaMainMenu/UserNotifications/Content';
import useNotifications from 'src/components/SupernovaMainMenu/UserNotifications/useNotifications';

const TrlKeys = {
    title: 'supernova.notifications.title',
};

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

interface UserNotificationProps {
    activatorRef: RefObject<HTMLDivElement>;
}

const UserNotifications: TranslatedComponent<NavItem & UserNotificationProps> = ({
    name,
    trackClicks,
    analytics,
    activatorRef,
    trls,
}) => {
    const { userNotifications, markNotificationsAsViewed } = useNotifications();

    const unreadNotificationsCount = userNotifications.filter(({ viewed }) => !viewed).length;
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);

    const [visible, open, close] = useOnOffState(false);

    const handleOpen = useCallback(() => {
        open();
        markNotificationsAsViewed();
    }, [markNotificationsAsViewed, open]);

    const content = <Content userNotifications={userNotifications} close={close} />;

    return (
        <SupernovaNaviItem visibleOn={isApplicant ? VISIBLE_ON : undefined}>
            <SupernovaActionLink
                name={name}
                trackClicks={trackClicks}
                analytics={analytics}
                activeIcon={<BellFilledSize24 />}
                showBadge={!!unreadNotificationsCount}
                badgeCount={unreadNotificationsCount}
                icon={<BellOutlinedSize24 />}
                data-qa="mainmenu_notifications"
                onClick={handleOpen}
                active={visible}
            />

            <Drop
                width={480}
                space={0}
                onClose={close}
                visible={visible}
                placement="bottom-right"
                activatorRef={activatorRef}
                style={{ marginTop: '4px', marginLeft: '-12px' }}
                forcePlacement
                ignoreIntersections
            >
                {content}
            </Drop>

            <BottomSheet
                onClose={close}
                withContentPaddings={false}
                visible={visible}
                header={<NavigationBar title={trls[TrlKeys.title]} showDivider="always" />}
            >
                {content}
            </BottomSheet>
        </SupernovaNaviItem>
    );
};

export default translation(UserNotifications);
