let checkedExperiments: string[] = [];

export const putCheckedExperiment = (experimentName: string): void => {
    checkedExperiments.push(experimentName);
};

export const resetCheckedExperiment = (): void => {
    checkedExperiments = [];
};

export default (): string[] => {
    return [...checkedExperiments];
};
