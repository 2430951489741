import { ComponentProps, FC, useCallback, useEffect, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Notification from 'bloko/blocks/notificationManager/Notification';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import { markUserNotificationsAsViewed } from 'Modules/UserNotifications';
import { isComplexUserNotification, UserNotification } from 'src/models/userNotifications';

interface SynchronizedNotificationProps extends ComponentProps<typeof Notification> {
    closeEvent?: string;
    markAsViewedEvent?: MarkAsViewedEvent;
    notification: UserNotification;
    analyticsElementName?: string;
}

const markAsViewed = (notification: UserNotification) =>
    isComplexUserNotification(notification)
        ? markUserNotificationsAsViewed(notification.id)
        : Notices.markAsViewed(notification.templateKey);

const sendAnalytics = (name: string | undefined, element: HTMLDivElement | null) => {
    if (element && name) {
        Analytics.sendHHEventElementShown(element, {
            name,
        });
    }
};

const SynchronizedNotification: FC<SynchronizedNotificationProps & PropsWithChildren> = ({
    closeEvent,
    markAsViewedEvent = MarkAsViewedEvent.OnShow,
    notification,
    analyticsElementName,
    closeFuncRef,
    onClose,
    children,
    ...notificationProps
}) => {
    const sendShownAnalytics = useCallback<(element: HTMLDivElement | null) => void>(
        (element) => sendAnalytics(analyticsElementName, element),
        [analyticsElementName]
    );

    const onCloseHandler = useCallback(() => {
        closeEvent && Analytics.sendHHEventButtonClick(closeEvent);
        if (markAsViewedEvent === MarkAsViewedEvent.OnHide) {
            void markAsViewed(notification);
        }
        onClose?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markAsViewedEvent, closeEvent, onClose]);

    useEffect(() => {
        if (markAsViewedEvent === MarkAsViewedEvent.OnShow) {
            void markAsViewed(notification);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markAsViewedEvent]);

    return (
        <Notification onClose={onCloseHandler} closeFuncRef={closeFuncRef} {...notificationProps}>
            <div ref={sendShownAnalytics}>{children}</div>
        </Notification>
    );
};

export default SynchronizedNotification;
