import { useState, FC, useContext, useEffect } from 'react';

import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import IndustrySelect from 'src/components/ResumeAdvancedSearch/SearchQueries/IndustrySelect';
import ConditionSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/ConditionSelect';
import PosSelectorDrop from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelectorDrop';
import { getHasWorkplaceOptions } from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { SearchQueries } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import styles from './styles.less';

interface QueryConditionsProps {
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    isNaviSearch?: boolean;
    autoInvite?: boolean;
}

const QueryConditions: FC<QueryConditionsProps> = ({ query, setQuery, isNaviSearch, autoInvite }) => {
    const [hasWorkplaceOptions, setHasWorkplaceOptions] = useState(getHasWorkplaceOptions(query[CriteriaKey.Pos]));
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, query]);

    return (
        <div className={styles.container}>
            <ConditionSelect name={CriteriaKey.Logic} query={query} setQuery={setQuery} />
            <PosSelectorDrop
                query={query}
                setQuery={setQuery}
                setHasWorkplaceOptions={setHasWorkplaceOptions}
                isMagritte
            />
            <ConditionSelect
                name={CriteriaKey.ExpPeriod}
                query={query}
                setQuery={setQuery}
                isHidden={!hasWorkplaceOptions}
            />
            {isHeadHunterPlatform && (
                <>
                    <ConditionSelect
                        name={CriteriaKey.ExpCompanySize}
                        query={query}
                        setQuery={setQuery}
                        isHidden={!hasWorkplaceOptions}
                    />
                    {autoInvite && (
                        <IndustrySelect
                            value={query[CriteriaKey.ExpIndustry]}
                            setValue={(values: string[]) => setQuery({ ...query, [CriteriaKey.ExpIndustry]: values })}
                            isNaviSearch={isNaviSearch}
                            isHidden={!hasWorkplaceOptions}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default QueryConditions;
