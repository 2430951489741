import { MutableRefObject } from 'react';

enum VacancyVisitorsCollectionName {
    VacancyVisitors = 'vacancy_visitors',
}

export enum EnteringCollectionName {
    RelevantResponses = 'relevant_responses',
    Response = 'response',
    PhoneCalls = 'phone_calls',
    ByLocation = 'by_location',
}

export enum SuitableWorkflowCollectionName {
    PhoneInterview = 'phone_interview',
    Assessment = 'assessment',
    Interview = 'interview',
    Offer = 'offer',
    Hired = 'hired',
    Consider = 'consider',
}

export enum UnsuitableWorkflowCollectionName {
    DiscardByEmployer = 'discard_by_employer',
    DiscardByApplicant = 'discard_by_applicant',
    DiscardNoInteraction = 'discard_no_interaction',
    DiscardToOtherVacancy = 'discard_to_other_vacancy',
    DiscardVacancyClosed = 'discard_vacancy_closed',
}

export const CollectionName = {
    ...VacancyVisitorsCollectionName,
    ...EnteringCollectionName,
    ...SuitableWorkflowCollectionName,
    ...UnsuitableWorkflowCollectionName,
} as const;

export type CollectionNameType = (typeof CollectionName)[keyof typeof CollectionName];

export enum ActionType {
    ChangeTopic = 'CHANGE_TOPIC',
    Invitation = 'INVITATION',
    Discard = 'DISCARD',
    Consider = 'CONSIDER',
}

export enum FilterType {
    OnlyNew = 'ONLY_NEW',
    All = 'ALL',
    Read = 'READ',
    Unread = 'UNREAD',
    Invitation = 'INVITATION',
    Discard = 'DISCARD',
    OnlyWithAssessments = 'ONLY_WITH_ASSESSMENTS',
}

export enum ResumeSettingType {
    Folders = 'FOLDERS',
    Hidden = 'HIDDEN',
    State = 'STATE',
    Order = 'ORDER',
}

type CollectionProperty<T> = { type: T };

export interface Collection<N extends string = CollectionNameType> {
    name: N;
    id: string;
    extraFields: {
        EMPLOYER_STATE_WITHOUT_SUBSTATE?: boolean;
    };
    actions: CollectionProperty<ActionType>[] | null;
    filters: CollectionProperty<FilterType>[] | null;
    resumeSettings: CollectionProperty<ResumeSettingType>[] | null;
    resumesCount: { newOrUpdated: number; total: number; applicantQuestionsCount: number };
    candidatesSubCollections: Collection<string>[];
    activatorRef?: MutableRefObject<null>;
}

export type EnteringCollection = Collection<EnteringCollectionName>;
export type UnsuitableWorkflowCollection = Collection<UnsuitableWorkflowCollectionName>;
export type SuitableWorkflowCollection = Collection<SuitableWorkflowCollectionName>;
export type VisitorCollection = Collection<VacancyVisitorsCollectionName>;
