import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

interface RecommendedVacancies {
    itemsOnPage: number;
    resultsFound: number;
    totalPages: number;
    type: string;
    lastActivityTimeByHHID: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    hasVacanciesWithAddress: boolean;
    queries: Record<string, string>;
}

export default autoGeneratedReducer<RecommendedVacancies>(null);
