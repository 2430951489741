import { FormEvent, useCallback, useState, FC, useMemo } from 'react';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import { ChevronScaleMediumKindLeft } from 'bloko/blocks/icon';
import InputText, { InputProps } from 'bloko/blocks/inputText';
import Modal, { ModalFooter, ModalHeader, ModalContent } from 'bloko/blocks/modal';
import { SuggestProps } from 'bloko/blocks/suggest';
import SuggestPickerItem from 'bloko/blocks/suggest/SuggestPickerItem';
import SuggestPickerItems from 'bloko/blocks/suggest/SuggestPickerItems';
import Defaults from 'bloko/blocks/suggest/defaults';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import { normalizeText } from 'bloko/blocks/suggest/utils';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';

import Form from 'src/components/Form';

import styles from './mobile-suggest.less';

export interface MobileSuggestProps<T = DataProviderItem> {
    isShown: boolean;
    onClose: () => void;
    onSubmit?: (inputValue: string) => void;
    submitButtonTitle?: string;
    dataProvider: SuggestProps<T>['dataProvider'];
    value?: string;
    onChange?: SuggestProps<T>['onChange'];
    itemContent?: SuggestProps<T>['itemContent'];
    debounceDelay?: SuggestProps<T>['debounceDelay'];
    limit?: SuggestProps<T>['limit'];
    inputProps?: InputProps;
}

const MobileSuggest: FC<MobileSuggestProps> = ({
    isShown,
    onClose,
    onSubmit,
    submitButtonTitle,
    dataProvider,
    value,
    onChange,
    itemContent,
    debounceDelay,
    limit,
    inputProps,
}) => {
    const [pickerItems, setPickerItems] = useState<DataProviderItem[]>([]);
    const [inputValue, setInputValue] = useState(value || '');

    const updatePickerDebounced = useMemo(
        () =>
            debounce(async (newPreparedSearchValue: string) => {
                await dataProvider(newPreparedSearchValue)
                    .then(({ items }) => {
                        setPickerItems(
                            items.slice(0, limit).map((item, index) => ({ ...item, id: `${item?.id || index}` }))
                        );
                    })
                    .catch(() => {
                        setPickerItems([]);
                    });
            }, debounceDelay || Defaults.THROTTLE),
        [debounceDelay, dataProvider, limit]
    );

    const handleInputChange = useCallback(
        (value: string) => {
            setInputValue(value);
            updatePickerDebounced(normalizeText(value));
        },
        [updatePickerDebounced]
    );

    const handleFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit?.(inputValue);
        },
        [inputValue, onSubmit]
    );

    return (
        <Modal visible={isShown} closeButtonShow={false} escapePress={false} onClose={onClose}>
            <ModalHeader>
                <Form onSubmit={handleFormSubmit} action="" className={styles.modalHeader}>
                    <div className={styles.backIconWrapper} onClick={onClose}>
                        <ChevronScaleMediumKindLeft />
                    </div>
                    <InputText
                        showClearButton
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => updatePickerDebounced(inputValue)}
                        autoFocus
                        light
                        {...inputProps}
                    />
                    {submitButtonTitle && (
                        <FormSpacer>
                            <Button kind={ButtonKind.Primary} type={ButtonType.Submit}>
                                {submitButtonTitle}
                            </Button>
                        </FormSpacer>
                    )}
                </Form>
                <VSpacing base={3} />
                <div className="horizontal-divider" />
            </ModalHeader>
            <ModalContent>
                <div className={styles.contentWrapper}>
                    <SuggestPickerItems>
                        {pickerItems.map((item, index) => {
                            return (
                                <SuggestPickerItem
                                    key={item.text}
                                    index={index}
                                    handleMouseDown={() => {
                                        onChange?.(item, '');
                                        onClose();
                                    }}
                                    highlighted={false}
                                >
                                    {itemContent?.(item) || item.text}
                                </SuggestPickerItem>
                            );
                        })}
                    </SuggestPickerItems>
                </div>
            </ModalContent>
            <ModalFooter />
        </Modal>
    );
};

export default MobileSuggest;
