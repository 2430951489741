import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

type VacancyId = string;
type Overall = 'overall';
export interface ApplicantResponseStreak {
    vacancyId: VacancyId;
    responsesCount: number;
    responsesRequired: number;
}

type ApplicantResponseStreaks = Record<VacancyId | Overall, ApplicantResponseStreak>;

export const APPLICANT_RESPONSE_STREAK_UPDATE = 'APPLICANT_RESPONSE_STREAK_UPDATE';
export const INITIAL_STATE: ApplicantResponseStreaks = {};

interface PayloadTypes {
    [APPLICANT_RESPONSE_STREAK_UPDATE]: {
        vacancyId: number;
        data: ApplicantResponseStreak;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const responseStreakUpdate = actionCreator(APPLICANT_RESPONSE_STREAK_UPDATE);

export default createReducer<ApplicantResponseStreaks, PayloadTypes>(INITIAL_STATE, {
    [APPLICANT_RESPONSE_STREAK_UPDATE]: (state, { payload }) => {
        return {
            ...state,
            [payload.vacancyId]: { ...state[payload.vacancyId], ...payload.data },
            overall: { ...state.overall, ...payload.data },
        };
    },
});
