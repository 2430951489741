import { PropsWithChildren } from 'react';

import { Tag } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaLink, { SupernovaLinkKind } from 'src/components/SupernovaMainMenu/Link';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

interface NaviItemLinkProps extends NavItem {
    dataQa: string;
    kind: SupernovaLinkKind;
    onClick?: () => void;
}

const TrlKeys = {
    tagNew: 'navi.menuitem.tag.new',
};

const NaviItemLink: TranslatedComponent<NaviItemLinkProps & PropsWithChildren> = ({
    trls,
    name,
    url,
    translations,
    target,
    dataQa,
    kind,
    onClick,
}) => {
    const targetProps = target ? { target } : {};
    const tag = name === 'hhPro' ? <Tag style="negative">{trls[TrlKeys.tagNew]}</Tag> : null;

    if (!url) {
        return null;
    }

    return (
        <SupernovaLink
            to={url}
            kind={kind}
            data-page-analytics-event={`footer_link.${name}`}
            data-qa={dataQa}
            onClick={onClick}
            {...targetProps}
        >
            {translations.name} {tag}
        </SupernovaLink>
    );
};

export default translation(NaviItemLink);
