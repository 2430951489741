import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const UPDATE_RESUME_VIEW_SETTINGS = 'UPDATE_RESUME_VIEW_SETTINGS';

export enum ResumesViewSettingFields {
    LastChangeTime = 'LAST_CHANGE_TIME',
    Name = 'NAME',
    Photo = 'PHOTO',
    LastExperience = 'LAST_EXPERIENCE',
    Comments = 'COMMENTS',
    CommentsExpanded = 'COMMENTS_EXPANDED',
    Languages = 'LANGUAGES',
    Area = 'AREA',
    Education = 'EDUCATION',
    Citizenship = 'CITIZENSHIP',
    Companies = 'COMPANIES',
    Profarea = 'PROFAREA',
    ProfessionalRole = 'PROFESSIONAL_ROLE',
    Relocation = 'RELOCATION',
    Certificates = 'CERTIFICATES',
    ExperienceSum = 'EXPERIENCE_SUM',
}

interface ResumesViewSettings {
    viewFields: {
        name: ResumesViewSettingFields;
        active: boolean;
    }[];
}

const INITIAL_STATE = null;

interface PayloadTypes {
    [UPDATE_RESUME_VIEW_SETTINGS]: ResumesViewSettingFields[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateResumeViewSettings = actionCreator(UPDATE_RESUME_VIEW_SETTINGS);

export default createReducer<ResumesViewSettings, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_RESUME_VIEW_SETTINGS]: (state, action) => ({
        ...state,
        viewFields: state.viewFields.map((field) => ({
            ...field,
            active: action.payload.includes(field.name),
        })),
    }),
});
