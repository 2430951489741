import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import notVerifiedStatusBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/not_verified_status_banner_element_shown';
import notVerifiedStatusBannerPrimaryClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-33515/not_verified_status_banner_primary_click';
import { Banner, Text, Button, Link as UiLink, LinkStyle, TextTypography } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import {
    useEmployerVerificationState,
    EmployerVerificationState,
    BannerType,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    upload: 'employer.notVerifiedBanner.upload',
    uploadPersonally: 'employer.notVerifiedBanner.uploadPersonally',
    removeRestrictions: 'employer.notVerifiedBanner.removeRestrictions',
    content: {
        candidates: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.candidates.newWithoutDocs.content',
            verificationNewWithDocs: 'employer.verification.notVerifiedBanner.candidates.newWithDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.candidates.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.candidates.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.candidates.notVerifiedWithoutDocs.content',
        },
        vacancies: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.vacancies.newWithoutDocs.content',
            verificationNewWithDocs: 'employer.verification.notVerifiedBanner.vacancies.newWithDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.vacancies.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.vacancies.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.vacancies.notVerifiedWithoutDocs.content',
        },
        vacancyPreview: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.vacancyPreview.newWithoutDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.vacancyPreview.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.vacancyPreview.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.vacancyPreview.notVerifiedWithoutDocs.content',
        },
        profile: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.profile.newWithoutDocs',
            newWithoutDocs: 'employer.notVerifiedBanner.profile.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.profile.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.profile.notVerifiedWithoutDocs.content',
        },
    },
};

interface NotVerifiedEmployerBannerProps {
    bannerType: BannerType;
}

const uiLinkProps = {
    Element: Link,
    to: '/employer/documents',
    target: '_blank',
    inline: true,
    underlined: true,
    style: 'neutral' as LinkStyle,
    typography: 'paragraph-2-regular' as TextTypography,
    onClick: () => notVerifiedStatusBannerPrimaryClick(),
};

const verificationLinkProps = {
    Element: Link,
    to: '/employer/documents',
    target: '_blank',
    inline: true,
    onClick: () => notVerifiedStatusBannerPrimaryClick(),
};

const setNewEmployerWithoutDocsClosedCandidatesBannerAction = makeSetStoreField(
    'newEmployerWithoutDocsClosedCandidatesBanner'
);

const NotVerifiedEmployerBanner: TranslatedComponent<NotVerifiedEmployerBannerProps> = ({ trls, bannerType }) => {
    const dispatch = useDispatch();
    const verificationExpEnabled = useSelector((state) => state.verificationExpEnabled);
    const newEmployerWithoutDocsClosedCandidatesBanner = useSelector(
        (state) => state.newEmployerWithoutDocsClosedCandidatesBanner
    );
    const setNewEmployerWithoutDocsClosedCandidatesBanner = (closed: boolean) =>
        dispatch(setNewEmployerWithoutDocsClosedCandidatesBannerAction(closed));
    const { employerVerificationState, shouldShowBanner, setBannerHasClosed, setBannerShownCount } =
        useEmployerVerificationState();
    const [showBanner, setShowBanner] = useState(shouldShowBanner);
    const bannerRef = useRef(null);
    // temp_exp_35925_next_line
    const bannerActionShownRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: 'not_verified_status_banner_action',
        bannerType,
    });
    const newWithoutDocs = employerVerificationState === EmployerVerificationState.NewWithoutDocs;
    const newWithDocs = employerVerificationState === EmployerVerificationState.NewWithDocs;
    const notVerifiedWithoutDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithoutDocs;

    const handleClose = () => {
        setBannerHasClosed?.();
        setShowBanner(false);
        if ([BannerType.Candidates, BannerType.Vacancies].includes(bannerType) && newWithoutDocs) {
            setNewEmployerWithoutDocsClosedCandidatesBanner(true);
        }
        // temp_exp_35925_next_line
        Analytics.sendHHEventButtonClick('not_verified_status_banner_close', { bannerType });
    };

    let bannerProps;

    useEffect(() => {
        if (bannerRef.current) {
            notVerifiedStatusBannerElementShown(bannerRef.current);
        }
    }, []);

    useEffect(() => {
        setBannerShownCount?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!showBanner || newEmployerWithoutDocsClosedCandidatesBanner) {
        return null;
    }

    if (bannerType === BannerType.Candidates) {
        bannerProps = {
            stretched: true,
            content: verificationExpEnabled ? (
                <Text>
                    {newWithoutDocs && trls[TrlKeys.content.candidates.verificationNewWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.candidates.verificationNewWithDocs]}
                </Text>
            ) : (
                <Text>
                    {newWithoutDocs && trls[TrlKeys.content.candidates.newWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.candidates.newWithDocs]}
                    {notVerifiedWithoutDocs && trls[TrlKeys.content.candidates.notVerifiedWithoutDocs]}
                </Text>
            ),
            buttonBase: (
                <>
                    {(newWithoutDocs || notVerifiedWithoutDocs) && (
                        <Button
                            Element={Link}
                            to="/employer/documents"
                            target="_blank"
                            mode="primary"
                            ref={bannerActionShownRef}
                            onClick={() => notVerifiedStatusBannerPrimaryClick()}
                        >
                            {verificationExpEnabled ? trls[TrlKeys.removeRestrictions] : trls[TrlKeys.upload]}
                        </Button>
                    )}
                </>
            ),
        };
    }

    if (bannerType === BannerType.Vacancies) {
        bannerProps = {
            content: verificationExpEnabled ? (
                <Text>
                    {newWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancies.verificationNewWithoutDocs], {
                            '{0}': (
                                <UiLink {...verificationLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.removeRestrictions]}
                                </UiLink>
                            ),
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancies.verificationNewWithDocs]}
                </Text>
            ) : (
                <Text>
                    {newWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancies.newWithoutDocs], {
                            '{0}': (
                                <UiLink {...uiLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.uploadPersonally].toLowerCase()}
                                </UiLink>
                            ),
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancies.newWithDocs]}
                    {notVerifiedWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancies.notVerifiedWithoutDocs], {
                            '{0}': (
                                <UiLink {...uiLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.uploadPersonally]}
                                </UiLink>
                            ),
                        })}
                </Text>
            ),
        };
    }

    if (bannerType === BannerType.VacancyPreview) {
        bannerProps = {
            content: verificationExpEnabled ? (
                <Text>
                    {newWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancyPreview.verificationNewWithoutDocs], {
                            '{0}': (
                                <UiLink {...verificationLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.removeRestrictions]}
                                </UiLink>
                            ),
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancyPreview.newWithDocs]}
                </Text>
            ) : (
                <Text>
                    {newWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancyPreview.newWithoutDocs], {
                            '{0}': (
                                <UiLink {...uiLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.uploadPersonally]}
                                </UiLink>
                            ),
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancyPreview.newWithDocs]}
                    {notVerifiedWithoutDocs &&
                        formatToReactComponent(trls[TrlKeys.content.vacancyPreview.notVerifiedWithoutDocs], {
                            '{0}': (
                                <UiLink {...uiLinkProps} ref={bannerActionShownRef}>
                                    {trls[TrlKeys.uploadPersonally]}
                                </UiLink>
                            ),
                        })}
                </Text>
            ),
        };
    }

    if (bannerType === BannerType.Profile) {
        bannerProps = {
            content: verificationExpEnabled ? (
                <Text>
                    {newWithoutDocs && trls[TrlKeys.content.profile.verificationNewWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.profile.newWithDocs]}
                </Text>
            ) : (
                <Text>
                    {newWithoutDocs && trls[TrlKeys.content.profile.newWithoutDocs]}
                    {notVerifiedWithoutDocs && trls[TrlKeys.content.profile.notVerifiedWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.profile.newWithDocs]}
                </Text>
            ),
            buttonBase: (
                <>
                    {(newWithoutDocs || notVerifiedWithoutDocs) && (
                        <Button
                            Element={Link}
                            to="/employer/documents"
                            mode="primary"
                            ref={bannerActionShownRef}
                            onClick={() => notVerifiedStatusBannerPrimaryClick()}
                        >
                            {verificationExpEnabled ? trls[TrlKeys.removeRestrictions] : trls[TrlKeys.upload]}
                        </Button>
                    )}
                </>
            ),
        };
    }

    return (
        <div ref={bannerRef}>
            <Banner style="warning" showClose onClose={handleClose} {...bannerProps} />
        </div>
    );
};

export default translation(NotVerifiedEmployerBanner);
