import urlParser from 'bloko/common/urlParser';

import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const linkMap = {
    [ComplexUserNotificationsTemplateKey.BuyVacancyPageConstructor]: '/price/branding',
    [ComplexUserNotificationsTemplateKey.BuyEmployerPageConstructor]: '/price/branding',
    [ComplexUserNotificationsTemplateKey.FillEmployerPageConstructor]: '/employer/constructor',
    [ComplexUserNotificationsTemplateKey.FillEmployerVacancyPageConstructor]: '/employer/vacancyconstructor',
    [ComplexUserNotificationsTemplateKey.SuggestionEmployerMakeup]: '/price/branding',
    [ComplexUserNotificationsTemplateKey.SuggestionVacancyMakeup]: '/price/branding',
};

type TemplateKey = keyof typeof linkMap;
const isSupportedTemplate = (key: ComplexUserNotificationsTemplateKey): key is TemplateKey => key in linkMap;

const getNotificationLink = (
    id: number,
    templateKey: ComplexUserNotificationsTemplateKey,
    acknowledge: boolean,
    linkParams?: Record<string, unknown>
): string | null => {
    if (!isSupportedTemplate(templateKey)) {
        return null;
    }
    const targetUrlObj = urlParser(linkMap[templateKey]);
    targetUrlObj.params = { ...targetUrlObj.params, ...linkParams } as typeof targetUrlObj.params;

    if (!acknowledge) {
        return targetUrlObj.href;
    }

    const urlObject = urlParser('/notifications/acknowledge');
    urlObject.params = {
        id,
        url: encodeURIComponent(targetUrlObj.href),
    };
    return urlObject.href;
};

export default getNotificationLink;
