import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';

const TrlKeys = {
    success: 'notification.applicant.job.search.status.success',
    statusSet: 'notification.applicant.job.search.status.success.status',
    status: {
        [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.change.version2.active_search',
        [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.change.version2.looking_for_offers',
        [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.change.version2.has_job_offer',
        [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.change.version2.accepted_job_offer',
        [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.change.version2.not_looking_for_job',
    },
};

const ApplicantJobSearchStatusSuccess: TranslatedComponent = ({ trls }) => {
    const currentStatus = useSelector((state) => state.applicantUserStatuses?.jobSearchStatus?.name);

    return (
        <div data-qa="applicant-job-search-status-success-notification">
            {!currentStatus && <NotificationHeading>{trls[TrlKeys.success]}</NotificationHeading>}
            {currentStatus && format(trls[TrlKeys.statusSet], { '{0}': trls[TrlKeys.status[currentStatus]] })}
        </div>
    );
};

export default {
    Element: translation(ApplicantJobSearchStatusSuccess),
    kind: 'ok',
    autoClose: true,
};
