import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale } from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import useSkillVerificationMethodsBreadcrumbs from 'src/hooks/useSkillVerificationMethodsBreadcrumbs';

const TrlKeys = {
    title: 'applicantSkillsNotification.expiration.title',
    desc: 'applicantSkillsNotification.expiration.desc',
    open: 'applicantSkillsNotification.expiration.open',
};

const SkillVerificationExpiring: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    ...notification
}) => {
    const { createSkillVerificationMethodsPath } = useSkillVerificationMethodsBreadcrumbs();

    return (
        <SynchronizedNotification notification={notification} kind={NotificationKind.Error} autoClose={false}>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            {trls[TrlKeys.desc]}
            <NotificationFooter>
                <BlokoLink Element={Link} to={createSkillVerificationMethodsPath()}>
                    <Button scale={ButtonScale.Small} onClick={notification.onClose}>
                        {trls[TrlKeys.open]}
                    </Button>
                </BlokoLink>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(SkillVerificationExpiring);
