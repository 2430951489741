import { Entries, Primitive } from 'type-fest';

import urlParser from 'bloko/common/urlParser';

import SearchType from 'src/components/NovaFilters/SearchType';
import { NOVAFILTERS_EXCLUDE_HIDDEN_INPUT, SORTS_WITH_DEFAULT_VALUE } from 'src/components/NovaFilters/novaFilterUtils';
import { CurrencyType } from 'src/models/currencies.types';
import NovaFilters, { NovaFilterKey } from 'src/models/novaFilters';
import { Criteria as ResumeSearchCriteria, LabelOption, SearchPeriodOption } from 'src/models/resumeSearchCriteria';
import { CriteriaKey, OrderByOption } from 'src/models/search/searchCriteria.types';
import { WITH_TOP_FILTER_CATALOG } from 'src/models/topFilterCatalog';
import { Criteria as VacancySearchCriteria } from 'src/models/vacancySearchCriteria';

import {
    GetSearchParamsArgs,
    GetSearchParamsFromFilterKeysType,
} from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/types';

const typicalFilters = [
    CriteriaKey.Area,
    CriteriaKey.Citizenship,
    CriteriaKey.District,
    CriteriaKey.DriverLicenseTypes,
    CriteriaKey.Employment,
    CriteriaKey.ExcludedText,
    CriteriaKey.Experience,
    CriteriaKey.Education,
    CriteriaKey.EducationLevel,
    CriteriaKey.FilterExpIndustry,
    CriteriaKey.FilterExpPeriodForIndustry,
    CriteriaKey.University,
    // industry and subIndustry
    CriteriaKey.Industry,
    CriteriaKey.JobSearchStatus,
    CriteriaKey.Label,
    CriteriaKey.Language,
    CriteriaKey.VerifiedLanguages,
    CriteriaKey.Metro,
    CriteriaKey.PartTime,
    CriteriaKey.ProfessionalRole,
    CriteriaKey.Relocation,
    CriteriaKey.Resume,
    CriteriaKey.Schedule,
    CriteriaKey.SearchField,
    CriteriaKey.Skill,
    CriteriaKey.VerifiedSkills,
    CriteriaKey.VacancyId,
    CriteriaKey.WorkTicket,
    CriteriaKey.AcceptTemporary,
] as const;

export type SharedSearchParams = GetSearchParamsFromFilterKeysType<(typeof typicalFilters)[number]> & {
    [CriteriaKey.CurrencyCode]: CurrencyType;
    [CriteriaKey.Text]: string | string[];
    [CriteriaKey.SearchPeriod]?: null | SearchPeriodOption;
    [CriteriaKey.OrderBy]?: OrderByOption;
    ['L_save_area']?: boolean;
    searchSessionId?: string;
};

type UntypedSearchParams = Record<string, Primitive | Primitive[]>;

const controlledLabels = [LabelOption.OnlyWithSalary, LabelOption.OnlyWithAge, LabelOption.OnlyWithGender];

const getSharedSearchParams = ({
    criteriaCurrencyCode,
    criteriaTextUpdated,
    currencies,
    router,
    resumeSearchResult,
    searchCatalogRedirect,
    searchClusters,
    searchType,
    vacancySearchResult,
    employerVacancySearch,
    searchSessionId,
    isSearchSessionIdSaved,
}: GetSearchParamsArgs): SharedSearchParams => {
    const searchParams = {} as SharedSearchParams;
    const untypedSearchParams = {} as UntypedSearchParams;

    typicalFilters.forEach(<Key extends keyof NovaFilters & keyof SharedSearchParams>(filter: Key) => {
        if (searchClusters[filter]) {
            const filterFromClusters = searchClusters[filter];
            searchParams[filter] = filterFromClusters.selectedValues as SharedSearchParams[Key];
        }
    });

    if (searchClusters[NovaFilterKey.Label]) {
        const labels = searchClusters[NovaFilterKey.Label].selectedValues as string[];
        searchParams.label = labels.filter((item) => !controlledLabels.includes(item)) as LabelOption[];
    }

    const { default: currencyCodeDefault } = currencies;
    if (currencyCodeDefault !== criteriaCurrencyCode) {
        searchParams[CriteriaKey.CurrencyCode] = criteriaCurrencyCode;
    }

    const { pathname, search } = router.location;
    const urlParams = urlParser(pathname + search).params;

    if (searchCatalogRedirect || urlParams[CriteriaKey.Text]) {
        searchParams.text = criteriaTextUpdated;
    }

    if (isSearchSessionIdSaved && searchSessionId) {
        searchParams.searchSessionId = searchSessionId;
    }

    // proxy other get params (like "hhtmFrom")
    Object.entries(urlParams).forEach(([key, values]) => {
        if (key !== WITH_TOP_FILTER_CATALOG && !NOVAFILTERS_EXCLUDE_HIDDEN_INPUT.includes(key)) {
            untypedSearchParams[key] = values;
        }
    });

    let criteria: Partial<VacancySearchCriteria> | ResumeSearchCriteria | undefined;

    switch (searchType) {
        case SearchType.Resume:
            criteria = resumeSearchResult.criteria;
            break;
        case SearchType.EmployerVacancy:
            criteria = employerVacancySearch.criteria;
            break;
        default:
            criteria = vacancySearchResult.criteria;
    }
    // proxy sorts without default values
    const entries = Object.entries(SORTS_WITH_DEFAULT_VALUE) as Entries<typeof SORTS_WITH_DEFAULT_VALUE>;
    entries.forEach(([key]) => {
        if (!urlParams[key] && criteria?.[key]) {
            untypedSearchParams[key] = criteria[key];
        }
    });

    return {
        ...untypedSearchParams,
        ...searchParams,
    };
};

export default getSharedSearchParams;
