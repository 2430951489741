import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Mailto {
    resumeUrl: string;
    resumeHash: string;
    simhash: string;
    template: {
        subject: string;
        content: string;
    };
}

export default autoGeneratedReducer<Mailto>(null);
