import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyDiscountPromoControlsInfo {
    visibility: boolean;
    extraPublicationsNeeded?: number;
}

export default autoGeneratedReducer<VacancyDiscountPromoControlsInfo>({
    visibility: false,
});
