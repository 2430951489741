import React, { useContext } from 'react';
import classnames from 'classnames';

import { Breakpoint } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaModals from 'src/components/SupernovaMainMenu/SupernovaModals';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import useIsAuthorizedRedesign from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import useGoogleAnalytics from 'src/components/SupernovaMainMenu/useGoogleAnalytics';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';

const VISIBLE_ON = [Breakpoint.L];
const ANON_VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];
const ANON_REDEIGN_VISIBLE_ON = [Breakpoint.M, Breakpoint.L];

const Help: React.FC<NavItem> = ({ name, modalType, subItems, translations, analytics }) => {
    const isRedesignActive = useContext(isRedesignActiveContext);
    const handleTrackClicks = useTrackClicks(name, true, analytics);

    const modalActivatorRef = React.useRef(null);

    const userType = useSelector(({ userType }) => userType);
    const isAuthorizedRedesign = useIsAuthorizedRedesign();

    const logGoogleAnalytics = useGoogleAnalytics('help');

    const handleClick = React.useCallback(() => {
        handleTrackClicks();
        logGoogleAnalytics();
    }, [handleTrackClicks, logGoogleAnalytics]);

    const renderActivator = React.useCallback(() => {
        const props = {
            onClick: handleClick,
            ref: modalActivatorRef,
        };
        if (isRedesignActive) {
            return (
                <SupernovaActionLink data-qa={`mainmenu_${name}`} name={name} {...props}>
                    {translations.name}
                </SupernovaActionLink>
            );
        }
        return (
            <button
                className={classnames({
                    'supernova-link': userType === UserType.BackOffice,
                    'supernova-link-switch': userType !== UserType.BackOffice,
                })}
                data-qa={`mainmenu_${name}`}
                type="button"
                {...props}
            >
                {translations.name}
            </button>
        );
    }, [handleClick, isRedesignActive, name, translations.name, userType]);

    const content = (
        <>
            {renderActivator()}
            <SupernovaModals modalType={modalType} activatorRef={modalActivatorRef} subItems={subItems} />
        </>
    );

    if (isRedesignActive) {
        return (
            <SupernovaNaviItem visibleOn={isAuthorizedRedesign ? VISIBLE_ON : ANON_REDEIGN_VISIBLE_ON}>
                {content}
            </SupernovaNaviItem>
        );
    }

    return (
        <SupernovaNaviItem visibleOn={userType === UserType.Anonymous ? ANON_VISIBLE_ON : VISIBLE_ON}>
            {content}
        </SupernovaNaviItem>
    );
};

export default Help;
