import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { AdviceType } from 'src/utils/employer/adviceTypes';

export interface McpManagersAdviceDetails {
    totalUnreadResponsesCount: number;
    managers: {
        managerId: number;
        fullName: string;
        unreadResponsesCount: number;
        affectedVacanciesCount: number;
    }[];
}

export interface ManagerAdviceDetails {
    unreadResponsesCount: number;
    vacancies: {
        vacancyId: number;
        vacancyName: string;
        unreadResponsesCount: number;
    }[];
}

interface EmployerAdvice {
    [AdviceType.McpManagersUnreadResponses]: McpManagersAdviceDetails;
    [AdviceType.ManagerUnreadResponses]: ManagerAdviceDetails;
}

export default autoGeneratedReducer<EmployerAdvice>(null);
