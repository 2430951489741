import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatFromHyphenDateString } from 'Modules/formatDate';
import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.BillingCartInsufficientFunds]: {
        first: 'notification.messages.billing.cart.insufficient.funds.message.part1',
        second: 'notification.messages.billing.cart.insufficient.funds.message.part2',
        link: 'notification.messages.billing.cart.insufficient.funds.link.cart',
    },
    [ComplexUserNotificationsTemplateKey.BillingCartIrrelevantStatus]: {
        first: 'notification.messages.billing.cart.irrelevant.status.message.part1',
        second: 'notification.messages.billing.cart.irrelevant.status.message.part2',
        link: 'notification.messages.billing.cart.irrelevant.status.link.cart',
    },
    [ComplexUserNotificationsTemplateKey.BillingCartError]: {
        first: 'notification.messages.billing.cart.system.error.message.part1',
        second: 'notification.messages.billing.cart.system.error.message.part2',
        link: 'notification.messages.billing.cart.system.error.link.cart',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const BillingCardsToCardId: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;
    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Info}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>
                {trls[TrlKeys[key].first]}{' '}
                <NotificationLink
                    to={`/employer/carts?cartId=${notification.params.cart_id}&code=${key}`}
                    target="_blank"
                    data-qa="notification-link"
                >
                    <span suppressHydrationWarning>
                        {format(trls[TrlKeys[key].link], {
                            '{0}': formatFromHyphenDateString(notification.params.purchase_date),
                        })}
                    </span>
                </NotificationLink>{' '}
                {trls[TrlKeys[key].second]}
            </NotificationSpacer>
        </Notification>
    );
};

export default translation(BillingCardsToCardId);
