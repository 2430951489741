import { ApplicantResumeList } from 'src/models/applicantResumes';
import fetcher from 'src/utils/fetcher';

import { Resume } from 'src/components/ImportResumeModal/types';

declare global {
    interface FetcherGetApi {
        '/zp_resume_import/hh_resumes': {
            queryParams: void;
            response: {
                items: Resume[];
            };
        };
    }
    interface FetcherPostApi {
        '/zp_resume_import/import_resume': {
            queryParams: void;
            body: {
                resumeHash: string;
            };
            response: {
                resumeHash: string;
            };
        };
    }
    interface FetcherPostApi {
        '/applicant/resumes/clone': {
            body: null;
            queryParams: {
                resume: string;
            };
            response: {
                url: string;
            };
        };
    }
    interface FetcherGetApi {
        '/zp_resume_import/resumes': {
            queryParams: void;
            response: {
                resumes: ApplicantResumeList;
            };
        };
    }
}

export const getZpImportHHResumes = async (): Promise<{ items: Resume[] }> =>
    fetcher.get('/zp_resume_import/hh_resumes');

export const getZpImportResumes = async (): Promise<{ resumes: ApplicantResumeList }> =>
    fetcher.get('/zp_resume_import/resumes');

export const applicantResumeClone = async (copyValue: string): Promise<{ data: { url: string } }> =>
    fetcher.post(`/applicant/resumes/clone`, null, { params: { resume: copyValue } });

export const applicantResumeImport = async (importValue: string): Promise<{ data: { resumeHash: string } }> =>
    fetcher.post('/zp_resume_import/import_resume', { resumeHash: importValue });
