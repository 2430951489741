import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Call } from 'src/models/employer/vacancyresponses/calls.types';

interface CallResumeData {
    name: string;
    hash: string;
    id: string;
    topicId: string;
    isReturnCallAvailable: boolean;
}

interface PhoneCalls {
    calls: Call[];
}

interface VacancyResponsesState {
    phoneCalls: PhoneCalls;
    callsToResume: Record<number, CallResumeData>;
}

export const INITIAL_STATE = {
    phoneCalls: {
        calls: [],
    },
    callsToResume: {},
};

export const CALLS_TO_UPDATE = 'CALLS_TO_UPDATE';

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateCallsList = actionCreator(CALLS_TO_UPDATE);

interface PayloadTypes {
    [CALLS_TO_UPDATE]: PhoneCalls;
}

export default createReducer<VacancyResponsesState, PayloadTypes>(INITIAL_STATE, {
    [CALLS_TO_UPDATE]: (state, action) => {
        return { ...state, phoneCalls: action.payload };
    },
});
