import { useCallback } from 'react';

import { useExperimentGroupCheckFunction } from 'src/hooks/useExperimentCheckFunction';

const useSendExpOnEmployerServicesClick = (name: string): (() => void) => {
    const sendExpCheck = useExperimentGroupCheckFunction();
    return useCallback(() => {
        if (name === 'employerServices') {
            sendExpCheck('employer-account-menu-exp-3');
        }
    }, [name, sendExpCheck]);
};

export default useSendExpOnEmployerServicesClick;
