import { AnyAction } from 'redux';

import createReducer from '@hh.ru/redux-create-reducer';

import NovaFilters, { NovaFilterKey, Range } from 'src/models/novaFilters';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { RelocationOption } from 'src/models/resumeSearchCriteria';
import { FilterExpPeriodOption } from 'src/models/search/searchCriteria.types';

export const EMPTY_CLUSTER = { groups: {}, selectedValues: [] };
export const EMPTY_SINGLE_CLUSTER = { groups: {}, selectedValues: false };
export const EMPTY_RANGE_CLUSTER = {
    groups: {},
    selectedValues: {
        [Range.From]: null,
        [Range.To]: null,
        onlyWith: false,
    },
};
const EMPTY_COMPENSATION_CLUSTER = {
    groups: {},
    selectedValues: {
        salary: null,
        onlyWithSalary: false,
    },
};
export const EMPTY_GENDER_CLUSTER = {
    groups: {},
    selectedValues: {
        value: Gender.Unknown,
        onlyWith: false,
    },
};

export const SEARCH_CLUSTERS_INITIAL_STATE = {
    [NovaFilterKey.AcceptTemporary]: EMPTY_SINGLE_CLUSTER,
    [NovaFilterKey.Age]: EMPTY_RANGE_CLUSTER,
    [NovaFilterKey.Area]: EMPTY_CLUSTER,
    [NovaFilterKey.Compensation]: EMPTY_COMPENSATION_CLUSTER,
    [NovaFilterKey.University]: EMPTY_CLUSTER,
    [NovaFilterKey.Education]: EMPTY_CLUSTER,
    [NovaFilterKey.ExcludedText]: EMPTY_CLUSTER,
    [NovaFilterKey.Gender]: { groups: {}, selectedValues: EMPTY_GENDER_CLUSTER },
    [NovaFilterKey.Industry]: EMPTY_CLUSTER,
    [NovaFilterKey.Metro]: EMPTY_CLUSTER,
    [NovaFilterKey.Relocation]: { groups: {}, selectedValues: RelocationOption.Living },
    [NovaFilterKey.Salary]: EMPTY_RANGE_CLUSTER,
    [NovaFilterKey.SubIndustry]: EMPTY_CLUSTER,
    [NovaFilterKey.ProfessionalRole]: EMPTY_CLUSTER,
    [NovaFilterKey.District]: EMPTY_CLUSTER,
    [NovaFilterKey.Employment]: EMPTY_CLUSTER,
    [NovaFilterKey.Company]: EMPTY_CLUSTER,
    [NovaFilterKey.EducationLevel]: EMPTY_CLUSTER,
    [NovaFilterKey.Experience]: EMPTY_CLUSTER,
    [NovaFilterKey.FilterExpIndustry]: EMPTY_CLUSTER,
    [NovaFilterKey.FilterExpPeriodForIndustry]: { groups: {}, selectedValues: FilterExpPeriodOption.AllTime },
    [NovaFilterKey.JobSearchStatus]: EMPTY_CLUSTER,
    [NovaFilterKey.Label]: EMPTY_CLUSTER,
    [NovaFilterKey.Neighbours]: EMPTY_CLUSTER,
    [NovaFilterKey.Resume]: EMPTY_CLUSTER,
    [NovaFilterKey.Schedule]: EMPTY_CLUSTER,
    [NovaFilterKey.VacancyId]: EMPTY_CLUSTER,
    [NovaFilterKey.PartTime]: EMPTY_CLUSTER,
} as unknown as NovaFilters;

const NOVA_FILTER_UPDATE_SELECTED_VALUES = 'NOVA_FILTER_UPDATE_SELECTED_VALUES';

interface PayloadTypes {
    [NOVA_FILTER_UPDATE_SELECTED_VALUES]: {
        data: NovaFilters[keyof NovaFilters]['selectedValues'];
        filter: keyof NovaFilters;
    };
}
export const searchClustersUpdate = <F extends keyof NovaFilters>(payload: {
    filter: F;
    data: NovaFilters[F]['selectedValues'];
}): AnyAction => ({ type: NOVA_FILTER_UPDATE_SELECTED_VALUES, payload });

export default createReducer<NovaFilters, PayloadTypes>(SEARCH_CLUSTERS_INITIAL_STATE, {
    [NOVA_FILTER_UPDATE_SELECTED_VALUES]: (state, { payload: { data, filter } }) => {
        const copy = { ...state };
        copy[filter].selectedValues = data;
        return copy;
    },
});
