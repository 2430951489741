import vacancyBlockedVacancyLinkClick, {
    NotificationCode,
} from '@hh.ru/analytics-js-events/build/xhh/employer/notifications/vacancy_blocked_vacancy_link_click';
import Link from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { VacancyBlockedReason } from 'src/components/Notifications/VacancyBlocked';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    goToVacancy: 'supernova.notification.employer.vacancy.blocked.go.to.vacancy',

    [VacancyBlockedReason.VacancyBlockedDuePaidServices]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.paid.services',
        advice: 'supernova.notification.employer.vacancy.blocked.due.paid.services.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueBadDescription]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.bad.description',
        advice: 'supernova.notification.employer.vacancy.blocked.due.bad.description.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueTwoInOne]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.two.in.one',
        advice: 'supernova.notification.employer.vacancy.blocked.due.two.in.one.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueTraining]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.training',
        advice: 'supernova.notification.employer.vacancy.blocked.due.training.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueCaptionMissing]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.caption.missing',
        advice: 'supernova.notification.employer.vacancy.blocked.due.caption.missing.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueModerator]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.moderator',
        advice: 'supernova.notification.employer.vacancy.blocked.due.moderator.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueRelocateFromBelarus]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.relocate.from.belarus',
        advice: 'supernova.notification.employer.vacancy.blocked.due.relocate.from.belarus.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueReplacement]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.replacement',
        advice: 'supernova.notification.employer.vacancy.blocked.due.replacement.advice',
    },
};

const VacancyBlocked: TranslatedComponent<ComplexNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const notificationCode = notification.notificationCode as NotificationCode;

    return (
        <SynchronizedNotification analyticsElementName="vacancy_blocked" notification={notification} onClose={onClose}>
            {formatToReactComponent(trls[TrlKeys[notificationCode].main], {
                '{0}': (
                    <Text Element="span" strong>
                        {notification.params.vacancyName}
                    </Text>
                ),
            })}
            {notificationCode !== VacancyBlockedReason.VacancyBlockedDueModerator && (
                <>
                    <VSpacing base={1} />
                    {trls[TrlKeys[notificationCode].advice]}
                    <VSpacing base={1} />
                    <Link
                        onClick={() => {
                            vacancyBlockedVacancyLinkClick({
                                notificationCode,
                                notificationType: 'bloko_notification',
                            });
                        }}
                        target="_blank"
                        href={`/vacancy/${notification.params.vacancyId}?from=notification&hhtmFrom=${hhtmSource}&hhtmFromLabel=user_notification`}
                    >
                        {trls[TrlKeys.goToVacancy]}
                    </Link>
                </>
            )}
        </SynchronizedNotification>
    );
};

export default translation(VacancyBlocked);
