export enum DataQaInvariants {
    Empty = 'notification_empty',
    Info = 'notification_info',
    Success = 'notification_success',
    Verified = 'notification_verified',
    Exclamation = 'notification_exclamation',
    CareerConsultPaid = 'notification_career_consult_paid',
    CareerConsultWaitingForCall = 'notification_career_consult_waiting_for_call',
    CompleteResumeWorkDone = 'notification_complete_resume_work_done',
    CompleteResumeWaitingForCall = 'notification_complete_resume_waiting_for_call',
    EmployerReviewCanEnable = 'notification_employer_reviews_can_enable',
    EmployerReviewFeedbackCreated = 'notification_employer_reviews_feedback_created',
    InterviewPracticePaid = 'notification_interview_practice_paid',
    InterviewPracticeWaitingForCall = 'notification_interview_practice_waiting_for_call',
    ResumeTargetEmployerDiscount = 'notification_resume_targetEmployer_discount',
    ResumeRenewalExpired = 'notification_resume_renewal_expired',
    ResumeRenewalSoonExpires = 'notification_resume_renewal_soon_expires',
    Question = 'notification_question',
    Mail = 'notification_mail',
    ChristmasTree = 'notification_christmas-tree',
    SetkaPromo = 'notification_setka-promo',
}
