import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum AuthSystemMethods {
    External = 'external',
    Native = 'native',
}

export interface AccountTemporarilyLocked {
    locked?: boolean;
    remainingTime?: string;
    authSystemMethod?: AuthSystemMethods;
}

export default autoGeneratedReducer<AccountTemporarilyLocked>({});
