import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';

import { CurrencyType } from 'src/models/currencies.types';
import NegotiationTopic from 'src/models/negotiationTopic.types';

export interface VacancyOfTheDay {
    name: string;
    vacancyOfTheDayId: null | number;
    area: {
        id: number;
        name: string;
    };
    click?: string;
    vacancyId: number;
    compensation: {
        from: number;
        to: number;
        currencyCode: CurrencyType;
        gross: boolean;
    };
    company: {
        id: number;
        visibleName: string;
        department?: {
            // tempexp_clickme-vacancy-of-the-day-experiment_next-line
            code: string;
            '@code': string;
        };
        logoUrl?: string;
    };
    links: {
        desktop: string;
    };
    viewUrl: string;
    showContact: boolean;
    chatWritePossibility: ChatWritePossibility;
}

export interface VacanciesOfTheDay {
    // tempexp_clickme-vacancy-of-the-day-experiment_start
    clickmeVacanciesPlacesList: string[];
    readyToShuffleVacancies: boolean;
    clickmeVacancies: VacancyOfTheDay[];
    firstFakeVacancyIndex: number;
    tempVacancies: VacancyOfTheDay[];
    // tempexp_clickme-vacancy-of-the-day-experiment_end
    vacancies: VacancyOfTheDay[];
    compensation: {
        currencyCode: CurrencyType;
        from: number;
        to: number;
    };
    topicsByVacancyId: Record<number, NegotiationTopic[]>;
}

export interface VacanciesOfTheDayState extends VacanciesOfTheDay {
    isLoading: boolean;
    success: boolean;
}

export default autoGeneratedReducer<VacanciesOfTheDayState>({
    isLoading: true,
    success: true,
    vacancies: [],
    // tempexp_clickme-vacancy-of-the-day-experiment_start
    readyToShuffleVacancies: false,
    clickmeVacanciesPlacesList: [],
    clickmeVacancies: [],
    firstFakeVacancyIndex: 100,
    tempVacancies: [],
    // tempexp_clickme-vacancy-of-the-day-experiment_end
    compensation: { from: 0, to: 0, currencyCode: CurrencyType.RUR },
    topicsByVacancyId: {},
});
