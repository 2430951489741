import { useCallback, useEffect, useState, memo, ReactNode, ReactElement, FC } from 'react';
import { Merge } from 'type-fest';

import Info, { InfoPlacement, InfoLayer, InfoTheme, InfoProps } from 'bloko/blocks/drop/Info';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import { useSelector } from 'src/hooks/useSelector';

import ClearButton from 'src/components/Infotip/ClearButton';

interface InfotipProps {
    /** Название показываемого инфотипа */
    name: string;
    render: (hideInfotip: () => void) => ReactNode;
    show?: boolean;
    showClearButton?: boolean;
    onSeen?: () => void;
    onClose?: () => void;
    markAsViewedEvent?: MarkAsViewedEvent;
    children: ReactElement;
    autoHide?: boolean;
}

const Infotip: FC<Merge<InfotipProps, Omit<InfoProps, 'render'>>> = ({
    name,
    render,
    show,
    showClearButton,
    onSeen,
    autoHide = true,
    onClose,
    markAsViewedEvent = MarkAsViewedEvent.OnShow,
    children,
    ...otherProps
}) => {
    const [isVisible, setVisible] = useState(true);
    const showInfoTip = useSelector(({ infoTip }) => show && infoTip.name === name);

    const mark = useCallback(
        (action: MarkAsViewedEvent) => {
            if (markAsViewedEvent === action) {
                Notices.markAsViewed(name);
                onSeen?.();
            }
        },
        [name, markAsViewedEvent, onSeen]
    );

    useEffect(() => {
        if (showInfoTip && autoHide) {
            mark(MarkAsViewedEvent.OnShow);
        }
    }, [autoHide, mark, showInfoTip]);

    const hideInfotip = useCallback(() => {
        mark(MarkAsViewedEvent.OnHide);
        onClose?.();
        setVisible(false);
    }, [mark, onClose]);

    const renderContent = useCallback(
        () => (
            <>
                {render(hideInfotip)}
                {showClearButton && <ClearButton onClick={hideInfotip} />}
            </>
        ),
        [hideInfotip, render, showClearButton]
    );

    return (
        <Info show={showInfoTip && isVisible} onClose={hideInfotip} render={renderContent} {...otherProps}>
            {children}
        </Info>
    );
};

export default memo(Infotip);
export { InfoPlacement, InfoLayer, InfoTheme };
