import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ApplicantPortraitItemType {
    name: string;
    resumeCount: number;
}

export interface ApplicantPortraitCategoryType {
    name: string;
    items: ApplicantPortraitItemType[];
}

export default autoGeneratedReducer<ApplicantPortraitCategoryType[]>([]);
