import { useCallback } from 'react';

import disabledMicCloseButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_close_button_click';
import disabledMicRepeatButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_repeat_button_click';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { MicCrossedOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import translation from 'src/components/translation';

import WebcallUserDataError from 'src/components/Webcall/WebcallUserDataError';
import useWatchWebcallClose from 'src/components/Webcall/useWatchWebcallClose';

import styles from './styles.less';

type WebcallWrapperProps = {
    place: string;
    onClose: VoidFunction;
    hhtmSource: HhtmSource;
    resumeId: number;
    topicId?: string;
};

const TrlKeys = {
    alertTitle: 'webcall.micDisabledPopup.title',
    alertDescription: 'webcall.micDisabledPopup.description',
    alertRepeatAction: 'webcall.micDisabledPopup.repeatAction',
    alertCancelAction: 'webcall.micDisabledPopup.cancelAction',
};

const WebcallWrapper: TranslatedComponent<WebcallWrapperProps> = ({
    place,
    trls,
    onClose,
    topicId,
    resumeId,
    hhtmSource,
}) => {
    const { isMicPermissionError, retryMicPermission, alertRef, isUserDataError, resetUserDataError } =
        useWatchWebcallClose({
            closeFn: onClose,
            widgetId: place,
            topicId,
            resumeId,
            source: hhtmSource,
        });

    const onAlertClose = useCallback(() => {
        onClose();
        disabledMicCloseButtonClick({
            hhtmSource,
            resumeId,
            topicId,
        });
    }, [hhtmSource, onClose, resumeId, topicId]);

    const onCallRepeat = useCallback(() => {
        retryMicPermission();
        disabledMicRepeatButtonClick({
            hhtmSource,
            resumeId,
            topicId,
        });
    }, [hhtmSource, resumeId, retryMicPermission, topicId]);

    return (
        <div className={styles.webcallWrapper}>
            <div ref={alertRef}>
                <Alert
                    title={trls[TrlKeys.alertTitle]}
                    description={trls[TrlKeys.alertDescription]}
                    iconStyle="negative"
                    layout="vertical"
                    icon={<MicCrossedOutlinedSize24 />}
                    buttons={
                        <>
                            <Button mode="primary" style="accent" stretched onClick={onCallRepeat}>
                                {trls[TrlKeys.alertRepeatAction]}
                            </Button>
                            <Button mode="secondary" style="accent" stretched onClick={onAlertClose}>
                                {trls[TrlKeys.alertCancelAction]}
                            </Button>
                        </>
                    }
                    visible={isMicPermissionError}
                    onClose={onAlertClose}
                />
            </div>
            {isUserDataError && (
                <WebcallUserDataError
                    onClose={() => {
                        onClose();
                        resetUserDataError();
                    }}
                />
            )}
            {!isMicPermissionError && !isUserDataError && (
                <ContainerForMicroFrontend place={place} preloadContent={null} />
            )}
        </div>
    );
};

export default translation(WebcallWrapper);
