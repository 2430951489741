import { FC } from 'react';

import { Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

interface PopupActionsProps {
    onClose: () => void;
}

const PopupActions: FC<PopupActionsProps> = ({ onClose }) => {
    return (
        <div className={styles.popupActions}>
            <Action icon={CrossOutlinedSize24} mode="secondary" style="neutral" onClick={onClose} />
        </div>
    );
};

export default PopupActions;
