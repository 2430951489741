import { GridColumn } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import BlokoLink, { LinkKind, LinkAppearance } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AreaSwitcherTopLevelTitle from 'src/components/AreaSwitcher/AreaSwitcherTopLevelTitle';
import CitiesList from 'src/components/AreaSwitcher/CitiesList';
import { AreaSwitcherScreens, TopLevelDomain } from 'src/components/AreaSwitcher/areaSwitcherTypes';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface TopLevelDomainsCitiesListProps {
    domain: TopLevelDomain;
    setCurrentScreen: (val: AreaSwitcherScreens) => void;
    setSelectedAreaId: () => void;
}

const TrlKeys = {
    more: 'area.switcher.andMore',
    moreOne: 'area.switcher.more.one',
    moreMany: 'area.switcher.more.some',
    moreSome: 'area.switcher.more.many',
};

const TopLevelDomainsCitiesList: TranslatedComponent<TopLevelDomainsCitiesListProps> = ({
    trls,
    domain,
    setCurrentScreen,
    setSelectedAreaId,
}) => {
    const hasMoreSubdomains = domain.moreLength > 0;

    return (
        <GridColumn xs={4} s={4} m={4} l={3}>
            <div className="area-switcher-column">
                <AreaSwitcherTopLevelTitle domain={domain} />

                <CitiesList domain={domain.subdomains} />

                {hasMoreSubdomains && (
                    <Text importance={TextImportance.Tertiary}>
                        {trls[TrlKeys.more]}
                        {NON_BREAKING_SPACE}
                        <BlokoLink
                            kind={LinkKind.Secondary}
                            appearance={LinkAppearance.Pseudo}
                            onClick={() => {
                                setSelectedAreaId();
                                setCurrentScreen(AreaSwitcherScreens.SubdomainsByLetter);
                            }}
                        >
                            <ConversionNumber
                                value={domain.moreLength}
                                one={trls[TrlKeys.moreOne]}
                                some={trls[TrlKeys.moreSome]}
                                many={trls[TrlKeys.moreMany]}
                            />
                        </BlokoLink>
                    </Text>
                )}
            </div>
        </GridColumn>
    );
};

export default translation(TopLevelDomainsCitiesList);
