import { ComponentType } from 'react';

import { Button, VSpacing } from '@hh.ru/magritte-ui';
import { CopyOutlinedSize24, DocumentOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { Step } from 'src/components/ImportResumeModal/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    createTitle: 'zp.importResume.v2.choose.createTitle',
    importTitle: 'zp.importResume.v2.choose.importTitle',
    copyTitle: 'zp.importResume.v2.choose.copyTitle',
};

type Props = {
    url: string;
    onSetStep: (step: Step) => void;
};

const ChooseStep: ComponentType<Props> = translation(({ trls, url, onSetStep }) => {
    const push = usePush();
    const hasResumes = useSelector((state) => state.applicantInfo?.total > 0);

    const onCreate = () => {
        push(url);
    };

    const onImport = () => {
        onSetStep(Step.Auth);
    };

    const onCopy = () => {
        onSetStep(Step.Copy);
    };

    return (
        <>
            <Button
                stretched
                size="large"
                style="neutral"
                mode="tertiary"
                icon={<DocumentOutlinedSize24 />}
                onClick={onCreate}
                data-qa={'import_resume-choose-create_cell'}
            >
                {trls[TrlKeys.createTitle]}
            </Button>
            <VSpacing default={8} gteS={4} />
            <Button
                stretched
                size="large"
                style="neutral"
                mode="tertiary"
                icon={<CopyOutlinedSize24 />}
                onClick={onImport}
                data-qa={'import_resume-choose-import_cell'}
            >
                {trls[TrlKeys.importTitle]}
            </Button>
            {hasResumes && (
                <>
                    <VSpacing default={8} gteS={4} />
                    <Button
                        stretched
                        size="large"
                        style="neutral"
                        mode="tertiary"
                        icon={<CopyOutlinedSize24 />}
                        onClick={onCopy}
                        data-qa={'import_resume-choose-copy_cell'}
                    >
                        {trls[TrlKeys.copyTitle]}
                    </Button>
                </>
            )}
        </>
    );
});

export default ChooseStep;
