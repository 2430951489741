import { Button, Card, Cell, CellText, Text } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';

interface WaitForActivationCardProps {
    count?: number;
    isLoading: boolean;
}

const TrlKeys = {
    actionDescription: 'employer.employerProfile.popup.waitForActivation.action.description',
    title: 'employer.employerProfile.popup.waitForActivation.title',
    subtitle: 'employer.employerProfile.popup.waitForActivation.subtitle',
};

const WaitForActivationCard: TranslatedComponent<WaitForActivationCardProps> = ({ isLoading, count, trls }) => {
    if (isLoading || !count) {
        return null;
    }
    return (
        <Card data-qa="wait-for-activation-card" borderRadius={24} padding={16} style="negative">
            <Cell
                right={
                    <Button
                        data-qa="wait-for-activation-button"
                        hideLabel
                        mode="primary"
                        style="contrast"
                        size="medium"
                        aria-label={trls[TrlKeys.actionDescription]}
                        to={paths.employerCarts}
                        Element={Link}
                        icon={<ArrowRightOutlinedSize24 />}
                    />
                }
            >
                <CellText data-qa="wait-for-activation-title">
                    <Text typography="subtitle-1-semibold" style="primary">
                        {trls[TrlKeys.title]}
                    </Text>
                </CellText>
                <CellText data-qa="wait-for-activation-description" style="primary" type="description">
                    {trls[TrlKeys.subtitle]}
                </CellText>
            </Cell>
        </Card>
    );
};

export default translation(WaitForActivationCard);
