import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

type BillItem = Record<
    string,
    {
        name: string;
    }
>;

type Filter = {
    ids: string[];
    items: BillItem;
};

type DateRange = {
    start: string;
    end: string;
};

interface FinancialDocumentDeedsFilters {
    payers: Filter;
    bills: Filter;
    periods: {
        allTheTime: DateRange;
        year: DateRange;
        quarter: DateRange;
        month: DateRange;
    };
}

export default autoGeneratedReducer<FinancialDocumentDeedsFilters>(null);
