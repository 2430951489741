import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ResumeViewList {
    employerManagerId: number;
    fullName: string;
    lastViewDate: { '@timestamp': number; $: string };
}

interface ResumeViewHistory {
    showResumeFallbackWarning: boolean;
    resumeView?: ResumeViewList[];
}

export default autoGeneratedReducer<ResumeViewHistory>({ showResumeFallbackWarning: false });
