import { useState, useEffect, useCallback } from 'react';

import userStatusSelectOfferCompanyButtonClick, {
    JobSearchStatus as JobSearchStatusAnalytics,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_button_click';
import userStatusSelectOfferCompanyEmptyButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_empty_button_click';
import userStatusSelectWhereFoundButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_where_found_button_click';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';

import {
    FoundOnHHVariants,
    Offer,
    UseFoundOnHHStep,
    UseOffersStep,
    UseReasonStep,
    FoundOnHHValue,
    ReasonValue,
} from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';

export const useFoundOnHHStep: UseFoundOnHHStep = ({ closeModal, setModalStep, fetchOffers }) => {
    const [foundOnHHValue, setFoundOnHHValue] = useState<FoundOnHHValue>(null);

    useEffect(() => {
        if (foundOnHHValue) {
            userStatusSelectWhereFoundButtonClick({
                offerFromHH: foundOnHHValue === FoundOnHHVariants.FoundOnHH,
            });

            if (foundOnHHValue === FoundOnHHVariants.NotFoundOnHH) {
                closeModal();
                return;
            }

            fetchOffers({ setModalStep });
        }
    }, [closeModal, fetchOffers, foundOnHHValue, setModalStep]);

    const updateFoundOnHHValue = useCallback((value: FoundOnHHValue) => {
        setFoundOnHHValue(value);
    }, []);

    return { foundOnHHValue, updateFoundOnHHValue };
};

export const useReasonsStep: UseReasonStep = ({ closeModal }) => {
    const [reasonValue, setReasonValue] = useState<ReasonValue>(null);

    useEffect(() => {
        if (reasonValue) {
            userStatusSelectOfferCompanyEmptyButtonClick({
                type: reasonValue,
            });

            closeModal();
        }
    }, [closeModal, reasonValue]);

    const updateReasonValue = useCallback((value: ReasonValue) => {
        setReasonValue(value);
    }, []);

    return { reasonValue, updateReasonValue };
};

export const useOffersStep: UseOffersStep = ({ userStatus, closeModal }) => {
    const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);
    const userStatusAnalytics = userStatus.toUpperCase() as JobSearchStatusAnalytics;

    const addOffer = useCallback(
        (employerId: number, vacancyId: number, resumeId: number) => {
            if (userStatus === JobSearchStatus.AcceptedJobOffer) {
                setSelectedOffers([{ employerId, vacancyId, resumeId }]);
                return;
            }

            let newValue;

            if (selectedOffers.map((offer) => offer.vacancyId).includes(vacancyId)) {
                newValue = selectedOffers.filter((offer) => offer.vacancyId !== vacancyId);
            } else {
                newValue = [...selectedOffers, { employerId, vacancyId, resumeId }];
            }

            setSelectedOffers(newValue);
        },
        [userStatus, selectedOffers]
    );

    const finishChooseOffers = useCallback(
        (offers: Offer[]) => {
            offers.forEach(({ employerId, vacancyId, resumeId }) => {
                userStatusSelectOfferCompanyButtonClick({
                    employerId: employerId.toString(),
                    type: 'full',
                    vacancyId: `${vacancyId}`,
                    resumeId: `${resumeId}`,
                    jobSearchStatus: userStatusAnalytics,
                });
            });

            closeModal();
        },
        [closeModal, userStatusAnalytics]
    );

    const updateSelectedOffers = useCallback((offers: Offer[]) => {
        setSelectedOffers(offers);
    }, []);

    useEffect(() => {
        if (userStatus === JobSearchStatus.AcceptedJobOffer && selectedOffers.length) {
            finishChooseOffers(selectedOffers);
        }
    }, [selectedOffers, finishChooseOffers, userStatus]);

    return {
        selectedOffers,
        addOffer,
        finishChooseOffers,
        updateSelectedOffers,
    };
};

export default useOffersStep;
