import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import { GetSearchParamsArgs } from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/types';
import getVacancySearchParams, {
    VacancySearchParams,
} from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancy';

export interface VacancyMapSearchParams extends VacancySearchParams {
    [CriteriaKey.BottomLeftLat]: string;
    [CriteriaKey.BottomLeftLng]: string;
    [CriteriaKey.GeocodeType]: string;
    [CriteriaKey.Height]: string;
    [CriteriaKey.ItemsOnPage]: string;
    [CriteriaKey.Precision]: string;
    [CriteriaKey.TopRightLat]: string;
    [CriteriaKey.TopRightLng]: string;
    [CriteriaKey.Width]: string;
}

const getVacancyMapSearchParams = (args: GetSearchParamsArgs): VacancyMapSearchParams => {
    const searchParams = getVacancySearchParams(args) as VacancyMapSearchParams;
    return searchParams;
};

export default getVacancyMapSearchParams;
