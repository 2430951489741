import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import buttonClickCloseResumesCountriesVisibilitySettingsModal from '@hh.ru/analytics-js-events/build/xhh/applicant/resumes_countries_visibility_settings_modal/button_click_close_resumes_countries_visibility_settings_modal';
import buttonClickProcessingConditionsResumesCountriesVisibilitySettingsModal from '@hh.ru/analytics-js-events/build/xhh/applicant/resumes_countries_visibility_settings_modal/button_click_processing_conditions_resumes_countries_visibility_settings_modal';
import buttonClickProcessingRulesResumesCountriesVisibilitySettingsModal from '@hh.ru/analytics-js-events/build/xhh/applicant/resumes_countries_visibility_settings_modal/button_click_processing_rules_resumes_countries_visibility_settings_modal';
import formSubmitSaveResumesCountriesVisibilitySettingsModal from '@hh.ru/analytics-js-events/build/xhh/applicant/resumes_countries_visibility_settings_modal/form_submit_save_resumes_countries_visibility_settings_modal';
import screenShownResumesCountriesVisibilitySettingsModal, {
    HhtmFrom,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resumes_countries_visibility_settings_modal/screen_shown_resumes_countries_visibility_settings_modal';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeCountriesVisibilitySettings } from 'src/models/resumeCountriesVisibility';
import fetcher from 'src/utils/fetcher';

import ResumesCountriesVisibilityForm from 'src/components/ResumesCountriesVisibility/ResumesCountriesVisibilityForm';
import { CountrySelection } from 'src/components/ResumesCountriesVisibility/types';

import styles from './resumes-visibility-settings-modal.less';

const TrlKeys = {
    title: 'components.appliciant_countries_visibility.visibility_settings_modal.header',
    save: 'components.appliciant_countries_visibility.visibility_settings_modal.save',
};

const SAVE_URL = '/applicant/settings/resume_countries_visibility';

const buildCheckBoxAnalyticData = (state: ResumeCountriesVisibilitySettings): string | null => {
    const array: CountrySelection[] = [];
    if (state.visibleForCommonCountries) {
        array.push('common');
    }
    if (state.visibleForUzbekistan) {
        array.push('uzbekistan');
    }

    if (array.length === 0) {
        return null;
    }
    return array.join(',');
};

const setShowingModalAction = makeSetStoreField('showResumeCountriesVisibilitySettingsModal');

const ResumesCountriesVisibilitySettingsModal: TranslatedComponent = ({ trls }) => {
    const hhtmFrom = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource) as HhtmFrom;
    const checkBoxInitialState = useSelector(({ resumeCountriesVisibility }) => resumeCountriesVisibility);
    const [checkBoxState, setCheckBoxState] = useState(checkBoxInitialState);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const checkBoxStateAnalyticValue = buildCheckBoxAnalyticData(checkBoxState);
    const isXs = useBreakpoint() === Breakpoint.XS;
    const { addNotification } = useNotification();

    const onClose = () => {
        buttonClickCloseResumesCountriesVisibilitySettingsModal({
            checkbox: checkBoxStateAnalyticValue,
            hhtmFrom,
        });
        dispatch(setShowingModalAction(false));
    };

    const handleChange = useCallback(
        (name: 'visibleForCommonCountries' | 'visibleForUzbekistan') => {
            setCheckBoxState((prev) => ({ ...prev, [name]: !prev[name] }));
        },
        [setCheckBoxState]
    );

    const onSubmit = () => {
        setIsFetching(true);
        fetcher
            .postFormData(SAVE_URL, { ...checkBoxState, isModal: true })
            .then(() => {
                formSubmitSaveResumesCountriesVisibilitySettingsModal({
                    checkbox: checkBoxStateAnalyticValue,
                    hhtmFrom,
                });
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            })
            .finally(() => {
                setIsFetching(false);
                dispatch(setShowingModalAction(false));
            });
    };

    useEffect(() => {
        screenShownResumesCountriesVisibilitySettingsModal({ hhtmFrom });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal data-qa="resumes-countries-visibility-settings-modal" visible onClose={onClose} useBottomSheet>
            <div className={styles.resumesVisibilitySettingsModalContent}>
                <ModalTitle data-qa="resumes-countries-visibility-settings-title-modal">
                    {trls[TrlKeys.title]}
                </ModalTitle>
                <VSpacing base={5} />
                <ModalContent>
                    <ResumesCountriesVisibilityForm
                        isFetching={isFetching}
                        settings={checkBoxState}
                        handleChange={handleChange}
                        dataQaKey={'modal'}
                        conditionsClickHandler={(country: CountrySelection) => {
                            buttonClickProcessingConditionsResumesCountriesVisibilitySettingsModal({
                                conditions: country,
                                hhtmFrom,
                            });
                        }}
                        rulesClickHandler={(country: CountrySelection) => {
                            buttonClickProcessingRulesResumesCountriesVisibilitySettingsModal({
                                rules: country,
                                hhtmFrom,
                            });
                        }}
                    />
                </ModalContent>
                <ModalFooter>
                    <Button
                        type={ButtonType.Button}
                        kind={ButtonKind.Primary}
                        disabled={isFetching}
                        stretched={isXs}
                        onClick={onSubmit}
                        data-qa="resumes-countries-visibility-settings-button-modal"
                    >
                        {trls[TrlKeys.save]}
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default translation(ResumesCountriesVisibilitySettingsModal);
