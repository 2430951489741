import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SpecialProject {
    url: string;

    // TODO: Пока используется только урл. Возможно, в будущем захотим поддержать поля:
    // title: string;
    // image: string;
}

export const specialProjectReducer = autoGeneratedReducer<SpecialProject>(null);
