import { type FC } from 'react';

import type {
    MagritteNotificationComponent,
    RemoveNotification,
    StoredNotification,
} from 'src/components/Notifications/Provider/types';

const MagritteNotifications: FC<{
    notifications: StoredNotification[];
    removeNotificationByIdAction: RemoveNotification;
}> = ({ notifications, removeNotificationByIdAction }) => {
    return (
        <>
            {notifications.map((notification) => {
                const { component, id, props } = notification;
                const onCloseCallback = () => {
                    removeNotificationByIdAction(id);
                };

                const NotificationComponent = component as MagritteNotificationComponent;
                return <NotificationComponent key={`notify-${id}`} removeNotification={onCloseCallback} {...props} />;
            })}
        </>
    );
};

export default MagritteNotifications;
