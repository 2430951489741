import { type PropsWithChildren, type FC, useEffect, useCallback } from 'react';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { isFetcherError } from 'src/utils/fetcher/fetcherError';

const FetcherErrorHandler: FC<PropsWithChildren> = ({ children }) => {
    const { addNotification } = useNotification();

    const onUnhandledRejection = useCallback(
        ({ reason }: PromiseRejectionEvent) => {
            if (isFetcherError(reason)) {
                defaultRequestErrorHandler(reason, addNotification);
            }
        },
        [addNotification]
    );

    useEffect(() => {
        window.addEventListener('unhandledrejection', onUnhandledRejection);
        return () => window.removeEventListener('unhandledrejection', onUnhandledRejection);
    }, [onUnhandledRejection]);

    return <>{children}</>;
};

export default FetcherErrorHandler;
