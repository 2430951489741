import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum ViewDurationEvent {
    VacancyViewDuration = 'VacancyViewDuration',
    ResumeViewDuration = 'ResumeViewDuration',
}

export interface ViewDurationProps {
    eventType: ViewDurationEvent;
    params: Record<string, string>;
}

export default autoGeneratedReducer<ViewDurationProps | null>(null);
