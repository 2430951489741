import { Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NavItems } from 'src/models/supernovaNaviMenu';

import EmployerPanelCardItem from 'src/components/SupernovaMainMenu/EmployerProfile/EmployerPanelCarditem';

import styles from './styles.less';

interface EmployerPanelProps {
    subItems?: NavItems;
}

const EmployerPanel: TranslatedComponent<EmployerPanelProps> = ({ subItems }) => {
    return (
        <div className={styles.panelContent}>
            {subItems?.map((item) => {
                if (item.name === 'divider') {
                    return (
                        <div key={item.name}>
                            <Divider marginTop={20} marginBottom={20} marginLeft={12} marginRight={12} />
                        </div>
                    );
                }
                return <EmployerPanelCardItem key={item.name} {...item} />;
            })}
        </div>
    );
};

export default translation(EmployerPanel);
