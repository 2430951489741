import scrollTop from 'bloko/common/scrollTop';

import { qaState, SCROLL_PROCESSING } from 'Modules/qaState';

export default ({ animationStartCallback, animationEndCallback, ...params }) => {
    scrollTop({
        ...params,
        animationStartCallback: () => {
            qaState[SCROLL_PROCESSING] = true;
            animationStartCallback && animationStartCallback();
        },
        animationEndCallback: () => {
            qaState[SCROLL_PROCESSING] = false;
            animationEndCallback && animationEndCallback();
        },
    });
};
