import { createContext } from 'react';

interface CodeInjectorContextValue {
    placesNamesForXSL: string[];
    placesNamesForMicroFrontends: string[];
    portals: string[];
}

export const CodeInjectorContext = createContext<CodeInjectorContextValue>({
    placesNamesForXSL: [],
    placesNamesForMicroFrontends: [],
    portals: [],
});

export const DIVIDER_NODE_NAME = 'lux-divide-result-into-several-parts';

export const makeLuxDivider = (place: string): string => `<${DIVIDER_NODE_NAME} place='${place}' />`;
