import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import SearchVisibilityList from 'src/models/resumeVisibility/searchVisibilityList.types';

export const RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST = 'RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST';
export const RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST_FETCHING =
    'RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST_FETCHING';

export const INITIAL_STATE: SearchVisibilityList = {
    items: [],
    total: 0,
    isFetching: false,
};

interface PayloadTypes {
    [RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST_FETCHING]: void;
    [RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST]: SearchVisibilityList;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumeVisibilitySearchResultsWhitelistFetching = actionCreator(
    RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST_FETCHING
);
export const resumeVisibilitySearchResultsWhitelist = actionCreator(RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST);

export default createReducer<SearchVisibilityList, PayloadTypes>(INITIAL_STATE, {
    [RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST_FETCHING]: (state) => {
        return { ...state, isFetching: true };
    },
    [RESUME_VISIBILITY_SEARCH_RESULTS_WHITELIST]: (state, action) => {
        const payload = action.payload ?? INITIAL_STATE;
        payload.items = payload.items || [];
        return { ...state, ...payload, isFetching: false };
    },
});
