import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum EmployerEmailTemplateVisibilityType {
    Common = 'common',
    Anonymous = 'anonymous',
}

export interface EmployerEmailTemplate {
    type: string;
    text: string;
    isSMS: boolean;
}

export type EmployerEmailTemplates = Record<EmployerEmailTemplateVisibilityType, Record<string, EmployerEmailTemplate>>;

const CHANGE_EMPLOYER_EMAIL_TEMPLATE = 'CHANGE_EMPLOYER_EMAIL_TEMPLATE';

interface PayloadTypes {
    [CHANGE_EMPLOYER_EMAIL_TEMPLATE]: {
        visibilityType: EmployerEmailTemplateVisibilityType;
        template: EmployerEmailTemplate;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const changeEmployerEmailTemplateAction = actionCreator(CHANGE_EMPLOYER_EMAIL_TEMPLATE);

export default createReducer<EmployerEmailTemplates, PayloadTypes>(null, {
    [CHANGE_EMPLOYER_EMAIL_TEMPLATE]: (state, { payload }) => ({
        ...state,
        [payload.visibilityType]: {
            ...state[payload.visibilityType],
            [payload.template.type]: payload.template,
        },
    }),
});
