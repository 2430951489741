import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';

const TrlKeys = {
    kakdela: 'supernova.navi.kakdelaFeedbackPlatform.employerMenu',
};

const Kakdela: TranslatedComponent<NavItem> = ({ name, trls, ...props }) => {
    return (
        <SupernovaDropdownOption name={name} {...props}>
            {trls[TrlKeys.kakdela]}
        </SupernovaDropdownOption>
    );
};

export default translation(Kakdela);
