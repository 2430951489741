import { FC, useCallback, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';

import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import { NavItem } from 'src/models/supernovaNaviMenu';

interface UserNotificationsMenuItemProps extends NavItem {
    onClick: () => void;
    unreadCount: number;
}

const UserNotificationsMenuItem: FC<UserNotificationsMenuItemProps & PropsWithChildren> = ({
    name,
    translations,
    unreadCount,
    onClick,
    ...props
}) => {
    const handleClick = useCallback(() => {
        onClick();
        Analytics.sendHHEventButtonClick('menu_item', { item: 'notification_list' });
    }, [onClick]);

    return (
        <SupernovaDropdownOption data-qa={`mainmenu_${name}-xs`} name={name} onClick={handleClick} {...props}>
            {translations.name}
            {unreadCount > 0 && <SupernovaCounter dropdown>{unreadCount}</SupernovaCounter>}
        </SupernovaDropdownOption>
    );
};

export default UserNotificationsMenuItem;
