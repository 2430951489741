import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    error: 'employer.implants.switch.error',
    passInvalidTitle: 'multiaccount.employer.switch.error.invalidpass.title',
    passInvalidText: 'multiaccount.employer.switch.error.invalidpass.text',
};

const EmployerAccountSwitchError: TranslatedComponent = ({ trls }) => {
    return <div>{trls[TrlKeys.error]}</div>;
};

export const employerAccountSwitchError = {
    Element: translation(EmployerAccountSwitchError),
    type: 'error',
};

const EmployerAccountSwitchPassInvalidatedError: TranslatedComponent = ({ trls }) => {
    return (
        <>
            <NotificationHeading>{trls[TrlKeys.passInvalidTitle]}</NotificationHeading>
            <p>{trls[TrlKeys.passInvalidText]}</p>
        </>
    );
};

export const employerAccountSwitchPassInvalidatedError = {
    Element: translation(EmployerAccountSwitchPassInvalidatedError),
    type: 'error',
    autoClose: true,
    autoCloseDelay: 15000,
};
