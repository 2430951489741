import { FC, PropsWithChildren } from 'react';

const NoIndex: FC<PropsWithChildren> = ({ children }) => (
    <>
        <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
        {children}
        <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
    </>
);

export default NoIndex;
