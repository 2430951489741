import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyIdAutoActions {
    vacancyId: number;
    status: string;
}
export interface VacancyAutoActions {
    PROLONGATE: VacancyIdAutoActions[];
    UPGRADE: VacancyIdAutoActions[];
}

export default autoGeneratedReducer<VacancyAutoActions>({ PROLONGATE: [], UPGRADE: [] });
