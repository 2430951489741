import { useCallback, useEffect, useMemo, useState } from 'react';

import headerGeoDefinitionAreaFieldTouch from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_area_field_touch';
import debounce from 'bloko/common/debounce';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import fetcher from 'src/utils/fetcher';

const SEARCH_REGIONS_URL = '/shards/area_switcher/search';

type SearchRegionsResponse = {
    items: Array<{
        href: string;
        region?: string;
        text: string;
        topLevelDomain: string;
        isOtherDomain: boolean;
        areaId: number;
    }>;
};

declare global {
    interface FetcherGetApi {
        [SEARCH_REGIONS_URL]: {
            response: SearchRegionsResponse;
            queryParams: { backUrl?: string; q: string; enableAnyLocation?: boolean; ignoreRussia?: boolean };
        };
    }
}

const FETCH_DEBOUNCE_MS = 300;

const useSearchRegions = (): {
    handleSearchChange: (value: string) => void;
    search: string;
    searchRegionsData: SearchRegionsResponse | null;
    lastSearchQuery: string;
} => {
    const [search, setSearch] = useState('');
    const [lastSearchQuery, setLastSearchQuery] = useState('');
    const { addNotification } = useNotification();
    const [searchRegionsData, setSearchRegionsData] = useState<SearchRegionsResponse | null>(null);
    const [isTriedToSearch, setIsTriedToSearch] = useState<boolean>(false);

    const searchRegions = useMemo(
        () =>
            debounce(async (searchQuery: string) => {
                try {
                    if (searchQuery) {
                        const result = await fetcher.get(SEARCH_REGIONS_URL, {
                            params: {
                                backUrl: window.location.href,
                                q: searchQuery,
                                enableAnyLocation: true,
                                ignoreRussia: true,
                            },
                        });

                        setLastSearchQuery(searchQuery);
                        setSearchRegionsData(result);
                    } else {
                        setSearchRegionsData(null);
                    }
                } catch (error) {
                    defaultRequestErrorHandler(error, addNotification);
                }
            }, FETCH_DEBOUNCE_MS),
        [addNotification]
    );

    useEffect(() => {
        if (isTriedToSearch) {
            headerGeoDefinitionAreaFieldTouch();
        }
    }, [isTriedToSearch]);

    const handleSearchChange = useCallback(
        (value: string) => {
            setIsTriedToSearch(true);
            setSearch(value);

            searchRegions(value);
        },
        [searchRegions]
    );

    return { handleSearchChange, search, searchRegionsData, lastSearchQuery };
};

export default useSearchRegions;
