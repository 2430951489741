import { FC } from 'react';

import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';

const MainContent: FC<MenuItemRendererProps> = ({ menuItem, renderer, showOverlay }) => {
    if (menuItem.subItems === undefined) {
        return null;
    }

    return (
        <div className="supernova-navi supernova-navi_lvl-1 supernova-navi_main-content">
            {menuItem.subItems.map((menuItem) => renderer(menuItem, showOverlay))}
        </div>
    );
};

export default MainContent;
