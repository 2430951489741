import { useMemo, HTMLProps } from 'react';

import CompositeSelection from 'bloko/blocks/compositeSelection';
import ConversionNumber from 'bloko/blocks/conversion';
import { BarsScaleSmall, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    title: 'fied.header.title.mobile',
    anyIndustry: 'resumesSearch.industry.any',
    oneIndustry: 'resumesSearch.industry.one',
    someIndustry: 'resumesSearch.industry.some',
    manyIndustry: 'resumesSearch.industry.many',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
};

interface IndustrySelectProps {
    value: string[];
    setValue: (value: string[]) => void;
    isNaviSearch?: boolean;
    isHidden?: boolean;
}

const SpanWrapper = (props: HTMLProps<HTMLSpanElement>) => <span {...props}>{props.children}</span>;

const IndustrySelect: TranslatedComponent<IndustrySelectProps> = ({
    trls,
    value,
    setValue,
    isNaviSearch,
    isHidden,
}) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    const defaultValues = useSelector((state) => state.searchQueries?.default[CriteriaKey.ExpIndustry]) || [];
    const selectedValues = value.toString() === defaultValues.toString() ? [] : value;

    const selectedValuesTrl = useMemo(() => {
        if (selectedValues.length === 0) {
            return trls[TrlKeys.anyIndustry];
        }
        const formatValue = { '{0}': selectedValues.length };
        return (
            <ConversionNumber
                one={format(trls[TrlKeys.oneIndustry], formatValue)}
                some={format(trls[TrlKeys.someIndustry], formatValue)}
                many={format(trls[TrlKeys.manyIndustry], formatValue)}
                value={selectedValues.length}
                hasValue={false}
            />
        );
    }, [selectedValues.length, trls]);

    return (
        <>
            <input
                type="hidden"
                name={CriteriaKey.ExpIndustry}
                value={selectedValues.length > 0 ? selectedValues.join(',') : defaultValues.join(',')}
            />
            {!isHidden && (
                <CompositeSelection
                    collection={industryTreeTreeCollection}
                    value={selectedValues}
                    onChange={(values) => setValue(values.length === 0 ? defaultValues : values)}
                    title={trls[TrlKeys.title]}
                    trl={{
                        submit: trls[TrlKeys.save],
                        cancel: trls[TrlKeys.cancel],
                        searchPlaceholder: trls[TrlKeys.placeholder],
                        notFound: trls[TrlKeys.notFound],
                    }}
                >
                    {({ showTreeSelectorPopup }) => (
                        <div
                            className="condition-select-industry"
                            data-qa={`resumes-search-wizard-${CriteriaKey.ExpIndustry}-switcher`}
                        >
                            <Link
                                kind={LinkKind.Tertiary}
                                appearance={isNaviSearch ? undefined : LinkAppearance.Pseudo}
                                Element={SpanWrapper}
                                onClick={industryTree.length ? showTreeSelectorPopup : () => null}
                                icon={
                                    industryTree.length ? (
                                        <BarsScaleSmall initial={IconColor.Gray50} />
                                    ) : (
                                        <Loading scale={LoadingScale.Small} />
                                    )
                                }
                            >
                                {selectedValuesTrl}
                            </Link>
                        </div>
                    )}
                </CompositeSelection>
            )}
        </>
    );
};

export default translation(IndustrySelect);
