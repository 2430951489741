import formatDate from 'date-fns/format';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    content: 'negotiation.interview',
    inTime: 'negotiation.interview.inTime',
    link: 'negotiation.interview.in',
};

const NegotiationInterview: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification iconKind={SupernovaUserNotificationsIconKind.Calendar} {...notification}>
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.content], {
                '{0}': (
                    <>
                        {notification.params.closestDay}
                        {notification.params.scheduledDate} {trls[TrlKeys.inTime]}
                        {NON_BREAKING_SPACE}
                        {formatDate(new Date(notification.params.scheduledTimeLocal), 'HH:mm')}
                    </>
                ),
                '{1}': (
                    <NotificationLink to={`/negotiations/gotochat?topicId=${notification.params.topic_id}`}>
                        {trls[TrlKeys.link]}
                        {NON_BREAKING_SPACE}
                        {notification.params.companyName}
                    </NotificationLink>
                ),
            })}
        </NotificationSpacer>
    </Notification>
);

export default translation(NegotiationInterview);
