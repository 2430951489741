import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './style.less';

const TrlKeys = {
    title: 'employer.candidates.autoinvite.onboardingModal.title',
    subtitle: 'employer.candidates.autoinvite.onboardingModal.subtitle',
    button: {
        ok: 'employer.candidates.autoinvite.onboardingModal.button.ok',
    },
};

interface AutoInviteOnboardingModalProps {
    modalVisibility: boolean;
    hideModal: () => void;
}

const AutoInviteOnboardingModal: TranslatedComponent<AutoInviteOnboardingModalProps> = ({
    trls,
    modalVisibility,
    hideModal,
}) => {
    const { isMobile } = useBreakpoint();

    const content = <div className={styles.image}></div>;
    const closeAction = <Action mode="secondary" onClick={hideModal} icon={CrossOutlinedSize24} />;
    const buttons = (
        <Button mode="primary" style="accent" onClick={hideModal}>
            {trls[TrlKeys.button.ok]}
        </Button>
    );

    if (isMobile) {
        return (
            <BottomSheet
                visible={modalVisibility}
                onClose={hideModal}
                header={
                    <NavigationBar
                        size="large"
                        title={trls[TrlKeys.title]}
                        subtitle={trls[TrlKeys.subtitle]}
                        right={closeAction}
                    />
                }
                footer={<BottomSheetFooter>{buttons}</BottomSheetFooter>}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <Modal
            visible={modalVisibility}
            onClose={hideModal}
            actions={closeAction}
            title={trls[TrlKeys.title]}
            titleSize="medium"
            titleDescription={trls[TrlKeys.subtitle]}
            titleDescriptionStyle="secondary"
            footer={<ActionBar type="modal" primaryActions={buttons}></ActionBar>}
        >
            {content}
        </Modal>
    );
};

export default translation(AutoInviteOnboardingModal);
