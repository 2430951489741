import { useCallback, useMemo } from 'react';

import { Alert, AlertProps, Button } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    alertSuccessTitle: 'search.vacancy.savedSearch.alert.success.title',
    alertSuccessDesc: 'search.vacancy.savedSearch.alert.success.desc',
    alertErrorTitle: 'search.vacancy.savedSearch.alert.error.title',
    alertErrorDesc: 'search.vacancy.savedSearch.alert.error.desc',
    actionGoToAutosearches: 'search.vacancy.savedSearch.action.goToAutosearches',
    actionClose: 'search.vacancy.savedSearch.action.close',
};

const URL_AUTOSEARCHES = '/applicant/autosearch';

interface Props {
    visible: boolean;
    onClose: () => void;
    email?: string;
    hasError?: boolean;
}

const SubscriptionAlert: TranslatedComponent<Props> = ({ visible, onClose, email = '', hasError, trls }) => {
    const push = usePush();

    const props: Pick<AlertProps, 'title' | 'description' | 'icon' | 'iconStyle'> = useMemo(() => {
        if (hasError) {
            return {
                title: trls[TrlKeys.alertErrorTitle],
                description: trls[TrlKeys.alertErrorDesc],
                icon: <MinusCircleFilledSize24 />,
                iconStyle: 'negative',
            };
        }

        return {
            title: trls[TrlKeys.alertSuccessTitle],
            description: formatToReactComponent(trls[TrlKeys.alertSuccessDesc], {
                '{email}': email,
            }),
            icon: <CheckCircleFilledSize24 />,
            iconStyle: 'positive',
        };
    }, [email, hasError, trls]);

    const onRedirect = useCallback(() => {
        push(URL_AUTOSEARCHES);
    }, [push]);

    return (
        <Alert
            {...props}
            layout="vertical"
            visible={visible}
            onClose={onClose}
            buttons={
                <>
                    {!hasError && (
                        <Button onClick={onRedirect} style="accent" mode="primary" stretched>
                            {trls[TrlKeys.actionGoToAutosearches]}
                        </Button>
                    )}
                    <Button onClick={onClose} style="accent" mode="tertiary" stretched>
                        {trls[TrlKeys.actionClose]}
                    </Button>
                </>
            }
        />
    );
};

export default translation(SubscriptionAlert);
