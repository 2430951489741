import { useCallback, useEffect, useState } from 'react';

import fetcher from 'src/utils/fetcher';

const ACTIVATION_COUNT_API_PATH = '/shards/employer/carts/activation_count';

declare global {
    interface FetcherGetApi {
        [ACTIVATION_COUNT_API_PATH]: {
            queryParams: void;
            response?: {
                activationCount?: number;
            };
        };
    }
}

const useEmployerActivationCartsCount = (): { isLoading: boolean; activationCount: number | undefined } => {
    const [activationCount, setActivationCount] = useState<number>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await fetcher.get(ACTIVATION_COUNT_API_PATH);
            setActivationCount(data?.activationCount);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        void fetchData();
    }, [fetchData]);

    return { activationCount, isLoading };
};

export default useEmployerActivationCartsCount;
