import { Dispatch, Fragment, SetStateAction, useCallback, useRef } from 'react';

import { BottomSheet, BottomSheetFooter, Button, DateTimeInput, Drop } from '@hh.ru/magritte-ui';
import { CalendarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DatePicker from 'src/components/InterviewReminder/DatePicker';
import { formatDate } from 'src/components/InterviewReminder/utils/formatDate';
import translation from 'src/components/translation';

const TrlKeys = {
    date: 'resume.interview.schedule.date.title.alt',
    apply: 'resume.interview.schedule.button.apply',
};

interface DateInputProps {
    isMobile: boolean;
    timeError: boolean;
    selectedDate: string;
    isDatepickerVisible: boolean;
    setSelectedDate: Dispatch<SetStateAction<string>>;
    setIsDatepickerVisible: Dispatch<SetStateAction<boolean>>;
}

const DateInput: TranslatedComponent<DateInputProps> = ({
    trls,
    isMobile,
    timeError,
    selectedDate,
    setSelectedDate,
    isDatepickerVisible,
    setIsDatepickerVisible,
}) => {
    const dateInputRef = useRef<HTMLDivElement>(null);
    const dateRef = useRef<string>(selectedDate);

    const inputProps = {
        buttonIcon: CalendarOutlinedSize24,
        onButtonClick: () => {
            setIsDatepickerVisible((prevState) => !prevState);
        },
    };

    const onDatepickerConfirm = useCallback(() => {
        setSelectedDate(dateRef.current);
        setIsDatepickerVisible(false);
    }, [setIsDatepickerVisible, setSelectedDate]);

    const ButtonWrapper = isMobile ? BottomSheetFooter : Fragment;
    const datepickerConfirmButton = (
        <ButtonWrapper>
            <Button mode="primary" style="accent" onClick={onDatepickerConfirm}>
                {trls[TrlKeys.apply]}
            </Button>
        </ButtonWrapper>
    );

    const datepicker = <DatePicker selectedDate={selectedDate} dateRef={dateRef} />;

    return (
        <>
            <DateTimeInput
                wrapperRef={dateInputRef}
                dateMask="dd.mm.yyyy"
                elevatePlaceholder
                placeholder={trls[TrlKeys.date]}
                size="large"
                invalid={timeError}
                onChange={(value) => {
                    setSelectedDate(formatDate(value, 'hyphen'));
                }}
                value={formatDate(selectedDate, 'dots')}
                {...inputProps}
            />
            <Drop
                onClose={() => setIsDatepickerVisible(false)}
                activatorRef={dateInputRef}
                placement="bottom-right"
                visible={!isMobile && isDatepickerVisible}
                maxWidth={415}
                space={300}
                footer={datepickerConfirmButton}
            >
                {datepicker}
            </Drop>
            <BottomSheet
                onClose={() => setIsDatepickerVisible(false)}
                visible={isMobile && isDatepickerVisible}
                footer={datepickerConfirmButton}
                withContentPaddings={false}
            >
                {datepicker}
            </BottomSheet>
        </>
    );
};

export default translation(DateInput);
