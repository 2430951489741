import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { VacancyCompensation } from 'src/models/employerVacancies/vacancy.types';

export interface CanvacSimilarVacancy {
    id: number;
    name: string;
    compensation: Partial<VacancyCompensation>;
    company: string;
    area: string;
}

export const setCanvacSimilarVacancies = makeSetStoreField('canvacSimilarVacancies');

export default autoGeneratedReducer<CanvacSimilarVacancy[]>([]);
