import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';

export interface TrackElementShownProps {
    trackElementShown?: boolean;
}

const useTrackElementShown = (
    elementRef: React.RefObject<HTMLElement>,
    name: string,
    trackElementShown?: boolean
): void => {
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (elementRef.current !== null && trackElementShown) {
            const { stopSpying } = Analytics.sendHHEventElementShown(elementRef.current, { name: `menu_item_${name}` });

            return () => {
                stopSpying();
            };
        }
    }, [elementRef, name, trackElementShown]);
};

export default useTrackElementShown;
