import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AuthEmployerInitialData {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneFull?: string;
    usePhoneFull?: boolean;
    employerCategory?: string;
    areaId?: string[];
}

export default autoGeneratedReducer<AuthEmployerInitialData>({});
