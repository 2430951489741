import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'supernova.notification.employer.change.email.by.manager.content',
    link: 'supernova.notification.employer.change.email.by.manager.link',
};

const EmployerChangeEmailByManager: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    return (
        <Notification {...notification}>
            <NotificationSpacer>
                {formatToReactComponent(trls[TrlKeys.content], {
                    '{0}': (
                        <Text Element="span" strong>
                            {notification.params.employerManagerName}
                        </Text>
                    ),
                })}
            </NotificationSpacer>
            <NotificationLink
                to={`/employer/settings?employerManagerId=${notification.params.employer_manager_id}`}
                target="_blank"
            >
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(EmployerChangeEmailByManager);
