import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerImplants {
    extraAccounts: {
        disabled: boolean;
        employer: {
            id: number;
            name: string;
        };
        hhid: number;
        userId: number;
    }[];
}

export default autoGeneratedReducer<EmployerImplants>(null);
