import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    clear: 'Clear',
};

const ClearButton: TranslatedComponent<{ onClick: () => void }> = ({ trls, onClick }) => (
    <>
        <VSpacing base={4} />
        <Button
            appearance={ButtonAppearance.Outlined}
            kind={ButtonKind.Inversed}
            onClick={onClick}
            scale={ButtonScale.Small}
        >
            {trls[TrlKeys.clear]}
        </Button>
        <VSpacing base={1} />
    </>
);

export default translation(ClearButton);
