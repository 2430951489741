import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum MessengerType {
    Telegram = 'TELEGRAM',
    Viber = 'VIBER',
    Whatsapp = 'WHATSAPP',
}

export interface Messenger {
    accountName: string;
    city: string;
    country: string;
    messengerType: MessengerType;
    number: string;
}

export default autoGeneratedReducer<Messenger[]>([]);
