import { ComponentType, Fragment } from 'react';

import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import { NotificationLinkProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoQuotasFailure]: {
        content: 'vacancy.scheduled.publication.no.quotas.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPermissionFailure]: {
        content: 'vacancy.scheduled.publication.no.permission.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPublicationFailure]: {
        content: 'vacancy.scheduled.publication.no.publication.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationValidationFailure]: {
        content: 'vacancy.scheduled.publication.validation.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationUnknownFailure]: {
        content: 'vacancy.scheduled.publication.unknown.failure',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses]: {
        content: 'vacancy.scheduled.publication.successes',
    },
    [ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess]: {
        content: 'vacancy.scheduled.publication.success',
    },
    vacancySome: 'vacancy.some',
    vacancyMany: 'vacancy.many',
};

type TemplateKeys = Exclude<keyof typeof TrlKeys, 'vacancySome' | 'vacancyMany'>;

interface VacancyScheduledPublicationProps {
    Component: ComponentType<NotificationLinkProps>;
}

const VacancyScheduledPublicationNotification: TranslatedComponent<
    ComplexUserNotification & VacancyScheduledPublicationProps
> = ({ Component, trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;

    if (key === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccess) {
        return formatToReactComponent(trls[TrlKeys[key].content], {
            '{0}': (
                <Component to={`/vacancy/${notification.params.vacancy_id}`} target="_blank">
                    «{notification.params.name}»
                </Component>
            ),
        });
    } else if (key === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationSuccesses) {
        return (
            <Fragment>
                <Component to="/employer/vacancies" target="_blank">
                    <ConversionNumber
                        formatValue={(value) => NumberFormatter.format(String(value))}
                        value={parseInt(notification.params.count, 10)}
                        one={trls[TrlKeys.vacancySome]}
                        some={trls[TrlKeys.vacancyMany]}
                        many={trls[TrlKeys.vacancyMany]}
                        zero={trls[TrlKeys.vacancyMany]}
                    />
                </Component>
                {trls[TrlKeys[key].content]}
            </Fragment>
        );
    }

    const typeTrl = notification.params.typeTrl;

    return (
        <Fragment>
            {formatToReactComponent(trls[TrlKeys[key].content], {
                '{0}': (
                    <Component
                        to={`/employer/vacancy/create?draftId=${notification.params.draft_id}&hidden=true`}
                        target="_blank"
                    >
                        «{notification.params.name}»
                    </Component>
                ),
            })}

            {key === ComplexUserNotificationsTemplateKey.VacancyScheduledPublicationNoPublicationFailure && typeTrl}
        </Fragment>
    );
};

export default translation(VacancyScheduledPublicationNotification);
