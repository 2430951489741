import { FC } from 'react';
import classnames from 'classnames';

import { ArrowScaleSmallKindUp, IconColor } from 'bloko/blocks/icon';

import styles from './table-flexible.less';

export enum SortIcons {
    Up = 'Up',
    Down = 'Down',
}

interface TableFlexibleSortingProps {
    direction: SortIcons;
    color?: IconColor;
}

const TableFlexibleSorting: FC<TableFlexibleSortingProps> = ({ direction, color }) => {
    return (
        <span
            className={classnames(styles.tableFlexibleSortIcon, {
                [styles.tableFlexibleSortIconDown]: direction === SortIcons.Down,
            })}
        >
            <ArrowScaleSmallKindUp initial={color} />
        </span>
    );
};

export default TableFlexibleSorting;
