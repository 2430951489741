import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Range } from 'src/types/range';

export enum SuccessIds {
    E = 'e',
    A = 'a',
    EA = 'ea',
    VUS = 'vus',
    VUSP = 'vusp',
    VUP = 'vup',
    APE = 'ape',
    APC = 'apc',
    VOE = 'voe',
    VOD = 'vod',
}

type ErrorRange = Range<1, 30>;
type ErrorKey = `EE${ErrorRange}`;
type ErrorValue = `ee${ErrorRange}`;
type ErrorArgument = Record<ErrorKey, ErrorValue>;
const errorArgument = new Array(29)
    .fill(1)
    .map((_, index) => `ee${index + 1}`)
    .reduce((result, item) => {
        result[item.toUpperCase() as ErrorKey] = item as ErrorValue;
        return result;
    }, {} as ErrorArgument);

export const ErrorIds = {
    D: 'd',
    F: 'f',
    ERR: 'err',
    NA: 'na',
    EVU: 'evu',
    ...errorArgument,
} as const;

export enum VacancyMessageTypes {
    Error = 'error',
    Success = 'success',
}

interface VacancyMessage {
    id: SuccessIds & ErrorValue;
    type: VacancyMessageTypes;
    vacancyIds: string[];
}

export default autoGeneratedReducer<VacancyMessage[]>([]);
