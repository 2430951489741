import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import CommonNotificationComponent from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';
import translation from 'src/components/translation';

const TrlKeys = {
    notificationSuccessText: 'search.vacancy.savedSearch.notification.success.text',
    errorText: 'search.vacancy.savedSearch.notification.error.text',
    actionGoToAutosearches: 'search.vacancy.savedSearch.action.goToAutosearches',
};

const URL_AUTOSEARCHES = '/applicant/autosearch';

interface Props extends NotificationProps {
    email: string;
    isError?: boolean;
    alreadySaved?: boolean;
}

const Notification: TranslatedComponent<Props> = ({ email, isError, alreadySaved, trls, ...props }) => {
    const push = usePush();

    let actionLabel;
    if (!isError) {
        actionLabel = trls[TrlKeys.actionGoToAutosearches];
    }

    const addon = isError ? (
        <MinusCircleFilledSize24 initial="negative" />
    ) : (
        <CheckCircleFilledSize24 initial="positive" />
    );

    return (
        <CommonNotificationComponent
            addon={addon}
            actionLabel={actionLabel}
            onAction={() => {
                push(URL_AUTOSEARCHES);
            }}
            autohideTime={3000}
            data-qa="autosearch-saved-notification"
            {...props}
        >
            {isError
                ? trls[TrlKeys.errorText]
                : formatToReactComponent(trls[TrlKeys.notificationSuccessText], {
                      '{email}': email,
                  })}
        </CommonNotificationComponent>
    );
};

export default translation(Notification);
