import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'resume_merge.notification.success',
};

const ResumeMergeSuccess: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    templateKey,
    ...notification
}) => (
    <SynchronizedNotification
        analyticsElementName="merge_resume_success"
        kind={NotificationKind.Ok}
        onClose={onClose}
        notification={{ templateKey, ...notification }}
        autoClose={false}
    >
        {trls[TrlKeys.title]}
    </SynchronizedNotification>
);

export default translation(ResumeMergeSuccess);
