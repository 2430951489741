import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import Widget from 'src/models/employerConstructor/widget.types';

export enum PictureType {
    GalleryWidget = 'GALLERY_WIDGET',
    PictureWidget = 'PICTURE_WIDGET',
    EmployerPageBackground = 'EMPLOYER_PAGE_BACKGROUND',
}

export interface PictureSettings {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    maxSizeBytes: number;
    widthHeightRatio: number;
    allowedMimeTypes: string;
}

export interface WidgetSettings {
    maxCounts?: Record<Widget, number | undefined>;
    textMaxLength: number;
    videoUrlMaxLength: number;
    pictureTypeByWidgetType: Record<Widget, PictureType>;
    galleryMaxItemsCount: number;
}

export interface EmployerConstructorSettings {
    pictureSettings: Partial<Record<PictureType, PictureSettings>>;
    widgetSettings?: WidgetSettings;
}

export default autoGeneratedReducer<EmployerConstructorSettings>({ pictureSettings: {} });
