import { useEffect, useState, useRef, MutableRefObject } from 'react';

import failedCloseButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/failed_web_call_close_button_click';
import micAlertShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_element_shown';

import { useNotification } from 'src/components/Notifications/Provider';
import { webcallFailedToCall, type WebcallFailedToCallProps } from 'src/components/Notifications/WebCall';

import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';

type HookProps = {
    closeFn: () => void;
    widgetId: string;
    topicId?: string;
    resumeId: number;
    source: HhtmSource;
};

const MIC_DISABLED_ERROR = 'Media access denied';
const RESUME_DATA_ERROR = 'resume_load_failure';

type HookResult = {
    isMicPermissionError: boolean;
    retryMicPermission: VoidFunction;
    alertRef: MutableRefObject<null>;
    isUserDataError: boolean;
    resetUserDataError: VoidFunction;
};

export default function ({ closeFn, widgetId, topicId, resumeId, source }: HookProps): HookResult {
    const { addNotification } = useNotification();
    const [isMicPermissionError, setIsMicPermissionError] = useState(false);
    const [isUserDataError, setIsUserDataError] = useState(false);
    const alertRef = useRef(null);

    useEffect(() => {
        const handleClose = (evt: CustomEvent<{ error?: string; widgetId?: string; webCallSessionId?: string }>) => {
            let shouldClose = true;
            if (evt.detail.error && evt.detail.widgetId === widgetId) {
                if (evt.detail.error === MIC_DISABLED_ERROR) {
                    shouldClose = false;
                    setIsMicPermissionError(true);
                    if (alertRef.current !== null) {
                        micAlertShown(alertRef.current as HTMLDivElement, {
                            hhtmSource: source,
                            resumeId,
                            topicId,
                        });
                    }
                } else if (evt.detail.error === RESUME_DATA_ERROR) {
                    shouldClose = false;
                    setIsUserDataError(true);
                } else {
                    addNotification(webcallFailedToCall, {
                        props: {
                            onClose: () => {
                                failedCloseButtonClick({
                                    hhtmSource: source,
                                    resumeId,
                                    topicId,
                                    webCallSessionId: evt.detail.webCallSessionId,
                                });
                            },
                            hhtmSource: source,
                            topicId,
                            resumeId,
                            webCallSessionId: evt.detail.webCallSessionId,
                            error: evt.detail.error,
                        } as WebcallFailedToCallProps,
                    });
                }
            }

            if (shouldClose) {
                closeFn();
            }
        };

        window.addEventListener(WEBCALL_CUSTOM_EVENTS.CLOSE, handleClose as EventListener);
        return () => window.removeEventListener(WEBCALL_CUSTOM_EVENTS.CLOSE, handleClose as EventListener);
    }, [closeFn, addNotification, setIsMicPermissionError, resumeId, source, topicId, widgetId]);

    return {
        isMicPermissionError,
        retryMicPermission: () => {
            setIsMicPermissionError(false);
        },
        isUserDataError,
        resetUserDataError: () => {
            setIsUserDataError(false);
        },
        alertRef,
    };
}
