import { keys } from 'src/models/applicant/resume/lib/common/object';
import { toUpperSnake } from 'src/models/applicant/resume/lib/common/string';

import { Actions, ReducersMap, Slice, SliceArg } from 'src/models/applicant/resume/lib/slice/types';

/**
 * Аналог createSlice из redux toolkit
 */
export const createSlice = <IS, PT>({ initialState, reducers }: SliceArg<IS, PT>): Slice<IS, PT> => {
    const actions = keys(reducers).reduce(
        (actions, key) => ({
            ...actions,
            [key]: <Key extends keyof PT>(payload: PT[Key]) => ({ type: toUpperSnake(key as string), payload }),
        }),
        {} as Actions<PT>
    );
    const reducerMap = keys(reducers).reduce(
        (reducer, key) => ({
            ...reducer,
            [toUpperSnake(key as string)]: reducers[key],
        }),
        {} as ReducersMap<IS, PT>
    );

    return {
        actions,
        reducer: {
            reducerMap,
            initialState,
        },
    };
};
