import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const REVOKE_ASSESSMENT = 'assessments/revoke';

export enum State {
    Assigned = 'ASSIGNED',
    New = 'NEW',
    Completed = 'COMPLETED',
    WaitingForResult = 'WAITING_FOR_RESULTS',
    Revoked = 'REVOKED',
    Deadline = 'DEADLINE',
    Expired = 'EXPIRED',
    Rejected = 'REJECTED',
    Started = 'STARTED',
}

export enum AssessmentCode {
    SkillazVideoInterview = 'SKILLAZ_VIDEO_INTERVIEW',
}

export interface Assessment {
    code: AssessmentCode;
    state: State;
    assessmentId: number;
    creationTime: number;
}

interface PayloadTypes {
    [REVOKE_ASSESSMENT]: { id: number };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const revoke = actionCreator(REVOKE_ASSESSMENT);

export const INITIAL_STATE = {
    assessments: [],
};

export default createReducer<{ assessments: Assessment[] }, PayloadTypes>(INITIAL_STATE, {
    [REVOKE_ASSESSMENT]: (state, action) => {
        return {
            ...state,
            assessments: state.assessments.map((assessment) =>
                assessment.assessmentId === action.payload.id
                    ? {
                          ...assessment,
                          revoke: true,
                          state: State.Revoked,
                      }
                    : assessment
            ),
        };
    },
});
