import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { CurrencyType } from 'src/models/currencies.types';
import { AllowedCriteria } from 'src/models/employerVacancyTemplateFilter';
import {
    CriteriaKey,
    ExperienceOption,
    OrderByOption,
    SearchFieldOption,
} from 'src/models/search/searchCriteria.types';

export enum LabelOption {
    AcceptHandicapped = 'accept_handicapped',
    AcceptKids = 'accept_kids',
    NotFromAgency = 'not_from_agency',
    WithAddress = 'with_address',
    AccreditedIT = 'accredited_it',
    LowPerformance = 'low_performance',
    Internship = 'internship',
}

export enum SearchPeriodOption {
    AllTime = '0',
    Month = '30',
    Week = '7',
    ThreeDays = '3',
    Day = '1',
}

export enum ItemsOnPageOption {
    Twenty = '20',
    Fifty = '50',
    Hundred = '100',
}

export enum EducationOption {
    Higher = 'higher',
    NotRequiredNotSpecified = 'not_required_or_not_specified',
    SpecialSecondary = 'special_secondary',
}

export enum GeocodeType {
    Quadkey = 'QUADKEY',
}

export interface Criteria {
    [CriteriaKey.Area]: number[];
    [CriteriaKey.Clusters]: boolean;
    [CriteriaKey.CurrencyCode]: CurrencyType;
    [CriteriaKey.District]: number[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.EmployerId]: number[];
    [CriteriaKey.Experience]: ExperienceOption;
    [CriteriaKey.ItemsOnPage]: number;
    [CriteriaKey.Label]: LabelOption;
    [CriteriaKey.NoMagic]: boolean;
    [CriteriaKey.OnlyWithSalary]: boolean;
    [CriteriaKey.OrderBy]: OrderByOption;
    [CriteriaKey.Page]: number;
    [CriteriaKey.Resume]: string;
    [CriteriaKey.Salary]: number;
    [CriteriaKey.Schedule]: ScheduleCamelCase;
    [CriteriaKey.SearchDebug]: boolean;
    [CriteriaKey.SearchField]: SearchFieldOption[];
    [CriteriaKey.SearchPeriod]: number;
    [CriteriaKey.SearchSessionId]: string;
    [CriteriaKey.Text]: string;
}

export interface MapCriteria extends Criteria {
    [CriteriaKey.Height]: number;
    [CriteriaKey.Width]: number;
    [CriteriaKey.Precision]: number;
    [CriteriaKey.TopRightLat]: number;
    [CriteriaKey.TopRightLng]: number;
    [CriteriaKey.BottomLeftLat]: number;
    [CriteriaKey.BottomLeftLng]: number;
    [CriteriaKey.GeocodeType]: GeocodeType;
}

export interface EmployerCriteria extends Criteria {
    [CriteriaKey.CurrentEmployerId]: number;
    [CriteriaKey.Department]: string;
    [CriteriaKey.Metro]: string[];
    [CriteriaKey.ProfessionalRole]: number[];
    [CriteriaKey.HiddenFilters]: AllowedCriteria[];
}
