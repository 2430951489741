export const COUNTER_OVERLIMIT = 100;

export enum AdviceType {
    McpManagersUnreadResponses = 'MCP_MANAGERS_UNREAD_RESPONSES',
    ManagerUnreadResponses = 'MANAGER_UNREAD_RESPONSES',
    // tempexp_30797_nextline
    MakeSalaryCloserToMarket = 'MAKE_SALARY_CLOSER_TO_MARKET',
}

export enum AdviceDefaultType {
    Tele2CallTrackingTip = 'TELE2_CALL_TRACKING_TIP',
    VacanciesActiveAdviceDefault = 'VACANCIES_ACTIVE_ADVICE_DEFAULT',
    VacanciesDraftsAdviceDefault = 'VACANCIES_DRAFTS_ADVICE_DEFAULT',
    VacanciesArchivedAdviceDefault = 'VACANCIES_ARCHIVED_ADVICE_DEFAULT',
    VacanciesTemplatesAdviceDefault = 'VACANCIES_TEMPLATES_ADVICE_DEFAULT',
    VacanciesUnpremoderatedAdviceDefault = 'VACANCIES_UNPREMODERATED_ADVICE_DEFAULT',
    VacanciesVrAdviceDefault = 'VACANCIES_VR_ADVICE_DEFAULT',
}

export type AdviceTypes = AdviceType | AdviceDefaultType;

export type AdviceContentType = string | JSX.Element;

export const ADVICE_TYPES = Object.values(AdviceType);
