import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SubscriptionField {
    channel: string;
    isDisabled: boolean;
    label: string;
    formLabel: string;
    name: string;
    type: string;
}

interface ApplicantSubscriptions {
    formItems: { [channel: string]: { subscription: SubscriptionField[] } };
    initialValues: { [fieldName: string]: string[] };
    email: string | null;
    emailVerified: boolean | null;
}

export default autoGeneratedReducer<ApplicantSubscriptions>({
    formItems: {},
    initialValues: {},
    email: null,
    emailVerified: null,
});
