import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import { useSelector } from 'src/hooks/useSelector';

export default function useIsMenuHidden(): boolean {
    const query = useSelector(({ router }) => router.location.query);
    const isWebView = useSelector(({ isWebView }) => isWebView);
    const hideLayout = useSelector(({ hideLayout }) => hideLayout);
    const isIFrameView = useSelector(({ isIFrame }) => isIFrame);
    const isNoMenu = useSelector(({ noMenu }) => noMenu);
    const { isHideHeader } = useApplicantRenewedAuthExp();

    return hideLayout || isNoMenu || isWebView || query?.print !== undefined || isIFrameView || isHideHeader;
}
