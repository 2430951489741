import { useCallback } from 'react';

import { useChatik } from '@hh.ru/chatik-integration';
import Cookies from 'bloko/common/Cookies';

import { ANON_SOCKET_COOKIE_NAME, EXPIRATION_TIME_IN_HOURS } from 'src/components/ChatikIntegration/constants';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

interface UseSupportChatikType {
    dataQa: string;
    handleChatikClick: () => void;
    unreadCount: number | null;
}

interface UseSupportChatikFunction {
    (fromLabel?: string, onClick?: () => void): UseSupportChatikType;
}

const setAnonCookie = () => {
    Cookies.set(ANON_SOCKET_COOKIE_NAME, '1', EXPIRATION_TIME_IN_HOURS);
};

const useSupportChatik: UseSupportChatikFunction = (fromLabel, onClick) => {
    const { openChatik } = useChatik();
    const unreadCount = useSelector(({ chatikCounters }) => chatikCounters.unreadSupportCount);
    const userType = useSelector(({ userType }) => userType);
    const dataQa = 'support-chat-button';

    const handleChatikClick = useCallback(() => {
        if (userType === UserType.Anonymous) {
            setAnonCookie();
        }

        openChatik({ chatId: 'support', view: 'widget', hhtmFromLabel: fromLabel });
        onClick?.();
    }, [openChatik, fromLabel, onClick, userType]);

    return { dataQa, handleChatikClick, unreadCount };
};

export default useSupportChatik;
