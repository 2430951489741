import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'vacancy.call.tracking.tele2owners.warning.notification',
    link: 'vacancy.call.tracking.tele2owners.warning.link',
};

const CallTracking: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification {...notification}>
        <NotificationSpacer>
            <NotificationSpacer>{trls[TrlKeys.content]}</NotificationSpacer>
            <NotificationLink to="/article/28992">{trls[TrlKeys.link]}</NotificationLink>
        </NotificationSpacer>
    </Notification>
);

export default translation(CallTracking);
