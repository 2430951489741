import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { LoyaltySurveyType, runLoyaltySurvey } from 'src/models/loyaltySurvey';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    [LoyaltySurveyType.Adv]: {
        header: 'notification.employer.loyaltySurvey.ADV.header',
        content: 'notification.employer.loyaltySurvey.ADV.content',
    },
    [LoyaltySurveyType.Branding]: {
        header: 'notification.employer.loyaltySurvey.BRANDING.header',
        content: 'notification.employer.loyaltySurvey.BRANDING.content',
    },
    [LoyaltySurveyType.Clickme]: {
        header: 'notification.employer.loyaltySurvey.CLICKME.header',
        content: 'notification.employer.loyaltySurvey.CLICKME.content',
    },
    [LoyaltySurveyType.MainServices]: {
        header: 'notification.employer.loyaltySurvey.MAIN_SERVICES.header.personal.manager',
        content: 'notification.employer.loyaltySurvey.MAIN_SERVICES.content',
    },
    link: 'notification.employer.loyaltySurvey.button',
};

const EmployerLoyaltySurvey: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const { type, showNotification } = useSelector(({ loyaltySurvey }) => loyaltySurvey);
    const manager = useSelector(({ employerManager }) => employerManager);
    const dispatch = useDispatch();

    if (showNotification || !type) {
        return null;
    }

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Question}
            dataQa={DataQaInvariants.Question}
            onRemove={() => {
                Analytics.sendHHEventButtonClick('loyalty_survey_cancel', { from: 'notification' });
            }}
            {...notification}
        >
            <NotificationSpacer>
                {format(trls[TrlKeys[type].header], { '{0}': manager?.firstName || '' })}
            </NotificationSpacer>
            {type !== LoyaltySurveyType.MainServices && (
                <NotificationSpacer>{trls[TrlKeys[type].content]}</NotificationSpacer>
            )}
            <BlokoLink
                data-qa="notification-link"
                appearance={LinkAppearance.Pseudo}
                onClick={() => {
                    Analytics.sendHHEventButtonClick('loyalty_survey_start', { from: 'notification' });
                    dispatch(runLoyaltySurvey(true));
                }}
            >
                {trls[TrlKeys.link]}
            </BlokoLink>
        </Notification>
    );
};

export default translation(EmployerLoyaltySurvey);
