import { useCallback, useContext, FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';

import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

const ChatikActivatorMobile: FC<NavItem> = ({ name, translations, ...props }) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const unreadCount = useSelector(({ chatikCounters }) => chatikCounters.unreadCount);

    const handleClick = useCallback(() => {
        openChatik?.({ resizable: false, draggable: false });
        Analytics.sendHHEventButtonClick('menu_item', { item: 'chat_list' });
    }, [openChatik]);

    return (
        <SupernovaDropdownOption data-qa={`mainmenu_${name}-xs`} name={name} onClick={handleClick} {...props}>
            {translations.name}
            {Boolean(unreadCount) && <SupernovaCounter dropdown>{unreadCount || 0}</SupernovaCounter>}
        </SupernovaDropdownOption>
    );
};

export default ChatikActivatorMobile;
