import React, { ComponentType } from 'react';

import OptionCard from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/OptionCard';
import {
    FoundOnHHValue,
    FoundOnHHVariants,
} from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';
import translation from 'src/components/translation';

import styles from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/styles.less';

const TrlKeys = {
    [FoundOnHHVariants.FoundOnHH]: 'jobSearchStatus.postChangeModal.foundOnHH.yes',
    [FoundOnHHVariants.NotFoundOnHH]: 'jobSearchStatus.postChangeModal.foundOnHH.no',
};

type Props = {
    checkedValue: FoundOnHHValue;
    setValue: (value: FoundOnHHValue) => void;
};

const OPTIONS = [{ value: FoundOnHHVariants.FoundOnHH }, { value: FoundOnHHVariants.NotFoundOnHH }];

const FoundOnHhStep: ComponentType<Props> = translation(({ trls, checkedValue, setValue }) => {
    const handleCardChange = (value: string) => {
        setValue(value as FoundOnHHValue);
    };

    return (
        <div className={styles.contentWrapper}>
            {OPTIONS.map((option) => (
                <OptionCard
                    key={option.value}
                    checked={checkedValue === option.value}
                    value={option.value}
                    title={trls[TrlKeys[option.value]]}
                    onChange={handleCardChange}
                />
            ))}
        </div>
    );
});

export default FoundOnHhStep;
