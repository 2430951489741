import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const TOP_PROMO_BANNER_HIDE = 'TOP_PROMO_BANNER_HIDE';

interface TopPromoBanner {
    name: string;
    isShow: boolean;
    isFirstShow: boolean;
    link: string;
    translations: {
        desktop: {
            [key: string]: string;
        };
        mobile: {
            [key: string]: string;
        };
    };
}

interface PayloadTypes {
    [TOP_PROMO_BANNER_HIDE]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const hideTopPromoBanner = actionCreator(TOP_PROMO_BANNER_HIDE);

const INITIAL_STATE = {
    isShow: false,
    isFirstShow: false,
    link: '',
    name: '',
    translations: {
        desktop: {
            description: '',
            link: '',
        },
        mobile: {
            description: '',
            link: '',
        },
    },
};

export default createReducer<TopPromoBanner, PayloadTypes>(INITIAL_STATE, {
    [TOP_PROMO_BANNER_HIDE]: (state) => ({
        ...state,
        isShow: false,
    }),
});
