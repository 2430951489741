import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Topic } from 'src/models/applicantNegotiationTopic.types';
import { Paging } from 'src/models/paging.types';

import { NegotiationsFilterTab } from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';

export interface Negotiations {
    topicList: Topic[];
    total?: number;
}

interface ApplicantNegotiations extends Negotiations {
    paging?: Paging;
    filterInUse: NegotiationsFilterTab;
}

export default autoGeneratedReducer<ApplicantNegotiations>({
    topicList: [],
    filterInUse: NegotiationsFilterTab.All,
});
