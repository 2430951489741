import type { ApplicantRenewedAuthExpState } from 'src/models/applicantRenewedAuthExp';
import { SupernovaUserType } from 'src/models/supernovaUserType';

import { useSelector } from 'src/hooks/useSelector';

interface ApplicantRenewedAuthExp extends ApplicantRenewedAuthExpState {
    isHideHeader: boolean;
    isHideFooter: boolean;
}

export function useApplicantRenewedAuthExp(): ApplicantRenewedAuthExp {
    const applicantRenewedAuthExp = useSelector(({ applicantRenewedAuthExp }) => applicantRenewedAuthExp);
    const isEmployer = useSelector(({ supernovaUserType }) => supernovaUserType === SupernovaUserType.Employer);

    const isApplicantRenewedAuthExp = applicantRenewedAuthExp?.isApplicantRenewedAuthExp && !isEmployer;
    const isApplicantRenewedAuthExpB = applicantRenewedAuthExp?.isApplicantRenewedAuthExpB && !isEmployer;
    const isApplicantRenewedAuthExpC = applicantRenewedAuthExp?.isApplicantRenewedAuthExpC && !isEmployer;
    const isApplicantRenewedAuthExpD = applicantRenewedAuthExp?.isApplicantRenewedAuthExpD && !isEmployer;

    const isHideHeader = isApplicantRenewedAuthExpB || isApplicantRenewedAuthExpC;
    const isHideFooter = isApplicantRenewedAuthExp;

    return {
        isApplicantRenewedAuthExp,
        isApplicantRenewedAuthExpB,
        isApplicantRenewedAuthExpC,
        isApplicantRenewedAuthExpD,
        isHideHeader,
        isHideFooter,
    };
}
