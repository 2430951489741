import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { LoyaltySurveyType, runLoyaltySurvey } from 'src/models/loyaltySurvey';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    [LoyaltySurveyType.Adv]: 'notification.employer.loyaltySurvey.ADV.content.personal.manager',
    [LoyaltySurveyType.Branding]: 'notification.employer.loyaltySurvey.BRANDING.content.personal.manager',
    [LoyaltySurveyType.Clickme]: 'notification.employer.loyaltySurvey.CLICKME.content.personal.manager',
    [LoyaltySurveyType.MainServices]: 'notification.employer.loyaltySurvey.MAIN_SERVICES.content.personal.manager',
    button: 'notification.employer.loyaltySurvey.button',
};

const REMOVE_NOTIFICATION_URL = '/surveys/loyalty/remove_notification';

declare global {
    interface FetcherPostApi {
        [REMOVE_NOTIFICATION_URL]: {
            queryParams: void;
            body: void;
            response: void;
        };
    }
}

const LoyaltySurvey: TranslatedComponent<SimpleNotificationComponentProps> = ({ trls, onClose, ...notification }) => {
    const params = useSelector(({ loyaltySurvey }) => loyaltySurvey);
    const account = useSelector(({ account }) => account);
    const surveyStarted = useRef(false);
    const closeNotificationRef = useRef<VoidFunction>(null);
    const dispatch = useDispatch();

    if (!params.showNotification) {
        return null;
    }

    return (
        <SynchronizedNotification
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={() => {
                if (!surveyStarted.current) {
                    Analytics.sendHHEventButtonClick('loyalty_survey_cancel', { from: 'action_block' });
                }

                fetcher.postFormData(REMOVE_NOTIFICATION_URL).catch(console.error);
            }}
            kind={NotificationKind.Message}
        >
            <div>
                {format(trls[TrlKeys[notification.params.type as LoyaltySurveyType]], {
                    '{0}': account?.firstName || '',
                })}
            </div>
            <NotificationFooter>
                <Button
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('loyalty_survey_start', { from: 'action_block' });
                        surveyStarted.current = true;
                        closeNotificationRef.current?.();
                        dispatch(runLoyaltySurvey(true));
                    }}
                    scale={ButtonScale.Small}
                    data-qa="loyalty-survey-show-popup"
                >
                    {trls[TrlKeys.button]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(LoyaltySurvey);
