import { TypedUseSelectorHook, useSelector as originalUseSelector } from 'react-redux';

import {
    useSelectorNonNullable as originalUseSelectorNonNullable,
    NonNullableRootState,
} from '@hh.ru/redux-create-reducer';

import { AppStore } from 'src/app/store';

export const useSelector: TypedUseSelectorHook<AppStore> = originalUseSelector;
export const useSelectorNonNullable: TypedUseSelectorHook<NonNullableRootState<AppStore>> =
    originalUseSelectorNonNullable;
