import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface StayInTouchApp {
    href: string;
    name: string;
}

export interface StayInTouch {
    apps: StayInTouchApp[];
    mobilePage: string | null;
}

export default autoGeneratedReducer<StayInTouch>({ apps: [], mobilePage: null });
