import useExperimentCheckFunction, {
    useExperimentControlCheckFunction,
    useExperimentGroupCheckFunction,
} from 'src/hooks/useExperimentCheckFunction';

export default function useExperiment(experimentName: string, callHook = true, sendCheck = true): boolean {
    return useExperimentCheckFunction(callHook, sendCheck)(experimentName);
}

export function useExperimentControl(experimentNames: string[], callHook = true, sendCheck = true): boolean {
    return useExperimentControlCheckFunction(callHook, sendCheck)(experimentNames);
}

export function useExperimentGroup(experimentName: string): string {
    return useExperimentGroupCheckFunction()(experimentName);
}
