import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CountryCode } from 'src/models/countryId';
import { VacancyProperties } from 'src/models/vacancyProperties';
import { VacancyEmployerManager } from 'src/models/vacancySearch/vacancySearchItem.types';

export interface VacancyFull {
    address?: {
        '@disabled': boolean;
        '@id': number;
        building: string;
        city: string;
        displayName: string;
    };
    vacancyId: number;
    publicationDate: {
        $: string;
    };
    area: {
        '@id': number;
        '@regionId': number;
        '@countryIsoCode': CountryCode;
        name: string;
        path: string;
    };
    name: string;
    company: {
        id: number;
    };
    vacancyProperties: VacancyProperties;
    keySkills: {
        keySkill: string[];
    };
    employerManager: VacancyEmployerManager;
}

export default autoGeneratedReducer<VacancyFull>(null);
