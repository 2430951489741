import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale, ButtonKind } from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ImportantNotifications, { ImportantNotificationsKind } from 'src/components/ImportantNotifications';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerSuspiciousState } from 'src/models/employerStatus';

import styles from './employer-informer.less';

const TrlKeys = {
    suspiciousContent: 'inform.employer.restricted.1',
    suspiciousContentTwo: 'inform.employer.restricted.2',
    load: 'inform.button.load',
    pending: 'inform.employer.pending.1',
    pendingTwo: 'inform.employer.pending.2',
};

const EmployerInformer: TranslatedComponent = ({ trls }) => {
    const suspiciousState = useSelector(({ employerStatus }) => employerStatus.suspiciousState);
    let kind;

    if (
        suspiciousState === EmployerSuspiciousState.NotSuspicious ||
        suspiciousState === EmployerSuspiciousState.TrustedSuspicious
    ) {
        return null;
    }

    let content;

    switch (suspiciousState) {
        case EmployerSuspiciousState.Suspicious:
            kind = ImportantNotificationsKind.Warning;
            content = (
                <>
                    <Column xs="4" s="6" m="10" l="14">
                        <div className={styles.employerInfo}>
                            <span>
                                {trls[TrlKeys.suspiciousContent]}
                                &#160;
                            </span>
                            <span>{trls[TrlKeys.suspiciousContentTwo]}</span>
                        </div>
                    </Column>
                    <Column xs="4" s="2" m="2" l="2">
                        <div className={styles.employerInfoButton}>
                            <Button
                                Element={Link}
                                to="/employer/documents"
                                scale={ButtonScale.Small}
                                kind={ButtonKind.Inversed}
                            >
                                {trls[TrlKeys.load]}
                            </Button>
                        </div>
                    </Column>
                </>
            );
            break;
        case EmployerSuspiciousState.SuspiciousRemovalPending:
            kind = ImportantNotificationsKind.Ok;
            content = (
                <Column xs="4" s="8" m="12" l="16">
                    <div className={styles.employerInfo}>
                        <span>
                            {trls[TrlKeys.pending]}
                            &#160;
                        </span>
                        <span>{trls[TrlKeys.pendingTwo]}</span>
                    </div>
                </Column>
            );
            break;
        default:
            content = null;
    }

    return (
        <ImportantNotifications kind={kind} hasDefaultLayout={false}>
            {content}
        </ImportantNotifications>
    );
};

export default translation(EmployerInformer);
