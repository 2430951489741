import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum ResumeShareKind {
    Vkontakte = 'vk',
    Facebook = 'fb',
    Twitter = 'twitter',
    Odnoklassniki = 'ok',
    GooglePlus = 'gplus',
    Linkedin = 'li',
    Mail = 'mail',
}

interface ResumeShareButton {
    type: ResumeShareKind;
    url: string;
    query: string;
    title: string;
    text: string;
}

export default autoGeneratedReducer<{
    buttons: ResumeShareButton[];
    topDomain: string;
}>(null);
