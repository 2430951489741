import Text, { TextImportance } from 'bloko/blocks/text';
import Textarea from 'bloko/blocks/textarea';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './what-confused-content.less';

const TrlKeys = {
    subTitle: 'jobSearchStatus.postChangeModal.step.writeWhatConfused.subTitle',
};

interface WhatConfusedContentProps {
    value: string;
    setValue: (text: string) => void;
}

const MAX_LEN = 150;

const WhatConfusedContent: TranslatedComponent<WhatConfusedContentProps> = ({ trls, value, setValue }) => {
    return (
        <>
            <Text Element="div">{trls[TrlKeys.subTitle]}</Text>
            <VSpacing base={5} />
            <Textarea
                name="text"
                maxLength={MAX_LEN}
                minLength={10}
                rows={4}
                noresize
                value={value}
                onChange={(event) => setValue(event.target.value)}
            />
            <VSpacing base={2} />
            <div className={styles.whatConfusedContentTip}>
                <Text importance={TextImportance.Secondary}>
                    {value.length}/{MAX_LEN}
                </Text>
            </div>
        </>
    );
};

export default translation(WhatConfusedContent);
