import format from 'date-fns/format';
import AZ from 'date-fns/locale/az';
import EN from 'date-fns/locale/en-US';
import KZ from 'date-fns/locale/kk';
import RU from 'date-fns/locale/ru';
import UA from 'date-fns/locale/uk';
import UZ from 'date-fns/locale/uz';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

const DEFAULT_LOCALE = (typeof window !== 'undefined' && window.globalVars?.lang) || 'RU';
// date-fns doesn't have kyrgyz language support yet
const LOCALES = { AZ, EN, KZ, RU, UA, UZ, KG: RU };

export const WINDOW_LOCALE = LOCALES[DEFAULT_LOCALE];

export const formatFromHyphenDateString = (
    dateString: string,
    formatOutput = 'dd.MM.yyyy',
    formatInput = 'yyyy-MM-dd kk:mm:ss'
): string => {
    return format(parse(dateString, formatInput, new Date()), formatOutput);
};

/**
 * Wrapper function around date-fns/format
 *
 * for docs see https://date-fns.org/v2.10.0/docs/format
 *
 * @param {string|number|Date} date Date
 * @param {string} formatString Date format
 * @param {'AZ'|'EN'|'KZ'|'RU'|'UA'|'UZ'|'KG'} lang Locale
 *
 * @returns {String}
 */

const formatDate = (date: string | Date | number, formatString: string, lang = DEFAULT_LOCALE): string =>
    format(typeof date === 'string' ? parseISO(date) : date, formatString, {
        locale: LOCALES[lang],
    });

export default formatDate;
