import { FC, Fragment, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { GridColumn, GridRow, GridLayout, VSpacing, Card } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import SupernovaMenuItem from 'src/components/SupernovaMainMenu/Item';
import { useSelector } from 'src/hooks/useSelector';
import { NavItems } from 'src/models/supernovaNaviMenu';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import styles from './services.less';

interface ServicesProps {
    items?: NavItems;
    redesigned?: boolean;
}

const Services: FC<ServicesProps & PropsWithChildren> = ({ items, redesigned }) => {
    const hide = useSelector(({ hideFooterServices }) => hideFooterServices);
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    if (!items?.length || hide) {
        return null;
    }

    if (redesigned) {
        return (
            <GridLayout>
                <VSpacing default={64} />

                <GridRow>
                    {items.map((menu) => {
                        return (
                            <GridColumn xs={4} s={8} m={4} key={menu.name} flexibleContent>
                                <div className={styles.serviceCardWrapper}>
                                    <Card padding={24} borderRadius={24} style="neutral" stretched>
                                        <SupernovaMenuItem {...menu} type="footer" key={menu.name} redesigned />
                                    </Card>
                                    <VSpacing default={16} gteM={0} />
                                </div>
                            </GridColumn>
                        );
                    })}
                </GridRow>

                <VSpacing default={40} />
            </GridLayout>
        );
    }

    return (
        <div
            className={classnames(styles.footerServices, {
                [styles.footerServicesHideMobile]: userType === UserType.Anonymous,
            })}
        >
            <ColumnsWrapper>
                {items.map((menu) => {
                    const menuItem = <SupernovaMenuItem {...menu} type="footer" key={menu.name} />;
                    if (userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer) {
                        return (
                            <Fragment key={menu.name}>
                                <Column xs="4" s="3" m="3" l="4">
                                    {menuItem}
                                </Column>
                                <Column xs="0" s="1" m="1" l="1" />
                            </Fragment>
                        );
                    }

                    return (
                        <Fragment key={menu.name}>
                            <Column xs="4" s="3" m="3" l="3">
                                {menuItem}
                            </Column>
                            <Column xs="0" s="1" m="1" l="1" />
                        </Fragment>
                    );
                })}
            </ColumnsWrapper>
        </div>
    );
};

export default Services;
