import { Entries } from 'type-fest';

import { rangeKeys } from 'src/components/NovaFilters/novaFilterUtils';
import { NovaFilterKey } from 'src/models/novaFilters';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { LabelOption } from 'src/models/resumeSearchCriteria';
import { ExpPeriodOption, LogicOption, PosOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import getSharedSearchParams, {
    SharedSearchParams,
} from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/shared';
import { GetSearchParamsArgs } from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/types';

export interface ResumeSearchParams extends SharedSearchParams {
    [CriteriaKey.AgeFrom]: string | number | null;
    [CriteriaKey.AgeTo]: string | number | null;
    [CriteriaKey.SalaryFrom]: string | number | null;
    [CriteriaKey.SalaryTo]: string | number | null;
    [CriteriaKey.ExpCompanySize]?: string[];
    [CriteriaKey.Gender]: Gender;
    [CriteriaKey.Logic]: LogicOption;
    [CriteriaKey.Pos]: PosOption;
    [CriteriaKey.ExpPeriod]: ExpPeriodOption;
}

const getResumeSearchParams = (args: GetSearchParamsArgs): ResumeSearchParams => {
    const searchParams = getSharedSearchParams(args) as ResumeSearchParams;
    const { searchCatalogRedirect, searchClusters } = args;

    // range values
    [NovaFilterKey.Age, NovaFilterKey.Salary].forEach((filter) => {
        if (!searchClusters[filter]) {
            return;
        }
        const { selectedValues } = searchClusters[filter];
        rangeKeys.forEach((key) => {
            const value = selectedValues[key];
            if (value) {
                searchParams[`${filter}_${key}`] = value;
            }
        });
        if (selectedValues.onlyWith) {
            const onlyWithStr = filter === NovaFilterKey.Age ? LabelOption.OnlyWithAge : LabelOption.OnlyWithSalary;
            searchParams[CriteriaKey.Label] = [...(searchParams[CriteriaKey.Label] || []), onlyWithStr];
        }
    });

    if (searchClusters[NovaFilterKey.Gender]) {
        const { selectedValues } = searchClusters[NovaFilterKey.Gender];
        searchParams[CriteriaKey.Gender] = selectedValues.value;
        if (selectedValues.onlyWith) {
            searchParams[CriteriaKey.Label] = [...(searchParams[CriteriaKey.Label] || []), LabelOption.OnlyWithGender];
        }
    }

    if (searchClusters[NovaFilterKey.Etc]) {
        const { selectedValues } = searchClusters[NovaFilterKey.Etc];
        const entries = Object.entries(selectedValues) as Entries<typeof selectedValues>;
        entries.forEach(([key, value]) => {
            searchParams[key] = value;
        });
    }

    // if we have metro and district, but don't have one region
    // need delete metro and district
    if (searchParams[CriteriaKey.Area]?.length !== 1) {
        searchParams[CriteriaKey.Metro] = [];
        searchParams[CriteriaKey.District] = [];
    }

    if (searchCatalogRedirect) {
        searchParams[CriteriaKey.Logic] = LogicOption.Normal;
        searchParams[CriteriaKey.Pos] = PosOption.FullText;
        searchParams[CriteriaKey.ExpPeriod] = ExpPeriodOption.AllTime;
    }

    return searchParams;
};

export default getResumeSearchParams;
