import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import headerGeoDefinitionButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_button_click';
import headerGeoDefinitionElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_element_shown';
import headerGeoDefinitionScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_screen_shown';
import { ActionBar, BottomSheet, Button, Text, Tooltip, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useNotification } from 'src/components/Notifications/Provider';
import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';
import regionClarificationComponent from 'src/components/SupernovaOverlayMenu/RegionClarification';
import translation from 'src/components/translation';
import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import { useSelector } from 'src/hooks/useSelector';
import useSendCheckGeoSwitcherRedesignExp from 'src/hooks/useSendCheckGeoSwitcherRedesignExp';
import { setIsGeoSwitcherActive } from 'src/models/isGeoSwitcherActive';

import styles from './styles.less';

const TrlKeys = {
    text: 'regionClarification.tooltip.text',
    submit: 'regionClarification.tooltip.actions.submit',
    change: 'regionClarification.tooltip.actions.cancel',
};

interface RegionClarificationTooltipProps {
    activatorRef: RefObject<HTMLElement>;
    showOverlay: OverlayTypeSetterFunc;
}

const setRegionClarification = makeSetStoreField('regionClarification');

const RegionClarificationTooltip: TranslatedComponent<RegionClarificationTooltipProps> = ({
    activatorRef,
    trls,
    showOverlay,
}) => {
    const { isMobile } = useBreakpoint();
    const isRedesignAnonymousGeoExp = useIsRedesignGeoExpActive();
    const sendExpCheck = useSendCheckGeoSwitcherRedesignExp();
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const regionClarification = useSelector(({ regionClarification }) => regionClarification);

    useEffect(() => {
        if (regionClarification) {
            sendExpCheck();
        }
    }, [regionClarification, sendExpCheck]);

    const clearRegionClarification = useCallback(() => {
        dispatch(setRegionClarification(null));
    }, [dispatch]);

    const handleChangeClick = () => {
        headerGeoDefinitionButtonClick({ buttonName: 'header_geo_definition_reject' });
        dispatch(setIsGeoSwitcherActive(true));

        clearRegionClarification();
    };

    const handleCloseClick = () => {
        headerGeoDefinitionButtonClick({ buttonName: 'header_geo_definition_confirm' });

        if (regionClarification?.host) {
            void axios.post('/shards/region_clarified', null, {
                params: { host: regionClarification.host },
            });
        }

        clearRegionClarification();
    };

    useEffect(() => {
        if (isRedesignAnonymousGeoExp) {
            return;
        }
        const container = document.querySelector<HTMLElement>('.HH-Supernova-RegionClarification-Container');

        if (!regionClarification || !container || (container.offsetWidth === 0 && container.offsetHeight === 0)) {
            return;
        }

        addNotification(regionClarificationComponent, {
            uniqueType: true,
            props: {
                ...regionClarification,
                onChangeClick: () => showOverlay(OverlayTypes.AreaSwitcherOverlay),
            },
        });

        // Сбрасываем поле, чтобы больше не показалась нотификация
        clearRegionClarification();
    }, [
        addNotification,
        clearRegionClarification,
        dispatch,
        isRedesignAnonymousGeoExp,
        regionClarification,
        showOverlay,
    ]);

    const visible = useMemo(
        () => !!(isRedesignAnonymousGeoExp && regionClarification),
        [isRedesignAnonymousGeoExp, regionClarification]
    );

    useEffect(() => {
        if (visible) {
            if (isMobile) {
                headerGeoDefinitionScreenShown({ hhtmSource: 'header_geo_defination' });
            }
        }
    }, [isMobile, visible]);

    const areaQuestionContent = (
        <div className={styles.textContainer}>
            <Text typography="title-5-semibold">
                {format(trls[TrlKeys.text], {
                    '{0}': regionClarification?.translations.area || '',
                })}{' '}
            </Text>
        </div>
    );

    return (
        <>
            <Tooltip
                buttons={(onClose) => (
                    <>
                        <Button
                            data-qa="region-clarification-submit-button"
                            onClick={() => {
                                onClose();
                            }}
                            style="contrast"
                            size="small"
                            stretched
                            mode="primary"
                        >
                            {trls[TrlKeys.submit]}
                        </Button>
                        <Button
                            data-qa="region-clarification-change-button"
                            onClick={handleChangeClick}
                            style="contrast"
                            size="small"
                            stretched
                            mode="secondary"
                        >
                            {trls[TrlKeys.change]}
                        </Button>
                    </>
                )}
                visible={visible}
                activatorRef={activatorRef}
                placement="bottom-center"
                onClose={handleCloseClick}
                closeByClickOutside={false}
            >
                <ElementShownAnchor fn={headerGeoDefinitionElementShown}>{areaQuestionContent}</ElementShownAnchor>
            </Tooltip>
            <BottomSheet
                footer={
                    <ActionBar
                        primaryActions={[
                            <Button
                                data-qa="region-clarification-change-button"
                                key="change-button"
                                onClick={handleChangeClick}
                                style="accent"
                                size="medium"
                                stretched
                                mode="secondary"
                            >
                                {trls[TrlKeys.change]}
                            </Button>,
                            <Button
                                data-qa="region-clarification-submit-button"
                                key="submit-button"
                                onClick={handleCloseClick}
                                style="accent"
                                size="medium"
                                stretched
                                mode="primary"
                            >
                                {trls[TrlKeys.submit]}
                            </Button>,
                        ]}
                        type="mobile"
                    />
                }
                onClose={clearRegionClarification}
                visible={visible}
            >
                <VSpacing default={12} />
                {areaQuestionContent}
                <VSpacing default={12} />
            </BottomSheet>
        </>
    );
};

export default translation(RegionClarificationTooltip);
