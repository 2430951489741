import { FC } from 'react';

import { DataProviderItem } from 'bloko/blocks/suggest/types';
import Text from 'bloko/blocks/text';

export interface SuggestDomainItemProps extends DataProviderItem {
    href: string;
    region?: string;
    areaId?: number;
}

export const suggestDomainItemKey = ({ text }: SuggestDomainItemProps): string => text;

const SuggestDomainItem: FC<SuggestDomainItemProps> = ({ text, region }) => {
    return (
        <>
            <Text strong data-qa="area-switcher-autocomplete-city">
                {text}
            </Text>
            {region && <Text data-qa="area-switcher-autocomplete-region">{region}</Text>}
        </>
    );
};

export default SuggestDomainItem;
