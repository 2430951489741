import { FC, useContext } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';

import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaLink from 'src/components/SupernovaMainMenu/NaviLink';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const EmployerSignup: FC<NavItem> = ({ name, trackClicks, translations, url, analytics }) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    return (
        <SupernovaNaviItem className="supernova-signup-wrapper" visibleOn={VISIBLE_ON}>
            {isRedesignActive ? (
                <SupernovaButton
                    isMagritte={isRedesignActive}
                    data-qa={`mainmenu_${name}`}
                    name={name}
                    trackClicks={trackClicks}
                    url={url}
                    analytics={analytics}
                >
                    {translations.name}
                </SupernovaButton>
            ) : (
                <SupernovaLink
                    data-qa={`mainmenu_${name}`}
                    name={name}
                    trackClicks={trackClicks}
                    url={url}
                    analytics={analytics}
                    isMagritte={isRedesignActive}
                >
                    {translations.name}
                </SupernovaLink>
            )}
        </SupernovaNaviItem>
    );
};

export default EmployerSignup;
