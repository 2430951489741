import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerBrandItemTypes {
    Inside = 'employerbrand-inside',
    Advit = 'employerbrand-advit',
    PartnerPost = 'employerbrand-partnerpost',
    Scan = 'employerbrand-scan',
    Creation = 'employerbrand-creation',
    Activation = 'employerbrand-activation',
    Kakdela = 'employerbrand-kakdela',
}

interface EmployerBrandItem {
    type: EmployerBrandItemTypes;
    detailsUrl: string;
    downloadUrl?: string;
}

interface PriceEmployerBrand {
    items: EmployerBrandItem[];
}

export default autoGeneratedReducer<PriceEmployerBrand>(null);
