import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum NegotiationsFilterTab {
    Active = 'active',
    All = 'all',
}

export enum ApplicantNegotiationStatus {
    Discard = 'DISCARD',
    Invitation = 'INVITATION',
    Read = 'READ',
    Unread = 'UNREAD',
    OnlyNew = 'ONLY_NEW',
    All = 'ALL',
}

export interface FilterByStatus {
    href: string;
    name: ApplicantNegotiationStatus;
    selected: boolean;
}

export enum ApplicantNegitiationsOrder {
    LastState = 'LAST_STATE',
    LastModified = 'LAST_MODIFIED',
}

export enum OrderDirection {
    Ascending = 'ASC',
    Descending = 'DESC',
}

interface Order {
    order: OrderDirection;
    href: string;
    name: ApplicantNegitiationsOrder;
}

interface ApplicantNegotiationsActionsDataState {
    deleteAction: {
        href?: string;
        substate?: string;
    };
    filters: {
        href: string;
        selected: boolean;
        name: NegotiationsFilterTab;
    }[];
    filterByStatus: FilterByStatus[];
    backUrl: string;
    order: Partial<Record<ApplicantNegitiationsOrder, Order>>;
}

export default autoGeneratedReducer<ApplicantNegotiationsActionsDataState>({
    deleteAction: {},
    filters: [],
    filterByStatus: [],
    backUrl: '',
    order: {},
});

export enum ApplicantNegotiationFiltersStates {
    All = 'all',
    Awaiting = 'awaiting',
    Invitation = 'invitation',
    Discard = 'discard',
    Deleted = 'deleted',
    Archived = 'archived',
}
