export const COOKIE_AGREEMENT_NAME = 'cookie_policy_agreement';
export const COOKIE_EXPIRE_TIME = 1000 * 3600 * 24 * 31 * 2;
export const LOCALSTORAGE_KEY = 'cookie_agreement_declined';
export const RESELECT_PARAMETER = 'reselect_cookie_policy';
export const BOTTOM_COOKIES_POLICY_INFORMER_ID = 'bottom-cookies-policy-informer';

export const checkUrlAndReload = (): void => {
    const currentSearchParams = new URLSearchParams(location.search);

    if (currentSearchParams.get(RESELECT_PARAMETER) !== null) {
        currentSearchParams.delete(RESELECT_PARAMETER);
        location.search = currentSearchParams.toString();
    }
};
export const clearCookies = (topLevelDomain: string, whitelistRegexpString: string): void => {
    const domains = ['', location.hostname, location.hostname.split('.').slice(-2).join('.'), topLevelDomain];
    const cookies = document.cookie.split(';');
    const whitelistRegexp = new RegExp(whitelistRegexpString);
    for (const cookie of cookies) {
        const cookieName = cookie.split('=')[0].trim();
        if (!whitelistRegexp.test(cookieName)) {
            for (const domain of domains) {
                const domainPart = domain ? `domain=${domain};` : '';
                document.cookie = `${cookieName}=;path=/;${domainPart}expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
            }
        }
    }
};

export const setTopLevelCookie = (name: string, value: string, expire: number): void => {
    const topLevelDomain = location.hostname.split('.').slice(-2).join('.');
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + expire);

    document.cookie = `${name}=${value};path=/;domain=.${topLevelDomain};expires=${expireDate.toUTCString()}`;
};
