import { useContext, useMemo } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsMiddleAsia } from 'src/hooks/useSites';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaLink from 'src/components/SupernovaMainMenu/NaviLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const TrlKeys = {
    help: 'supernova.navi.help',
};

const Feedback: TranslatedComponent<NavItem> = ({ active, name, trackClicks, trls, url }) => {
    const isMagritte = useContext(isRedesignActiveContext);
    const isMiddleAsia = useIsMiddleAsia();

    const visibleOn = useMemo(() => {
        if (isMagritte) {
            return [Breakpoint.M, Breakpoint.L];
        }

        if (name === 'copiny' || isMiddleAsia) {
            return [Breakpoint.S, Breakpoint.M, Breakpoint.L];
        }

        return undefined;
    }, [isMagritte, isMiddleAsia, name]);

    return (
        <SupernovaNaviItem active={active} visibleOn={visibleOn}>
            <SupernovaLink
                isMagritte={isMagritte}
                active={active}
                data-qa="supernova-help-feedback-link"
                name={name}
                trackClicks={trackClicks}
                url={url}
            >
                {trls[TrlKeys.help]}
            </SupernovaLink>
            <div className="supernova-navi-underline">{trls[TrlKeys.help]}</div>
        </SupernovaNaviItem>
    );
};

export default translation(Feedback);
