import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface WorkInOtherCityItem {
    id: number;
    domain: string;
    name: string;
}

export interface WorkInOtherCityInterface {
    link: WorkInOtherCityItem[];
}

export default autoGeneratedReducer<WorkInOtherCityInterface>(null);
