import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerEdo {
    payerId: number;
    payerName: string;
    inn: string;
    fio: string;
    phone: string;
    email: string;
    operator: string;
    date: string;
    status: string;
}

export default autoGeneratedReducer<EmployerEdo[]>([]);
