import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push, Replace, usePush, useReplace } from '@hh.ru/redux-spa-middleware';
import debounce from 'bloko/common/debounce';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import { useNotification } from 'src/components/Notifications/Provider';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { useUpdateAbortController } from 'src/components/NovaFilters/NovaFilterUpdateContext';
import sendFilterForm, { SendFilterFormArgs } from 'src/components/NovaFilters/actions/sendFilterForm';

export interface SendFilterFormWrapped {
    (args?: SendFilterFormArgs): void;
}

const searchCountsAction = makeSetStoreField('searchCounts');

const DEBOUNCE_DELAY_MS = 300;

const isDesktop = () => [Breakpoint.M, Breakpoint.L].includes(getBreakpoint());

const dispatchSendFilterForm = (
    dispatch: Dispatch,
    args: SendFilterFormArgs,
    addNotification: AddNotification,
    replace: Replace,
    push: Push
) => dispatch(sendFilterForm(args, addNotification, replace, push));

const debouncedDispatchSendFilterForm = debounce(
    (dispatch: Dispatch, args: SendFilterFormArgs, addNotification: AddNotification, replace: Replace, push: Push) =>
        dispatch(sendFilterForm(args, addNotification, replace, push)),
    DEBOUNCE_DELAY_MS
);

const useSendFilterForm = (isDebounced?: boolean): SendFilterFormWrapped => {
    const dispatch = useDispatch();
    const abortAndGetUpdatedSignal = useUpdateAbortController();
    const { addNotification } = useNotification();
    const replace = useReplace();
    const push = usePush();

    const dispatchCallback = isDebounced ? debouncedDispatchSendFilterForm : dispatchSendFilterForm;

    return (args) => {
        const abortSignal = abortAndGetUpdatedSignal();
        dispatchCallback(dispatch, { ...args, abortSignal }, addNotification, replace, push);
    };
};

export const useDebouncedCountsRequest = (): (() => void) => {
    const dispatch = useDispatch();
    const debouncedSendFilterForm = useSendFilterForm(true);

    return useCallback(() => {
        if (isDesktop()) {
            return;
        }
        dispatch(searchCountsAction({ isLoad: true, value: 0 }));
        debouncedSendFilterForm({ countsRequest: true });
    }, [debouncedSendFilterForm, dispatch]);
};

export const useDebouncedSendFilterFormByBreakpoint = (): SendFilterFormWrapped => {
    const debouncedSendFilterForm = useSendFilterForm(true);

    return useCallback((args) => isDesktop() && debouncedSendFilterForm(args), [debouncedSendFilterForm]);
};

export default useSendFilterForm;
