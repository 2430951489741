import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { YearMonthDayString } from 'bloko/blocks/calendar/datesHelper';

import formatDate from 'Modules/formatDate';

export const TODAY = formatDate(new Date(), 'yyyy-MM-dd');

export type PayerId = string;

export interface VerificationActsPayer {
    id: PayerId;
    name: string;
    inn: string;
    kpp: string;
}

export interface VerificationActsPeriod {
    dateFrom: YearMonthDayString;
    dateTo: YearMonthDayString;
    disableDatesBefore: YearMonthDayString | null;
    disableDatesAfter: YearMonthDayString | null;
}

export type VerificationActId = string;

export interface VerificationAct {
    id: VerificationActId;
    completed: boolean;
    fileUrl: string;
    creationTime: YearMonthDayString;
    from: YearMonthDayString;
    to: YearMonthDayString;
    payerId: PayerId;
    taxNumber: string;
    kpp: string;
}

interface OrdersInfo {
    info: string;
    date: YearMonthDayString;
}

export interface PickupInterval {
    from: string;
    to: string;
}

export interface VerificationActsResponse {
    verificationActs: {
        items: VerificationAct[];
        ordersInfo: Record<VerificationActId, OrdersInfo>;
    };
}

export enum AddressFields {
    Country = 'deliveryAddress.country',
    Area = 'deliveryAddress.area',
    City = 'deliveryAddress.city',
    Street = 'deliveryAddress.street',
    Building = 'deliveryAddress.building',
    Office = 'deliveryAddress.office',
    PhoneCountry = 'deliveryAddress.phone.country',
    PhoneCity = 'deliveryAddress.phone.city',
    PhoneNumber = 'deliveryAddress.phone.number',
    Description = 'deliveryAddress.description',
    DeliveryPerson = 'deliveryAddress.deliveryPerson',
}

interface AddressField {
    required: boolean;
}

interface PayerAddressDetails {
    id: PayerId;
    city: string;
    street: string;
    building: string;
    description: string;
    country: string;
    area: string;
    ['phone.city']: string;
    ['phone.country']: string;
    ['phone.number']: string;
    ['phone.comment']: string;
    office: string;
    streetPrefix: string;
    deliveryPerson: string;
}

export interface PayerAddress {
    electronicRecord: boolean;
    address: PayerAddressDetails;
}

export type Countries = string[];

export interface DeliveryAddress {
    country: string;
    city: string;
    street: string;
    building: string;
    office: string;
    phone: {
        country: string;
        city: string;
        number: string;
    };
    deliveryPerson: string;
}

interface VerificationActs {
    payers: VerificationActsPayer[];
    period: VerificationActsPeriod;
    items: VerificationAct[];
    ordersInfo: Record<VerificationActId, OrdersInfo>;
    canPickup: boolean;
    initialPayerId?: PayerId;
    duplicateId?: string;
    newId?: string;
    deliveryAddress: DeliveryAddress;
    pickupIntervals: PickupInterval[];
    fields: Partial<Record<AddressFields, AddressField>>;
    countries: Countries;
    payerAddress: PayerAddress;
    notices?: string[];
}

const INITIAL_STATE: VerificationActs = {
    payers: [],
    period: {
        dateFrom: TODAY,
        dateTo: TODAY,
        disableDatesBefore: TODAY,
        disableDatesAfter: TODAY,
    },
    items: [],
    ordersInfo: {},
    canPickup: false,
    deliveryAddress: {
        country: '',
        city: '',
        street: '',
        building: '',
        office: '',
        phone: {
            country: '',
            city: '',
            number: '',
        },
        deliveryPerson: '',
    },
    pickupIntervals: [],
    fields: {},
    countries: [],
    payerAddress: {
        electronicRecord: false,
        address: {
            id: '',
            city: '',
            street: '',
            building: '',
            description: '',
            country: '',
            area: '',
            'phone.city': '',
            'phone.country': '',
            'phone.number': '',
            'phone.comment': '',
            office: '',
            streetPrefix: '',
            deliveryPerson: '',
        },
    },
};

const HIDE_NOTICE = 'HIDE_NOTICE';

interface PayloadTypes {
    [HIDE_NOTICE]: string;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const hideNotice = ActionCreator(HIDE_NOTICE);

export default createReducer<VerificationActs, PayloadTypes>(INITIAL_STATE, {
    [HIDE_NOTICE]: (state, { payload }) => {
        const notices = state.notices?.filter((notice) => notice !== payload);
        return {
            ...state,
            notices,
        };
    },
});
