import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.CompleteResumeWorkDone]: {
        first: 'supernova.notification.complete.resume.work_done',
        link: 'supernova.notification.complete.resume.work_done.link',
    },
    [ComplexUserNotificationsTemplateKey.CompleteResumePaid]: {
        first: 'supernova.notification.complete.resume.paid',
        link: 'supernova.notification.complete.resume.service.link',
        lastLink: 'supernova.notification.complete.resume.paid.fill.link',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const LinkMap = {
    [ComplexUserNotificationsTemplateKey.CompleteResumeWorkDone]: '/applicant/resumes',
    [ComplexUserNotificationsTemplateKey.CompleteResumePaid]: '/expert_resume',
};

const CompleteResume: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Mail}
            dataQa={
                key === ComplexUserNotificationsTemplateKey.CompleteResumeWorkDone
                    ? DataQaInvariants.CompleteResumeWorkDone
                    : undefined
            }
            {...notification}
        >
            <NotificationSpacer>
                {formatToReactComponent(trls[TrlKeys[key].first], {
                    '{0}': <NotificationLink to={LinkMap[key]}>{trls[TrlKeys[key].link]}</NotificationLink>,
                })}
            </NotificationSpacer>
            {key === ComplexUserNotificationsTemplateKey.CompleteResumePaid && (
                <NotificationLink
                    to={`/applicant-services/complete_resume/order/${notification.params.orderCode || ''}`}
                >
                    {trls[TrlKeys[key].lastLink]}
                </NotificationLink>
            )}
        </Notification>
    );
};

export default translation(CompleteResume);
