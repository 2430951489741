import Analytics from '@hh.ru/analytics-js';

export const applicationItemClick = (item: string): void =>
    Analytics.sendHHEventButtonClick(`footer_application_item_${item}`);

export const applicationItemsShown = (element: HTMLElement, items: string[]): void => {
    Analytics.sendHHEventElementShown(element, {
        name: 'footer_application_items',
        item: items,
    });
};

export const navigationItemClick = (item: string): void =>
    Analytics.sendHHEventButtonClick(`footer_navigation_item_${item}`);

export const navigationItemsShown = (element: HTMLElement, section: string, items: string[]): void => {
    Analytics.sendHHEventElementShown(element, {
        name: 'footer_navigation_items',
        section,
        item: items,
    });
};

export const servicesItemClick = (item: string): void => {
    Analytics.sendHHEventButtonClick(item);
};

export const servicesItemShown = (element: HTMLElement, params: Record<string, string>): void => {
    Analytics.sendHHEventElementShown(element, params);
};

export const socialNetworkItemsShown = (element: HTMLElement, items: string[]): void => {
    Analytics.sendHHEventElementShown(element, {
        name: 'footer_social_network_items',
        item: items,
    });
};

export default {
    applicationItemClick,
    applicationItemsShown,
    navigationItemClick,
    navigationItemsShown,
    servicesItemClick,
    servicesItemShown,
    socialNetworkItemsShown,
};
