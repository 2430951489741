import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push, Replace } from '@hh.ru/redux-spa-middleware';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import ModalHelper from 'bloko/common/modalHelper';
import urlParser from 'bloko/common/urlParser';

import Debug from 'HHC/Debug';
import fetchEmployerVacancies from 'src/api/employerVacancySearch/fetchEmployerVacancies';
import fetchEmployerVacancyCount from 'src/api/employerVacancySearch/fetchEmployerVacancyCount';
import { AppStore } from 'src/app/store';
import networkError from 'src/components/Notifications/NetworkError';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import SearchType from 'src/components/NovaFilters/SearchType';
import fetchMapVacancies from 'src/components/NovaFilters/fetchMapVacancies';
import getFetchResume from 'src/components/NovaFilters/fetchResume';
import getFetchVacancies from 'src/components/NovaFilters/fetchVacancies';
import getFetchVacancyCounts from 'src/components/NovaFilters/fetchVacancyCounts';
import { NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { setSearchClusterTipData, setSearchClusterTipShow } from 'src/models/searchClustersTip';
import fetcher from 'src/utils/fetcher';

import { adjustSavedArea } from 'src/components/NovaFilters/actions/sendFilterForm/adjustSavedArea';
import getSearchParams from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams';

const searchLoadingAction = makeSetStoreField('searchLoading');
const searchPreferenceAction = makeSetStoreField('searchPreference');
const cancelFetchBannersAction = makeSetStoreField('cancelFetchBanners');

let tipTimeout: NodeJS.Timeout;

const isXsOrS = () => {
    const breakpoint = getBreakpoint();
    return [Breakpoint.XS, Breakpoint.S].includes(breakpoint);
};

export interface SendFilterFormArgs {
    abortSignal?: AbortSignal;
    filter?: NovaFilterKey;
    countsRequest?: boolean;
    showClustersTip?: boolean;
    isSearchSessionIdSaved?: boolean;
}

export interface SendFilterForm {
    (
        args: SendFilterFormArgs,
        addNotification: AddNotification,
        replace: Replace,
        push: Push
    ): (dispatch: Dispatch, getState: () => AppStore) => void;
}

const sendFilterForm: SendFilterForm =
    (
        { filter, countsRequest = false, showClustersTip = true, abortSignal, isSearchSessionIdSaved = false },
        addNotification,
        replace,
        push
    ) =>
    (dispatch, getState) => {
        const {
            criteriaCurrencyCode,
            criteriaTextUpdated,
            employerVacancySearch,
            currencies,
            enableVacancySnippets,
            novaSorts,
            resumeSearchResult,
            router,
            searchCatalogRedirect,
            searchClusters,
            searchClustersSettings,
            searchCounts,
            searchSessionId,
            vacancySearchResult,
            isAdjustDetectedRegionResumeExpEnabled,
            searchClustersLastAppliedFilter,
            employerInfo,
            employerVacancyTemplateFilter,
            hasChameleon,
        } = getState();
        const { actionPath, type: searchType } = searchClustersSettings;

        const searchParams = getSearchParams({
            dispatch,
            filter,
            criteriaCurrencyCode,
            criteriaTextUpdated:
                searchType === SearchType.EmployerVacancy
                    ? employerVacancySearch.criteria?.[CriteriaKey.Text] || ''
                    : criteriaTextUpdated,
            currencies,
            enableVacancySnippets,
            router,
            novaSorts,
            resumeSearchResult,
            searchCatalogRedirect,
            searchClusters,
            searchType,
            vacancySearchResult,
            employerInfo,
            employerVacancySearch,
            isAdjustDetectedRegionResumeExpEnabled,
            employerVacancyTemplateFilter,
            hasChameleon,
            isSearchSessionIdSaved,
            searchSessionId,
        });

        adjustSavedArea(
            searchClustersLastAppliedFilter?.filterName,
            searchType,
            isAdjustDetectedRegionResumeExpEnabled,
            searchParams,
            dispatch
        );
        const query = urlParser.stringify(searchParams as never);

        const sharedFetchArgs = { dispatch, query, abortSignal };

        // map vacancy search
        if (searchType === SearchType.VacancyMap) {
            fetchMapVacancies(sharedFetchArgs)().catch((error) => {
                Debug.log('out error', error, {
                    query,
                });
            });
            return;
        }

        // request for counts (only for employer vacancy search)
        if (countsRequest && searchType === SearchType.EmployerVacancy) {
            fetchEmployerVacancyCount({
                ...sharedFetchArgs,
                value: searchCounts.value,
                addNotification,
            }).catch(console.error);
            return;
        }

        // request for counts (only for vacancy search)
        if (countsRequest) {
            const fetchCountsRequest = getFetchVacancyCounts({
                ...sharedFetchArgs,
                value: searchCounts.value,
            });
            fetchCountsRequest().catch((error) => {
                if (fetcher.isCancel(error)) {
                    return;
                }
                addNotification(networkError);
            });
            return;
        }

        if (searchType === SearchType.EmployerVacancy) {
            dispatch(
                fetchEmployerVacancies({
                    ...sharedFetchArgs,
                    addNotification,
                    replace,
                    isMobile: isXsOrS(),
                })
            ).catch(console.error);
            return;
        }

        // catalog redirects (with debounce)
        if (searchCatalogRedirect) {
            push(`${actionPath}?${query}`, { cancelScrollCorrection: true });
            return;
        }

        // request for search data's
        const fetchResponse =
            searchType === SearchType.Vacancy
                ? getFetchVacancies(sharedFetchArgs, push)
                : getFetchResume(sharedFetchArgs, push);

        // loader and tip
        dispatch([searchLoadingAction(true), setSearchClusterTipShow(false), cancelFetchBannersAction(true)]);

        if (tipTimeout) {
            clearTimeout(tipTimeout);
        }

        // after update search result
        fetchResponse()
            .then(({ totalResults, redirect }) => {
                if (redirect) {
                    push(redirect, { cancelScrollCorrection: true });
                    return;
                }
                dispatch([cancelFetchBannersAction(false), searchLoadingAction(false)]);

                // close xs/s form
                if (isXsOrS()) {
                    dispatch(searchPreferenceAction({ isShown: false }));
                    return;
                }

                // show result tip on next tick
                setTimeout(() => {
                    dispatch(setSearchClusterTipData({ show: showClustersTip, totalResults, filter }));
                }, 60);
                tipTimeout = setTimeout(() => {
                    dispatch(setSearchClusterTipShow(false));
                }, 2000);
            })
            .catch((error) => {
                if (fetcher.isCancel(error)) {
                    return;
                }
                Debug.log('out error', error, { query });
                dispatch(searchLoadingAction(false));
                addNotification(networkError);
            })
            .finally(() => {
                if (searchType === SearchType.Resume) {
                    ModalHelper.enableScroll();
                }
            });
    };

export default sendFilterForm;
