import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerAddress } from 'src/models/employerAddresses.types';

interface EmployerAddressesPageState {
    addresses: EmployerAddress[];
    addressesCount: number;
    isFetching: boolean;
    loadingComponent: string;
}

export const CHANGE_ADDRESSES_LOADING_STATUS = 'CHANGE_ADDRESSES_LOADING_STATUS';
export const CHANGE_ADDRESSES_LIST = 'CHANGE_ADDRESSES_LIST';
export const CHANGE_ADDRESSES_COUNT = 'CHANGE_ADDRESSES_COUNT';

interface PayloadTypes {
    [CHANGE_ADDRESSES_LOADING_STATUS]: {
        isFetching: boolean;
        loadingComponent: 'search' | 'city' | 'address' | 'manager' | '';
    };
    [CHANGE_ADDRESSES_LIST]: EmployerAddress[];
    [CHANGE_ADDRESSES_COUNT]: number;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const changeAddressesLoadingStatus = ActionCreator(CHANGE_ADDRESSES_LOADING_STATUS);
export const changeAddressesList = ActionCreator(CHANGE_ADDRESSES_LIST);
export const changeAddressesCount = ActionCreator(CHANGE_ADDRESSES_COUNT);

const initialState: EmployerAddressesPageState = {
    addresses: [],
    addressesCount: 0,
    isFetching: false,
    loadingComponent: '',
};

export default createReducer<EmployerAddressesPageState, PayloadTypes>(initialState, {
    [CHANGE_ADDRESSES_LOADING_STATUS]: (state, action) => ({
        ...state,
        isFetching: action.payload.isFetching,
        loadingComponent: action.payload.loadingComponent,
    }),
    [CHANGE_ADDRESSES_LIST]: (state, action) => ({
        ...state,
        addresses: action.payload,
    }),
    [CHANGE_ADDRESSES_COUNT]: (state, action) => ({
        ...state,
        addressesCount: action.payload,
    }),
});
