import React from 'react';

import FormSpacer from 'bloko/blocks/form/FormSpacer';
import { ArrowScaleSmallKindRight, IconColor } from 'bloko/blocks/icon';

import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption, { OptionHighlight } from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import useHandleSwitchAccountClick from 'src/components/SupernovaMainMenu/hooks/useHandleSwitchAccountClick';

const SwitchAccount: React.FC<NavItem> = ({ name, trackClicks, translations, analytics }) => {
    const handleSwitchAccountClick = useHandleSwitchAccountClick();

    return (
        <SupernovaDropdownSection>
            <SupernovaDropdownOption
                name={name}
                trackClicks={trackClicks}
                highlight={OptionHighlight.Primary}
                onClick={handleSwitchAccountClick}
                analytics={analytics}
            >
                <div className="supernova-switch-account-content">
                    {translations.name}
                    <FormSpacer>
                        <ArrowScaleSmallKindRight initial={IconColor.Blue50} />
                    </FormSpacer>
                </div>
            </SupernovaDropdownOption>
        </SupernovaDropdownSection>
    );
};

export default SwitchAccount;
