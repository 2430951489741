import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export default autoGeneratedReducer<
    Partial<{
        maxActiveDocuments: number;
        maxFileSize: number;
        maxFileSizeInMb: number;
        allowedMimeTypes: string[];
        allowedTypes: string[];
        allowedDocumentChanges: string[];
    }>
>({});
