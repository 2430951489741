import { PropsWithChildren, FC, ComponentPropsWithoutRef, useMemo, ComponentType, useCallback } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import useDeleteUserNotificationById from 'src/components/SupernovaMainMenu/UserNotifications/useDeleteNotification';
import translation from 'src/components/translation';
import { ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

import getNotificationLink from 'src/components/BrandingTriggerNotification/getNotificationLink';
import useTriggerNotificationsAnalytics from 'src/components/BrandingTriggerNotification/useTriggerNotificationsAnalytics';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.BuyVacancyPageConstructor]: {
        content: 'branding.suggestion.buy.vacancy.constructor.notification.text',
        link: 'branding.suggestion.buy.vacancy.constructor.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.BuyEmployerPageConstructor]: {
        content: 'branding.suggestion.buy.employer.page.constructor.notification.text',
        link: 'branding.suggestion.buy.employer.page.constructor.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.FillEmployerPageConstructor]: {
        content: 'branding.reminder.fill.employer.page.constructor.notification.text',
        link: 'branding.reminder.fill.employer.page.constructor.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.FillEmployerVacancyPageConstructor]: {
        content: 'branding.reminder.fill.vacancy.constructor.notification.text',
        link: 'branding.reminder.fill.vacancy.constructor.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.SuggestionEmployerMakeup]: {
        content: 'branding.suggestion.employer.page.makeup.notification.text',
        link: 'branding.suggestion.employer.page.makeup.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.SuggestionVacancyMakeup]: {
        content: 'branding.suggestion.vacancy.makeup.notification.text',
        link: 'branding.suggestion.vacancy.makeup.notification.link',
    },
};

type TemplateKey = keyof typeof TrlKeys;
const isSupportedTemplate = (key: ComplexUserNotificationsTemplateKey): key is TemplateKey => key in TrlKeys;

export const notificationsAcknowledgedByAction = [
    ComplexUserNotificationsTemplateKey.BuyEmployerPageConstructor,
    ComplexUserNotificationsTemplateKey.BuyVacancyPageConstructor,
    ComplexUserNotificationsTemplateKey.SuggestionEmployerMakeup,
    ComplexUserNotificationsTemplateKey.SuggestionVacancyMakeup,
];

type AbstractLinkComponent = FC<PropsWithChildren<Pick<ComponentPropsWithoutRef<typeof Link>, 'to' | 'onClick'>>>;

interface BrandingTriggerNotificationProps {
    place: 'screen' | 'supernova';
    notificationId: number;
    templateKey: ComplexUserNotificationsTemplateKey;
    LinkComponent?: AbstractLinkComponent;
    linkParams?: Record<string, unknown>;
    children: (params: {
        Content: ComponentType;
        Action: FC<PropsWithChildren>;
        sendOnRemoveEvent?: () => void;
        acknowledgeNotification: () => void;
    }) => ReturnType<FC>;
}

const makeActionComponent =
    (LinkComponent: AbstractLinkComponent, sendLinkClickEvent: () => void, linkTrl: string, linkTo: string | null) =>
    // eslint-disable-next-line react/display-name
    (props: PropsWithChildren) =>
        linkTo ? (
            <LinkComponent {...props} onClick={sendLinkClickEvent} to={linkTo}>
                {linkTrl}
            </LinkComponent>
        ) : null;

// eslint-disable-next-line react/display-name
const makeContentComponent = (ElementShownWrapper: FC<PropsWithChildren>, notificationText: string) => () => (
    <ElementShownWrapper>{notificationText}</ElementShownWrapper>
);

const BrandingTriggerNotification: FC<TranslationHOCProps & BrandingTriggerNotificationProps> = ({
    place,
    notificationId,
    templateKey,
    children,
    LinkComponent = Link,
    linkParams,
    trls,
}) => {
    if (!isSupportedTemplate(templateKey)) {
        throw new Error(`Unsupported template key: ${templateKey}`);
    }

    const templateTrlKeys = TrlKeys[templateKey];
    const notificationText = trls[templateTrlKeys.content];

    const { ElementShownWrapper, sendOnRemoveEvent, sendLinkClickEvent } = useTriggerNotificationsAnalytics({
        notificationText,
        notificationId,
        templateKey,
        place,
    });

    const shouldAcknowledgeOnAction = notificationsAcknowledgedByAction.includes(templateKey);

    const linkTrl = trls[templateTrlKeys.link];
    const linkTo = getNotificationLink(notificationId, templateKey, shouldAcknowledgeOnAction, linkParams);

    const Action = useMemo(
        () => makeActionComponent(LinkComponent, sendLinkClickEvent, linkTrl, linkTo),
        [LinkComponent, linkTo, linkTrl, sendLinkClickEvent]
    );

    const Content = useMemo(
        () => makeContentComponent(ElementShownWrapper, notificationText),
        [ElementShownWrapper, notificationText]
    );

    const [isDeleting, deleteNotification] = useDeleteUserNotificationById(notificationId);
    const acknowledgeNotification = useCallback(() => {
        if (!isDeleting && shouldAcknowledgeOnAction) {
            deleteNotification().catch(console.error);
        }
    }, [deleteNotification, isDeleting, shouldAcknowledgeOnAction]);

    const renderParams = useMemo(
        () => ({
            Content,
            Action,
            sendOnRemoveEvent,
            acknowledgeNotification,
        }),
        [Content, Action, sendOnRemoveEvent, acknowledgeNotification]
    );

    return children(renderParams);
};

export default translation(BrandingTriggerNotification);
