import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum FeedbackRecipient {
    SUPPORT = 'SUPPORT',
    MARKET = 'MARKET',
    SALES = 'SALES',
    APPLICANT = 'APPLICANT',
    QUALITY = 'QUALITY',
}

type FeedbackRecipients = { [K in FeedbackRecipient]?: string };

interface FeedbackPage {
    feedbackRecipients: FeedbackRecipients;
}

export default autoGeneratedReducer<FeedbackPage>({
    feedbackRecipients: { [FeedbackRecipient.MARKET]: 'feedback.send.market' },
});
