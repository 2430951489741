import { FC } from 'react';

import { CheckableChip } from '@hh.ru/magritte-ui';

import CopiedToClipboardNotification from 'src/components/CopiedToClipboardNotification';
import { useNotification } from 'src/components/Notifications/Provider';
import { NON_BREAKING_SPACE, REGULAR_SPACE } from 'src/utils/constants/symbols';

interface EmployerProfileIdProps {
    employerId: string;
}

const EMPLOYER_ID_PART_LENGTH = 3;

const formatEmployerId = (employerId: string): string => {
    const partsCount = Math.ceil(employerId.length / EMPLOYER_ID_PART_LENGTH);

    const parts = [];

    for (let i = 0; i < partsCount; i++) {
        const start = i * EMPLOYER_ID_PART_LENGTH;
        parts.push(employerId.substring(start, start + EMPLOYER_ID_PART_LENGTH));
    }

    return parts.join(REGULAR_SPACE);
};

const EmployerProfileId: FC<EmployerProfileIdProps> = ({ employerId }) => {
    const { addNotification } = useNotification();

    const handleClick = async () => {
        await navigator.clipboard.writeText(employerId);
        addNotification(CopiedToClipboardNotification);
    };
    return (
        <CheckableChip type="checkbox" checked={false} onChange={handleClick} style="neutral">
            &#8470;{NON_BREAKING_SPACE}
            {formatEmployerId(employerId)}
        </CheckableChip>
    );
};

export default EmployerProfileId;
