import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './help-modal-title.less';

const TrlKeys = {
    title: 'supernova.modal.help.title',
    subtitleEmployer: 'supernova.modal.help.subtitle.employer',
    subtitle: 'supernova.modal.help.subtitle',
    subtitleZp: 'personalManager.region.zarplata',
};

const HelpTitleComponent: TranslatedComponent = ({ trls }) => {
    return <div className={styles.titleContainer}>{trls[TrlKeys.title]}</div>;
};
const HelpTitle = translation(HelpTitleComponent);

const HelpSubTitleComponent: TranslatedComponent<{ isContactPhoneEnabled?: boolean }> = ({
    trls,
    isContactPhoneEnabled,
}) => {
    const isZarplata = useIsZarplataPlatform();
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;

    if (isZarplata && isContactPhoneEnabled) {
        return <div className={styles.titleContainer}>{trls[TrlKeys.subtitleZp]}</div>;
    }

    return (
        <div className={styles.titleContainer}>
            {isEmployer && !isZarplata ? trls[TrlKeys.subtitleEmployer] : trls[TrlKeys.subtitle]}
        </div>
    );
};
const HelpSubTitle = translation(HelpSubTitleComponent);

export { HelpTitle, HelpSubTitle };
