import { Primitive } from 'type-fest';

import responseFromCpahhruFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/cpahhru/response_from_cpahhru_form_submit';
import urlParser from 'bloko/common/urlParser';

import { isString } from 'Utils/Utils';
import { pushCPAConversion } from 'src/utils/analytics/pushCPAConversion';
import { replaceTemporaryStorageData, type StateItem } from 'src/utils/temporaryStorageUtils';

import {
    CPA_OFFER_ALIAS_ID,
    CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
    CPA_STORE_FIELD_BY_VACANCY_ID,
    YA_DIRECT_STORE_FIELD_BY_VACANCY_ID,
    STORE_TIME,
    YA_DIRECT_VACANCY_TARGETING_FLOW_SETTINGS,
} from 'src/components/VacancyTargetingViewer/const';
import type {
    FromUrlToLocalParamsMapValues,
    FromUrlToLocalParamsMap,
    UrlParams,
} from 'src/components/VacancyTargetingViewer/types';
import { ReplaceTemporaryStorageCondition } from 'src/components/VacancyTargetingViewer/types';

export const isAdvVisit = (params: ReturnType<typeof urlParser>['params']): boolean =>
    YA_DIRECT_VACANCY_TARGETING_FLOW_SETTINGS.some((flow) =>
        Object.entries(flow).every((flowParam) => {
            const paramValue = params[flowParam[0]];

            return Array.isArray(paramValue)
                ? paramValue.includes(flowParam[1])
                : params[flowParam[0]] === flowParam[1];
        })
    );

export const getFirstFromParams = (params: Primitive | Primitive[]): Primitive => {
    return Array.isArray(params) ? params[0] : params;
};

export const sendCpaLeadConversion = (
    vacancyAreaId: number | string,
    vacancyId: number | string,
    tempStorageItem: StateItem | undefined,
    cpaType: 'VACANCY' | 'AREA'
): void => {
    const oid = tempStorageItem?.additionalParams?.[CPA_OFFER_ALIAS_ID];

    if (!vacancyAreaId || !vacancyId || !oid || !cpaType) {
        return;
    }

    responseFromCpahhruFormSubmit({ cpaType, vacancyId: Number(vacancyId), targetAreaId: Number(vacancyAreaId) });

    pushCPAConversion([
        'conversion',
        {
            id: `vr-cpa-${Date.now()}-${Math.random() * Math.pow(10, 17)}-${vacancyId}`,
            type: 'lead',
            offerAlias: oid,
            category: String(vacancyAreaId),
            brand: 'vr',
            isNewCustomer: true,
        },
    ]);
};

export const computeFirstParamsFromUrlParams = <T extends FromUrlToLocalParamsMap>(
    constMap: T,
    urlParams: UrlParams
): Record<FromUrlToLocalParamsMapValues, Primitive> => {
    return Object.entries(constMap).reduce(
        (result, currentValue) => {
            result[currentValue[1]] = getFirstFromParams(urlParams[currentValue[0]]);
            delete urlParams[currentValue[0]];

            return result;
        },
        {} as Record<FromUrlToLocalParamsMapValues, Primitive>
    );
};

const yaDirectTargetByVacancyIdCondition: ReplaceTemporaryStorageCondition = ({ params, urlParams }) => {
    const yaDirectTargetVacancyId = params.yaDirectTargetVacancyId;
    if (yaDirectTargetVacancyId && urlParams && isString(yaDirectTargetVacancyId) && isAdvVisit(urlParams)) {
        replaceTemporaryStorageData(YA_DIRECT_STORE_FIELD_BY_VACANCY_ID, STORE_TIME, yaDirectTargetVacancyId);
    }
};

const cpaTargetByVacancyIdCondition: ReplaceTemporaryStorageCondition = ({ params, features }) => {
    const { cpaTargetVacancyId, cpaOfferAliasId } = params;
    const cpaTargetByVacancyIdStoreTimeMs = features?.cpaTargetByVacancyIdStoreTimeMs;
    if (cpaTargetByVacancyIdStoreTimeMs && isString(cpaTargetVacancyId) && isString(cpaOfferAliasId)) {
        replaceTemporaryStorageData(
            CPA_STORE_FIELD_BY_VACANCY_ID,
            cpaTargetByVacancyIdStoreTimeMs as number,
            cpaTargetVacancyId,
            { [CPA_OFFER_ALIAS_ID]: cpaOfferAliasId }
        );
    }
};

const cpaTargetByVacancyAreaIdCondition: ReplaceTemporaryStorageCondition = ({ params, features }) => {
    const { cpaTargetVacancyAreaId, cpaOfferAliasId } = params;
    const cpaTargetByVacancyAreaIdStoreTimeMs = features?.cpaTargetByVacancyAreaIdStoreTimeMs;
    if (cpaTargetByVacancyAreaIdStoreTimeMs && isString(cpaTargetVacancyAreaId) && isString(cpaOfferAliasId)) {
        replaceTemporaryStorageData(
            CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
            cpaTargetByVacancyAreaIdStoreTimeMs as number,
            cpaTargetVacancyAreaId,
            { [CPA_OFFER_ALIAS_ID]: cpaOfferAliasId }
        );
    }
};

export const CONDITIONS_TO_REPLACE_FROM_TEMPORARY_STORAGE = [
    yaDirectTargetByVacancyIdCondition,
    cpaTargetByVacancyIdCondition,
    cpaTargetByVacancyAreaIdCondition,
];
