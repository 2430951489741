import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'supernova.notification.interview.practice.paid',
    second: 'supernova.notification.interview.practice.paid.service.link',
    link: 'supernova.notification.interview.practice.paid.fill.link',
};

const InterviewPractice: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification dataQa={DataQaInvariants.InterviewPracticePaid} {...notification}>
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.first], {
                '{0}': <NotificationLink to="/interview_practice">{trls[TrlKeys.second]}</NotificationLink>,
            })}
            <NotificationLink
                to={`/applicant-services/complete_resume_interview_practice/order/${
                    notification.params.orderCode || ''
                }`}
            >
                {trls[TrlKeys.link]}
            </NotificationLink>
        </NotificationSpacer>
    </Notification>
);

export default translation(InterviewPractice);
