import { HHTM_FROM_LABEL_SUITABLE_RESUMES } from 'Modules/constants/analytics';
import { updateUrl } from 'Modules/url';
import type { ResumeCardData } from 'src/models/resumeCard';

type Attrs = {
    id: ResumeCardData['_attributes']['id'];
    hash: ResumeCardData['_attributes']['hash'];
    topicId: ResumeCardData['topicId'];
    simhash?: ResumeCardData['_attributes']['sim_hash'];
    highlight?: string;
    isResponse?: boolean;
    vacancyId?: number;
};

type MakeResumeHrefFunction = (attrs: Attrs) => string;

export const makeResumeHref: MakeResumeHrefFunction = ({
    hash,
    id,
    topicId,
    simhash,
    highlight = '',
    isResponse,
    vacancyId,
}) => {
    const query: Record<string, string | number> = {};

    if (vacancyId) {
        query.vacancyId = vacancyId;

        if (simhash) {
            query.simhash = simhash;
        }

        if (!isResponse) {
            query.hhtmFromLabel = HHTM_FROM_LABEL_SUITABLE_RESUMES;
        }
    }

    if (isResponse && topicId) {
        query.t = topicId;
        query.resumeId = id;
    }
    return updateUrl(`/resume/${hash}?${highlight}`, query);
};
