import React from 'react';

import Link, { LinkAppearance } from 'bloko/blocks/link';

import Form from 'src/components/Form';
import { NavItem } from 'src/models/supernovaNaviMenu';

const LogoffSudoUser: React.VFC<NavItem> = ({ name, url, translations }) => {
    return (
        <div className="supernova-dropdown-static-option">
            <Form action={url}>
                <Link appearance={LinkAppearance.Pseudo} data-qa={`mainmenu_${name}`} type="submit">
                    {translations.name}
                </Link>
            </Form>
        </div>
    );
};

export default LogoffSudoUser;
