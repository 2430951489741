import vacancyBlockedVacancyLinkClick, {
    NotificationCode,
} from '@hh.ru/analytics-js-events/build/xhh/employer/notifications/vacancy_blocked_vacancy_link_click';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { VacancyBlockedReason } from 'src/components/Notifications/VacancyBlocked';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    goToVacancy: 'supernova.notification.employer.vacancy.blocked.go.to.vacancy',

    [VacancyBlockedReason.VacancyBlockedDuePaidServices]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.paid.services',
        advice: 'supernova.notification.employer.vacancy.blocked.due.paid.services.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueBadDescription]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.bad.description',
        advice: 'supernova.notification.employer.vacancy.blocked.due.bad.description.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueTwoInOne]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.two.in.one',
        advice: 'supernova.notification.employer.vacancy.blocked.due.two.in.one.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueTraining]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.training',
        advice: 'supernova.notification.employer.vacancy.blocked.due.training.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueCaptionMissing]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.caption.missing',
        advice: 'supernova.notification.employer.vacancy.blocked.due.caption.missing.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueModerator]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.moderator',
        advice: 'supernova.notification.employer.vacancy.blocked.due.moderator.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueRelocateFromBelarus]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.relocate.from.belarus',
        advice: 'supernova.notification.employer.vacancy.blocked.due.relocate.from.belarus.advice',
    },
    [VacancyBlockedReason.VacancyBlockedDueReplacement]: {
        main: 'supernova.notification.employer.vacancy.blocked.due.replacement',
        advice: 'supernova.notification.employer.vacancy.blocked.due.replacement.advice',
    },
};

const VacancyBlocked: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const notificationCode = notification.notificationCode as NotificationCode;

    return (
        <Notification {...notification}>
            <NotificationSpacer>
                {formatToReactComponent(trls[TrlKeys[notificationCode].main], {
                    '{0}': (
                        <Text Element="span" strong>
                            {notification.params.vacancyName}
                        </Text>
                    ),
                })}
            </NotificationSpacer>
            {notificationCode !== VacancyBlockedReason.VacancyBlockedDueModerator && (
                <>
                    <NotificationSpacer>{trls[TrlKeys[notificationCode].advice]}</NotificationSpacer>
                    <NotificationLink
                        onClick={() => {
                            vacancyBlockedVacancyLinkClick({
                                notificationCode,
                                notificationType: 'supernova_notification',
                            });
                        }}
                        target="_blank"
                        to={`/vacancy/${notification.params.vacancyId}`}
                    >
                        {trls[TrlKeys.goToVacancy]}
                    </NotificationLink>
                </>
            )}
        </Notification>
    );
};

export default translation(VacancyBlocked);
