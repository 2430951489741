import { FC, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';
import FormItem from 'bloko/blocks/form/FormItem';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    addField: 'resumesSearch.advanced.addField',
};

const Wrapper: FC<{ autoInvite?: boolean } & PropsWithChildren> = ({ autoInvite, children }) => {
    if (autoInvite) {
        return <>{children}</>;
    }

    return (
        <Column xs="4" s="8" m="3" l="4">
            {children}
        </Column>
    );
};

const AddButton: TranslatedComponent<{ addQuery: () => void; autoInvite?: boolean }> = ({
    trls,
    addQuery,
    autoInvite,
}) => {
    return (
        <Wrapper autoInvite={autoInvite}>
            <FormItem>
                <Link appearance={LinkAppearance.Pseudo} onClick={addQuery} data-qa="resumes-search-wizard-add-item">
                    {trls[TrlKeys.addField]}
                </Link>
            </FormItem>
        </Wrapper>
    );
};

export default translation(AddButton);
