import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum ContactPhonePostfix {
    City = 'city',
    Mobile = 'mobile',
    Msk = 'msk',
    Other = 'other',
    Regions = 'regions',
    Spb = 'spb',
}

export interface ContactPhoneItem {
    country: string;
    city: string;
    number: string;
    postfix: ContactPhonePostfix;
}

type ContactPhones = ContactPhoneItem[];

export default autoGeneratedReducer<ContactPhones>([]);
