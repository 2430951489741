import { Store } from 'redux';

import { AppStore } from 'src/app/store';

// eslint-disable-next-line @typescript-eslint/no-empty-function
let promiseResolveFunction: (store: Store) => void = () => {};
const storeInstancePromise: Promise<Store<AppStore>> = new Promise((resolve) => (promiseResolveFunction = resolve));

export const setStore = promiseResolveFunction;

export const getStore = (): Promise<Store<AppStore>> => storeInstancePromise;
