import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CountryId } from 'src/models/countryId';
import { EmployerCategoryCode } from 'src/models/employerCategories';

export enum EmployerSuspiciousState {
    NotSuspicious = 'NOT_SUSPICIOUS',
    Suspicious = 'SUSPICIOUS',
    TrustedSuspicious = 'TRUSTED_SUSPICIOUS',
    SuspiciousRemovalPending = 'SUSPICIOUS_REMOVAL_PENDING',
}

export enum EmployerState {
    Approved = 'APPROVED',
    Disabled = 'DISABLED',
    Discard = 'DISCARDED',
    New = 'NEW',
    NotVerified = 'NOT_VERIFIED',
    Unregistered = 'UNREGISTERED',
    Waiting = 'WAITING',
}

interface EmployerStatusProps {
    category: EmployerCategoryCode | '';
    state: EmployerState | null;
    countryId: CountryId | null;
    suspiciousState: EmployerSuspiciousState;
    accepted: boolean;
    hasDocuments: boolean;
}

export default autoGeneratedReducer<EmployerStatusProps>({
    category: '',
    state: null,
    countryId: null,
    suspiciousState: EmployerSuspiciousState.NotSuspicious,
    accepted: false,
    hasDocuments: false,
});
