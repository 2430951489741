import { FC, useRef, useEffect } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';
import overlayMenuAnalytics from 'src/components/SupernovaOverlayMenu/analytics';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const DefaultMenuItem: FC<MenuItemRendererProps> = ({ menuItem }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const menuItemRef = useRef(null);
    const handleClick = useTrackClicks(menuItem.name, menuItem.trackClicks, menuItem.analytics);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && menuItemRef.current) {
            overlayMenuAnalytics.overlayMenuItemShown(menuItemRef.current, menuItem.name);
        }
    }, [employerMainPageAnalyticsEnabled, menuItem.name]);

    return (
        <div ref={menuItemRef} className="supernova-navi-item supernova-navi-item_lvl-1">
            {menuItem.active ? (
                menuItem.translations.name
            ) : (
                <Link
                    isSeoLink={menuItem.external}
                    to={menuItem.url}
                    data-qa={`mainmenu_${menuItem.name}`}
                    className="supernova-link supernova-link_dimmed"
                    onClick={handleClick}
                >
                    {menuItem.translations.name}
                </Link>
            )}
        </div>
    );
};

export default DefaultMenuItem;
