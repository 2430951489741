import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import SearchVisibilityList from 'src/models/resumeVisibility/searchVisibilityList.types';

export const RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST = 'RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST';
export const RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST_FETCHING =
    'RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST_FETCHING';

export const INITIAL_STATE: SearchVisibilityList = {
    items: [],
    total: 0,
    isFetching: false,
};

interface PayloadTypes {
    [RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST_FETCHING]: void;
    [RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST]: SearchVisibilityList;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumeVisibilitySearchResultsBlacklistFetching = actionCreator(
    RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST_FETCHING
);
export const resumeVisibilitySearchResultsBlacklist = actionCreator(RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST);

export default createReducer<SearchVisibilityList, PayloadTypes>(INITIAL_STATE, {
    [RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST_FETCHING]: (state) => {
        return { ...state, isFetching: true };
    },
    [RESUME_VISIBILITY_SEARCH_RESULTS_BLACKLIST]: (state, action) => {
        const payload = action.payload ?? INITIAL_STATE;
        payload.items = payload.items || [];
        return { ...state, ...payload, isFetching: false };
    },
});
