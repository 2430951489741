import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

interface NotificationTextProps {
    multiline?: boolean;
}

const NotificationText: FC<NotificationTextProps & PropsWithChildren> = ({ children, multiline }) => {
    return (
        <div
            className={classnames('supernova-notification-text', {
                'supernova-notification-text_multiline': multiline,
            })}
            data-qa="notification-content"
        >
            {children}
        </div>
    );
};

export default NotificationText;
