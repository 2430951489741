import { useState, FC, MutableRefObject, RefObject, PropsWithChildren, useContext } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import AddButton from 'src/components/ResumeAdvancedSearch/SearchQueries/AddButton';
import SearchQuery from 'src/components/ResumeAdvancedSearch/SearchQueries/SearchQuery';
import ShowConditionsCheckbox from 'src/components/ResumeAdvancedSearch/SearchQueries/ShowConditions';

const Wrapper: FC<
    {
        isNaviSearch: boolean;
        autoInvite?: boolean;
        isModal?: boolean;
    } & PropsWithChildren
> = ({ isNaviSearch, autoInvite, children, isModal }) => {
    if (isNaviSearch || autoInvite) {
        return <>{children}</>;
    }

    return (
        <Column xs="4" s="8" m="8" l={isModal ? '10' : '11'}>
            {children}
        </Column>
    );
};

interface NaviSearchProps {
    isNaviSearch: true;
    addQueryRef: MutableRefObject<(() => void) | undefined>;
    formRef?: RefObject<HTMLFormElement>;
}

interface AdvancedSearchProps {
    isNaviSearch: false;
}

const SearchQueries: FC<(NaviSearchProps | AdvancedSearchProps) & { autoInvite?: boolean; isMagritte?: boolean }> = (
    props
) => {
    const searchQueries = useSelector((state) => state.searchQueries);
    const autoInviteQueries = useSelector((state) => state.autoInvite?.filters.searchQueries);
    const isEmployer = useSelector(({ userType }) => UserType.Employer === userType);
    const [queries, setQueries] = useState(() => {
        const queriesList = props.autoInvite && autoInviteQueries ? autoInviteQueries : searchQueries?.values;
        if (queriesList && queriesList.length > 0) {
            return queriesList;
        }

        return searchQueries?.default ? [searchQueries.default] : [];
    });
    const [fromSuggest, setFromSuggest] = useState(false);
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    if (!searchQueries) {
        return null;
    }

    const addQuery = () => {
        const lastElementId = queries[queries.length - 1].id;
        setQueries([...queries, { ...searchQueries.default, id: lastElementId + 1 }]);
        autoInviteHandlerFormChange?.();
    };
    if (props.isNaviSearch) {
        props.addQueryRef.current = addQuery;
    }
    const removeQuery = (id: number) => {
        setQueries(queries.filter((query) => query.id !== id));
        autoInviteHandlerFormChange?.();
    };

    return (
        <>
            <Wrapper isNaviSearch={props.isNaviSearch} autoInvite={props.autoInvite} isModal={props.autoInvite}>
                {queries.map((searchQuery) => (
                    <SearchQuery
                        key={searchQuery.id}
                        query={searchQuery}
                        queries={queries}
                        onRemove={removeQuery}
                        setFromSuggest={setFromSuggest}
                        isNaviSearch={props.isNaviSearch}
                        autoInvite={props.autoInvite}
                        formRef={props.isNaviSearch ? props.formRef : undefined}
                        isMagritte={props.isMagritte}
                    />
                ))}
                {fromSuggest && !props.autoInvite && <input type="hidden" name="from" value="suggest_post" />}
            </Wrapper>
            {!props.isNaviSearch && (
                <>
                    <AddButton addQuery={addQuery} autoInvite={props.autoInvite} />
                    {!props.autoInvite && (
                        <>
                            {isEmployer && <ShowConditionsCheckbox />}
                            <VSpacing base={6} />
                            {isEmployer && (
                                <>
                                    <div className="horizontal-divider" />
                                    <VSpacing base={6} />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default SearchQueries;
