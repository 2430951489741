import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

export const DEFAULT_ERROR = 'DEFAULT_ERROR';

const TrlKeys = {
    error: 'lux.error',
};

const DefaultError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.error]}</>;

export default {
    Element: translation(DefaultError),
    kind: 'error',
    autoClose: true,
};
