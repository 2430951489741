import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Employer, EmployerBillingSummary } from 'src/models/oldEmployer/employerBillingSummary';

export interface EmployerBillingInfo {
    billingSummary?: EmployerBillingSummary;
    employer?: Employer;
    clickme?: {
        availableBalance: number;
        balance: number;
        bonusBalance: number;
        realBalance: number;
        reservedBalance: number;
    };
    talantixTariffDaysLeft?: number;
    quotas?: {
        vacanciesQuotas?: number;
        contactsQuotas?: number;
    };
}

export default autoGeneratedReducer<EmployerBillingInfo>({});
