export const FormState = {
    Saving: 'SAVING',
    Saved: 'SAVED',
    AlreadySaved: 'ALREADY_SAVED',
    Error: 'ERROR',
} as const;

export type FormStateType = (typeof FormState)[keyof typeof FormState];

export const SaveSearchError = {
    WrongEmail: 'WrongEmail',
    DuplicateEmail: 'DuplicateEmail',
} as const;

export type SaveSearchErrorType = (typeof SaveSearchError)[keyof typeof SaveSearchError];
