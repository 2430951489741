import { FC, PropsWithChildren, SyntheticEvent } from 'react';

import {
    useBreakpoint,
    Card,
    Avatar,
    Button,
    Badge,
    ComponentWithBadge,
    Cell,
    CellText,
    CellRightLabel,
} from '@hh.ru/magritte-ui';
import {
    PhoneWaveOutlinedSize24,
    QuestionCircleOutlinedSize24,
    BubbleRoundDoubleOutlinedSize24,
    ChevronRightOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { MailSolidSize24 } from '@hh.ru/magritte-ui/service';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useSupportChatik from 'src/components/SupportChatPlate/useSupportChatik';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import styles from './help-modal-button-links.less';

const TrlKeys = {
    employerId: 'supernova.modal.help.employerId',
    supportChatContact: 'supernova.modal.help.supportChat.contact',
};

const ICONS = {
    employerHelp: <QuestionCircleOutlinedSize24 />,
    writeToUs: <MailSolidSize24 />,
    supportChat: <BubbleRoundDoubleOutlinedSize24 />,
    orderCall: <PhoneWaveOutlinedSize24 />,

    applicantHelp: <QuestionCircleOutlinedSize24 />,
    applicantHelpOther: <QuestionCircleOutlinedSize24 />,
    feedback: <MailSolidSize24 />,
    anonHelp: <QuestionCircleOutlinedSize24 />,
    copiny: <QuestionCircleOutlinedSize24 />,
};

const HelpModalButtonLink: TranslatedComponent<{
    item: NavItem;
    handleCloseModal: (event?: SyntheticEvent) => void;
    onClose?: () => void;
}> = ({ trls, item, handleCloseModal, onClose }) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const { isMobile } = useBreakpoint();

    const closeModalAndSupernovaDropdown = () => {
        onClose?.();
        handleCloseModal();
    };
    const { dataQa, handleChatikClick, unreadCount } = useSupportChatik('menu_support', closeModalAndSupernovaDropdown);

    const { name, trackClicks, analytics, url, external, translations } = item;

    const trackClick = useTrackClicks(name, trackClicks, analytics);

    const HelpModalButtonAvatar = (
        <Avatar size={48} mode="icon" icon={ICONS[name as keyof typeof ICONS]} style="secondary" aria-label={name} />
    );

    if (name === 'supportChat') {
        if (!isEmployer && !isMobile) {
            return (
                <Card borderRadius={24} padding={12} stretched showBorder>
                    <div className={styles.helpCardContainer}>
                        <Cell left={HelpModalButtonAvatar}>
                            <CellText>{translations.name}</CellText>
                        </Cell>
                        <ComponentWithBadge
                            badge={unreadCount ? <Badge size="medium">{`${unreadCount}`}</Badge> : undefined}
                            offset={-4}
                        >
                            <Button onClick={handleChatikClick} mode="secondary" data-qa={dataQa}>
                                {trls[TrlKeys.supportChatContact]}
                            </Button>
                        </ComponentWithBadge>
                    </div>
                </Card>
            );
        }

        const Wrapper: FC<PropsWithChildren> = ({ children }) =>
            isMobile ? (
                <>{children}</>
            ) : (
                <ComponentWithBadge
                    badge={unreadCount ? <Badge size="medium">{`${unreadCount}`}</Badge> : undefined}
                    offset={6}
                    stretched
                >
                    {children}
                </ComponentWithBadge>
            );
        return (
            <Wrapper>
                <Card
                    data-qa={dataQa}
                    onClick={handleChatikClick}
                    actionCard
                    borderRadius={24}
                    padding={12}
                    stretched
                    showBorder
                >
                    <Cell
                        left={HelpModalButtonAvatar}
                        right={
                            isMobile ? (
                                <CellRightLabel badge={unreadCount ? <Badge>{`${unreadCount}`}</Badge> : undefined} />
                            ) : undefined
                        }
                    >
                        <CellText>{translations.name}</CellText>
                    </Cell>
                </Card>
            </Wrapper>
        );
    }

    return (
        <Card
            Element={Link}
            to={url}
            data-qa={`mainmenu_${name}`}
            target={external ? '_blank' : undefined}
            onClick={trackClick}
            actionCard
            borderRadius={24}
            padding={12}
            stretched
            showBorder
        >
            <Cell
                left={HelpModalButtonAvatar}
                right={isMobile ? <ChevronRightOutlinedSize24 initial="secondary" /> : undefined}
            >
                <CellText>{translations.name}</CellText>
            </Cell>
        </Card>
    );
};

export default translation(HelpModalButtonLink);
