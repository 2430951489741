import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    applicantText: 'supernova.search.submit',
};

const sendAnalytics = () => Analytics.sendHHEventButtonClick('search_button_header');

const SearchButton: TranslatedComponent<{ stretched?: boolean }> = ({ trls, stretched }) => {
    return (
        <Button
            mode="primary"
            style="accent"
            type="submit"
            onClick={sendAnalytics}
            data-qa="search-button"
            stretched={stretched}
        >
            {trls[TrlKeys.applicantText]}
        </Button>
    );
};

export default translation(SearchButton);
