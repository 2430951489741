import React from 'react';

import Form from 'src/components/Form';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption, { OptionHighlight } from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';

const LogoffUser: React.VFC<NavItem> = ({ name, trackClicks, translations, url }) => {
    return (
        <SupernovaDropdownSection mainContent>
            <Form action={url}>
                <SupernovaDropdownOption name={name} trackClicks={trackClicks} highlight={OptionHighlight.Warning}>
                    <span>{translations.name}</span>
                </SupernovaDropdownOption>
            </Form>
        </SupernovaDropdownSection>
    );
};

export default LogoffUser;
