/**
 * Возвращает promise который резолвится как только
 * станет доступным contentWindow iframe'а.
 *
 * Promise резолвится в contentWindow.
 *
 * @param {Element} iframe iframe-элемент
 * @param {Number} checkInterval
 *
 * @returns {Promise}
 *
 * @type {Function}
 * @exports Utils/waitIframeContent
 */
const waitIframeContent = (iframe, checkInterval = 100) =>
    new Promise((resolve) => {
        const checkIframeContent = () => {
            if (!iframe.contentWindow) {
                setTimeout(checkIframeContent, checkInterval);
            } else {
                resolve({ contentWindow: iframe.contentWindow });
            }
        };

        iframe.addEventListener('load', checkIframeContent);
    });

export default waitIframeContent;
