import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import routePaths from 'src/app/routePaths';
import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    first: 'applicant.callOnVacancy.notification.text1',
    second: 'applicant.callOnVacancy.notification.text2',
    link: 'applicant.callOnVacancy.notification.link',
};

const UserNotificationsApplicantCalling: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => (
    <Notification iconKind={SupernovaUserNotificationsIconKind.Empty} {...notification}>
        <NotificationSpacer>
            {formatToReactComponent(trls[TrlKeys.first], {
                '{0}': <Text strong>{notification.params.vacancyName}</Text>,
            })}
            <NotificationSpacer>{trls[TrlKeys.second]}</NotificationSpacer>
            <NotificationLink to={routePaths.applicantNegotiations}>{trls[TrlKeys.link]}</NotificationLink>
        </NotificationSpacer>
    </Notification>
);

export default translation(UserNotificationsApplicantCalling);
