import { CompensationMode, CompensationFrequency } from '@hh.ru/types-hh-microcore';

interface CompensationFieldsDictionary<T> {
    id: T;
    text: string;
}

export interface VacancyCompensationFieldsDictionary {
    mode: CompensationFieldsDictionary<CompensationMode>[];
    frequency: CompensationFieldsDictionary<CompensationFrequency>[];
}

export const DEFAULT_VACANCY_COMPENSATION_FIELDS_DICTIONARY = {
    mode: [],
    frequency: [],
};
