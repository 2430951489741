import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AccountHistoryTab {
    href: string;
    name: string;
    selected: boolean;
}

export interface AccountHistoryActionsDataProps {
    tabs: AccountHistoryTab[];
}

export default autoGeneratedReducer<AccountHistoryActionsDataProps>(null);
