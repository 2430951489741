import { createSlice } from 'src/models/applicant/resume/lib/slice';
import { ReducerAction } from 'src/models/applicant/resume/lib/slice/types';
import { ApplicantResumeDTO } from 'src/models/applicant/resume/types/resume';

const applicantResumeSlice = createSlice({
    initialState: {} as ApplicantResumeDTO, // eslint-disable-line no-empty-pattern
    reducers: {
        initApplicantResume: (_, { payload }: ReducerAction<ApplicantResumeDTO>) => ({ ...payload }),
        setApplicantResumeFields: (state, { payload }: ReducerAction<ApplicantResumeDTO>) => ({
            ...state,
            ...payload,
        }),
    },
});

export const { initApplicantResume, setApplicantResumeFields } = applicantResumeSlice.actions;

export const applicantResume = applicantResumeSlice.reducer;
