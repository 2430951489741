import Analytics from '@hh.ru/analytics-js';

import { ViewDurationProps, ViewDurationEvent } from 'src/models/viewDuration';

let startTimestamp: number;
let isFirstDurationEvent = true; // detect user, who return on open tab
let globalAnalyticsParams = {};

// map some data
const keysDuration = {
    [ViewDurationEvent.VacancyViewDuration]: 'vacancy_view_duration',
    [ViewDurationEvent.ResumeViewDuration]: 'resume_view_duration',
};

// send analytic event
export const sendViewDutaionEvent: (props: ViewDurationProps) => void = ({ eventType, params }) => {
    // calc params and event data
    const endTimestamp = performance.now();
    const eventKey = keysDuration[eventType];
    const eventData = {
        ...globalAnalyticsParams,
        [eventKey]: Math.ceil(endTimestamp - startTimestamp),
        isFirst: isFirstDurationEvent,
        ...params,
    };
    Analytics.sendHHEvent(eventType, eventData);
    // rewrite some params
    startTimestamp = 0;
    isFirstDurationEvent = false;
};

// need delete hablder after spa
let visibilityChangeListener: () => void = () => null;

export const addTabListener: (props: ViewDurationProps) => void = (props) => {
    // if we don't have rights, for example
    if (!props) {
        return;
    }
    const { eventType, params } = props;
    // if user one tab at once
    if (document.visibilityState === 'visible') {
        startTimestamp = performance.now();
    }
    globalAnalyticsParams = {
        ...window?.globalVars?.analyticsParams,
        originalRequestId: window?.globalVars?.requestId,
    };

    // but if user use target blank, need check visibility event
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            startTimestamp = performance.now();
        }
    });

    // close tab
    visibilityChangeListener = () => {
        if (document.visibilityState === 'hidden') {
            sendViewDutaionEvent({ eventType, params });
        }
    };
    document.addEventListener('visibilitychange', visibilityChangeListener);
};

export const removeTabListener: () => void = () => {
    document.removeEventListener('visibilitychange', visibilityChangeListener);
    isFirstDurationEvent = true;
};
