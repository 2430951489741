import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { NavItems } from 'src/models/supernovaNaviMenu';

interface EmployerSettingsMenu {
    employerManagersLink: string;
    employerManagerName: string;
    menuItems: NavItems;
}

export default autoGeneratedReducer<EmployerSettingsMenu>({
    employerManagersLink: '/employer/managers',
    menuItems: [],
    employerManagerName: '',
});
