import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Resume, ResumeAttributes } from 'src/models/resume/resume.types';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { Phone } from 'src/models/resume/resumeCommon.types';

export const RESUMES_SET_TO_UPDATE_VALUE = 'RESUMES_SET_TO_UPDATE_VALUE';

type ResumeItem = Pick<
    Resume,
    'hiddenFields' | 'humanDatesRules' | 'phone' | 'showUpdateBlock' | 'specialization' | 'title' | 'updatedHuman'
>;

export type ApplicantResumeItem = ResumeItem & {
    _attributes: ResumeAttributes;
    toUpdate: {
        value: number;
    };
    accessType?: [{ string: ResumeAccessType }];
    renewalTime?: {
        nearestIntervalEndTime: null | number;
        nearestIntervalStartTime: null | number;
    };
    phone?: Phone[];
    updatedHuman: number;
};
export type ApplicantResumeList = ApplicantResumeItem[];
export const INITIAL_STATE: ApplicantResumeList = [];

interface PayloadTypes {
    [RESUMES_SET_TO_UPDATE_VALUE]: {
        hash: string;
        value: number;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumesSetToUpdateValueAction = actionCreator(RESUMES_SET_TO_UPDATE_VALUE);

export default createReducer<ApplicantResumeList, PayloadTypes>(INITIAL_STATE, {
    [RESUMES_SET_TO_UPDATE_VALUE]: (state, action) => {
        const { hash, value } = action.payload;
        const newState = state.map((resume: ApplicantResumeItem) => {
            if (resume._attributes.hash === hash) {
                return {
                    ...resume,
                    // поле toUpdate из питона имеет структуру вида
                    // {value: 12804, count: 4, translation: "hours", ending: 1}
                    // но в коде используем только value, так что все остальные поля просто стираю
                    toUpdate: { value },
                };
            }
            return resume;
        });
        return newState;
    },
});
