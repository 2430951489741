import React, { PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AreaSwitcher from 'src/components/AreaSwitcher';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaDropdownSectionHeader from 'src/components/SupernovaMainMenu/SupernovaDropdownSectionHeader';

interface MobileScreenProps {
    currentScreen: string;
    setDefaultScreen: () => void;
    close: () => void;
}

const TrlKeys = {
    areaSwitcher: 'supernova.navi.areaSwitcher',
};

const MobileScreen: TranslatedComponent<MobileScreenProps & NavItem> = ({
    currentScreen,
    name,
    setDefaultScreen,
    subItems,
    close,
    trls,
}) => {
    const Wrapper = React.useCallback<(args: { name: string; header: string } & PropsWithChildren) => ReactElement>(
        ({ children, header, name }) => (
            <div
                className={classnames('supernova-dropdown-mobile-screen', {
                    'supernova-dropdown-mobile-screen_hidden': name !== currentScreen,
                })}
            >
                <SupernovaDropdownSection>
                    <SupernovaDropdownSectionHeader onClick={setDefaultScreen}>{header}</SupernovaDropdownSectionHeader>
                </SupernovaDropdownSection>
                {children}
            </div>
        ),
        [currentScreen, setDefaultScreen]
    );

    const linksItems = subItems?.find((item) => item.name === 'links');
    const buttonsItems = subItems?.find((item) => item.name === 'buttons');
    const navItems: NavItem[] = [
        ...(subItems || []),
        ...(linksItems?.subItems || []),
        ...(buttonsItems?.subItems || []),
    ];

    switch (name) {
        case 'areaSwitcher':
            return (
                <Wrapper header={trls[TrlKeys.areaSwitcher]} name={name}>
                    <SupernovaDropdownSection>
                        <Column xs="4" s="8" m="12" l="16">
                            <VSpacing base={6} />
                            <AreaSwitcher isDropdown />
                        </Column>
                    </SupernovaDropdownSection>
                </Wrapper>
            );
        case 'mainContent':
            return (
                <React.Fragment>
                    {navItems?.map((item) => {
                        switch (item.name) {
                            case 'help':
                            case 'anonSupport':
                                return (
                                    <Wrapper key={item.name} header={item.translations.name} name={item.name}>
                                        <DropdownContent items={item.subItems} onClose={close} />
                                    </Wrapper>
                                );
                            case 'support':
                                return (
                                    <Wrapper header={item.translations.name} name={item.name}>
                                        <DropdownContent item={item} onClose={close} />
                                    </Wrapper>
                                );
                            case 'userNotifications':
                                return (
                                    <Wrapper key={item.name} header={item.translations.name} name={item.name}>
                                        <DropdownContent
                                            item={item}
                                            visible={currentScreen === 'userNotifications'}
                                            onClose={close}
                                        />
                                    </Wrapper>
                                );
                            default:
                                if (!item.dropdown) {
                                    return null;
                                }

                                return (
                                    <Wrapper key={item.name} header={item.translations.name} name={item.name}>
                                        <SupernovaDropdownSection mainContent>
                                            <DropdownContent items={item.subItems} />
                                        </SupernovaDropdownSection>
                                    </Wrapper>
                                );
                        }
                    })}
                </React.Fragment>
            );
        default:
            return null;
    }
};

export default translation(MobileScreen);
