import { ValueOf } from 'type-fest';

// Общие ключи для Criteria и NovaFilters
export const CommonKeys = {
    Area: 'area',
    AcceptTemporary: 'accept_temporary',
    Citizenship: 'citizenship',
    District: 'district',
    DriverLicenseTypes: 'driver_license_types',
    Gender: 'gender',
    Employment: 'employment',
    Education: 'education',
    EducationLevel: 'education_level',
    Experience: 'experience',
    ExcludedText: 'excluded_text',
    FilterExpIndustry: 'filter_exp_industry',
    FilterExpPeriodForIndustry: 'filter_exp_period',
    JobSearchStatus: 'job_search_status',
    Industry: 'industry',
    Language: 'language',
    Label: 'label',
    Metro: 'metro',
    PartTime: 'part_time',
    ProfessionalRole: 'professional_role',
    Relocation: 'relocation',
    Resume: 'resume',
    SearchDebug: 'search_debug',
    Salary: 'salary',
    Schedule: 'schedule',
    SearchField: 'search_field',
    Skill: 'skill',
    University: 'university',
    VacancyId: 'vacancy_id',
    VerifiedSkills: 'verified_skills',
    VerifiedLanguages: 'verified_languages',
    WorkTicket: 'work_ticket',
} as const;

/**
 * Названия полей критерии
 * Объединенные для вакансий и резюме
 */
export const CriteriaKey = {
    ...CommonKeys,
    AgeFrom: 'age_from',
    AgeTo: 'age_to',
    BottomLeftLat: 'bottom_left_lat',
    BottomLeftLng: 'bottom_left_lng',
    Clusters: 'clusters',
    ControlFlag: 'control_flag',
    CurrencyCode: 'currency_code',
    DateFrom: 'date_from',
    DateTo: 'date_to',
    EmployerId: 'employer_id',
    EmployerManagerId: 'employer_manager_id',
    ExpCompanySize: 'exp_company_size',
    ExpIndustry: 'exp_industry',
    ExpPeriod: 'exp_period',
    Folder: 'folder',
    GeocodeType: 'geocode_type',
    Geohash: 'geohash',
    Height: 'height',
    IsAutosearch: 'L_is_autosearch',
    IsDefaultArea: 'isDefaultArea',
    ItemsOnPage: 'items_on_page',
    Lenient: 'L_lenient',
    Logic: 'logic',
    NoMagic: 'no_magic',
    OnlyWithAge: 'only_with_age',
    OnlyWithGender: 'only_with_gender',
    OnlyWithPhoto: 'only_with_photo',
    OnlyWithSalary: 'only_with_salary',
    OrderBy: 'order_by',
    Page: 'page',
    Pos: 'pos',
    Precision: 'precision',
    RecommendedByUid: 'recommended_by_uid',
    SalaryFrom: 'salary_from',
    SalaryTo: 'salary_to',
    SavedSearchId: 'saved_search_id',
    SearchPeriod: 'search_period',
    SearchSessionId: 'search_session_id',
    Specialization: 'specialization',
    Text: 'text',
    TopRightLat: 'top_right_lat',
    TopRightLng: 'top_right_lng',
    Width: 'width',
    CurrentEmployerId: 'current_employer_id',
    Department: 'department',
    HiddenFilters: 'hidden_filters',
    Internship: 'internship',
} as const;

export type CriteriaKey = ValueOf<typeof CriteriaKey>;

export enum SearchFieldOption {
    Name = 'name',
    CompanyName = 'company_name',
    Description = 'description',
}

export enum OrderByOption {
    PublicationTime = 'publication_time',
    SalaryDesc = 'salary_desc',
    SalaryAsc = 'salary_asc',
    Relevance = 'relevance',
}

export enum ExperienceOption {
    Between1And3 = 'between1And3',
    Between3And6 = 'between3And6',
    MoreThan6 = 'moreThan6',
    NoExperience = 'noExperience',
}

export enum PartTimeOption {
    EmploymentProject = 'employment_project',
    EmploymentPart = 'employment_part',
    TemporaryJobTrue = 'temporary_job_true',
    FromFourToSixHoursInADay = 'from_four_to_six_hours_in_a_day',
    OnlySaturdayAndSunday = 'only_saturday_and_sunday',
    StartAfterSixteen = 'start_after_sixteen',
}

export enum FilterExpPeriodOption {
    AllTime = 'all_time',
    LastYear = 'last_year',
    LastThreeYears = 'last_three_years',
    LastSixYears = 'last_six_years',
}
