import { useRef } from 'react';

import Link from 'bloko/blocks/link';
import Notification from 'bloko/blocks/notificationManager/Notification';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    title: 'employer.vacancyDraft.saveNotificaition.title',
    content: 'employer.vacancyDraft.saveNotificaition.text',
    link: 'employer.vacancyDraft.saveNotificaition.vacancies',
};

const VacancyDraftSaved: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    templateKey,
    ...notification
}) => {
    const closeNotificationRef = useRef<VoidFunction>(null);
    return (
        <Notification kind={NotificationKind.Ok} autoClose closeFuncRef={closeNotificationRef} {...notification}>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            {trls[TrlKeys.content]}
            {NON_BREAKING_SPACE}
            <Link onClick={() => closeNotificationRef.current?.()} href="/employer/vacancies">
                {trls[TrlKeys.link]}
            </Link>
        </Notification>
    );
};

export default translation(VacancyDraftSaved);
