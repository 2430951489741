import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';
import { Criteria } from 'src/models/vacancySearchCriteria';

import { Suggests } from 'src/models/vacancySearch/suggests.types';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import { VacancySearchQueries } from 'src/models/vacancySearch/vacancySearchQueries.types';

const ADD_MORE_VACANCIES = 'ADD_MORE_VACANCIES';
const REPLACE_VACANCY_SEARCH_RESULT = 'REPLACE_VACANCY_SEARCH_RESULT';
const SET_IS_LOADING_NEW_VACANCIES = 'SET_IS_LOADING_NEW_VACANCIES';
const SET_IS_LOADING_MORE_VACANCIES = 'SET_IS_LOADING_MORE_VACANCIES';
const SET_IS_ERROR = 'SET_IS_ERROR';
const SET_FAILED_REQUEST_PARAMS = 'SET_FAILED_REQUEST_PARAMS';
const REPLACE_VACANCIES = 'REPLACE_VACANCIES';
const SAVE_AUTO_SEARCH = 'SAVE_AUTO_SEARCH';

interface PayloadTypes {
    [ADD_MORE_VACANCIES]: { vacancies: VacancySearchItem[]; paging?: Paging };
    [REPLACE_VACANCIES]: { vacancies: VacancySearchItem[]; paging?: Paging; totalResults: number };
    [REPLACE_VACANCY_SEARCH_RESULT]: VacancySearchResult;
    [SET_IS_LOADING_NEW_VACANCIES]: boolean;
    [SET_IS_LOADING_MORE_VACANCIES]: boolean;
    [SET_IS_ERROR]: boolean;
    [SET_FAILED_REQUEST_PARAMS]: Partial<Criteria>;
    [SAVE_AUTO_SEARCH]: string;
}
const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const addMoreVacancies = actionHelper(ADD_MORE_VACANCIES);
export const replaceVacancies = actionHelper(REPLACE_VACANCIES);
export const replaceVacancySearchResult = actionHelper(REPLACE_VACANCY_SEARCH_RESULT);
export const setIsLoadingNewVacancies = actionHelper(SET_IS_LOADING_NEW_VACANCIES);
export const setIsLoadingMoreVacancies = actionHelper(SET_IS_LOADING_MORE_VACANCIES);
export const setIsError = actionHelper(SET_IS_ERROR);
export const setFailedRequestParams = actionHelper(SET_FAILED_REQUEST_PARAMS);
export const saveAutoSearch = actionHelper(SAVE_AUTO_SEARCH);

export interface Fixes {
    text: { original: string };
}

export interface SavedSearches {
    isSavedSearch: boolean;
    position?: string;
    email?: string;
    selectedClusters: SelectedClusters[];
    isFormOpen?: boolean;
    isShowButton?: boolean;
}

interface SelectedClusters {
    name: string;
    title: string;
}

export interface VacancySearchResult {
    clusters: unknown;
    criteria?: Partial<Criteria>;
    enableSimilarSavedSearch?: boolean;
    fixes?: Fixes;
    hasVacanciesWithAddress: boolean;
    isClustersEnabled: boolean;
    paging?: Paging;
    proxiedSearchFormParams?: Partial<Criteria>;
    queries: Partial<VacancySearchQueries>;
    queryRanks: unknown;
    savedSearches: SavedSearches;
    showClusters: boolean;
    suggests?: Suggests;
    totalResults: number;
    totalUsedFilters?: number;
    vacancies: VacancySearchItem[];
    vacancyHint: unknown;
    totalResultsForList: number;
}

interface VacancySearchResultState extends VacancySearchResult {
    isLoadingNewVacancies: boolean;
    isLoadingMoreVacancies: boolean;
    isError: boolean;
    failedRequestParams: Partial<Criteria>;
}

const INITIAL_STATE: VacancySearchResultState = {
    clusters: {},
    criteria: {},
    hasVacanciesWithAddress: false,
    isClustersEnabled: true,
    isLoadingNewVacancies: false,
    isLoadingMoreVacancies: false,
    isError: false,
    failedRequestParams: {},
    queries: { searchWizardBase: '' },
    queryRanks: [],
    savedSearches: {
        isSavedSearch: false,
        selectedClusters: [],
    },
    showClusters: false,
    totalResults: 0,
    totalResultsForList: 0,
    vacancies: [],
    vacancyHint: null,
};

export default createReducer<VacancySearchResultState, PayloadTypes>(INITIAL_STATE, {
    [ADD_MORE_VACANCIES]: (state, { payload }) => ({
        ...state,
        vacancies: [...state.vacancies, ...payload.vacancies],
        isLoadingNewVacancies: false,
        isLoadingMoreVacancies: false,
        isError: false,
        failedRequestParams: {},
        paging: payload.paging,
    }),
    [REPLACE_VACANCIES]: (state, { payload }) => ({
        ...state,
        vacancies: payload.vacancies,
        isLoadingNewVacancies: false,
        isLoadingMoreVacancies: false,
        isError: false,
        failedRequestParams: {},
        paging: payload.paging,
        totalResultsForList: payload.totalResults,
    }),
    [REPLACE_VACANCY_SEARCH_RESULT]: (_state, { payload: newState }) => ({
        ...newState,
        isLoadingNewVacancies: false,
        isLoadingMoreVacancies: false,
        isError: false,
        failedRequestParams: {},
    }),
    [SET_IS_LOADING_NEW_VACANCIES]: (state, { payload: isLoadingNewVacancies }) => ({
        ...state,
        isLoadingNewVacancies,
    }),
    [SET_IS_LOADING_MORE_VACANCIES]: (state, { payload: isLoadingMoreVacancies }) => ({
        ...state,
        isLoadingMoreVacancies,
    }),
    [SET_IS_ERROR]: (state, { payload: isError }) => ({ ...state, isError }),
    [SET_FAILED_REQUEST_PARAMS]: (state, { payload: failedRequestParams }) => ({ ...state, failedRequestParams }),
    [SAVE_AUTO_SEARCH]: (state, { payload: email }) => ({
        ...state,
        savedSearches: { ...state.savedSearches, isSavedSearch: true, email },
    }),
});
