import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import urlParser from 'bloko/common/urlParser';

import PostMessage from 'Modules/PostMessage';
import waitIframeContent from 'Utils/WaitIframeContent';

import ActiveOverlay from 'HHC/ActiveOverlay';

let activeInstance = null;
const supportChatIframes = {};

// eslint-disable-next-line
export const CreateSupportChatInstance = (
    postfix,
    { onOnlineOperatorsAppear = () => {}, onOnlineOperatorsLeave = () => {} }
) => {
    // переменная supportChat в данном месте связана с чатом поддержки вебим, который мы больше не используем, но когда
    // она присутствует, то в DOM помещается iframe webim, который пытается загрузить скрипты, которые падают.
    // Полностью код чата вебим будет удален в PORTFOLIO-24092
    const { supportChat } = false;

    // Если нам не пришел конфиг supportChat — не рисуем его.
    if (!supportChat) {
        return null;
    }

    const iframeOrigin = `${supportChat.url}`;

    const isSmallScreen = () => [Breakpoint.XS, Breakpoint.S].includes(getBreakpoint());
    const createSrc = (postfix) => {
        const queryString = urlParser.stringify({
            location: `${supportChat.location}${postfix}`,
            'provided-visitor': supportChat.webim_visitor ? JSON.stringify(supportChat.webim_visitor) : null,
            'start-page': JSON.stringify({ url: document.location && document.location.href }),
            'small-screen': isSmallScreen() ? '1' : '0',
        });

        return `${iframeOrigin}?${queryString}`;
    };

    const instance = { postfix };

    if (supportChatIframes[postfix] === undefined) {
        supportChatIframes[postfix] = document.createElement('iframe');
    }
    const supportChatIframe = supportChatIframes[postfix];
    const supportChatLoadedPromise = waitIframeContent(supportChatIframe);

    supportChatIframe.classList.add('support-chat-iframe', 'g-hidden');
    supportChatIframe.setAttribute('src', createSrc(postfix));
    document.body.appendChild(supportChatIframe);

    const send = (msg) => {
        supportChatLoadedPromise
            .then(({ contentWindow }) => {
                PostMessage.trigger({
                    target: contentWindow,
                    origin: iframeOrigin,
                    message: msg,
                });
            })
            .catch(console.error);
    };

    const onFocus = () => send({ name: 'window.focus' });
    const onBlur = () => send({ name: 'window.blur' });

    const showSupportChat = () => {
        supportChatIframe.classList.remove('g-hidden');
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        ActiveOverlay.overlayOpened();
    };
    const hideSupportChat = () => {
        activeInstance = null;
        supportChatIframe.classList.add('g-hidden');
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
        ActiveOverlay.overlayClosed();
    };

    const handlers = {
        onOnlineOperatorsAppear,
        onOnlineOperatorsLeave,
        onChatShownStateChanged: ({ shown }) => {
            // Эти сообщения дублируются во все открытые инстансы чата
            // Закрываете один чат - все чаты получат сообщение вида
            // {"handlerName":"onChatShownStateChanged","event":{"shown":false}}
            if (!activeInstance) {
                activeInstance = instance;
            }
            if (activeInstance === instance) {
                if (shown) {
                    showSupportChat();
                } else {
                    hideSupportChat();
                }
            }
        },
    };
    handlers.onInit = ({ online }) => online && handlers.onOnlineOperatorsAppear();

    window.addEventListener('message', (event) => {
        if (event.source === supportChatIframe.contentWindow) {
            let data;
            try {
                data = JSON.parse(event.data);
            } catch (error) {
                return;
            }
            if (data && data.name === 'handler') {
                const params = data.data;
                if (params && handlers[params.handlerName] instanceof Function) {
                    supportChatLoadedPromise
                        .then(() => {
                            handlers[params.handlerName](params.event);
                        })
                        .catch(console.error);
                }
            }
        }
    });

    instance.deactivate = () => {
        send({ name: 'webim.api.chat.close' });
        hideSupportChat();
    };
    instance.activate = (prehistory) => {
        if (activeInstance) {
            activeInstance.deactivate();
        }
        send({ name: 'webim.api.chat.start', data: { prehistory } });
        activeInstance = instance;
    };

    instance.unsubscribe = () => {
        handlers.onOnlineOperatorsAppear = () => {};
        handlers.onOnlineOperatorsLeave = () => {};
    };

    return instance;
};
