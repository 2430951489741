import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ChangeAuthor {
    userId: number;
    fullName: string;
}

export interface Division {
    divisionId: number;
    divisionName: string;
}

export enum ChangeType {
    DivisionAdded = 'DIVISION_ADDED',
    DivisionRemoved = 'DIVISION_REMOVED',
    ManagerAdded = 'MANAGER_ADDED',
    ManagerRemoved = 'MANAGER_REMOVED',
    ServiceAdded = 'SERVICE_ADDED',
    ServiceRemoved = 'SERVICE_REMOVED',
    DivisionNameSet = 'DIVISION_NAME_SET',
    DivisionQuotaChanged = 'DIVISION_QUOTA_CHANGED',
}

export interface DivisionManager {
    managerFullName: string;
    managerId: number;
}

export interface DivisionService {
    serviceId: number;
    serviceCode: string;
}

export interface DivisionQuotaChange {
    service: DivisionService;
    previousValue?: number;
    currentValue?: number;
}

export interface DivisionChange {
    name?: string;
    manager?: DivisionManager;
    service?: DivisionService;
    quota?: DivisionQuotaChange;
}

export interface DivisionChangeRow {
    changeTimeIso: string;
    author: ChangeAuthor;
    division: Division;
    changeType: ChangeType;
    change: DivisionChange;
}

export interface DivisionsChangelog {
    changes: DivisionChangeRow[];
}

export default autoGeneratedReducer<DivisionsChangelog>({
    changes: [],
});
