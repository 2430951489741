export const ArticleTypes = {
    Block: 'block',
    Event: 'event',
    Insider: 'insider',
    Planet: 'planet',
    PressRelease: 'press-release',
    Promo: 'promo',
} as const;
export type ArticleType = (typeof ArticleTypes)[keyof typeof ArticleTypes];

export const ArticleRubrics = {
    Applicants: 'applicants',
    Employers: 'employers',
    Events: 'events',
    Insider: 'insider',
    SiteNews: 'site-news',
    Specs: 'specs',
    Students: 'students',
    LabourMarket: 'labour-market',
    OwnBusiness: 'own-business',
} as const;
export type ArticleRubric = (typeof ArticleRubrics)[keyof typeof ArticleRubrics];

// full enum of topics in context of rubrics can be viewed at cms.hh.ru
export const ArticleTopics = {
    // applicants
    Resume: 'resume',
    Interview: 'interview',
    Career: 'career',
    JobSearch: 'job-search',
    LaborDisputes: 'labor-disputes',
    Professions: 'professions',
    ForRecruiters: 'for-recruiters',
    Productivity: 'productivity',
    SelfDevelopment: 'self-development',
    // employers
    HrTalks: 'hr-talks',
    HrManagement: 'hr-management',
    Recruitment: 'recruitment',
    Einterview: 'einterview',
    HrMarketing: 'hr-marketing',
    HrBranding: 'hr-branding',
    AboutRecruitment: 'about-recruitment',
    Skills: 'skills',
    LaborLaw: 'labor-law',
    OwnBusiness: 'own-business',
    Updates: 'updates',
    Automation: 'automation',
    // students
    JobSearchForStudents: 'job-search-for-students',
    CareerForStudents: 'career-for-students',
    EventsForStudents: 'events-for-students',
    NewsForStudents: 'news-for-students',
    // labour-market
    CompaniesNews: 'companies-news',
    MarketNews: 'market-news',
    // own-business
    Hiring: 'hiring',
    Cases: 'cases',
    Team: 'team',
    LawsAndLegislation: 'laws-and-legislation',
    HhServices: 'hh-services',
    Startup: 'startup',
    Leadership: 'leadership',
    // guide-to-companies
    Insider: 'insider',
    ItProjects: 'it-projects',
    InsiderArchive: 'insider-archive',
    ItProjectsArchive: 'it-projects-archive',
} as const;
export type ArticleTopic = (typeof ArticleTopics)[keyof typeof ArticleTopics];

export const ArticleTags = {
    selection: 'selection',
    jobInterview: 'job-interview',
    outsourcedRecruiting: 'outsourced-recruiting',
    itRecruting: 'it-recruiting',
} as const;
export type ArticleTag = (typeof ArticleTags)[keyof typeof ArticleTags];
