export const YA_DIRECT_TARGET_BY_VACANCY_ID_PARAM = 'utm_vacancy';
export const CPA_TARGET_BY_VACANCY_ID_PARAM = 'utm_cpa_vacancyId';
export const CPA_TARGET_BY_VACANCY_AREA_ID_PARAM = 'utm_cpa_areaId';
export const CPA_OFFER_ALIAS_ID = 'oid';
export const YA_DIRECT_STORE_FIELD_BY_VACANCY_ID = 'vacancyTargeting';
export const CPA_STORE_FIELD_BY_VACANCY_ID = 'cpaVacancyTargetingByVacancyId';
export const CPA_STORE_FIELD_BY_VACANCY_AREA_ID = 'cpaVacancyTargetingByVacancyAreaId';
export const STORE_TIME = 1000 * 60 * 60 * 24 * 10;
export const YA_DIRECT_VACANCY_TARGETING_FLOW_SETTINGS = [
    { utm_medium: 'cpc_yandex_direct' }, // eslint-disable-line camelcase
    { utm_source: 'yandex' }, // eslint-disable-line camelcase
];

export const FROM_URL_TO_LOCAL_PARAMS_MAP = {
    [YA_DIRECT_TARGET_BY_VACANCY_ID_PARAM]: 'yaDirectTargetVacancyId',
    [CPA_TARGET_BY_VACANCY_ID_PARAM]: 'cpaTargetVacancyId',
    [CPA_TARGET_BY_VACANCY_AREA_ID_PARAM]: 'cpaTargetVacancyAreaId',
    [CPA_OFFER_ALIAS_ID]: 'cpaOfferAliasId',
} as const;
