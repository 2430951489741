import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_button_click';
import appInstallBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_element_shown';
import { H3Section } from 'bloko/blocks/header';
import { CrossScaleMediumEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import Storage from 'bloko/common/storage/LocalStorageWrapper';

import media from 'Utils/Media';
import scrollTop from 'Utils/ScrollTop';
import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { AppLinkType } from 'src/models/appLink';

import { ACTION_SMART_BANNER_LOADED, ACTION_SMART_BANNER_HIDE } from 'src/components/AppSmartBanner/utils';

import styles from './index.less';

const TrlKeys = {
    title: 'mobile.app.smartbanner.text.anchor.universal',
    storeLinks: {
        ios: 'mobile.app.smartbanner.text.anchor.ios',
        android: 'mobile.app.smartbanner.text.anchor.android',
        winphone: 'mobile.app.smartbanner.text.anchor.winphone',
        huawei: 'mobile.app.smartbanner.text.anchor.huawei',
    },
};

const sendLinkAnalytics = (userType: string) => {
    Analytics.sendEvent(userType, 'click_hrmobile', 'main_banner');
    appInstallBannerButtonClick();
};

const DAYS_TO_HIDE_AFTER_CLOSE = 15;
const STORAGE_VARIABLE_NAME = 'HH-smartbanner-hidden-until';
const DAY = 86400000;
const XS_ONLY_LINK_PARAM = 'main_xs_banner';
const LINK_PARAM = 'main_banner';

const AppSmartBanner: TranslatedComponent = ({ trls }) => {
    const appLink = useSelector((state) => state.appLink);
    const device = useSelector((state) => state.device);
    const userType = useSelector(({ userType }) => userType);
    const isWebView = useSelector((state) => state.isWebView);
    const isCookiesPolicyInformerRendered = useSelector((state) => state.isCookiesPolicyInformerRendered);
    const isTopPromoBannerVisible = useSelector(({ topPromoBanner }) => topPromoBanner.isShow);
    const isXs = useBreakpoint() === Breakpoint.XS;
    const [isVisible, setIsVisible] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isTopPromoBannerEnabled = useRef(isTopPromoBannerVisible);

    useEffect(() => {
        if ((isXs && isCookiesPolicyInformerRendered) || isTopPromoBannerEnabled.current) {
            setIsVisible(false);
            return;
        }
        if (isWebView || !appLink.detected || !device.type || !media.isXsFriendly()) {
            setIsVisible(false);
            return;
        }
        const hiddenUntil = Number(Storage.getItem(STORAGE_VARIABLE_NAME));
        if (hiddenUntil && hiddenUntil > Date.now()) {
            setIsVisible(false);
            return;
        }
        setIsVisible(true);
    }, [appLink.detected, device.type, isXs, isCookiesPolicyInformerRendered, isWebView, isTopPromoBannerVisible]);

    useEffect(() => {
        if (isVisible && wrapperRef.current) {
            window.dispatchEvent(
                new CustomEvent(ACTION_SMART_BANNER_LOADED, { detail: { height: wrapperRef.current.clientHeight } })
            );
            appInstallBannerElementShown(wrapperRef.current);
        }
    }, [isVisible]);

    if (!isVisible) {
        return null;
    }

    const linkParam = isXs ? XS_ONLY_LINK_PARAM : LINK_PARAM;
    const link = `${appLink.href}?${linkParam}`;

    const hide = () => {
        if (!wrapperRef.current) {
            return;
        }
        const bannerHeight = wrapperRef.current.offsetHeight;
        scrollTop({
            top: bannerHeight,
            speed: 2500,
            animationEndCallback() {
                setIsVisible(false);
                window.scrollTo(0, 0);
                window.dispatchEvent(new CustomEvent(ACTION_SMART_BANNER_HIDE));
            },
        });
        const hideUntil = Date.now() + DAY * DAYS_TO_HIDE_AFTER_CLOSE;

        Storage.setItem(STORAGE_VARIABLE_NAME, String(hideUntil));
    };

    const onLinkClick = () => {
        sendLinkAnalytics(userType);
        hide();
    };

    return (
        <NoIndex>
            <div className={styles.wrapper} ref={wrapperRef} data-qa="app-smartbanner">
                <a
                    className={classnames(styles.icon, {
                        [styles.iconHhIosApplicant]: appLink.type === AppLinkType.HhIosApplicant,
                        [styles.iconHhAndroidApplicant]: appLink.type === AppLinkType.HhAndroidApplicant,
                        [styles.iconHhHuaweiApplicant]: appLink.type === AppLinkType.HhHuaweiApplicant,
                        [styles.iconHhIosEmployer]: appLink.type === AppLinkType.HhIosEmployer,
                        [styles.iconHhAndroidEmployer]: appLink.type === AppLinkType.HhAndroidEmployer,
                        [styles.iconHhHuaweiEmployer]: appLink.type === AppLinkType.HhHuaweiEmployer,
                        [styles.iconJtbIosApplicant]: appLink.type === AppLinkType.JtbIosApplicant,
                        [styles.iconJtbAndroidApplicant]: appLink.type === AppLinkType.JtbAndroidApplicant,
                        [styles.iconJtbIosEmployer]: appLink.type === AppLinkType.JtbIosEmployer,
                        [styles.iconJtbAndroidEmployer]: appLink.type === AppLinkType.JtbAndroidEmployer,
                        [styles.iconZpIosApplicant]: appLink.type === AppLinkType.ZpIosApplicant,
                        [styles.iconZpAndroidApplicant]: appLink.type === AppLinkType.ZpAndroidApplicant,
                        [styles.iconZpHuaweiApplicant]: appLink.type === AppLinkType.ZpHuaweiApplicant,
                    })}
                    onClick={onLinkClick}
                    href={link}
                    data-qa="app-smartbanner-img"
                />
                <a className={styles.content} onClick={onLinkClick} href={link}>
                    <div className={styles.title}>
                        <H3Section Element="div">{trls[TrlKeys.title]}</H3Section>
                    </div>
                    {!!device.type && (
                        <span className={styles.link} data-qa="app-smartbanner-storelink">
                            {trls[TrlKeys.storeLinks[device.type]]}
                        </span>
                    )}
                </a>
                <button type="button" className={styles.close} data-qa="app-smartbanner-close" onClick={hide}>
                    <CrossScaleMediumEnclosedFalse initial={IconColor.Gray80} />
                </button>
            </div>
        </NoIndex>
    );
};

export default translation(AppSmartBanner);
