import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CallStatuses } from 'src/models/employer/vacancyresponses/calls.types';

export interface CallItem {
    callerNumber: string;
    creationTime: string;
    durationSeconds: number;
    formattedCallerNumber: string;
    id: number;
    lastChangeTime: string;
    status: CallStatuses;
    viewTime: string;
}

export interface TopicCall {
    items: CallItem[];
    pickedUpPhone: boolean;
}

export default autoGeneratedReducer<Record<number, TopicCall>>({});
