import { useState } from 'react';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { H2Section } from 'bloko/blocks/header';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { PosOption, SearchQueries } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import IndustryXsSelector from 'src/components/ResumeAdvancedSearch/SearchQueries/IndustryXsSelector';
import PosSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelect';
import QuerySettingsXsSelector from 'src/components/ResumeAdvancedSearch/SearchQueries/QuerySettingsXsSelector';
import { getHasWorkplaceOptions } from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';

const HIDDEN_FIELDS = [CriteriaKey.ExpPeriod, CriteriaKey.ExpCompanySize, CriteriaKey.ExpIndustry] as const;

const TrlKeys = {
    applyButton: 'resumesSearch.advanced.apply',
    modalTitle: 'resumesSearch.novaFilters.conditions',
    posTitle: 'resumesSearch.pos.title',
};

interface QuerySettingsXsModalProps {
    currentQuery: SearchQueries;
    setCurrentQuery: (value: SearchQueries) => void;
    isVisible: boolean;
    onClose: () => void;
    autoInvite?: boolean;
}

const QuerySettingsXsModal: TranslatedComponent<QuerySettingsXsModalProps> = ({
    trls,
    currentQuery,
    setCurrentQuery,
    isVisible,
    onClose,
    autoInvite,
}) => {
    const [query, setQuery] = useState(currentQuery);
    const searchQueryDefault = useSelectorNonNullable((state) => state.searchQueries.default);
    const [hasWorkplaceOptions, setHasWorkplaceOptions] = useState(getHasWorkplaceOptions(query[CriteriaKey.Pos]));

    const handlePosChange = (values: PosOption[]) => {
        let newValue = { ...query, [CriteriaKey.Pos]: values };
        if (getHasWorkplaceOptions(values)) {
            setHasWorkplaceOptions(true);
        } else {
            setHasWorkplaceOptions(false);
            HIDDEN_FIELDS.forEach((field) => {
                newValue = { ...newValue, [field]: searchQueryDefault[field] };
            });
        }
        setQuery(newValue);
    };

    const handleSubmit = () => {
        setCurrentQuery(query);
        onClose();
    };

    const handleClose = () => {
        setQuery(currentQuery);
        setHasWorkplaceOptions(getHasWorkplaceOptions(currentQuery[CriteriaKey.Pos]));
        onClose();
    };

    return (
        <Modal visible={isVisible} closeButtonShow escapePress={false} onClose={handleClose}>
            <ModalHeader>
                <H2Section>{trls[TrlKeys.modalTitle]}</H2Section>
            </ModalHeader>
            <ModalContent>
                <VSpacing base={4} />
                <QuerySettingsXsSelector name={CriteriaKey.Logic} query={query} setQuery={setQuery} />
                <div>
                    <Text strong>{trls[TrlKeys.posTitle]}</Text>
                    <VSpacing base={4} />
                    <PosSelect selectedValues={query[CriteriaKey.Pos]} setSelectedValues={handlePosChange} />
                    <VSpacing base={3} />
                </div>
                {hasWorkplaceOptions && (
                    <>
                        <QuerySettingsXsSelector name={CriteriaKey.ExpPeriod} query={query} setQuery={setQuery} />
                        <QuerySettingsXsSelector name={CriteriaKey.ExpCompanySize} query={query} setQuery={setQuery} />
                        {autoInvite && (
                            <IndustryXsSelector
                                values={query[CriteriaKey.ExpIndustry]}
                                setValues={(values: string[]) =>
                                    setQuery({ ...query, [CriteriaKey.ExpIndustry]: values })
                                }
                            />
                        )}
                    </>
                )}
            </ModalContent>
            <ModalFooter>
                <Button type={ButtonType.Button} kind={ButtonKind.Primary} stretched onClick={handleSubmit}>
                    {trls[TrlKeys.applyButton]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default translation(QuerySettingsXsModal);
