import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    text: 'settings.subscribe.saved',
};
const SubscribeFormSucces: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.text]}</>;

export default {
    Element: translation(SubscribeFormSucces),
    kind: 'ok',
    autoClose: true,
    autoCloseDelay: 3000,
};
