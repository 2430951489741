import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

type PersonalManagerMessengersTypes = 'telegram' | 'whatsapp';

export default autoGeneratedReducer<{
    workingHoursFrom?: string;
    workingHoursTo?: string;
    available: boolean;
    smallPhoto: string;
    yearsInCompany: number;
    firstName: string;
    lastName: string;
    skype?: string;
    email?: string;
    phonesWithComments: {
        comment: string;
        extension: string;
        phone: string;
    }[];
    messenger?: {
        messengers: PersonalManagerMessengersTypes[];
        phone: string;
    };
    lastRatingValue?: string;
    vacationEndDay?: string;
    vacationEndMonth?: string;
}>({
    available: false,
    smallPhoto: '',
    yearsInCompany: NaN,
    firstName: '',
    lastName: '',
    phonesWithComments: [],
});
