import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { PosOption, LogicOption, ExpPeriodOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import { useHiddenAreaList } from 'src/components/SupernovaSearch/useHiddenAreaList';

const ResumeSearchHiddenFields: FC = () => {
    const domainAreaId = useSelector(({ locale }) => locale.domainAreaId);
    const proxiedResumeSearchFormParams = useSelector((state) => state.resumeSearchResult.proxiedSearchFormParams);
    const isSuitableResumes = useSelector((state) => !!state.searchClusters?.[CriteriaKey.VacancyId]);
    const showResumeSearchConditions = useSelector((state) => state.showResumeSearchConditions);

    const hiddenAreaList = useHiddenAreaList();
    const hiddenAreaListElement = (
        <>
            {hiddenAreaList.map((savedAreaId) => (
                <input key={savedAreaId} type="hidden" name={CriteriaKey.Area} value={savedAreaId} />
            ))}
            {Boolean(hiddenAreaList.length) && <input type="hidden" name={CriteriaKey.IsDefaultArea} value="true" />}
        </>
    );

    if (!domainAreaId) {
        return null;
    }
    const ignoredCustomParams = [
        CriteriaKey.Text,
        CriteriaKey.SavedSearchId,
        CriteriaKey.Folder,
        CriteriaKey.Area,
        CriteriaKey.SearchSessionId,
    ];

    if (proxiedResumeSearchFormParams) {
        const textParams = proxiedResumeSearchFormParams[CriteriaKey.Text];
        const pos = proxiedResumeSearchFormParams[CriteriaKey.Pos];
        const logic = proxiedResumeSearchFormParams[CriteriaKey.Logic];
        const expPeriod = proxiedResumeSearchFormParams[CriteriaKey.ExpPeriod];
        return (
            <>
                {!isSuitableResumes && hiddenAreaListElement}

                {!pos && !showResumeSearchConditions && (
                    <input type="hidden" name={CriteriaKey.Pos} value={PosOption.FullText} />
                )}
                {!logic && !showResumeSearchConditions && (
                    <input type="hidden" name={CriteriaKey.Logic} value={LogicOption.Normal} />
                )}
                {!expPeriod && !showResumeSearchConditions && (
                    <input type="hidden" name={CriteriaKey.ExpPeriod} value={ExpPeriodOption.AllTime} />
                )}
                {Object.entries(proxiedResumeSearchFormParams)
                    .filter(([key]) => !ignoredCustomParams.includes(key))
                    .map(([key, value]) =>
                        Array.isArray(value) ? (
                            value.map((value, index) => (
                                <input key={`${key}${index}`} type="hidden" name={key} value={value.toString()} />
                            ))
                        ) : (
                            <input type="hidden" name={key} key={key} value={value?.toString()} />
                        )
                    )}
                {Array.isArray(textParams) &&
                    textParams.length > 1 &&
                    textParams
                        .slice(1)
                        .map((value, index) => (
                            <input type="hidden" name={CriteriaKey.Text} value={value} key={`text${index}`} />
                        ))}
            </>
        );
    }

    return (
        <>
            {hiddenAreaListElement}
            <input type="hidden" name={CriteriaKey.ExpPeriod} value={ExpPeriodOption.AllTime} />
            <input type="hidden" name={CriteriaKey.Logic} value={LogicOption.Normal} />
            <input type="hidden" name={CriteriaKey.Pos} value={PosOption.FullText} />
        </>
    );
};

export default ResumeSearchHiddenFields;
