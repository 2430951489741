import esiaAuthorizationErrorElementShown from '@hh.ru/analytics-js-events/build/xhh/common/authorization/esia/esia_authorization_error_element_shown';
import Button, { ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'notifications.esiaAccountNotLinked.caption',
    text1: 'notifications.esiaAccountNotLinked.text.1',
    text2: 'notifications.esiaAccountNotLinked.text.2',
    ok: 'notifications.esiaAccountNotLinked.ok',
};

const EsiaAccountNotLinked: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    return (
        <SynchronizedNotification notification={notification} onClose={onClose}>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <ElementShownAnchor fn={esiaAuthorizationErrorElementShown}>
                <p>{trls[TrlKeys.text1]}</p>
                <VSpacing base={3} />
                <p>{trls[TrlKeys.text2]}</p>
                <VSpacing base={1} />
            </ElementShownAnchor>
            <NotificationFooter>
                <Button scale={ButtonScale.Small} data-qa="esia-account-not-linked-button-ok" onClick={onClose}>
                    {trls[TrlKeys.ok]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(EsiaAccountNotLinked);
