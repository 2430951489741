import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ResumeFieldStatus, ResumeFieldInvariant } from 'src/models/resume/resume.types';

interface ResumeFieldPart {
    name?: string;
    required: boolean;
    type?: string;
    subtype?: string;
    minlength?: string;
    maxlength?: string;
    regexp?: string;
    minvalue?: string;
    maxvalue?: string;
    minsize?: number;
    maxsize?: number;
    enum?: string[];
}

type ResumeFieldParts = Record<string, ResumeFieldPart>;

interface FieldCondition {
    status: ResumeFieldStatus;
    weight: number;
    mincount: number;
    maxcount: number;
    parts: ResumeFieldParts[];
}

type ResumeFormCondition = Partial<Record<ResumeFieldInvariant, FieldCondition>>;

export default autoGeneratedReducer<ResumeFormCondition>({});
