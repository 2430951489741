import { Dispatch, SetStateAction, useContext, useRef } from 'react';
import classnames from 'classnames';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import { Breakpoint } from '@hh.ru/magritte-ui';
import { MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import Form from 'src/components/Form';
import HiddenFields from 'src/components/SupernovaSearch/HiddenFields';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';
import { UserType } from 'src/models/userType';

import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const TrlKeys = {
    searchText: 'supernova.navi.jobSearch.1',
};

const sendGoogleEvent = (userType: UserType) => VendorAnalytics.eventToStorage(userType, 'header_new', 'search');

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const Search: TranslatedComponent<NavItem & { setExpanded: Dispatch<SetStateAction<boolean>> }> = ({
    trls,
    setExpanded,
    name,
    trackClicks,
    analytics,
}) => {
    const formRef = useRef<HTMLFormElement>(null);
    const supernovaSearchView = useSelector(({ supernovaSearchView }) => supernovaSearchView);
    const searchName = useSelector(({ supernovaSearchName }) => supernovaSearchName);
    const userType = useSelector(({ userType }) => userType);
    const isRedesignActive = useContext(isRedesignActiveContext);

    const isDashboard = useContext(IsDashboardContext);
    const isEmployer = userType === UserType.Employer;
    const shouldRenderSearchForm = isEmployer;

    const handleClick = () => {
        sendGoogleEvent(userType);

        if (shouldRenderSearchForm && formRef.current) {
            formRef.current.submit();
        }

        if (!shouldRenderSearchForm && !searchName) {
            setExpanded((expanded) => !expanded);
        }
    };

    const button = (
        <SupernovaActionLink
            name={name}
            data-qa="supernova-search"
            onClick={handleClick}
            icon={<MagnifierOutlinedSize24 />}
            trackClicks={trackClicks}
            analytics={analytics}
            onlyIconOnMobile
        >
            {trls[TrlKeys.searchText]}
        </SupernovaActionLink>
    );

    if (supernovaSearchView.large) {
        return null;
    }

    return (
        <SupernovaNaviItem
            className={classnames('supernova-navi-item_search', {
                'supernova-navi-item_dashboard': isDashboard,
                'supernova-navi-item_with-arrow': !isEmployer && !isRedesignActive,
            })}
            visibleOn={supernovaSearchView.largeXS ? VISIBLE_ON : undefined}
        >
            {shouldRenderSearchForm ? (
                <Form ref={formRef} action={paths.resumeSearch} method="GET">
                    {button}
                    <input type="hidden" name="from" value="header-menu" />
                    <HiddenFields searchName={SupernovaSearchName.Resumes} />
                </Form>
            ) : (
                button
            )}
        </SupernovaNaviItem>
    );
};

export default translation(Search);
