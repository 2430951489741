import { Fragment, FC, useCallback, useContext } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Breakpoint } from '@hh.ru/magritte-ui';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaLink from 'src/components/SupernovaMainMenu/NaviLink';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import useGoogleAnalytics from 'src/components/SupernovaMainMenu/useGoogleAnalytics';

const sendAnalytics = (vacancyId?: number) => {
    Analytics.sendHHEventButtonClick('login_button_header', { vacancyId });
};

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];
const VISIBLE_ON_MOBILE = [Breakpoint.XS];

const Login: FC<NavItem> = ({ employer, name, trackClicks, translations, url, analytics }) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);

    const vacancyId = useSelector(
        ({ postponedActions, vacancyView }) => vacancyView.vacancyId ?? postponedActions?.vacancy?.vacancyId
    );
    const sendAdditionalAnalytics = useCallback(() => sendAnalytics(vacancyId), [vacancyId]);
    const handleClick = useGoogleAnalytics('start_login', sendAdditionalAnalytics);

    const shouldRenderButtonOnMobile = isRedesignActive && isRedesignAnonymousMenuExpB;

    const desktopDataQa = employer ? `mainmenu_${name}` : name;
    return (
        <Fragment>
            {employer && !isRedesignActive ? (
                <SupernovaNaviItem className="supernova-login-wrapper" visibleOn={VISIBLE_ON}>
                    <SupernovaLink
                        data-qa={desktopDataQa}
                        name={name}
                        onClick={handleClick}
                        trackClicks={trackClicks}
                        url={url}
                        analytics={analytics}
                    >
                        {translations.name}
                    </SupernovaLink>
                </SupernovaNaviItem>
            ) : (
                <SupernovaNaviItem className="supernova-login-wrapper" isButton visibleOn={VISIBLE_ON}>
                    <SupernovaButton
                        isMagritte={isRedesignActive}
                        data-qa={desktopDataQa}
                        name={name}
                        onClick={handleClick}
                        trackClicks={trackClicks}
                        url={url}
                        analytics={analytics}
                    >
                        {translations.name}
                    </SupernovaButton>
                </SupernovaNaviItem>
            )}
            <SupernovaNaviItem
                className="supernova-login-wrapper"
                visibleOn={VISIBLE_ON_MOBILE}
                isButton={shouldRenderButtonOnMobile}
            >
                {shouldRenderButtonOnMobile ? (
                    <SupernovaButton
                        isMagritte={isRedesignActive}
                        data-qa={name}
                        name={name}
                        onClick={handleClick}
                        trackClicks={trackClicks}
                        url={url}
                        analytics={analytics}
                    >
                        {translations.name}
                    </SupernovaButton>
                ) : (
                    <SupernovaLink
                        isMagritte={isRedesignActive}
                        magritteTypography="subtitle-2-semibold"
                        data-qa="mainmenu_profile-link"
                        name={name}
                        onClick={handleClick}
                        trackClicks={trackClicks}
                        url={url}
                        analytics={analytics}
                    >
                        {translations.name}
                    </SupernovaLink>
                )}
            </SupernovaNaviItem>
        </Fragment>
    );
};

export default Login;
