export enum VacancyBlockedReason {
    VacancyBlockedDuePaidServices = 'VACANCY_BLOCKED_DUE_PAID_SERVICES',
    VacancyBlockedDueBadDescription = 'VACANCY_BLOCKED_DUE_BAD_DESCRIPTION',
    VacancyBlockedDueTwoInOne = 'VACANCY_BLOCKED_DUE_TWO_IN_ONE',
    VacancyBlockedDueTraining = 'VACANCY_BLOCKED_DUE_TRAINING',
    VacancyBlockedDueCaptionMissing = 'VACANCY_BLOCKED_DUE_CAPTION_MISSING',
    VacancyBlockedDueModerator = 'VACANCY_BLOCKED_DUE_MODERATOR',
    VacancyBlockedDueRelocateFromBelarus = 'VACANCY_BLOCKED_DUE_RELOCATE_FROM_BELARUS',
    VacancyBlockedDueReplacement = 'VACANCY_BLOCKED_DUE_REPLACEMENT',
}
