import { ButtonName } from '@hh.ru/analytics-js-events/build/xhh/employer/search/pick_resume_tab_button_click';
import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { UserStat } from 'src/models/userStats';

export interface EmployerSearchTab {
    name: string;
    href: string;
    title: string;
    analyticsKey: ButtonName;
    counter?: UserStat;
    active?: boolean;
}

type EmployerSearchTabsState = EmployerSearchTab[] | undefined;

export default autoGeneratedReducer<EmployerSearchTabsState>([]);
