export default function (data, params = {}) {
    if (!data?.trl) {
        return data;
    }
    if (params.noTrl) {
        const trl = data.trl;
        Object.keys(trl).forEach((lang) => {
            const trls = trl[lang];
            Object.keys(trls).forEach((key) => {
                trls[key] = `[${lang}:${key}]`;
            });
        });
        return data;
    }

    const trl = data.trl;
    Object.keys(trl).forEach((lang) => {
        const trls = trl[lang];
        Object.keys(trls).forEach((key) => {
            const value = trls[key];
            trls[key] = value.replace(/&#(\d+);/g, (_, code) => String.fromCharCode(code));
        });
    });
    return data;
}
