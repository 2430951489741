import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

export interface SupernovaSearchBaseState {
    searchName: SupernovaSearchName;
    supernovaSearchName: SupernovaSearchName | null;
    setSearchName: Dispatch<SetStateAction<SupernovaSearchName>>;
    isEmployerSearch: boolean;
}

export default (): SupernovaSearchBaseState => {
    const userType = useSelector(({ userType }) => userType);

    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const supernovaSearchName = useSelector(({ supernovaSearchName }) => supernovaSearchName);

    const isEmployerSearch = userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer;
    const [searchName, setSearchName] = useState(
        supernovaSearchName || (isEmployerSearch ? SupernovaSearchName.Resumes : SupernovaSearchName.Vacancies)
    );
    const setSearchNameWithQueryUpdate = useCallback(
        (searchName: SetStateAction<SupernovaSearchName>) => setSearchName(searchName),
        [setSearchName]
    );

    return {
        searchName,
        supernovaSearchName,
        setSearchName: setSearchNameWithQueryUpdate,
        isEmployerSearch,
    };
};
