import { ComponentType, FC, ReactNode } from 'react';

import { ScrollableContainer, useBreakpoint } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import BalanceInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/BalanceInformer';
import ClickmeBalanceInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/ClickmeBalanceInformer';
import ContactsInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/ContactsInformer';
import PfpBalanceInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/PfpBalanceInformer';
import ResumeAccessInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/ResumeAccessInformer';
import SkeletonInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/SkeletonInformer';
import TalantixDaysLeftInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/TalantixDaysLeftInformer';
import VacancyInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/VacancyInformer';
import VideoInterviewInformer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/VideoInterviewInformer';

import styles from './styles.less';

enum InformerType {
    Balance = 'balance',
    Vacancy = 'vacancy',
    Contacts = 'contacts',
    ResumeAccess = 'resumeAccess',
    PfpBalace = 'PfpBalance',
    VideoInterview = 'videoInterview',
    ClickmeBalance = 'clickmeBalance',
    TalantixDaysLeft = 'TalantixDaysLeft',
}

const MOBILE_SKELETON_COUNT = 2;
const DESKTOP_SKELETON_COUNT = 4;

const Informers = new Map<InformerType, ComponentType>([
    [InformerType.Balance, BalanceInformer],
    [InformerType.Vacancy, VacancyInformer],
    [InformerType.Contacts, ContactsInformer],
    [InformerType.ResumeAccess, ResumeAccessInformer],
    [InformerType.PfpBalace, PfpBalanceInformer],
    [InformerType.VideoInterview, VideoInterviewInformer],
    [InformerType.ClickmeBalance, ClickmeBalanceInformer],
    [InformerType.TalantixDaysLeft, TalantixDaysLeftInformer],
]);
const managerInformers = [
    InformerType.Balance,
    InformerType.Vacancy,
    InformerType.Contacts,
    InformerType.ResumeAccess,
    InformerType.PfpBalace,
    InformerType.VideoInterview,
    InformerType.ClickmeBalance,
    InformerType.TalantixDaysLeft,
];
const hiringManagerInformers = [
    InformerType.Contacts,
    InformerType.ResumeAccess,
    InformerType.PfpBalace,
    InformerType.VideoInterview,
    InformerType.ClickmeBalance,
    InformerType.TalantixDaysLeft,
];

const renderInformers = (informers: InformerType[]): (JSX.Element | null)[] =>
    informers.map((informerType) => {
        const Informer = Informers.get(informerType);
        return Informer ? <Informer key={informerType} /> : null;
    });

const getSkeletonInformers = (isMobile: boolean): ReactNode => {
    const skeletonCount = isMobile ? MOBILE_SKELETON_COUNT : DESKTOP_SKELETON_COUNT;

    const skeletonsContent = [];

    for (let i = 0; i < skeletonCount; i++) {
        skeletonsContent.push(<SkeletonInformer key={i} />);
    }

    return <>{skeletonsContent}</>;
};

interface EmployerProfileInformersProps {
    isLoading: boolean;
}

const EmployerProfileInformers: FC<EmployerProfileInformersProps> = ({ isLoading }) => {
    const { isMobile } = useBreakpoint();
    const isHiringManager = useSelector((state) => state.isHiringManager);

    const informers = isLoading
        ? getSkeletonInformers(isMobile)
        : renderInformers(isHiringManager ? hiringManagerInformers : managerInformers);

    if (isMobile) {
        return (
            <ScrollableContainer correction={16} correctionS={24}>
                <div className={styles.informers}>{informers}</div>
            </ScrollableContainer>
        );
    }

    return <div className={styles.informers}>{informers}</div>;
};

export default EmployerProfileInformers;
