import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerShortInfo {
    areaId?: number;
    creationTime?: number;
    hasMoney?: boolean;
    hasPublications?: boolean;
    hasResumeAccess?: boolean;
    hasVacancies?: boolean;
    paid?: boolean;
    state?: string;
}

export default autoGeneratedReducer<EmployerShortInfo>({});
