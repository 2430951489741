import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';
import { analyticsNameMap, linkMap } from 'src/utils/notifications/HRSpace';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.HRSpacePromoApplicant]: {
        description: 'hrspace.notification.applicant.description',
        link: 'hrspace.notification.applicant.link',
    },
    [ComplexUserNotificationsTemplateKey.HRSpacePromoEmployer]: {
        description: 'hrspace.notification.employer.description',
        link: 'hrspace.notification.employer.link',
    },
    [ComplexUserNotificationsTemplateKey.HRSpacePromoITEmployer]: {
        description: 'hrspace.notification.it_employer.description',
        link: 'hrspace.notification.it_employer.link',
    },
};

type TemplateKeys = keyof typeof TrlKeys;

const HRSpace: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const key = notification.templateKey as TemplateKeys;

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Info}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>
                <p>{trls[TrlKeys[key].description]}</p>
            </NotificationSpacer>
            <NotificationLink
                target="_blank"
                to={linkMap[key]}
                onClick={() => {
                    Analytics.sendHHEventButtonClick(`${analyticsNameMap[key]}_link_supernova`);
                    Analytics.sendEvent(analyticsNameMap[key], 'view', 'supernova');
                }}
            >
                {trls[TrlKeys[key].link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(HRSpace);
