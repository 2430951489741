import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    balance: 'employer.employerProfile.popup.informers.balance',
    reserved: 'employer.employerProfile.popup.informers.reserved',
};

const BalanceInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();

    const currency = billingInfo?.billingSummary?.currency || CurrencyType.RUR;
    const balance = billingInfo?.billingSummary?.availableWithoutBlockedMoney;
    const reserved = billingInfo?.billingSummary?.blockedMoney;

    return (
        <>
            <Informer data-qa="total_money" title={trls[TrlKeys.balance]}>
                <FormatMoney currency={currency}>{typeof balance === 'number' ? balance : 0}</FormatMoney>
            </Informer>

            {reserved ? (
                <Informer data-qa="reserved_money" title={trls[TrlKeys.reserved]}>
                    <FormatMoney currency={currency}>{reserved}</FormatMoney>
                </Informer>
            ) : null}
        </>
    );
};

export default translation(BalanceInformer);
