import { useState } from 'react';

import Select, { Option } from 'bloko/blocks/select';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import {
    ExpCompanySizeOption,
    ExpPeriodOption,
    LogicOption,
    SearchQueries,
} from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import BottomSheetSelector from 'src/components/ResumeAdvancedSearch/SearchQueries/BottomSheetSelector';

const TrlKeys = {
    titles: {
        [CriteriaKey.Logic]: 'resumesSearch.logic.title',
        [CriteriaKey.ExpPeriod]: 'resumesSearch.exp_period.title',
        [CriteriaKey.ExpCompanySize]: 'resumesSearch.exp_company_size.title',
    },
    [CriteriaKey.Logic]: {
        [LogicOption.Normal]: 'resumesSearch.logic.mobile.normal',
        [LogicOption.Any]: 'resumesSearch.logic.any',
        [LogicOption.Phrase]: 'resumesSearch.logic.phrase',
        [LogicOption.Except]: 'resumesSearch.logic.except',
    },
    [CriteriaKey.ExpPeriod]: {
        [ExpPeriodOption.AllTime]: 'resumeSearch.experience_period.all_time',
        [ExpPeriodOption.LastYear]: 'resumeSearch.experience_period.last_year',
        [ExpPeriodOption.LastThreeYears]: 'resumeSearch.experience_period.last_three_years',
        [ExpPeriodOption.LastSixYears]: 'resumeSearch.experience_period.last_six_years',
    },
    [CriteriaKey.ExpCompanySize]: {
        [ExpCompanySizeOption.Any]: 'resumeSearch.company_size.mobile.any',
        [ExpCompanySizeOption.Small]: 'resumeSearch.company_size.mobile.small',
        [ExpCompanySizeOption.Medium]: 'resumeSearch.company_size.mobile.medium',
        [ExpCompanySizeOption.Large]: 'resumeSearch.company_size.mobile.large',
    },
};

interface QuerySettingsXsSelectorProps {
    name: typeof CriteriaKey.Logic | typeof CriteriaKey.ExpPeriod | typeof CriteriaKey.ExpCompanySize;
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
}

const QuerySettingsXsSelector: TranslatedComponent<QuerySettingsXsSelectorProps> = ({
    trls,
    name,
    query,
    setQuery,
}) => {
    const [modalIsVisible, setModalVisible] = useState(false);
    const optionValue = query[name][0];
    const optionsTrls = TrlKeys[name];

    return (
        <div>
            <Text strong>{trls[TrlKeys.titles[name]]}</Text>
            <VSpacing base={3} />
            <Select
                name={name}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                <Option hidden value={optionValue}>
                    {trls[optionsTrls[optionValue as keyof typeof optionsTrls]]}
                </Option>
            </Select>
            <VSpacing base={6} />
            <BottomSheetSelector
                name={name}
                title={trls[TrlKeys.titles[name]]}
                currentOption={optionValue}
                setCurrentOption={(value) => setQuery({ ...query, [name]: [value] })}
                modalIsVisible={modalIsVisible}
                setModalIsVisible={setModalVisible}
            />
        </div>
    );
};

export default translation(QuerySettingsXsSelector);
