import Checkbox from 'bloko/blocks/checkbox';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ResumeCountriesVisibilitySettings } from 'src/models/resumeCountriesVisibility';

import ResumesCountriesVisibilityFormDescription from 'src/components/ResumesCountriesVisibility/ResumesCountriesVisibilityFormDescription';
import { CountrySelection, DataQaKey, DATA_QA_ATTR_PREFIX } from 'src/components/ResumesCountriesVisibility/types';

const TrlKeys = {
    common: 'settings.countries_visible.common_countries.applicant',
    uzbekistan: 'settings.countries_visible.uzbekistan.applicant',
};

interface ResumesCountriesVisibilityFormProps {
    isFetching: boolean;
    settings: ResumeCountriesVisibilitySettings;
    handleChange: (name: keyof ResumeCountriesVisibilitySettings, value: boolean) => void;
    dataQaKey: DataQaKey;
    conditionsClickHandler?: (country: CountrySelection) => void;
    rulesClickHandler?: (country: CountrySelection) => void;
}

const ResumesCountriesVisibilityForm: TranslatedComponent<ResumesCountriesVisibilityFormProps> = ({
    isFetching,
    settings,
    handleChange,
    dataQaKey,
    conditionsClickHandler,
    rulesClickHandler,
    trls,
}) => {
    return (
        <>
            <Checkbox
                data-qa={`${DATA_QA_ATTR_PREFIX}-checkbox-common-${dataQaKey}`}
                disabled={isFetching}
                checked={settings.visibleForCommonCountries}
                onChange={() => handleChange('visibleForCommonCountries', !settings.visibleForCommonCountries)}
            >
                <div data-qa={`${DATA_QA_ATTR_PREFIX}-title-common-${dataQaKey}`}> {trls[TrlKeys.common]}</div>
                <ResumesCountriesVisibilityFormDescription
                    dataQaKey={dataQaKey}
                    country={'common'}
                    conditionsClickHandler={conditionsClickHandler}
                    rulesClickHandler={rulesClickHandler}
                />
            </Checkbox>
            <VSpacing base={4} />
            <Checkbox
                data-qa={`${DATA_QA_ATTR_PREFIX}-checkbox-uzbekistan-${dataQaKey}`}
                disabled={isFetching}
                checked={settings.visibleForUzbekistan}
                onChange={() => handleChange('visibleForUzbekistan', !settings.visibleForUzbekistan)}
            >
                <div data-qa={`${DATA_QA_ATTR_PREFIX}-title-uzbekistan-${dataQaKey}`}>{trls[TrlKeys.uzbekistan]}</div>
                <ResumesCountriesVisibilityFormDescription
                    dataQaKey={dataQaKey}
                    country={'uzbekistan'}
                    conditionsClickHandler={conditionsClickHandler}
                    rulesClickHandler={rulesClickHandler}
                />
            </Checkbox>
        </>
    );
};
export default translation(ResumesCountriesVisibilityForm);
