import Analytics from '@hh.ru/analytics-js';

export const overlayMenuItemShown = (element: HTMLElement, item: string): void => {
    Analytics.sendHHEventElementShown(element, {
        name: `overlay_menu_item_${item}`,
    });
};

export default {
    overlayMenuItemShown,
};
