import {
    ChipsContainer,
    CustomChip,
    FormLabel,
    HSpacingContainer,
    VSpacingContainer,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { PartTimeOption } from 'src/models/search/searchCriteria.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.onboarding.title.schedule',
    schedule: {
        fullDay: 'search.schedule.fullDay',
        remote: 'search.schedule.remote',
        shift: 'search.schedule.shift',
        flexible: 'search.schedule.flexible',
        flyInFlyOut: 'search.schedule.flyInFlyOut',
        doesNotMatter: 'search.schedule.doesNotMatter',
        partTime: 'cluster.partTime',
    },
};

const OnboardingFilterChips: TranslatedComponent = ({ trls }) => {
    const search = useSelector(({ router }) => router.location.search);
    const { partTime, schedule } = useSelector((state) => state.vacancySearchDictionaries);
    const { roleExtensions, showScheduleChips, additionalSchedule } = useSelector(
        ({ anonymousOnboardingFilterChips }) => anonymousOnboardingFilterChips
    );

    const { isMobile } = useBreakpoint();

    const push = usePush();

    const filteredPartTime = partTime.filter((item) => item !== PartTimeOption.TemporaryJobTrue);

    const SchedulesChipsWrapper = isMobile ? VSpacingContainer : HSpacingContainer;

    return (
        <VSpacingContainer default={24}>
            {roleExtensions.length > 0 && (
                <ChipsContainer>
                    {roleExtensions.map((extension) => (
                        <CustomChip
                            key={extension}
                            size="small"
                            onClick={() => {
                                const newQuery = urlParser(search).params as Record<string, string | string[]>;
                                newQuery.text = `${newQuery.text?.[0] || ''} ${extension}`;
                                newQuery.hhtmFromLabel = 'search_list_onboarding_exstensions_role';
                                delete newQuery.needShortRoleExtensions;
                                push(`${paths.vacancySearch}?${urlParser.stringify(newQuery)}`);
                            }}
                        >
                            +{NON_BREAKING_SPACE}
                            {extension}
                        </CustomChip>
                    ))}
                </ChipsContainer>
            )}
            {showScheduleChips && additionalSchedule && (
                <SchedulesChipsWrapper default={8}>
                    <div className={styles.filterChipsLabel}>
                        <FormLabel>{trls[TrlKeys.title]}:</FormLabel>
                    </div>
                    <ChipsContainer scrollable={!isMobile}>
                        {[...schedule, additionalSchedule].map((item) => (
                            <CustomChip
                                key={item}
                                size="small"
                                onClick={() => {
                                    const newQuery = urlParser(search).params as Record<string, string | string[]>;
                                    if (item === 'partTime') {
                                        newQuery[NovaFilterKey.PartTime] = filteredPartTime;
                                    } else if (item === 'doesNotMatter') {
                                        newQuery[NovaFilterKey.Schedule] = schedule;
                                    } else {
                                        newQuery[NovaFilterKey.Schedule] = item;
                                    }
                                    newQuery.hhtmFromLabel = 'search_list_onboarding_schedule';
                                    delete newQuery.needSchedulesChips;
                                    const url = `${paths.vacancySearch}?${urlParser.stringify(newQuery)}`;
                                    if (item === 'partTime') {
                                        window.location.href = url;
                                    } else {
                                        push(url);
                                    }
                                }}
                            >
                                {trls[TrlKeys.schedule[item]]}
                            </CustomChip>
                        ))}
                    </ChipsContainer>
                </SchedulesChipsWrapper>
            )}
        </VSpacingContainer>
    );
};

export default translation(OnboardingFilterChips);
