import { FC, useState, useLayoutEffect, useRef, useContext, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import Debug from 'HHC/Debug';

import { CodeInjectorContext } from 'src/components/CodeInjector';

interface InjectPortalProps {
    place: string;
    logLevel?: Parameters<typeof Debug.log>[0];
}

export const makePortalDelimiter = (place: string, point: 'start' | 'end'): string =>
    `lux-divide-portal-${place}-${point}`;

const InjectPortal: FC<InjectPortalProps & PropsWithChildren> = ({ children, place, logLevel = 'error out' }) => {
    const [showPortal, setShowPortal] = useState(false);
    const container = useRef<HTMLDivElement | null>(null);
    const dividerContext = useContext(CodeInjectorContext);

    useLayoutEffect(() => {
        container.current = document.querySelector(`.HH-CodeInjectorPortal-Place-${place}`);

        if (container.current) {
            container.current.innerHTML = '';
            setShowPortal(true);
        } else {
            Debug.log(logLevel, `CodeInjectorPortal not found DOM node. Place: ${place}`);
        }
    }, [logLevel, place]);

    if (process.env.SSR) {
        dividerContext.portals.push(place);
        const injectPortalStartDelimiter = makePortalDelimiter(place, 'start');
        const injectPortalEndDelimiter = makePortalDelimiter(place, 'end');

        return (
            <>
                {injectPortalStartDelimiter}
                {children}
                {injectPortalEndDelimiter}
            </>
        );
    }

    if (showPortal === false || container.current === null) {
        return null;
    }

    return createPortal(children, container.current);
};

export default InjectPortal;
