import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: {
        title: 'hhrating.registration.invitation.notification.title',
        description: 'hhrating.registration.invitation.notification.description',
        link: 'hhrating.registration.invitation.notification.link',
    },
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: {
        title: 'hhrating.vote.invitation.notification.title',
        description: 'hhrating.vote.invitation.notification.description',
        link: 'hhrating.vote.invitation.notification.link',
    },
};

const notificationContent = {
    [ComplexUserNotificationsTemplateKey.HHRatingRegistration]: {
        trlKeys: TrlKeys[ComplexUserNotificationsTemplateKey.HHRatingRegistration],
        link: '/shards/notifications/view_registration_hhrating',
        analyticsName: 'hhrating_registration_invitation',
    },
    [ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation]: {
        trlKeys: TrlKeys[ComplexUserNotificationsTemplateKey.HHRatingVoteInvitation],
        link: '/shards/notifications/view_vote_hhrating',
        analyticsName: 'hhrating_vote_invitation',
    },
};

const sendClickAnalytics = (eventName: string) => {
    // Internal analytics is sent from bff
    Analytics.sendEvent(eventName, 'view', 'supernova');
};

const HHRating: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const { trlKeys, link, analyticsName } =
        notificationContent[notification.templateKey as keyof typeof notificationContent];

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.New}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>
                <p>{trls[trlKeys.title]}</p>
                <p>{trls[trlKeys.description]}</p>
            </NotificationSpacer>
            <NotificationLink
                target="_blank"
                to={`${link}?notification_medium=supernova`}
                onClick={() => {
                    sendClickAnalytics(analyticsName);
                }}
            >
                {trls[trlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(HHRating);
